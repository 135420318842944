import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useGetDiscussionThread } from '../use-get-discussion-thread';
import { DiscussionThreadType } from '../../types';

type SetConditionalDiscussionThreadProps =
  | {
      /*
        Id of the discussion thread to be opened
      */
      discussionThreadId: number;
      /*
        Id of parent entity where the discussion will be about. e.g room_id, rent_id
      */
      discussableId?: never;
      type: DiscussionThreadType;
      onCloseFN?: () => void;
      onMessageSent?: () => void;
    }
  | {
      type: DiscussionThreadType;
      /*
        Id of parent entity where the discussion will be about. e.g room_id, rent_id
      */
      discussableId: number;
      /*
        Id of the discussion thread to be opened
      */
      discussionThreadId?: never;
      onCloseFN?: () => void;
      onMessageSent?: () => void;
    };

type DiscussionThreadProps = {
  show: boolean;
  discussionThreadId: number | undefined;
  discussionType: DiscussionThreadType | undefined;
  discussableId: number | undefined;
  onCloseFN?: () => void;
  onMessageSent?: () => void;
};

const useDiscussionThreadProvider = () => {
  const [showDiscussionThread, setShowDiscussionThread] =
    useState<DiscussionThreadProps>({
      show: false,
      discussionThreadId: undefined,
      discussionType: undefined,
      discussableId: undefined,
      onCloseFN: undefined,
      onMessageSent: undefined,
    });

  const openDiscussionThread = useCallback(
    (props: SetConditionalDiscussionThreadProps) => {
      setShowDiscussionThread({
        show: true,
        discussionType: props.type,
        discussableId: props?.discussableId ?? undefined,
        discussionThreadId: props?.discussionThreadId ?? undefined,
        onCloseFN: props?.onCloseFN ?? undefined,
        onMessageSent: props?.onMessageSent ?? undefined,
      });
    },
    []
  );

  const discussionThreadHasBeenCreated = useMemo(() => {
    return (
      showDiscussionThread.show === true &&
      showDiscussionThread.discussionThreadId !== undefined
    );
  }, [showDiscussionThread.discussionThreadId, showDiscussionThread.show]);

  const closeDiscussionThread = useCallback(() => {
    showDiscussionThread.onCloseFN?.();
    setShowDiscussionThread({
      show: false,
      discussionType: undefined,
      discussableId: undefined,
      discussionThreadId: undefined,
      onCloseFN: undefined,
      onMessageSent: undefined,
    });
  }, [showDiscussionThread]);

  const {
    fetch,
    addDiscussionThreadMessage,
    deleteDiscussionThreadMessageInCache,
  } = useGetDiscussionThread({
    id: showDiscussionThread?.discussionThreadId,
  });

  return {
    showDiscussionThread,
    fetch,
    addDiscussionThreadMessage,
    deleteDiscussionThreadMessageInCache,
    closeDiscussionThread,
    openDiscussionThread,
    discussionThreadHasBeenCreated,
  };
};

const DiscussionThreadProviderContext = createContext<
  ReturnType<typeof useDiscussionThreadProvider> | undefined
>(undefined);

const useDiscussionThreadProviderContext = () => {
  const context = useContext(DiscussionThreadProviderContext);

  if (context === null) {
    throw new Error(
      'useDiscussionThreadProviderContext must be used within a DiscussionThreadsProvider'
    );
  }

  return context;
};

export {
  useDiscussionThreadProviderContext,
  DiscussionThreadProviderContext,
  useDiscussionThreadProvider,
};
