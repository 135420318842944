import React from 'react';
import { TableRowProps } from './types';
import { composeClassName } from '@/utils/util_helpers';

const TableRow: React.FC<TableRowProps> = ({
  children,
  className,
  index = 0,
  ...props
}) => {
  return (
    <tr
      {...props}
      className={composeClassName(
        index % 2 === 0 ? 'bg-white' : 'bg-E30',
        className && className
      )}
    >
      {children}
    </tr>
  );
};

export { TableRow };
