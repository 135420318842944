import { AvatarType } from '../types';

const getAvatarColor = (params: { text: string; type: AvatarType }): string => {
  const { text, type } = params;

  if (type === 'entity') {
    return 'bg-white';
  }

  const colors = [
    'bg-E300',
    'bg-E100',
    'bg-R500',
    'bg-Q500',
    'bg-C500',
    'bg-J500',
    'bg-B500',
  ];

  const sum = Object.values(text).reduce((a, b) => a + b.charCodeAt(0), 0);

  const colorIndex = sum % colors.length;

  return colors[colorIndex];
};

export { getAvatarColor };
