/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useContext, useState } from 'react';
import { NavigationMenuDisplayValue, NavigationMenuProps } from '../../types';

type NavigationMenuContextProps = Pick<
  NavigationMenuProps,
  'onChange' | 'activeNavigationKey' | 'displayValue'
> & {
  setDisplayValue?: (
    value: React.SetStateAction<NavigationMenuDisplayValue>
  ) => void;
};

type NavigationMenuContextProviderProps = Omit<
  NavigationMenuContextProps,
  'setD'
> & {
  children: React.ReactNode;
};

const NavigationMenuContext = createContext<
  NavigationMenuContextProps | undefined
>(undefined);

const NavigationMenuContextProvider: React.FC<NavigationMenuContextProviderProps> =
  ({
    onChange,
    activeNavigationKey,
    children,
    displayValue: passedDisplayValue,
  }) => {
    const [displayValue, setDisplayValue] =
      useState<NavigationMenuDisplayValue>(passedDisplayValue || 'expanded');

    return (
      <NavigationMenuContext.Provider
        value={{
          activeNavigationKey,
          onChange,
          displayValue,
          setDisplayValue,
        }}
      >
        {children}
      </NavigationMenuContext.Provider>
    );
  };

const useNavigationMenuContext = () => {
  const context = useContext(NavigationMenuContext);

  if (context === null) {
    throw new Error(
      'useNavigationMenuContext must be used within a NavigationMenuContextProvider'
    );
  }

  return context;
};

export { useNavigationMenuContext, NavigationMenuContextProvider };
