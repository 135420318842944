import { Tab as InternalTab } from '@headlessui/react';
import React from 'react';
import { isElement } from 'react-dom/test-utils';
import { composeClassName } from '@/utils/util_helpers';
import { TabProps } from './types';

const Tab: React.FC<TabProps> = ({ children, disabled = false, className }) => {
  return (
    <InternalTab
      disabled={disabled}
      className={composeClassName('', className && className)}
    >
      {isElement(children)
        ? ({ selected }) => {
            return React.Children.map(
              children as unknown as React.ReactElement,
              (child: React.ReactElement) => {
                return {
                  ...child,
                  props: {
                    ...child.props,
                    selected,
                    disabled,
                  },
                };
              }
            ) as unknown as React.ReactElement;
          }
        : children}
    </InternalTab>
  );
};

export { Tab };
