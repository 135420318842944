<template>
  <section
    :style="{ backgroundImage: `url(${bgImage})` }"
    class="w-full"
    id="WhatImages"
  >
    <div class="m-auto w-full max-w-8xl px-4 py-7 pb-9 lg:pt-9">
      <div class="flex flex-col items-center space-y-2 lg:hidden">
        <h1 class="font-Vollkorn text-[28px] font-bold text-primary-100">
          {{ t('vue_components.spot_a_house.what_are_we_looking_for') }}
        </h1>
        <span class="text-base font-medium text-[#414141]">{{
          t('vue_components.spot_a_house.the_most_interesting_projects')
        }}</span>
      </div>
      <div
        class="
          flex
          w-full
          flex-col
          items-center
          lg:flex-row
          lg:items-start
          lg:justify-between
        "
      >
        <div class="flex flex-col items-start space-y-4">
          <div class="hidden flex-col items-start space-y-2 lg:flex">
            <h1 class="font-Vollkorn text-5xl font-bold text-primary-100">
              {{ t('vue_components.spot_a_house.what_are_we_looking_for') }}
            </h1>
            <span class="text-[22px] font-medium text-[#414141]">{{
              t('vue_components.spot_a_house.the_most_interesting_projects')
            }}</span>
          </div>
          <div class="flex w-full flex-col items-start space-y-3">
            <div class="flex flex-col items-start space-y-4">
              <div
                class="
                  flex
                  cursor-pointer
                  items-center
                  space-x-2
                  rounded-full
                  py-2
                  px-6
                  ring-[#FEE37D]
                "
                :class="{
                  'bg-[#FECE1F] ': selected == 1,
                  'hover:bg-[#FFFAE9] hover:ring-1': selected != 1,
                }"
                @click="
                  handelSelect(1, t('vue_components.spot_a_house.one_doorbell'))
                "
              >
                <IconCheck />
                <span class="text-sm font-normal text-[#414141] lg:text-lg">{{
                  t('vue_components.spot_a_house.one_doorbell')
                }}</span>
              </div>
              <div class="w-full max-w-[283px] pl-4" v-show="selected == 1">
                <p class="text-sm font-normal text-[#414141]">
                  {{ t('vue_components.spot_a_house.one_doorbell2') }}
                </p>
                <Houses
                  :selected="selected"
                  :selectedBadgeTitle="selectedBadgeTitle"
                  class="lg:hidden"
                />
              </div>
            </div>
            <div class="flex flex-col items-start space-y-4">
              <div
                class="
                  flex
                  cursor-pointer
                  items-center
                  space-x-2
                  rounded-full
                  py-2
                  px-6
                  ring-[#FEE37D]
                "
                :class="{
                  'bg-[#FECE1F] ': selected == 2,
                  'hover:bg-[#FFFAE9] hover:ring-1': selected != 2,
                }"
                @click="
                  handelSelect(2, t('vue_components.spot_a_house.wide_facade'))
                "
              >
                <IconCheck />
                <span class="text-sm font-normal text-[#414141] lg:text-lg">{{
                  t('vue_components.spot_a_house.wide_facade')
                }}</span>
              </div>
              <div class="w-full max-w-[283px] pl-4" v-show="selected == 2">
                <p class="text-sm font-normal text-[#414141]">
                  {{ t('vue_components.spot_a_house.wide_facade2') }}
                </p>
                <Houses
                  :selected="selected"
                  :selectedBadgeTitle="selectedBadgeTitle"
                  class="lg:hidden"
                />
              </div>
            </div>
            <div class="flex flex-col items-start space-y-4">
              <div
                class="
                  flex
                  cursor-pointer
                  items-center
                  space-x-2
                  rounded-full
                  py-2
                  px-6
                  ring-[#FEE37D]
                "
                :class="{
                  'bg-[#FECE1F] ': selected == 3,
                  'hover:bg-[#FFFAE9] hover:ring-1': selected != 3,
                }"
                @click="
                  handelSelect(
                    3,
                    t('vue_components.spot_a_house.great_location')
                  )
                "
              >
                <IconCheck />
                <span class="text-sm font-normal text-[#414141] lg:text-lg">{{
                  t('vue_components.spot_a_house.great_location')
                }}</span>
              </div>
              <div class="w-full max-w-[283px] pl-4" v-show="selected == 3">
                <p class="text-sm font-normal text-[#414141]">
                  {{ t('vue_components.spot_a_house.great_location2') }}
                </p>
                <Houses
                  :selected="selected"
                  :selectedBadgeTitle="selectedBadgeTitle"
                  class="lg:hidden"
                />
              </div>
            </div>
            <div class="flex flex-col items-start space-y-4">
              <div
                class="
                  flex
                  cursor-pointer
                  items-center
                  space-x-2
                  rounded-full
                  py-2
                  px-6
                  ring-[#FEE37D]
                "
                :class="{
                  'bg-[#FECE1F] ': selected == 4,
                  'hover:bg-[#FFFAE9] hover:ring-1': selected != 4,
                }"
                @click="
                  handelSelect(
                    4,
                    t('vue_components.spot_a_house.at_least_three_stories_high')
                  )
                "
              >
                <IconCheck />
                <span class="text-sm font-normal text-[#414141] lg:text-lg">{{
                  t('vue_components.spot_a_house.at_least_three_stories_high')
                }}</span>
              </div>
              <div class="w-full max-w-[283px] pl-4" v-show="selected == 4">
                <p class="text-sm font-normal text-[#414141]">
                  {{ t('vue_components.spot_a_house.the_more_stories') }}
                </p>
                <Houses
                  :selected="selected"
                  :selectedBadgeTitle="selectedBadgeTitle"
                  class="lg:hidden"
                />
              </div>
            </div>
            <div class="flex flex-col items-start space-y-4">
              <div
                class="
                  flex
                  cursor-pointer
                  items-center
                  space-x-2
                  rounded-full
                  py-2
                  px-6
                  ring-[#FEE37D]
                "
                :class="{
                  'bg-[#FECE1F] ': selected == 5,
                  'hover:bg-[#FFFAE9] hover:ring-1': selected != 5,
                }"
                @click="handelSelect(5, t('vue_components.spot_a_house.after'))"
              >
                <IconCheck />
                <span
                  class="
                    whitespace-nowrap
                    text-sm
                    font-normal
                    text-[#414141]
                    lg:text-lg
                  "
                  >{{ t('vue_components.spot_a_house.to_be_renovated') }}</span
                >
              </div>
              <div class="w-full max-w-[283px] pl-4" v-show="selected == 5">
                <p class="text-sm font-normal text-[#414141]">
                  {{
                    t(
                      'vue_components.spot_a_house.all_of_out_projects_need_to_be_renovated'
                    )
                  }}
                </p>
                <Houses
                  :selected="selected"
                  :selectedBadgeTitle="selectedBadgeTitle"
                  class="lg:hidden"
                />
              </div>
            </div>
            <div class="flex flex-col items-start space-y-4">
              <div
                class="
                  flex
                  cursor-pointer
                  items-center
                  space-x-2
                  rounded-full
                  bg-[#FFFAE9]
                  py-2
                  px-3
                "
                @click="
                  handelSelect(
                    6,
                    t('vue_components.spot_a_house.300_square_feet')
                  )
                "
              >
                <span
                  class="text-base font-medium text-primary-100 lg:text-lg"
                  >{{
                    t(
                      'vue_components.spot_a_house.more_detailed_characteristics'
                    )
                  }}</span
                >
                <svg
                  :class="{ bottom: selected >= 6 }"
                  width="9"
                  height="19"
                  viewBox="0 0 9 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.910156 17.4201L7.43016 10.9001C8.20016 10.1301 8.20016 8.87008 7.43016 8.10008L0.910156 1.58008"
                    stroke="#1A1A1A"
                    stroke-width="1.3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                class="flex w-full flex-col items-start space-y-4"
                v-show="selected >= 6"
              >
                <div class="flex flex-col items-start space-y-4">
                  <div
                    class="
                      flex
                      cursor-pointer
                      items-center
                      space-x-2
                      rounded-full
                      py-2
                      px-6
                      ring-[#FEE37D]
                    "
                    :class="{
                      'bg-[#FECE1F] ': selected == 6,
                      'hover:bg-[#FFFAE9] hover:ring-1': selected != 6,
                    }"
                    @click="
                      handelSelect(
                        6,
                        t('vue_components.spot_a_house.300_square_feet')
                      )
                    "
                  >
                    <IconCheck />
                    <span
                      class="text-sm font-normal text-[#414141] lg:text-lg"
                      >{{
                        t('vue_components.spot_a_house.300_square_feet')
                      }}</span
                    >
                  </div>
                  <div class="w-full max-w-[283px] pl-4" v-if="selected == 6">
                    <Houses
                      :selected="selected"
                      :selectedBadgeTitle="selectedBadgeTitle"
                      class="lg:hidden"
                    />
                  </div>
                </div>
                <div class="flex flex-col items-start space-y-4">
                  <div
                    class="
                      flex
                      cursor-pointer
                      items-center
                      space-x-2
                      rounded-full
                      py-2
                      px-6
                      ring-[#FEE37D]
                    "
                    :class="{
                      'bg-[#FECE1F] ': selected == 7,
                      'hover:bg-[#FFFAE9] hover:ring-1': selected != 7,
                    }"
                    @click="
                      handelSelect(
                        7,
                        t('vue_components.spot_a_house.at_least_rooms')
                      )
                    "
                  >
                    <IconCheck />
                    <span class="text-sm font-normal text-[#414141] lg:text-lg"
                      >{{ t('vue_components.spot_a_house.at_least_rooms') }}
                    </span>
                  </div>
                  <div class="w-full max-w-[283px] pl-4" v-if="selected == 7">
                    <Houses
                      :selected="selected"
                      :selectedBadgeTitle="selectedBadgeTitle"
                      class="lg:hidden"
                    />
                  </div>
                </div>
                <div class="flex flex-col items-start space-y-4">
                  <div
                    class="
                      flex
                      cursor-pointer
                      items-center
                      space-x-2
                      rounded-full
                      py-2
                      px-6
                      ring-[#FEE37D]
                    "
                    :class="{
                      'bg-[#FECE1F] ': selected == 8,
                      'hover:bg-[#FFFAE9] hover:ring-1': selected != 8,
                    }"
                    @click="
                      handelSelect(
                        8,
                        t('vue_components.spot_a_house.square_meters_room')
                      )
                    "
                  >
                    <IconCheck />
                    <span
                      class="text-sm font-normal text-[#414141] lg:text-lg"
                      >{{
                        t('vue_components.spot_a_house.square_meters_room')
                      }}</span
                    >
                  </div>
                  <div class="w-full max-w-[283px] pl-4" v-if="selected == 8">
                    <Houses
                      :selected="selected"
                      :selectedBadgeTitle="selectedBadgeTitle"
                      class="lg:hidden"
                    />
                  </div>
                </div>
                <div class="flex flex-col items-start space-y-4">
                  <div
                    class="
                      flex
                      cursor-pointer
                      items-center
                      space-x-2
                      rounded-full
                      py-2
                      px-6
                      ring-[#FEE37D]
                    "
                    :class="{
                      'bg-[#FECE1F] ': selected == 9,
                      'hover:bg-[#FFFAE9] hover:ring-1': selected != 9,
                    }"
                    @click="
                      handelSelect(
                        9,
                        t('vue_components.spot_a_house.garden_or_terrace')
                      )
                    "
                  >
                    <IconCheck />
                    <span
                      class="text-sm font-normal text-[#414141] lg:text-lg"
                      >{{
                        t('vue_components.spot_a_house.garden_or_terrace')
                      }}</span
                    >
                  </div>
                  <div class="w-full max-w-[283px] pl-4" v-if="selected == 9">
                    <Houses
                      :selected="selected"
                      :selectedBadgeTitle="selectedBadgeTitle"
                      class="lg:hidden"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Houses
          :selected="selected"
          :selectedBadgeTitle="selectedBadgeTitle"
          class="hidden lg:flex"
        />
      </div>
    </div>
  </section>
</template>

<script>
import bgImage from 'assets/images/spot_a_house/secondsection.png';
import IconCheck from '../Icons/IconCheck.vue';
import Houses from '../Houses.vue';

export default {
  data() {
    return {
      bgImage,
      selected: 1,
      selectedBadgeTitle: this.t('vue_components.spot_a_house.one_doorbell'),
    };
  },
  methods: {
    goUp() {
      window.scrollTo({
        top: document.getElementById('WhatImages').offsetTop,
        behavior: 'smooth',
      });
    },
    handelSelect(index, text) {
      if (this.selected === index) {
        this.selected = 1;
        return;
      }

      this.selected = index;
      this.selectedBadgeTitle = text;
      if (window.innerWidth > 1024) {
        this.goUp();
      }
    },
  },
  components: {
    IconCheck,
    Houses,
  },
};
</script>
