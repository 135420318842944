<template>
  <form
    v-if="!submitted"
    class="flex w-full flex-col items-start space-y-4"
    @submit.prevent="submitForm"
  >
    <div class="flex w-full flex-col items-start space-y-2">
      <label
        for="enquiry_first_name"
        class="text-sm font-medium text-black lg:text-lg"
        >First name</label
      >
      <input
        id="enquiry_first_name"
        v-model="v$.form.first_name.$model"
        placeholder="First name"
        type="text"
        class="
          bg-G10
          rounded-md
          h-[57px]
          w-full
          px-4
          py-3
          text-base
          font-normal
          placeholder-E100
          outline-none
        "
      />
      <div v-if="v$.form.first_name.$errors[0]" class="text-red">
        {{ v$.form.first_name.$errors[0].$message }}
      </div>
    </div>
    <div class="flex w-full flex-col items-start space-y-2">
      <label
        for="enquiry_last_name"
        class="text-sm font-medium text-black lg:text-lg"
        >Last name</label
      >
      <input
        id="enquiry_last_name"
        v-model="v$.form.last_name.$model"
        placeholder="Last name"
        type="text"
        class="
          bg-G10
          rounded-md
          h-[57px]
          w-full
          px-4
          py-3
          text-base
          font-normal
          placeholder-E100
          outline-none
        "
      />
      <div v-if="v$.form.last_name.$errors[0]" class="text-red">
        {{ v$.form.last_name.$errors[0].$message }}
      </div>
    </div>
    <div class="flex w-full flex-col items-start space-y-2">
      <label
        for="enquiry_email"
        class="text-sm font-medium text-black lg:text-lg"
        >Email</label
      >
      <input
        id="enquiry_email"
        v-model="v$.form.email.$model"
        placeholder="Email"
        type="text"
        class="
          bg-G10
          rounded-md
          h-[57px]
          w-full
          px-4
          py-3
          text-base
          font-normal
          placeholder-E100
          outline-none
        "
      />
      <div v-if="v$.form.email.$errors[0]" class="text-red">
        {{ v$.form.email.$errors[0].$message }}
      </div>
    </div>
    <div class="flex w-full flex-col items-center space-y-2">
      <button
        type="submit"
        class="
          h-[44px]
          w-full
          rounded-[5px]
          bg-[#FECE1F]
          text-center text-sm
          font-medium
          text-primary-100
          hover:bg-opacity-60
          hover:text-opacity-60
          lg:h-[60px]
          lg:w-[159px]
          lg:text-lg
          lg:font-bold
        "
      >
        Send an mail
      </button>
      <span
        class="text-center font-sans text-base font-normal text-primary-100"
      >
        Your email will be used solely for us to reach out to you, no extra
        promotional emails will be sent to you.
      </span>
    </div>
  </form>
  <div v-else>Enquiry has been sent!</div>
</template>
<script>
import axios from 'axios';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';

export default {
  setup: () => ({ v$: useVuelidate() }),
  data: () => ({
    form: {
      first_name: '',
      last_name: '',
      email: '',
    },
    submitted: false,
  }),
  validations() {
    return {
      form: {
        first_name: { required },
        last_name: { required },
        email: { required, email },
      },
    };
  },
  methods: {
    async submitForm() {
      const isValid = await this.v$.$validate();
      if (!isValid) return;

      try {
        await axios.post(
          '/api/v1/owner_enquiries',
          { enquiry: this.form },
          {
            withCredentials: true,
          }
        );

        this.submitted = true;
      } catch (error) {
        // this.$toast.showMessage({
        //   message: error,
        //   type: 'error',
        // })
      }
    },
  },
};
</script>
