/* eslint-disable global-require */
/* eslint-disable */

import { Controller } from 'stimulus';
import I18n from 'src/vendor/i18n-js.js.erb';
import flash from 'src/services/flash.service';

export default class extends Controller {
  static targets = ['link', 'icon'];

  connect() {
    if (this.watching) {
      this.iconTarget.title = this.linkTarget.dataset.removeLabel;
      this.iconTarget.src = require('assets/images/icons/watchlist/heart.svg');
    } else {
      this.iconTarget.title = this.linkTarget.dataset.addLabel;
      this.iconTarget.src = require('assets/images/icons/watchlist/heart_off.svg');
    }

    this.iconTarget.onload = () => {
      this.iconTarget.width = 32;
      this.iconTarget.height = 32;
      this.iconTarget.style.display = 'block';
    };
  }

  onLinkClick(event) {
    event.preventDefault();

    if (!this.watching) {
      this.add();
    } else {
      this.remove();
    }
  }

  add() {
    if (this.linkTarget.dataset.signedIn !== 'true') {
      window.location = this.linkTarget.dataset.fallbackUrl;
      return;
    }

    fetch(this.linkTarget.dataset.addUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRF-Token': jQuery.rails.csrfToken(), // eslint-disable-line no-undef
      },
    })
      .then((response) => {
        if (response.status >= 400) {
          throw response.statusText;
        }

        return response.text();
      })
      .then(() => {
        this.iconTarget.title = I18n.t(
          `components.${this.element.dataset.watchableType}_card.remove_from_watchlist`
        );
        this.iconTarget.src = require('assets/images/icons/watchlist/heart.svg');
        this.linkTarget.dataset.watching = true;
        flash(
          'notice',
          I18n.t(
            `components.watchlist.this_${this.element.dataset.watchableType}_has_been_added_to_watchlist`
          )
        );
      })
      .catch((errorMessage) => {
        flash('alert', errorMessage);
      });
  }

  remove() {
    fetch(this.linkTarget.dataset.removeUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRF-Token': jQuery.rails.csrfToken(), // eslint-disable-line no-undef,
      },
    })
      .then((response) => {
        if (response.status >= 400) {
          throw response.statusText;
        }

        return response.text();
      })
      .then(() => {
        this.iconTarget.title = I18n.t(
          `components.${this.element.dataset.watchableType}_card.add_to_watchlist`
        );
        this.iconTarget.src = require('assets/images/icons/watchlist/heart_off.svg');
        this.linkTarget.dataset.watching = false;
        flash(
          'notice',
          I18n.t(
            `components.watchlist.this_${this.element.dataset.watchableType}_has_been_removed_from_watchlist`
          )
        );
      })
      .catch((errorMessage) => {
        flash('alert', errorMessage);
      });
  }

  get watching() {
    return this.linkTarget.dataset.watching === 'true';
  }
}
