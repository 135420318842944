import { Controller } from 'stimulus';
import Sortable from 'sortablejs';

export default class extends Controller {
  static targets = ['sortable'];

  connect() {
    this.initSortable();
  }

  initSortable() {
    Sortable.create(this.sortableTarget, {
      onEnd: this.onEnd.bind(this),
    });
  }

  onEnd(e) {
    const order = Array.from(this.sortableTarget.children).map(
      (child) => child.dataset.id
    );

    const url = this.sortableTarget.dataset.url;

    fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({ showcase_photos: order })
    })
      .then((response) => response.json())
      .then(data => {
        if (data.success) {
          console.log('Order updated successfully');
        } else {
          console.error('Failed to update order');
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }
}
