import React from 'react';
import { TableHeaderCellProps } from './types';
import { composeClassName } from '@/utils/util_helpers';

const TableHeaderCell: React.FC<TableHeaderCellProps> = ({
  children,
  sortingOrder,
  onClick,
  className,
  ...props
}) => {
  return (
    <th
      scope="col"
      className={composeClassName(
        'h-[54px] whitespace-nowrap px-1 align-middle',
        className && className
      )}
      onClick={onClick}
      {...props}
    >
      {children &&
        React.Children.map(
          children,
          (child: React.ReactElement | React.ReactNode) => {
            return {
              // @ts-ignore
              ...child,
              props: {
                // @ts-ignore
                ...child.props,
                sortingOrder,
              },
            };
          }
        )}
    </th>
  );
};

export { TableHeaderCell };
