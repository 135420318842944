import React from 'react';
import { useTranslation } from 'react-i18next';
import { SectionTitle } from '@/features/marketplace/components/section-title';
import { DEFAULT_CONTENT_WIDTH } from '@/features/marketplace/constants';
import { composeClassName } from '@/utils/util_helpers';
import { Button } from '@/components/design-system/atoms/button';
import { useWindowDimensions } from '@/hooks/use-window-dimensions';

const backgroundImage = require(`@assets/images/marketplace/about-us/section-3-bg.svg`);

type PreviewType = 'logo' | 'photo';

type PreviewAsset = {
  link: string;
  imgSrc: string;
  fileName: string;
};

const logos: Array<PreviewAsset> = [
  {
    link: 'https://colocmanagement.sharepoint.com/:f:/s/colochousing/EsJ11rX4QdBMgS-9m5kdcQ0BK0rM-QgeeGMnIg4D_j-1Qw?e=3qDv0p',
    imgSrc: 'color-coloc',
    fileName: 'color_logo_pack',
  },
  {
    link: 'https://colocmanagement.sharepoint.com/:f:/s/colochousing/EmBYKf9z6v1Gnw82P_IWYNQBc-d6EWaev36jgVIMPhtBNQ?e=EXNbOk',
    imgSrc: 'bw-coloc',
    fileName: 'bw_logo_pack',
  },
];

const pictures: Array<PreviewAsset> = [
  {
    link: 'https://colocmanagement.sharepoint.com/:i:/s/colochousing/EQDUAd65o7BMliU1Jg7K6QkBgDspp_dF3INa0B7nR89K8Q?e=7ftYbN',
    imgSrc: 'bedroom_sint_michiels_2',
    fileName: 'bedroom_sint_michiels_2.jpg',
  },
  {
    link: 'https://colocmanagement.sharepoint.com/:i:/s/colochousing/ERuXvR5KUDJEjF5I3liGZjMBtSXO3yMOCvVLPjVRspi6gg?e=RRWaOk',
    imgSrc: 'bedroom_sint_michiels',
    fileName: 'bedroom_sint_michiels.jpg',
  },
  {
    link: 'https://colocmanagement.sharepoint.com/:i:/s/colochousing/ERPcbbogYGlBopMf2bS3FK0BUrIX0FF-6IjK3fId9LZOOg?e=Wqdfzf',
    imgSrc: 'front_maria_theresia',
    fileName: 'front_maria_theresia.jpg',
  },
  {
    link: 'https://colocmanagement.sharepoint.com/:i:/s/colochousing/Edc1eGIJAwdAseaLB-1lB9sBkmbFHRWVap1RA9kZKMEOag?e=GngQEC',
    imgSrc: 'kitchen_francois_benard',
    fileName: 'kitchen_francois_benard.jpg',
  },
  {
    link: 'https://colocmanagement.sharepoint.com/:i:/s/colochousing/Ed5BXkCIWjBLqiRlnG22XvUBLOAvO0g7_-8CCVTHYVeZsA?e=4bFts5',
    imgSrc: 'living_room_francois_benard',
    fileName: 'living_room_francois_benard.jpg',
  },
  {
    link: 'https://colocmanagement.sharepoint.com/:i:/s/colochousing/EQrVjhlJ8dVEhwty_vbb44MBterlkRAT4bOt9bfLO4_fjw?e=mHwBZk',
    imgSrc: 'living_room_ledeganck',
    fileName: 'living_room_ledeganck.jpg',
  },
  {
    link: 'https://colocmanagement.sharepoint.com/:i:/s/colochousing/EVv_eVK0vvdLsAlYkHS3MlEBgcn9GV_l6_d4u3BJi0-QOQ?e=SEIcGP',
    imgSrc: 'living_room_revolution',
    fileName: 'living_room_revolution.jpg',
  },
  {
    link: 'https://colocmanagement.sharepoint.com/:i:/s/colochousing/ERgoPVAU8v1JiCc9MzgPYikBHgvtzf5a0ETJDETQ7T39bg?e=RHPeik',
    imgSrc: 'living_room_zandpoort',
    fileName: 'living_room_zandpoort.jpg',
  },
];

const allFiles =
  'https://colocmanagement.sharepoint.com/:f:/s/colochousing/EvEHc0DtMwtMvE27UvDoHLoB4m3EEG7KL9i0owo4fj4NEQ?e=Qcam9F';

const PreviewItem: React.FC<{ asset: PreviewAsset }> = ({ asset }) => {
  return (
    <div
      className={composeClassName(
        'flex w-full flex-col gap-3 rounded-lg border-[1px] border-solid border-E50 p-4',
        'sm:w-[45%]',
        'lg:w-[30%]'
      )}
    >
      <picture>
        <source
          srcSet={`${require(`@assets/images/marketplace/press/asset_previews/${asset.imgSrc}.webp`)}`}
          type="image/webp"
        />
        <img
          src={require(`@assets/images/marketplace/press/asset_previews/${asset.imgSrc}`)}
          alt={asset.fileName}
          className="aspect-video h-auto w-full rounded object-cover"
        />
      </picture>
      <div className="flex items-center justify-between py-3 px-4">
        <span className="truncate pr-3 text-lead2 text-text-primary">
          {asset.fileName}
        </span>
        <Button
          variant="ghost"
          iconName="document-download"
          size="large"
          type="button"
          onClick={() => window.open(asset.link, '_blank')}
        />
      </div>
    </div>
  );
};

const PreviewSection: React.FC<{ type: PreviewType }> = ({ type }) => {
  const { t } = useTranslation();
  const sourceToMap = type === 'logo' ? logos : pictures;

  return (
    <div className="w-full">
      <h3 className="text-h4 font-medium text-text-primary">
        {t(`js.features.marketplace.press.section3.${type}_title`)}
      </h3>
      <div className="mt-7 flex w-full flex-wrap gap-4">
        {sourceToMap.map((asset) => {
          return <PreviewItem key={asset.fileName} asset={asset} />;
        })}
      </div>
    </div>
  );
};

const ThirdSection = () => {
  const { screenIsSmallerThan } = useWindowDimensions();
  const { t } = useTranslation();

  return (
    <section className="bg-[#FBFBFB]">
      <div
        className={composeClassName('h-10 w-full', 'sm:h-[12rem]')}
        style={{
          backgroundSize: '100% auto',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${backgroundImage})`,
        }}
      />
      <div
        className={composeClassName(
          DEFAULT_CONTENT_WIDTH,
          'm-auto pb-8',
          'md:pb-[10.5rem]',
          'lg:pb-[14rem]'
        )}
      >
        <SectionTitle>{'Press and \n media kit'}</SectionTitle>
        <div
          className={composeClassName(
            'mt-8 flex flex-wrap justify-between gap-x-4 gap-y-7'
          )}
        >
          <PreviewSection type="logo" />
          <PreviewSection type="photo" />
        </div>
        <Button
          onClick={() => window.open(allFiles, '_blank')}
          type="button"
          size="large"
          variant="secondary"
          iconName="document-download"
          className={composeClassName(
            'mt-4',
            'sm:mt-7',
            screenIsSmallerThan('sm') && 'w-full'
          )}
        >
          {t('js.features.marketplace.press.section3.download_all_button') ??
            ''}
        </Button>
      </div>
    </section>
  );
};

export { ThirdSection };
