import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SlideOver } from '@/components/design-system/molecules/slide-over';
import { DiscussionThreadInput } from '../discussion-thread-input';
import { useDiscussionThreadProviderContext } from '../../hooks/use-discussion-thread';
import { DiscussionThreadMessageList } from '../discussion-thread-message-list';
import { useAuthContext } from '@/hooks/use-auth';
import { useDeleteDiscussionThreadMessage } from '../../hooks/use-delete-thread-message';

const DiscussionThreadContent = () => {
  const {
    showDiscussionThread,
    closeDiscussionThread,
    discussionThreadHasBeenCreated,
  } = useDiscussionThreadProviderContext()!;
  const { mutate: deleteDiscussionThreadMessage } =
    useDeleteDiscussionThreadMessage();
  const { user } = useAuthContext()!;

  const { t } = useTranslation();

  const {
    fetch: { data, isLoading },
  } = useDiscussionThreadProviderContext()!;

  useEffect(() => {}, [data?.data.messages]);

  return (
    <SlideOver
      show={showDiscussionThread.show}
      className="!z-[60]"
      title="Notes"
      onClose={() => closeDiscussionThread()}
    >
      <SlideOver.SlideOverContent
        className="items-center gap-5"
        scrollToEnd={true}
      >
        <DiscussionThreadMessageList
          messages={data?.data.messages ?? []}
          isLoading={isLoading}
          currentUserId={user?.id ?? 1}
          onDelete={(messageId: number) =>
            deleteDiscussionThreadMessage.mutate({ id: messageId })
          }
        />
      </SlideOver.SlideOverContent>
      <SlideOver.SlideOverEmptyState
        show={
          data?.data.messages.length === 0 || !discussionThreadHasBeenCreated
        }
        illustration="EmptyBox"
        title={t(
          'js.features.shared.discussion_thread.empty_state.empty_state_title'
        )}
      />
      <SlideOver.SlideOverBottomContent className="w-full px-5 pb-5">
        <DiscussionThreadInput />
      </SlideOver.SlideOverBottomContent>
    </SlideOver>
  );
};

export { DiscussionThreadContent };
