import React from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { composeClassName } from '@/utils/util_helpers';
import { DEFAULT_CONTENT_WIDTH } from '@/features/marketplace/constants';
import { textMultiLine } from '@/utils/util_helpers/formatting/text_multi_line';
import { Testimonial } from './testimonial';
import { useWindowDimensions } from '@/hooks/use-window-dimensions';

const BgShape = () => {
  const { screenIsLargeOrEqualThan } = useWindowDimensions();

  return (
    <div
      className={composeClassName(
        'absolute right-[-3.5rem] bottom-[-5rem] hidden',
        'sm:block',
        'xl:right-[-4rem] xl:bottom-[-9rem]',
        '2xl:hidden'
      )}
    >
      <ReactSVG
        src={require(`@assets/images/marketplace/about-us/bg-shape-01.svg`)}
        beforeInjection={(svg) => {
          svg.setAttribute(
            'width',
            screenIsLargeOrEqualThan('xl') ? '237' : '185'
          );
          svg.setAttribute(
            'height',
            screenIsLargeOrEqualThan('xl') ? '205' : '160'
          );
        }}
      />
    </div>
  );
};

const SecondSection = () => {
  const { t } = useTranslation();

  return (
    <section className={composeClassName('relative')}>
      <div
        className={composeClassName(
          DEFAULT_CONTENT_WIDTH,
          'm-auto flex flex-col gap-5 pt-[9rem] pb-[5.6rem]',
          'sm:gap-7 sm:pb-[13rem]',
          'lg:pb-[17rem]'
        )}
      >
        <h2
          className={composeClassName(
            'text-[3.2rem] font-extrabold text-text-primary',
            'sm:text-[4.8rem]'
          )}
        >
          {t('js.features.marketplace.about_us.section2.title')}
        </h2>
        <span
          className={composeClassName(
            'text-body font-medium text-text-secondary',
            'sm:text-lead2'
          )}
        >
          {textMultiLine(t('js.features.marketplace.about_us.section2.text'))}
        </span>
        <Testimonial />
        <BgShape />
      </div>
    </section>
  );
};

export { SecondSection };
