import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Listbox } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { IconSaxIcon } from '@/components/design-system/atoms/icon';
import { TablePaginationProps } from './types';
import { paginationToResultsSummaryType } from '@/utils/util_helpers/coloc_api_helpers';
import { ResultsSummary } from '@/types/global';
import { theme } from '@/utils/util_theme';

const TablePagination: React.FC<TablePaginationProps> = ({
  totalItems,
  totalPages,
  currentPage,
  pageLimit = 30,
  disabled,
  onPageLimitChange,
  onPageChange,
}) => {
  const { t } = useTranslation();
  const [paginationSummary, setPaginationSummary] = useState<ResultsSummary>();
  const [goFirstButtonDisabled, setGoFirstButtonDisabled] = useState(false);
  const [goLastButtonDisabled, setGoLastButtonDisabled] = useState(false);
  const [previousButtonDisabled, setPreviousButtonDisabled] = useState(false);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
  const internalPageLimit = useMemo(() => {
    return Math.round(pageLimit / 10) * 10;
  }, [pageLimit]);

  const updatePageLimit = useCallback(
    (selectedValue: number) => {
      onPageLimitChange(selectedValue);
    },
    [onPageLimitChange]
  );

  useEffect(() => {
    setPaginationSummary(
      paginationToResultsSummaryType({
        pagination: {
          page_limit: pageLimit,
          total: totalItems,
          total_pages: totalPages,
          page: currentPage,
        },
      })
    );
  }, [totalItems, totalPages, pageLimit, currentPage]);

  useEffect(() => {
    if (currentPage <= 1) {
      setPreviousButtonDisabled(true);
      setGoFirstButtonDisabled(true);
    } else {
      setPreviousButtonDisabled(false);
      setGoFirstButtonDisabled(false);
    }

    if (currentPage >= totalPages) {
      setNextButtonDisabled(true);
      setGoLastButtonDisabled(true);
    } else {
      setNextButtonDisabled(false);
      setGoLastButtonDisabled(false);
    }

    if (disabled) {
      setGoFirstButtonDisabled(true);
      setGoLastButtonDisabled(true);
      setPreviousButtonDisabled(true);
      setNextButtonDisabled(true);
    }
  }, [currentPage, totalPages, disabled]);

  return (
    <div className="sticky left-[0px] my-4 flex w-full flex-1 flex-row items-center justify-center gap-8 self-center">
      <div className="flex items-center gap-3">
        <span className="box text-body text-text-primary">
          {t('js.components.table.pagination.rows_per_page')}
        </span>
        <Listbox
          as="div"
          value={pageLimit}
          onChange={updatePageLimit}
          className="scrollbar-hide relative"
        >
          <Listbox.Button className="relative flex rounded-sm bg-E30 py-1 px-2">
            <div className="flex items-center gap-1">
              <span className="box text-body text-text-tertiary">
                {internalPageLimit}
              </span>
              <IconSaxIcon name="arrow-down" size={12} />
            </div>
          </Listbox.Button>
          <Listbox.Options className="scrollbar-hide absolute top-[-64px] z-10 inline-block h-[200%] max-h-[200px] w-full overflow-y-scroll rounded-sm bg-white py-2 shadow-medium">
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <Listbox.Option
                key={pageSize}
                value={pageSize}
                className="cursor-pointer text-center text-body text-text-secondary hover:bg-E30"
              >
                {pageSize}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Listbox>

        <span className="box text-body text-text-primary">{`${
          paginationSummary?.fromItem ?? 0
        }-${paginationSummary?.untilItem ?? 0} ${t(
          'js.components.table.pagination.of'
        )} ${paginationSummary?.totalItems ?? 0}`}</span>
      </div>
      <div className="flex items-center gap-7">
        <div className="flex items-center gap-5">
          <button
            type="button"
            disabled={goFirstButtonDisabled}
            onClick={() => onPageChange(1)}
          >
            <IconSaxIcon
              name="arrow-previous"
              strokeWidth={1.3}
              strokeColor={
                goFirstButtonDisabled
                  ? theme?.colors.E75
                  : theme.colors.text.primary
              }
            />
          </button>
          <button
            type="button"
            onClick={() => onPageChange(currentPage - 1)}
            disabled={previousButtonDisabled}
          >
            <IconSaxIcon
              name="arrow-left-1"
              strokeWidth={1.3}
              strokeColor={
                previousButtonDisabled
                  ? theme?.colors.E75
                  : theme.colors.text.primary
              }
            />
          </button>
        </div>
        <div className="flex items-center gap-5">
          <button
            onClick={() => onPageChange(currentPage + 1)}
            type="button"
            disabled={nextButtonDisabled}
          >
            <IconSaxIcon
              name="arrow-right-1"
              strokeWidth={1.3}
              strokeColor={
                nextButtonDisabled
                  ? theme?.colors.E75
                  : theme.colors.text.primary
              }
            />
          </button>
          <button
            onClick={() => onPageChange(totalPages)}
            type="button"
            disabled={goLastButtonDisabled}
          >
            <IconSaxIcon
              name="arrow-next"
              strokeWidth={1.3}
              strokeColor={
                goLastButtonDisabled
                  ? theme?.colors.E75
                  : theme.colors.text.primary
              }
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export { TablePagination };
