<template>
  <button
    :class="buttonStyling"
    :disabled="disabled || loading"
    @click="handleClick"
  >
    <div :class="buttonContainerStyling">
      <template v-if="!loading">
        <SocialIcon
          v-if="iconName && iconType === 'social'"
          :name="iconName"
          :size="size === 'large' ? 24 : 18"
          :variant="showOriginalIcon ? 'original' : 'black-white'"
          :class="[!showOriginalIcon && 'nested-social-icon']"
        />
        <IconSaxIconVue
          v-if="iconName && iconType === 'icon-sax'"
          :name="iconName"
          :size="size === 'large' ? 24 : 18"
          class="nested-sax-icon"
        />
      </template>
      <lottie-player
        v-if="loading"
        autoplay
        loop
        :style="
          size === 'large'
            ? {
                width: `${theme.spacing[4]}`,
                height: `${theme.spacing[4]}`,
              }
            : { width: '18px', height: '18px' }
        "
        src="https://lottie.host/a6649ad2-1ea1-4370-acc2-c7d308a1df0d/dKyZg3uboM.json"
        speed="1"
        background="transparent"
      ></lottie-player>
      <p
        v-if="(text && !loading) || (loadingText && loading)"
        :class="buttonTextStyling"
        :disabled="disabled"
      >
        {{ buttonText }}
      </p>
    </div>
  </button>
</template>

<script>
import './Button.scss';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../../../../../config/tailwind.config';
import SocialIcons from '../icon/social-icon/socialIconValidator';
import IconSaxIcons from '../icon/iconsax-icon/iconSaxValidator';
import SocialIcon from '../icon/social-icon/SocialIcon.vue';
import IconSaxIconVue from '../icon/iconsax-icon/IconSaxIcon.vue';

const fullConfig = resolveConfig(tailwindConfig);

export default {
  components: { SocialIcon, IconSaxIconVue },
  props: {
    /**
     * Icon to show. Restricted to IconSax and Social icons
     */
    iconName: {
      type: String,
      validator(value) {
        return Object.prototype.hasOwnProperty.call(
          { ...SocialIcons, ...IconSaxIcons },
          value
        );
      },
    },
    /**
     * Where to align the icon. By default left alligned. Will be center in case no text is provided.
     *
     * Options: left, right
     */
    iconAlignment: {
      type: String,
      default: 'left',
      validator(value) {
        return ['left', 'right'].includes(value);
      },
    },
    /**
     * To show icon in orginal colors. By default the icon will have a stroke fill that matches the text in the button.
     */
    showOriginalIcon: {
      type: Boolean,
      default: false,
    },
    /**
     * Variant of the button.
     *
     * Options: primary, secondary, tertiary, link, ghost
     */
    variant: {
      type: String,
      default: 'primary',
      validator(value) {
        return ['primary', 'secondary', 'tertiary', 'link', 'ghost'].includes(
          value
        );
      },
    },
    /**
     * The size of the Button. Defaults to medium.
     *
     * Options: small (34px height), medium(40 px height), large(48 px height)
     */
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        return ['small', 'medium', 'large'].includes(value);
      },
    },
    /**
     * Button disabled or not
     *
     * Hover and pressed state are handled internaly
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Button showing loading state
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Text to show in the button.
     */
    text: {
      type: String,
    },
    /**
     * Button showing loading state
     */
    loadingText: {
      type: String,
    },
  },
  data: () => ({ theme: fullConfig.theme }),
  computed: {
    buttonText() {
      if (this.text && !this.loading) {
        return this.text;
      }

      if (this.loading && this.loadingText) {
        return this.loadingText;
      }

      return undefined;
    },
    buttonStyling() {
      return [
        'ui-button',
        `ui-button--size-${this.size}`,
        `ui-button--variant-${this.variant}`,
      ];
    },
    buttonContainerStyling() {
      return [
        'button__container',
        `button__container--${this.size}`,
        this.iconAlignment === 'right' && 'button__container--right',
        this.variant === 'link' &&
          !this.iconName &&
          'ui-button--variant-link-show-underline',
        this.iconName &&
          !this.text &&
          `button__container--only-icon-${this.size}`,
      ];
    },
    buttonTextStyling() {
      return ['button__text', `button__text--${this.size}`];
    },
    iconType() {
      if (Object.prototype.hasOwnProperty.call(SocialIcons, this.iconName)) {
        return 'social';
      }

      if (Object.prototype.hasOwnProperty.call(IconSaxIcons, this.iconName)) {
        return 'icon-sax';
      }

      return null;
    },
  },
  methods: {
    handleClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>
