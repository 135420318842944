import React from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Button } from '@/components/design-system/atoms/button';
import { DownloadRentReportCellContentProps } from './types';
import { useDownloadFile } from '@/hooks/use-download-file';

const DownloadRentReportCellContent: React.FC<DownloadRentReportCellContentProps> =
  ({ report }) => {
    const { t } = useTranslation();
    const [downloadingFile, downloadFile] = useDownloadFile({
      url: report.download_link,
      filename: `rent_report_${report.house.name}_${format(
        new Date(report.date),
        'MMMyy'
      )}`,
      filetype: 'pdf',
    });

    return (
      <Button
        variant="link"
        iconName="document-download"
        size="small"
        loading={downloadingFile}
        onClick={downloadFile}
      >
        {t('js.features.admin.rent_report.download_report_cell') ?? ''}
      </Button>
    );
  };

export { DownloadRentReportCellContent };
