import React, { useMemo } from 'react';
import { ColocIcon } from '@/components/design-system/atoms/icon';
import { useDiscussionThreadProviderContext } from '@/features/shared/discussion-threads/hooks/use-discussion-thread';
import { NotificationType } from '../types';
import { prettyDateTime } from '@/utils/util_helpers/formatting/dates';

const NotificationCard = ({
  notification,
}: {
  notification: NotificationType;
}) => {
  const { actor, action } = notification;
  const actorName = `${actor?.first_name} ${actor?.last_name}`;

  const { openDiscussionThread } = useDiscussionThreadProviderContext()!;

  const handleClick = () => {
    const { notifiable_type } = notification;
    if (notifiable_type === 'DiscussionMessage') {
      openDiscussionThread({
        type: 'RoomAssignment',
        discussionThreadId: notification.notifiable.thread_id
      });
    }
  };
  const isRead = useMemo(()=>
    Boolean(notification.read_at)
  , [notification.read_at]);
  const prettyDateTimeFormat = useMemo(()=>
    prettyDateTime(new Date(notification.created_at))
  , [notification.created_at]);
  return (
    <div
      className={`overflow-visible border-b border-solid border-gray-300 p-5 ${
        isRead ? '' : 'bg-R50'
      }`}
    >
      <div className="flex flex-row items-center justify-center gap-3">
        <div className="relative flex items-center justify-center rounded-full bg-N100 p-2">
          <ColocIcon name="bell-outline" size={18} />
          {!isRead && (
            <div
              className="absolute h-[8px] w-[8px] rounded-full bg-C300"
              style={{
                top: '5%',
                left: '5%',
              }}
            />
          )}
        </div>
        <div className="flex-grow">
          <h1 className="text-lead2 font-semibold text-text-primary">
            {actorName} {action} you in a comment
          </h1>
          <p className="text-body text-text-primaryMedium">
            Please click
            &nbsp;
            <button className="text-primary underline" onClick={() => handleClick()}>
              here
            </button>
            &nbsp;
            to read the comment
          </p>
          <p className="text-body text-text-primaryMedium mt-3">
            {prettyDateTimeFormat}
          </p>
        </div>

      </div>
    </div>
  );
};

export { NotificationCard };
