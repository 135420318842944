import React from 'react';
import { useTranslation } from 'react-i18next';
import { RoomAssignmentApplicantsOverviewCellContentProps } from './types';
import { Button } from '@/components/design-system/atoms/button';

const RoomAssignmentApplicantsOverviewCellContent: React.FC<RoomAssignmentApplicantsOverviewCellContentProps> =
  ({ base_url, replacement, applicants, showApplicants }) => {
    const { t } = useTranslation();

    if (!replacement) {
      return (
        <Button variant="link" iconName="eye" onClick={() => showApplicants()}>
          {t(
            'js.features.admin.contracts.room_assignment.table_cells.applicant_overview.num_applicants',
            { num_applicants: applicants.length }
          ) ?? ''}
        </Button>
      );
    }

    return (
      <div className="inline-flex flex-col">
        <a
          target="_blank"
          rel="noreferrer"
          className="text-right text-body font-medium text-text-primaryHeavy"
          href={`${base_url}/admin/contracts/${replacement?.contract_id}`}
        >
          {replacement.name}
        </a>
        <Button
          className="inline-flex text-right text-[12px]"
          variant="link"
          onClick={() => showApplicants()}
        >
          {t(
            'js.features.admin.contracts.room_assignment.table_cells.applicant_overview.num_applicant_chosen_replacement',
            { num_applicants: applicants.length }
          ) ?? ''}
        </Button>
      </div>
    );
  };

export { RoomAssignmentApplicantsOverviewCellContent };
