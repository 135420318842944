import { useMutation } from '@tanstack/react-query';

import React, { createContext, useContext, useState } from 'react';
import { endOfMonth } from 'date-fns';
import { generateRentReports } from '@/services/coloc-api/admin';
import { showToast } from '@/services/toast-service';
import { RentReport } from '@/features/admin/rents/types';
import { useGetRentReportListContext } from '../use-get-rent-report-list';

const useRegenerateRentReports = () => {
  const { setRowSelection, updateRentReportsInCache } =
    useGetRentReportListContext()!;
  const [
    showRegenerateRentReportConfirmationModal,
    toggleShowRegenerateRentReportConfirmationModal,
  ] = useState<{
    show: boolean;
    reports: Array<RentReport>;
  }>({
    show: false,
    reports: [],
  });

  const mutate = useMutation({
    mutationKey: ['generateRentReports'],
    mutationFn: (reports: Array<RentReport>) => {
      return generateRentReports({
        house_ids: reports.map((report) => String(report.house.id)),
        date: endOfMonth(new Date(reports[0].date)),
      });
    },
    onSuccess: (data) => {
      setRowSelection({});
      toggleShowRegenerateRentReportConfirmationModal({
        show: false,
        reports: [],
      });
      updateRentReportsInCache(data.data.data);

      showToast.success({});
    },
    onError: () => {
      toggleShowRegenerateRentReportConfirmationModal({
        show: false,
        reports: [],
      });
      showToast.error({});
    },
  });

  return {
    showRegenerateRentReportConfirmationModal,
    toggleShowRegenerateRentReportConfirmationModal,
    mutate,
  };
};

const RegenerateRentReportContext = createContext<
  ReturnType<typeof useRegenerateRentReports> | undefined
>(undefined);

const RegenerateRentReportContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const hook = useRegenerateRentReports();

  return (
    <RegenerateRentReportContext.Provider value={hook}>
      {children}
    </RegenerateRentReportContext.Provider>
  );
};

const useRegenerateRenReportContext = () => {
  const context = useContext(RegenerateRentReportContext);

  if (context === null) {
    throw new Error(
      'useRegenerateRenReportContext must be used within a RegenerateRentReportContextProvider'
    );
  }

  return context;
};

export { RegenerateRentReportContextProvider, useRegenerateRenReportContext };
