import { ColocAPIPaginationResponse } from '@/lib/coloc-api';
import { ResultsSummary } from '@/types/global';

const paginationToResultsSummaryType = (
  pagination: ColocAPIPaginationResponse | undefined
): ResultsSummary => {
  if (pagination !== undefined) {
    const from =
      pagination.pagination.page_limit * (pagination.pagination.page - 1) + 1;
    let until = pagination.pagination.page_limit * pagination.pagination.page;
    if (until >= pagination.pagination.total) {
      until = pagination.pagination.total;
    }
    return {
      totalItems: pagination.pagination.total,
      untilItem: until,
      fromItem: from,
    };
  }

  return {
    totalItems: 0,
    untilItem: 0,
    fromItem: 0,
  };
};

export { paginationToResultsSummaryType };
