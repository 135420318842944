/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useContext, useState } from 'react';
import { DropDownProps } from '../../types';

type DropDownContextProps = Pick<
  DropDownProps,
  | 'onTextInputChange'
  | 'displayValue'
  | 'disabled'
  | 'multiple'
  | 'placeholder'
  | 'value'
  | 'invalid'
  | 'clearable'
  | 'onChange'
> & {
  id: string;
  optionsOpen: boolean;
  setOptionsOpen: (newValue: boolean) => void;
  inputHovered: boolean;
  setInputHovered: (newValue: boolean) => void;
  inputFocused: boolean;
  setInputFocused: (newValue: boolean) => void;
};

type DropDownContextProviderProps = Omit<
  DropDownContextProps,
  | 'inputFocused'
  | 'setInputFocused'
  | 'inputHovered'
  | 'setInputHovered'
  | 'optionsOpen'
  | 'setOptionsOpen'
> & {
  children: React.ReactNode;
};

const DropDownContext = createContext<DropDownContextProps | undefined>(
  undefined
);

const DropDownContextProvider: React.FC<DropDownContextProviderProps> = ({
  id,
  onTextInputChange,
  disabled,
  invalid,
  multiple,
  displayValue,
  placeholder,
  value,
  clearable,
  onChange,
  children,
}) => {
  const [inputFocused, setInputFocused] = useState(false);
  const [inputHovered, setInputHovered] = useState(false);
  const [optionsOpen, setOptionsOpen] = useState(false);

  return (
    <DropDownContext.Provider
      value={{
        id,
        inputHovered,
        setInputHovered,
        inputFocused,
        setInputFocused,
        onTextInputChange,
        displayValue,
        disabled,
        invalid,
        multiple,
        value,
        placeholder,
        clearable,
        onChange,
        optionsOpen,
        setOptionsOpen,
      }}
    >
      {children}
    </DropDownContext.Provider>
  );
};

const useDropDownContext = () => {
  const context = useContext(DropDownContext);

  if (context === null) {
    throw new Error(
      'useDropDownInputContext must be used within a DropDownInputContextProvider'
    );
  }

  return context;
};

export { useDropDownContext, DropDownContextProvider };
