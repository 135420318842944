import { useMutation } from '@tanstack/react-query';

import { createDiscussionThread } from '@/services/coloc-api/admin';
import { showToast } from '@/services/toast-service';
import { useDiscussionThreadProviderContext } from '../use-discussion-thread';

const useCreateDiscussionThread = () => {
  const { showDiscussionThread, openDiscussionThread } =
    useDiscussionThreadProviderContext()!;

  const mutate = useMutation({
    mutationKey: ['createDiscussionThread'],
    mutationFn: (params: {
      content: string;
      mention_ids: Array<{ id: number }>;
    }) => {
      return createDiscussionThread({
        message: {
          content: params.content,
          mention_ids: params.mention_ids,
        },
        discussable_id: showDiscussionThread.discussableId!,
        discussable_type: showDiscussionThread.discussionType!,
      });
    },
    onSuccess: (data) => {
      openDiscussionThread({
        discussionThreadId: data.data.discussion_thread.id,
        type: showDiscussionThread.discussionType!,
        onCloseFN: showDiscussionThread.onCloseFN,
        onMessageSent: showDiscussionThread.onMessageSent,
      });
      showDiscussionThread.onMessageSent?.();
    },
    onError: () => {
      showToast.error({});
    },
  });

  return {
    mutate,
  };
};

export { useCreateDiscussionThread };
