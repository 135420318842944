import { Amount, CurrencyIso } from '@/features/admin/rents/types';

type CurrencyMap = {
  [key in CurrencyIso]: string;
};

const currencyMap: CurrencyMap = {
  EUR: '€',
  USD: '$',
};

const currencyIsoToString = (currencyIso: CurrencyIso): string => {
  return currencyMap[currencyIso];
};

function currencyFormat(amount: number, currency: string) {
  const locale = currency === 'USD' ? 'en-US' : 'de-DE';
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(amount);
}

const amountToString = (amount: Amount) => {
  const centsToFull = (amount.cents / 100).toFixed(2);

  return currencyFormat(centsToFull, amount.currency_iso);
};

const numberToCurrency = (amount: Number, currency_iso = 'EUR') => {
  return `${currencyIsoToString(currency_iso)}${amount}`;
};

export { amountToString, currencyIsoToString, numberToCurrency };
