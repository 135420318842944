import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropDown } from '@/components/design-system/molecules/dropdown';
import { useRentReportListFiltersContext } from '../../../../hooks/use-get-rent-report-filter';

const ListRentReportStatusDropDown = () => {
  const {
    selectedReportStatus,
    setSelectedReportStatus,
    setReportStatusQuery,
    filteredListReportsStatusArray,
  } = useRentReportListFiltersContext()!;
  const { t } = useTranslation();

  return (
    <DropDown
      placeholder={
        t('js.features.admin.rent_report.filters.status_placeholder') ?? ''
      }
      className="z-50 w-[180px]"
      showLabel
      label={t('js.features.admin.rent_report.filters.status_label') ?? ''}
      value={selectedReportStatus}
      onChange={setSelectedReportStatus}
      {...(selectedReportStatus && {
        displayValue: selectedReportStatus.displayValue,
      })}
      onTextInputChange={(event) => setReportStatusQuery(event.target.value)}
    >
      <DropDown.DropDownOptions>
        {filteredListReportsStatusArray.map((option) => {
          return (
            <DropDown.DropDownOption key={option.displayValue} value={option}>
              <DropDown.DropDownOptionDefaultContent>
                {option.displayValue}
              </DropDown.DropDownOptionDefaultContent>
            </DropDown.DropDownOption>
          );
        })}
      </DropDown.DropDownOptions>
    </DropDown>
  );
};

export { ListRentReportStatusDropDown };
