import React from "react";
import { useThirdSectionContext } from '../../hooks/use-third-section-context';
import { InfoImage } from "../info-image";

const CurrentInfoImage = () => {
  const { currentOption } = useThirdSectionContext()!;
  return (
    <InfoImage currentOption={currentOption} />
  );
};

export { CurrentInfoImage };
