import React from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContextProvider } from '@/hooks/use-auth';
import { ReactQueryProvider } from '@/providers/react-query-provider';
import { MarketPlaceHeader } from '@/features/marketplace/components/header';
import { PADDING_TOP_HEADER_HEIGHT } from '@/constants';
import { composeClassName } from '@/utils/util_helpers';
import { Footer } from '@/features/marketplace/components/footer';
import { GeneralHero } from '@/features/marketplace/components/general-hero';
import { SecondSection } from './containers/second-section';
import { ThirdSection } from './containers/third-section';
import { Inquiries } from './containers/inquiries';
import { FourthSection } from './containers/fourth-section';

const Press = () => {
  const { t } = useTranslation();

  return (
    <ReactQueryProvider>
      <AuthContextProvider>
        <MarketPlaceHeader />
        <div
          className={composeClassName(
            'overflow-hidden',
            PADDING_TOP_HEADER_HEIGHT
          )}
        >
          <GeneralHero
            title={t('js.features.marketplace.press.hero.title')}
            subTitle={t('js.features.marketplace.press.hero.subtitle')}
            imgSrc={require(`@assets/images/marketplace/press/hero.jpg`)}
            pictureSrcSet={`${require(`@assets/images/marketplace/press/hero.webp`)} 1x, ${require(`@assets/images/marketplace/press/hero@2x.webp`)} 2x`}
          />
          <SecondSection />
          <ThirdSection />
          <Inquiries />
          <FourthSection />
        </div>
        <Footer />
      </AuthContextProvider>
    </ReactQueryProvider>
  );
};

export { Press };
