<template>
  <inline-svg
    :src="
      require(`../../../../../../../assets/icons/social-icons/${iconType}/${name}-${iconType}.svg`)
    "
    :width="size"
    :height="size"
    :class="iconType === 'bw' && strokeColor && 'icon-social-bw'"
    class="icon-social"
    :style="{ '--icon-color': strokeColor }"
  />
</template>

<script>
import './SocialIcon.scss';
import InlineSvg from 'vue-inline-svg';
import SocialIcons from './socialIconValidator';

export default {
  components: {
    InlineSvg,
  },
  props: {
    /**
     * Variant of social icon.
     *
     * Options: black-white or original. Defaults to black-white
     */
    variant: {
      type: String,
      default: 'black-white',
      validator(value) {
        return ['black-white', 'original'].includes(value);
      },
    },
    /**
     * The size of the icon. Defaults to 24.
     */
    size: {
      type: Number,
      default: 24,
    },
    /**
     * Color of the stroke for black white icons. defaults to black.
     */
    strokeColor: {
      type: String,
      default: 'black',
    },
    /**
     * The name of the icon.
     */
    name: {
      type: String,
      required: true,
      validator(value) {
        return Object.prototype.hasOwnProperty.call(SocialIcons, value);
      },
    },
  },
  computed: {
    iconType() {
      return this.variant === 'original' ? 'og' : 'bw';
    },
  },
};
</script>
