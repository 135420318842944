import React from 'react';
import { RotatingLines } from 'react-loader-spinner';
import { LoadingSpinnerProps } from './types';

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  size = 24,
  strokeWidth = 3,
  strokeColor = 'grey',
  ...props
}) => {
  return (
    <RotatingLines
      strokeWidth={String(strokeWidth)}
      width={String(size)}
      strokeColor={strokeColor}
      visible
      {...props}
    />
  );
};

export { LoadingSpinner };
