const ENV = {
BASE_URL: '',
COLOC_APP_API_HOST: '',
COLOC_APP_API_TIMEOUT_MS: '5000',
CHAT_URL: 'https://chat.staging.colochousing.com',
FIREBASE_CONFIG: {
    apiKey: 'AIzaSyB-i36gs9Y4s6BRpivQ2tVSPnID2QhcJtg',
    authDomain: 'coloc-housing-2420f.firebaseapp.com',
    projectId: 'coloc-housing-2420f',
    storageBucket: 'coloc-housing-2420f.appspot.com',
    messagingSenderId: '814507312576',
    appId: '1:814507312576:web:8b629855d1d0c66d73f425',
},
}
export {ENV}
