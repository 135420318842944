import React, { useCallback, useEffect, useRef } from 'react';
import { t } from 'i18next';
import { PaginatedColocAPIConnectedDropDownProps } from './types';
import { DropDown } from '@/components/design-system/molecules/dropdown';
import { LoadingSpinner } from '@/components/design-system/atoms/loading-spinner';

// eslint-disable-next-line react/function-component-definition
function PaginatedColocApiConnectedDropDown<T1>({
  dataSource,
  itemRenderer,
  onLoaded,
  ...props
}: PaginatedColocAPIConnectedDropDownProps<T1>) {
  const { fetch } = dataSource;
  const { data, status, isFetchingNextPage, hasNextPage, fetchNextPage } =
    fetch;

  useEffect(() => {
    if (data && onLoaded) {
      onLoaded(data.pages[0].data.data);
    }
  }, [data, onLoaded]);

  const getItem = useCallback(
    (passed: { props: T1 }, ref?: ((item: Element) => void) | undefined) => {
      return itemRenderer({ ...passed.props }, ref);
    },
    [itemRenderer]
  );

  const intObserver = useRef<IntersectionObserver>();
  const lastItemRef = useCallback(
    (item: Element) => {
      if (isFetchingNextPage) return;

      // If there is already an observer present destroy.
      if (intObserver.current) intObserver.current.disconnect();

      intObserver.current = new IntersectionObserver((items) => {
        if (items[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });

      if (item) intObserver.current.observe(item);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  return (
    <DropDown {...props}>
      <DropDown.DropDownOptions>
        <>
          {data?.pages && data?.pages?.length >= 1 && status === 'success' ? (
            <>
              {data?.pages.map((group, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={i}>
                  {group.data.data.map((item, index) => {
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <DropDown.DropDownOption
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        value={item}
                      >
                        {getItem(
                          {
                            props: item,
                          },
                          group.data.data.length - 1 === index
                            ? lastItemRef
                            : undefined
                        )}
                      </DropDown.DropDownOption>
                    );
                  })}
                  {isFetchingNextPage && (
                    <div className="flex items-center justify-center">
                      <LoadingSpinner />
                    </div>
                  )}
                </React.Fragment>
              ))}
            </>
          ) : (
            <div className="flex items-center justify-center">
              {t('js.features.shared.connected_dropdown.no_results')}
            </div>
          )}
        </>
      </DropDown.DropDownOptions>
    </DropDown>
  );
}

PaginatedColocApiConnectedDropDown.displayName =
  'PaginatedColocApiConnectedDropDown';

export { PaginatedColocApiConnectedDropDown };
