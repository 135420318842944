import React from 'react';
import { Combobox } from '@headlessui/react';
import { DropDownOptionsProps } from './types';
import { composeClassName } from '../../../../../../utils/util_helpers';

const DropDownOptions: React.FC<DropDownOptionsProps> = ({
  children,
  className,
}) => {
  return (
    <div className="relative w-full">
      <Combobox.Options
        className={composeClassName(
          'absolute z-10 mt-1 max-h-[200px] w-full overflow-auto rounded-sm bg-white py-1 shadow-medium',
          className && className
        )}
      >
        {children}
      </Combobox.Options>
    </div>
  );
};

export { DropDownOptions };
