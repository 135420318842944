/* eslint no-new: 0 */

import Tooltip from 'tooltip.js';

window.addEventListener('turbolinks:load', () => {
  document.querySelectorAll('[data-tooltip]').forEach((element) => {
    new Tooltip(element, {
      title: element.dataset.tooltip,
      trigger: element.dataset.trigger || 'hover',
    });
  });
});
