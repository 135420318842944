/* eslint no-multi-assign: 0 */
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['editor'];

  connect() {
    this.element.addEventListener('trix-initialize', (event) => {
      event.target.toolbarElement
        .querySelector('.trix-button-row')
        .insertAdjacentHTML('beforeend', this.toolbarExtensionHTML);

      event.target.toolbarElement
        .querySelectorAll('[data-action="click->text-editor#inject"')
        .forEach((el) => {
          el.addEventListener('click', (e) => this.inject(e));
        });
    });
  }

  formatDocument() {}

  reIndexPages() {}

  inject(e) {
    const { html } = e.target.dataset;
    this.element.editor.insertString(html);
  }

  get toolbarExtensionHTML() {
    return this.element.dataset.toolbarExtension;
  }

  get editor() {
    return this.editorTarget;
  }
}
