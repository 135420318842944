// @ts-ignore
import { ENV } from './vars.js.erb';

const COLOC_APP_API_HOST = ENV.COLOC_APP_API_HOST as unknown as string;
const COLOC_MAIN_APP = ENV.COLOC_APP_API_HOST as unknown as string;
const COLOC_APP_API_TIMEOUT_MS =
  ENV.COLOC_APP_API_TIMEOUT_MS as unknown as number;
const BASE_URL = ENV.BASE_URL as unknown as string;
const CHAT_URL = ENV.CHAT_URL as unknown as string;

const FIREBASE_CONFIG = ENV.FIREBASE_CONFIG as unknown as object;

export {
  COLOC_APP_API_HOST,
  COLOC_MAIN_APP,
  COLOC_APP_API_TIMEOUT_MS,
  BASE_URL,
  CHAT_URL,
  FIREBASE_CONFIG,
};
