<template>
  <div class="relative">
    <button @click="toggleMenu" class="flex space-x-2">
      <div>
        <img src="~assets/images/linear-icons/languages.svg" alt="" />
      </div>
    </button>

    <div
      v-if="showMenu"
      class="
        rounded-md
        absolute
        z-50
        flex flex-col
        items-start
        space-y-2
        bg-white
        p-5
        shadow-md
      "
      style="right: 0px"
    >
      <div
        v-for="locale in locales"
        :key="locale.code"
        class="flex w-full cursor-pointer items-center"
      >
        <a :href="`/${locale.code}/spot-a-house`" data-turbolinks="false">{{
          locale.name
        }}</a>
      </div>
    </div>

    <div
      @click="showMenu = false"
      v-if="showMenu"
      class="inset-0 fixed z-40 transition-opacity"
      aria-hidden="true"
    ></div>
  </div>
</template>

<script>
import I18n from 'i18n-js';

export default {
  name: 'header-Language',
  data() {
    return {
      showMenu: false,
      locales: [
        {
          code: 'en',
          name: 'English',
        },
        {
          code: 'fr',
          name: 'Français',
        },
        {
          code: 'nl',
          name: 'Nederlands',
        },
      ],
    };
  },
  computed: {
    selectedLocale() {
      return this.locales.find(
        (locale) => locale.code === I18n.currentLocale()
      );
    },
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  },
};
</script>

<style scoped>
button {
  display: flex;
  align-items: center;
  border: none;
  background: white;
  cursor: pointer;
}
button .world {
  width: 20px;
  height: 20px;
}
button .chevron {
  height: 18px;
  width: 18px;
  transition: all 0.3s ease-in-out;
}
.top {
  transform: rotate(180deg);
}

ul {
  position: absolute;

  left: calc(-100%);
  min-width: 10rem;
  padding: 0.5rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0 2rem rgb(26 26 26 / 8%);
  animation: fadeIn 0.3s ease-in-out forwards;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
a {
  display: block;
  padding: 0.5rem 0.75rem;
  font-size: 0.9625rem;
  cursor: pointer;
  text-decoration: none;
}
@media (hover: hover) {
  a:hover {
    color: var(--cyan);
    text-decoration: none;
  }
}
</style>
