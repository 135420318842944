import React from 'react';

import { GetTransactionsListContextProvider } from './hooks/use-get-transactions-list';

import { composeClassName } from '@/utils/util_helpers';

import { GetTransactionsFiltersContextProvider } from './hooks/use-transactions-list-filter';
import { PairTransactionWithRentContextProvider } from './hooks/use-pair-transaction-with-rent';
import { TransactionsListTable } from './components/transactions-list-table';
import { TransactionUpdateInfo } from './components/transaction-update-info';
import { TransactionsListFilterContainer } from './components/transactions-list-filter-container';

const TransactionsListContent = () => {
  return (
    <div className={composeClassName('flex h-full w-full flex-1 flex-col')}>
      <TransactionsListFilterContainer />
      <TransactionUpdateInfo />
      <TransactionsListTable />
    </div>
  );
};

const IncomingTransactionsList = () => {
  return (
    <GetTransactionsFiltersContextProvider>
      <GetTransactionsListContextProvider>
        <PairTransactionWithRentContextProvider>
          <TransactionsListContent />
        </PairTransactionWithRentContextProvider>
      </GetTransactionsListContextProvider>
    </GetTransactionsFiltersContextProvider>
  );
};

export { IncomingTransactionsList };
