import React from 'react';
import { ColocIcon } from '@/components/design-system/atoms/icon';
import { Button } from '@/components/design-system/atoms/button';
import { useGetRoomsAssignmentContext } from '../../hooks/use-get-room-assignment-list';

const SuccessContent = ({ text }: { text: string }) => {
  const { setShowUpdateMoveIn } = useGetRoomsAssignmentContext()!;

  return (
    <div className="text-center justify-center items-center flex flex-col gap-3 w-[320px]">
      <ColocIcon name="done" size={128} />
      <p className="text-lead2 text-text-primaryHeavy font-bold">
        Move-in date updated
      </p>

      <p className="text-body text-text-primaryMedium whitespace-pre-wrap">{text}</p>

      <Button
        variant="primary"
        onClick={() => {
          setShowUpdateMoveIn({
            show: false,
            applicant: undefined,
            status: undefined,
          })
        }}
      >
        Done
      </Button>
    </div>
  );
}

export { SuccessContent };
