import React from 'react';
import { DEFAULT_CONTENT_WIDTH } from '@/features/marketplace/constants';
import { composeClassName } from '@/utils/util_helpers';
import { useWindowDimensions } from '@/hooks/use-window-dimensions';
import { BaseComponent } from '@/types/global';

export type GeneralHeroProps = BaseComponent & {
  title: string;
  subTitle: string;
  imgSrc: string;
  pictureSrcSet: string;
};


const backgroundImage = require(`@assets/images/marketplace/about-us/about-us-bg.svg`);

const GeneralHero: React.FC<GeneralHeroProps> = ({
  imgSrc,
  pictureSrcSet,
  title,
  subTitle,
}) => {
  const { screenIsLargeOrEqualThan, screenIsSmallerThan } =
    useWindowDimensions();

  return (
    <section
      className={composeClassName(
        'relative w-full',
        'lg:h-[35rem]',
        'xl:h-[55rem]'
      )}
      style={{
        backgroundSize: 'cover',
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div
        className={composeClassName(
          'flex flex-col',
          screenIsLargeOrEqualThan('sm') ? DEFAULT_CONTENT_WIDTH : 'w-full',
          'sm:m-auto',
          'lg:h-full lg:flex-row lg:items-center lg:justify-between'
        )}
      >
        <div
          className={composeClassName(
            screenIsSmallerThan('sm') && DEFAULT_CONTENT_WIDTH,
            screenIsSmallerThan('sm') && 'm-auto',
            'relative mt-[5.4rem] flex flex-col gap-2',
            'sm:mt-[12.8rem] sm:gap-3 sm:pr-5',
            'lg:mt-[0px]'
          )}
        >
          <h1
            className={composeClassName(
              'z-10 flex flex-col text-[4.8rem] font-semibold text-text-primary',
              'sm:text-[7.2rem]'
            )}
          >
            {title}
          </h1>
          <span className="text-lead1 text-text-secondary">{subTitle}</span>
          {/* rounded circle header */}
          <div
            className={composeClassName(
              'absolute top-[-1.2rem] left-[-2rem] z-0 h-[7.2rem] w-[7.2rem] rounded-full bg-R300',
              'sm:top-[0rem] sm:left-[-2rem] sm:h-[9.6rem] sm:w-[9.6rem]'
            )}
          />
        </div>
        <div
          className={composeClassName(
            'relative mt-[5.6rem] w-[95%]',
            screenIsSmallerThan('sm') && 'right-[0] aspect-[3.5/4] self-end',
            screenIsLargeOrEqualThan('md') &&
              screenIsSmallerThan('lg') &&
              'aspect-[7/5]',
            'sm:mt-10 sm:h-full sm:w-auto',
            'lg:mt-[0px]'
          )}
        >
          <picture
            className={composeClassName('relative h-full w-auto object-cover')}
          >
            <source srcSet={pictureSrcSet}/>
            <img
              className="relative z-10 h-full w-auto object-cover"
              src={imgSrc}
              alt="team"
            />
          </picture>
          {/* square behind picture */}
          <div
            className={composeClassName(
              'absolute top-[6%] left-[-6%] h-full w-full bg-R300',
              'sm:top-[2rem] sm:left-[-2rem]',
              'xl:top-[3rem] xl:left-[-3rem]'
            )}
          />
        </div>
      </div>
    </section>
  );
};

export { GeneralHero };
