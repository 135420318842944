import React, { useMemo } from 'react';
import { composeClassName } from '@/utils/util_helpers';
import { PaginationItemProps } from './types';
import { IconSaxIcon } from '../../../icon';
import { theme } from '@/utils/util_theme';

const ItemContent: React.FC<PaginationItemProps> = ({
  isBackButton = false,
  isForwardButton = false,
  displayValue = undefined,
  isRestIndicatorButton = false,
  value = undefined,
  disabled = false,
  active = false,
}) => {
  const strokeColor = useMemo(() => {
    if (active) {
      return theme.colors?.text?.primary;
    }

    if (disabled) {
      return theme.colors?.E50;
    }

    return theme.colors?.text?.tertiary;
  }, [active, disabled]);

  return (
    <>
      {isBackButton && (
        <IconSaxIcon
          name="arrow-left-1"
          strokeColor={strokeColor}
          size={16}
          strokeWidth={2}
        />
      )}
      {isForwardButton && (
        <IconSaxIcon
          name="arrow-right-1"
          strokeColor={strokeColor}
          size={16}
          strokeWidth={2}
        />
      )}

      {isRestIndicatorButton && <span>...</span>}

      {value && displayValue ? (
        <span>{displayValue}</span>
      ) : (
        value && <span>{value}</span>
      )}
    </>
  );
};

const PaginationItem: React.FC<PaginationItemProps> = (props) => {
  const {
    disabled = false,
    active = false,
    onClick,
    className,
    isRestIndicatorButton = false,
  } = props;
  return (
    <button
      type="button"
      onClick={onClick}
      className={composeClassName(
        'flex h-5 w-5 items-center justify-center rounded-sm bg-white text-body text-text-tertiary shadow-medium',
        !isRestIndicatorButton && 'hover:bg-E30',
        active && '!bg-R300 !text-text-primary',
        disabled && '!bg-E10 !text-E50',
        className && className
      )}
    >
      <ItemContent {...props} />
    </button>
  );
};

export { PaginationItem };
