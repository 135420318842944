import React from 'react';
import { composeClassName } from '../../../../../../utils/util_helpers';
import { useDropDownContext } from '../../hooks/use-dropdown-context';
import { DropDownInputWrapperProps } from './types';

const DropDownInputWrapper: React.FC<DropDownInputWrapperProps> = ({
  children,
  className,
}) => {
  const { inputFocused, setInputHovered, invalid } = useDropDownContext()!;

  return (
    <div
      className={composeClassName(
        'border-box relative flex w-full overflow-hidden rounded-sm border-[1px] border-solid pr-9 outline-none',
        !inputFocused && 'border-E30 bg-E30 hover:border-E50 hover:bg-E50',
        inputFocused && ' border-R300 bg-white',
        invalid && '!border-C300 !bg-E30',
        className && className
      )}
      onMouseEnter={() => setInputHovered(true)}
      onMouseLeave={() => setInputHovered(false)}
    >
      {children}
    </div>
  );
};

export { DropDownInputWrapper };
