import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@/components/design-system/atoms/modal';
import { MakeTransfterModalProps } from './types';
import { IconSaxIcon } from '@/components/design-system/atoms/icon';
import { TextInput } from '@/components/design-system/atoms/text/text-input';
import { Button } from '@/components/design-system/atoms/button';
import { Rent } from '@/features/admin/rents/types';
import { DropDown } from '@/components/design-system/molecules/dropdown';
import { getPairRents } from '@/lib/coloc-api/admin';
import { useMakeTransfer } from './hooks';
import { OptionStateSelector } from '@/components/design-system/atoms/option-state-selector';
import { amountToString } from '@/utils/util_helpers/formatting/amount_to_string';
import { getFullDate } from '@/utils/util_helpers/formatting/dates';
import { DatePicker } from '@/components/design-system/molecules/datepicker';

const options = [
  { value: 'full', label: 'Full Amount' },
  { value: 'partail', label: 'Partial Amount' },
];

const MakeTransferModal: React.FC<MakeTransfterModalProps> = ({
  open,
  onClose,
  rent,
}) => {
  const { t } = useTranslation();
  const [selectedTransactionAmount, setRentTransactionAmount] = useState<
    number | undefined
  >();
  const [errors, setErrors] = useState<any>({});
  const [selectedPairRent, setSelectedPairRent] = useState<{
    value: number;
    label: string;
  } | null>(null);
  const [pairRents, setPairRents] = useState<Rent[]>([]);
  const { mutate: makeTransfer } = useMakeTransfer()!;
  const [selectedOption, setSelectedOption] = useState<string>('full');
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  useEffect(() => {
    getPairRents(rent.id).then((res) => {
      setPairRents(res.data.data);
    });
  }, [rent]);

  const pairRentsOptions = useMemo(() => {
    return pairRents.map((pairRent) => {
      return {
        value: pairRent.id,
        label: `${pairRent.month_name} ${pairRent.year} (${amountToString(
          pairRent.unpaid_amount
        )})`,
      };
    });
  }, [pairRents]);

  const handleTransfer = () => {
    if (!selectedPairRent) return;
    setErrors({});
    const params = {
      rent_id: rent.id,
      transaction: {
        pair_rent_id: selectedPairRent.value,
        amount:
          selectedOption === 'partail'
            ? selectedTransactionAmount
            : Math.abs(rent.total_amount_paid.cents / 100).toFixed(2),
        date: getFullDate(selectedDate),
      },
    };

    makeTransfer.mutate(params, {
      onError: (error) => {
        setErrors(error.response.data.errors);
      }
    })
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className="w-[650px] overflow-hidden rounded-lg bg-white shadow-medium">
        <div className="flex h-[102px] w-full items-center justify-between bg-R300 px-7 py-3">
          <div className="flex flex-col">
            <span className="text-lead2 font-medium text-text-primary opacity-60">
              {t(
                'js.features.admin.rents.make_transfer_modal.title_first_part'
              )}
            </span>
            <span className="text-h4 font-bold text-text-primary">
              {t(
                'js.features.admin.rents.make_transfer_modal.title_second_part'
              )}
            </span>
          </div>
          <IconSaxIcon name="wallet-add" size={70} className="opacity-20" />
        </div>
        <div className="flex w-full overflow-hidden">
          <div className="flex flex-col gap-4 bg-E30 px-4 pt-4">
            <div>
              <span className="text-body font-medium italic text-text-primary opacity-60">
                {t(
                  'js.features.admin.rents.make_transfer_modal.how_to_section.title'
                )}
              </span>
              <div className="flex flex-col gap-3">
                <div className="flex gap-1 pt-[6px] text-body text-text-primary">
                  <span>1.</span>
                  <span>
                    {t(
                      'js.features.admin.rents.make_transfer_modal.how_to_section.step_1'
                    )}
                  </span>
                </div>
                <div className="flex gap-1 text-body text-text-primary">
                  <span>2.</span>
                  <span>
                    {t(
                      'js.features.admin.rents.make_transfer_modal.how_to_section.step_2'
                    )}
                  </span>
                </div>
                <div className="flex gap-1 text-body text-text-primary">
                  <span>3.</span>
                  <span>
                    {t(
                      'js.features.admin.rents.make_transfer_modal.how_to_section.step_3'
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className="h-[1px] w-full bg-E50" />
            <div className="flex flex-col gap-1 text-body text-text-primary">
              <span className="font-medium italic opacity-60">
                {t(
                  'js.features.admin.rents.make_transfer_modal.rent_info.room',
                  { room_number: rent.room.room_number }
                )}
              </span>
              <span className="font-bold">{rent.tenant.name}</span>
              <span className="uppercase opacity-60">
                {rent.room.house.name}
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-[105px] bg-white p-7">
            <div className="flex w-[320px] flex-col gap-4">
              <div>
                <div className="text-body font-medium text-text-primary">
                  Collected Amount
                </div>
                <div className="text-h4 font-bold text-text-primary">
                  {amountToString(rent.total_amount_paid)}
                </div>
              </div>

              <OptionStateSelector
                options={options}
                selectedOption={selectedOption}
                onSelect={(value: string) => setSelectedOption(value)}
              />

              {selectedOption === 'partail' && (
                <TextInput
                  label={t(
                    'js.features.admin.rents.make_transfer_modal.form.amount_input_label'
                  )}
                  type="number"
                  value={selectedTransactionAmount}
                  onChange={(e) => {
                    setRentTransactionAmount(Number(e.target.value));
                  }}
                  invalid={!!errors.amount}
                  errorMessage={errors.amount}
                />
              )}
              <DatePicker
                label={t(
                  'js.features.admin.rents.make_transfer_modal.form.date_input_label'
                )}
                type="date"
                value={selectedDate}
                onChange={(date) => setSelectedDate(date)}
              />
              <DropDown
                placeholder={
                  pairRents.length === 0 ? (
                    t(
                      'js.features.admin.rents.make_transfer_modal.form.no_pair_rent_placeholder'
                    )
                  ) : (
                    t(
                      'js.features.admin.rents.make_transfer_modal.form.pair_rent_placeholder'
                    )
                  )
                }
                className="w-full"
                showLabel
                label={t(
                  'js.features.admin.rents.make_transfer_modal.form.pair_rent_label'
                )}
                {...(selectedPairRent && {
                  displayValue: selectedPairRent.label,
                })}
                value={selectedPairRent}
                onChange={(value) => setSelectedPairRent(value)}
                invalid={!!errors.pair_rent_id}
                errorMessage={errors.pair_rent_id}
              >
                <DropDown.DropDownOptions>
                  {pairRentsOptions.map((option) => {
                    return (
                      <DropDown.DropDownOption key={option.id} value={option}>
                        <DropDown.DropDownOptionDefaultContent>
                          {option.label}
                        </DropDown.DropDownOptionDefaultContent>
                      </DropDown.DropDownOption>
                    );
                  })}
                </DropDown.DropDownOptions>
              </DropDown>
            </div>
            <div className="flex items-center gap-4">
              <Button
                variant="primary"
                disabled={!selectedPairRent || makeTransfer.isLoading}
                onClick={() => handleTransfer()}
              >
                {t(
                  'js.features.admin.rents.make_transfer_modal.form.transfer_button'
                ) ?? ''}
              </Button>
              <Button
                variant="ghost"
                onClick={() => {
                  onClose && onClose();
                }}
              >
                {t(
                  'js.features.admin.rents.make_transfer_modal.form.cancel_button'
                ) ?? ''}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { MakeTransferModal };
