/* eslint-disable react/prop-types */
import React, { Children, Fragment, isValidElement, useMemo } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { SlideOverProps, SlideOverSlots } from './types';
import { SlideOverContentProps } from './components/slide-over-content/types';
import { SlideOverContent } from './components/slide-over-content';
import { SlideOverBottomContent } from './components/slide-over-bottom-content';
import { SlideOverBottomContentProps } from './components/slide-over-bottom-content/types';
import { Button } from '../../atoms/button';
import { SlideOverEmptyStateProps } from './components/slide-over-empty-state/types';
import { SlideOverEmptyState } from './components/slide-over-empty-state';
import { composeClassName } from '@/utils/util_helpers';

const SlideOver: React.FC<SlideOverProps> & {
  SlideOverContent: React.FC<SlideOverContentProps>;
  SlideOverBottomContent: React.FC<SlideOverBottomContentProps>;
  SlideOverEmptyState: React.FC<SlideOverEmptyStateProps>;
} = ({ children, show, onClose, title, subtitle, className, width = 540, ...props }) => {
  const filledSlots = useMemo(() => {
    const slots: SlideOverSlots = {
      content: null,
      placeholder: null,
      bottomContent: null,
    };

    Children.toArray(children).forEach((child) => {
      if (!isValidElement(child)) {
        return;
      }

      switch (child.type) {
        case SlideOverContent:
          slots.content = child;
          break;
        case SlideOverBottomContent:
          slots.bottomContent = child;
          break;
        case SlideOverEmptyState:
          slots.placeholder = child;
          break;
        default:
          break;
      }
    });

    return slots;
  }, [children]);

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className={composeClassName(
          'absolute top-[0px] z-50 h-full w-full',
          className && className
        )}
        onClose={() => onClose()}
      >
        {/* Backdrop */}
        <Transition.Child
          as={Fragment}
          enter="transform transition duration-500 ease-in-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition duration-500 ease-in-out"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="inset-0 fixed h-full w-full bg-black opacity-5" />
        </Transition.Child>

        {/* Modal */}
        <div className="inset-0 fixed">
          <div className="inset-0 absolute">
            <div
              className="inset-y-0 right-0 pointer-events-none fixed flex h-full max-w-full "
              style={{ paddingLeft: `calc(100% - ${width}px)` }}
            >
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel
                  className="pointer-events-auto h-full"
                  style={{ width: `${width}px` }}
                >
                  <div className="flex flex-row items-center gap-3 bg-N100 px-5 py-3">
                    <Button
                      variant="icon"
                      customIconSize={18}
                      iconName="close-circle"
                      onClick={() => onClose()}
                    />
                    <div className="flex flex-col">
                      <Dialog.Title>
                        <div className="text-h5 font-bold text-text-primaryHeavy">
                          {title}
                        </div>

                        {subtitle && (
                          <div className="text-body text-text-primaryMedium">
                            {subtitle}
                          </div>
                        )}
                      </Dialog.Title>
                    </div>
                  </div>
                  <div className="flex h-full flex-col bg-white shadow-xl">
                    {filledSlots.placeholder?.props?.show
                      ? filledSlots.placeholder
                      : filledSlots.content}
                    {filledSlots.bottomContent}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

SlideOver.SlideOverContent = SlideOverContent;
// @ts-ignore
SlideOver.SlideOverBottomContent = SlideOverBottomContent;
SlideOver.SlideOverEmptyState = SlideOverEmptyState;

export { SlideOver };
