import { useMemo } from 'react';
import { useIsMutating, useMutation } from '@tanstack/react-query';

import { t } from 'i18next';
import { UpdateRentAction } from '@/lib/coloc-api/admin';
import { updateRents } from '@/services/coloc-api/admin';
import { useGetRentsListContext } from '../use-get-rents-list';
import { showToast } from '@/services/toast-service';

const useUpdateRents = () => {
  const {
    fetch: rentsFetch,
    rowSelectionFullData,
    setRowSelection,
  } = useGetRentsListContext()!;

  let performingAction: UpdateRentAction | null = null;

  const arrayOfSelectedRentIds = useMemo(() => {
    return rowSelectionFullData.map((rent) => rent.id);
  }, [rowSelectionFullData]);

  const mutationKey = 'mutateRents';

  const mutate = useMutation({
    mutationKey: [mutationKey],
    mutationFn: (params: UpdateRentAction) => {
      performingAction = params;
      return updateRents({
        status: params,
        rent_ids: arrayOfSelectedRentIds,
      });
    },
    onSuccess: () => {
      rentsFetch.refetch();
      showToast.success({
        text:
          t(
            `js.features.admin.rents.rent_list.bulk_actions.${performingAction}.success_text_toast`
          ) ?? '',
      });

      setRowSelection({});
      performingAction = null;
    },
    onError: () => {
      showToast.error({});
      performingAction = null;
    },
  });

  const isMutatingRents = useIsMutating({ mutationKey: [mutationKey] });

  return {
    mutate,
    performingAction,
    isMutatingRents,
  };
};

export { useUpdateRents };
