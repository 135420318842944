<template>
  <div>
    <PressHeader />
    <section class="o-grid3col o-grid3col--r1L2">
      <PressNav />
      <div class="o-grid3col__main">
        <div class="o-grid3col__main__2col" id="company">
          <div>
            <h2>Why Coloc Housing ?</h2>
            <p>
              The world is changing. We help your investments change with it.
            </p>
            <p>
              The sharing economy in general is on the rise. Sharing spaces,
              time and resources, not only increases productivity, but also
              enhances social interaction, is more sustainable and makes perfect
              sense. According to an article from Forbes magazine from March
              2019 the sharing economy is expected to grow from $15 billion in
              2014 to $335 billion in 2025.
            </p>
            <p>
              Coloc Housing emerged from the rising trend in Belgium for young
              people to get together and share a house. Our vision is to help
              tighten the social fabric in society by facilitating coliving. We
              do this by creating a community of like-minded people across all
              walks of life that connect, share experiences and live to the
              fullest.
            </p>
            <p>
              Our mission to design, conceptualise and realise beautifully
              furnished and sustainable coliving spaces that inspire and
              facilitate coliving. Our goal is to create tools and concepts to
              connect colocs and improve their coliving experience. We love to
              organise events to engage our colocs in social interactions.
            </p>
          </div>
          <PressQuote />
        </div>
        <div>
          <h2 class="o-grid3col__main__title" style="margin-top: 16px">
            What are our services ?
          </h2>
          <ul class="o-grid3col__main__2col">
            <li>
              <IconPurchase />
              <p>
                <strong>Purchase</strong><br />As certified real estate agents
                we help you find the right property.
              </p>
            </li>
            <li>
              <IconRenovation />
              <p>
                <strong>Renovation</strong><br />Our team of architects,
                interior designers and renovation contractors make sure each
                co-living project meets the highest building standards.
              </p>
            </li>
          </ul>
          <ul class="o-grid3col__main__2col">
            <li>
              <IconDecoration />
              <p>
                <strong>Decoration</strong><br />We combine a mix of
                contemporary design and vintage interior elements.
              </p>
            </li>
            <li>
              <IconRental />
              <p>
                <strong>Rental</strong><br />We take care of the visits,
                contracts, move-ins and departures.
              </p>
            </li>
          </ul>
          <ul class="o-grid3col__main__2col">
            <li>
              <IconManagement />
              <p>
                <strong>Property management</strong><br />Your property is in
                the best hands. We take care of everything to A to Z.
              </p>
            </li>
            <li>
              <IconConsulting />
              <p>
                <strong>We buy</strong><br />Contact us if you want to sell or
                lease your property to Coloc Housing.
              </p>
            </li>
          </ul>
        </div>
        <PressTeam />
        <PressKit />
        <PressForm />
      </div>
    </section>
  </div>
</template>

<script>
import PressHeader from './PressHeader';
import PressNav from './PressNav';
import PressQuote from './PressQuote';
import PressTeam from './PressTeam';
import PressKit from './PressKit';
import PressForm from './PressForm';

import IconPurchase from './icons/IconPurchase';
import IconRenovation from './icons/IconRenovation';
import IconDecoration from './icons/IconDecoration';
import IconRental from './icons/IconRental';
import IconManagement from './icons/IconManagement';
import IconConsulting from './icons/IconConsulting';

export default {
  components: {
    PressHeader,
    PressNav,
    PressQuote,
    PressTeam,
    PressKit,
    PressForm,
    IconPurchase,
    IconRenovation,
    IconDecoration,
    IconRental,
    IconManagement,
    IconConsulting,
  },
};
</script>


<style scoped>
body {
  line-height: 1.3;
}
h2 {
  font-size: 1.5rem;
}
p {
  line-height: 1.3;
  font-size: 1rem;
}
ul {
  list-style: none;
  padding: 0;
}
@media screen and (min-width: 601px) {
  .o-grid3col {
    position: relative;
    display: grid;
    width: 100%;
    grid-template-columns: 0.5fr 1fr 1fr;
    grid-gap: 16px;
  }
  .company .o-grid3col {
    grid-template-columns: 200px 1fr 1fr;
  }
  .o-grid3col--r1L2 {
    grid-template-areas: 'aside main main';
  }
  .o-grid3col--r2L1 {
    grid-template-areas: 'main main aside';
  }
  .o-grid3col__main {
    grid-area: main;
  }
  .o-grid3col__aside {
    grid-area: aside;
  }
  .o-grid3col__main__2col {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .o-grid3col__main__2col > * {
    flex: 0 1 calc(50% - 8px);
  }
  .o-grid3col__main__title {
    flex: 1 1 100%;
  }
}
</style>
<style>
body {
  scroll-behavior: smooth;
}
</style>