import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMutation } from '@tanstack/react-query';
import { Transaction } from '../../../../types';
import { House, RentOfContract, Room, TenantOfRoom } from '@/types/admin';

import { linkTransactionWithRent } from '@/services/coloc-api/admin';
import { showToast } from '@/services/toast-service';
import { useGetTransactionsListContext } from '../use-get-transactions-list';

const usePairTransactionWithRent = () => {
  const { updateTransactionInCache } = useGetTransactionsListContext()!;
  const [showPairTransactionModal, toggleShowPairTransactionModal] = useState<{
    show: boolean;
    transaction: Transaction | undefined;
  }>({
    show: false,
    transaction: undefined,
  });
  // House
  const [selectedHouse, setSelectedHouseInternal] = useState<House | undefined>(
    undefined
  );
  const [housesQuery, setHousesQuery] = useState('');
  // Room
  const [selectedRoom, setSelectedRoomInternal] = useState<Room | undefined>(
    undefined
  );
  const [roomsQuery, setRoomsQuery] = useState('');
  // Tenant
  const [selectedTenant, setSelectedTenantInternal] = useState<
    TenantOfRoom | undefined
  >(undefined);
  const [tenantQuery, setTenantQuery] = useState('');
  // Rent
  const [selectedRent, setSelectedRentInternal] = useState<
    RentOfContract | undefined
  >(undefined);
  const [rentQuery, setRentQuery] = useState('');
  // Confirmation modal
  const [
    showLinkedTransactionConfirmationModal,
    toggleLinkedTransactionConfirmationModal,
  ] = useState<{
    show: boolean;
    transaction: Transaction | undefined;
  }>({
    show: false,
    transaction: undefined,
  });

  useEffect(() => {
    if (!showPairTransactionModal.show) {
      setSelectedHouseInternal(undefined);
      setSelectedRoomInternal(undefined);
      setSelectedTenantInternal(undefined);
      setSelectedRentInternal(undefined);
      setTenantQuery('');
      setHousesQuery('');
      setRoomsQuery('');
      setRentQuery('');
    }
  }, [showPairTransactionModal.show]);

  const setSelectedHouse = useCallback(
    (house: House | undefined) => {
      if (selectedHouse !== house) {
        setSelectedRoomInternal(undefined);
        setSelectedTenantInternal(undefined);
        setSelectedRentInternal(undefined);
      }

      setSelectedHouseInternal(house);
    },
    [selectedHouse]
  );

  const setSelectedRoom = useCallback(
    (room: Room | undefined) => {
      if (selectedRoom !== room) {
        setSelectedTenantInternal(undefined);
        setSelectedRentInternal(undefined);
      }

      setSelectedRoomInternal(room);
    },
    [selectedRoom]
  );

  const setSelectedTenant = useCallback(
    (tenant: TenantOfRoom | undefined) => {
      if (selectedTenant !== tenant) {
        setSelectedRentInternal(undefined);
      }

      setSelectedTenantInternal(tenant);
    },
    [selectedTenant]
  );

  const setSelectedRent = useCallback((rent: RentOfContract | undefined) => {
    setSelectedRentInternal(rent);
  }, []);

  const rentIsSubmittable = useMemo(() => {
    return selectedHouse && selectedRoom && selectedTenant && selectedRent;
  }, [selectedHouse, selectedRent, selectedRoom, selectedTenant]);

  const mutate = useMutation({
    mutationKey: ['linkTransactionWithRent'],
    mutationFn: () => {
      return linkTransactionWithRent({
        rent_id: selectedRent?.id ?? 0,
        transaction_id: showPairTransactionModal.transaction?.id ?? '',
      });
    },
    onSuccess: (data) => {
      updateTransactionInCache(data.data);
      toggleShowPairTransactionModal({ show: false, transaction: undefined });
      toggleLinkedTransactionConfirmationModal({
        transaction: data.data,
        show: true,
      });
    },
    onError: () => {
      toggleShowPairTransactionModal({ show: false, transaction: undefined });
      showToast.error({});
    },
  });

  return {
    showPairTransactionModal,
    toggleShowPairTransactionModal,
    selectedHouse,
    setSelectedHouse,
    housesQuery,
    setHousesQuery,
    selectedRoom,
    setSelectedRoom,
    roomsQuery,
    setRoomsQuery,
    selectedTenant,
    setSelectedTenant,
    tenantQuery,
    setTenantQuery,
    selectedRent,
    setSelectedRent,
    rentQuery,
    setRentQuery,
    rentIsSubmittable,
    mutate,
    showLinkedTransactionConfirmationModal,
    toggleLinkedTransactionConfirmationModal,
  };
};

const PairTransactionWithRentContext = createContext<
  ReturnType<typeof usePairTransactionWithRent> | undefined
>(undefined);

const PairTransactionWithRentContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const hook = usePairTransactionWithRent();

  return (
    <PairTransactionWithRentContext.Provider value={hook}>
      {children}
    </PairTransactionWithRentContext.Provider>
  );
};

const usePairTransactionWithRentContext = () => {
  const context = useContext(PairTransactionWithRentContext)!;

  if (context === null) {
    throw new Error(
      'usePairTransactionWithRentContext must be used within a PairTransactionWithRentContextProvider'
    );
  }

  return context;
};

export {
  usePairTransactionWithRentContext,
  PairTransactionWithRentContextProvider,
};
