import React from 'react';

import { SlideOverBottomContentProps } from './types';
import { composeClassName } from '@/utils/util_helpers';

const SlideOverBottomContent: React.FC<SlideOverBottomContentProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <div
      className={composeClassName('w-full self-end', className && className)}
      {...props}
    >
      {children}
    </div>
  );
};

export { SlideOverBottomContent };
