import { useTranslation } from 'react-i18next';
import React from 'react';
import { SearchInput } from '@/components/design-system/molecules/search-input';

import { useRoomAssignmentListFiltersContext } from '../../hooks/use-get-room-assignment-list-filter';
import { DatePicker } from '@/components/design-system/molecules/datepicker';
import { AssignmentStatusDropDown } from './components/assignment-status-dropdown';
import { CitiesSelectionDropdown } from './components/cities-selection-dropdown';
import { PropertyManagersFilter } from './components/property-managers-filter';
import { RentalTypeFilter } from './components/rental-type-filter';

const RoomAssignmentListFilter = () => {
  const { setSearchQuery, searchQuery, setDateRange, startDate, endDate } =
    useRoomAssignmentListFiltersContext()!;
  const { t } = useTranslation();

  return (
    <div className="relative flex w-full items-end justify-between pt-5">
      <div className="flex flex-wrap gap-4">
        <SearchInput
          placeholder={
            t(
              'js.features.admin.contracts.room_assignment.search_placeholder'
            ) ?? ''
          }
          className="w-[250px]"
          label={
            t('js.features.admin.contracts.room_assignment.search_label') ?? ''
          }
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <AssignmentStatusDropDown />
        <CitiesSelectionDropdown />
        <PropertyManagersFilter />
        <DatePicker
          placeholder="All Dates"
          className="w-[172px]"
          onChange={setDateRange}
          label={
            t('js.features.admin.contracts.room_assignment.date_range_label') ??
            ''
          }
          dateRange
          type="month"
          startValue={startDate}
          endValue={endDate}
        />

        <RentalTypeFilter />
      </div>
    </div>
  );
};

export { RoomAssignmentListFilter };
