<template>
  <div class="inset-x-0 fixed z-50 w-full bg-white p-4 shadow-medium">
    <div class="m-auto flex w-full max-w-8xl items-center justify-between">
      <a href="/" data-turbolinks="false">
        <Logo />
      </a>
    </div>
  </div>
</template>

<script>
import Logo from '../spot_a_house/logo/Logo.vue';

export default {
  components: {
    Logo,
  },
  methods: {
    GetStarted() {
      // scroll to ContactUs id
      window.scrollTo({
        top: document.getElementById('ContactUs').offsetTop,
        behavior: 'smooth',
      });
    },
  },
};
</script>