import React, { useState } from 'react';
import { Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { IconSaxIcon } from '@/components/design-system/atoms/icon';

import { useSearchRoomContext } from '@/features/marketplace/hooks/use-search-room';
import { theme } from '@/utils/util_theme';
import { SearchBarItems } from '../search-bar-items';
import { SearchBarProps } from './types';
import { composeClassName } from '@/utils/util_helpers';

const SearchBarHeader: React.FC<SearchBarProps> = ({
  className,
  inputWrappingClassName,
}) => {
  const [inputActive, setInputActive] = useState(false);

  const { setSearchQuery } = useSearchRoomContext()!;

  const { t } = useTranslation();

  return (
    <div
      className={composeClassName('relative', className && className)}
    >
      <div
        className={composeClassName(
          'flex h-[5rem] w-[28rem] items-center rounded-[50px] border-[1px] border-solid border-E50 bg-white px-3',
          inputWrappingClassName && inputWrappingClassName
        )}
      >
        <IconSaxIcon
          name="search-normal-1"
          strokeColor={theme?.colors.text.tertiary}
        />
        <input
          className="mx-3 h-full flex-1 text-body text-text-primary outline-none placeholder:text-body placeholder:text-text-tertiary"
          type="text"
          placeholder={
            t('js.features.marketplace.components.room_search.placeholder') ??
            ''
          }
          onChange={(e) => setSearchQuery(e.target.value)}
          onFocus={() => setInputActive(true)}
          onBlur={() => setTimeout(() => setInputActive(false), 200)}
        />
      </div>
      <Transition
        show={inputActive}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        className="relative z-20"
      >
        <div className="scrollbar-hide absolute top-[0.8rem] z-30 flex max-h-[28rem] w-full flex-col gap-4 overflow-y-scroll rounded-[24px] bg-white p-4 shadow-medium">
          <SearchBarItems />
        </div>
      </Transition>
    </div>
  );
};

export { SearchBarHeader };
