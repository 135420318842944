import toast from 'react-hot-toast';
import React from 'react';
import { uniqueId } from 'lodash-es';
import {
  ToastProps,
  ToastType,
} from '@/components/design-system/atoms/toast/types';
import { Toast } from '@/components/design-system/atoms/toast';

type ShowToastInterface = Pick<ToastProps, 'text' | 'title'>;

const returnToast = (type: ToastType, params: ShowToastInterface) => {
  const id = uniqueId();

  return toast.custom(
    <Toast
      type={type}
      {...params}
      onDismiss={() => {
        toast.remove(id);
      }}
    />
  );
};

const showToast = {
  success: (params: ShowToastInterface) => returnToast('success', params),
  alert: (params: ShowToastInterface) => returnToast('alert', params),
  info: (params: ShowToastInterface) => returnToast('info', params),
  error: (params: ShowToastInterface) => returnToast('error', params),
};

export { showToast };
