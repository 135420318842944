/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { composeClassName } from '../../../../../../utils/util_helpers';
import { theme } from '../../../../../../utils/util_theme';
import { IconSaxIcon } from '../../../../atoms/icon';
import { DropDownInputMultipleValueDefaultContentProps } from './types';

const DropDownInputMultipleValueDefaultContent: React.FC<DropDownInputMultipleValueDefaultContentProps> =
  ({ children, contentBefore, onClear, className }) => {
    return (
      <div
        className={composeClassName(
          'flex items-center gap-[2px] rounded-sm border-[1px] border-solid border-E50 bg-E30 px-1 py-1',
          className && className
        )}
      >
        <>
          {contentBefore && contentBefore}
          <span className="text-body text-text-primary">{children}</span>
          <IconSaxIcon
            name="close"
            size={16}
            strokeColor={theme?.colors?.E75}
            strokeWidth={2}
            onClick={onClear}
          />
        </>
      </div>
    );
  };

export { DropDownInputMultipleValueDefaultContent };
