import React, { useId } from 'react';
import { Checkbox } from '../../../../atoms/checkbox';
import { TableSelectionCellProps } from './types';

const TableSelectionCell: React.FC<TableSelectionCellProps> = ({
  cellProps,
  ...props
}) => {
  const id = useId();

  return (
    <td className="py-[10px] px-3 align-middle" {...cellProps}>
      <Checkbox id={id} {...props} size="large" />
    </td>
  );
};

export { TableSelectionCell };
