import { useTranslation } from 'react-i18next';
import React from 'react';

import { OutgoingSubTransactionStatusCellContentProps } from './types';
import { PayoutTransactionStatus } from '@/features/admin/payouts/types';
import { theme } from '@/utils/util_theme';
import { IconSaxIcon } from '@/components/design-system/atoms/icon';
import { IconSaxIconName } from '@/components/design-system/atoms/icon/iconsax-icon/types';
import { composeClassName } from '@/utils/util_helpers';

const OutgoingSubTransactionStatusCellContent: React.FC<OutgoingSubTransactionStatusCellContentProps> =
  ({ status }) => {
    const { t } = useTranslation();

    const textColor: { [key in PayoutTransactionStatus]: string } = {
      pending: theme.colors.R500,
      resolved: theme.colors.J300,
    };

    const iconName: { [key in PayoutTransactionStatus]: IconSaxIconName } = {
      pending: 'clock-1',
      resolved: 'tick-circle',
    };

    return (
      <div className="inline-block">
        <div className={composeClassName('flex items-center gap-1')}>
          <IconSaxIcon
            size={18}
            strokeColor={textColor[status]}
            name={iconName[status]}
            strokeWidth={1.3}
          />
          <span
            className={composeClassName('text-body')}
            style={{ color: textColor[status] }}
          >
            {t(
              `js.features.admin.transactions.outgoing_transactions_list.transaction_status.${status}`
            )}
          </span>
        </div>
      </div>
    );
  };

export { OutgoingSubTransactionStatusCellContent };
