import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RentReportStatusCellContentProps } from './types';
import { IconSaxIconName } from '@/components/design-system/atoms/icon/iconsax-icon/types';
import { theme } from '@/utils/util_theme';
import { composeClassName } from '@/utils/util_helpers';
import { IconSaxIcon } from '@/components/design-system/atoms/icon';

const RentReportStatusCellContent: React.FC<RentReportStatusCellContentProps> =
  ({ status, className }) => {
    const { t } = useTranslation();

    const iconName: IconSaxIconName = useMemo(() => {
      switch (status) {
        case 'final':
          return 'tick-circle';
        default:
          return 'minus-cirlce';
      }
    }, [status]);

    const strokeColor: string = useMemo(() => {
      switch (status) {
        case 'final':
          return theme?.colors?.fill?.icon?.successHeavy;
        default:
          return theme?.colors?.fill?.icon?.primaryHeavy;
      }
    }, [status]);

    const stringColor: string = useMemo(() => {
      switch (status) {
        case 'final':
          return 'text-text-successHeavy';
        default:
          return 'text-text-primaryHeavy';
      }
    }, [status]);

    return (
      <div
        className={composeClassName(
          'flex items-center',
          className && className
        )}
      >
        <IconSaxIcon
          name={iconName}
          strokeWidth={1.3}
          strokeColor={strokeColor}
          size={24}
        />
        <span
          className={composeClassName(
            'line-clamp-1 overflow-hidden break-all pl-2 text-body font-medium',
            stringColor
          )}
          style={{ whiteSpace: 'initial' }}
        >
          {t(
            `js.features.admin.rent_report.rent_report_status_cell_content.${status}`
          )}
        </span>
      </div>
    );
  };

export { RentReportStatusCellContent };
