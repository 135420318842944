import React, { useCallback } from 'react';
import { PopUpPropsType } from './types';
import { Modal } from '@/components/design-system/atoms/modal';
import { PopUp } from '@/components/design-system/molecules/pop-up';
import { usePopUpConfirmation } from './hooks';
import { use } from 'vue/types/umd';

const PopUpConfirmation = ({
  isOpened = false,
  type = 'default',
  title,
  description,
  onClose = () => {},
  onConfirm,
  confirmLabel = 'Yes',
  cancelLabel = 'No',
}: PopUpPropsType & { isOpened?: boolean }) => {
  const { closePopUpConfirmation } = usePopUpConfirmation();
  const handleClose = useCallback(() => {
    closePopUpConfirmation();
    onClose && onClose();
  }, [closePopUpConfirmation, onClose]);

  const handleConfirm = useCallback(() => {
    onConfirm && onConfirm();
    handleClose();
  }, [onConfirm, handleClose]);

  return (
    <Modal open={isOpened} onClose={() => onClose && onClose()}>
      <PopUp
        type={type}
        title={title}
        description={description}
        primaryButtonProps={{
          onClick: () => handleConfirm(),
          title: confirmLabel,
        }}
        secondaryButtonProps={{
          onClick: () => handleClose(),
          title: cancelLabel,
        }}
      />
    </Modal>
  );
};

export { PopUpConfirmation };
