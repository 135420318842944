import { getI18n } from 'react-i18next';
import { endOfDay, format, lastDayOfMonth, sub } from 'date-fns';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { HouseRentStatusFilter } from '../../../../types';

const i18n = getI18n();

type HouseRentStatusOption = {
  status: HouseRentStatusFilter;
  displayValue: string;
};

const houseRentStatus: Array<HouseRentStatusOption> = [
  {
    status: 'paid',
    displayValue: i18n.t(
      'js.features.admin.rents.rent_houses_overview.status_options.paid'
    ),
  },
  {
    status: 'unpaid',
    displayValue: i18n.t(
      'js.features.admin.rents.rent_houses_overview.status_options.unpaid'
    ),
  },
];

const useRentFilters = () => {
  const [selectedHouseRentStatus, setSelectedHouseRentStatus] = useState<
    HouseRentStatusOption | undefined
  >(undefined);
  const [rentStatusQuery, setRentStatusQuery] = useState('');
  const [startDate, setStartDate] = useState<Date | undefined>(
    sub(new Date(format(new Date(), 'yyyy-MM-01')), { months: 0 })
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    endOfDay(
      lastDayOfMonth(
        sub(new Date(format(new Date(), 'yyyy-MM-01')), { months: 0 })
      )
    )
  );
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);

  const setDateRange = useCallback(
    (new_date_range: [Date | null, Date | null]) => {
      setStartDate(new_date_range[0] ?? undefined);
      setEndDate(new_date_range[1] ?? undefined);
    },
    []
  );

  const filteredHouseRentsStatusArray = useMemo(() => {
    return houseRentStatus.filter((statusObject) =>
      statusObject.displayValue.includes(rentStatusQuery)
    );
  }, [rentStatusQuery]);

  return {
    selectedHouseRentStatus,
    setSelectedHouseRentStatus,
    setRentStatusQuery,
    rentStatusQuery,
    filteredHouseRentsStatusArray,
    startDate,
    endDate,
    setDateRange,
    searchQuery,
    setSearchQuery,
  };
};

const GetRentsSummaryFiltersContext = createContext<
  ReturnType<typeof useRentFilters> | undefined
>(undefined);

const GetRentsSummaryFiltersContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const hook = useRentFilters();

  return (
    <GetRentsSummaryFiltersContext.Provider value={hook}>
      {children}
    </GetRentsSummaryFiltersContext.Provider>
  );
};

const useRentsSummaryFiltersContext = () => {
  const context = useContext(GetRentsSummaryFiltersContext);

  if (context === null) {
    throw new Error(
      'useRentsSummaryFiltersContext must be used within a GetRentsSummaryFiltersContextProvider'
    );
  }

  return context;
};

export { GetRentsSummaryFiltersContextProvider, useRentsSummaryFiltersContext };
