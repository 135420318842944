import React from 'react';
import { composeClassName } from '@/utils/util_helpers';
import { useThirdSectionContext } from '../../../../hooks/use-third-section-context';

const PagerIndicator = () => {
  const { options, currentOptionNum } = useThirdSectionContext()!;

  return (
    <div
      className={composeClassName(
        'flex items-center justify-between gap-[10px]'
      )}
    >
      {options.map((value, index) => {
        return (
          <div
            key={value}
            className={composeClassName(
              'h-[10px] w-[10px] cursor-pointer rounded-full bg-E50 transition-colors duration-300',
              currentOptionNum === index + 1 && '!bg-R300'
            )}
          />
        );
      })}
    </div>
  );
};

export { PagerIndicator };
