import React from 'react';
import {
  usePopUpConfirmationProvider,
  PopUpConfirmationContext,
} from './hooks';
import { PopUpConfirmation } from './component';

const PopUpConfirmationProvider: React.FC = ({ children }) => {
  const hook = usePopUpConfirmationProvider();
  return (
    <PopUpConfirmationContext.Provider value={hook}>
      <PopUpConfirmation isOpened={hook.isOpened} {...hook.popUpProps} />
      {children}
    </PopUpConfirmationContext.Provider>
  );
}

export { PopUpConfirmationProvider };
