import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropDown } from '@/components/design-system/molecules/dropdown';
import { useRentsListFiltersContext } from '../../../../hooks/use-rents-list-filter';

const ListRentRemindersDropDown = () => {
  const {
    selectedListRentReminder,
    setSelectedListRentReminder,
    setRentReminderQuery,
    filteredListRentsReminderArray,
  } = useRentsListFiltersContext()!;
  const { t } = useTranslation();

  return (
    <DropDown
      placeholder={
        t('js.features.admin.rents.rent_list.reminders_placeholder') ?? ''
      }
      className="z-50 w-[180px]"
      showLabel
      label={t('js.features.admin.rents.rent_list.reminders_label') ?? ''}
      value={selectedListRentReminder}
      onChange={setSelectedListRentReminder}
      {...(selectedListRentReminder && {
        displayValue: selectedListRentReminder.displayValue,
      })}
      onTextInputChange={(event) => setRentReminderQuery(event.target.value)}
    >
      <DropDown.DropDownOptions>
        {filteredListRentsReminderArray.map((option) => {
          return (
            <DropDown.DropDownOption key={option.displayValue} value={option}>
              <DropDown.DropDownOptionDefaultContent>
                {option.displayValue}
              </DropDown.DropDownOptionDefaultContent>
            </DropDown.DropDownOption>
          );
        })}
      </DropDown.DropDownOptions>
    </DropDown>
  );
};

export { ListRentRemindersDropDown };
