import React, { useCallback } from 'react';
import { IconSaxIcon } from '@/components/design-system/atoms/icon';
import { useNavigationMenuContext } from '../../hooks/use-navigation-menu-context';

const CollapsingToggle: React.FC = () => {
  const { displayValue, setDisplayValue } = useNavigationMenuContext()!;

  const changeDisplayState = useCallback(() => {
    setDisplayValue &&
      setDisplayValue(displayValue === 'collapsed' ? 'expanded' : 'collapsed');
  }, [displayValue, setDisplayValue]);

  return (
    <button
      className="bg-whit absolute right-[-22px] top-[160px] flex items-center justify-center rounded-full border-[1px] border-solid border-E50 bg-white"
      type="button"
      onClick={changeDisplayState}
    >
      <IconSaxIcon
        className="p-[10px]"
        name={displayValue === 'collapsed' ? 'menu-expand2' : 'menu-collapse2'}
      />
    </button>
  );
};

export { CollapsingToggle };
