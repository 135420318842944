var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('PressHeader'),_vm._v(" "),_c('section',{staticClass:"o-grid3col o-grid3col--r1L2"},[_c('PressNav'),_vm._v(" "),_c('div',{staticClass:"o-grid3col__main"},[_c('div',{staticClass:"o-grid3col__main__2col",attrs:{"id":"company"}},[_vm._m(0),_vm._v(" "),_c('PressQuote')],1),_vm._v(" "),_c('div',[_c('h2',{staticClass:"o-grid3col__main__title",staticStyle:{"margin-top":"16px"}},[_vm._v("\n          What are our services ?\n        ")]),_vm._v(" "),_c('ul',{staticClass:"o-grid3col__main__2col"},[_c('li',[_c('IconPurchase'),_vm._v(" "),_vm._m(1)],1),_vm._v(" "),_c('li',[_c('IconRenovation'),_vm._v(" "),_vm._m(2)],1)]),_vm._v(" "),_c('ul',{staticClass:"o-grid3col__main__2col"},[_c('li',[_c('IconDecoration'),_vm._v(" "),_vm._m(3)],1),_vm._v(" "),_c('li',[_c('IconRental'),_vm._v(" "),_vm._m(4)],1)]),_vm._v(" "),_c('ul',{staticClass:"o-grid3col__main__2col"},[_c('li',[_c('IconManagement'),_vm._v(" "),_vm._m(5)],1),_vm._v(" "),_c('li',[_c('IconConsulting'),_vm._v(" "),_vm._m(6)],1)])]),_vm._v(" "),_c('PressTeam'),_vm._v(" "),_c('PressKit'),_vm._v(" "),_c('PressForm')],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h2',[_vm._v("Why Coloc Housing ?")]),_vm._v(" "),_c('p',[_vm._v("\n            The world is changing. We help your investments change with it.\n          ")]),_vm._v(" "),_c('p',[_vm._v("\n            The sharing economy in general is on the rise. Sharing spaces,\n            time and resources, not only increases productivity, but also\n            enhances social interaction, is more sustainable and makes perfect\n            sense. According to an article from Forbes magazine from March\n            2019 the sharing economy is expected to grow from $15 billion in\n            2014 to $335 billion in 2025.\n          ")]),_vm._v(" "),_c('p',[_vm._v("\n            Coloc Housing emerged from the rising trend in Belgium for young\n            people to get together and share a house. Our vision is to help\n            tighten the social fabric in society by facilitating coliving. We\n            do this by creating a community of like-minded people across all\n            walks of life that connect, share experiences and live to the\n            fullest.\n          ")]),_vm._v(" "),_c('p',[_vm._v("\n            Our mission to design, conceptualise and realise beautifully\n            furnished and sustainable coliving spaces that inspire and\n            facilitate coliving. Our goal is to create tools and concepts to\n            connect colocs and improve their coliving experience. We love to\n            organise events to engage our colocs in social interactions.\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Purchase")]),_c('br'),_vm._v("As certified real estate agents\n              we help you find the right property.\n            ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Renovation")]),_c('br'),_vm._v("Our team of architects,\n              interior designers and renovation contractors make sure each\n              co-living project meets the highest building standards.\n            ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Decoration")]),_c('br'),_vm._v("We combine a mix of\n              contemporary design and vintage interior elements.\n            ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Rental")]),_c('br'),_vm._v("We take care of the visits,\n              contracts, move-ins and departures.\n            ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Property management")]),_c('br'),_vm._v("Your property is in\n              the best hands. We take care of everything to A to Z.\n            ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("We buy")]),_c('br'),_vm._v("Contact us if you want to sell or\n              lease your property to Coloc Housing.\n            ")])
}]

export { render, staticRenderFns }