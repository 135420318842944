import { useTranslation } from 'react-i18next';
import React from 'react';
import { DatePicker } from '@/components/design-system/molecules/datepicker';
import { SearchInput } from '@/components/design-system/molecules/search-input';

import { DropDown } from '@/components/design-system/molecules/dropdown';
import { BankAccountDropDown } from './components/bank-account-dropdown';
import { useTransactionsFiltersContext } from '@/features/admin/transactions/components/incoming-transactions-list/hooks/use-transactions-list-filter';

const TransactionsListFilterContainer = () => {
  const {
    setSearchQuery,
    searchQuery,
    setDateRange,
    startDate,
    endDate,
    filteredTransactionPairedStatusArray,
    setPairStatusQuery,
    setSelectedTransactionPairStatus,
    selectedTransactionPairStatus,
  } = useTransactionsFiltersContext()!;
  const { t } = useTranslation();

  return (
    <div className="relative flex w-full items-end justify-between pt-4">
      <div className="flex gap-4">
        <SearchInput
          placeholder={
            t(
              'js.features.admin.transactions.transactions_list.filters.search_placeholder'
            ) ?? ''
          }
          className="w-[270px]"
          label={
            t(
              'js.features.admin.transactions.transactions_list.filters.search_label'
            ) ?? ''
          }
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <DatePicker
          className="w-[320px]"
          onChange={setDateRange}
          label={
            t(
              'js.features.admin.transactions.transactions_list.filters.date_range_label'
            ) ?? ''
          }
          dateRange
          type="month"
          startValue={startDate}
          endValue={endDate}
          placeholder={t('js.features.shared.date.range.placeholder') ?? ''}
        />
        <DropDown
          placeholder={
            t(
              'js.features.admin.transactions.transactions_list.filters.paired_placeholder'
            ) ?? ''
          }
          className="z-50 w-[180px]"
          showLabel
          label={
            t(
              'js.features.admin.transactions.transactions_list.filters.paired_label'
            ) ?? ''
          }
          value={selectedTransactionPairStatus}
          onChange={setSelectedTransactionPairStatus}
          {...(selectedTransactionPairStatus && {
            displayValue: selectedTransactionPairStatus.displayValue,
          })}
          onTextInputChange={(event) => setPairStatusQuery(event.target.value)}
        >
          <DropDown.DropDownOptions>
            {filteredTransactionPairedStatusArray.map((option) => {
              return (
                <DropDown.DropDownOption
                  key={option.displayValue}
                  value={option}
                >
                  <DropDown.DropDownOptionDefaultContent>
                    {option.displayValue}
                  </DropDown.DropDownOptionDefaultContent>
                </DropDown.DropDownOption>
              );
            })}
          </DropDown.DropDownOptions>
        </DropDown>
        <BankAccountDropDown />
      </div>
    </div>
  );
};

export { TransactionsListFilterContainer };
