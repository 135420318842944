export type BaseComponent = {
  className?: string;
};

export type BaseUser = {
  id: number;
  first_name: string;
  last_name: string;
};

export type ResultsSummary = {
  totalItems: number;
  fromItem: number;
  untilItem: number;
};

const langOptions = ['en', 'nl', 'fr'];

export type LangOption = typeof langOptions[number];

export type I18nObject = {
  [key in LangOption]: string;
};

export type CurrencyIso = 'EUR' | 'USD';

export type Amount = {
  cents: number;
  currency_iso: CurrencyIso;
};
