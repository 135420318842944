import React from 'react';
import { usePaginationContext } from '../../hooks/use-pagination-context';
import { PaginationItem } from '../pagination-item';
import { PaginationRestItemProps } from './types';

const PaginationRestItem: React.FC<PaginationRestItemProps> = ({
  isPrevious = false,
}) => {
  const { isNextTruncable, isPreviousTruncable, disabled } =
    usePaginationContext()!;

  return (isPreviousTruncable && isPrevious === true) ||
    (isNextTruncable && !isPrevious) ? (
    <PaginationItem
      className="cursor-default"
      isRestIndicatorButton
      disabled={disabled}
    />
  ) : null;
};

export { PaginationRestItem };
