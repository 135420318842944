import React from 'react';
import { composeClassName } from '@/utils/util_helpers';
import { TableContentProps } from './types';

const TableContent: React.FC<TableContentProps> = ({ children, className }) => {
  return (
    <div className="min-h-fit w-full">
      <table className={composeClassName('min-h-fit', className && className)}>
        {children}
      </table>
    </div>
  );
};

export { TableContent };
