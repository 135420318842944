import React, { forwardRef, useId } from 'react';
import {
  FloatingPortal,
  useDelayGroup,
  useDelayGroupContext,
  useMergeRefs,
  useTransitionStyles,
} from '@floating-ui/react';
import { usePopOverContext } from '../../hooks/use-pop-over-context';
import { PopOverContentProps } from './types';
import { IconSaxIcon } from '@/components/design-system/atoms/icon';
import { composeClassName } from '@/utils/util_helpers';

const PopOverContent = forwardRef<HTMLDivElement, PopOverContentProps>(
  ({ ...props }, propRef) => {
    const state = usePopOverContext()!;
    const id = useId();
    const { isInstantPhase, currentId } = useDelayGroupContext();
    const ref = useMergeRefs([state.refs.setFloating, propRef]);

    useDelayGroup(state.context, { id });

    const instantDuration = 0;
    const duration = 250;

    const { isMounted, styles } = useTransitionStyles(state.context, {
      duration: isInstantPhase
        ? {
            open: instantDuration,
            // `id` is this component's `id`
            // `currentId` is the current group's `id`
            close: currentId === id ? duration : instantDuration,
          }
        : duration,
      initial: {
        opacity: 0,
      },
    });

    if (!isMounted) return null;

    const { children, title, text, iconName, className, ...restProps } = props;

    return (
      <FloatingPortal>
        <div
          className={composeClassName(
            'flex max-w-max flex-col gap-2 rounded-[8px] bg-white p-4 shadow-large',
            className && className
          )}
          ref={ref}
          style={{
            ...state.floatingStyles,
            ...styles,
          }}
          {...state.getFloatingProps(restProps)}
        >
          {iconName && (
            <IconSaxIcon name={iconName} size={32} strokeWidth={1.3} />
          )}
          {title && <span className="text-primary text-lead2">{title}</span>}
          {text && <span className="text-secondary text-body">{text}</span>}
          {children}
        </div>
      </FloatingPortal>
    );
  }
);

PopOverContent.displayName = 'PopOverContent';

export { PopOverContent };
