import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMutation } from '@tanstack/react-query';
import { endOfMonth } from 'date-fns';
import { House } from '@/types/admin';

import { generateRentReports } from '@/services/coloc-api/admin';
import { showToast } from '@/services/toast-service';
import { useGetRentReportListContext } from '../use-get-rent-report-list';
import { RentReport } from '@/features/admin/rents/types';

const useCreateRentReport = () => {
  const { fetch } = useGetRentReportListContext()!;
  const [showCreateRentReportModal, toggleShowCreateRentReportModal] =
    useState<{
      show: boolean;
    }>({
      show: false,
    });
  const [showCreationConfirmationModal, toggleShowCreationConfirmationModal] =
    useState<{
      show: boolean;
      report: RentReport | undefined;
    }>({
      show: false,
      report: undefined,
    });
  // House
  const [selectedHouse, setSelectedHouseInternal] = useState<House | undefined>(
    undefined
  );
  const [housesQuery, setHousesQuery] = useState('');
  // Date
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);

  useEffect(() => {
    if (!showCreateRentReportModal.show) {
      setSelectedHouseInternal(undefined);
      setSelectedDate(undefined);
      setHousesQuery('');
    }
  }, [showCreateRentReportModal.show]);

  const setSelectedHouse = useCallback(
    (house: House | undefined) => {
      if (selectedHouse !== house) {
        setSelectedDate(undefined);
      }

      setSelectedHouseInternal(house);
    },
    [selectedHouse]
  );

  const mutate = useMutation({
    mutationKey: ['generateRentReports'],
    mutationFn: () => {
      return generateRentReports({
        house_ids: [String(selectedHouse?.id) ?? ''],
        date: selectedDate ? endOfMonth(selectedDate) : new Date(),
      });
    },
    onSuccess: (data) => {
      toggleShowCreateRentReportModal({ show: false });
      fetch.refetch();
      toggleShowCreationConfirmationModal({
        show: true,
        report: data.data.data[0],
      });
    },
    onError: () => {
      toggleShowCreateRentReportModal({ show: false });
      showToast.error({});
    },
  });

  const reportIsCreatable = useMemo(() => {
    return selectedHouse && selectedDate;
  }, [selectedDate, selectedHouse]);

  return {
    showCreateRentReportModal,
    toggleShowCreateRentReportModal,
    selectedHouse,
    setSelectedHouse,
    housesQuery,
    setHousesQuery,
    selectedDate,
    setSelectedDate,
    reportIsCreatable,
    mutate,
    showCreationConfirmationModal,
    toggleShowCreationConfirmationModal,
  };
};

const CreateRentReportContext = createContext<
  ReturnType<typeof useCreateRentReport> | undefined
>(undefined);

const CreateRentReportContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const hook = useCreateRentReport();

  return (
    <CreateRentReportContext.Provider value={hook}>
      {children}
    </CreateRentReportContext.Provider>
  );
};

const useCreateRentReportContext = () => {
  const context = useContext(CreateRentReportContext)!;

  if (context === null) {
    throw new Error(
      'useCreateRentReportContext must be used within a PairTransactionWithRentContextProvider'
    );
  }

  return context;
};

export { useCreateRentReportContext, CreateRentReportContextProvider };
