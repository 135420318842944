import {
  ColumnDefBase,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@/components/design-system/molecules/table';
import { Button } from '@/components/design-system/atoms/button';
import {
  ColocAPIConnectedTableImperativeMethods,
  ColocAPIConnectedTableProps,
} from './types';
import { TableColumnSortingOptions } from '@/components/design-system/molecules/table/types';
import { composeClassName } from '@/utils/util_helpers';

const TableEmptyState: React.FC<{
  status: 'loading' | 'error' | 'success';
  numColumns: number;
  numRows: number;
  refetch: () => void;
}> = ({ status, numColumns, numRows, refetch }) => {
  const { t } = useTranslation();

  const emptyState = useMemo(() => {
    if (status === 'loading') {
      return 'Loading';
    }

    if (status === 'error') {
      return 'Error';
    }

    return 'NoResults';
  }, [status]);

  return (
    <Table.TableEmptyStateDefaultContent
      numColumns={numColumns}
      numRows={numRows}
      type={emptyState}
      {...(emptyState === 'Error' && {
        actionButton: (
          <Button onClick={refetch}>
            {t('js.features.shared.connected_table.retry') ?? ''}
          </Button>
        ),
      })}
    />
  );
};

const InnerColocAPIConnectedTable = <T1, T2 extends string>(
  props: ColocAPIConnectedTableProps<T1, T2>,
  ref: React.ForwardedRef<ColocAPIConnectedTableImperativeMethods>
) => {
  const {
    dataSource,
    columns,
    actions,
    hidePagination = false,
    enableRowSelection = false,
    enableMultiRowSelection = false,
    renderSubComponent,
  } = props;

  const {
    fetch,
    pageLimit,
    setPage,
    setPageLimit,
    sortDirection,
    setColumnToSort,
    setRowSelection,
    rowSelection,
    setResetTableExpandedRowsSelectionCallBack,
    setResetTableRowSelectionCallBack,
  } = dataSource;

  const columnHelper = createColumnHelper<T1>();
  const sortableColumns = dataSource.sortableColumns as ReadonlyArray<string>;
  const selectedColumnToSort = dataSource.selectedColumnToSort as string | null;

  const { data, status, isLoading, refetch } = fetch;

  const showBulkActions = useMemo(() => {
    if (rowSelection) {
      return Object.keys(rowSelection).length >= 1;
    }
    return false;
  }, [rowSelection]);

  const selectionColumns = useMemo(
    () => [
      columnHelper.display({
        id: 'select',
        cell: ({ row }) => (
          <Table.TableSelectionCell
            className={composeClassName(
              'sticky left-[0px] z-5',
              row.index % 2 === 0 ? 'bg-white' : 'bg-E30'
            )}
            checked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
            disabled={!row.getCanSelect()}
          />
        ),
        header: ({ table }) => (
          <Table.TableHeaderSelectionCell
            className={composeClassName('sticky left-[0px] z-40 bg-E30')}
            checked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        ),
      }),
    ],
    [columnHelper]
  );

  const mergedColumns: ColumnDefBase<T1, any>[] = useMemo(() => {
    if (enableMultiRowSelection || enableMultiRowSelection) {
      return [...selectionColumns, ...columns];
    }
    return columns;
  }, [columns, enableMultiRowSelection, selectionColumns]);

  const table = useReactTable({
    data: data?.data.data ?? [],
    // @ts-ignore
    columns: mergedColumns,
    state: {
      rowSelection,
    },
    getRowCanExpand: () => true,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection,
    enableMultiRowSelection,
    ...(setRowSelection && { onRowSelectionChange: setRowSelection }),
  });

  const getSortingOrder = useCallback(
    (id: string): TableColumnSortingOptions => {
      if (id === selectedColumnToSort) {
        if (sortDirection === 'ascending') {
          return 'asc';
        }

        if (sortDirection === 'descending') {
          return 'desc';
        }

        return 'none';
      }

      return 'none';
    },
    [selectedColumnToSort, sortDirection]
  );

  // Define imperative functions you want to trigger by ref.
  useImperativeHandle(ref, () => ({
    refetch: () => {
      refetch();
    },
  }));

  useEffect(() => {
    setResetTableExpandedRowsSelectionCallBack &&
      setResetTableExpandedRowsSelectionCallBack(() => table.resetExpanded());
    setResetTableRowSelectionCallBack &&
      setResetTableRowSelectionCallBack(() => table.resetRowSelection());
  }, [
    setResetTableExpandedRowsSelectionCallBack,
    setResetTableRowSelectionCallBack,
    table,
  ]);

  return (
    <Table className="pt-4">
      {actions ? (
        <Table.TableBulkActions show={showBulkActions}>
          {actions.map((action) => {
            return (
              <Table.TableBulkAction
                key={action.text}
                iconName={action.iconName}
                disabled={action.disabled}
                loading={action.loading}
                onClick={(e) => {
                  action.onClick && action.onClick(e);
                  table.resetExpanded();
                }}
                loadingLabelText={action.loadingText}
              >
                {action.text}
              </Table.TableBulkAction>
            );
          })}
        </Table.TableBulkActions>
      ) : (
        <></>
      )}
      <Table.TableContent className="scrollbar-hide h-full w-full flex-1 overflow-x-scroll">
        <Table.TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.TableRow index={1} key={headerGroup.id}>
              {headerGroup.headers.map((header) =>
                header.id.endsWith('select') ? (
                  <React.Fragment key={header.id}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </React.Fragment>
                ) : (
                  <Table.TableHeaderCell
                    // @ts-expect-error
                    onClick={() => setColumnToSort(header.id)}
                    key={header.id}
                    {...(sortableColumns.includes(header.id) && {
                      sortingOrder: getSortingOrder(header.id),
                    })}
                    className={header.column.columnDef.meta?.className ?? ''}
                  >
                    <Table.TableHeaderCellDefaultContent>
                      {header.isPlaceholder
                        ? ''
                        : (flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          ) as unknown as string)}
                    </Table.TableHeaderCellDefaultContent>
                  </Table.TableHeaderCell>
                )
              )}
            </Table.TableRow>
          ))}
        </Table.TableHeader>
        {data?.data &&
        data?.data.data?.length >= 1 &&
        status === 'success' &&
        !isLoading ? (
          <>
            <Table.TableBody>
              {table.getRowModel().rows.map((row, index) => (
                <React.Fragment key={row.id}>
                  <Table.TableRow index={index} className="relative">
                    {row
                      .getVisibleCells()
                      .map((cell) =>
                        cell.id.endsWith('select') ? (
                          <React.Fragment key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </React.Fragment>
                        ) : (
                          <Table.TableCell
                            key={cell.id}
                            className={cell.column.columnDef.meta?.className ?? ''}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Table.TableCell>
                        )
                      )}
                  </Table.TableRow>
                  {renderSubComponent && row.getIsExpanded() && (
                    <tr>
                      {/* 2nd row is a custom 1 cell row */}
                      <td colSpan={row.getVisibleCells().length}>
                        {renderSubComponent({ row })}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </Table.TableBody>
          </>
        ) : (
          <Table.TableBody>
            <TableEmptyState
              status={status}
              numColumns={columns.length}
              numRows={pageLimit}
              refetch={refetch}
            />
          </Table.TableBody>
        )}
      </Table.TableContent>
      {!hidePagination ? (
        <Table.TablePagination
          disabled={!data?.data.pagination}
          totalPages={data?.data.pagination.total_pages ?? 1}
          currentPage={data?.data.pagination.page ?? 1}
          totalItems={data?.data.pagination.total ?? 1}
          pageLimit={pageLimit}
          onPageChange={(page) => {
            setPage(page);
            table.resetExpanded();
            const tableWrapperContainer = document.getElementById(
              'table-wrapper-container'
            );
            tableWrapperContainer?.scroll({
              top: 0,
              behavior: 'smooth',
            });
          }}
          onPageLimitChange={(limit) => {
            table.resetExpanded();
            setPageLimit(limit);
          }}
        />
      ) : (
        <></>
      )}
    </Table>
  );
};

const ColocAPIConnectedTable = React.forwardRef(
  InnerColocAPIConnectedTable
) as <T1, T2 extends string>(
  props: ColocAPIConnectedTableProps<T1, T2> & {
    ref?: React.ForwardedRef<ColocAPIConnectedTableImperativeMethods>;
  }
) => ReturnType<typeof InnerColocAPIConnectedTable>;

export { ColocAPIConnectedTable };
