import React, { useMemo, useRef } from 'react';
import {
  SnapList,
  SnapItem,
  useScroll,
  useDragToScroll,
} from 'react-snaplist-carousel';
import {
  CitiesListContextProvider,
  useCitiesListContext,
} from '@/features/marketplace/hooks/use-get-cities';
import { DEFAULT_CONTENT_WIDTH_IN_NUM } from '../../../../../../../constants';
import { CityCard } from '../city-card';
import { useWindowDimensions } from '@/hooks/use-window-dimensions';

const CitiesHorizontalCarousel = () => {
  const snapList = useRef(null);
  const {
    windowDimensions: { width: screenWidth },
  } = useWindowDimensions();

  const { citiesSelectionHomePage } = useCitiesListContext()!;

  const goToChildren = useScroll({ ref: snapList });
  useDragToScroll({ ref: snapList });

  const paddingHorizontal = useMemo(() => {
    if (
      screenWidth >=
      DEFAULT_CONTENT_WIDTH_IN_NUM + DEFAULT_CONTENT_WIDTH_IN_NUM * 0.2
    ) {
      return (screenWidth - DEFAULT_CONTENT_WIDTH_IN_NUM) / 2;
    }
    return screenWidth * 0.1;
  }, [screenWidth]);

  return (
    <SnapList ref={snapList} direction="horizontal" className="mt-10 mb-7">
      <SnapItem
        margin={{ left: `${paddingHorizontal}px`, right: `8px` }}
        snapAlign="center"
      >
        <CityCard
          cityId={citiesSelectionHomePage.bru.id}
          cityName={citiesSelectionHomePage.bru.name}
          onClick={() => goToChildren(0)}
        />
      </SnapItem>
      <SnapItem margin={{ left: `8px`, right: `8px` }} snapAlign="center">
        <CityCard
          cityId={citiesSelectionHomePage.ghe.id}
          cityName={citiesSelectionHomePage.ghe.name}
          onClick={() => goToChildren(1)}
        />
      </SnapItem>
      <SnapItem margin={{ left: `8px`, right: `8px` }} snapAlign="center">
        <CityCard
          cityId={citiesSelectionHomePage.ant.id}
          cityName={citiesSelectionHomePage.ant.name}
          onClick={() => goToChildren(2)}
        />
      </SnapItem>
      <SnapItem
        margin={{ left: `8px`, right: `${paddingHorizontal}px` }}
        snapAlign="center"
      >
        <CityCard
          cityId={citiesSelectionHomePage.leu.id}
          cityName={citiesSelectionHomePage.leu.name}
          onClick={() => goToChildren(3)}
        />
      </SnapItem>
    </SnapList>
  );
};

const CitiesListVertical = () => {
  const { citiesSelectionHomePage } = useCitiesListContext()!;

  return (
    <div className="my-7 flex w-full flex-col gap-4">
      {Object.keys(citiesSelectionHomePage).map((key) => {
        return (
          <CityCard
            key={key}
            cityId={
              citiesSelectionHomePage[
                key as keyof typeof citiesSelectionHomePage
              ].id
            }
            cityName={
              citiesSelectionHomePage[
                key as keyof typeof citiesSelectionHomePage
              ].name
            }
          />
        );
      })}
    </div>
  );
};

const CitiesList = () => {
  const { screenIsLargeOrEqualThan } = useWindowDimensions();

  return (
    <CitiesListContextProvider>
      {screenIsLargeOrEqualThan(450) ? (
        <CitiesHorizontalCarousel />
      ) : (
        <CitiesListVertical />
      )}
    </CitiesListContextProvider>
  );
};

export { CitiesList };
