import { useTranslation } from 'react-i18next';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useGetPayoutPayment } from '@/features/shared/data-access-payout-payments/hooks/use-get-payout-payment';
import { useGetOutgoingTransactionsListContext } from '../use-get-outgoing-transactions';
import { GetPayoutPaymentResponse } from '@/lib/coloc-api/admin';
import { showToast } from '@/services/toast-service';

const useGetOutgoingTransactionPayment = () => {
  const { t } = useTranslation();
  const { updateOutgoingTransactionsInCache } =
    useGetOutgoingTransactionsListContext()!;
  const [paymentId, setPaymentId] = useState<string | undefined>(undefined);
  const [redirected, setRedirected] = useState<boolean>(false);

  const [showFetchedPaymentSuccessModal, toggleShowFetchedPaymentSuccessModal] =
    useState<{
      show: boolean;
      payment: GetPayoutPaymentResponse | undefined;
    }>({
      show: false,
      payment: undefined,
    });
  const [showFetchedPaymentFailedModal, toggleShowFetchedPaymentFailedModal] =
    useState<{
      show: boolean;
      payment_id: string | undefined;
      title: string | undefined;
      text: string | undefined;
      retryFetching: boolean;
    }>({
      show: false,
      payment_id: undefined,
      title: undefined,
      text: undefined,
      retryFetching: false,
    });

  // Function is reused between state passed via the URL and the state of the payment returned by the API.
  const handleError = useCallback(
    (error: string) => {
      switch (error) {
        case 'aborted':
          toggleShowFetchedPaymentFailedModal({
            show: true,
            payment_id: paymentId,
            title:
              t(
                'js.features.admin.transactions.outgoing_transactions_list.payout_confirmation.cancelled_title'
              ) ?? '',
            text:
              t(
                'js.features.admin.transactions.outgoing_transactions_list.payout_confirmation.cancelled_text'
              ) ?? '',
            retryFetching: false,
          });
          break;
        case 'rejected':
          toggleShowFetchedPaymentFailedModal({
            show: true,
            payment_id: paymentId,
            title:
              t(
                'js.features.admin.transactions.outgoing_transactions_list.payout_confirmation.rejected_title'
              ) ?? '',
            text:
              t(
                'js.features.admin.transactions.outgoing_transactions_list.payout_confirmation.rejected_text'
              ) ?? '',
            retryFetching: false,
          });
          break;
        default:
          toggleShowFetchedPaymentFailedModal({
            show: true,
            payment_id: paymentId,
            title:
              t(
                'js.features.admin.transactions.outgoing_transactions_list.payout_confirmation.generic_error_title'
              ) ?? '',
            text:
              t(
                'js.features.admin.transactions.outgoing_transactions_list.payout_confirmation.generic_error_text'
              ) ?? '',
            retryFetching: true,
          });
      }
    },
    [paymentId, t]
  );

  // Check for URL parameters
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const passedQueryParams = Object.fromEntries(urlSearchParams.entries());
    const errorFromQuery = passedQueryParams?.error;
    const paymentIdFromQuery = passedQueryParams?.payment_id;

    if (paymentIdFromQuery) {
      !errorFromQuery && setPaymentId(paymentIdFromQuery);
      setRedirected(true);
    }

    if (errorFromQuery) {
      handleError(errorFromQuery);
    }
  }, [handleError, paymentId, t]);

  const resetState = useCallback(() => {
    const url = new URL(window.location.href);
    url.searchParams.delete('payment_id');
    window.history.replaceState(null, '', url.toString());
    setPaymentId(undefined);
    setRedirected(false);
  }, []);

  const hideErrorModal = useCallback(() => {
    toggleShowFetchedPaymentFailedModal({
      show: false,
      title: undefined,
      text: undefined,
      payment_id: undefined,
      retryFetching: false,
    });
  }, []);

  const fetch = useGetPayoutPayment({
    ...(paymentId && {
      params: {
        id: paymentId,
        force_sync: true,
      },
      onSuccessCallback: (data) => {
        updateOutgoingTransactionsInCache(data.data.payment.payouts);
        const paymentContainsError =
          data.data.payment.attributes.status === 'rejected' ||
          data.data.payment.attributes.status === 'cancelled';
        paymentContainsError &&
          handleError(data.data.payment.attributes.status);

        if (!paymentContainsError) {
          /*
            In case the user is redirected from Ponto show a confirmation modal.
           In case a sync for a specific payment has been requested show a toast that the payment has been updated to the latest status.
          */
          if (redirected) {
            toggleShowFetchedPaymentSuccessModal({
              show: true,
              payment: data.data,
            });
          } else {
            showToast.success({
              title:
                t(
                  'js.features.admin.transactions.outgoing_transactions_list.payout_confirmation.payment_synced_toast_title'
                ) ?? '',
              text:
                t(
                  'js.features.admin.transactions.outgoing_transactions_list.payout_confirmation.payment_synced_toast_text'
                ) ?? '',
            });
          }
        }
        resetState();
      },
      onErrorCallback: () => {
        // If redirected from Ponto show a modal. Otherwise show toast that the sync failed.
        if (redirected) {
          toggleShowFetchedPaymentFailedModal({
            show: true,
            payment_id: paymentId,
            title:
              t(
                'js.features.admin.transactions.outgoing_transactions_list.payout_confirmation.generic_error_title'
              ) ?? '',
            text:
              t(
                'js.features.admin.transactions.outgoing_transactions_list.payout_confirmation.generic_error_text'
              ) ?? '',
            retryFetching: true,
          });
        } else {
          showToast.error({
            title:
              t(
                'js.features.admin.transactions.outgoing_transactions_list.payout_confirmation.payment_synced_failed_toast_title'
              ) ?? '',
            text:
              t(
                'js.features.admin.transactions.outgoing_transactions_list.payout_confirmation.payment_synced_failed_toast_text'
              ) ?? '',
          });
        }
        resetState();
      },
    }),
  })!;

  const fetchingPayment = useMemo(() => {
    return fetch.fetch.isFetching;
  }, [fetch.fetch.isFetching]);

  return {
    setPaymentId,
    fetchingPayment,
    paymentId,
    showFetchedPaymentSuccessModal,
    toggleShowFetchedPaymentSuccessModal,
    showFetchedPaymentFailedModal,
    toggleShowFetchedPaymentFailedModal,
    hideErrorModal,
  };
};

const GetOutgoingTransactionPaymentContext = createContext<
  ReturnType<typeof useGetOutgoingTransactionPayment> | undefined
>(undefined);

const GetOutgoingTransactionPaymentContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const hook = useGetOutgoingTransactionPayment();

  return (
    <GetOutgoingTransactionPaymentContext.Provider value={hook}>
      {children}
    </GetOutgoingTransactionPaymentContext.Provider>
  );
};

const useGetOutgoingTransactionPaymentContext = () => {
  const context = useContext(GetOutgoingTransactionPaymentContext)!;

  if (context === null) {
    throw new Error(
      'useGetOutgoingTransactionPaymentContext must be used within a GetOutgoingTransactionPaymentContext'
    );
  }

  return context;
};

export {
  GetOutgoingTransactionPaymentContextProvider,
  useGetOutgoingTransactionPaymentContext,
};
