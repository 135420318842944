import React, { useMemo, useRef } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { Table } from '@/components/design-system/molecules/table';

import { ColocAPIConnectedTable } from '@/features/shared/feature-coloc-api-connected-table';
import { PayoutsSortType } from '@/lib/coloc-api/admin';
import { ColocAPIConnectedTableImperativeMethods } from '@/features/shared/feature-coloc-api-connected-table/types';

import { Payout } from '@/features/admin/payouts/types';
import { useGetOutgoingTransactionsListContext } from '../../hooks/use-get-outgoing-transactions';
import { getFullDate } from '@/utils/util_helpers/formatting/dates';
import { OutgoingTransactionStatusCellContent } from './components/outgoing-transaction-status-cell-content';
import { OutgoingTransactionsRow } from './components/outgoing-transactions-row';
import { OutgoingTransactionAmountCellContent } from './components/outgoing-transaction-amount-cell-content';
import { PayoutRentDetailModal } from '@/features/shared/payout-rent-detail-modal';
import { PopUp } from '@/components/design-system/molecules/pop-up';
import { Modal } from '@/components/design-system/atoms/modal';
import { useGetOutgoingTransactionPaymentContext } from '../../hooks/use-get-outgoing-transaction-payment';
import { OutgoingTransactionActionCellContent } from './components/outgoing-transaction-action-cell-content';

const OutgoingTransactionsListTable = () => {
  const columnHelper = createColumnHelper<Payout>();
  const { t } = useTranslation();
  const tableRef = useRef<ColocAPIConnectedTableImperativeMethods>(null);
  const { showTransactionDetailsModal, toggleShowTransactionDetailsModal } =
    useGetOutgoingTransactionsListContext();
  const {
    showFetchedPaymentSuccessModal,
    toggleShowFetchedPaymentSuccessModal,
    showFetchedPaymentFailedModal,
    hideErrorModal,
    setPaymentId,
  } = useGetOutgoingTransactionPaymentContext()!;

  const columns = useMemo(
    () => [
      columnHelper.accessor('created_at', {
        id: 'created_at',
        cell: (info) => (
          <Table.TableCellDefaultContent className="w-[95px]">
            {getFullDate(new Date(info.getValue()))}
          </Table.TableCellDefaultContent>
        ),
        header: () =>
          t(
            'js.features.admin.transactions.outgoing_transactions_list.table_columns.date'
          ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('property', {
        id: 'house_name',
        cell: (info) => (
          <Table.TableCellDefaultContent className="w-[95px]">
            {info.getValue().name}
          </Table.TableCellDefaultContent>
        ),
        header: () =>
          t(
            'js.features.admin.transactions.outgoing_transactions_list.table_columns.house'
          ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('transactions', {
        id: 'transactions',
        cell: (info) => (
          <Table.TableCellDefaultContent className="w-[95px]">
            {info.getValue().length > 1
              ? t(
                  'js.features.admin.transactions.outgoing_transactions_list.table_cells.transactions',
                  { num_transactions: info.getValue().length }
                ) ?? ''
              : t(
                  'js.features.admin.transactions.outgoing_transactions_list.table_cells.transaction'
                ) ?? ''}
          </Table.TableCellDefaultContent>
        ),
        header: () =>
          t(
            'js.features.admin.transactions.outgoing_transactions_list.table_columns.transactions'
          ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('amount', {
        id: 'amount',
        cell: (info) => (
          <OutgoingTransactionAmountCellContent
            amount={info.getValue()}
            openDetailsModal={() =>
              toggleShowTransactionDetailsModal({
                show: true,
                payout: info.row.original,
              })
            }
          />
        ),
        header: () =>
          t(
            'js.features.admin.transactions.outgoing_transactions_list.table_columns.amount'
          ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('status', {
        id: 'status',
        cell: (info) => (
          <OutgoingTransactionStatusCellContent status={info.getValue()} />
        ),
        header: () =>
          t(
            'js.features.admin.transactions.outgoing_transactions_list.table_columns.status'
          ),
        footer: (info) => info.column.id,
      }),
      columnHelper.display({
        id: 'action',
        cell: ({ row }) => (
          <OutgoingTransactionActionCellContent
            outgoingTransaction={row.original}
          />
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.display({
        id: 'accordion',
        cell: ({ row }) => (
          <Table.TableCellAccordionContent
            onClick={() => row.toggleExpanded()}
            open={row.getIsExpanded()}
          />
        ),
        footer: (info) => info.column.id,
      }),
    ],
    [columnHelper, t, toggleShowTransactionDetailsModal]
  );

  return (
    <>
      <ColocAPIConnectedTable<Payout, PayoutsSortType>
        ref={tableRef}
        columns={columns}
        dataSource={useGetOutgoingTransactionsListContext()!}
        renderSubComponent={(row) => {
          return (
            <OutgoingTransactionsRow
              outgoingTransaction={row.row.original}
              index={row.row.index}
            />
          );
        }}
      />

      {showTransactionDetailsModal.show && showTransactionDetailsModal.payout && (
        <PayoutRentDetailModal
          payout={showTransactionDetailsModal.payout}
          open={showTransactionDetailsModal.show}
          onClose={() =>
            toggleShowTransactionDetailsModal({
              show: false,
              payout: undefined,
            })
          }
        />
      )}

      {showFetchedPaymentSuccessModal && (
        <Modal
          open={showFetchedPaymentSuccessModal.show}
          onClose={() =>
            toggleShowFetchedPaymentSuccessModal({
              show: false,
              payment: undefined,
            })
          }
        >
          <PopUp
            type="feedback"
            title={
              t(
                'js.features.admin.transactions.outgoing_transactions_list.payout_confirmation.success_title'
              ) ?? ''
            }
            description={
              t(
                'js.features.admin.transactions.outgoing_transactions_list.payout_confirmation.success_description'
              ) ?? ''
            }
            primaryButtonProps={{
              onClick: () =>
                toggleShowFetchedPaymentSuccessModal({
                  show: false,
                  payment: undefined,
                }),
              title:
                t(
                  'js.features.admin.transactions.outgoing_transactions_list.payout_confirmation.success_confirmation_button'
                ) ?? '',
            }}
          />
        </Modal>
      )}

      {showFetchedPaymentFailedModal && showFetchedPaymentFailedModal.show && (
        <Modal
          open={showFetchedPaymentFailedModal.show}
          onClose={() => hideErrorModal()}
        >
          <PopUp
            type="error"
            title={showFetchedPaymentFailedModal.title ?? ''}
            description={showFetchedPaymentFailedModal.text ?? ''}
            primaryButtonProps={{
              onClick: () => {
                showFetchedPaymentFailedModal.retryFetching &&
                  setPaymentId(showFetchedPaymentFailedModal.payment_id);
                hideErrorModal();
              },
              title: showFetchedPaymentFailedModal.retryFetching
                ? t(
                    'js.features.admin.transactions.outgoing_transactions_list.payout_confirmation.generic_retry_button'
                  ) ?? ''
                : t(
                    'js.features.admin.transactions.outgoing_transactions_list.payout_confirmation.generic_ok_button'
                  ) ?? '',
            }}
            {...(showFetchedPaymentFailedModal.retryFetching && {
              secondaryButtonProps: {
                onClick: () => hideErrorModal(),
                title:
                  t(
                    'js.features.admin.transactions.outgoing_transactions_list.payout_confirmation.generic_cancel_button'
                  ) ?? '',
              },
            })}
          />
        </Modal>
      )}
    </>
  );
};

export { OutgoingTransactionsListTable };
