import React from 'react';
import { Dialog } from '@headlessui/react';
import { ModalProps } from './types';
import { composeClassName } from '@/utils/util_helpers';

const Modal: React.FC<ModalProps> = ({
  children,
  open,
  onClose,
  className,
  ...props
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      className="fixed inset-[0px] z-[50] flex items-center justify-center bg-black bg-opacity-25"
      {...props}
    >
      <Dialog.Panel
        className={composeClassName('z-[60]', className && className)}
      >
        {children}
      </Dialog.Panel>
    </Dialog>
  );
};

export { Modal };
