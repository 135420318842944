import React from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { DropDown } from '@/components/design-system/molecules/dropdown';

import { usePairTransactionWithRentContext } from '@/features/admin/transactions/components/incoming-transactions-list/hooks/use-pair-transaction-with-rent';
import { RentOfContract } from '@/types/admin';
import { ColocApiConnectedDropDown } from '@/features/shared/feature-coloc-api-connected-dropdown';
import { useGetRentsForTenant } from '@/features/admin/transactions/components/incoming-transactions-list/hooks/use-get-rents-for-tenant';
import { amountToString } from '@/utils/util_helpers/formatting/amount_to_string';

const PairTransactionRentSelectionDropDown = () => {
  const { setRentQuery, selectedRent, setSelectedRent, selectedTenant } =
    usePairTransactionWithRentContext()!;

  const { t } = useTranslation();

  return (
    <ColocApiConnectedDropDown<RentOfContract>
      disabled={!selectedTenant}
      placeholder={
        t(
          'js.features.admin.transactions.transaction_pairing_modal.rent_selection_placeholder'
        ) ?? ''
      }
      className="z-20 w-full"
      showLabel
      label={t(
        'js.features.admin.transactions.transaction_pairing_modal.rent_selection_label'
      )}
      value={selectedRent}
      onChange={setSelectedRent}
      {...(selectedRent && {
        displayValue: `${format(new Date(selectedRent.due_on), 'MMM yy')}`,
      })}
      onTextInputChange={(event) => setRentQuery(event.target.value)}
      dataSource={
        useGetRentsForTenant({
          selectedContractId: selectedTenant?.id ?? 0,
        })!
      }
      itemRenderer={(itemProps) => {
        return (
          <DropDown.DropDownOptionDefaultContent>
            {`${format(
              new Date(itemProps.due_on),
              'MMM yy'
            )} - ${amountToString(itemProps.full_amount)}`}
          </DropDown.DropDownOptionDefaultContent>
        );
      }}
    />
  );
};

export { PairTransactionRentSelectionDropDown };
