<template>
  <inline-svg
    :src="
      require(`../../../../../../../assets/icons/icon-sax/linear/${name}.svg`)
    "
    :width="size"
    :height="size"
    :class="['icon-sax', strokeColor && 'icon-sax-with-stroke-color']"
    :style="{ '--icon-color': strokeColor }"
  />
</template>

<script>
import './IconSaxIcon.scss';
import InlineSvg from 'vue-inline-svg';
import IconSaxIcons from './iconSaxValidator';

export default {
  components: {
    InlineSvg,
  },
  props: {
    /**
     * The size of the icon. Defaults to 24.
     */
    size: {
      type: Number,
      default: 24,
    },
    /**
     * Color of the stroke. defaults to black.
     */
    strokeColor: {
      type: String,
      default: 'black',
    },
    /**
     * The name of the icon.
     */
    name: {
      type: String,
      required: true,
      validator(value) {
        return Object.prototype.hasOwnProperty.call(IconSaxIcons, value);
      },
    },
  },
};
</script>
