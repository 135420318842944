import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';

import { ReactSVG } from 'react-svg';
import { composeClassName } from '../../../../../../../../../utils/util_helpers';
import {
  DisplayTransactionStatus,
  TransactionPairedStatusCellContentProps,
} from './types';

const TransactionPairedStatusCellContent: React.FC<TransactionPairedStatusCellContentProps> =
  ({ status }) => {
    const { t } = useTranslation();

    const transactionStatus: DisplayTransactionStatus = useMemo(() => {
      if (status === 'contract_unmatched' || status === 'rent_unmatched') {
        return 'unpaired';
      }

      if (status === 'match') {
        return 'paired';
      }

      return status;
    }, [status]);

    return (
      <div className="flex items-center">
        <ReactSVG
          src={require(`@assets/icons/transaction-paired-icons/${transactionStatus}.svg`)}
          className={composeClassName(
            'inline-block align-baseline',
            'icon-sax'
          )}
          beforeInjection={(svg) => {
            svg.setAttribute('width', `24`);
            svg.setAttribute('height', `24`);
          }}
        />
        <span
          className={composeClassName(
            'pl-1 text-body',
            transactionStatus === 'paired' && 'text-J300',
            transactionStatus === 'unpaired' && 'text-C300',
            transactionStatus === 'ignored' && 'text-text-tertiary'
          )}
        >
          {t(
            `js.features.admin.transactions.transactions_list.paired_option.${transactionStatus}`
          )}
        </span>
      </div>
    );
  };

export { TransactionPairedStatusCellContent };
