import React from 'react';
import './style.css';
import { ReactSVG } from 'react-svg';
import { IconSaxIconProps } from './types';
import { composeClassName } from '../../../../../utils/util_helpers';

const IconSaxIcon: React.FC<IconSaxIconProps> = ({
  name,
  strokeColor = '#1a1a1a',
  hoverStrokeColor,
  strokeWidth,
  size = 24,
  className,
  ...props
}) => {
  const style = {
    '--icon-color-sax': strokeColor,
    ...(hoverStrokeColor && { '--icon-color-sax--hover': hoverStrokeColor }),
    ...(strokeWidth && { '--icon-stroke-width': strokeWidth }),
  } as React.CSSProperties;

  return (
    <ReactSVG
      data-testid={name}
      src={require(`@assets/icons/icon-sax/linear/${name}.svg`)}
      className={composeClassName(
        'inline-block align-baseline',
        'icon-sax',
        hoverStrokeColor && 'icon-sax-hover',
        className && className
      )}
      style={style}
      beforeInjection={(svg) => {
        svg.setAttribute('width', `${size}`);
        svg.setAttribute('height', `${size}`);
      }}
      {...props}
    />
  );
};

export { IconSaxIcon };
