import { useTranslation } from 'react-i18next';
import React from 'react';
import { DatePicker } from '@/components/design-system/molecules/datepicker';

import { ListHouseSelectionDropDown } from './components/list-house-selection-dropdown';
import { useRentReportListFiltersContext } from '../../hooks/use-get-rent-report-filter';
import { ListRentReportStatusDropDown } from './components/list-rent-report-status-dropdown';

const RentReportListFilter = () => {
  const { setDateRange, startDate, endDate } =
    useRentReportListFiltersContext()!;
  const { t } = useTranslation();

  return (
    <div className="relative flex w-full items-end justify-between pt-5">
      <div className="flex gap-4">
        <ListHouseSelectionDropDown />
        <ListRentReportStatusDropDown />
        <DatePicker
          className="w-[250px]"
          onChange={setDateRange}
          placeholder={
            t('js.features.admin.rent_report.filters.date_range_placeholder') ??
            ''
          }
          label={
            t('js.features.admin.rent_report.filters.date_range_label') ?? ''
          }
          dateRange
          type="month"
          startValue={startDate}
          endValue={endDate}
        />
      </div>
    </div>
  );
};

export { RentReportListFilter };
