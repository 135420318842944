/* eslint-disable react/jsx-no-constructed-context-values */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useWindowDimensions } from '@/hooks/use-window-dimensions';
import { ColocIconName } from '@/components/design-system/atoms/icon/coloc-icon/types';

export type ThirdSectionOption = {
  img_name: string;
  title: string;
  text: string;
};

const options = [
  'furnished',
  'flexibility',
  'community',
  'app',
  'help',
  'iot',
  'sustainable',
  '3d',
];

const iconOptionsDec: {
  [key in typeof options[number]]: {
    first: ColocIconName;
    second: ColocIconName;
  };
} = {
  furnished: {
    first: 'furniture',
    second: 'lamp',
  },
  flexibility: {
    first: 'contract-x',
    second: 'happy-face',
  },
  community: {
    first: 'community',
    second: 'festival',
  },
  app: {
    first: 'mobile-app',
    second: 'message',
  },
  help: {
    first: 'help',
    second: 'question',
  },
  iot: {
    first: 'smart-door',
    second: 'smart-light',
  },
  sustainable: {
    first: 'clean-energy',
    second: 'clean-energy2',
  },
  '3d': {
    first: 'vr',
    second: 'vr2',
  },
};

const UPDATE_TIME_OPTION = 3500;

const useThirdSection = () => {
  const [currentOptionNum, setCurrentOption] = useState(1);
  const { screenIsLargeOrEqualThan } = useWindowDimensions();

  const numOptions = options.length;

  const currentOption = useMemo(() => {
    return options[currentOptionNum - 1];
  }, [currentOptionNum]);

  useEffect(() => {
    if (screenIsLargeOrEqualThan('lg')) {
      const intervalId = setInterval(() => {
        const nextOption =
          currentOptionNum < numOptions ? currentOptionNum + 1 : 1;
        setCurrentOption(nextOption);
      }, UPDATE_TIME_OPTION);
      return () => {
        clearInterval(intervalId);
      };
    }

    return undefined;
  }, [currentOptionNum, numOptions, screenIsLargeOrEqualThan]);

  return {
    options,
    currentOption,
    currentOptionNum,
    setCurrentOption,
    iconOptionsDec,
  };
};

const ThirdSectionContext = createContext<
  ReturnType<typeof useThirdSection> | undefined
>(undefined);

const ThirdSectionContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const sectionCont = useThirdSection();

  return (
    <div>
      <ThirdSectionContext.Provider value={sectionCont}>
        {children}
      </ThirdSectionContext.Provider>
    </div>
  );
};

const useThirdSectionContext = () => {
  const context = useContext(ThirdSectionContext);

  if (context === null) {
    throw new Error(
      'useThirdSectionContext must be used within a ThirdSectionContextProvider'
    );
  }

  return context;
};

export { useThirdSectionContext, ThirdSectionContextProvider };
