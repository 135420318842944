import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { PayoutRentDetailModalProps } from './types';
import { Modal } from '@/components/design-system/atoms/modal';
import { Button } from '@/components/design-system/atoms/button';
import { Amount } from '@/types/global';
import { amountToString } from '@/utils/util_helpers/formatting/amount_to_string';
import { Rent } from '@/features/admin/rents/types';
import { composeClassName } from '@/utils/util_helpers';

const AmountCell: React.FC<{ amount: Amount; className?: string }> = ({
  amount,
  className,
}) => {
  return (
    <td
      className={composeClassName(
        'border-r-[1px] border-solid border-E50 text-center align-middle text-body font-medium',
        className && className
      )}
    >
      <div className="flex flex-col items-center justify-center">
        <span> {amount.cents > 0 ? amountToString(amount) : '--'}</span>
      </div>
    </td>
  );
};

const TenantCell: React.FC<{ rent: Rent }> = ({ rent }) => {
  const { t } = useTranslation();

  return (
    <td>
      <div className="flex flex-col text-body">
        <span className="font-medium text-text-primary">
          {t(
            'js.features.admin.payouts.payout_rent_detail_modal.tenant_info.title',
            {
              room_number: rent.room.room_number,
              month_name: format(
                new Date(rent.period.start_date),
                'MMMM  yyyy'
              ),
            }
          )}
        </span>
        <span className="line-clamp-1 break-all text-text-secondary">
          {rent.tenant.name}
        </span>
      </div>
    </td>
  );
};

const HeaderCell: React.FC<{
  title: string;
  description: string;
  className?: string;
}> = ({ title, description, className }) => {
  return (
    <th
      className={composeClassName(
        'border-r-[1px] border-solid border-E50 px-3 pb-2 align-middle',
        className && className
      )}
    >
      <div className="flex w-[120px] flex-col">
        <span className="text-lead2 font-medium text-text-primary">
          {title}
        </span>
        <span className="line-clamp-1 break-all text-body text-text-tertiary">
          {description}
        </span>
      </div>
    </th>
  );
};

const PayoutRentDetailModal: React.FC<PayoutRentDetailModalProps> = ({
  payout,
  open,
  onClose,
}) => {
  const { t } = useTranslation();

  const netRentAmount = useMemo(() => {
    const centsAmount = payout.payout_rents.reduce((acc, rent) => {
      return acc + rent.net_amount.cents;
    }, 0);
    return { currency_iso: 'EUR', cents: centsAmount };
  }, [payout]);

  const chargesAmount = useMemo(() => {
    const centsAmount = payout.payout_rents.reduce((acc, rent) => {
      return acc + rent.charges_amount.cents;
    }, 0);
    return { currency_iso: 'EUR', cents: centsAmount };
  }, [payout]);

  const managementFeesAmount = useMemo(() => {
    const centsAmount = payout.payout_rents.reduce((acc, rent) => {
      return acc + rent.management_fee.cents;
    }, 0);
    return { currency_iso: 'EUR', cents: centsAmount };
  }, [payout]);

  const discountAmount = useMemo(() => {
    const centsAmount = payout.payout_rents.reduce((acc, rent) => {
      return acc + rent.discount_amount.cents;
    }, 0);
    return { currency_iso: 'EUR', cents: centsAmount };
  }, [payout]);

  // TODO: Uncomment this when the backend is ready
  // const refundAmount = useMemo(() => {
  //   const centsAmount = payout.rents.reduce((acc, rent) => {
  //     if (rent.unpaid_amount.cents < 0) {
  //       return acc + Math.abs(rent.unpaid_amount.cents);
  //     }
  //     return acc;
  //   }, 0);
  //   return { currency_iso: 'EUR', cents: centsAmount };
  // }, [payout]);

  return (
    <Modal open={open} onClose={onClose}>
      <div className="flex flex-col gap-5 overflow-hidden rounded-lg bg-white p-5 shadow-medium">
        <div>
          <span className="text-lead2 font-bold text-text-primary">
            {t('js.features.admin.payouts.payout_rent_detail_modal.title', {
              house_name: payout.property.name.replace(/\./g, ''),
            })}
          </span>
        </div>
        <div>
          <table
            className="border-spacing-2 text-text-primary"
            style={{ borderSpacing: '0px 8px', borderCollapse: 'separate' }}
          >
            <thead className="pb-3">
              <tr>
                <th className="text-lead2 font-medium text-text-primary">
                  <div className="flex flex-col items-start">Total</div>
                </th>
                <HeaderCell
                  title={amountToString(netRentAmount)}
                  description="Net Rent"
                />
                <HeaderCell
                  title={amountToString(chargesAmount)}
                  description="Charges"
                />
                <HeaderCell
                  title={amountToString(managementFeesAmount)}
                  description="Management Fees"
                />
                <HeaderCell
                  title={amountToString(discountAmount)}
                  description="Discounts"
                  className="!border-none"
                />
                {/* TODO: uncomment this when the backend is ready
                <HeaderCell
                  title={amountToString(refundAmount)}
                  description="Refund"
                  className="!border-none"
                /> */}
              </tr>
            </thead>
            <tbody>
              {payout.payout_rents
                .sort((a, b) => a.rent.room.room_number - b.rent.room.room_number)
                .map((payout_rent) => {
                  return (
                    <tr
                      key={`${payout.id}-${payout_rent.rent.id}`}
                      className="border-spacing-2 border-t-[1px] border-solid border-E50 pb-2"
                    >
                      <TenantCell rent={payout_rent.rent} />
                      <AmountCell amount={payout_rent.net_amount} />
                      <AmountCell amount={payout_rent.charges_amount} />
                      <AmountCell amount={payout_rent.management_fee} />
                      <AmountCell
                        amount={payout_rent.discount_amount}
                        className="!border-none"
                      />
                      {/* // TODO: uncomment this when the backend is ready
                       <AmountCell
                        amount={{
                          currency_iso: 'EUR',
                          cents:
                            rent.unpaid_amount.cents < 0
                              ? Math.abs(rent.unpaid_amount.cents)
                              : 0,
                        }}
                        className="!border-none"
                      />  */}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div>
          <Button variant="ghost" onClick={() => onClose && onClose()}>
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export { PayoutRentDetailModal };
