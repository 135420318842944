import * as colocAdminApi from '@/lib/coloc-api/admin';

export const {
  getRentsSummaryHouses,
  getRentsList,
  getRent,
  updateRents,
  getRentTransactions,
  addRentTransaction,
  deleteRentTransaction,
  getTransactionsList,
  getHouseList,
  getRoomList,
  getTenantsForRoom,
  getRentsForContract,
  linkTransactionWithRent,
  ignoreTransaction,
  getPayoutPreviewSummary,
  createPayout,
  getPayouts,
  getPayoutPayment,
  getRoomAssignmentList,
  getRentReportList,
  getRentReportVersions,
  generateRentReports,
  getDiscussionThreadById,
  createDiscussionThreadMessage,
  createDiscussionThread,
  deleteDiscussionThreadMessage,
  updateContract,
  updateRoomAvailability,
  getRoomAssignment,
  getCityList,
  updateMoveIn,
} = colocAdminApi;
