import React, { useMemo } from 'react';
import { IconSaxIcon } from '@/components/design-system/atoms/icon';
import { composeClassName } from '@/utils/util_helpers';
import { theme } from '@/utils/util_theme';
import { DropdownMenuOptionProps } from './types';

const DropdownMenuOptionContent: React.FC<DropdownMenuOptionProps> = ({
  ...props
}) => {
  const {
    iconName,
    destructive = false,
    disabled,
    children,
    className,
  } = props;

  const iconStrokeColor = useMemo(() => {
    if (disabled) {
      return theme?.colors.E75;
    }

    if (destructive) {
      return theme?.colors?.text?.failed;
    }

    return theme?.colors?.text?.secondary;
  }, [destructive, disabled]);

  return (
    <div
      className={composeClassName(
        'flex h-[3.2rem] min-w-[16.8rem] items-center gap-2 rounded-sm bg-white px-[12px]',
        !disabled && 'hover:bg-E30',
        className && className
      )}
    >
      {iconName && (
        <IconSaxIcon
          name={iconName}
          size={18}
          strokeWidth={1.5}
          strokeColor={iconStrokeColor}
        />
      )}
      <span
        className={composeClassName(
          'text-body',
          destructive ? 'text-text-failed' : 'text-text-secondary',
          disabled && 'text-E75'
        )}
      >
        {children}
      </span>
    </div>
  );
};

const DropdownMenuOption: React.FC<DropdownMenuOptionProps> = ({
  as = 'a',
  // @ts-ignore
  href,
  disabled = false,
  onClick,
  ...props
}) => {
  if (as === 'a') {
    return (
      <a style={{ pointerEvents: disabled ? 'none' : 'auto' }} href={href}>
        <DropdownMenuOptionContent disabled={disabled} {...props} />
      </a>
    );
  }

  if (as === 'button') {
    return (
      // @ts-ignore
      <button disabled={disabled} type="button" onClick={onClick} {...props}>
        <DropdownMenuOptionContent disabled={disabled} {...props} />
      </button>
    );
  }

  return <></>;
};

export { DropdownMenuOption };
