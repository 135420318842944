import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@/components/design-system/atoms/modal';

import { Button } from '@/components/design-system/atoms/button';

import { RegenerateRentReportsVersionModalProps } from './types';
import { useRegenerateRenReportContext } from '../../hooks/use-regenerate-rent-report';
import {
  getFullMonthName,
  getFullYearNumber,
} from '@/utils/util_helpers/formatting/dates';

const RegenerateRentReportsModal: React.FC<RegenerateRentReportsVersionModalProps> =
  ({ open, onClose, reports }) => {
    const { t } = useTranslation();
    const { mutate, toggleShowRegenerateRentReportConfirmationModal } =
      useRegenerateRenReportContext()!;

    const isSingleFile = reports.length === 1;

    return (
      <Modal open={open} onClose={onClose}>
        <div className="flex w-[500px] flex-col gap-5 rounded-lg bg-white p-7 shadow-medium">
          <div className="flex w-full flex-col gap-2 text-text-primaryHeavy">
            <span className="text-lead2 font-bold">
              {isSingleFile
                ? t(
                    'js.features.admin.rent_report.regenerate_report_modal.title'
                  )
                : t(
                    'js.features.admin.rent_report.regenerate_report_modal.title_plural'
                  )}
            </span>
            <span className="text-body text-text-primaryMedium">
              {isSingleFile
                ? t(
                    'js.features.admin.rent_report.regenerate_report_modal.text'
                  )
                : t(
                    'js.features.admin.rent_report.regenerate_report_modal.text_plural'
                  )}
            </span>
            <ul>
              {reports.map((report) => (
                <li
                  className="text-body text-text-primaryMedium"
                  key={report.id}
                >{`\u{2022}   ${getFullMonthName(
                  new Date(report.date)
                )} ${getFullYearNumber(new Date(report.date))} - ${
                  report.house.name
                }`}</li>
              ))}
            </ul>
          </div>
          <div className="flex gap-4">
            <Button
              variant="primary"
              loading={mutate.isLoading}
              onClick={() => {
                mutate.mutate(reports.map((report) => report));
              }}
            >
              {t(
                'js.features.admin.rent_report.regenerate_report_modal.confirm_button'
              ) ?? ''}
            </Button>
            <Button
              variant="ghost"
              disabled={mutate.isLoading}
              onClick={() => {
                toggleShowRegenerateRentReportConfirmationModal({
                  show: false,
                  reports: [],
                });
              }}
            >
              {t(
                'js.features.admin.rent_report.regenerate_report_modal.cancel_button'
              ) ?? ''}
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

export { RegenerateRentReportsModal };
