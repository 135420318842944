/* eslint-env jquery */
import { Controller } from 'stimulus';
import Sortable from 'sortablejs';

export default class extends Controller {
  static targets = ['item'];

  connect() {
    this.sortable = new Sortable(this.element, {
      draggable: this.itemSelector,
      onEnd: (e) => this.updatePosition(e),
    });
  }

  updatePosition() {
    fetch(this.element.dataset.sortUrl, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': jQuery.rails.csrfToken(),
      },
      credentials: 'same-origin',
      body: JSON.stringify({
        item_ids: this.sortable.toArray(),
      }),
    });
  }

  get itemSelector() {
    return '[data-target="sortable.item"]';
  }
}
