import React from 'react';
import { useNotificationsProvider, NotificationsContext } from './hooks';
import { NotificationsSlideOver } from './components/NotificationSlideOver';

const NotificationsProvider: React.FC = ({ children }) => {
  const hook = useNotificationsProvider();
  return (
    <NotificationsContext.Provider value={hook}>
      <NotificationsSlideOver />
      {children}
    </NotificationsContext.Provider>
  );
}

export { NotificationsProvider };
