import React from 'react';
import { NavigationMenuTabsProps } from './types';

const NavigationMenuTabs: React.FC<NavigationMenuTabsProps> = ({
  children,
}) => {
  return <div className="mt-10 flex flex-col gap-3">{children}</div>;
};

export { NavigationMenuTabs };
