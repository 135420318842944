/* eslint-disable react/prop-types */
import { Menu } from '@headlessui/react';
import React from 'react';
import { composeClassName } from '@/utils/util_helpers';
import { DropDownMenuButton } from './components/dropdown-menu-button';
import { DropdownMenuButtonProps } from './components/dropdown-menu-button/types';
import { DropdownMenuOption } from './components/dropdown-menu-option';
import { DropdownMenuOptionProps } from './components/dropdown-menu-option/types';
import { DropdownMenuOptions } from './components/dropdown-menu-options';
import { DropdownMenuOptionsProps } from './components/dropdown-menu-options/types';
import { DropDownMenuProps } from './types';

const DropdownMenu: React.FC<DropDownMenuProps> & {
  DropdownMenuOption: React.FC<DropdownMenuOptionProps>;
  DropdownMenuOptions: React.FC<DropdownMenuOptionsProps>;
  DropdownMenuButton: React.FC<DropdownMenuButtonProps>;
} = ({ children, className }) => {
  return (
    <Menu
      as="div"
      className={composeClassName('relative', className && className)}
    >
      {children}
    </Menu>
  );
};

DropdownMenu.DropdownMenuOption = DropdownMenuOption;
DropdownMenu.DropdownMenuOptions = DropdownMenuOptions;
DropdownMenu.DropdownMenuButton = DropDownMenuButton;

export { DropdownMenu };
