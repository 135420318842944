<template>
  <section class="vue-fullwidth reviews">
    <div v-for="review in reviews" :key="review.id" class="review__item">
      <span class="prev">
        <svg xmlns="http://www.w3.org/2000/svg" width="12.386" height="22.772" viewBox="0 0 12.386 22.772">
          <path id="chevron-right" d="M18.972,25.944,9,15.972,18.972,6" transform="translate(-8 -4.586)" fill="none" stroke="#e5e5e5" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
        </svg>
      </span>
      <div class="review__details">
        <div class="review__blockquote">
          <svg class="quote__left" xmlns="http://www.w3.org/2000/svg" width="29.702" height="23.18" viewBox="0 0 29.702 23.18">
            <g id="Group_447" data-name="Group 447" transform="translate(-633.43 -3081.327)">
              <path id="Path_163" data-name="Path 163" d="M-6.57-32.714a14.544,14.544,0,0,1,2.527-7.6q2.527-4.06,8.895-7.362l.809,1.516A38.07,38.07,0,0,0,.876-42.249q-2.729,2.763-2.729,5.155a1.549,1.549,0,0,0,.4,1.146A1.481,1.481,0,0,0-.3-35.511q1.314-.168,1.718-.168A4.248,4.248,0,0,1,4.8-34.113a6,6,0,0,1,1.331,4.026,5.718,5.718,0,0,1-1.5,3.891A5.464,5.464,0,0,1,.3-24.494a6.075,6.075,0,0,1-5-2.342A9.124,9.124,0,0,1-6.57-32.714Z" transform="translate(640 3129)" fill="#fcce23"/>
              <path id="Path_164" data-name="Path 164" d="M-6.57-32.714a14.544,14.544,0,0,1,2.527-7.6q2.527-4.06,8.895-7.362l.809,1.516A38.07,38.07,0,0,0,.876-42.249q-2.729,2.763-2.729,5.155a1.549,1.549,0,0,0,.4,1.146A1.481,1.481,0,0,0-.3-35.511q1.314-.168,1.718-.168A4.248,4.248,0,0,1,4.8-34.113a6,6,0,0,1,1.331,4.026,5.718,5.718,0,0,1-1.5,3.891A5.464,5.464,0,0,1,.3-24.494a6.075,6.075,0,0,1-5-2.342A9.124,9.124,0,0,1-6.57-32.714Z" transform="translate(657 3129)" fill="#fcce23"/>
            </g>
          </svg>
          <blockquote class="review__text" :cite="review.author_url">{{ review.text }}</blockquote>
          <svg class="quote__right" data-name="Group 448" xmlns="http://www.w3.org/2000/svg" width="29.702" height="23.18" viewBox="0 0 29.702 23.18">
            <path id="Path_163" data-name="Path 163" d="M6.132-32.714a14.544,14.544,0,0,0-2.527-7.6q-2.527-4.06-8.895-7.362L-6.1-46.157a38.07,38.07,0,0,1,4.784,3.908q2.729,2.763,2.729,5.155a1.549,1.549,0,0,1-.4,1.146,1.481,1.481,0,0,1-1.146.438q-1.314-.168-1.718-.168a4.248,4.248,0,0,0-3.386,1.567A6,6,0,0,0-6.57-30.086a5.718,5.718,0,0,0,1.5,3.891,5.464,5.464,0,0,0,4.329,1.7,6.075,6.075,0,0,0,5-2.342A9.124,9.124,0,0,0,6.132-32.714Z" transform="translate(23.57 47.673)" fill="#fcce23"/>
            <path id="Path_164" data-name="Path 164" d="M6.132-32.714a14.544,14.544,0,0,0-2.527-7.6q-2.527-4.06-8.895-7.362L-6.1-46.157a38.07,38.07,0,0,1,4.784,3.908q2.729,2.763,2.729,5.155a1.549,1.549,0,0,1-.4,1.146,1.481,1.481,0,0,1-1.146.438q-1.314-.168-1.718-.168a4.248,4.248,0,0,0-3.386,1.567A6,6,0,0,0-6.57-30.086a5.718,5.718,0,0,0,1.5,3.891,5.464,5.464,0,0,0,4.329,1.7,6.075,6.075,0,0,0,5-2.342A9.124,9.124,0,0,0,6.132-32.714Z" transform="translate(6.57 47.673)" fill="#fcce23"/>
          </svg>
        </div>
        <small class="review__author"><strong>{{review.author_name}}</strong> <span>•</span> <time>{{ formatTime(review.time) }}</time></small>
        <div class="review__stars">
          <img
              v-for="n in review.rating"
              :key="n"
              src="https://maps.gstatic.com/consumer/images/icons/2x/ic_star_rate_14.png"
              alt=""
              class="review__star"
            />
        </div>
        <br><a 
        href="https://www.google.com/maps/place/Coloc+Housing+HQ/@50.8347979,4.4034545,17z/data=!4m7!3m6!1s0x47c3c5f907bb3189:0x379f0c6c12f8bd0e!8m2!3d50.8347979!4d4.4056432!9m1!1b1" 
        class="review__link">
        See more reviews
      </a>
      </div>
      <span class="next">
        <svg xmlns="http://www.w3.org/2000/svg" width="12.386" height="22.772" viewBox="0 0 12.386 22.772">
          <path id="chevron-right" d="M9,25.944l9.972-9.972L9,6" transform="translate(-7.586 -4.586)" fill="none" stroke="#e5e5e5" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
        </svg>
      </span>
    </div>
  </section>
</template>

<script>
import { formatDistance } from 'date-fns';
import i18n from '../../vue_mixins/i18n';

export default {
  components: {},
  mixins: [i18n],
  props: {
    reviews: Array
  },
  methods: {
    formatTime(time) {
      return formatDistance(new Date(time), new Date(), {
        addSuffix: true,
        locale: window.dateFnsLocales[this.currentLocale()],
      });
    },
  },
  mounted() {
    reviewSlider(this.$el);
    swipeReview(this.$el);
  },
};

function reviewSlider(rootElement){
  var next = rootElement.querySelectorAll('.next');
  var prev = rootElement.querySelectorAll('.prev');
  next.forEach(slide);
  prev.forEach(slide);
}

function slide(e){
  e.addEventListener("click", function(){
    var actualReview = this.parentNode;
    var firstReview = this.parentNode.parentNode.firstChild;
    var nextReview = actualReview.nextElementSibling;
    var prevReview = actualReview.previousElementSibling;
    var lastReview = this.parentNode.parentNode.lastChild;

    actualReview.style.display = "none";

    if (this.classList == "next"){
      if (nextReview){
        nextReview.style.display = "flex";
      } else {
        firstReview.style.display = "flex";
      }
    } else if (this.classList == "prev"){
      if (prevReview){
        prevReview.style.display = "flex";
      } else {
        lastReview.style.display = "flex"
      }
    }
  })
}

/* 
  Function that detect if the user swipe on mobile 
  explanation : http://www.javascriptkit.com/javatutors/touchevents2.shtml
*/
function swipeReview(rootElement){
  var reviews = rootElement.querySelectorAll('.review__item');

  for(var i = 0; i < reviews.length; i++ ){
    reviews[i].addEventListener('touchstart', handleTouchStart, false);        
    reviews[i].addEventListener('touchmove', handleTouchMove, false);
  }
  var xDown = null;                                                        
  var yDown = null;

  function getTouches(evt) {
    return evt.touches;
  }                                                     

  function handleTouchStart(evt) {
    const firstTouch = getTouches(evt)[0];                                      
    xDown = firstTouch.clientX;                                      
    yDown = firstTouch.clientY;                                      
  };                                                

  function handleTouchMove(evt) {
    if ( ! xDown || ! yDown ) {
      return;
    }
    
    var xUp = evt.touches[0].clientX;                                    
    var yUp = evt.touches[0].clientY;
    
    var xDiff = xDown - xUp;
    var yDiff = yDown - yUp;
    
    if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*check for swipe right and left*/
      var actualReview = this;
      var firstReview = this.parentNode.firstChild;
      var nextReview = actualReview.nextElementSibling;
      var prevReview = actualReview.previousElementSibling;
      var lastReview = this.parentNode.lastChild;

      actualReview.style.display = "none";
      if ( xDiff > 0 ) {
        if (nextReview){ /* Swipe to right */
          nextReview.style.display = "flex";
        } else {
          firstReview.style.display = "flex";
        }
      } else { /* Swipe to left */
        if (prevReview){
          prevReview.style.display = "flex";
        } else {
          lastReview.style.display = "flex"
        }
      }                       
    } else {
      if ( yDiff > 0 ) {
        /* up swipe */ 
      } else { 
        /* down swipe */
      }                                                                 
    }
    /* reset values */
    xDown = null;
    yDown = null;                                             
  };
}
</script>
