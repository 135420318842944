import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingSpinner } from '@/components/design-system/atoms/loading-spinner';
import { useRegenerateRenReportContext } from '../../hooks/use-regenerate-rent-report';

const RegenerationLoadingIndicator = () => {
  const { mutate } = useRegenerateRenReportContext()!;
  const { t } = useTranslation();

  if (mutate.isLoading) {
    return (
      <div className="flex items-center gap-2 pt-3">
        <LoadingSpinner />
        <span className="text-body text-text-primaryMedium">
          {t('js.features.admin.rent_report.regenerate_loading_indicator')}
        </span>
      </div>
    );
  }

  return <></>;
};

export { RegenerationLoadingIndicator };
