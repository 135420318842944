/* global google */
import GMaps from 'gmaps/gmaps';
import { Controller } from 'stimulus';
import mapStyle from './map-style.json';

export default class extends Controller {
  static targets = ['map', 'highlighter', 'marker', 'mapControl'];

  connect() {
    if (this.element.dataset.autoLoad === 'true') {
      this.load();
    }
  }

  load() {
    this.setCoordinates();
    this.initGMaps();
    this.addDistrict();
    this.addMarkers();
    this.fitToBounds();

    google.maps.event.addListenerOnce(this.map.map, 'idle', () => {
      this.map.addListener('dragend', this.viewportChanged.bind(this));
      this.map.addListener('zoom_changed', this.viewportChanged.bind(this));
    });

    const walkingLineSymbol = {
      path: google.maps.SymbolPath.CIRCLE,
      fillOpacity: 1,
      scale: 3,
    };

    const walkingPathLine = new google.maps.Polyline({
      strokeColor: '#0eb7f6',
      strokeOpacity: 0,
      fillOpacity: 0,
      icons: [
        {
          icon: walkingLineSymbol,
          offset: '0',
          repeat: '10px',
        },
      ],
    });

    this.directionsService = new google.maps.DirectionsService();
    this.directionsDisplay = new google.maps.DirectionsRenderer({
      map: this.map.map,
      suppressMarkers: true,
      polylineOptions: walkingPathLine,
    });
  }

  viewportChanged() {
    if (this.mapTarget.dataset.placeLat) {
      return;
    }

    if (!document.querySelector('.map-layout-content-grid')) {
      return;
    }

    const bounds = this.map.map.getBounds();
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    const query = new URLSearchParams(window.location.search);

    query.delete('page');
    query.set('colocations[sw_lat]', sw.lat());
    query.set('colocations[sw_lng]', sw.lng());
    query.set('colocations[ne_lat]', ne.lat());
    query.set('colocations[ne_lng]', ne.lng());
    query.set('layout', '0');

    document.querySelector('.map-layout-content-grid').innerHTML = 'Loading..';

    fetch(`${window.location.pathname}?${query.toString()}`)
      .then((res) => res.text())
      .then((html) => {
        document.querySelector('.map-layout-content-grid').innerHTML = html;
        this.application
          .getControllerForElementAndIdentifier(document.body, 'page')
          .updateLazyload();
      });
  }

  initGMaps() {
    const startPositionBrussels = { lat: 50.8467, lng: 4.3547 };
    this.config = {
      ...startPositionBrussels,
      el: this.mapTarget,
      mapTypeControl: true,
      scrollwheel: true,
      fullscreenControl: false,
      styles: mapStyle,
      maxZoom: 18,
    };
    this.map = new GMaps(this.config);
  }

  addMarkers() {
    this.markersCoordinates.forEach((marker) => {
      if (marker.content) {
        this.map.drawOverlay(marker);
      } else {
        this.map.addMarker(marker);
      }
    });
  }

  centerOnMarker(e) {
    const { marker, markerId } = e.target.dataset;
    const element = markerId ? document.querySelector(markerId) : e.target;
    const markerData = JSON.parse(marker);

    this.markerTargets.forEach((el) => {
      el.parentNode.style.zIndex = '100';
      el.classList.remove('active');
    });

    const popover = element.parentNode.querySelector('.marker-popover');
    const mapRect = this.mapTarget.getBoundingClientRect();
    const elementRect = element.parentNode.getBoundingClientRect();
    const deltaLeft = elementRect.x - mapRect.x;
    const deltaRight =
      mapRect.x + mapRect.width - (elementRect.x + popover.clientWidth);

    if (deltaLeft < popover.clientWidth / 2) {
      popover.style.left = `${deltaLeft * -1 + 20}px`;
    } else if (deltaRight < 0) {
      popover.style.left = `${deltaRight - 20}px`;
    } else {
      popover.style.removeProperty('left');
    }

    const deltaTop = elementRect.y - mapRect.y;

    if (deltaTop < popover.clientHeight + 25) {
      popover.style.bottom = '-120px';
    } else {
      popover.style.removeProperty('bottom');
    }

    element.parentNode.style.zIndex = '101';

    if (this.mapTarget.dataset.keepOverlayActive === 'true') {
      element.classList.add('active');
    }

    if (this.mapTarget.dataset.placeLat && this.mapTarget.dataset.placeLng) {
      const originPoint = new google.maps.LatLng(
        this.mapTarget.dataset.placeLat,
        this.mapTarget.dataset.placeLng
      );
      const destination = new google.maps.LatLng(
        markerData.lat,
        markerData.lng
      );

      this.directionsService.route(
        {
          origin: originPoint,
          destination,
          travelMode: google.maps.TravelMode.WALKING,
          provideRouteAlternatives: true,
        },
        (response, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            this.directionsDisplay.setDirections(response);
          } else {
            this.map.panTo(markerData);
            // eslint-disable-next-line no-console
            console.log(`Directions request failed due to ${status}`);
          }
        }
      );
    }
  }

  setCoordinates() {
    this.markersCoordinates =
      JSON.parse(this.mapTarget.dataset.mapCoordinates) || [];
  }

  updateMarkers(e) {
    e.preventDefault();
    e.stopPropagation();

    this.markersCoordinates = JSON.parse(e.target.dataset.mapCoordinates);
    this.map.removeOverlays();
    this.addMarkers();
    this.fitToBounds();
    this.setActiveIcon(e.target);
  }

  fitToBounds() {
    if (this.allCoordinates.length === 0) {
      this.map.setZoom(8);
    } else if (this.allCoordinates.length === 1) {
      this.map.fitLatLngBounds(this.allCoordinates);
      this.map.panTo(this.allCoordinates[0]);
      this.map.setZoom(13);
    } else {
      this.map.fitLatLngBounds(this.allCoordinates);
    }
  }

  addDistrict() {
    const { mapDistrictCoordinates, mapDistrictColor } = this.mapTarget.dataset;

    this.districtCoordinates = JSON.parse(mapDistrictCoordinates);

    if (!Array.isArray(this.districtCoordinates)) {
      return;
    }

    const path = this.districtCoordinates.map((el) => [el.lat, el.lng]);

    this.map.drawPolygon({
      paths: path, // pre-defined polygon shape
      strokeColor: mapDistrictColor,
      strokeOpacity: 1,
      strokeWeight: 3,
      fillColor: mapDistrictColor,
      fillOpacity: 0.3,
    });
  }

  setActiveIcon(link) {
    this.mapControls.forEach((el) => {
      el.classList.remove('active');
    });
    link.classList.add('active');
  }

  get mapControls() {
    return this.mapControlTargets;
  }

  get allCoordinates() {
    if (this.districtCoordinates) {
      return this.markersCoordinates.concat(this.districtCoordinates);
    }

    return this.markersCoordinates;
  }

  get markerHighlighters() {
    return this.highlighterTargets;
  }
}
