import React, { useMemo, useRef } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Table } from '@/components/design-system/molecules/table';
import {
  GetRentReportListContextProvider,
  useGetRentReportListContext,
} from './hooks/use-get-rent-report-list';
import { RentReport } from '../../types';
import { ColocAPIConnectedTable } from '@/features/shared/feature-coloc-api-connected-table';
import { RentReportListSortType } from '@/lib/coloc-api/admin';
import {
  ColocAPIConnectedTableImperativeMethods,
  ColocApiConnectedTableBulkActionDeclaration,
} from '@/features/shared/feature-coloc-api-connected-table/types';
import { BASE_URL } from '@/config';
import { RentReportStatusCellContent } from './components/table-cells';
import {
  getFullMonthName,
  getFullYearNumber,
} from '@/utils/util_helpers/formatting/dates';
import { GetRentReportListFiltersContextProvider } from './hooks/use-get-rent-report-filter';
import { RentReportListFilter } from './components/rent-report-list-filter';
import { IconSaxIcon } from '@/components/design-system/atoms/icon';
import { DownloadReportVersionModal } from './components/download-rent-report-version-modal';
import { RegenerateRentReportsModal } from './components/regenerate-rent-reports-modal';
import {
  RegenerateRentReportContextProvider,
  useRegenerateRenReportContext,
} from './hooks/use-regenerate-rent-report';
import { RegenerationLoadingIndicator } from './components/regeneration-loading-indicator';
import { DownloadRentReportCellContent } from './components/table-cells/download-report-cell-content';
import {
  CreateRentReportContextProvider,
  useCreateRentReportContext,
} from './hooks/use-create-rent-report';
import { CreateRentReportButton } from './components/create-rent-report-button';
import { CreateRentReportModal } from './components/create-rent-report-modal';
import { useDownloadFile } from '@/hooks/use-download-file';
import { CreateRentReportConfirmation } from './components/create-rent-report-confirmation';

const RentsReportListContent = () => {
  const columnHelper = createColumnHelper<RentReport>();
  const { t } = useTranslation();
  const tableRef = useRef<ColocAPIConnectedTableImperativeMethods>(null);
  const {
    showDownloadReportVersionModal,
    toggleShowDownloadReportVersionModal,
    rowSelectionFullData,
  } = useGetRentReportListContext()!;
  const {
    showRegenerateRentReportConfirmationModal,
    toggleShowRegenerateRentReportConfirmationModal,
    mutate: mutateRegenerateRentReport,
  } = useRegenerateRenReportContext()!;
  const {
    showCreateRentReportModal,
    toggleShowCreateRentReportModal,
    toggleShowCreationConfirmationModal,
    showCreationConfirmationModal,
  } = useCreateRentReportContext()!;

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.house, {
        id: 'house_name',
        cell: (info) => (
          <Table.TableCellDefaultContent
            href={`${BASE_URL}/admin/colocations/${info.getValue().id}`}
            description={`${info.getValue().owner.first_name} ${
              info.getValue().owner.last_name
              }`}
          >
            {`${info.getValue().name}`}
          </Table.TableCellDefaultContent>
        ),
        header: () => t('js.features.admin.rent_report.table_columns.houses'),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('date', {
        id: 'date',
        cell: (info) => (
          <Table.TableCellDefaultContent>
            {`${getFullMonthName(
              new Date(info.getValue())
            )} ${getFullYearNumber(new Date(info.getValue()))}`}
          </Table.TableCellDefaultContent>
        ),
        header: () => t('js.features.admin.rent_report.table_columns.month'),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('version_number', {
        id: 'version_number',
        header: () => t('js.features.admin.rent_report.table_columns.version'),
        cell: (info) => (
          <Table.TableCellDefaultContent>
            {`${info.getValue()}`}
          </Table.TableCellDefaultContent>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('last_updated_at', {
        id: 'last_updated_at',
        header: () =>
          t('js.features.admin.rent_report.table_columns.last_update'),
        cell: (info) => (
          <Table.TableCellDefaultContent>
            {format(new Date(info.getValue()), 'MMM d, yyyy h:mm a')}
          </Table.TableCellDefaultContent>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('status', {
        id: 'status',
        header: () =>
          t('js.features.admin.rent_report.table_columns.completeness'),
        cell: (info) => (
          <RentReportStatusCellContent status={info.getValue()} />
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.display({
        id: 'download_link',
        cell: ({ row }) => (
          <DownloadRentReportCellContent report={row.original} />
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.display({
        id: 'actions',
        cell: ({ row }) => (
          <Table.TableCellRowActionsContent>
            <Table.TableCellRowActionsContent.TableRowActions>
              <Table.TableCellRowActionsContent.TableRowAction
                disabled={mutateRegenerateRentReport.status === 'loading'}
                onClick={() => {
                  toggleShowRegenerateRentReportConfirmationModal({
                    reports: [row.original],
                    show: true,
                  });
                }}
              >
                <Table.TableCellRowActionsContent.TableRowActionContent
                  contentBefore={<IconSaxIcon name="refresh-2" size={18} />}
                >
                  {t(
                    'js.features.admin.rent_report.row_actions.regenerate_report'
                  ) ?? ''}
                </Table.TableCellRowActionsContent.TableRowActionContent>
              </Table.TableCellRowActionsContent.TableRowAction>
              <Table.TableCellRowActionsContent.TableRowAction
                onClick={() =>
                  toggleShowDownloadReportVersionModal({
                    report: row.original,
                    show: true,
                  })
                }
              >
                <Table.TableCellRowActionsContent.TableRowActionContent
                  contentBefore={
                    <IconSaxIcon name="document-download" size={18} />
                  }
                >
                  {t(
                    'js.features.admin.rent_report.row_actions.download_versions'
                  ) ?? ''}
                </Table.TableCellRowActionsContent.TableRowActionContent>
              </Table.TableCellRowActionsContent.TableRowAction>
            </Table.TableCellRowActionsContent.TableRowActions>
          </Table.TableCellRowActionsContent>
        ),
        footer: (info) => info.column.id,
      }),
    ],
    [
      columnHelper,
      mutateRegenerateRentReport.status,
      t,
      toggleShowDownloadReportVersionModal,
      toggleShowRegenerateRentReportConfirmationModal,
    ]
  );

  const actions: Array<ColocApiConnectedTableBulkActionDeclaration> =
    useMemo(() => {
      return [
        {
          text: t(
            'js.features.admin.rent_report.bulk_actions.regenerate_report'
          ),
          loadingText:
            t(
              'js.features.admin.rent_report.bulk_actions.regenerate_report_loading'
            ) ?? '',
          iconName: 'refresh-2',
          onClick: () => {
            toggleShowRegenerateRentReportConfirmationModal({
              show: true,
              reports: rowSelectionFullData,
            });
          },
          disabled: mutateRegenerateRentReport.status === 'loading',
          loading: mutateRegenerateRentReport.status === 'loading',
        },
      ];
    }, [
      mutateRegenerateRentReport.status,
      rowSelectionFullData,
      t,
      toggleShowRegenerateRentReportConfirmationModal,
    ]);

  return (
    <>
      <ColocAPIConnectedTable<RentReport, RentReportListSortType>
        ref={tableRef}
        columns={columns}
        dataSource={useGetRentReportListContext()!}
        actions={actions}
        enableMultiRowSelection
        enableRowSelection
      />

      {showDownloadReportVersionModal.show &&
        showDownloadReportVersionModal.report && (
          <DownloadReportVersionModal
            open={showDownloadReportVersionModal.show}
            onClose={() =>
              toggleShowDownloadReportVersionModal({
                show: false,
                report: undefined,
              })
            }
            report={showDownloadReportVersionModal.report}
          />
        )}

      {showRegenerateRentReportConfirmationModal.show &&
        showRegenerateRentReportConfirmationModal.reports.length >= 1 && (
          <RegenerateRentReportsModal
            open={showRegenerateRentReportConfirmationModal.show}
            onClose={() =>
              toggleShowRegenerateRentReportConfirmationModal({
                show: false,
                reports: [],
              })
            }
            reports={showRegenerateRentReportConfirmationModal.reports}
          />
        )}

      {showCreateRentReportModal.show && (
        <CreateRentReportModal
          open={showCreateRentReportModal.show}
          onClose={() =>
            toggleShowCreateRentReportModal({
              show: false,
            })
          }
        />
      )}

      {showCreationConfirmationModal.show && (
        <CreateRentReportConfirmation
          open={showCreationConfirmationModal.show}
          report={showCreationConfirmationModal.report!}
          onClose={() =>
            toggleShowCreationConfirmationModal({
              show: false,
              report: undefined,
            })
          }
        />
      )}
    </>
  );
};

const RentReportsList = () => {
  return (
    <GetRentReportListFiltersContextProvider>
      <GetRentReportListContextProvider>
        <CreateRentReportContextProvider>
          <RegenerateRentReportContextProvider>
            <CreateRentReportButton />
            <div className="flex h-full w-full flex-1 flex-col">
              <RegenerationLoadingIndicator />
              <RentReportListFilter />
              <RentsReportListContent />
            </div>
          </RegenerateRentReportContextProvider>
        </CreateRentReportContextProvider>
      </GetRentReportListContextProvider>
    </GetRentReportListFiltersContextProvider>
  );
};

export { RentReportsList };
