import React, { ReactElement, useMemo, useState } from 'react';
import { differenceInDays, differenceInYears, format } from 'date-fns';
import { ApplicantInfoProps } from './types';
import { Avatar } from '@/components/design-system/atoms/avatar';
import { RoomApplicantVotes } from '@/features/admin/contracts/types';
import { IconSaxIcon, ColocIcon } from '@/components/design-system/atoms/icon';
import { theme } from '@/utils/util_theme';
import { composeClassName } from '@/utils/util_helpers';
import { Button } from '@/components/design-system/atoms/button';
import { openIntercomConversation } from '@/utils/util_helpers/intercom';

const ApplicantVotes: React.FC<{ votes: RoomApplicantVotes }> = ({ votes }) => {
  return (
    <div className="flex flex-row items-center gap-3">
      <div className="flex flex-row items-center gap-1">
        <span className="text-body font-medium text-text-successHeavy">
          {votes.accepted}
        </span>
        <IconSaxIcon
          name="like-1"
          size={18}
          strokeWidth={1.3}
          strokeColor={theme?.colors.fill.icon.successHeavy}
        />
      </div>
      <div className="flex flex-row items-center gap-1">
        <span className="text-body font-medium text-text-dangerHeavy">
          {votes.rejected}
        </span>
        <IconSaxIcon
          name="dislike"
          size={18}
          strokeWidth={1.3}
          strokeColor={theme?.colors.fill.icon.dangerHeavy}
        />
      </div>
    </div>
  );
};

const ApplicantTextField: React.FC<{
  title: string;
  subTitle?: string;
  content?: ReactElement;
  className?: string;
}> = ({ title, content, subTitle, className }) => {
  return (
    <div
      className={composeClassName(
        'flex flex-1 flex-col gap-[2px] text-footnote1',
        className && className
      )}
    >
      <span className="text-text-primaryMedium">{title}</span>
      {subTitle && (
        <span className="text-body text-text-primaryHeavy">{subTitle}</span>
      )}
      {content}
    </div>
  );
};

const ApplicantInfoRow: React.FC<{ children: ReactElement | ReactElement[] }> =
  ({ children }) => {
    return (
      <div className="flex w-full flex-1 flex-row justify-between gap-4">
        {children}
      </div>
    );
  };

const ApplicantInfo: React.FC<ApplicantInfoProps> = ({
  applicant,
  chat_url,
  onEditMoveIn,
}) => {
  const [showMore, toggleShowMore] = useState(false);

  const chatDateText = useMemo(() => {
    if (!applicant.chat.last_message_stamp) return 'No messages yet';
    const messageTime = new Date(applicant.chat.last_message_stamp).getTime();
    const currentTime = Date.now();
    const diff = currentTime - messageTime;

    if (diff < 60 * 60 * 1000) {
      // Less than an hour
      return `${Math.floor(diff / (60 * 1000))}m`;
    }

    if (diff < 24 * 60 * 60 * 1000) {
      // Less than a day
      return `${Math.floor(diff / (60 * 60 * 1000))}h`;
    }

    return `${differenceInDays(currentTime, messageTime)} day(s) ago`;
  }, [applicant.chat.last_message_stamp]);

  const age = useMemo(() => {
    if (!applicant.user.birthday) return null;
    return differenceInYears(new Date(), new Date(applicant.user.birthday));
  }, [applicant.user.birthday]);

  return (
    <div className="flex w-full flex-col gap-3">
      <div className="flex w-full flex-col gap-3">
        <div className="flex w-full flex-row justify-between">
          <div className="flex flex-row items-center gap-2">
            <Avatar
              size="m"
              firstName={applicant.user.name.split(' ')[0]}
              lastName={applicant.user.name.split(' ').pop()}
              src={applicant.user.avatar_url}
            />
            <span className="text-lead2 font-medium text-text-primaryHeavy">
              <a
                href={`${window.location.origin}/admin/contracts/${applicant.contract.id}`}
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                {applicant.user.name}, {age}
              </a>
            </span>
          </div>
          <ApplicantVotes votes={applicant.contract.votes} />
        </div>
        <ApplicantInfoRow>
          <ApplicantTextField
            title="Phone Number"
            content={
              <div className="flex items-center gap-2 ">
                <button
                  type="button"
                  className="underline text-medium"
                  onClick={() =>
                    openIntercomConversation(applicant.user.intercom_user_id)
                  }
                >
                  {applicant.user.phone_number.number}
                </button>
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(applicant.user.phone_number.number);
                  }}
                  size={18}
                >
                  <ColocIcon name="copy" size={18} />
                </button>
              </div>
            }
          />
          <ApplicantTextField
            title="Email"
            content={
              <div className="flex items-center gap-2 ">
                <button
                  type="button"
                  className="underline text-medium"
                  onClick={() =>
                    openIntercomConversation(applicant.user.intercom_user_id)
                  }
                >
                  {applicant.user.email}
                </button>
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(applicant.user.email);
                  }}
                  size={18}
                >
                  <ColocIcon name="copy" size={18} />
                </button>
              </div>
            }
          />
        </ApplicantInfoRow>
        <ApplicantInfoRow>
          <ApplicantTextField
            title="Move in"
            content={
              <button
                type="button"
                className="flex items-center gap-2 text-medium underline"
                onClick={() => onEditMoveIn()}
              >
                {applicant.contract.move_in_date
                  ? format(
                      new Date(applicant.contract.move_in_date),
                      'MMMM d, yyyy'
                    )
                  : '--'}
                <ColocIcon name="pencil-square" size={16} />
              </button>
            }
          />
          <ApplicantTextField
            title="Last chat activity"
            content={
              <a
                className="flex items-center gap-2 text-medium underline"
                href={`${chat_url}/chats/${applicant.chat.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {chatDateText}
                <ColocIcon name="messages" size={16} />
              </a>
            }
          />
        </ApplicantInfoRow>
        {showMore && (
          <>
            <ApplicantInfoRow>
              <ApplicantTextField
                title="Nationality"
                subTitle={applicant.user.nationality.country_name}
              />
            </ApplicantInfoRow>
            <ApplicantInfoRow>
              <ApplicantTextField
                title={`Languages (${applicant.user.languages.length})`}
                subTitle={applicant.user.languages
                  .map((language) => language.code)
                  .join(', ')}
              />
            </ApplicantInfoRow>
            <ApplicantInfoRow>
              <ApplicantTextField
                title="Work"
                subTitle={`${applicant.user.work.profession ?? '--'} @ ${
                  applicant.user.work.company ?? '--'
                }`}
              />
            </ApplicantInfoRow>
            <ApplicantInfoRow>
              <ApplicantTextField
                title="Introduction letter"
                subTitle={applicant.contract.motivation}
              />
            </ApplicantInfoRow>
          </>
        )}
      </div>
      <div className="h-[1px] w-full bg-border-primaryLight" />
      <div className="flex">
        <Button
          variant="link"
          iconName={showMore ? 'eye-slash' : 'eye'}
          onClick={() => toggleShowMore(!showMore)}
        >
          {showMore ? 'Show less' : 'Show more'}
        </Button>
      </div>
    </div>
  );
};

export { ApplicantInfo };
