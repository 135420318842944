import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropDown } from '@/components/design-system/molecules/dropdown';
import { PaginatedColocApiConnectedDropDown } from '@/features/shared/feature-paginated-coloc-api-connected-dropdown';

import { House } from '@/types/admin';
import { useGetHousesInfinite } from '@/features/shared/hooks/use-get-houses-infinite';
import { useRentsListFiltersContext } from '../../../../hooks/use-rents-list-filter';

const ListHouseSelectionDropDown = () => {
  const { selectedHouse, setSelectedHouse, housesQuery, setHousesQuery } =
    useRentsListFiltersContext()!;

  const { t } = useTranslation();

  return (
    <PaginatedColocApiConnectedDropDown<House>
      placeholder={
        t('js.features.admin.rents.rent_list.houses_placeholder') ?? ''
      }
      className="z-50"
      showLabel
      label={t('js.features.admin.rents.rent_list.houses_label')}
      value={selectedHouse}
      onChange={setSelectedHouse}
      {...(selectedHouse && {
        displayValue: selectedHouse.name,
      })}
      onTextInputChange={(event) => setHousesQuery(event.target.value)}
      dataSource={
        useGetHousesInfinite({
          searchQuery: housesQuery,
        })!
      }
      itemRenderer={(itemProps, ref) => {
        return (
          // @ts-ignore
          <DropDown.DropDownOptionDefaultContent ref={ref}>
            {itemProps.name}
          </DropDown.DropDownOptionDefaultContent>
        );
      }}
    />
  );
};

export { ListHouseSelectionDropDown };
