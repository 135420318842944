import React from 'react';
import { composeClassName } from '@/utils/util_helpers';
import { TableCellRowActionContentProps } from './types';

const TableCellRowActionContent: React.FC<TableCellRowActionContentProps> = ({
  children,
  contentBefore,
  disabled = false,
  className,
}) => {
  return (
    <div
      className={composeClassName(
        'flex items-center gap-2 rounded-sm p-2 text-body text-text-primary hover:bg-E30',
        disabled && '!text-E75',
        className && className
      )}
    >
      {contentBefore && (
        <div className=" flex items-center ui-disabled:grayscale-[80%] ui-disabled:[&_svg>path]:stroke-E75">
          {contentBefore}
        </div>
      )}
      {children}
    </div>
  );
};

export { TableCellRowActionContent };
