import React from 'react';
import { composeClassName } from '@/utils/util_helpers';

const PhotoCollage = () => {
  return (
    <div className={composeClassName('pt-[30%] pb-[47.5%]', 'sm:py-[0px]')}>
      <div
        className={composeClassName(
          'relative flex w-full flex-col items-center',
          'sm:flex-row sm:items-start sm:justify-center'
        )}
      >
        <picture
          className={composeClassName(
            'absolute top-[75%] left-[3%] z-30 aspect-square w-[65%] -rotate-6 shadow-longShadow',
            'sm:top-[0px] sm:left-[-3%] sm:z-0 sm:w-[45%]',
            'lg:w-[40%]',
            'xl:left-[0px] xl:w-[32.5%]'
          )}
        >
          <source
            srcSet={`${require(`@assets/images/marketplace/community-collage/img1.webp`)} 1x, ${require(`@assets/images/marketplace/community-collage/img1@2x.webp`)} 2x, ${require(`@assets/images/marketplace/community-collage/img1@3x.webp`)} 3x`}
            type="image/webp"
          />
          <img
            className="w-full"
            src={require(`@assets/images/marketplace/community-collage/img1.jpg`)}
            alt="img1"
          />
        </picture>
        <picture
          className={composeClassName(
            'relative right-[-3%] z-20 aspect-square w-[85%] rotate-[9deg] shadow-longShadow',
            'sm:w-[45%] sm:rotate-0',
            'lg:w-[40%]',
            'xl:right-[-1%] xl:w-[32.5%]'
          )}
        >
          <source
            srcSet={`${require(`@assets/images/marketplace/community-collage/img2.webp`)} 1x, ${require(`@assets/images/marketplace/community-collage/img2@2x.webp`)} 2x, ${require(`@assets/images/marketplace/community-collage/img2@3x.webp`)} 3x`}
            type="image/webp"
          />
          <img
            className="w-full"
            src={require(`@assets/images/marketplace/community-collage/img2.jpg`)}
            alt="img2"
          />
        </picture>
        <picture
          className={composeClassName(
            'absolute right-[25%] top-[-30%] aspect-square w-[70%] rotate-[-9deg] shadow-longShadow',
            'sm:right-[-3%] sm:top-[0px] sm:w-[45%] sm:rotate-[9deg]',
            'lg:w-[40%]',
            'xl:w-[32.5%]'
          )}
        >
          <source
            srcSet={`${require(`@assets/images/marketplace/community-collage/img3.webp`)} 1x, ${require(`@assets/images/marketplace/community-collage/img3@2x.webp`)} 2x, ${require(`@assets/images/marketplace/community-collage/img3@3x.webp`)} 3x`}
            type="image/webp"
          />
          <img
            className="w-full"
            src={require(`@assets/images/marketplace/community-collage/img3.jpg`)}
            alt="img3"
          />
        </picture>

        {/* <img
          className={composeClassName(
            'absolute right-[25%] top-[-30%] aspect-square w-[70%] rotate-[-9deg] shadow-longShadow',
            'sm:right-[-3%] sm:top-[0px] sm:w-[45%] sm:rotate-[9deg]',
            'lg:w-[40%]',
            'xl:w-[32.5%]'
          )}
          alt="img3"
          src={require(`@assets/images/marketplace/community-collage/img3.jpg`)}
        /> */}
      </div>
    </div>
  );
};

export { PhotoCollage };
