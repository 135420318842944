import React from 'react';
import './styles.css';

const UpdatingTitleText = () => {
  return (
    <div className="inline-block">
      <div className="animation">
        <div className="first">
          <div>Better,</div>
        </div>
        <div className="second">
          <div>Smarter,</div>
        </div>
        <div className="third">
          <div>Anywhere,</div>
        </div>
      </div>
    </div>
  );
};

export { UpdatingTitleText };
