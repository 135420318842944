import React from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { composeClassName } from '@/utils/util_helpers';
import { DEFAULT_CONTENT_WIDTH } from '@/features/marketplace/constants';
import { IconSaxIcon } from '@/components/design-system/atoms/icon';

type PressArticle = {
  publisher_name: string;
  imgSrc: string;
  link: string;
  title: string;
  text: string;
  publication_date: Date;
};

const pressArticles: Array<PressArticle> = [
 {
    publisher_name: 'De Tijd',
    imgSrc:
      'https://images.tijd.be/view?iid=Elvis:5iAR_ut_aPn81PRIBFvqGT&context=ONLINE&ratio=16/9&width=1280&u=1714572609000',
    link: 'https://www.tijd.be/ondernemen/vastgoed/families-balcaen-thermote-en-vlerick-stappen-in-colivinguitbater-coloc-housing/10542395.html',
    title: 'Families Balcaen, Thermote en Vlerick stappen in colivinguitbater Coloc Housing',
    text: 'De colivingspecialist Coloc Housing tankt 23 miljoen euro bij een groep investeerders, van wie Filip Balcaen, Ann Thermote en Philippe Vlerick de bekendste zijn. Oprichters Christophe Vercarre en Karel Versyp denken dit jaar af te klokken op 650 huurders, verspreid over een 80-tal panden in Brussel, Leuven, Gent en Antwerpen.',
    publication_date: new Date('2024-4-30'),
  },
  {
    publisher_name: 'De Tijd',
    imgSrc:
      'https://images.tijd.be/view?iid=Elvis:0osC_Gk24UvAu6IOJ2TZKa&context=ONLINE&ratio=16/9&width=815&u=1681155928000',
    link: 'https://www.tijd.be/ondernemen/vastgoed/coliving-uitbater-coloc-housing-wil-50-miljoen-ophalen-100-herenhuizen-kopen-om-te-renoveren/10459599.html',
    title: `Coliving-uitbater Coloc Housing wil 50 miljoen ophalen: '100 herenhuizen kopen om te renoveren'`,
    text: `'Als je naar links kijkt, zie je de giraffen in de zoo rondlopen', zegt Christophe Vercarre (34) terwijl we uit het dakraam van zijn jongste project turen. In hartje Antwerpen vormen Vercarre en zijn vennoot Karel Versyp (27) een herenhuis om tot een pand voor coliving. Op 1 mei arriveren de acht bewoners die samen een huurcontract afsluiten. Voor 800 à 850 euro per maand krijgen ze elk een privékamer met eigen badkamer. De woonkamer, de keuken en een berging delen ze.`,
    publication_date: new Date('2023-04-11'),
  },
  {
    publisher_name: 'De Tijd',
    imgSrc:
      'https://images.tijd.be/view?iid=Elvis:F9_nfnUxqL9AKzg3rMNJyo&context=ONLINE&ratio=16/9&width=1024&u=1646049922000',
    link: 'https://www.tijd.be/partner/vastgoed/ion/duurzaam-samenwonen-in-een-authentiek-kader/10370127.html',
    title: 'Duurzaam samenwonen in een authentiek kader',
    text: 'Wonen in het groen en toch vlak bij het centrum van de stad: Burenberg bewijst dat het kan. Projectontwikkelaar ION realiseert dit project op liefst twee hectare, op de site van de voormalige Acco-drukkerij en een voormalig klooster. ION koos bewust voor een mix van nieuwbouw en herbestemming van bestaande gebouwen, waarbij de waardevolle erfgoedelementen behouden blijven. Dat resulteert in een boeiende mix van moderne woonmogelijkheden op ieders maat.',
    publication_date: new Date('2022-02-28'),
  },

];

const PressArticlePreview: React.FC<{ article: PressArticle }> = ({
  article,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <picture>
        <img
          className="aspect-[11/8] rounded object-cover"
          src={article.imgSrc}
          alt={article.title}
          loading="lazy"
        />
      </picture>
      <div
        className={composeClassName(
          'mt-3 flex w-full flex-col gap-3',
          'lg:mt-5'
        )}
      >
        <span className="w-full text-body text-text-tertiary">{`${
          article.publisher_name
        } - ${format(article.publication_date, 'dd')} ${format(
          article.publication_date,
          'MMM'
        )}. ${format(article.publication_date, 'yyyy')}`}</span>
        <p
          className={composeClassName(
            'line-clamp-2 w-full text-lead1 font-medium text-text-primary',
            'lg:text-h5'
          )}
        >
          {article.title}
        </p>
        <p
          className={composeClassName(
            'line-clamp-5 text-body text-text-secondary',
            'lg:text-lead2'
          )}
        >
          {article.text}
        </p>
        <a
          href={article.link}
          target="_blank"
          rel="noreferrer noopener"
          className={composeClassName(
            'flex items-center gap-2 self-end text-body font-medium text-text-secondary hover:text-E75',
            'lg:mt-3'
          )}
        >
          <span>
            {t('js.features.marketplace.press.section2.read_more_link')}
          </span>
          <IconSaxIcon name="arrow-right" size={18} strokeWidth={1.2} />
        </a>
      </div>
    </div>
  );
};

const SecondSection = () => {
  const { t } = useTranslation();

  return (
    <section className={composeClassName('relative')}>
      <div
        className={composeClassName(
          DEFAULT_CONTENT_WIDTH,
          'm-auto mt-[9rem] flex flex-col items-center pb-[5.6rem]',
          'sm:pb-7 md:mt-[11rem]',
          'lg:pb-[7.6rem] lg:pt-[16.5rem]'
        )}
      >
        <div className="flex max-w-[63.2rem] flex-col items-center gap-4">
          <h2
            className={composeClassName(
              'text-center text-[3.2rem] font-extrabold leading-[4rem] text-text-primary',
              'sm:text-[4.8rem] sm:leading-[5.5rem]'
            )}
          >
            {t('js.features.marketplace.press.section2.title')}
          </h2>
          <span className="text-center text-lead1 text-text-secondary">
            {t('js.features.marketplace.press.section2.subtitle')}
          </span>
        </div>
        <div
          className={composeClassName(
            'mt-7 flex flex-wrap gap-4',
            'md:mt-[5.6rem] md:gap-7',
            'lg:mt-[11rem]',
            'grid grid-cols-1 md:grid-cols-2'
          )}
        >
          {pressArticles.map((article) => {
            return (
              <PressArticlePreview key={article.title} article={article} />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export { SecondSection };
