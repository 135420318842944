/* eslint-disable react/prop-types */
import React, { Children, isValidElement, useMemo } from 'react';
import { TableHeaderCell } from './components/table-header-cell';
import { TableHeader } from './components/table-header';
import { TableRow } from './components/table-row';
import { TableBody } from './components/table-body';
import { TableHeaderCellDefaultContent } from './components/table-header-cell-default-content';
import { TableCell } from './components/table-cell';
import { TableCellDefaultContent } from './components/table-cell-default-content';
import { TableSelectionCell } from './components/table-selection-cell';
import { TableProps, TableSlots } from './types';
import { TableHeaderProps } from './components/table-header/types';
import { TableBodyProps } from './components/table-body/types';
import { TableRowProps } from './components/table-row/types';
import { TableSelectionCellProps } from './components/table-selection-cell/types';
import { TableHeaderCellProps } from './components/table-header-cell/types';
import { TableHeaderCellDefaultContentProps } from './components/table-header-cell-default-content/types';
import { TableCellProps } from './components/table-cell/types';
import { TableCellDefaultContentProps } from './components/table-cell-default-content/types';
import { TableEmptyStateDefaultContent } from './components/table-empty-state-default-content';
import { TableEmptyStateDefaultContentProps } from './components/table-empty-state-default-content/types';
import { composeClassName } from '@/utils/util_helpers';
import { TableCellRowActionsContent } from './components/table-cell-row-actions-content';
import { TableCellRowActionsContentPropsAndSubComponents } from './components/table-cell-row-actions-content/types';
import { TablePaginationProps } from './components/table-pagination/types';
import { TablePagination } from './components/table-pagination';
import { TableContentProps } from './components/table-content/types';
import { TableContent } from './components/table-content';
import { TableBulkActionsProps } from './components/table-bulk-actions/types';
import { TableBulkActions } from './components/table-bulk-actions';
import { TableBulkActionProps } from './components/table-bulk-action/types';
import { TableBulkAction } from './components/table-bulk-action';
import { TableHeaderSelectionCellProps } from './components/table-header-selection-cell/types';
import { TableHeaderSelectionCell } from './components/table-header-selection-cell';
import { TableCellAccordionContentProps } from './components/table-cell-accordion-content/types';
import { TableCellAccordionContent } from './components/table-cell-accordion-content';

const Table: React.FC<TableProps> & {
  TableHeader: React.FC<TableHeaderProps>;
  TableBody: React.FC<TableBodyProps>;
  TableRow: React.FC<TableRowProps>;
  TableSelectionCell: React.FC<TableSelectionCellProps>;
  TableHeaderSelectionCell: React.FC<TableHeaderSelectionCellProps>;
  TableHeaderCell: React.FC<TableHeaderCellProps>;
  TableHeaderCellDefaultContent: React.FC<TableHeaderCellDefaultContentProps>;
  TableCell: React.FC<TableCellProps>;
  TableCellDefaultContent: React.FC<TableCellDefaultContentProps>;
  TableEmptyStateDefaultContent: React.FC<TableEmptyStateDefaultContentProps>;
  TableCellRowActionsContent: TableCellRowActionsContentPropsAndSubComponents;
  TablePagination: React.FC<TablePaginationProps>;
  TableContent: React.FC<TableContentProps>;
  TableBulkActions: React.FC<TableBulkActionsProps>;
  TableBulkAction: React.FC<TableBulkActionProps>;
  TableCellAccordionContent: React.FC<TableCellAccordionContentProps>;
} = ({ children, tableProps, className }) => {
  const filledSlots = useMemo(() => {
    const slots: TableSlots = {
      pagination: null,
      content: null,
      actions: null,
    };

    Children.toArray(children).forEach((child) => {
      if (!isValidElement(child)) {
        return;
      }

      switch (child.type) {
        case TablePagination:
          slots.pagination = child;
          break;
        case TableContent:
          slots.content = { ...child, props: { ...child.props, tableProps } };
          break;
        case TableBulkActions:
          slots.actions = child;
          break;
        default:
      }
    });

    return slots;
  }, [children, tableProps]);

  return (
    <div
      className={composeClassName(
        'flex h-full w-full flex-1 flex-col overflow-scroll',
        className && className
      )}
    >
      {filledSlots.actions}
      <div
        className={composeClassName(
          'scrollbar-hide h-full w-full flex-1 overflow-scroll rounded-t-sm'
        )}
      >
        <div
          id="table-wrapper-container"
          className="scrollbar-hide relative h-full w-full overflow-scroll rounded-t-sm pb-4"
        >
          {filledSlots.content}
          {filledSlots.pagination}
        </div>
      </div>
    </div>
  );
};

Table.TableBody = TableBody;
Table.TableHeader = TableHeader;
Table.TableRow = TableRow;
Table.TableHeaderCell = TableHeaderCell;
Table.TableHeaderCellDefaultContent = TableHeaderCellDefaultContent;
Table.TableSelectionCell = TableSelectionCell;
Table.TableHeaderSelectionCell = TableHeaderSelectionCell;
Table.TableCell = TableCell;
Table.TableCellDefaultContent = TableCellDefaultContent;
Table.TableEmptyStateDefaultContent = TableEmptyStateDefaultContent;
Table.TableCellRowActionsContent = TableCellRowActionsContent;
Table.TablePagination = TablePagination;
Table.TableContent = TableContent;
Table.TableBulkActions = TableBulkActions;
Table.TableBulkAction = TableBulkAction;
Table.TableCellAccordionContent = TableCellAccordionContent;

export { Table };
