import React, { useMemo, useRef } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { Table } from '@/components/design-system/molecules/table';
import { amountToString } from '@/utils/util_helpers/formatting/amount_to_string';

import { ColocAPIConnectedTable } from '@/features/shared/feature-coloc-api-connected-table';
import { TransactionsListSortType } from '@/lib/coloc-api/admin';
import { ColocAPIConnectedTableImperativeMethods } from '@/features/shared/feature-coloc-api-connected-table/types';

import {
  getFullDate,
  getFullMonthName,
  getFullYearNumber,
} from '@/utils/util_helpers/formatting/dates';
import { useGetTransactionsListContext } from '@/features/admin/transactions/components/incoming-transactions-list/hooks/use-get-transactions-list';

import { Transaction } from '@/features/admin/transactions/types';
import { IconSaxIcon } from '@/components/design-system/atoms/icon';
import { PopOver } from '@/components/design-system/molecules/pop-over';
import { capitalize } from '@/utils/util_helpers/formatting/capitalize';
import { extractDescription } from '@/utils/util_helpers/formatting/extract_description_message';
import { TransactionPairModal } from '../transaction-pair-modal';
import { usePairTransactionWithRentContext } from '@/features/admin/transactions/components/incoming-transactions-list/hooks/use-pair-transaction-with-rent';
import { Modal } from '@/components/design-system/atoms/modal';
import { PopUp } from '@/components/design-system/molecules/pop-up';
import { TransactionPairedRentCellContent } from './components/transaction-paired-rent-cell-content';
import { TransactionPairedStatusCellContent } from './components/transaction-pair-status-cell-content';
import { useIgnoreTransaction } from '../../hooks/use-ignore-transaction';

const TransactionsListTable = () => {
  const columnHelper = createColumnHelper<Transaction>();
  const { t } = useTranslation();
  const tableRef = useRef<ColocAPIConnectedTableImperativeMethods>(null);
  const {
    showPairTransactionModal,
    toggleShowPairTransactionModal,
    showLinkedTransactionConfirmationModal,
    toggleLinkedTransactionConfirmationModal,
  } = usePairTransactionWithRentContext()!;
  const { mutate: ignoreTransactionMutation } = useIgnoreTransaction()!;

  const columns = useMemo(
    () => [
      columnHelper.accessor('date', {
        id: 'date',
        cell: (info) => (
          <Table.TableCellDefaultContent className="w-[95px]">
            {getFullDate(new Date(info.getValue()))}
          </Table.TableCellDefaultContent>
        ),
        header: () =>
          t(
            'js.features.admin.transactions.transactions_list.table_columns.date'
          ),
        footer: (info) => info.column.id,
      }),
      columnHelper.display({
        id: 'from',
        cell: (info) => (
          <PopOver placement="bottom-start">
            <PopOver.PopOverTrigger>
              <Table.TableCellDefaultContent
                className="w-[150px]"
                {...(info.row.original.sender.account_number && {
                  description: capitalize(
                    info.row.original.sender.account_number
                  ),
                })}
              >
                {info.row.original.sender.account_name}
              </Table.TableCellDefaultContent>
            </PopOver.PopOverTrigger>
            <PopOver.PopOverContent
              className="!max-w-[400px]"
              text={info.row.original.sender.account_name}
            >
              <span className="text-body text-text-tertiary">
                {info.row.original.sender.account_number
                  ? capitalize(info.row.original.sender.account_number)
                  : null}
              </span>
            </PopOver.PopOverContent>
          </PopOver>
        ),
        header: () =>
          t(
            'js.features.admin.transactions.transactions_list.table_columns.from'
          ),
        footer: (info) => info.column.id,
      }),
      columnHelper.display({
        id: 'to',
        cell: (info) => (
          <PopOver placement="bottom-start">
            <PopOver.PopOverTrigger>
              <Table.TableCellDefaultContent
                className="w-[150px]"
                {...(info.row.original.receiver.account_number && {
                  description: info.row.original.receiver.account_number,
                })}
              >
                {info.row.original.receiver.account_name}
              </Table.TableCellDefaultContent>
            </PopOver.PopOverTrigger>
            <PopOver.PopOverContent
              className="!max-w-[400px]"
              text={info.row.original.receiver.account_name}
            >
              <span className="text-body text-text-tertiary">
                {info.row.original.receiver.account_number
                  ? info.row.original.receiver.account_number
                  : null}
              </span>
            </PopOver.PopOverContent>
          </PopOver>
        ),
        header: () =>
          t(
            'js.features.admin.transactions.transactions_list.table_columns.to'
          ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('bank_description', {
        id: 'bank_description',
        cell: (info) => (
          <PopOver placement="bottom-start">
            <PopOver.PopOverTrigger>
              <Table.TableCellDefaultContent className="w-[150px]">
                {info.getValue() ? extractDescription(info.getValue()) : null}
              </Table.TableCellDefaultContent>
            </PopOver.PopOverTrigger>
            <PopOver.PopOverContent
              className="!max-w-[400px] lowercase"
              {...(info.getValue() && {
                text: extractDescription(info.getValue()),
              })}
            />
          </PopOver>
        ),
        header: () =>
          t(
            'js.features.admin.transactions.transactions_list.table_columns.bank_description'
          ),
        footer: (info) => info.column.id,
      }),
      columnHelper.display({
        id: 'house',
        header: () =>
          t(
            'js.features.admin.transactions.transactions_list.table_columns.paired_rent'
          ),
        cell: (info) => (
          <div>
            {info.row.original.rents_covered?.map((rent) => (
              <TransactionPairedRentCellContent
                key={new Date(rent.due_on).toISOString()}
                rent={rent}
                room={info.row.original.room}
                tenant={info.row.original.tenant}
                amount={info.row.original.amount}
              />
            ))}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('amount', {
        id: 'amount',
        header: () =>
          t(
            'js.features.admin.transactions.transactions_list.table_columns.amount'
          ),
        cell: (info) => (
          <Table.TableCellDefaultContent className="w-[100px]">
            {info.row.original?.amount
              ? amountToString(info.row.original?.amount)
              : ''}
          </Table.TableCellDefaultContent>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('status', {
        id: 'status',
        header: () =>
          t(
            'js.features.admin.transactions.transactions_list.table_columns.pair'
          ),
        cell: (info) => (
          <TransactionPairedStatusCellContent status={info.getValue()} />
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.display({
        id: 'actions',
        cell: ({ row }) => {
          if (
            row.original.status.includes('unmatched') ||
            row.original.status === 'ignored'
          ) {
            return (
              <Table.TableCellRowActionsContent>
                <Table.TableCellRowActionsContent.TableRowActions>
                  <Table.TableCellRowActionsContent.TableRowAction
                    onClick={() => {
                      toggleShowPairTransactionModal({
                        show: true,
                        transaction: row.original,
                      });
                    }}
                  >
                    <Table.TableCellRowActionsContent.TableRowActionContent
                      contentBefore={<IconSaxIcon name="link" size={20} />}
                    >
                      {t(
                        'js.features.admin.transactions.transactions_list.link_transaction_button'
                      ) ?? ''}
                    </Table.TableCellRowActionsContent.TableRowActionContent>
                  </Table.TableCellRowActionsContent.TableRowAction>
                  {row.original.status.includes('unmatched') && (
                    <Table.TableCellRowActionsContent.TableRowAction
                      onClick={() => {
                        ignoreTransactionMutation.mutate({
                          transactionId: row.original.id,
                        });
                      }}
                    >
                      <Table.TableCellRowActionsContent.TableRowActionContent
                        contentBefore={
                          <IconSaxIcon name="minus-square" size={20} />
                        }
                      >
                        {t(
                          'js.features.admin.transactions.ignore_transaction.action_button'
                        ) ?? ''}
                      </Table.TableCellRowActionsContent.TableRowActionContent>
                    </Table.TableCellRowActionsContent.TableRowAction>
                  )}
                </Table.TableCellRowActionsContent.TableRowActions>
              </Table.TableCellRowActionsContent>
            );
          }

          return <></>;
        },
        footer: (info) => info.column.id,
      }),
    ],
    [columnHelper, ignoreTransactionMutation, t, toggleShowPairTransactionModal]
  );

  return (
    <>
      <ColocAPIConnectedTable<Transaction, TransactionsListSortType>
        ref={tableRef}
        columns={columns}
        dataSource={useGetTransactionsListContext()!}
      />

      {showPairTransactionModal.show && showPairTransactionModal.transaction && (
        <TransactionPairModal
          open={showPairTransactionModal.show}
          onClose={() =>
            toggleShowPairTransactionModal({
              show: false,
              transaction: undefined,
            })
          }
          transaction={showPairTransactionModal.transaction}
        />
      )}

      {showLinkedTransactionConfirmationModal.show &&
        showLinkedTransactionConfirmationModal.transaction && (
          <Modal
            open={showLinkedTransactionConfirmationModal.show}
            onClose={() =>
              toggleLinkedTransactionConfirmationModal({
                transaction: undefined,
                show: false,
              })
            }
          >
            <PopUp
              type="feedback"
              title={t(
                'js.features.admin.transactions.transaction_paired_confirmation.title'
              )}
              description={
                t(
                  'js.features.admin.transactions.transaction_paired_confirmation.text',
                  {
                    date: `${getFullMonthName(
                      new Date(
                        showLinkedTransactionConfirmationModal.transaction.date
                      ) ?? 0
                    )} ${getFullYearNumber(
                      new Date(
                        showLinkedTransactionConfirmationModal.transaction.date
                      ) ?? 0
                    )}`,
                  }
                ) ?? ''
              }
              primaryButtonProps={{
                onClick: () =>
                  toggleLinkedTransactionConfirmationModal({
                    transaction: undefined,
                    show: false,
                  }),
                title:
                  t(
                    'js.features.admin.transactions.transaction_paired_confirmation.button_text'
                  ) ?? '',
              }}
            />
          </Modal>
        )}
    </>
  );
};

export { TransactionsListTable };
