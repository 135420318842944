import React from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useDownloadFile } from '@/hooks/use-download-file';
import { useCreateRentReportContext } from '../../hooks/use-create-rent-report';
import { Modal } from '@/components/design-system/atoms/modal';
import { CreateRentReportConfirmationProps } from './types';
import { PopUp } from '@/components/design-system/molecules/pop-up';

const CreateRentReportConfirmation: React.FC<CreateRentReportConfirmationProps> =
  ({ open, onClose, report }) => {
    const { toggleShowCreationConfirmationModal } =
      useCreateRentReportContext()!;

    const { t } = useTranslation();

    const [downloadingFile, downloadFile] = useDownloadFile({
      url: report.download_link ?? '',
      filename: `rent_report_${report.house.name}_${format(
        new Date(report.date ?? new Date()),
        'MMMyy'
      )}`,
      filetype: 'pdf',
      onCompletion: () => {
        toggleShowCreationConfirmationModal({
          show: false,
          report: undefined,
        });
      },
    });

    return (
      <Modal open={open} onClose={onClose}>
        <PopUp
          type="default"
          title={t('js.features.admin.rent_report.confirmation_modal.title')}
          description={`${format(new Date(report.date), 'MMM yyyy')} - ${
            report.house.name
          }`}
          primaryButtonProps={{
            disabled: downloadingFile,
            onClick: () => {
              downloadFile();
            },
            title:
              t(
                'js.features.admin.rent_report.confirmation_modal.download_button'
              ) ?? '',
          }}
          secondaryButtonProps={{
            onClick: () =>
              toggleShowCreationConfirmationModal({
                show: false,
                report: undefined,
              }),
            title:
              t(
                'js.features.admin.rent_report.confirmation_modal.close_button'
              ) ?? '',
          }}
        />
      </Modal>
    );
  };

export { CreateRentReportConfirmation };
