import React from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { ToastProps } from './types';
import { composeClassName } from '@/utils/util_helpers';
import { toastBgStyling } from './styles';
import { Button } from '../button';

const ToastIcon: React.FC<Pick<ToastProps, 'type'>> = ({ type }) => {
  return (
    <ReactSVG
      src={require(`@assets/icons/toast-icons/${type}.svg`)}
      beforeInjection={(svg) => {
        svg.setAttribute('width', `40`);
        svg.setAttribute('height', `40`);
      }}
    />
  );
};

const Toast: React.FC<ToastProps> = ({
  className,
  type,
  title,
  text,
  onDismiss,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={composeClassName(
        'w-[400px] rounded-lg bg-white shadow-large',
        className && className
      )}
      style={{ pointerEvents: 'all' }}
    >
      <div className="flex items-center justify-between gap-3 py-4 px-3">
        <div
          className={composeClassName(
            'h-[60px] w-[8px] rounded-sm',
            toastBgStyling[type]
          )}
        />
        <ToastIcon type={type} />
        <div className="flex flex-1">
          <div className="flex-1">
            <span className="line-clamp-1 block text-lead2 font-medium text-text-primary">
              {title ?? t(`js.components.toast.default_title.${type}`)}
            </span>
            {text && (
              <span className="line-clamp-2 block text-body text-text-secondary">
                {text}
              </span>
            )}
          </div>
          <Button
            iconName="close"
            size="large"
            variant="icon"
            onClick={() => {
              onDismiss();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export { Toast };
