import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RentHouseCardProps } from './types';
import { composeClassName } from '@/utils/util_helpers';
import { Card } from '@/components/design-system/atoms/card';
import { amountToString } from '@/utils/util_helpers/formatting/amount_to_string';

const RentHouseCard: React.FC<RentHouseCardProps> = ({
  className,
  rent_summary,
  onClick,
}) => {
  const { t } = useTranslation();
  const {
    cover_image_url = undefined,
    name,
    unpaid_amount,
    rents_count,
    paid_rents_count,
  } = rent_summary;
  const [isHovering, setIsHovering] = useState(false);

  const percentagePaidOfRents = useMemo(() => {
    return (100 * paid_rents_count) / rents_count;
  }, [paid_rents_count, rents_count]);

  return (
    <Card
      className={composeClassName(
        'h-[320px] w-[320px] transform-gpu cursor-pointer transition-transform duration-300',
        isHovering && '!scale-[101%]',
        className && className
      )}
      {...(isHovering && {
        style: { boxShadow: '0px 30px 60px rgba(139, 133, 110, 0.22' },
      })}
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      onClick={onClick}
    >
      {cover_image_url && (
        <img
          src={cover_image_url}
          alt={name}
          className="h-[55%] w-full object-cover"
        />
      )}
      <div className="h-full py-4 px-3">
        <div>
          <span className="text-lead1 text-text-primary">{name}</span>
        </div>
        <div className="flex w-full gap-4 pt-3">
          <div className="flex w-[40%] flex-col ">
            <span className="text-lead2 font-medium text-text-primary">
              {t('js.features.admin.rents.rent_house_card.paid_tenants_range', {
                num_paid_rents: paid_rents_count,
                num_total_rents: rents_count,
              })}
            </span>
            <div className="h-[6px] w-full overflow-hidden rounded-sm bg-C300">
              <div
                className="h-full bg-J300"
                style={{ width: `${percentagePaidOfRents}%` }}
              />
            </div>
            <span className="text-footnote1 text-text-secondary">
              {t('js.features.admin.rents.rent_house_card.paid_tenants')}
            </span>
          </div>
          <div className="flex flex-col">
            <span
              className={composeClassName(
                'text-lead2 font-medium',
                unpaid_amount.cents === 0
                  ? 'text-text-primary'
                  : 'text-text-failed'
              )}
            >
              {amountToString(unpaid_amount)}
            </span>
            <span className="text-footnote1 text-text-secondary">
              {t('js.features.admin.rents.rent_house_card.unpaid_amount')}
            </span>
          </div>
        </div>
      </div>
    </Card>
  );
};

export { RentHouseCard };
