import { useMemo } from 'react';
import { useIsFetching, useQuery } from '@tanstack/react-query';

import { toString } from 'lodash-es';
import { t } from 'i18next';

import { getRentsCSV } from '@/lib/coloc-api/admin';

import { useGetRentsListContext } from '../use-get-rents-list';
import { showToast } from '@/services/toast-service';
import { useRentsListFiltersContext } from '../use-rents-list-filter';

const useGetRentSelectionCSV = () => {
  const { sortDirection, selectedColumnToSort } = useRentsListFiltersContext()!;
  const { rowSelectionFullData, setRowSelection } = useGetRentsListContext()!;

  const arrayOfSelectedRentIds = useMemo(() => {
    return rowSelectionFullData.map((rent) => rent.id);
  }, [rowSelectionFullData]);

  const { refetch: fetch } = useQuery({
    queryKey: [
      'getRentsSelectionCSV',
      sortDirection,
      selectedColumnToSort,
      arrayOfSelectedRentIds,
    ],
    enabled: false,
    queryFn: async () => {
      const data = await getRentsCSV({
        ...(arrayOfSelectedRentIds && {
          ids: toString(arrayOfSelectedRentIds),
        }),
        ...(sortDirection && { sort_direction: sortDirection }),
        ...(selectedColumnToSort && { sort: selectedColumnToSort }),
      });

      const href = URL.createObjectURL(data.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'rents.csv');
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      return data;
    },
    onSuccess: () => {
      showToast.success({
        text:
          t(
            `js.features.admin.rents.rent_list.bulk_actions.csv.success_text_toast`
          ) ?? '',
      });
      setRowSelection({});
    },
    onError: () => {
      showToast.error({});
    },
  });

  const numOfRentCSVFetches = useIsFetching({
    queryKey: ['getRentsSelectionCSV', 'getAllRentsCSV'],
  });

  const isExportingRents = useMemo(() => {
    return numOfRentCSVFetches >= 1;
  }, [numOfRentCSVFetches]);

  return {
    fetch,
    isExportingRents,
  };
};

export { useGetRentSelectionCSV };
