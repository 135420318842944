/* eslint-disable react/prop-types */
import React, { Children, isValidElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from '@/components/design-system/molecules/header';
import { useAuthContext } from '@/hooks/use-auth';
import { ConnectedHeaderProps, Slots } from './types';
import { COLOC_MAIN_APP } from '@/config';
import { UserMenu } from './components/user-menu';
import { composeClassName } from '@/utils/util_helpers';
import { LocaleSwitcher } from './components/locale-selector';
import { useWindowDimensions } from '@/hooks/use-window-dimensions';
import { ConnectedMobileSideMenu } from './components/connected-mobile-side-menu';

const Leading: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

const Trailing: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

const ConnectedHeader: React.FC<ConnectedHeaderProps> & {
  Leading: React.FC<{ children: React.ReactNode }>;
  Trailing: React.FC<{ children: React.ReactNode }>;
  MobileSideMenuLeading: React.FC<{ children: React.ReactNode }>;
  MobileSideMenuTrailing: React.FC<{ children: React.ReactNode }>;
} = ({ children }) => {
  const { screenIsLargeOrEqualThan } = useWindowDimensions();
  const { t } = useTranslation();

  const filledSlots = useMemo(() => {
    const slots: Slots = {
      leading: null,
      trailing: null,
      mobileSideMenuLeading: null,
      mobileSideMenuTrailing: null,
    };

    Children.toArray(children).forEach((child) => {
      if (!isValidElement(child)) {
        return;
      }

      switch (child.type) {
        case Leading:
          slots.leading = child;
          break;
        case Trailing:
          slots.trailing = child;
          break;
        case ConnectedMobileSideMenu.Leading:
          slots.mobileSideMenuLeading = child;
          break;
        case ConnectedMobileSideMenu.Trailing:
          slots.mobileSideMenuTrailing = child;
          break;
        default:
      }
    });

    return slots;
  }, [children]);

  const { user } = useAuthContext()!;

  return (
    <Header>
      <Header.Leading>{filledSlots.leading}</Header.Leading>
      <Header.Trailing className={composeClassName(user ? 'gap-5' : 'gap-7')}>
        {filledSlots.trailing}
        {screenIsLargeOrEqualThan('sm')}
        <LocaleSwitcher />
        {screenIsLargeOrEqualThan('lg') ? (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {user ? (
              <UserMenu />
            ) : (
              <>
                <Header.HeaderLink href={`${COLOC_MAIN_APP}/users/sign_in`}>
                  {t('js.features.shared.header.login') ?? ''}
                </Header.HeaderLink>
                <Header.HeaderLink href={`${COLOC_MAIN_APP}/users/sign_up`}>
                  {t('js.features.shared.header.register') ?? ''}
                </Header.HeaderLink>
              </>
            )}
          </>
        ) : (
          <ConnectedMobileSideMenu>
            {filledSlots.mobileSideMenuLeading}
            {filledSlots.mobileSideMenuTrailing}
          </ConnectedMobileSideMenu>
        )}
      </Header.Trailing>
    </Header>
  );
};

ConnectedHeader.Leading = Leading;
ConnectedHeader.Trailing = Trailing;
ConnectedHeader.MobileSideMenuLeading = ConnectedMobileSideMenu.Leading;
ConnectedHeader.MobileSideMenuTrailing = ConnectedMobileSideMenu.Trailing;

export { ConnectedHeader };
