import React from 'react';

import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { TransactionPairedRentCellContentProps } from './types';
import { Table } from '@/components/design-system/molecules/table';
import { PopOver } from '@/components/design-system/molecules/pop-over';
import { amountToString } from '@/utils/util_helpers/formatting/amount_to_string';

const TransactionPairedRentCellContent: React.FC<TransactionPairedRentCellContentProps> =
  ({ rent, room, tenant, amount }) => {
    const { t } = useTranslation();

    if (rent !== null) {
      return (
        <PopOver placement="bottom-start">
          <PopOver.PopOverTrigger>
            <Table.TableCellDefaultContent
              {...(room !== null && {
                description: `${room.house.name} #${room.room_number}`,
              })}
            >
              {format(new Date(rent.period.start_date), 'MMM yyyy')}
            </Table.TableCellDefaultContent>
          </PopOver.PopOverTrigger>
          <PopOver.PopOverContent
            className="min-w-[250px] !max-w-[500px]"
            title={format(new Date(rent.period.start_date), 'MMM yyyy')}
          >
            <div className="flex flex-col gap-3">
              <div className="flex flex-col text-body text-text-secondary">
                {room !== null && (
                  <>
                    <span>{room.house.name}</span>
                    <span>
                      {t(
                        'js.features.admin.transactions.transactions_list.covered_rent_cell.room_name',
                        { room_number: room.room_number }
                      )}
                    </span>
                    <span>{tenant?.name}</span>
                  </>
                )}
              </div>
              <span className="text-body text-text-tertiary">
                {t(
                  'js.features.admin.transactions.transactions_list.covered_rent_cell.covered_cost',
                  {
                    paid_amount: amountToString(amount),
                    total_amount: amountToString(rent.rent_amount),
                  }
                )}
              </span>
            </div>
          </PopOver.PopOverContent>
        </PopOver>
      );
    }

    return <Table.TableCellDefaultContent>/</Table.TableCellDefaultContent>;
  };

export { TransactionPairedRentCellContent };
