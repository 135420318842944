import React, { useCallback, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './styles.css';
import { getI18n } from 'react-i18next';
import { endOfDay, lastDayOfMonth } from 'date-fns';
import { TextInput } from '../../atoms/text/text-input';
import { DatePickerProps } from './types';
import { DatePickerDropDownHeader } from './components/datepicker-dropdown-header';
import { IconSaxIcon } from '../../atoms/icon';
import { theme } from '@/utils/util_theme';

const IDatePicker: React.FC<DatePickerProps> = ({
  className,
  minDate,
  maxDate,
  onChange,
  disabled = false,
  placeholder,
  dateRange = false,
  value,
  startValue,
  endValue,
  type,
  ...props
}) => {
  const i18n = getI18n();

  const [inputIsFocused, setInputIsFocused] = useState(false);

  const checkOpenStateDatePicker = useCallback(() => {
    setTimeout(() => {
      const datePickerWindow = document.querySelector(
        '.react-datepicker-popper'
      );
      setInputIsFocused(!!datePickerWindow);
    }, 50);
  }, []);

  const renderDayContents = (dayOfMonth: number) => {
    return (
      <div className="after: relative flex h-full w-full items-center justify-center bg-inherit text-body text-inherit">
        {dayOfMonth}
      </div>
    );
  };

  const dateFormat = useMemo(() => {
    if (type === 'month') {
      return 'MMM yy';
    }

    if (type === 'time') {
      return 'kk:mm';
    }

    if (type === 'datetime') {
      return 'dd/MM/yyyy kk:mm';
    }

    return 'dd/MM/yyyy';
  }, [type]);

  const onChangeHandler = useCallback(
    (date: Date | [Date | null, Date | null]) => {
      if (dateRange) {
        // eslint-disable-next-line prefer-const
        let [start, end] = date as [Date, Date | null];
        if (type === 'month' && end) {
          end = endOfDay(lastDayOfMonth(end));
        }

        if (type === 'date' && end) {
          end = endOfDay(end);
        }

        // @ts-ignore
        onChange([start, end]);
      } else {
        // @ts-ignore
        onChange(date);
      }
      checkOpenStateDatePicker();
    },
    [checkOpenStateDatePicker, dateRange, onChange, type]
  );

  return (
    <div>
      <DatePicker
        id="date-picker-input"
        placeholderText={placeholder}
        selected={dateRange ? startValue : value}
        disabled={disabled}
        dateFormat={dateFormat}
        locale={i18n.language}
        onChange={onChangeHandler}
        onFocus={() => {
          checkOpenStateDatePicker();
        }}
        onBlur={() => checkOpenStateDatePicker()}
        customInput={
          <TextInput
            containerClassName={className}
            className={inputIsFocused ? '!border-R300' : ''}
            contentAfter={
              <IconSaxIcon
                name={type === 'time' ? 'clock-1' : 'calendar-2'}
                strokeColor={
                  inputIsFocused
                    ? theme.colors?.R300
                    : theme.colors?.text?.primary
                }
              />
            }
            {...props}
          />
        }
        calendarClassName="calendar-dropdown__container"
        renderCustomHeader={(headerProps) => (
          <DatePickerDropDownHeader type={type} {...headerProps} />
        )}
        renderDayContents={renderDayContents}
        minDate={minDate}
        maxDate={maxDate}
        timeIntervals={15}
        {...(dateRange && {
          selectsRange: true,
          startDate: startValue,
          endDate: endValue,
        })}
        {...(type === 'time' && {
          showTimeSelect: true,
          showTimeSelectOnly: true,
        })}
        {...(type === 'datetime' && { showTimeSelect: true })}
        {...(type === 'month' && {
          showMonthYearPicker: true,
        })}
      />
    </div>
  );
};

export { IDatePicker as DatePicker };
