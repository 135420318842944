import React from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_CONTENT_WIDTH } from '@/features/marketplace/constants';
import { composeClassName } from '@/utils/util_helpers';

const Inquiries = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full bg-R300">
      <div
        className={composeClassName(
          DEFAULT_CONTENT_WIDTH,
          'm-auto flex flex-col gap-3 py-7',
          'md:py-[6rem]',
          'lg:py-[8rem]'
        )}
      >
        <span
          className={composeClassName(
            'text-[2.8rem] font-bold text-text-primary',
            'md:text-[4rem]'
          )}
        >
          {t('js.features.marketplace.press.inquiries.title')}
        </span>
        <span className="text-lead1 text-text-primary">
          {t('js.features.marketplace.press.inquiries.subtitle')}
          <a href="mailto:press@colochousing.com" className="underline">
            press@colochousing.com
          </a>
        </span>
      </div>
    </div>
  );
};

export { Inquiries };
