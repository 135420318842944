/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useContext } from 'react';
import { PaginationProps } from '../../types';

type PaginationContextProps = Pick<
  PaginationProps,
  'currentPage' | 'onPageChange' | 'disabled'
> & {
  pages: Array<number>;
  previousPages: Array<number>;
  isPreviousTruncable: boolean;
  middlePages: Array<number>;
  isNextTruncable: boolean;
  nextPages: Array<number>;
};

const usePagination = ({
  currentPage,
  totalPages,
  onPageChange,
  disabled,
}: PaginationProps): PaginationContextProps => {
  const pages = Array(totalPages)
    .fill(0)
    .map((_, i) => i + 1);

  const middlePagesSiblingCount = 1;
  const edgePageCount = 1;

  const isReachedToFirst = currentPage <= middlePagesSiblingCount;
  const isReachedToLast = currentPage + middlePagesSiblingCount >= totalPages;

  const middlePages = React.useMemo(() => {
    const middlePageCount = middlePagesSiblingCount * 2 + 1;
    if (isReachedToFirst) {
      return pages.slice(0, middlePageCount);
    }
    if (isReachedToLast) {
      return pages.slice(-middlePageCount);
    }
    return pages.slice(
      currentPage - middlePagesSiblingCount,
      currentPage + middlePagesSiblingCount + 1
    );
  }, [isReachedToFirst, isReachedToLast, pages, currentPage]);

  const getAllPreviousPages = React.useCallback(() => {
    return pages.slice(0, middlePages[0] - 1);
  }, [middlePages, pages]);

  const previousPages = React.useMemo(() => {
    if (isReachedToFirst || getAllPreviousPages().length < 1) {
      return [];
    }
    return pages
      .slice(0, edgePageCount)
      .filter((p) => !middlePages.includes(p));
  }, [isReachedToFirst, getAllPreviousPages, pages, middlePages]);

  const getAllNextPages = React.useMemo(() => {
    return pages.slice(
      middlePages[middlePages.length - 1],
      pages[pages.length]
    );
  }, [pages, middlePages]);

  const nextPages = React.useMemo(() => {
    if (isReachedToLast) {
      return [];
    }
    if (getAllNextPages.length < 1) {
      return [];
    }
    return pages
      .slice(pages.length - edgePageCount, pages.length)
      .filter((p) => !middlePages.includes(p));
  }, [getAllNextPages.length, isReachedToLast, middlePages, pages]);

  const isPreviousTruncable = React.useMemo(() => {
    // Is truncable if first value of middlePage is larger than last value of previousPages
    return middlePages[0] > previousPages[previousPages.length - 1] + 1;
  }, [previousPages, middlePages]);

  const isNextTruncable = React.useMemo(() => {
    // Is truncable if last value of middlePage is larger than first value of previousPages
    return middlePages[middlePages.length - 1] + 1 < nextPages[0];
  }, [nextPages, middlePages]);

  return {
    disabled,
    onPageChange,
    currentPage,
    pages,
    previousPages,
    isPreviousTruncable,
    middlePages,
    isNextTruncable,
    nextPages,
  };
};

const PaginationContext = createContext<PaginationContextProps | undefined>(
  undefined
);

type PaginationContextProviderProps = PaginationProps & {
  children: React.ReactNode;
};

const PaginationContextProvider: React.FC<PaginationContextProviderProps> = ({
  currentPage,
  totalPages,
  onPageChange,
  disabled,
  children,
}) => {
  const pagination = usePagination({
    currentPage,
    totalPages,
    onPageChange,
    disabled,
  });

  return (
    <div>
      <PaginationContext.Provider value={pagination}>
        {children}
      </PaginationContext.Provider>
    </div>
  );
};

const usePaginationContext = () => {
  const context = useContext(PaginationContext);

  if (context === null) {
    throw new Error(
      'usePaginationContext must be used within a PaginationContextProvider'
    );
  }

  return context;
};

export { usePaginationContext, PaginationContextProvider };
