import { ButtonSize, ButtonVariant } from './types';
import { theme } from '../../../../utils/util_theme';

export const buttonStyling: { [key in ButtonVariant]: string } = {
  primary: 'bg-R300 hover:bg-R400 text-E300 disabled:bg-E50 disabled:text-E75 ',
  secondary:
    'bg-E300 hover:bg-E200 text-white disabled:bg-E30 disabled:text-E75',
  warning:
    'bg-R700 hover:bg-R600 text-white disabled:bg-Y30 disabled:text-E75',
  tertiary:
    'bg-transparent hover:bg-E300 text-E300 hover:text-white disabled:bg-transparent border-[1px] border-E300 disabled:border-transparent disabled:text-E75',
  link: 'bg-transparent text-E200 hover:text-J300 underline hover:no-underline disabled:text-E75',
  ghost: 'bg-E30 hover:bg-E50 text-E300 disabled:text-E75',
  icon: 'bg-transparent',
};

export const buttonContainerStylingSize: { [key in ButtonSize]: string } = {
  small: 'px-[calc(theme(spacing.2)_+_theme(spacing.1))] py-[6px]',
  medium: 'px-[18px] py-[9px]',
  large: 'px-[theme(spacing.4)] py-[calc(theme(spacing.2)_+_theme(spacing.1))]',
};

export const buttonContainerOnlyIconStylingSize: {
  [key in ButtonSize]: string;
} = {
  small: 'p-2',
  medium: 'p-[11px]',
  large: 'p-[calc(theme(spacing.2)_+_theme(spacing.1))]',
};

export const iconColors: { [key in ButtonVariant]: string | undefined } = {
  primary: theme?.colors?.text.primary,
  secondary: theme?.colors?.white,
  warning: theme?.colors?.R700,
  tertiary: theme?.colors?.text.primary,
  link: theme?.colors?.text.primary,
  ghost: theme?.colors?.text.primary,
  icon: theme?.colors?.text.primary,
};
