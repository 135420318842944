import React from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { SectionTitle } from '@/features/marketplace/components/section-title';
import { composeClassName } from '@/utils/util_helpers';
import { DEFAULT_CONTENT_WIDTH } from '@/features/marketplace/constants';

const backgroundImage = require(`@assets/images/marketplace/about-us/section-3-bg.svg`);

const services = [
  'purchase',
  'renovation',
  'decoration',
  'rental',
  'property-management',
  'buy',
] as const;

const Service: React.FC<{ service: typeof services[number] }> = ({
  service,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={composeClassName(
        'flex w-full flex-col items-center gap-3 text-center',
        'md:w-[45%] md:items-start md:text-left',
        'lg:w-[30%] lg:flex-row lg:items-start lg:gap-4'
      )}
    >
      <ReactSVG
        src={require(`@assets/icons/marketplace/about-us/service-icons/${service}.svg`)}
        className={composeClassName('inline-block align-baseline')}
        beforeInjection={(svg) => {
          svg.setAttribute('width', '72');
          svg.setAttribute('height', '72');
        }}
      />
      <div className="flex flex-col gap-2">
        <span className="text-h5 text-text-primary">
          {t(
            `js.features.marketplace.about_us.section3.services.${service}_title`
          )}
        </span>
        <span className="text-lead2 text-text-secondary">
          {t(
            `js.features.marketplace.about_us.section3.services.${service}_text`
          )}
        </span>
      </div>
    </div>
  );
};

const ThirdSection = () => {
  const { t } = useTranslation();

  return (
    <section className="bg-[#FBFBFB]">
      <div
        className="h-[12rem] w-full"
        style={{
          backgroundSize: '100% auto',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${backgroundImage})`,
        }}
      />
      <div className={composeClassName(DEFAULT_CONTENT_WIDTH, 'm-auto')}>
        <SectionTitle>
          {t('js.features.marketplace.about_us.section3.title')}
        </SectionTitle>
        <div
          className={composeClassName(
            'mt-8 flex flex-wrap justify-between gap-x-4 gap-y-7 pb-8',
            'md:pb-[10.5rem]',
            'lg:pb-[14rem]'
          )}
        >
          {services.map((service) => {
            return <Service key={service} service={service} />;
          })}
        </div>
      </div>
    </section>
  );
};

export { ThirdSection };
