<template>
  <div
    class="inset-0 fixed z-50 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="
        pb-20
        sm:p-0
        flex
        min-h-screen
        items-end
        justify-center
        px-4
        pt-4
        text-center
        sm:block
      "
    >
      <div
        class="inset-0 fixed bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <span
        class="hidden sm:inline-block sm:h-screen sm:align-middle"
        aria-hidden="true"
        >&#8203;</span
      >

      <div
        class="
          inline-block
          w-full
          transform
          space-y-8
          overflow-hidden
          rounded-lg
          bg-white
          px-10
          py-8
          text-left
          align-bottom
          shadow-xl
          transition-all
          sm:my-8
          sm:w-full
          sm:max-w-md
          sm:align-middle
          lg:max-w-lg
        "
      >
        <div class="flex w-full">
          <div class="m-auto">
            <lottie-player
              class="md:pr-0 pr-36"
              autoplay
              loop
              style="width: 400px"
              src="https://assets6.lottiefiles.com/packages/lf20_s03mfrde.json"
              speed="1"
              debug
            ></lottie-player>
            <div
              class="lg:pr-0 flex flex-col items-center space-y-1 pr-[136px]"
            >
              <div class="flex flex-col items-center space-y-2">
                <span
                  class="
                    text-center text-[22px]
                    font-bold
                    text-primary-100
                    lg:text-2xl
                  "
                  >Thank you</span
                >
                <span class="text-lg font-normal text-[#414141]"
                  >Request submitted</span
                >
              </div>

              <div class="pt-4">
                <button
                  @click="$emit('close')"
                  class="
                    text-mainback
                    rounded-md
                    bg-[#FECE1F]
                    py-2
                    px-6
                    text-sm
                    font-normal
                    hover:bg-opacity-75
                  "
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    Done() {
      this.$root.$emit('closeModal');
    },
  },
};
</script>
