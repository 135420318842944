<template>
  <section
    class="w-full"
    :style="{ backgroundImage: `url(${bgImage})` }"
    id="ContactUs"
  >
    <div
      class="
        m-auto
        w-full
        max-w-[1520px]
        space-y-4
        px-4
        pt-7
        pb-4
        lg:space-y-7
        lg:pt-9
        lg:pb-8
      "
    >
      <div
        class="
          flex flex-col
          items-center
          space-y-3
          text-center
          font-Vollkorn
          text-[28px]
          font-bold
          leading-[34px]
          text-text-primary
          lg:items-start
          lg:text-left lg:text-[48px]
          lg:leading-[67px]
        "
      >
        <span leading-loose
          >{{ t('vue_components.spot_a_house.get_started') }} <br />
          {{ t('vue_components.spot_a_house.and_submit_the_house') }}</span
        >
      </div>

      <div class="grid w-full grid-cols-1 lg:grid-cols-3">
        <ApplicationForm />
        <div class="col-span-1 hidden h-full w-full pl-10 lg:flex">
          <object
            width="468"
            height="422"
            class="m-auto"
            :data="require('assets/images/spot_a_house/svgs/sixth_section.svg')"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import bgImage from 'assets/images/spot_a_house/secondsection.png';
import ApplicationForm from '../ApplicationForm.vue';

export default {
  components: {
    ApplicationForm,
  },
  data() {
    return {
      bgImage,
    };
  },
};
</script>
