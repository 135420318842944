import React from 'react';
import {
  DiscussionThreadProviderContext,
  useDiscussionThreadProvider,
} from '../hooks/use-discussion-thread';
import { DiscussionThreadContent } from '../components/discussion-thread-content';

const DiscussionThreadsProvider: React.FC<{ children: any }> = ({
  children,
}) => {
  const hook = useDiscussionThreadProvider();

  return (
    <DiscussionThreadProviderContext.Provider value={hook}>
      {children}
      <DiscussionThreadContent />
    </DiscussionThreadProviderContext.Provider>
  );
};

export { DiscussionThreadsProvider };
