import React, { useEffect, useState } from 'react';
import { getPropertyManagerList } from '@/lib/coloc-api/admin';
import { DropDown } from '@/components/design-system/molecules/dropdown';
import { PropertyManager } from '@/features/admin/contracts/types';
import { useRoomAssignmentListFiltersContext } from '../../../../hooks/use-get-room-assignment-list-filter';

const PropertyManagersFilter = () => {
  const { selectedPropertyManager, setSelectedPropertyManager } =
    useRoomAssignmentListFiltersContext()!;

  const [propertyManagers, setPropertyManagers] = useState<
    Array<PropertyManager>
  >([]);

  useEffect(() => {
    getPropertyManagerList().then((response) => {
      setPropertyManagers(response.data.data);
    });
  }, []);

  return (
    <DropDown
      placeholder="All"
      className="z-20 w-[130px]"
      showLabel
      label="Property Managers"
      value={selectedPropertyManager}
      {...(selectedPropertyManager && {
        displayValue: `${selectedPropertyManager.first_name} ${selectedPropertyManager.last_name}`,
      })}
      onChange={setSelectedPropertyManager}
    >
      <DropDown.DropDownOptions>
        {propertyManagers.map((option) => {
          return (
            <DropDown.DropDownOption key={option.id} value={option}>
              <DropDown.DropDownOptionDefaultContent>
                {`${option.first_name} ${option.last_name}`}
              </DropDown.DropDownOptionDefaultContent>
            </DropDown.DropDownOption>
          );
        })}
      </DropDown.DropDownOptions>
    </DropDown>
  );
}

export { PropertyManagersFilter };
