/* eslint-disable react/prop-types */
import React, { Children, isValidElement, useMemo } from 'react';
import { Tab as InternalTab } from '@headlessui/react';
import { TabsProps, TabGroupSlots } from './types';
import { composeClassName } from '../../../../utils/util_helpers';
import { inputParentContainer } from '../../shared-styles';
import { TabsListProps } from './components/tabs-list/types';

import { TabsScreensProps } from './components/tabs-screens/types';
import { TabsList } from './components/tabs-list';
import { TabsScreens } from './components/tabs-screens';
import { TabProps } from './components/tab/types';
import { Tab } from './components/tab';
import { TabDefaultContentProps } from './components/tab-default-content/types';
import { TabDefaultContent } from './components/tab-default-content';
import { TabScreen } from './components/tab-screen';
import { TabScreenProps } from './components/tab-screen/types';

const Tabs: React.FC<TabsProps> & {
  TabsList: React.FC<TabsListProps>;
  TabsScreens: React.FC<TabsScreensProps>;
  TabScreen: React.FC<TabScreenProps>;
  Tab: React.FC<TabProps>;
  TabDefaultContent: React.FC<TabDefaultContentProps>;
} = ({
  children,
  defaultIndex = 0,
  selectedIndex: internalSelectedIndex,
  className,
  onChange,
  ...props
}) => {
  const filledSlots = useMemo(() => {
    const slots: TabGroupSlots = {
      tabs: null,
      screens: null,
    };

    Children.toArray(children as unknown as React.ReactNode[]).forEach(
      (child) => {
        if (!isValidElement(child)) {
          return;
        }

        switch (child.type) {
          case TabsList:
            slots.tabs = child;
            break;
          case TabsScreens:
            slots.screens = child;
            break;
          default:
        }
      }
    );

    return slots;
  }, [children]);

  return (
    <InternalTab.Group
      {...props}
      as="div"
      defaultIndex={defaultIndex}
      {...(internalSelectedIndex && {
        selectedIndex: internalSelectedIndex,
      })}
      onChange={onChange}
      className={composeClassName(
        inputParentContainer,
        'min-h-full w-full min-w-full',
        className && className
      )}
    >
      {filledSlots.tabs}
      {filledSlots.screens}
    </InternalTab.Group>
  );
};

Tabs.TabsList = TabsList;
Tabs.Tab = Tab;
Tabs.TabsScreens = TabsScreens;
Tabs.TabScreen = TabScreen;
Tabs.TabDefaultContent = TabDefaultContent;

export { Tabs };
