import { useQuery } from '@tanstack/react-query';
import { getRentTransactionsParams } from '@/lib/coloc-api/admin';
import { getRentTransactions } from '@/services/coloc-api/admin';

const useGetRentTransactions = (params: getRentTransactionsParams) => {
  const fetch = useQuery({
    queryKey: ['getRentTransactions', params],
    queryFn: () => getRentTransactions(params),
    keepPreviousData: true,
  });

  return {
    fetch,
  };
};

export { useGetRentTransactions };
