const defaultTheme = require('tailwindcss/defaultTheme');

const baseColors = {
  // Ripe Mango
  R50: '#fffae9',
  R75: '#ffeba3',
  R100: '#fee37d',
  R200: '#fed645',
  R300: '#fece1f',
  R400: '#e5bc20',
  R500: '#9b7e13',
  R600: '#F03239',
  R700: '#E5422F',

  // Jungle Green
  J50: '#e8f5f3',
  J75: '#a2d5cf',
  J100: '#7bc4bc',
  J200: '#42aa9f',
  J300: '#1b998b',
  J400: '#136b61',
  J500: '#105d55',
  // Bright Yellow
  B50: '#fff6e9',
  B75: '#fedaa3',
  B100: '#fdca7d',
  B200: '#fdb344',
  B300: '#fca41e',
  B400: '#b07315',
  B500: '#9a6412',
  // Queen Blue
  Q50: '#ecf0f4',
  Q75: '#afc1d4',
  Q100: '#8ea7c2',
  Q200: '#5d82a7',
  Q300: '#3c6895',
  Q400: '#2a4968',
  Q500: '#253f5b',
  // Cinnabar
  C50: '#fcecea',
  C75: '#f4b2aa',
  C100: '#f09186',
  C200: '#e96252',
  C300: '#e5422f',
  C400: '#a02e21',
  C500: '#8c281d',
  // Natural
  N1000: '#1a1a1aff',
  N900: '#212529ff',
  N800: '#343a40ff',
  N700: '#495057ff',
  N600: '#6c757dff',
  N500: '#adb5bdff',
  N400: '#ced4daff',
  N300: '#e9ecefff',
  N200: '#f3f4f5ff',
  N100: '#f8f9faff',
  N0: '#ffffffff',

  // Eerie Black
  E10: '#fcfcfc',
  E30: '#f7f7f7',
  E50: '#e8e8e8',
  E75: '#a1a1a1',
  E100: '#7a7a7a',
  E200: '#414141',
  E300: '#1a1a1a',
  E400: '#121212',
  E500: '#101010',


};

const semanticColors = {
  text: {
    primaryLight: baseColors.N600,
    primaryMedium: baseColors.N700,
    primaryHeavy: baseColors.N900,
    secondaryLight: baseColors.N0,
    secondaryMedium: baseColors.N300,
    secondaryHeavy: baseColors.N400,
    successLight: baseColors.J50,
    successHeavy: baseColors.J300,
    dangerLight: baseColors.C50,
    dangerHeavy: baseColors.C300,
    infoLight: baseColors.Q50,
    infoHeavy: baseColors.Q300,
    warningHeavy: '#9B7E13',
    disabled: baseColors.N500,
    primary: '#1A1A1A',
    secondary: '#414141',
    tertiary: '#7A7A7A',
    success: '#1B998B',
    failed: '#E5422F',
  },
  border: {
    primaryLight: baseColors.N300,
  },
  fill: {
    button: {
      primaryMedium: baseColors.R100,
      primaryHeavy: baseColors.R300,
      secondaryLight: baseColors.N100,
      secondaryMedium: baseColors.N200,
      disabled: baseColors.N100,
    },
    surface: {
      primaryLight: baseColors.N0,
      primaryMedium: baseColors.N100,
      primaryHeavy: baseColors.N300,
      secondaryMedium: baseColors.N900,
      secondaryHeavy: baseColors.N1000,
      successLight: baseColors.J50,
      infoLight: baseColors.Q50,
      warningHeavy: baseColors.R300,
    },
    icon: {
      primaryHeavy: baseColors.N900,
      primaryMedium: baseColors.N700,
      successHeavy: baseColors.J300,
      infoHeavy: baseColors.Q300,
      dangerHeavy: '#E5422F',
      warningHeavy: '#9B7E13',
    },
  },
};

module.exports = {
  content: [
    './public/*.html',
    './app/helpers/**/*.rb',
    './app/javascript/**/*.{js,vue,tsx,jsx,ts}',
    './app/javascript/**/*.css',
    './app/views/**/*.{erb,haml,html,slim}',
    './app/javascript/src/vue_components/**/*.{js,vue}',
  ],
  theme: {
    spacing: {
      1: '4px',
      2: '8px',
      3: '16px',
      4: '24px',
      5: '32px',
      6: '40px',
      7: '48px',
      8: '64px',
      9: '80px',
      10: '96px',
      11: '160px',
    },
    borderRadius: {
      none: '0',
      sm: '4px',
      DEFAULT: '8px',
      lg: '16px',
      full: '9999px',
    },
    extend: {
      fontSize: {
        h1: '40px',
        h2: '36px',
        h3: '32px',
        h4: '25px',
        h5: '22px',
        lead1: ['18px', {
          lineHeight: '27px',
          fontWeight: '700',
        }],
        lead2: ['16px', {
          lineHeight: '24px',
          fontWeight: '400',
        }],
        body: '14px',
        footnote1: '12px',
        footnote2: '11px',
        medium: ['14px', {
          lineHeight: '24px',
          fontWeight: '500',
        }]
      },
      colors: {
        ...baseColors,
        ...semanticColors,
        // Natural
        white: '#ffffff',
        primary: {
          100: '#1A1A1A',
          200: '#FFEBA3',
        },
        red: '#F03239',
        solid: {
          'background-1': '#FCFCFC',
          cards: '#FFFFFF',
        },
      },
      boxShadow: {
        longShadow: '-16px 16px 80px rgba(18, 2, 47, 0.1)',
        small: '0px 15px 20px rgba(124, 145, 176, 0.05)',
        medium: '0px 20px 40px rgba(124, 145, 176, 0.1)',
        large: '0px 20px 40px rgba(124, 145, 176, 0.3)',
      },
      maxWidth: {
        '8xl': '1410px',
      },
      fontFamily: {
        sans: ['"sofia-pro"', 'sans-serif'],
        serif: ['Vollkorn', 'serif'],
        Vollkorn: ['Vollkorn', 'Inter var', 'sans-serif'],
      },
    },
  },
  plugins: [require('@headlessui/tailwindcss')],
  corePlugins: {
    preflight: false,
  },
};
