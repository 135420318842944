import React from 'react';
import { composeClassName } from '@/utils/util_helpers';

const VotingCard = (props: { name: string; className?: string }) => {
  const { name, className } = props;

  return (
    <div
      className={composeClassName(
        'w-[80%] rounded-lg bg-white p-[2%] ',
        className && className
      )}
      style={{ boxShadow: '0px 59px 150px rgba(187, 196, 204, 0.25)' }}
    >
      <picture className="relative w-full">
        <source
          srcSet={`${require(`@assets/images/marketplace/voting-home/${name}.webp`)} 1x, ${require(`@assets/images/marketplace/voting-home/${name}@2x.webp`)} 2x, ${require(`@assets/images/marketplace/voting-home/${name}@3x.webp`)} 3x`}
          type="image/webp"
        />
        <img
          className="w-full"
          src={require(`@assets/images/marketplace/voting-home/${name}.png`)}
          alt="thumb-up"
        />
      </picture>
    </div>
  );
};

const Glass = () => {
  return (
    <div
      className="absolute bottom-[0px] right-[18%] z-30 aspect-square w-[39%] rounded-full"
      style={{
        background: 'rgba(255, 255, 255, 0.2)',
        boxShadow:
          '12.8px 57.6px 83.2px rgba(98, 47, 0, 0.14), inset 0px 1.6px 3.2px rgba(0, 0, 0, 0.04), inset -1.6px -6.4px 6.4px rgba(0, 0, 0, 0.06), inset 1.6px 6.4px 6.4px #FFFFFF',
        backdropFilter: 'blur(16px)',
      }}
    />
  );
};

const ThumbUp = () => {
  return (
    <picture className="absolute left-[-35%] right-[0px] bottom-[-7%] z-20 m-auto h-auto w-[80%]">
      <source
        srcSet={`${require(`@assets/images/marketplace/voting-home/thumbs-up.webp`)} 1x, ${require(`@assets/images/marketplace/voting-home/thumbs-up@2x.webp`)} 2x, ${require(`@assets/images/marketplace/voting-home/thumbs-up@3x.webp`)} 3x`}
        type="image/webp"
      />
      <img
        className="w-full"
        src={require(`@assets/images/marketplace/voting-home/thumbs-up.png`)}
        alt="thumb-up"
      />
    </picture>
  );
};

const VotingCards = () => {
  return (
    <div
      className={composeClassName(
        'mt-7 flex aspect-square w-full max-w-[580px] justify-center self-center',
        'sm:mt-9',
        'lg:mt-[0px] lg:w-[47%]'
      )}
    >
      <div
        className={composeClassName(
          'relative mt-[-5%] flex h-full w-full flex-row justify-end'
        )}
      >
        <VotingCard name="nurul" className="relative mt-[10%] self-start" />
        <VotingCard
          name="josias"
          className="absolute left-[0px] top-[4%] z-10 rotate-[-5deg]"
        />
        <Glass />
        <ThumbUp />
      </div>
    </div>
  );
};

export { VotingCards };
