import React from 'react';
import { ReactSVG } from 'react-svg';
import { composeClassName } from '@/utils/util_helpers';

const AnimationBlob = () => {
  return (
    <ReactSVG
      className={composeClassName(
        'absolute left-[50%] top-[50%] h-auto w-[87.5%] translate-x-[-50%] translate-y-[-50%] transform-gpu',
        'lg:w-[50rem]',
        '[@media(min-width:1200px)]:w-[62.5rem]',
        '2xl:!w-[75rem]'
      )}
      src={require(`@assets/animations/svg/blob.svg`)}
    />
  );
};

export { AnimationBlob };
