import bootbox from 'bootbox';
import Rails from '@rails/ujs';

Rails.confirm = (message, element) => {
  bootbox.confirm({
    title: false,
    // message: `<div class="headline">${message}</div>`,
    message,
    // className: "confirmation-modal",
    centerVertical: true,
    buttons: {
      cancel: {
        label: element.dataset.bootboxCancelMessage || 'Cancel',
        className: 'btn btn-secondary',
      },
      confirm: {
        label: element.dataset.bootboxConfirmMessage || 'Confirm',
        className: 'btn btn-primary',
      },
    },
    callback(result) {
      if (result) {
        const dataConfirm = delete element.dataset.confirm;

        element.click();

        setTimeout(() => {
          element.dataset.confirm = dataConfirm;

          window.Rails.fire(element, 'confirm:confirmed');
        });
      }
    },
  });
};
