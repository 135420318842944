import { Controller } from 'stimulus';

export default class extends Controller {
  validate(event) {
    const form = event.target;

    if (!form.checkValidity()) {
      const invalidFields = form.querySelectorAll(':invalid');

      this.displayInvalidFeedback(invalidFields);

      event.preventDefault();
      event.stopPropagation();
    }

    form.classList.add('was-validated');
  }

  displayInvalidFeedback(invalidFields) {
    invalidFields.forEach((invalidField) => {
      const invalidFeedback = this.findOrCreateInvalidFeedback(invalidField);
      invalidFeedback.innerHTML = invalidField.validationMessage;
    });
  }

  findOrCreateInvalidFeedback(invalidField) {
    let invalidFeedback = this.findInvalidFeedback(invalidField);

    if (invalidFeedback == null) {
      invalidFeedback = this.createInvalidFeedback(invalidField);
    }

    return invalidFeedback;
  }

  findInvalidFeedback(invalidField) {
    return invalidField.parentElement.querySelector('.invalid-feedback');
  }

  createInvalidFeedback(invalidField) {
    const invalidFeedback = document.createElement('div');
    invalidFeedback.className = 'invalid-feedback';

    this.insertInvalidFeedback(invalidFeedback, invalidField);

    return invalidFeedback;
  }

  insertInvalidFeedback(invalidFeedback, invalidField) {
    const invalidFieldParent = invalidField.parentElement;

    if (
      invalidFieldParent.classList.contains('input-group') ||
      invalidFieldParent.classList.contains('form-check')
    ) {
      invalidFieldParent.insertBefore(invalidFeedback, null);
    } else {
      const invalidFieldSibling = invalidField.nextSibling;
      invalidFieldParent.insertBefore(invalidFeedback, invalidFieldSibling);
    }
  }
}
