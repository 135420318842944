import React from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useGetTransactionsListContext } from '@/features/admin/transactions/components/incoming-transactions-list/hooks/use-get-transactions-list';

const TransactionUpdateInfo = () => {
  const { t } = useTranslation();
  const {
    fetch: { data },
  } = useGetTransactionsListContext()!;

  if (data?.data.summary) {
    return (
      <div className="w-full pt-4 text-body text-text-secondary">
        {t('js.features.admin.transactions.transaction_update_info.title', {
          last_update_time: format(
            new Date(data.data.summary.last_update),
            'd MMM yyyy, HH:mm'
          ),
        })}
      </div>
    );
  }

  return <></>;
};

export { TransactionUpdateInfo };
