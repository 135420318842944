/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { composeClassName } from '@/utils/util_helpers';
import { IconSaxIcon } from '../icon';
import { ViewToggleProps } from './types';
import { theme } from '@/utils/util_theme';
import { ViewToggleResultsIndicator } from './components/view-toggle-results-indicator';
import { ViewToggleResultsIndicatorProps } from './components/view-toggle-results-indicator/types';

const defaultButtonStyling =
  'pointer border-none bg-E30 outline-none m-[0px] p-[0px] w-6 h-6 flex items-center justify-center';

const ViewToggle: React.FC<ViewToggleProps> & {
  ResultsIndicator: React.FC<ViewToggleResultsIndicatorProps>;
} = ({ onChange, activeView, className }) => {
  const cardActive = useMemo(() => {
    return activeView === 'card';
  }, [activeView]);

  return (
    <div
      className={composeClassName('flex outline-none', className && className)}
    >
      <button
        className={composeClassName(
          defaultButtonStyling,
          'rounded-l-sm',
          cardActive && '!bg-R300'
        )}
        type="button"
        onClick={() => onChange('card')}
      >
        <IconSaxIcon
          strokeColor={
            cardActive
              ? theme?.colors.text.primary
              : theme?.colors.text.tertiary
          }
          strokeWidth={1.5}
          name="element-3"
          size={18}
        />
      </button>
      <button
        className={composeClassName(
          defaultButtonStyling,
          'rounded-r-sm',
          !cardActive && '!bg-R300'
        )}
        type="button"
        onClick={() => onChange('list')}
      >
        <IconSaxIcon
          strokeColor={
            cardActive
              ? theme?.colors.text.tertiary
              : theme?.colors.text.primary
          }
          strokeWidth={1.5}
          name="menu"
          size={18}
        />
      </button>
    </div>
  );
};

ViewToggle.ResultsIndicator = ViewToggleResultsIndicator;

export { ViewToggle };
