import { Tab } from '@headlessui/react';
import React from 'react';
import { isElement } from 'react-dom/test-utils';
import { composeClassName } from '@/utils/util_helpers';
import { TabScreenProps } from './types';

const TabScreen: React.FC<TabScreenProps> = ({
  children,
  className,
  static: internalStatic = false,
  unmount = true,
}) => {
  return (
    // @ts-expect-error
    <Tab.Panel
      className={composeClassName('h-full w-full', className && className)}
      {...(internalStatic && {
        static: internalStatic,
      })}
      unmount={unmount}
    >
      {isElement(children)
        ? ({ selected }) => {
            return React.Children.map(
              children as unknown as React.ReactElement,
              (child: React.ReactElement) => {
                return {
                  ...child,
                  props: {
                    ...child.props,
                    selected,
                  },
                };
              }
            ) as unknown as React.ReactElement;
          }
        : children}
    </Tab.Panel>
  );
};

export { TabScreen };
