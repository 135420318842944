import React from 'react';
import { composeClassName } from '@/utils/util_helpers';
import { useThirdSectionContext } from '../../hooks/use-third-section-context';
import { ColocIcon } from '@/components/design-system/atoms/icon';
import { useWindowDimensions } from '@/hooks/use-window-dimensions';

const ExtraIcon: React.FC<{ order: 'first' | 'second' }> = ({ order }) => {
  const { iconOptionsDec, currentOption } = useThirdSectionContext()!;
  const { screenIsLargeOrEqualThan } = useWindowDimensions();

  const iconName = iconOptionsDec[currentOption][order];

  return (
    <div
      className={composeClassName(
        'flex h-[48px] w-[48px] items-center justify-center rounded-full bg-white shadow-large',
        'sm:h-[56px] sm:w-[56px]'
      )}
    >
      <ColocIcon
        name={iconName}
        size={screenIsLargeOrEqualThan('sm') ? 40 : 32}
      />
    </div>
  );
};

const InfoImage = ({ currentOption }) => {
  const { screenIsSmallerThan } = useWindowDimensions()!;

  return (
    <div
      className={composeClassName(
        'relative self-end rounded-bl-[110px] border-[6px] border-solid border-Q50',
        screenIsSmallerThan('lg') &&
          'w-[calc(100%_-_48px)] sm:ml-[0px] sm:w-[80%]',
        'lg:aspect-[1.2/1] lg:h-[400px]',
        'xl:h-[450px]',
        '2xl:h-[500px]'
      )}
    >
      <div
        className={composeClassName(
          'relative z-10 w-full',
          'sm:w-[72%]',
          screenIsSmallerThan('lg') && 'aspect-square',
          'lg:h-full lg:w-full'
        )}
      >
        <picture className={composeClassName('relative z-10 h-full w-full')}>
          <source
            srcSet={`${require(`@assets/images/marketplace/home-options/${currentOption}.webp`)} 1x, ${require(`@assets/images/marketplace/home-options/${currentOption}@2x.webp`)} 2x, ${require(`@assets/images/marketplace/home-options/${currentOption}@3x.webp`)} 3x`}
            type="image/webp"
          />
          <img
            className={composeClassName(
              'h-full w-full rounded-bl-[110px] object-cover pl-[12px] pb-[12px] pt-4',
              'sm:p-4'
            )}
            src={require(`@assets/images/marketplace/home-options/${currentOption}.jpg`)}
            alt={currentOption}
          />
        </picture>
      </div>
      {/* Extra icons */}
      <div className="absolute top-[33%] left-[-30px] z-20 flex flex-col gap-5">
        <ExtraIcon order="first" />
        <ExtraIcon order="second" />
      </div>
      {/* yellow square behind image */}
      <div
        className={composeClassName(
          'absolute right-[0px] top-[0px] z-0 aspect-[22/20] w-[60%] max-w-[44.5rem] bg-R300'
        )}
      />
    </div>
  );
};

export { InfoImage };
