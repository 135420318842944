import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import { differenceInDays } from 'date-fns';
import './styles.css';
import { ReactSVG } from 'react-svg';

import { RoomUrgencyStatusTableCellProps } from './types';
import { theme } from '@/utils/util_theme';
import { composeClassName } from '@/utils/util_helpers';

const RoomUrgencyStatusTableCellContent: React.FC<RoomUrgencyStatusTableCellProps> =
  ({ status, reason, className, reason_date }) => {
    const { t } = useTranslation();

    const urgencyLabelTextColor: string = useMemo(() => {
      switch (status) {
        case 'very_high':
          return 'text-text-dangerHeavy';
        case 'high':
          return 'text-text-dangerHeavy';
        case 'medium':
          return 'text-text-warningHeavy';
        default:
          return 'text-text-primaryMedium';
      }
    }, [status]);

    const daysToReasonDate: number = useMemo(() => {
      const today = new Date();
      const timeDiff = differenceInDays(
        reason_date ? new Date(reason_date) : today,
        today
      );
      return Math.ceil(timeDiff);
    }, [reason_date]);

    const urgencyReasonText: string = useMemo(() => {
      switch (reason) {
        case 'available_now':
        case 'available_soon':
          return t(
            `js.features.admin.contracts.room_assignment.table_cells.room_urgency.${reason}_reason`,
            { num_days: Math.abs(daysToReasonDate) }
          );
        default:
          return t(
            `js.features.admin.contracts.room_assignment.table_cells.room_urgency.${reason}_reason`
          );
      }
    }, [reason, t, daysToReasonDate]);

    const iconFillColor = useMemo(() => {
      switch (status) {
        case 'very_high':
          return theme?.colors?.fill?.icon?.dangerHeavy;
        case 'high':
          return theme?.colors?.fill?.icon?.dangerHeavy;
        case 'medium':
          return theme?.colors?.fill?.icon?.warningHeavy;
        default:
          return theme?.colors?.fill?.icon?.primaryMedium;
      }
    }, [status]);

    const style = {
      '--icon-color-urgency-status': iconFillColor,
    } as React.CSSProperties;

    return (
      <div
        className={composeClassName(
          'flex items-center',
          className && className
        )}
      >
        {status !== null ? (
          <>
            <ReactSVG
              src={require(`@assets/icons/other/error-ic.svg`)}
              className={composeClassName(
                'inline-block align-baseline',
                'icon-urgency-fill'
              )}
              style={style}
              beforeInjection={(svg) => {
                svg.setAttribute('width', `30`);
                svg.setAttribute('height', `30`);
              }}
            />
            <div className="pl-2">
              <span
                className={composeClassName(
                  'text-body font-medium',
                  urgencyLabelTextColor
                )}
              >
                {t(
                  `js.features.admin.contracts.room_assignment.table_cells.room_urgency.${status}_status`
                )}
              </span>
              {reason && (
                <span
                  className={composeClassName(
                    'line-clamp-1 overflow-hidden break-all text-body text-text-primaryLight'
                  )}
                  style={{ whiteSpace: 'initial' }}
                >
                  {`${urgencyReasonText}${
                    status === 'high' || status === 'very_high' ? '!' : ''
                  }`}
                </span>
              )}
            </div>
          </>
        ) : (
          '--'
        )}
      </div>
    );
  };

export { RoomUrgencyStatusTableCellContent };
