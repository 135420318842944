/* eslint-disable react/prop-types */
import React, { Children, ReactElement, isValidElement, useMemo } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { ReactSVG } from 'react-svg';
import { PopUpProps, PopUpSlots } from './types';
import { composeClassName } from '@/utils/util_helpers';
import { Button } from '../../atoms/button';
import { ColocIcon } from '../../atoms/icon';
import { useWindowDimensions } from '@/hooks/use-window-dimensions';

const PopUpInput: React.FC<{ children: ReactElement }> = ({ children }) => {
  return <div className="pb-5">{children}</div>;
};

const PopUpIcon: React.FC<Pick<PopUpProps, 'type' | 'iconName'>> = ({
  type = 'default',
  iconName,
}) => {
  if (type === 'default') {
    if (iconName) {
      return <ColocIcon size={64} name={iconName} className="pb-4" />;
    }

    return <></>;
  }

  if (type === 'error') {
    return (
      <div className="flex items-center justify-center">
        <ReactSVG
          className="inline-block align-baseline"
          src={require('@assets/icons/error-pop-up-icon.svg')}
          beforeInjection={(svg) => {
            svg.setAttribute('width', '96');
            svg.setAttribute('height', '96');
          }}
        />
      </div>
    );
  }

  if (type === 'warning') {
    return (
      <div className="flex items-center justify-center">
        <ReactSVG
          className="inline-block align-baseline"
          src={require('@assets/icons/warning-pop-up-icon.svg')}
          beforeInjection={(svg) => {
            svg.setAttribute('width', '96');
            svg.setAttribute('height', '96');
          }}
        />
      </div>
    );
  }

  return (
    <Player
      autoplay
      keepLastFrame
      src={require(`@assets/lottie/confirmation.json`)}
      style={{ height: '128px', width: '128px' }}
    />
  );
};

const PopUp: React.FC<PopUpProps> & {
  PopUpInput: React.FC<{ children: ReactElement }>;
} = ({
  className,
  type = 'default',
  description,
  primaryButtonProps,
  secondaryButtonProps,
  children,
  iconName,
  buttonOrder = 'primary-first',
  title,
}) => {
  const { screenIsLargeOrEqualThan } = useWindowDimensions();
  const isDefault = useMemo(() => {
    return type === 'default';
  }, [type]);
  const isError = useMemo(() => {
    return type === 'error';
  }, [type]);
  const isWarning = useMemo(() => {
    return type === 'warning';
  }, [type]);

  const filledSlots = useMemo(() => {
    const slots: PopUpSlots = {
      input: null,
    };

    Children.toArray(children).forEach((child) => {
      if (!isValidElement(child)) {
        return;
      }

      switch (child.type) {
        case PopUpInput:
          slots.input = child;
          break;
        default:
      }
    });

    return slots;
  }, [children]);

  return (
    <div
      className={composeClassName(
        'rounded-lg bg-white p-5 shadow-lg',
        screenIsLargeOrEqualThan('lg') ? 'w-[450px]' : 'w-[80%]',
        className && className
      )}
    >
      <PopUpIcon iconName={iconName} type={type} />
      <div
        className={composeClassName(
          'flex w-full flex-col gap-2',
          isDefault
            ? 'justify-start pb-4 text-left'
            : 'justify-center pb-5 pt-3 text-center'
        )}
      >
        <span className="text-lead2 font-semibold text-text-primary">
          {title}
        </span>
        {description && (
          <span className="text-body text-text-primary">{description}</span>
        )}
      </div>
      {filledSlots.input}
      <div
        className={composeClassName(
          'flex gap-4',
          isDefault ? 'justify-start' : 'justify-center',
          buttonOrder === 'secondary-first'
            ? 'flex-row-reverse !justify-end'
            : 'flex-row'
        )}
      >
        <Button {...primaryButtonProps} size="medium" variant={isWarning ? 'warning' : 'primary'} />
        {(isDefault || isError || isWarning) && secondaryButtonProps && (
          <Button {...secondaryButtonProps} size="medium" variant="ghost" />
        )}
      </div>
    </div>
  );
};

PopUp.PopUpInput = PopUpInput;

export { PopUp };
