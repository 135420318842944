import isArray from 'lodash-es/isArray';
import { useMemo } from 'react';
import { useDropDownContext } from './use-dropdown-context';

export const useDropDownHasValue = (): boolean => {
  const { value } = useDropDownContext()!;

  return useMemo(() => {
    if (isArray(value)) {
      return value.length > 0;
    }

    if (value) {
      return true;
    }

    return false;
  }, [value]);
};
