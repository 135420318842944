/* eslint-disable react/prop-types */
import { Dialog, Transition } from '@headlessui/react';
import React, {
  useState,
  Fragment,
  Children,
  useMemo,
  isValidElement,
} from 'react';
import { IconSaxIcon } from '@/components/design-system/atoms/icon';
import { MobileSideMenuPropsAndSubComponents, Slots } from './types';
import { theme } from '@/utils/util_theme';
import { composeClassName } from '@/utils/util_helpers';
import { MobileSideMenuOptions } from './components/mobile-side-menu-options';
import { MobileSideMenuOption } from './components/mobile-side-menu-option';

const Leading: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className={composeClassName('flex w-full flex-col')}>{children}</div>
  );
};

const Trailing: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className={composeClassName('flex w-full flex-col')}>{children}</div>
  );
};

const MobileSideMenu: MobileSideMenuPropsAndSubComponents = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const filledSlots = useMemo(() => {
    const slots: Slots = {
      leading: null,
      trailing: null,
    };

    Children.toArray(children).forEach((child) => {
      if (!isValidElement(child)) {
        return;
      }

      switch (child.type) {
        case Leading:
          slots.leading = child;
          break;
        case Trailing:
          slots.trailing = child;
          break;
        default:
      }
    });

    return slots;
  }, [children]);

  return (
    <div>
      <button
        type="button"
        className="flex items-center justify-center transition"
        aria-label="Toggle navigation"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <IconSaxIcon name={isOpen ? 'close' : 'menu'} />
      </button>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          onClose={() => setIsOpen(false)}
          // style={{ height: '100dvh' }}
          className="fixed top-[0px] z-40 h-[100dvh] w-full"
        >
          {/* The backdrop */}
          <Transition.Child
            as={Fragment}
            enter="transform transition duration-300 ease-out"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="duration-200 ease-in"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="inset-0 fixed h-[100dvh] w-full bg-black opacity-5" />
          </Transition.Child>

          <Dialog.Panel className="absolute z-40 w-full">
            <Transition.Child
              as={Fragment}
              enter="transform transition duration-500 ease-in-out"
              enterFrom="translate-x-full opacity-0"
              enterTo="translate-x-270 opacity-100"
              leave="transform transition duration-500 ease-in-out"
              leaveFrom="translate-x-270 opacity-100"
              leaveTo="translate-x-full opacity-0"
            >
              <div className="absolute right-[0px] top-[0px] flex h-[100dvh] w-[80%] max-w-[450px] flex-col bg-white py-4 px-[2rem]">
                <div className="h-[10.4rem]">
                  <button type="button" onClick={() => setIsOpen(false)}>
                    <IconSaxIcon
                      name="close"
                      strokeColor={theme?.colors.text.tertiary}
                    />
                  </button>
                </div>
                <div className="flex w-full flex-1 flex-col justify-between">
                  {filledSlots.leading}
                  {filledSlots.trailing}
                </div>
              </div>
            </Transition.Child>
          </Dialog.Panel>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

MobileSideMenu.Leading = Leading;
MobileSideMenu.Trailing = Trailing;
MobileSideMenu.MobileSideMenuOptions = MobileSideMenuOptions;
MobileSideMenu.MobileSideMenuOption = MobileSideMenuOption;

export { MobileSideMenu };
