import { AxiosRequestConfig } from 'axios';
import { COLOC_APP_API_HOST } from '@/config';
import { getColocApiCallProperties } from '..';

const getColocAdminApiCallProperties = (): AxiosRequestConfig => {
  return {
    ...getColocApiCallProperties(),
    baseURL: `${COLOC_APP_API_HOST}/api/v1/admin`,
  };
};

export { getColocAdminApiCallProperties };
