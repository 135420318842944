import { QueryKey, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { AxiosResponse } from 'axios';
import { getDiscussionThreadById } from '@/services/coloc-api/admin';
import { DiscussionThreadMessage } from '../../types';
import { GetDiscussionThreadResponse } from '@/lib/coloc-api/admin';

const useGetDiscussionThread = (params: { id: number | undefined }) => {
  const queryClient = useQueryClient();

  const queryKey: QueryKey = useMemo(
    () => ['getDiscussionThread', params],
    [params]
  );

  const fetch = useQuery({
    queryKey,
    queryFn: () =>
      getDiscussionThreadById({
        // @ts-ignore
        id: params.id,
      }),
    keepPreviousData: true,
    enabled: !!params.id,
  });

  const addDiscussionThreadMessage = useCallback(
    (message: DiscussionThreadMessage) => {
      queryClient.setQueryData(
        queryKey,
        (
          oldData: AxiosResponse<GetDiscussionThreadResponse, any> | undefined
        ) => {
          if (oldData?.data) {
            const newData = {
              ...oldData,
              data: {
                ...oldData.data,
                messages: [...oldData.data.messages, message],
              },
            };
            return newData;
          }

          return oldData;
        }
      );
    },
    [queryClient, queryKey]
  );

  const deleteDiscussionThreadMessageInCache = useCallback(
    (messageId: number) => {
      queryClient.setQueryData(
        queryKey,
        (
          oldData: AxiosResponse<GetDiscussionThreadResponse, any> | undefined
        ) => {
          if (oldData?.data) {
            const newMessages = oldData.data.messages.filter(
              (message) => message.id !== messageId
            );
            const newData = {
              ...oldData,
              data: {
                ...oldData.data,
                messages: newMessages,
              },
            };

            return newData;
          }

          return oldData;
        }
      );
    },
    [queryClient, queryKey]
  );

  return {
    fetch,
    addDiscussionThreadMessage,
    deleteDiscussionThreadMessageInCache,
  };
};

export { useGetDiscussionThread };
