import React, { createContext, useContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getSearchRoomAutoCompleteResults } from '@/services/coloc-api/marketplace';
import { useDebounce } from '@/hooks/use-debounce';

const useSearchRoom = () => {
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const debouncedSearchQuery: string = useDebounce(searchQuery ?? '', 500);

  const fetchSearchRoomResults = useQuery({
    queryKey: ['getSearchRoomAutoCompleteResults', debouncedSearchQuery],
    queryFn: () =>
      getSearchRoomAutoCompleteResults({
        ...(debouncedSearchQuery &&
          debouncedSearchQuery !== '' && { q: debouncedSearchQuery }),
      }),
    enabled: !!debouncedSearchQuery,
  });

  return {
    fetchSearchRoomResults,
    searchQuery,
    setSearchQuery,
  };
};

const SearchRoomContext = createContext<
  ReturnType<typeof useSearchRoom> | undefined
>(undefined);

const SearchRoomContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const hook = useSearchRoom();

  return (
    <SearchRoomContext.Provider value={hook}>
      {children}
    </SearchRoomContext.Provider>
  );
};

const useSearchRoomContext = () => {
  const context = useContext(SearchRoomContext);

  if (context === null) {
    throw new Error(
      'useSearchRoomContext must be used within a SearchRoomContextProvider'
    );
  }

  return context;
};

export { useSearchRoomContext, SearchRoomContextProvider };
