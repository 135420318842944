import { AxiosRequestConfig } from 'axios';
import { axiosLib } from '@/lib/axios';
import { getColocApiCallProperties } from '..';

import { COLOC_APP_API_HOST } from '@/config';
import { User } from '@/types/auth';

const getColocAuthApiCallProperties = (): AxiosRequestConfig => {
  return {
    ...getColocApiCallProperties(),
    baseURL: `${COLOC_APP_API_HOST}/api/v1/auth`,
  };
};

export type getUserResultResponse = {
  data: {
    attributes: User;
  };
};

export const getUser = () => {
  return axiosLib.get<getUserResultResponse>(`/user`, {
    ...getColocAuthApiCallProperties(),
  });
};

export const signOutUser = () => {
  const formData = new FormData();
  formData.append('_method', 'delete');

  return axiosLib.post(`/users/sign_out`, {
    ...getColocApiCallProperties(),
    baseURL: `${COLOC_APP_API_HOST}`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
