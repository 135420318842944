import { Menu, Transition } from '@headlessui/react';
import React, { Children, isValidElement, useMemo } from 'react';
import { composeClassName } from '@/utils/util_helpers';
import { DropdownMenuOptionsProps, Slots } from './types';
import { DropdownMenuOption } from '../dropdown-menu-option';

const DropdownMenuOptions: React.FC<DropdownMenuOptionsProps> = ({
  children,
  className,
}) => {
  const filledSlots = useMemo(() => {
    const slots: Slots = {
      options: [],
      destructiveOptions: [],
    };

    Children.toArray(children).forEach((child) => {
      if (!isValidElement(child)) {
        return;
      }

      switch (child.type) {
        case DropdownMenuOption:
          if (child.props.destructive) {
            slots.destructiveOptions = [...slots.destructiveOptions, child];
          } else {
            slots.options = [...slots.options, child];
          }
          break;
        default:
      }
    });

    return slots;
  }, [children]);

  return (
    <Transition
      as={React.Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items
        className={composeClassName(
          'absolute top-[5rem] z-10 rounded bg-white shadow-medium',
          className && className
        )}
      >
        <div className="flex flex-col gap-2 p-[16px]">
          {filledSlots.options}
          {/* separation line between normal and destructive options */}
          {filledSlots.destructiveOptions.length >= 1 &&
            filledSlots.options.length >= 1 && (
              <div className="h-[1px] w-full bg-E50" />
            )}
          {filledSlots.destructiveOptions}
        </div>
      </Menu.Items>
    </Transition>
  );
};

export { DropdownMenuOptions };
