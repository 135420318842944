import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { QueryKey, useQuery } from '@tanstack/react-query';

import { AxiosResponse } from 'axios';
import {
  roomAssignmentsListSortableIds,
  RoomAssignmentListSortType,
  GetRoomAssignmentsListResponse,
} from '@/lib/coloc-api/admin';
import { getRoomAssignmentList } from '@/services/coloc-api/admin';
import { useDebounce } from '@/hooks/use-debounce';
import { useColocApi } from '@/hooks/use-coloc-api';
import { useRoomAssignmentListFiltersContext } from '../use-get-room-assignment-list-filter';
import {
  RoomApplicant,
  RoomAssignment,
} from '@/features/admin/contracts/types';
import { queryClient } from '@/lib/react-query';

const useGetRoomsAssignmentList = () => {
  const {
    searchQuery,
    endDate,
    startDate,
    selectedAssignmentStatus,
    setColumnToSort,
    sortDirection,
    selectedColumnToSort,
    selectedHouse,
    selectedCity,
    selectedPropertyManager,
    rentalType,
  } = useRoomAssignmentListFiltersContext()!;
  const {
    setPage,
    setPageLimit,
    page,
    pageLimit,
    resultSummary,
    setPaginationResponse,
  } = useColocApi<RoomAssignmentListSortType>()!;
  const debouncedSearchQuery: string = useDebounce(searchQuery ?? '', 500);
  const sortableColumns = roomAssignmentsListSortableIds;
  const [showApplicants, setShowApplicants] = React.useState<{
    show: boolean;
    assignment: RoomAssignment | undefined;
  }>({
    show: false,
    assignment: undefined,
  });
  const [showUpdateRoomAvailability, setShowUpdateRoomAvailability] =
    React.useState<{
      show: boolean;
      assignment: RoomAssignment | undefined;
    }>({ show: false, assignment: undefined });

  const [showUpdateMoveIn, setShowUpdateMoveIn] = React.useState<{
    show: boolean;
    status: string | undefined;
    applicant: RoomApplicant | undefined;
  }>({ show: false, applicant: undefined, status: undefined });

  const queryKey: QueryKey = useMemo(
    () => [
      'getRoomAssignmentList',
      selectedAssignmentStatus,
      startDate,
      endDate,
      debouncedSearchQuery,
      searchQuery,
      pageLimit,
      page,
      selectedColumnToSort,
      sortDirection,
      selectedHouse,
      selectedCity,
      selectedPropertyManager,
      rentalType,
    ],
    [
      debouncedSearchQuery,
      endDate,
      page,
      pageLimit,
      searchQuery,
      selectedColumnToSort,
      selectedAssignmentStatus,
      sortDirection,
      startDate,
      selectedHouse,
      selectedCity,
      selectedPropertyManager,
      rentalType,
    ]
  );

  const fetch = useQuery({
    queryKey,
    queryFn: () =>
      getRoomAssignmentList({
        ...(startDate && { start_date: startDate }),
        ...(endDate && { end_date: endDate }),
        ...(pageLimit && { page_limit: pageLimit }),
        ...(page && { page }),
        ...(debouncedSearchQuery &&
          debouncedSearchQuery !== '' && { query: debouncedSearchQuery }),
        ...(sortDirection && { sort_direction: sortDirection }),
        ...(selectedColumnToSort && { sort: selectedColumnToSort }),
        ...(selectedAssignmentStatus && {
          status: selectedAssignmentStatus.status,
        }),
        ...(selectedHouse && { house_id: selectedHouse.id }),
        ...(selectedCity && { city_id: selectedCity.id }),
        ...(selectedPropertyManager && {
          property_manager_id: selectedPropertyManager.id,
        }),
        ...(rentalType && { rental_type: rentalType.slug }),
      }),
    keepPreviousData: true,
  });

  useEffect(() => {
    setPaginationResponse(
      fetch.data?.data.pagination
        ? { pagination: fetch.data?.data.pagination }
        : undefined
    );
  }, [fetch.data?.data.pagination, setPaginationResponse]);

  const updateAssignmentInCache = useCallback(
    (assignment: RoomAssignment) => {
      queryClient.setQueryData(
        queryKey,
        (
          oldData:
            | AxiosResponse<GetRoomAssignmentsListResponse, any>
            | undefined
        ) => {
          if (oldData?.data) {
            const oldDataCopy = { ...oldData };
            const newData = oldDataCopy.data.data.map((mappedAssignment) => {
              if (mappedAssignment.id === assignment.id) {
                return assignment;
              }

              return mappedAssignment;
            });
            oldDataCopy.data.data = newData;

            return oldDataCopy;
          }
          return oldData;
        }
      );
    },
    [queryKey]
  );

  const updateApplicantWithContractId = useCallback(
    ({ contract_id, params }: { contract_id: number; params: object }) => {
      if (!showApplicants.assignment) {
        return;
      }

      const applications = showApplicants.assignment.applications.map(
        (mappedApp) => {
          if (mappedApp.contract.id === contract_id) {
            return {
              ...mappedApp,
              contract: {
                ...mappedApp.contract,
                ...params,
              },
            };
          }
          return mappedApp;
        });

      setShowApplicants({
        ...showApplicants,
        assignment: {
          ...showApplicants.assignment,
          applications,
        }
      });
    },
    [showApplicants]
  );

  return {
    pageLimit,
    setPageLimit,
    fetch,
    startDate,
    endDate,
    searchQuery,
    setPage,
    sortableColumns,
    selectedColumnToSort,
    setColumnToSort,
    sortDirection,
    resultSummary,
    setShowApplicants,
    showApplicants,
    showUpdateRoomAvailability,
    setShowUpdateRoomAvailability,
    updateAssignmentInCache,
    showUpdateMoveIn,
    setShowUpdateMoveIn,
    updateApplicantWithContractId,
  };
};

const GetRoomsAssignmentListContext = createContext<
  ReturnType<typeof useGetRoomsAssignmentList> | undefined
>(undefined);

const GetRoomsAssignmentListContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const hook = useGetRoomsAssignmentList();

  return (
    <GetRoomsAssignmentListContext.Provider value={hook}>
      {children}
    </GetRoomsAssignmentListContext.Provider>
  );
};

const useGetRoomsAssignmentContext = () => {
  const context = useContext(GetRoomsAssignmentListContext)!;

  if (context === null) {
    throw new Error(
      'useGetRoomsAssignmentContext must be used within a GetRoomsAssignmentListContextProvider'
    );
  }

  return context;
};

export { GetRoomsAssignmentListContextProvider, useGetRoomsAssignmentContext };
