<template>
  <section :style="{ backgroundImage: `url(${bgImage})` }" class="w-full">
    <div class="m-auto w-full max-w-8xl space-y-5 px-4 py-7 lg:py-9">
      <div class="flex flex-col items-center lg:items-start">
        <h1
          class="
            font-Vollkorn
            text-[28px]
            font-bold
            text-primary-100
            lg:text-5xl
          "
        >
          {{ t('vue_components.spot_a_house.we_make_it_easy_for_you') }}
        </h1>
        <p
          class="
            max-width-[400px]
            text-center text-base
            font-normal
            text-text-secondary
            lg:text-[22px]
            lg:font-medium
          "
        >
          {{ t('vue_components.spot_a_house.you_can_easily_track') }}
        </p>
      </div>

      <div class="grid w-full grid-cols-1 lg:grid-cols-3">
        <div
          class="
            lg:space-y-0
            col-span-1
            flex
            w-full
            flex-col
            items-center
            space-y-5
            lg:col-span-2
            lg:flex-row
            lg:space-x-5
          "
        >
          <div
            class="
              relative
              flex
              w-full
              max-w-[400px]
              flex-col
              items-center
              lg:flex-row
              lg:-space-x-10
              lg:space-y-7
            "
          >
            <div
              class="
                card-shadow
                w-full
                max-w-[267px]
                self-start
                overflow-hidden
                rounded-lg
                lg:self-center
              "
            >
              <div class="h-[158px] w-full bg-gray-400">
                <img
                  src="~assets/images/spot_a_house/house2.png"
                  alt="Patersstraat 39 house #1"
                  class="h-full w-full"
                />
              </div>
              <div class="w-full space-y-4 bg-white p-5">
                <div class="flex flex-col items-start space-y-1">
                  <h3
                    class="
                      font-Vollkorn
                      text-2xl
                      font-semibold
                      text-primary-100
                    "
                  >
                    Audent 30
                  </h3>
                  <span class="text-base font-medium text-primary-100"
                    >HOUSE #2</span
                  >
                </div>

                <div class="flex w-full items-center space-x-2">
                  <div
                    class="
                      flex
                      rounded-full
                      border border-[#3C6895]
                      bg-[#ECF0F4]
                      py-2
                      px-4
                      text-sm
                      font-medium
                      text-[#3C6895]
                    "
                  >
                    {{ t('vue_components.spot_a_house.prescreening') }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="
                card-shadow
                lg:mt-0
                -mt-[220px]
                w-full
                max-w-[267px]
                self-end
                overflow-hidden
                rounded-lg
                lg:self-center
              "
            >
              <div class="h-[158px] w-full bg-gray-400">
                <img
                  src="~assets/images/spot_a_house/house1.png"
                  alt="Patersstraat 39 house #1"
                  class="h-full w-full"
                />
              </div>
              <div class="w-full space-y-4 bg-white p-5">
                <div class="flex flex-col items-start space-y-1">
                  <h3
                    class="
                      font-Vollkorn
                      text-2xl
                      font-semibold
                      text-primary-100
                    "
                  >
                    Patersstraat 39
                  </h3>
                  <span class="text-base font-medium text-primary-100"
                    >HOUSE #1</span
                  >
                </div>

                <div class="flex w-full items-center space-x-2">
                  <div
                    class="
                      flex
                      rounded-full
                      border border-[#1B998B]
                      bg-[#E8F5F3]
                      py-2
                      px-4
                      text-sm
                      font-medium
                      text-[#1B998B]
                    "
                  >
                    {{ t('vue_components.spot_a_house.completed') }}
                  </div>
                  <div class="flex flex-col items-start space-y-1">
                    <span class="text-xs font-medium text-primary-100">{{
                      t('vue_components.spot_a_house.received')
                    }}</span>
                    <span class="text-trans font-Vollkorn text-2xl font-bold"
                      >€1000
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="
                absolute
                -right-1
                -bottom-6
                lg:-right-[130px]
                lg:bottom-3
                xl:-right-[140px]
              "
            >
              <svg
                class="hidden scale-50 lg:block xl:scale-75"
                width="164"
                height="68"
                viewBox="0 0 164 68"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.94179 35.0331C14.3418 29.242 26.75 23.4707 39.15 17.6796C41.7753 16.4666 43.4565 20.5761 40.8393 21.809C30.3743 26.6927 19.9292 31.5682 9.46415 36.4519C20.4165 38.7386 31.2483 41.5846 42.0675 44.343C49.8453 46.3294 57.0337 45.4513 60.9273 37.6011C63.8248 31.7575 63.0019 24.678 63.5961 18.3861C64.1407 12.4853 66.3202 5.68397 71.9073 2.65675C78.7146 -1.0319 87.0665 1.27579 93.6698 4.20613C107.783 10.4607 117.487 23.3627 129.911 32.0929C135.636 36.1239 142.693 39.309 149.678 36.5673C156.484 33.8992 159.584 25.8174 159.471 19.05C159.422 16.1963 163.871 15.6275 163.909 18.5092C164.153 32.4305 153.555 45.2479 138.68 41.3445C131.996 39.5829 126.228 35.2215 121.009 30.8907C115.146 26.012 109.705 20.6828 103.767 15.9045C97.3017 10.6927 89.8655 6.29546 81.4695 5.30363C72.489 4.24963 68.8506 11.3002 68.0128 18.9895C67.3299 25.1786 67.8317 31.4162 65.7914 37.4189C63.4603 44.3055 57.7041 49.14 50.4308 49.8675C46.7801 50.2253 43.2651 49.2066 39.787 48.3351C36.0444 47.3864 32.2818 46.4458 28.5193 45.5053C22.7653 44.0815 17.0113 42.6578 11.1982 41.3741C19.8128 48.7657 28.4193 56.1373 37.0339 63.5289C39.2177 65.3934 36.4785 68.9474 34.3146 67.0747C23.3353 57.6609 12.364 48.267 1.38468 38.8532C0.0615711 37.7259 0.329802 35.7621 1.91371 35.0214L1.94179 35.0331Z"
                  fill="#FECE1F"
                />
              </svg>
              <svg
                class="lg:hidden"
                width="103"
                height="75"
                viewBox="0 0 103 75"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.01451 10.6475C7.14368 18.3862 13.2845 26.1147 19.4137 33.8534C20.7155 35.4834 23.1267 33.3752 21.8365 31.735C16.6625 25.2064 11.4988 18.6894 6.32485 12.1608C14.128 14.2214 22.0338 15.8824 29.9033 17.5958C35.5616 18.8254 39.9151 21.7124 39.8924 28.0332C39.875 32.738 37.0587 37.0391 35.4108 41.2895C33.8571 45.2716 33.0674 50.3625 35.6941 54.1189C38.8944 58.6959 45.0269 59.9035 50.2323 60.145C61.3552 60.6665 71.7799 55.4768 82.6122 53.856C87.6069 53.1042 93.1872 53.3278 96.8079 57.3513C100.336 61.2694 99.7272 67.4834 97.4697 71.8123C96.5167 73.6372 99.2033 75.4406 100.158 73.5938C104.809 64.6925 102.111 53.0035 91.2559 50.7194C86.3755 49.6978 81.2471 50.6492 76.4822 51.7579C71.1254 53.0122 65.895 54.6932 60.5219 55.8585C54.6692 57.1333 48.4528 57.5691 42.7167 55.4984C36.5836 53.2792 36.5127 47.5566 38.4546 42.3261C40.0122 38.1133 42.3495 34.2518 42.9712 29.721C43.6907 24.5262 41.5384 19.5494 37.0815 16.7322C34.8421 15.3228 32.2459 14.8451 29.721 14.2845C27.0005 13.6883 24.2698 13.0803 21.5391 12.4724C17.3679 11.5332 13.1967 10.5941 9.03247 9.54545C16.9756 7.55854 24.907 5.58182 32.8501 3.59492C34.8607 3.09717 34.241 -0.0796692 32.2407 0.429788C22.1194 2.95775 12.0097 5.47552 1.88838 8.00348C0.670957 8.30356 0.210018 9.65689 0.992622 10.646L1.01451 10.6475Z"
                  fill="#FECE1F"
                />
              </svg>
            </div>
          </div>

          <div class="lg:max-w-[334px]">
            <p
              class="
                pt-4
                text-center text-lead1 text-base
                font-normal
                text-text-secondary
                lg:-ml-8
                lg:pt-[175px]
                lg:pl-10
                lg:text-start
                lg:font-medium
                xl:pt-[120px]
                xl:text-[22px]
              "
            >
              {{ t('vue_components.spot_a_house.track_your_spotted_house') }}
            </p>
          </div>
        </div>
        <div
          class="
            col-span-1
            flex
            w-full
            flex-col
            items-center
            justify-center
            pl-4
            pt-6
            lg:pt-7
          "
        >
          <div
            class="
              lg:row-reverse
              relative
              flex
              w-full
              max-w-[400px]
              flex-col
              items-center
              justify-center
              lg:items-end
            "
          >
            <div
              class="
                card-shadow
                lg:ml-0
                relative
                z-10
                -ml-2
                flex
                w-full
                max-w-[271px]
                flex-col
                items-start
                space-y-4
                self-start
                rounded-lg
                bg-white
                p-4
                lg:left-auto
                lg:mt-8
                lg:max-w-[341px]
                lg:self-center
              "
            >
              <span class="text-base font-medium text-primary-100">{{
                t('vue_components.spot_a_house.balance')
              }}</span>
              <span class="text-trans font-Vollkorn text-5xl font-bold"
                >€3000
              </span>
              <span class="text-sm font-normal text-[#414141]"
                >{{ t('vue_components.spot_a_house.last_update') }}
                {{ '10 Aug 2022, 14:30' }}</span
              >
              <button
                class="
                  rounded-full
                  bg-primary-100
                  py-2
                  px-5
                  text-sm
                  font-medium
                  text-white
                  hover:bg-opacity-80
                  hover:text-opacity-70
                "
              >
                {{ t('vue_components.spot_a_house.transfer_money') }}
              </button>
            </div>
            <div
              class="
                card-shadow
                absolute
                mt-[200px]
                w-full
                max-w-[284px]
                self-end
                rounded-lg
                bg-white
                p-4
                lg:-left-[120px] lg:-mt-6
                lg:self-center
              "
            >
              <svg
                class="w-full"
                height="159"
                viewBox="0 0 306 159"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="30" height="132" rx="2" fill="#FECE1F" />
                <path
                  d="M11.476 154L8.332 145.732H7.18L4.036 154H5.176L5.98 151.876H9.532L10.336 154H11.476ZM7.756 147.16L9.172 150.916H6.34L7.756 147.16ZM16.456 151.48C16.444 152.692 15.508 153.16 14.728 153.16C13.948 153.16 13.384 152.56 13.384 151.612V148.396H12.34V151.636C12.328 153.208 13.192 154.144 14.596 154.144C15.22 154.144 16.12 153.832 16.456 153.124V154H17.5V148.396H16.456V151.48ZM23.7423 149.272C23.3703 148.624 22.5783 148.252 21.7383 148.252C20.2023 148.252 18.9423 149.416 18.9423 151.192C18.9423 152.956 20.2143 154.144 21.7503 154.144C22.5543 154.144 23.3703 153.76 23.7423 153.124V153.292C23.7423 155.188 22.9743 155.884 21.7383 155.884C21.1143 155.884 20.4543 155.536 20.1063 154.9L19.2183 155.368C19.7703 156.352 20.7183 156.868 21.7383 156.868C23.5863 156.868 24.7863 155.872 24.7863 153.22V148.396H23.7423V149.272ZM21.8343 153.16C20.8143 153.16 19.9983 152.296 19.9983 151.18C19.9983 150.052 20.8143 149.236 21.8343 149.236C22.7583 149.236 23.7423 149.968 23.7423 151.18C23.7423 152.38 22.8423 153.16 21.8343 153.16Z"
                  fill="#414141"
                />
                <rect
                  x="46"
                  y="50"
                  width="30"
                  height="82"
                  rx="2"
                  fill="#FECE1F"
                />
                <path
                  d="M53.784 156.796C55.644 156.796 56.34 155.56 56.34 154.12V145.732H55.248V154.12C55.248 154.996 54.852 155.74 53.784 155.74C53.688 155.74 53.196 155.704 53.016 155.644L52.908 156.664C53.184 156.724 53.568 156.796 53.784 156.796ZM62.3427 151.48C62.3307 152.692 61.3947 153.16 60.6147 153.16C59.8347 153.16 59.2707 152.56 59.2707 151.612V148.396H58.2267V151.636C58.2147 153.208 59.0787 154.144 60.4827 154.144C61.1067 154.144 62.0067 153.832 62.3427 153.124V154H63.3867V148.396H62.3427V151.48ZM66.2451 154V145.348H65.2011V154H66.2451Z"
                  fill="#414141"
                />
                <rect
                  x="92"
                  y="34"
                  width="30"
                  height="98"
                  rx="2"
                  fill="#FECE1F"
                />
                <path
                  d="M97.784 156.796C99.644 156.796 100.34 155.56 100.34 154.12V145.732H99.248V154.12C99.248 154.996 98.852 155.74 97.784 155.74C97.688 155.74 97.196 155.704 97.016 155.644L96.908 156.664C97.184 156.724 97.568 156.796 97.784 156.796ZM106.343 151.48C106.331 152.692 105.395 153.16 104.615 153.16C103.835 153.16 103.271 152.56 103.271 151.612V148.396H102.227V151.636C102.215 153.208 103.079 154.144 104.483 154.144C105.107 154.144 106.007 153.832 106.343 153.124V154H107.387V148.396H106.343V151.48ZM112.105 148.252C111.493 148.252 110.581 148.564 110.245 149.248V148.396H109.201V154H110.245V150.964C110.245 149.752 111.181 149.284 111.961 149.284C112.741 149.284 113.317 149.884 113.317 150.82V154H114.361V150.76C114.373 149.188 113.497 148.252 112.105 148.252Z"
                  fill="#414141"
                />
                <rect
                  x="138"
                  y="81"
                  width="30"
                  height="51"
                  rx="2"
                  fill="#FECE1F"
                />
                <path
                  d="M150.052 145.732H149.068L146.128 151.864L143.176 145.732H142.192L141.184 154H142.252L143.008 147.7L145.66 153.1H146.608L149.236 147.7L150.004 154H151.072L150.052 145.732ZM156.996 149.272C156.624 148.612 155.832 148.252 154.992 148.252C153.408 148.252 152.196 149.536 152.196 151.192C152.196 152.908 153.48 154.144 154.944 154.144C155.844 154.144 156.624 153.676 156.996 153.004V154H158.04V148.396H156.996V149.272ZM155.1 153.16C154.068 153.16 153.252 152.26 153.252 151.192C153.252 150.136 154.068 149.236 155.088 149.236C156.024 149.236 156.996 150.04 156.996 151.192C156.996 152.332 156.096 153.16 155.1 153.16ZM161.981 152.524L160.289 148.396H159.161L161.417 153.832L160.241 156.724H161.345L164.825 148.396H163.697L161.981 152.524Z"
                  fill="#414141"
                />
                <rect
                  x="184"
                  y="41"
                  width="30"
                  height="91"
                  rx="2"
                  fill="#FECE1F"
                />
                <path
                  d="M196.476 154L193.332 145.732H192.18L189.036 154H190.176L190.98 151.876H194.532L195.336 154H196.476ZM192.756 147.16L194.172 150.916H191.34L192.756 147.16ZM200.448 148.252C199.644 148.252 198.828 148.636 198.456 149.272V148.396H197.412V156.724H198.456V153.124C198.828 153.784 199.62 154.144 200.46 154.144C201.996 154.144 203.256 152.98 203.256 151.204C203.256 149.44 201.984 148.252 200.448 148.252ZM200.352 153.16C199.44 153.16 198.456 152.428 198.456 151.216C198.456 150.016 199.356 149.236 200.352 149.236C201.384 149.236 202.2 150.1 202.2 151.216C202.2 152.344 201.384 153.16 200.352 153.16ZM207.341 148.252C206.597 148.252 206.057 148.684 205.769 149.668V148.396H204.713V154H205.769V151.948C205.769 150.472 206.213 149.26 207.329 149.26C207.545 149.26 207.845 149.308 208.145 149.452L208.313 148.468C208.145 148.36 207.737 148.252 207.341 148.252Z"
                  fill="#414141"
                />
                <rect
                  x="230"
                  y="12"
                  width="30"
                  height="120"
                  rx="2"
                  fill="#FECE1F"
                />
                <path
                  d="M242.552 145.732H241.568L238.628 151.864L235.676 145.732H234.692L233.684 154H234.752L235.508 147.7L238.16 153.1H239.108L241.736 147.7L242.504 154H243.572L242.552 145.732ZM249.496 149.272C249.124 148.612 248.332 148.252 247.492 148.252C245.908 148.252 244.696 149.536 244.696 151.192C244.696 152.908 245.98 154.144 247.444 154.144C248.344 154.144 249.124 153.676 249.496 153.004V154H250.54V148.396H249.496V149.272ZM247.6 153.16C246.568 153.16 245.752 152.26 245.752 151.192C245.752 150.136 246.568 149.236 247.588 149.236C248.524 149.236 249.496 150.04 249.496 151.192C249.496 152.332 248.596 153.16 247.6 153.16ZM254.997 148.252C254.253 148.252 253.713 148.684 253.425 149.668V148.396H252.369V154H253.425V151.948C253.425 150.472 253.869 149.26 254.985 149.26C255.201 149.26 255.501 149.308 255.801 149.452L255.969 148.468C255.801 148.36 255.393 148.252 254.997 148.252Z"
                  fill="#414141"
                />
                <rect
                  x="276"
                  y="41"
                  width="30"
                  height="91"
                  rx="2"
                  fill="#FECE1F"
                />
                <path
                  d="M286.964 145.732H282.152V154H283.244V150.448H286.196V149.428H283.244V146.74H286.964V145.732ZM293.099 151.18C293.099 149.404 291.911 148.252 290.315 148.252C288.719 148.252 287.435 149.404 287.435 151.18C287.435 152.956 288.719 154.144 290.315 154.144C291.395 154.144 292.403 153.616 292.883 152.644L291.983 152.284C291.659 152.848 291.035 153.16 290.351 153.16C289.391 153.16 288.671 152.644 288.515 151.648H293.075C293.087 151.492 293.099 151.336 293.099 151.18ZM288.527 150.748C288.695 149.74 289.367 149.236 290.315 149.236C291.239 149.236 291.923 149.788 292.055 150.748H288.527ZM297.569 148.252C296.729 148.252 295.937 148.612 295.565 149.272V145.348H294.521V154H295.565V153.124C295.937 153.76 296.753 154.144 297.557 154.144C299.093 154.144 300.365 152.956 300.365 151.192C300.365 149.416 299.105 148.252 297.569 148.252ZM297.461 153.16C296.465 153.16 295.565 152.38 295.565 151.18C295.565 149.968 296.549 149.236 297.461 149.236C298.493 149.236 299.309 150.052 299.309 151.18C299.309 152.296 298.493 153.16 297.461 153.16Z"
                  fill="#414141"
                />
              </svg>
            </div>

            <div
              class="
                absolute
                -left-2
                -bottom-9
                z-20
                scale-75
                lg:-left-9 lg:-bottom-5
                lg:scale-50
                xl:-bottom-6
                xl:scale-75
              "
            >
              <svg
                class="hidden lg:block"
                width="140"
                height="92"
                viewBox="0 0 140 92"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M139.215 17.5377C130.032 27.0924 120.864 36.6619 111.681 46.2166C109.743 48.252 106.823 45.1417 108.746 43.1207C116.342 35.2113 123.954 27.2875 131.536 19.3633C121.161 21.1161 110.714 22.4573 100.303 24.0189C91.8384 25.2895 87.4426 32.3125 88.577 40.4798C89.3954 46.4876 92.3309 51.9579 93.3415 57.9248C94.3522 63.8916 92.8984 70.6689 87.4018 74.1565C81.1843 78.1015 73.0659 78.3371 66.0799 77.3011C51.4499 75.1745 38.6049 66.833 23.9532 64.8967C18.1158 64.1284 11.7452 65.081 7.80758 70.0301C3.82323 75.0517 3.92408 82.2204 6.18103 87.797C7.20148 90.3344 3.38436 92.4274 2.36499 89.8607C-2.58856 77.5132 2.55106 62.2946 17.2375 60.5233C23.8371 59.721 30.5289 61.573 36.6866 63.5923C43.6129 65.8438 50.3264 68.6928 57.2853 70.857C64.8673 73.2216 73.0465 74.5114 80.9412 72.3962C89.3679 70.1723 90.2366 62.5648 88.3748 55.4264C86.8735 49.6421 84.2865 44.2654 84.0716 38.1941C83.8318 31.2136 87.3526 24.8508 93.6309 21.6396C96.6956 20.0621 100.096 19.7212 103.442 19.2621C106.788 18.8031 110.12 18.3292 113.467 17.8408C118.76 17.0614 124.069 16.2676 129.352 15.3854C118.854 11.7719 108.385 8.15882 97.8868 4.54528C95.2802 3.65318 96.5342 -0.50375 99.1408 0.388353C112.216 4.89352 125.277 9.38381 138.353 13.889C139.931 14.4274 140.361 16.2811 139.186 17.508L139.215 17.5377Z"
                  fill="#FECE1F"
                />
              </svg>
              <svg
                class="lg:hidden"
                width="48"
                height="115"
                viewBox="0 0 48 115"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M45.3384 2.11227C45.8181 11.9725 46.2823 21.8327 46.7619 31.6929C46.8548 33.7769 43.6519 33.7769 43.5436 31.6929C43.1414 23.3724 42.7391 15.0674 42.3368 6.74688C37.8188 13.4344 32.9604 19.8886 28.164 26.3584C24.7136 31.0086 23.3365 36.0476 27.5141 40.7911C30.6241 44.3214 35.5753 45.7056 39.6136 47.8207C43.4044 49.7959 47.3499 53.1085 47.845 57.6654C48.4484 63.2176 44.6267 68.1633 40.8669 71.7714C32.8367 79.4854 21.5727 82.4403 12.3511 88.3502C8.09613 91.0719 4.04233 94.9133 3.96497 100.326C3.88761 105.598 8.43652 109.875 12.9854 111.648C14.904 112.394 14.0685 115.52 12.1344 114.758C2.77359 111.119 -2.88935 100.543 3.7793 91.6785C6.78097 87.697 11.268 85.0376 15.5848 82.7358C20.4432 80.1541 25.4872 77.9768 30.2992 75.3173C35.5444 72.4246 40.511 68.6609 43.4663 63.3265C46.6227 57.6187 42.9093 53.2641 38.0045 50.6046C34.059 48.4584 29.7576 47.0898 26.3073 44.0882C22.3463 40.6511 20.6907 35.4877 22.1916 30.4332C22.9497 27.8981 24.5898 25.8297 26.1216 23.7456C27.7772 21.5061 29.4327 19.251 31.0883 16.9959C33.6103 13.5433 36.1323 10.0906 38.577 6.56025C31.2894 10.2928 24.0173 14.0254 16.7298 17.758C14.8886 18.7067 13.2639 15.9072 15.1052 14.9741C24.3887 10.2151 33.6567 5.45602 42.9402 0.696991C44.0542 0.121559 45.292 0.836967 45.3539 2.09671L45.3384 2.11227Z"
                  fill="#FECE1F"
                />
              </svg>
            </div>
          </div>

          <div
            class="
              max-w-[412px]
              pt-10
              text-center text-lead1
              font-normal
              text-[#414141]
              lg:-ml-[120px]
              lg:pt-3
              lg:text-start
              lg:font-medium
              xl:pt-6
              xl:text-[22px]
            "
          >
            <p>
              {{ t('vue_components.spot_a_house.know_how_much_you_earned') }}
            </p>
          </div>
        </div>
      </div>

      <div class="flex w-full">
        <button
          @click="GetStarted"
          class="
            m-auto
            h-[44px]
            w-[128px]
            rounded-sm
            bg-[#FECE1F]
            text-center text-sm
            font-medium
            text-primary-100
            hover:bg-opacity-60
            hover:text-opacity-60
            lg:h-[60px]
            lg:w-[196px]
            lg:text-lg
            lg:font-bold
          "
        >
          {{ t('vue_components.spot_a_house.get_started') }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import bgImage from 'assets/images/spot_a_house/secondsection.png';

export default {
  data() {
    return {
      bgImage,
    };
  },
  methods: {
    GetStarted() {
      // scroll to ContactUs id
      window.scrollTo({
        top: document.getElementById('ContactUs').offsetTop,
        behavior: 'smooth',
      });
    },
  },
};
</script>
