import React from 'react';
import { composeClassName } from '../../../../../../utils/util_helpers';
import { theme } from '../../../../../../utils/util_theme';
import { IconSaxIcon } from '../../../../atoms/icon';
import { useDropDownContext } from '../../hooks/use-dropdown-context';
import { useDropDownHasValue } from '../../hooks/use-dropdown-has-value';
import { DropDownInputClearButtonProps } from './types';

const DropDownClearInputButton: React.FC<DropDownInputClearButtonProps> = ({
  className,
  onClick,
}) => {
  const { multiple, inputHovered } = useDropDownContext()!;
  const value = useDropDownHasValue();

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {inputHovered && value ? (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          onClick={() => onClick(multiple ? [] : null)}
          className={composeClassName(
            'absolute right-7 flex h-full items-center',
            className && className
          )}
        >
          <IconSaxIcon
            name="close"
            strokeColor={theme?.colors?.text?.tertiary}
          />
        </div>
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <></>
      )}
    </>
  );
};

export { DropDownClearInputButton };
