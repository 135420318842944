<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div v-show="isActive">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: 'Placeholder title',
    },
  },
  data() {
    return {
      isActive: this.active,
    };
  },
};
</script>
