import React from 'react';
import './style.css';
import { ReactSVG } from 'react-svg';
import { SocialIconProps, SocialIconNameMappingProtocol } from './types';
import { composeClassName } from '../../../../../utils/util_helpers';

const SocialIconVariantMap: SocialIconNameMappingProtocol = {
  'black-white': 'bw',
  original: 'og',
};

const SocialIcon: React.FC<SocialIconProps> = ({
  name,
  strokeColor = '#1a1a1a',
  hoverStrokeColor,
  variant = 'original',
  size = 24,
  className,
  ...props
}) => {
  const style = {
    '--icon-color-social': strokeColor,
    ...(hoverStrokeColor && { '--icon-color-social--hover': hoverStrokeColor }),
  } as React.CSSProperties;

  return (
    <ReactSVG
      data-testid={name}
      src={require(`@assets/icons/social-icons/${SocialIconVariantMap[variant]}/${name}-${SocialIconVariantMap[variant]}.svg`)}
      className={composeClassName(
        'inline-block align-baseline',
        variant === 'black-white' && 'icon-social-bw',
        className && className
      )}
      style={style}
      beforeInjection={(svg) => {
        svg.setAttribute('width', `${size}`);
        svg.setAttribute('height', `${size}`);
      }}
      {...props}
    />
  );
};

export { SocialIcon };
