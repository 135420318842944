import React from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ReactQueryProvider } from '@/providers/react-query-provider';
import { ToastProvider } from '@/providers/toast-provider';
import { PayoutSummary } from '@/features/admin/payouts/components/payout-summary';

const PayoutSummaryScreen = () => {
  return (
    <ReactQueryProvider>
      <ToastProvider />
      {/* Dev tools wil only be displayed during development */}
      <ReactQueryDevtools initialIsOpen={false} />
      <div className="flex h-screen w-screen justify-center bg-E10">
        <div className="h-full w-[80%]">
          <PayoutSummary />
        </div>
      </div>
    </ReactQueryProvider>
  );
};

export { PayoutSummaryScreen };
