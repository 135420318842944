<template>
  <section
    class="main-bg relative w-full space-y-5 py-7 py-4 sm:space-y-10 lg:pt-10"
  >
    <div class="m-auto flex w-full max-w-[450px] px-4 sm:px-1">
      <div class="flex w-full flex-col items-center space-y-4">
        <div>
          <span
            class="
              text-center
              font-Vollkorn
              text-[28px]
              font-bold
              text-primary-100
              lg:text-5xl
            "
            >Talk to us now</span
          >
        </div>

        <EnquiryForm />
      </div>
    </div>
    <div class="absolute -right-1 bottom-7 hidden lg:block">
      <ColocIcon name="message" :size="150" />
    </div>
    <div
      class="
        absolute
        -left-8
        bottom-2
        hidden
        h-[130px]
        w-[130px]
        rounded-full
        bg-R300
        sm:block
      "
    ></div>
  </section>
</template>

<script>
// import TextInput from '../../../common/components/ui/atoms/text/text-input/TextInput.vue'
import ColocIcon from '../../../common/components/ui/atoms/icon/coloc-icon/ColocIcon.vue';
// import Button from '../../../common/components/ui/atoms/button/Button.vue';
import EnquiryForm from '../EnquiryForm.vue';

export default {
  components: {
    // TextInput
    ColocIcon,
    // eslint-disable-next-line vue/no-reserved-component-names
    // Button,
    EnquiryForm,
  },
};
</script>
