<template>
  <button class="ui-fab-button" :disabled="disabled" @click="handleClick">
    <IconSaxIconVue :name="iconName" class="nested-sax-icon" />
  </button>
</template>

<script>
import './Fab.scss';
import IconSaxIcons from '../icon/iconsax-icon/iconSaxValidator';
import IconSaxIconVue from '../icon/iconsax-icon/IconSaxIcon.vue';

export default {
  components: { IconSaxIconVue },
  props: {
    /**
     * Icon to show. Restricted to IconSax
     */
    iconName: {
      type: String,
      validator(value) {
        return Object.prototype.hasOwnProperty.call({ ...IconSaxIcons }, value);
      },
    },
    /**
     * Button disabled or not
     *
     * Hover and pressed state are handled internaly
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>
