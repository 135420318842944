<template>
  <footer
    class="
      m-auto
      flex
      w-full
      max-w-[1520px]
      flex-col
      space-y-4
      bg-white
      px-4
      pb-6
    "
  >
    <div class="w-full border-t border-[#414141]"></div>
    <span class="text-xs font-normal text-[#414141] lg:text-sm"
      >©{{ year }} Coloc Housing • VAT BE 0773.923.507 - IPI 512 838</span
    >
  </footer>
</template>


<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
