import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  GetPayoutSummaryContextProvider,
  useGetPayoutSummaryContext,
} from './hooks/use-get-payout-summary';
import { PayoutSummaryCard } from './components/payout-summary-card';
import { LoadingSpinner } from '@/components/design-system/atoms/loading-spinner';
import { Button } from '@/components/design-system/atoms/button';
import { EmptyState } from '@/components/design-system/atoms/empty-state';
import { useCreatePayout } from './hooks/use-create-payout';
import { PayoutRentDetailModal } from '@/features/shared/payout-rent-detail-modal';

const EmptyOrErrorState: React.FC<{
  status: 'loading' | 'error' | 'success';
  refetch: () => void;
}> = ({ status, refetch }) => {
  const { t } = useTranslation();

  if (status === 'loading') {
    return (
      <div className="flex h-full max-h-full w-full items-center justify-center">
        <LoadingSpinner size={64} />
      </div>
    );
  }

  return (
    <div className="flex h-full max-h-full w-full items-center justify-center">
      <EmptyState
        type={status === 'error' ? 'Error' : 'NoResults'}
        {...(status !== 'error' && {
          actionButton: (
            <Button onClick={() => refetch()}>
              {t(
                'js.features.admin.payouts.payouts_summary.retry_fetching_button'
              ) ?? ''}
            </Button>
          ),
        })}
      />
    </div>
  );
};

const Content = () => {
  const {
    fetch,
    toggleShowRentDetailsPayoutModal,
    showRentDetailsPayoutModal,
  } = useGetPayoutSummaryContext()!;
  const { mutate: createPayout } = useCreatePayout();
  const { data, status, refetch } = fetch;
  const { t } = useTranslation();

  if (data?.data && data?.data.data?.length >= 1 && status === 'success') {
    return (
      <div className="flex flex-col gap-3 ">
        {data?.data.data?.map((item) => {
          return (
            <PayoutSummaryCard
              key={item.id}
              payout={item}
              showRentDetails={() =>
                toggleShowRentDetailsPayoutModal({ payout: item, show: true })
              }
            />
          );
        })}
        <div className="flex gap-4 pt-2">
          <Button onClick={() => createPayout.mutate()}>
            {t('js.features.admin.payouts.payouts_summary.confirm_button') ??
              ''}
          </Button>
          <Button
            variant="ghost"
            onClick={() => {
              window.location.replace(`./new-rents?all=true`);
            }}
          >
            {t('js.features.admin.payouts.payouts_summary.cancel_button') ?? ''}
          </Button>
        </div>
        {showRentDetailsPayoutModal.show && showRentDetailsPayoutModal.payout && (
          <PayoutRentDetailModal
            payout={showRentDetailsPayoutModal.payout}
            open={showRentDetailsPayoutModal.show}
            onClose={() =>
              toggleShowRentDetailsPayoutModal({
                show: false,
                payout: undefined,
              })
            }
          />
        )}
      </div>
    );
  }

  return <EmptyOrErrorState status={status} refetch={refetch} />;
};

const PayoutSummary = () => {
  const { t } = useTranslation();

  return (
    <GetPayoutSummaryContextProvider>
      <div className="flex items-center gap-3 pb-3 pt-4">
        <Button
          variant="icon"
          iconName="arrow-left"
          customIconSize={32}
          onClick={() => window.location.replace(`./new-rents?all=true`)}
        />
        <h1 className="text-h5 font-medium text-text-primary">
          {t('js.features.admin.payouts.payouts_summary.title')}
        </h1>
      </div>
      <div className="mb-8 w-full rounded-lg bg-white p-5 shadow-sm">
        <Content />
      </div>
    </GetPayoutSummaryContextProvider>
  );
};

export { PayoutSummary };
