import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropDown } from '@/components/design-system/molecules/dropdown';
import { useRentsSummaryFiltersContext } from '../../../../hooks/use-rents-summary-filter';

const HouseRentStatusDropDown = () => {
  const {
    selectedHouseRentStatus,
    setSelectedHouseRentStatus,
    setRentStatusQuery,
    filteredHouseRentsStatusArray,
  } = useRentsSummaryFiltersContext()!;
  const { t } = useTranslation();

  return (
    <DropDown
      placeholder={t('js.features.admin.rents.shared.status_placeholder') ?? ''}
      className="w-[180px]"
      showLabel
      label={t('js.features.admin.rents.shared.status_label') ?? ''}
      value={selectedHouseRentStatus}
      onChange={setSelectedHouseRentStatus}
      {...(selectedHouseRentStatus && {
        displayValue: selectedHouseRentStatus.displayValue,
      })}
      onTextInputChange={(event) => setRentStatusQuery(event.target.value)}
    >
      <DropDown.DropDownOptions>
        {filteredHouseRentsStatusArray.map((option) => {
          return (
            <DropDown.DropDownOption key={option.displayValue} value={option}>
              <DropDown.DropDownOptionDefaultContent>
                {option.displayValue}
              </DropDown.DropDownOptionDefaultContent>
            </DropDown.DropDownOption>
          );
        })}
      </DropDown.DropDownOptions>
    </DropDown>
  );
};

export { HouseRentStatusDropDown };
