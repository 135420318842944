<template>
  <div
    class="lg:py-0 order-first flex items-center py-10 pr-10 lg:order-last"
    :class="{ '-space-x-14 lg:ml-0 ml-[-53px] lg:-space-x-11': selected == 5 }"
  >
    <div class="relative -z-20" v-if="selected == 5">
      <div
        class="
          absolute
          -left-4
          -top-3
          z-10
          h-[34px]
          w-[34px]
          rounded-full
          bg-[#FECE1F]
          lg:-left-10 lg:-top-7
          lg:h-[64px]
          lg:w-[64px]
        "
      ></div>
      <div
        class="
          relative
          h-[218px]
          w-[150px]
          overflow-hidden
          rounded-lg
          bg-gray-600
          lg:h-[350px]
          lg:w-[250px]
        "
      >
        <img
          src="~assets/images/spot_a_house/before.png"
          class="h-full w-full"
          :alt="t('vue_components.spot_a_house.before')"
        />
        <div
          class="
            absolute
            left-4
            bottom-3
            rounded-lg
            bg-white
            py-2
            px-4
            text-sm
            font-medium
            text-primary-100
            lg:text-lg
          "
        >
          {{ t('vue_components.spot_a_house.before') }}
        </div>
      </div>
    </div>
    <div class="relative">
      <div
        class="
          absolute
          -right-3
          -top-3
          -z-10
          h-[64px]
          w-[64px]
          rounded-full
          bg-[#AFC1D4]
          lg:-right-7 lg:-top-5
          lg:h-[120px]
          lg:w-[120px]
        "
      ></div>
      <div
        class="
          absolute
          -left-3
          -bottom-3
          -z-10
          h-[64px]
          w-[64px]
          rounded-full
          bg-[#FECE1F]
          lg:-left-7 lg:-bottom-7
          lg:h-[120px]
          lg:w-[120px]
        "
      ></div>
      <div
        class="
          relative
          h-[318px]
          w-[251px]
          overflow-hidden
          rounded-lg
          bg-gray-400
          lg:h-[450px]
          lg:w-[350px]
        "
      >
        <img
          :src="require('assets/images/spot_a_house/' + selected + '.png')"
          class="h-full w-full"
          :alt="selectedBadgeTitle"
        />
        <div
          class="
            absolute
            left-4
            bottom-3
            rounded-lg
            bg-white
            py-2
            px-4
            text-sm
            font-medium
            text-primary-100
            lg:text-lg
          "
        >
          {{ selectedBadgeTitle }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selected: {
      type: Number,
      default: 1,
    },
    selectedBadgeTitle: {
      type: String,
      default() {
        return this.t('vue_components.spot_a_house.after');
      },
    },
  },
};
</script>
