import { axiosLib } from '@/lib/axios';
import { getColocAdminApiCallProperties } from './utils';

export const unpairTransaction = async (transactionId: number) => {
  return axiosLib.put(
    `/rent_transactions/${transactionId}/unpair`,
    {},
    { ...getColocAdminApiCallProperties() }
  );
};
