var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"w-full",style:({ backgroundImage: `url(${_vm.bgImage})` }),attrs:{"id":"WhatImages"}},[_c('div',{staticClass:"m-auto w-full max-w-8xl px-4 py-7 pb-9 lg:pt-9"},[_c('div',{staticClass:"flex flex-col items-center space-y-2 lg:hidden"},[_c('h1',{staticClass:"font-Vollkorn text-[28px] font-bold text-primary-100"},[_vm._v("\n        "+_vm._s(_vm.t('vue_components.spot_a_house.what_are_we_looking_for'))+"\n      ")]),_vm._v(" "),_c('span',{staticClass:"text-base font-medium text-[#414141]"},[_vm._v(_vm._s(_vm.t('vue_components.spot_a_house.the_most_interesting_projects')))])]),_vm._v(" "),_c('div',{staticClass:"flex w-full flex-col items-center lg:flex-row lg:items-start lg:justify-between"},[_c('div',{staticClass:"flex flex-col items-start space-y-4"},[_c('div',{staticClass:"hidden flex-col items-start space-y-2 lg:flex"},[_c('h1',{staticClass:"font-Vollkorn text-5xl font-bold text-primary-100"},[_vm._v("\n            "+_vm._s(_vm.t('vue_components.spot_a_house.what_are_we_looking_for'))+"\n          ")]),_vm._v(" "),_c('span',{staticClass:"text-[22px] font-medium text-[#414141]"},[_vm._v(_vm._s(_vm.t('vue_components.spot_a_house.the_most_interesting_projects')))])]),_vm._v(" "),_c('div',{staticClass:"flex w-full flex-col items-start space-y-3"},[_c('div',{staticClass:"flex flex-col items-start space-y-4"},[_c('div',{staticClass:"flex cursor-pointer items-center space-x-2 rounded-full py-2 px-6 ring-[#FEE37D]",class:{
                'bg-[#FECE1F] ': _vm.selected == 1,
                'hover:bg-[#FFFAE9] hover:ring-1': _vm.selected != 1,
              },on:{"click":function($event){_vm.handelSelect(1, _vm.t('vue_components.spot_a_house.one_doorbell'))}}},[_c('IconCheck'),_vm._v(" "),_c('span',{staticClass:"text-sm font-normal text-[#414141] lg:text-lg"},[_vm._v(_vm._s(_vm.t('vue_components.spot_a_house.one_doorbell')))])],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected == 1),expression:"selected == 1"}],staticClass:"w-full max-w-[283px] pl-4"},[_c('p',{staticClass:"text-sm font-normal text-[#414141]"},[_vm._v("\n                "+_vm._s(_vm.t('vue_components.spot_a_house.one_doorbell2'))+"\n              ")]),_vm._v(" "),_c('Houses',{staticClass:"lg:hidden",attrs:{"selected":_vm.selected,"selectedBadgeTitle":_vm.selectedBadgeTitle}})],1)]),_vm._v(" "),_c('div',{staticClass:"flex flex-col items-start space-y-4"},[_c('div',{staticClass:"flex cursor-pointer items-center space-x-2 rounded-full py-2 px-6 ring-[#FEE37D]",class:{
                'bg-[#FECE1F] ': _vm.selected == 2,
                'hover:bg-[#FFFAE9] hover:ring-1': _vm.selected != 2,
              },on:{"click":function($event){_vm.handelSelect(2, _vm.t('vue_components.spot_a_house.wide_facade'))}}},[_c('IconCheck'),_vm._v(" "),_c('span',{staticClass:"text-sm font-normal text-[#414141] lg:text-lg"},[_vm._v(_vm._s(_vm.t('vue_components.spot_a_house.wide_facade')))])],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected == 2),expression:"selected == 2"}],staticClass:"w-full max-w-[283px] pl-4"},[_c('p',{staticClass:"text-sm font-normal text-[#414141]"},[_vm._v("\n                "+_vm._s(_vm.t('vue_components.spot_a_house.wide_facade2'))+"\n              ")]),_vm._v(" "),_c('Houses',{staticClass:"lg:hidden",attrs:{"selected":_vm.selected,"selectedBadgeTitle":_vm.selectedBadgeTitle}})],1)]),_vm._v(" "),_c('div',{staticClass:"flex flex-col items-start space-y-4"},[_c('div',{staticClass:"flex cursor-pointer items-center space-x-2 rounded-full py-2 px-6 ring-[#FEE37D]",class:{
                'bg-[#FECE1F] ': _vm.selected == 3,
                'hover:bg-[#FFFAE9] hover:ring-1': _vm.selected != 3,
              },on:{"click":function($event){_vm.handelSelect(
                  3,
                  _vm.t('vue_components.spot_a_house.great_location')
                )}}},[_c('IconCheck'),_vm._v(" "),_c('span',{staticClass:"text-sm font-normal text-[#414141] lg:text-lg"},[_vm._v(_vm._s(_vm.t('vue_components.spot_a_house.great_location')))])],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected == 3),expression:"selected == 3"}],staticClass:"w-full max-w-[283px] pl-4"},[_c('p',{staticClass:"text-sm font-normal text-[#414141]"},[_vm._v("\n                "+_vm._s(_vm.t('vue_components.spot_a_house.great_location2'))+"\n              ")]),_vm._v(" "),_c('Houses',{staticClass:"lg:hidden",attrs:{"selected":_vm.selected,"selectedBadgeTitle":_vm.selectedBadgeTitle}})],1)]),_vm._v(" "),_c('div',{staticClass:"flex flex-col items-start space-y-4"},[_c('div',{staticClass:"flex cursor-pointer items-center space-x-2 rounded-full py-2 px-6 ring-[#FEE37D]",class:{
                'bg-[#FECE1F] ': _vm.selected == 4,
                'hover:bg-[#FFFAE9] hover:ring-1': _vm.selected != 4,
              },on:{"click":function($event){_vm.handelSelect(
                  4,
                  _vm.t('vue_components.spot_a_house.at_least_three_stories_high')
                )}}},[_c('IconCheck'),_vm._v(" "),_c('span',{staticClass:"text-sm font-normal text-[#414141] lg:text-lg"},[_vm._v(_vm._s(_vm.t('vue_components.spot_a_house.at_least_three_stories_high')))])],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected == 4),expression:"selected == 4"}],staticClass:"w-full max-w-[283px] pl-4"},[_c('p',{staticClass:"text-sm font-normal text-[#414141]"},[_vm._v("\n                "+_vm._s(_vm.t('vue_components.spot_a_house.the_more_stories'))+"\n              ")]),_vm._v(" "),_c('Houses',{staticClass:"lg:hidden",attrs:{"selected":_vm.selected,"selectedBadgeTitle":_vm.selectedBadgeTitle}})],1)]),_vm._v(" "),_c('div',{staticClass:"flex flex-col items-start space-y-4"},[_c('div',{staticClass:"flex cursor-pointer items-center space-x-2 rounded-full py-2 px-6 ring-[#FEE37D]",class:{
                'bg-[#FECE1F] ': _vm.selected == 5,
                'hover:bg-[#FFFAE9] hover:ring-1': _vm.selected != 5,
              },on:{"click":function($event){_vm.handelSelect(5, _vm.t('vue_components.spot_a_house.after'))}}},[_c('IconCheck'),_vm._v(" "),_c('span',{staticClass:"whitespace-nowrap text-sm font-normal text-[#414141] lg:text-lg"},[_vm._v(_vm._s(_vm.t('vue_components.spot_a_house.to_be_renovated')))])],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected == 5),expression:"selected == 5"}],staticClass:"w-full max-w-[283px] pl-4"},[_c('p',{staticClass:"text-sm font-normal text-[#414141]"},[_vm._v("\n                "+_vm._s(_vm.t(
                    'vue_components.spot_a_house.all_of_out_projects_need_to_be_renovated'
                  ))+"\n              ")]),_vm._v(" "),_c('Houses',{staticClass:"lg:hidden",attrs:{"selected":_vm.selected,"selectedBadgeTitle":_vm.selectedBadgeTitle}})],1)]),_vm._v(" "),_c('div',{staticClass:"flex flex-col items-start space-y-4"},[_c('div',{staticClass:"flex cursor-pointer items-center space-x-2 rounded-full bg-[#FFFAE9] py-2 px-3",on:{"click":function($event){_vm.handelSelect(
                  6,
                  _vm.t('vue_components.spot_a_house.300_square_feet')
                )}}},[_c('span',{staticClass:"text-base font-medium text-primary-100 lg:text-lg"},[_vm._v(_vm._s(_vm.t(
                    'vue_components.spot_a_house.more_detailed_characteristics'
                  )))]),_vm._v(" "),_c('svg',{class:{ bottom: _vm.selected >= 6 },attrs:{"width":"9","height":"19","viewBox":"0 0 9 19","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M0.910156 17.4201L7.43016 10.9001C8.20016 10.1301 8.20016 8.87008 7.43016 8.10008L0.910156 1.58008","stroke":"#1A1A1A","stroke-width":"1.3","stroke-miterlimit":"10","stroke-linecap":"round","stroke-linejoin":"round"}})])]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected >= 6),expression:"selected >= 6"}],staticClass:"flex w-full flex-col items-start space-y-4"},[_c('div',{staticClass:"flex flex-col items-start space-y-4"},[_c('div',{staticClass:"flex cursor-pointer items-center space-x-2 rounded-full py-2 px-6 ring-[#FEE37D]",class:{
                    'bg-[#FECE1F] ': _vm.selected == 6,
                    'hover:bg-[#FFFAE9] hover:ring-1': _vm.selected != 6,
                  },on:{"click":function($event){_vm.handelSelect(
                      6,
                      _vm.t('vue_components.spot_a_house.300_square_feet')
                    )}}},[_c('IconCheck'),_vm._v(" "),_c('span',{staticClass:"text-sm font-normal text-[#414141] lg:text-lg"},[_vm._v(_vm._s(_vm.t('vue_components.spot_a_house.300_square_feet')))])],1),_vm._v(" "),(_vm.selected == 6)?_c('div',{staticClass:"w-full max-w-[283px] pl-4"},[_c('Houses',{staticClass:"lg:hidden",attrs:{"selected":_vm.selected,"selectedBadgeTitle":_vm.selectedBadgeTitle}})],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"flex flex-col items-start space-y-4"},[_c('div',{staticClass:"flex cursor-pointer items-center space-x-2 rounded-full py-2 px-6 ring-[#FEE37D]",class:{
                    'bg-[#FECE1F] ': _vm.selected == 7,
                    'hover:bg-[#FFFAE9] hover:ring-1': _vm.selected != 7,
                  },on:{"click":function($event){_vm.handelSelect(
                      7,
                      _vm.t('vue_components.spot_a_house.at_least_rooms')
                    )}}},[_c('IconCheck'),_vm._v(" "),_c('span',{staticClass:"text-sm font-normal text-[#414141] lg:text-lg"},[_vm._v(_vm._s(_vm.t('vue_components.spot_a_house.at_least_rooms'))+"\n                  ")])],1),_vm._v(" "),(_vm.selected == 7)?_c('div',{staticClass:"w-full max-w-[283px] pl-4"},[_c('Houses',{staticClass:"lg:hidden",attrs:{"selected":_vm.selected,"selectedBadgeTitle":_vm.selectedBadgeTitle}})],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"flex flex-col items-start space-y-4"},[_c('div',{staticClass:"flex cursor-pointer items-center space-x-2 rounded-full py-2 px-6 ring-[#FEE37D]",class:{
                    'bg-[#FECE1F] ': _vm.selected == 8,
                    'hover:bg-[#FFFAE9] hover:ring-1': _vm.selected != 8,
                  },on:{"click":function($event){_vm.handelSelect(
                      8,
                      _vm.t('vue_components.spot_a_house.square_meters_room')
                    )}}},[_c('IconCheck'),_vm._v(" "),_c('span',{staticClass:"text-sm font-normal text-[#414141] lg:text-lg"},[_vm._v(_vm._s(_vm.t('vue_components.spot_a_house.square_meters_room')))])],1),_vm._v(" "),(_vm.selected == 8)?_c('div',{staticClass:"w-full max-w-[283px] pl-4"},[_c('Houses',{staticClass:"lg:hidden",attrs:{"selected":_vm.selected,"selectedBadgeTitle":_vm.selectedBadgeTitle}})],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"flex flex-col items-start space-y-4"},[_c('div',{staticClass:"flex cursor-pointer items-center space-x-2 rounded-full py-2 px-6 ring-[#FEE37D]",class:{
                    'bg-[#FECE1F] ': _vm.selected == 9,
                    'hover:bg-[#FFFAE9] hover:ring-1': _vm.selected != 9,
                  },on:{"click":function($event){_vm.handelSelect(
                      9,
                      _vm.t('vue_components.spot_a_house.garden_or_terrace')
                    )}}},[_c('IconCheck'),_vm._v(" "),_c('span',{staticClass:"text-sm font-normal text-[#414141] lg:text-lg"},[_vm._v(_vm._s(_vm.t('vue_components.spot_a_house.garden_or_terrace')))])],1),_vm._v(" "),(_vm.selected == 9)?_c('div',{staticClass:"w-full max-w-[283px] pl-4"},[_c('Houses',{staticClass:"lg:hidden",attrs:{"selected":_vm.selected,"selectedBadgeTitle":_vm.selectedBadgeTitle}})],1):_vm._e()])])])])]),_vm._v(" "),_c('Houses',{staticClass:"hidden lg:flex",attrs:{"selected":_vm.selected,"selectedBadgeTitle":_vm.selectedBadgeTitle}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }