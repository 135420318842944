/* eslint no-unused-vars: 0 */
/* eslint-disable */

import { Controller } from 'stimulus';

export default class extends Controller {
  success(event) {
    const [_data, _status, xhr] = event.detail;
    this.element.outerHTML = xhr.response;
  }
}
