import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { QueryKey, useQuery } from '@tanstack/react-query';
import { RowSelectionState } from '@tanstack/react-table';

import { AxiosResponse } from 'axios';
import {
  GetPayoutsResponse,
  PayoutsSortType,
  payoutsSortableIds,
} from '@/lib/coloc-api/admin';
import { getPayouts } from '@/services/coloc-api/admin';
import { useColocApi } from '@/hooks/use-coloc-api';
import { useGetOutgoingTransactionsListFilterContext } from '../use-get-outgoing-transactions-list-filter';
import { useDebounce } from '@/hooks/use-debounce';
import { Payout } from '@/features/admin/payouts/types';
import { queryClient } from '@/lib/react-query';

const useGetOutgoingTransactionsList = () => {
  const {
    searchQuery,
    endDate,
    startDate,
    selectedStatus,
    selectedHouse,
    setColumnToSort,
    sortDirection,
    selectedColumnToSort,
  } = useGetOutgoingTransactionsListFilterContext()!;
  const {
    setPage,
    setPageLimit,
    page,
    pageLimit,
    resultSummary,
    setPaginationResponse,
  } = useColocApi<PayoutsSortType>()!;
  const debouncedSearchQuery: string = useDebounce(searchQuery ?? '', 500);
  const sortableColumns = payoutsSortableIds;
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [showTransactionDetailsModal, toggleShowTransactionDetailsModal] =
    useState<{ show: boolean; payout: Payout | undefined }>({
      show: false,
      payout: undefined,
    });

  const queryKey: QueryKey = useMemo(
    () => [
      'getPayouts',
      debouncedSearchQuery,
      page,
      pageLimit,
      selectedHouse,
      selectedColumnToSort,
      sortDirection,
      startDate,
      endDate,
      selectedStatus,
    ],
    [
      debouncedSearchQuery,
      endDate,
      page,
      pageLimit,
      selectedHouse,
      selectedColumnToSort,
      selectedStatus,
      sortDirection,
      startDate,
    ]
  );

  const fetch = useQuery({
    queryKey,
    queryFn: () =>
      getPayouts({
        ...(pageLimit && { page_limit: pageLimit }),
        ...(page && { page }),
        ...(debouncedSearchQuery &&
          debouncedSearchQuery !== '' && { query: debouncedSearchQuery }),
        ...(sortDirection && { sort_direction: sortDirection }),
        ...(selectedColumnToSort && { sort: selectedColumnToSort }),
        ...(startDate && { start_date: startDate }),
        ...(endDate && { end_date: endDate }),
        ...(selectedStatus && {
          status: selectedStatus.status,
        }),
        ...(selectedHouse && {
          house_id: selectedHouse.id,
        }),
      }),
    keepPreviousData: true,
  });

  useEffect(() => {
    setPaginationResponse(
      fetch.data?.data.pagination
        ? { pagination: fetch.data?.data.pagination }
        : undefined
    );
  }, [fetch.data?.data.pagination, setPaginationResponse]);

  const updateOutgoingTransactionsInCache = useCallback(
    (transactions: Array<Payout>) => {
      queryClient.setQueryData(
        queryKey,
        (oldData: AxiosResponse<GetPayoutsResponse, any> | undefined) => {
          if (oldData?.data) {
            let oldDataCopy = { ...oldData };
            const newData = oldDataCopy.data.data.map((mappedRent) => {
              const foundTransaction = transactions.find(
                (transaction) => transaction.id === mappedRent.id
              );
              return foundTransaction
                ? { ...mappedRent, ...foundTransaction }
                : mappedRent;
            });
            oldDataCopy.data = { ...oldDataCopy.data, data: newData };
            return oldDataCopy;
          }
          return oldData;
        }
      );
    },
    [queryKey]
  );

  return {
    pageLimit,
    setPageLimit,
    fetch,
    setPage,
    sortableColumns,
    selectedColumnToSort,
    setColumnToSort,
    sortDirection,
    resultSummary,
    rowSelection,
    setRowSelection,
    showTransactionDetailsModal,
    toggleShowTransactionDetailsModal,
    updateOutgoingTransactionsInCache,
  };
};

const GetOutgoingTransactionsListContext = createContext<
  ReturnType<typeof useGetOutgoingTransactionsList> | undefined
>(undefined);

const GetOutgoingTransactionsListContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const hook = useGetOutgoingTransactionsList();

  return (
    <GetOutgoingTransactionsListContext.Provider value={hook}>
      {children}
    </GetOutgoingTransactionsListContext.Provider>
  );
};

const useGetOutgoingTransactionsListContext = () => {
  const context = useContext(GetOutgoingTransactionsListContext)!;

  if (context === null) {
    throw new Error(
      'useGetOutgoingTransactionsListContext must be used within a GetOutgoingTransactionsListContextProvider'
    );
  }

  return context;
};

export {
  GetOutgoingTransactionsListContextProvider,
  useGetOutgoingTransactionsListContext,
};
