// eslint-disable-next-line import/prefer-default-export
export const getAvatarChars = (firstName, lastName) => {
  let chars = '';

  if (firstName) {
    chars += firstName.charAt(0).toUpperCase();
  }

  if (lastName) {
    chars += lastName.charAt(0).toUpperCase();
  }

  return chars;
};
