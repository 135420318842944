import { format } from 'date-fns';
import { fr, enGB, nl } from 'date-fns/locale';
import i18n from 'i18next';

const locales = {
  en: enGB,
  fr,
  nl,
};

const locToUse = () => {
  const lang = i18n.language;

  switch (lang) {
    case 'fr':
      return locales.fr;
    case 'nl':
      return locales.nl;
    default:
      return locales.en;
  }
};

/**
 * Returns full month name. e.g (January, February etc.)
 *
 */
const getFullMonthName = (date: Date): string => {
  return format(date, 'MMMM', {
    locale: locToUse(),
  });
};

/**
 * Returns full year number. e.g (2023, 1984 etc.)
 *
 */
const getFullYearNumber = (date: Date): string => {
  return format(date, 'yyyy', {
    locale: locToUse(),
  });
};

/**
 * Returns display date format. e.g 29/03/2023
 *
 */
const getFullDate = (date: Date): string => {
  return format(date, 'dd/MM/yyyy');
};

/**
 * Returns full date in format for api use. e.g 2023-03-29
 *
 */
const getFullDateAPIFormat = (date: Date): string => {
  return format(date, 'yyyy-MM-dd');
};


/**
 * Return date time in format for api use. e.g 09:38 AM, 18 Aug 2024
 *
*/
const prettyDateTime = (date: Date): string => {
  return format(date, 'hh:mm a, dd MMM yyyy');
}

export {
  getFullMonthName,
  getFullYearNumber,
  getFullDateAPIFormat,
  getFullDate,
  prettyDateTime
};
