import React, { createContext, useContext, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getUser } from '@/services/coloc-api/auth';

const useAuth = () => {
  const fetchUser = useQuery({
    queryKey: ['getUser'],
    queryFn: () => getUser(),
  });

  const user = useMemo(() => {
    if (fetchUser.data?.data.data.attributes) {
      return fetchUser.data?.data.data.attributes;
    }

    return undefined;
  }, [fetchUser.data]);

  const roleChecks = useMemo(() => {
    return {
      isSuperAdmin: user?.type === 'AdminUser' && user?.role === 'superadmin',
      isPropertyManager:
        user?.type === 'AdminUser' && user?.role === 'property_manager',
    };
  }, [user]);


  return {
    user,
    userFetchingStatus: fetchUser.status,
    fetchUser: fetchUser.refetch,
    ...roleChecks,
  };
};

const AuthContext = createContext<ReturnType<typeof useAuth> | undefined>(
  undefined
);

const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const hook = useAuth();

  return <AuthContext.Provider value={hook}>{children}</AuthContext.Provider>;
};

const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (context === null) {
    throw new Error('useAuthContext must be used within a AuthContextProvider');
  }

  return context;
};

export { useAuthContext, AuthContextProvider };
