import React from 'react';
import { ReactSVG } from 'react-svg';
import { ColocIconProps } from './types';
import { composeClassName } from '../../../../../utils/util_helpers';

const ColocIcon: React.FC<ColocIconProps> = ({
  name,
  size = 24,
  className,
  ...props
}) => {
  return (
    <ReactSVG
      data-testid={name}
      src={require(`@assets/icons/coloc-icons/${name}.svg`)}
      className={composeClassName(
        'inline-block align-baseline',
        className && className
      )}
      beforeInjection={(svg) => {
        svg.setAttribute('width', `${size}`);
        svg.setAttribute('height', `${size}`);
      }}
      {...props}
    />
  );
};

export { ColocIcon };
