import { Tab } from '@headlessui/react';
import React from 'react';
import { isElement } from 'react-dom/test-utils';
import { composeClassName } from '@/utils/util_helpers';
import { TabsScreensProps } from './types';

const TabsScreens: React.FC<TabsScreensProps> = ({ children, className }) => {
  return (
    <Tab.Panels
      className={composeClassName('h-full w-full', className && className)}
    >
      {isElement(children)
        ? ({ selectedIndex }) => {
            return React.Children.map(
              children as unknown as React.ReactElement,
              (child: React.ReactElement) => {
                return {
                  ...child,
                  props: {
                    ...child.props,
                    selectedIndex,
                  },
                };
              }
            ) as unknown as React.ReactElement;
          }
        : children}
    </Tab.Panels>
  );
};

export { TabsScreens };
