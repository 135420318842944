/* eslint-disable react/jsx-no-constructed-context-values */
import { useMemo } from 'react';

type UseHelpOrErrorMessageProps = {
  helpMessage: string | undefined;
  showHelpMessage: boolean;
  invalid: boolean;
  errorMessage: string | undefined;
  showErrorMessage: boolean;
};

export const useHelpOrErrorMessage = (
  input: UseHelpOrErrorMessageProps
): {
  helpOrErrorMessage?: string;
  showHelpOrErrorMessage: boolean;
} => {
  const {
    errorMessage,
    showErrorMessage,
    invalid,
    helpMessage,
    showHelpMessage,
  } = input;

  const helpOrErrorMessage = useMemo(() => {
    if (errorMessage && showErrorMessage && invalid) {
      return errorMessage;
    }

    if (helpMessage && showHelpMessage) {
      return helpMessage;
    }
    return undefined;
  }, [errorMessage, showErrorMessage, invalid, helpMessage, showHelpMessage]);

  const showHelpOrErrorMessage = useMemo(() => {
    if (errorMessage && showErrorMessage && invalid) {
      return true;
    }

    if (helpMessage && showHelpMessage) {
      return true;
    }

    return false;
  }, [errorMessage, showErrorMessage, invalid, helpMessage, showHelpMessage]);

  return { helpOrErrorMessage, showHelpOrErrorMessage };
};
