import { useMutation } from '@tanstack/react-query';

import { DeleteRentTransactionParams } from '@/lib/coloc-api/admin';
import { deleteRentTransaction } from '@/services/coloc-api/admin';
import { showToast } from '@/services/toast-service';
import { useGetRentsListContext } from '../../use-get-rents-list';

const useDeleteRentTransaction = () => {
  const { toggleShowDeleteRentTransactionModal, updateRentInCache } =
    useGetRentsListContext()!;

  const mutate = useMutation({
    mutationKey: ['deleteRentTransaction'],
    mutationFn: (params: DeleteRentTransactionParams) => {
      return deleteRentTransaction(params);
    },
    onSuccess: (data) => {
      data.data.rents.forEach((rent) => {
        updateRentInCache(rent);
      })
      toggleShowDeleteRentTransactionModal({
        show: false,
        transaction: undefined,
      });
      showToast.success({});
    },
    onError: () => {
      toggleShowDeleteRentTransactionModal({
        show: false,
        transaction: undefined,
      });
      showToast.error({});
    },
  });

  return {
    mutate,
  };
};

export { useDeleteRentTransaction };
