import { Combobox } from '@headlessui/react';
import React, { useEffect } from 'react';
import { theme } from '../../../../../../utils/util_theme';
import { IconSaxIcon } from '../../../../atoms/icon';
import { afterTextInputStyle } from '../../../../shared-styles';
import { useDropDownContext } from '../../hooks/use-dropdown-context';
import { DropDownClearInputButton } from '../dropdown-clear-input-button';
import { DropDownInput } from '../dropdown-input';
import { DropDownInputWrapper } from '../dropdown-input-wrapper';
import { DropDownInputContainerProps } from './types';

const DropDownInputContainer: React.FC<DropDownInputContainerProps> = ({
  children,
  contentBefore,
  open,
}) => {
  const { multiple, disabled, clearable, onChange, setOptionsOpen, id } =
    useDropDownContext()!;

  useEffect(() => {
    setOptionsOpen(open);
  }, [open, setOptionsOpen]);

  return (
    <DropDownInputWrapper>
      {multiple ? (
        children
      ) : (
        <DropDownInput contentBefore={contentBefore} className="h-6 pl-3" />
      )}
      {clearable && <DropDownClearInputButton onClick={onChange} />}
      <Combobox.Button className={afterTextInputStyle} id={id}>
        <IconSaxIcon
          name="arrow-down"
          strokeColor={
            // eslint-disable-next-line no-nested-ternary
            open
              ? theme?.colors?.R300
              : disabled
              ? theme?.colors?.E75
              : theme?.colors?.text?.primary
          }
        />
      </Combobox.Button>
    </DropDownInputWrapper>
  );
};

export { DropDownInputContainer };
