import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SlideOver } from '@/components/design-system/molecules/slide-over';
import { ApplicantsOverviewSlideOverProps } from './types';
import { ApplicantPreviewCard } from './components/applicant-preview-card';
import { ApplicantFilters } from './components/applicant-filters';
import { CHAT_URL } from '@/config';
import { PopUp } from '@/components/design-system/molecules/pop-up';
import { RoomApplicant } from '../../types';
import { Modal } from '@/components/design-system/atoms/modal';
import { EmptyState } from '@/components/design-system/atoms/empty-state';

const ApplicantsOverviewSlideOver: React.FC<ApplicantsOverviewSlideOverProps> =
  ({
    close,
    assignment,
    show,
    onAccept,
    onDecline,
    onEditMoveIn,
    updatingApplication
  }) => {
    const { t } = useTranslation()!;
    const applicants = useMemo(
      () => assignment?.applications ?? [],
      [assignment]
    );
    const isAssignmentApplicantAccepted =
      assignment?.assignment_status?.status === 'searching_applicants';

    const [filters, setFilters] = useState({
      search: undefined,
      status: undefined,
    });
    const [showAccept, toggleShowAccept] = useState<{
      applicant: RoomApplicant | undefined;
      show: boolean;
    }>({ applicant: undefined, show: false });
    const [showDecline, toggleShowDecline] = useState<{
      applicant: RoomApplicant | undefined;
      show: boolean;
    }>({ applicant: undefined, show: false });

    useEffect(() => {
      toggleShowAccept({ applicant: undefined, show: false });
      toggleShowDecline({ applicant: undefined, show: false });
    }, [updatingApplication]);

    const filteredApplicants = useMemo(() => {
      let toFilterApplicants = applicants;

      if (filters.search) {
        toFilterApplicants = toFilterApplicants.filter((applicant) => {
          return applicant.user.name.toLowerCase().includes(
            filters.search.toLowerCase()
          );
        });
      }

      if (filters.status) {
        toFilterApplicants = toFilterApplicants.filter((applicant) => {
          return applicant.contract.status === filters.status;
        });
      }

      return toFilterApplicants;
    }, [applicants, filters.search, filters.status]);

    const roomNumber = useMemo(() => {
      const room = assignment?.room;
      return `#${room?.room_number} - ${room?.house?.name}`;
    }, [assignment]);

    return (
      <>
        {showAccept && showAccept.applicant !== undefined && showAccept.show && (
          <Modal
            open={showAccept.show}
            onClose={() =>
              toggleShowAccept({ applicant: undefined, show: false })
            }
          >
            <PopUp
              title={t(
                'js.features.admin.contracts.applicant_overview.accept_modal.title'
              )}
              type="default"
              description={showAccept.applicant.user.name}
              primaryButtonProps={{
                title:
                  t(
                    'js.features.admin.contracts.applicant_overview.accept_modal.accept_button'
                  ) ?? '',
                className: '!bg-J300',
                nestedContainerClassName: '!text-white',
                onClick: () => onAccept(showAccept.applicant!),
                disabled: updatingApplication,
                loading: updatingApplication,
              }}
              secondaryButtonProps={{
                title:
                  t(
                    'js.features.admin.contracts.applicant_overview.accept_modal.cancel_button'
                  ) ?? '',
                variant: 'ghost',
                onClick: () =>
                  toggleShowAccept({ applicant: undefined, show: false }),
              }}
            />
          </Modal>
        )}

        {showDecline &&
          showDecline.applicant !== undefined &&
          showDecline.show && (
            <Modal
              open={showDecline.show}
              onClose={() =>
                toggleShowAccept({ applicant: undefined, show: false })
              }
            >
              <PopUp
                title={t(
                  'js.features.admin.contracts.applicant_overview.decline_modal.title'
                )}
                type="default"
                description={showDecline.applicant.user.name}
                primaryButtonProps={{
                  title:
                    t(
                      'js.features.admin.contracts.applicant_overview.decline_modal.decline_button'
                    ) ?? '',
                  className: '!bg-C300',
                  nestedContainerClassName: '!text-white',
                  onClick: () => onDecline(showDecline.applicant!),
                  disabled: updatingApplication,
                  loading: updatingApplication,
                }}
                secondaryButtonProps={{
                  title:
                    t(
                      'js.features.admin.contracts.applicant_overview.decline_modal.cancel_button'
                    ) ?? '',
                  variant: 'ghost',
                  onClick: () =>
                    toggleShowDecline({ applicant: undefined, show: false }),
                }}
              />
            </Modal>
          )}

        <SlideOver
          show={show}
          title="Applicants"
          subtitle={roomNumber}
          onClose={() => close()}
          className="!z-50"
        >
          <SlideOver.SlideOverContent className="items-center gap-5">
            {applicants.length === 0 && (
              <div className="flex flex-col items-center justify-center w-full h-full gap-5">
                <EmptyState type="NoResults" text="No applicants yet" size={125} />
                <p className="text-center text-lead2 text-text-primaryMedium">
                  There are currently no applicants for the room.
                  <br/>
                  Stay tuned for updates
                </p>
              </div>
            )}

            {applicants.length > 0 && (
              <>
                <ApplicantFilters
                  value={filters}
                  onChange={(value) => setFilters(value)}
                />
                <>
                  { filteredApplicants.length === 0 && (
                    <>
                      <EmptyState type="NoResults" text="No Results" />
                      <p className="text-center text-lead2 text-text-primaryMedium">
                        Try change the selected filter or clear the search.
                      </p>
                      <button
                        className="text-medium text-text-primaryMedium underline"
                        onClick={() => setFilters({ search: undefined, status: undefined })}
                      >
                        Reset filters
                      </button>
                    </>
                  )}
                </>
                <>
                  { filteredApplicants.length > 0 && (
                    filteredApplicants.map((applicant) => (
                      <ApplicantPreviewCard
                        key={applicant.user.id}
                        applicant={applicant}
                        chat_url={CHAT_URL}
                        isActionsEnabled={isAssignmentApplicantAccepted}
                        onAccept={() => toggleShowAccept({ show: true, applicant })}
                        onDecline={() => toggleShowDecline({ show: true, applicant })}
                        onEditMoveIn={() => onEditMoveIn(applicant)}
                      />
                    ))
                  )}
                </>
              </>
            )}

          </SlideOver.SlideOverContent>
        </SlideOver>
      </>
    );
  };

export { ApplicantsOverviewSlideOver };
