import React, { Children, isValidElement, useMemo } from 'react';
import { composeClassName } from '@/utils/util_helpers';
import { MobileSideMenuOptionsProps, Slots } from './types';
import { MobileSideMenuOption } from '../mobile-side-menu-option';

const MobileSideMenuOptions: React.FC<MobileSideMenuOptionsProps> = ({
  children,
  className,
}) => {
  const filledSlots = useMemo(() => {
    const slots: Slots = {
      options: [],
      destructiveOptions: [],
    };

    Children.toArray(children).forEach((child) => {
      if (!isValidElement(child)) {
        return;
      }

      switch (child.type) {
        case MobileSideMenuOption:
          if (child.props.destructive) {
            slots.destructiveOptions = [...slots.destructiveOptions, child];
          } else {
            slots.options = [...slots.options, child];
          }
          break;
        default:
      }
    });

    return slots;
  }, [children]);

  return (
    <div
      className={composeClassName(
        'flex flex-col gap-3',
        className && className
      )}
    >
      {filledSlots.options}
      {/* separation line between normal and destructive options */}
      {filledSlots.destructiveOptions.length >= 1 &&
        filledSlots.options.length >= 1 && (
          <div className="h-[1px] w-full bg-E50" />
        )}
      {filledSlots.destructiveOptions}
    </div>
  );
};

export { MobileSideMenuOptions };
