import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/toast';
import 'bootstrap/js/dist/tooltip';
import 'bootstrap/js/dist/popover';
import 'bootstrap';

// eslint-disable-next-line no-undef
$('.toast').toast('show');

window.addEventListener('turbolinks:load', () => {
  // eslint-disable-next-line no-undef
  $('.toast').toast();
  // eslint-disable-next-line no-undef
  $('.toast').toast('show');

  // eslint-disable-next-line no-undef
  $('[data-toggle="tooltip"]').tooltip();
  // eslint-disable-next-line no-undef
  $('[data-toggle="tooltip"][data-tooltip-onload="true"]').tooltip('show');

  // eslint-disable-next-line no-undef
  $('[data-toggle="popover"]').popover({
    sanitizeFn: (content) => content,
  });
  // eslint-disable-next-line no-undef
  $('[data-toggle="popover"][data-popover-onload="true"]').popover('show');
  // eslint-disable-next-line no-undef
  $('[data-toggle="popover"]').on('shown.bs.popover', () => {
    // eslint-disable-next-line no-undef
    $('.popover [data-dismiss="popover"]').click((e) => {
      // eslint-disable-next-line no-undef
      $(e.target.closest('.popover')).popover('hide');
    });
  });

  $('[data-bs-toggle="dropdown"]').dropdown();
  $(document).on('click', (e) => {
    $('[data-bs-toggle="dropdown"]')
      .dropdown('hide')
      .attr('aria-expanded', false);
  });
});

const styles = getComputedStyle(document.documentElement);

export default {
  primary: styles.getPropertyValue('--primary'),
  secondary: styles.getPropertyValue('--secondary'),
  success: styles.getPropertyValue('--success'),
  warning: styles.getPropertyValue('--warning'),
  danger: styles.getPropertyValue('--danger'),
  info: styles.getPropertyValue('--info'),
  light: styles.getPropertyValue('--light'),
  dark: styles.getPropertyValue('--dark'),
  white: styles.getPropertyValue('--white'),
  black: styles.getPropertyValue('--black'),
  gray: styles.getPropertyValue('--gray'),
  closePopoversAndTooltips: () => {
    // eslint-disable-next-line no-undef
    $('.popover').popover('hide');
    // eslint-disable-next-line no-undef
    $('.tooltip').tooltip('hide');
  },
};
