import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/design-system/atoms/button';
import { useCreateRentReportContext } from '../../hooks/use-create-rent-report';

const CreateRentReportButton = () => {
  const { t } = useTranslation();
  const { toggleShowCreateRentReportModal } = useCreateRentReportContext()!;

  return (
    <div className="absolute right-[0px] top-[-32px] z-50">
      <Button onClick={() => toggleShowCreateRentReportModal({ show: true })}>
        {t('js.features.admin.rent_report.create_report_button') ?? ''}
      </Button>
    </div>
  );
};

export { CreateRentReportButton };
