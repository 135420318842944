/* eslint import/prefer-default-export: 0 */

export const colors = [
  '#D8526F',
  '#FB700B',
  '#FD5061',
  '#66A3BF',
  '#EFBF5F',
  '#DAB823',
  '#842000',
  '#0F7C4D',
  '#A98FE9',
  '#42798E',
  '#D14A63',
  '#E1DBB9',
  '#E5B143',
  '#937AD9',
  '#738E7D',
  '#FD8153',
  '#B43500',
  '#8E91A0',
  '#034632',
  '#99C48C',
  '#496557',
  '#E984BD',
  '#C23F01',
  '#F5CDCD',
  '#438E94',
  '#4F5474',
  '#D3C69A',
  '#ABD5A7',
  '#684959',
  '#E84D55',
  '#5D99E9',
  '#664D84',
  '#AD7C1E',
  '#9C6773',
  '#ACC2D7',
  '#485752',
  '#9F2500',
  '#B4DBD4',
  '#155280',
  '#2C4945',
  '#FB7665',
  '#98C0D7',
  '#822B40',
  '#9DD168',
  '#8FBC7B',
  '#DE5602',
  '#FFD195',
  '#FFCC51',
  '#3C8FAF',
  '#A82E01',
  '#89BE52',
  '#75BEB3',
  '#1D446F',
  '#71758E',
  '#DBAD26',
  '#97A46F',
  '#E16984',
  '#F3A747',
  '#855B71',
  '#D470AA',
  '#076741',
  '#41425E',
  '#4A86D9',
  '#2B5C7D',
];
