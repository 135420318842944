import React from 'react';
import { TabDefaultContentProps, withoutPassedTabProps } from './types';
import { composeClassName } from '../../../../../../utils/util_helpers';

const TabDefaultContentDefault: React.FC<TabDefaultContentProps> = ({
  children,
  disabled = false,
  selected = false,
  className,
}) => {
  return (
    <div
      className={composeClassName(
        'flex flex-col gap-1',
        className && className
      )}
    >
      <span
        className={composeClassName(
          'text-lead2 text-text-tertiary hover:text-text-secondary',
          selected &&
            '!hover:text-text-secondary font-medium !text-text-primary',
          disabled && '!text-E75'
        )}
      >
        {children}
      </span>
      <div
        className={composeClassName(
          'h-[3px] w-full rounded-full bg-transparent',
          selected && '!bg-R300',
          disabled && selected && '!bg-E75'
        )}
      />
    </div>
  );
};

const TabDefaultContent = withoutPassedTabProps<TabDefaultContentProps>(
  TabDefaultContentDefault
);

export { TabDefaultContent };
