import './react_front-end.scss';

import 'src/vendor/rails';
import './i18n';

// Support component names relative to this directory:
const componentRequireContext = require.context('src', true);
const ReactRailsUJS = require('react_ujs');

// eslint-disable-next-line react-hooks/rules-of-hooks
ReactRailsUJS.useContext(componentRequireContext);
