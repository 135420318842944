import { createElement } from './dom.service';

export default class Loader {
  constructor(parentElement) {
    this.container = parentElement;
    this.render();
  }

  destroy() {
    this.container.removeChild(this.loader);
  }

  render() {
    this.loader = createElement('div', this.html);
    this.loader.classList = 'flexbox flexbox-center layer layer-overlay';
    this.container.appendChild(this.loader);
  }

  get html() {
    return `
      <div class="spinner-primary spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    `;
  }
}
