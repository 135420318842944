<template>
  <div class="ui-text-input__container">
    <label
      :for="label"
      :class="[
        'ui-text-input__label',
        disabled && 'ui-text-input__label--disabled',
        !showLabel && 'ui-text-input__label--hidden',
      ]"
      >{{ label }}</label
    >
    <div class="ui-text-input__wrapper">
      <div v-if="`$slots.left_component`" class="ui-text-input__left-container">
        <slot name="left_component" />
      </div>
      <input
        :id="label"
        v-model="model"
        :class="textInputStyling"
        :type="[secureTextEntry ? 'password' : 'text']"
        :placeholder="placeholder"
        oninvalid="toggleFieldInvalid"
        :disabled="disabled"
        :readonly="readOnly"
      />
      <div
        v-if="`$slots.right_component`"
        class="ui-text-input__right-container"
      >
        <slot name="right_component"></slot>
      </div>
    </div>
    <span
      v-if="showHelpOrErrorMessage"
      :class="[
        'ui-text-input__help-text',
        invalid && 'ui-text-input__help-text--error',
      ]"
      >{{ helpOrErrorMessage }}</span
    >
  </div>
</template>

<script>
import '../Text.scss';
import './TextInput.scss';

export default {
  props: {
    /**
     * label of the input. Required for accessibility reasons.
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * Show the label above the input.
     */
    showLabel: {
      type: Boolean,
      default: true,
    },
    /**
     * Help message for input field.
     */
    helpMessage: {
      type: String,
    },
    /**
     * Show help message for input field.
     */
    showHelpMessage: {
      type: Boolean,
      default: true,
    },
    /**
     * Is input disabled or not.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Is input valid or not.
     */
    invalid: {
      type: Boolean,
      default: false,
    },
    /**
     * Error message to show when field is invalid.
     */
    errorMessage: {
      type: String,
    },
    /**
     * Show error message when field is invalid.
     */
    showErrorMessage: {
      type: Boolean,
      default: true,
    },
    /**
     * Should the input value be hidden.
     */
    secureTextEntry: {
      type: Boolean,
      default: false,
    },
    /**
     * Placeholder of the input.
     */
    placeholder: {
      type: String,
      required: false,
    },
    /**
     * Text field is not editable by the user.
     */
    readOnly: {
      type: Boolean,
      required: false,
    },
    /**
     * Value of the input.
     */
    value: {
      type: String,
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    showHelpOrErrorMessage() {
      if (this.errorMessage && this.showErrorMessage && this.invalid) {
        return true;
      }

      if (this.helpMessage && this.showHelpMessage) {
        return true;
      }

      return false;
    },
    helpOrErrorMessage() {
      if (this.errorMessage && this.showErrorMessage && this.invalid) {
        return this.errorMessage;
      }

      if (this.helpMessage && this.showHelpMessage) {
        return this.helpMessage;
      }

      return null;
    },
    textInputStyling() {
      return [
        'ui-text-input',
        this.invalid && 'ui-text-input--error',
        this.disabled && 'ui-text-input--disabled',
        this.$slots.right_component?.[0] && 'ui-text-input--right_component',
        this.$slots.left_component?.[0] && 'ui-text-input--left_component',
      ];
    },
  },
};
</script>
