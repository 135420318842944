import React from 'react';
import { BaseComponent } from '../../../../../../types/global';

type PassedPropsByTab = {
  /**
   * Option disabled.
   *
   * @default false
   */
  disabled?: boolean;
  /**
   * Option selected.
   *
   * @default false
   */
  selected?: boolean;
};

type PropsWithoutPassedProps<TBaseProps> = Omit<
  TBaseProps,
  keyof PassedPropsByTab
>;

export type TabDefaultContentProps = BaseComponent &
  React.AriaAttributes &
  PassedPropsByTab & {
    /**
     * value of the tab.
     */
    children?: string;
  };

export type TabContentPropsWithoutPassedProps =
  PropsWithoutPassedProps<TabDefaultContentProps>;

export function withoutPassedTabProps<TProps extends PassedPropsByTab>(
  WrappedComponent: React.ComponentType<PropsWithoutPassedProps<TProps>>
) {
  // eslint-disable-next-line react/display-name
  return (props: PropsWithoutPassedProps<TProps>) => {
    return <WrappedComponent {...props} />;
  };
}
