import React from 'react';
import { OutgoingTransactionsRowProps } from './types';
import { composeClassName } from '@/utils/util_helpers';
import { Table } from '@/components/design-system/molecules/table';
import { OutgoingTransactionReceiverCellContent } from './components/outgoing-transaction-receiver-cell-content';
import { amountToString } from '@/utils/util_helpers/formatting/amount_to_string';
import { useTranslation } from 'react-i18next';

const OutgoingTransactionTableHeader: React.FC<{ children: string }> = ({
  children,
}) => {
  return (
    <th className="px-3 text-start text-footnote1 text-text-tertiary">
      {children}
    </th>
  );
};

const OutgoingTransactionsTable: React.FC<OutgoingTransactionsRowProps> = ({
  outgoingTransaction,
}) => {
  const { t } = useTranslation();

  return (
    <table className="w-1/2">
      <thead className="pb-3">
        <tr>
          <OutgoingTransactionTableHeader>
            {t(
              'js.features.admin.transactions.outgoing_transactions_list.sub_table_columns.receiver'
            )}
          </OutgoingTransactionTableHeader>
          <OutgoingTransactionTableHeader>
            {t(
              'js.features.admin.transactions.outgoing_transactions_list.sub_table_columns.amount'
            )}
          </OutgoingTransactionTableHeader>
        </tr>
      </thead>
      <tbody>
        {outgoingTransaction.transactions.map((transaction) => {
          return (
            <tr key={transaction.reference}>
              <Table.TableCell>
                <OutgoingTransactionReceiverCellContent
                  transaction={transaction}
                />
              </Table.TableCell>
              <Table.TableCell>
                <Table.TableCellDefaultContent
                  description={transaction.description}
                >
                  {amountToString(transaction.amount)}
                </Table.TableCellDefaultContent>
              </Table.TableCell>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const OutgoingTransactionsRow: React.FC<OutgoingTransactionsRowProps> = ({
  outgoingTransaction,
  index,
}) => {
  return (
    <div
      className={composeClassName(
        'w-full',
        index % 2 === 0 ? 'bg-white' : 'bg-E30'
      )}
    >
      <div className="flex w-full justify-center">
        <div className="h-[1px] w-full bg-E50" />
      </div>

      {outgoingTransaction.transactions.length >= 1 ? (
        <div className="my-3 mx-5">
          <OutgoingTransactionsTable
            outgoingTransaction={outgoingTransaction}
            index={index}
          />
        </div>
      ) : (
        <div className="flex items-center justify-center" />
      )}
    </div>
  );
};

export { OutgoingTransactionsRow };
