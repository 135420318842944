import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { composeClassName } from '@/utils/util_helpers';
import { getFullDate } from '@/utils/util_helpers/formatting/dates';
import { amountToString } from '@/utils/util_helpers/formatting/amount_to_string';
import { Button } from '@/components/design-system/atoms/button';
import { useAuthContext } from '@/hooks/use-auth';
import { Table } from '@/components/design-system/molecules/table';
import { useUnpairTransaction } from './hooks';
import { usePopUpConfirmation } from '@/features/shared/pop-up-confirmation/hooks';
import { capitalize } from '@/utils/util_helpers/formatting/capitalize';
import { RentTransaction, Rent } from '@/features/admin/rents/types';

export type RentTransactionsTableProps = {
  rent: Rent;
  data: Array<RentTransaction>;
  onDelete: (transaction: RentTransaction) => void;
  reload?: () => void;
};

const RentTransactionTableHeader: React.FC<{ children: string }> = ({
  children,
}) => {
  return (
    <th className="text-start text-footnote1 text-text-tertiary">{children}</th>
  );
};

const RentTransactionTableCell: React.FC<{ children: string }> = ({
  children,
}) => {
  return <td className="py-[12px] text-body text-text-primary">{children}</td>;
};

const RentTransactionsTable: React.FC<RentTransactionsTableProps> = ({
  rent,
  data,
  onDelete,
  reload = () => {},
}) => {
  const { t } = useTranslation();
  const { isSuperAdmin, isPropertyManager } = useAuthContext()!;
  const { mutate: unpairTransaction } = useUnpairTransaction();
  const { openPopUpConfirmation } = usePopUpConfirmation()!;

  const hasManualTransactions = useMemo(() => {
    return data.some((transaction) => transaction.manual);
  }, [data]);

  const isAbleToUnpair = useCallback(
    (transaction: RentTransaction) => {
      const overflowAmount =
        rent.unpaid_amount.cents < 0 ? Math.abs(rent.unpaid_amount.cents) : 0;
      const mightBeAnInCorrectedPair =
        transaction.amount.cents === overflowAmount;

      const isDisabledUnpairStatus = ['pending_payout', 'resolved'].includes(
        rent.status
      );

      return (
        (isSuperAdmin || isPropertyManager) &&
        !transaction.manual &&
        !hasManualTransactions &&
        (!isDisabledUnpairStatus || (isDisabledUnpairStatus && mightBeAnInCorrectedPair))
      );
    },
    [
      isSuperAdmin,
      isPropertyManager,
      hasManualTransactions,
      rent.status,
      rent.unpaid_amount,
    ]
  );

  return (
    <table className="w-full">
      <thead className="pb-3">
        <tr>
          <RentTransactionTableHeader>
            {t(
              'js.features.admin.rents.rent_transaction_row.table_columns.transaction_type'
            )}
          </RentTransactionTableHeader>
          <RentTransactionTableHeader>
            {t(
              'js.features.admin.rents.rent_transaction_row.table_columns.registered'
            )}
          </RentTransactionTableHeader>
          <RentTransactionTableHeader>
            {t(
              'js.features.admin.rents.rent_transaction_row.table_columns.added_by'
            )}
          </RentTransactionTableHeader>
          <RentTransactionTableHeader>
            From
          </RentTransactionTableHeader>
          <RentTransactionTableHeader>
            Bank Description
          </RentTransactionTableHeader>
          <RentTransactionTableHeader>
            {t(
              'js.features.admin.rents.rent_transaction_row.table_columns.date'
            )}
          </RentTransactionTableHeader>
          <RentTransactionTableHeader>
            {t(
              'js.features.admin.rents.rent_transaction_row.table_columns.amount'
            )}
          </RentTransactionTableHeader>
          <RentTransactionTableHeader>
          </RentTransactionTableHeader>
          {isSuperAdmin && (
            <RentTransactionTableHeader>
            </RentTransactionTableHeader>
          )}
        </tr>
      </thead>
      <tbody>
        {data.map((transaction, transaction_index) => {
          return (
            <tr
              key={transaction.id}
              className={composeClassName(
                'w-full',
                // eslint-disable-next-line no-unsafe-optional-chaining
                transaction_index < data.length - 1 &&
                  'border-opacity-10" border-b-[1px] border-solid border-E50'
              )}
            >
              <RentTransactionTableCell>
                {
                  transaction.pair_transaction_id ?
                    t(
                      `js.features.admin.rents.rent_transaction_row.status_transaction_type.transfer`
                    ) ?? ''
                  : (transaction.amount.cents >= 0 ? t(
                      `js.features.admin.rents.rent_transaction_row.status_transaction_type.payment`
                    ) ?? ''
                  : t(
                      `js.features.admin.rents.rent_transaction_row.status_transaction_type.refund`
                    ) ?? '')
                }
              </RentTransactionTableCell>
              <RentTransactionTableCell>
                {transaction.manual
                  ? t(
                      `js.features.admin.rents.rent_transaction_row.status_registered.manual`
                    ) ?? ''
                  : t(
                      `js.features.admin.rents.rent_transaction_row.status_registered.automatically`
                    ) ?? ''}
              </RentTransactionTableCell>
              <RentTransactionTableCell>
                {transaction.manual
                  ? `${transaction.admin_user.first_name} ${transaction.admin_user.last_name}`
                  : t(
                      `js.features.admin.rents.rent_transaction_row.status_added_by.${transaction.medium}`
                  ) ?? ''}

              </RentTransactionTableCell>
              <RentTransactionTableCell>
                <Table.TableCellDefaultContent
                  className="w-[150px]"
                  {...(transaction.ponto_transaction?.sender.account_number && {
                    description: capitalize(
                      transaction.ponto_transaction?.sender.account_number
                    ),
                  })}
                >
                  {transaction.ponto_transaction?.sender.account_name}
                </Table.TableCellDefaultContent>
              </RentTransactionTableCell>
              <RentTransactionTableCell>
                {transaction.ponto_transaction?.bank_description}
              </RentTransactionTableCell>

              <RentTransactionTableCell>
                {getFullDate(new Date(transaction.date))}
              </RentTransactionTableCell>
              <RentTransactionTableCell>
                {amountToString(transaction.amount)}
              </RentTransactionTableCell>
              <RentTransactionTableCell>
                <Button
                  variant="icon"
                  iconName="trash"
                  className={`h-full w-full items-center justify-center ${
                    transaction.can_delete ? '' : 'hidden'
                  }`}
                  onClick={() => onDelete(transaction)}
                />
                {isAbleToUnpair(transaction) && (
                  <Button
                    variant="link"
                    iconName="unlink"
                    className='items-center justify-center text-danger'
                    onClick={() => {
                      openPopUpConfirmation({
                        type: 'warning',
                        title: t(
                          'js.features.admin.rents.unpair_confirmation_modal.title'
                        ),
                        description: t(
                          'js.features.admin.rents.unpair_confirmation_modal.text'
                        ),
                        confirmLabel: t(
                          'js.features.admin.rents.unpair_confirmation_modal.confirm_button_label'
                        ),
                        cancelLabel: t(
                          'js.features.admin.rents.unpair_confirmation_modal.cancel_button_label'
                        ),
                        onConfirm: () => {
                          unpairTransaction.mutate(transaction.id, {
                            onSuccess: () => {
                              reload();
                            },
                          })
                        },
                      });
                    }}
                  >
                    Unpair
                  </Button>
                )}
               </RentTransactionTableCell>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export { RentTransactionsTable };
