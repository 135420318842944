<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="ui-tabs">
    <div class="ui-tabs__header">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        class="ui-tabs__header__tab"
        :class="{ 'ui-tabs__header__tab--active': activeTab === index }"
        @click="OnSelectTab(index)"
      >
        {{ tab.title }}
        <div v-if="activeTab === index" class="ui-tabs__border-selection" />
      </div>
    </div>
    <div class="ui-tabs__content">
      <slot />
    </div>
  </div>
</template>

<script>
import './Tabs.scss';

export default {
  props: {
    /**
     * Selected tab. Default to the first tab = 0.
     */
    selectedTab: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      activeTab: 0,
      tabs: [],
    };
  },
  watch: {
    selectedTab(newVal) {
      this.UpdateTab(newVal);
    },
  },
  mounted() {
    this.UpdateTab(this.selectedTab);
  },
  created() {
    this.tabs = this.$children;
  },
  methods: {
    UpdateTab(i) {
      this.activeTab = i;
      this.tabs.forEach((tab, index) => {
        tab.isActive = index === i;
      });
    },
    OnSelectTab(i) {
      this.$emit('onSelectTab', {
        index: i,
        previousIndex: this.activeTab,
      });
      this.UpdateTab(i);
    },
  },
};
</script>
