import React from 'react';
import { TableBulkActionProps } from './types';
import { Button } from '@/components/design-system/atoms/button';

const TableBulkAction: React.FC<TableBulkActionProps> = ({
  children,
  ...props
}) => {
  return (
    <Button variant="ghost" size="small" {...props}>
      {children}
    </Button>
  );
};

export { TableBulkAction };
