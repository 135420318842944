/* eslint no-multi-assign: 0 */

import { Controller } from 'stimulus';
import DataTable from 'vanilla-datatables';
import { parseDataset } from 'src/services/dom.service';

export default class extends Controller {
  connect() {
    this.dataTable = new DataTable(this.element, {
      ...parseDataset(this.element),
      // how many rows per page
      // fixedColumns: true,
      // fixedHeight: false,

      // Pagination
      // nextPrev: true,
      // firstLast: false,
      // prevText: "&lsaquo;",
      // nextText: "&rsaquo;",
      // firstText: "&laquo;",
      // lastText: "&raquo;",
      // ellipsisText: "&hellip;",
      // ascText: "▴",
      // descText: "▾",
      // truncatePager: true,
      // pagerDelta: 2,

      // enables sorting
      sortable: false,

      // enables live search
      searchable: false,

      header: true,
      footer: false,

      // Customise the display text
      labels: {
        perPage: false, // per-page dropdown label
        info: false, //
      },

      // Customise the layout
      // layout: {
      //   top: "{select}{search}",
      //   bottom: "{info}{pager}"
      // }
    });
  }
}
