import React from 'react';
import { useNotifications } from '@/features/shared/notifications/hooks';
import { ColocIcon } from '@/components/design-system/atoms/icon/coloc-icon';
import { useAuthContext } from '@/hooks/use-auth';

const NotificationButton = () => {
  const { openNotification, isNotificationEnabled } = useNotifications()!;
  const { user } = useAuthContext()!;
  const hasUnreadNotifications = user?.unread_notifications_count > 0;
  return (
    <button
      type="button"
      className="notification-button"
      onClick={openNotification}
    >
      {hasUnreadNotifications && (
        <div className="unread-notifications">
          {user?.unread_notifications_count}
        </div>
      )}
      {isNotificationEnabled && <ColocIcon name="bell-outline" size={18} />}
      {!isNotificationEnabled && (
        <ColocIcon name="bell-slash-outline" size={18} />
      )}
    </button>
  )
}


export { NotificationButton };
