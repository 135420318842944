import React from 'react';
import { Menu } from '@headlessui/react';
import { isElement } from 'react-dom/test-utils';
import { TableCellRowActionProps } from './types';
import { composeClassName } from '@/utils/util_helpers';

const TableCellRowAction: React.FC<TableCellRowActionProps> = ({
  disabled = false,
  loading = false,
  children,
  className,
  onClick,

}) => {
  return (
    <Menu.Item
      disabled={disabled}
      as="div"
      onClick={onClick}
      className={composeClassName(
        'relative cursor-pointer select-none',
        disabled && '!cursor-default',
        className && className
      )}
    >
      {loading ? (
        "...loading"
      ) : (
        isElement(children)
        ? ({ disabled: optionDisabled, active, close }) => {
            return React.Children.map(
              children as unknown as React.ReactElement,
              (child: React.ReactElement) => {
                return {
                  ...child,
                  props: {
                    ...child.props,
                    active,
                    disabled: optionDisabled,
                    close,
                  },
                };
              }
            ) as unknown as React.ReactElement;
          }
        : children
      )}
    </Menu.Item>
  );
};

export { TableCellRowAction };
