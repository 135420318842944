require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();

document.onreadystatechange = () => {
  switch (document.readyState) {
    case 'loading':
      break;
    case 'interactive':
      document.body.classList.add('loading');
      break;
    case 'complete':
      document.body.classList.add('loaded');
      break;
    default:
      break;
  }
};

document.addEventListener('turbolinks:load', () => {
  if (document.readyState === 'complete') {
    document.body.classList.add('loaded');
  }
});
