import React from 'react';

import { AnimationBlob } from './components/animation-blob';
import { PromoVideo } from './components/promo-video';
import { UpdatingTitleText } from './components/updating-title-text';
import { composeClassName } from '@/utils/util_helpers';
import { DEFAULT_CONTENT_WIDTH } from '../../../../../constants';
import { SearchBarHero } from './components/search-bar-hero';
import { SearchRoomContextProvider } from '@/features/marketplace/hooks/use-search-room';

const HeroSection = () => {
  return (
    <section className="hero-section">
      <div
        className={composeClassName(
          'm-auto flex flex-col items-center py-[6rem]',
          'sm:pt-[7.2rem]',
          'lg:flex-row lg:items-start lg:justify-between lg:gap-8 lg:py-[16.5rem]',
          DEFAULT_CONTENT_WIDTH
        )}
      >
        <div className="relative flex w-full flex-1 flex-col">
          <div className="relative z-10">
            <h1
              className={composeClassName(
                'flex flex-col gap-[3px] text-h3 font-bold leading-[3.7rem] text-text-primary',
                'sm:gap-[2px] sm:text-[4.8rem] sm:leading-[5.8rem]'
              )}
            >
              <span className="inline-block">Coloc Housing:</span>
              <span className="flex gap-1">
                Living <UpdatingTitleText />
              </span>
              <span className="inline-block">Together</span>
            </h1>
            <span
              className='mt-4 block text-h5 font-weight-500'
            >
              Promoting Togetherness
            </span>
          </div>
          {/* rounded circle header */}
          <div
            className={composeClassName(
              'absolute top-[-2.6rem] left-[-1.4rem] z-0 h-[7.2rem] w-[7.2rem] rounded-full bg-R300',
              'sm:top-[-3rem] sm:left-[-2rem] sm:h-[9.6rem] sm:w-[9.6rem]'
            )}
          />
          {/* Search bar */}
          <SearchRoomContextProvider>
            <SearchBarHero />
          </SearchRoomContextProvider>
        </div>
        <div
          className={composeClassName(
            'relative mt-[7.2rem] flex w-full flex-1 justify-end',
            'lg:mt-[0px]'
          )}
        />
      </div>
    </section>
  );
};

export { HeroSection };
