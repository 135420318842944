import { useMutation } from '@tanstack/react-query';

import { CreateDiscussionThreadMessageRequestBody } from '@/lib/coloc-api/admin';
import { createDiscussionThreadMessage } from '@/services/coloc-api/admin';
import { showToast } from '@/services/toast-service';
import { useDiscussionThreadProviderContext } from '../use-discussion-thread';

const useCreateThreadMessage = () => {
  const { showDiscussionThread, addDiscussionThreadMessage } =
    useDiscussionThreadProviderContext()!;

  const mutate = useMutation({
    mutationKey: ['createThreadMessage'],
    mutationFn: (params: CreateDiscussionThreadMessageRequestBody) => {
      return createDiscussionThreadMessage(
        {
          id: showDiscussionThread.discussionThreadId ?? 0,
        },
        {
          content: params.content,
          mention_ids: params.mention_ids,
      }
      );
    },
    onSuccess: (data) => {
      addDiscussionThreadMessage(data.data.discussion_message);
      showDiscussionThread.onMessageSent?.();
    },
    onError: () => {
      showToast.error({});
    },
  });

  return {
    mutate,
  };
};

export { useCreateThreadMessage };
