const requireIcons = require.context(
  '../../../../../../../assets/icons/icon-sax/linear',
  false,
  /.svg$/
);

const IconSaxIcons = {};

requireIcons.keys().forEach((fileName) => {
  const iconName = fileName.replace(/^\.\/(.+)\.svg$/, '$1');
  const componentConfig = requireIcons(fileName);
  IconSaxIcons[iconName] = componentConfig.default || componentConfig;
});

export default IconSaxIcons;
