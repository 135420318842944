import React from 'react';
import { Avatar } from '@/components/design-system/atoms/avatar';
import { useAuthContext } from '@/hooks/use-auth';
import { useNavigationMenuContext } from '@/components/design-system/molecules/navigation-menu/hooks/use-navigation-menu-context';

const CurrentUserInfo = () => {
  const { user } = useAuthContext()!;
  const { displayValue } = useNavigationMenuContext()!;

  return (
    <div className="flex items-center gap-1">
      <Avatar size="m" src={user?.avatar} type="person" />
      {displayValue === 'expanded' && (
        <div className="font-semibold">{user?.name}</div>
      )}
    </div>
  );
};

export { CurrentUserInfo };
