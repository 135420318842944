/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useContext } from 'react';
import {
  autoUpdate,
  offset,
  useFloating,
  flip,
  shift,
  useDelayGroupContext,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  useClick,
} from '@floating-ui/react';
import { PopOverProps } from '../../types';

type PopOverContextProviderProps = Pick<
  PopOverProps,
  | 'placement'
  | 'initialOpen'
  | 'onOpenChange'
  | 'open'
  | 'children'
  | 'controlMethod'
>;

type PopOverProviderProps = Omit<PopOverContextProviderProps, 'children'>;

const usePopOver = ({
  initialOpen = false,
  placement = 'bottom',
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  controlMethod = 'hover',
}: PopOverProviderProps) => {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const { delay } = useDelayGroupContext();

  const data = useFloating({
    // @ts-ignore
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offset(5), flip(), shift()],
  });

  const { context } = data;

  const hover = useHover(context, {
    move: false,
    enabled: controlledOpen == null && controlMethod === 'hover',
    delay,
  });
  const focus = useFocus(context, {
    enabled: controlledOpen == null,
  });
  const click = useClick(context, {
    enabled: controlledOpen == null && controlMethod === 'click',
  });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });

  const interactions = useInteractions(
    controlMethod === 'hover'
      ? [hover, focus, dismiss, role]
      : [click, focus, dismiss, role]
  );

  return React.useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
    }),
    [open, setOpen, interactions, data]
  );
};

type ContextType = ReturnType<typeof usePopOver> | null;

const PopOverContext = createContext<ContextType>(null);

const PopOverContextProvider: React.FC<PopOverContextProviderProps> = ({
  children,
  ...props
}) => {
  // @ts-ignore
  const popOverHook = usePopOver(props);

  return (
    <PopOverContext.Provider value={popOverHook}>
      {children}
    </PopOverContext.Provider>
  );
};

const usePopOverContext = () => {
  const context = useContext(PopOverContext);

  if (context === null) {
    throw new Error(
      'usePopOverContext must be used within a PopOverContextProvider'
    );
  }

  return context;
};

export { usePopOverContext, PopOverContextProvider };
