import React from 'react';
import { ReactSVG } from 'react-svg';
import { CountyIconProps, countryIconNames, CountryIconName } from './types';
import { composeClassName } from '../../../../../utils/util_helpers';

const isCountryIconName = (str: string): str is CountryIconName => {
  return [...countryIconNames].includes(str.toLocaleLowerCase() as CountryIconName);
};

const CountryIcon: React.FC<CountyIconProps> = ({
  name,
  size = 24,
  className,
  ...props
}) => {
  return (
    <ReactSVG
      data-testid={name}
      src={require(`@assets/icons/country-icons/${name.toLocaleLowerCase()}.svg`)}
      className={composeClassName(
        'inline-block align-baseline',
        className && className
      )}
      beforeInjection={(svg) => {
        svg.setAttribute('width', `${size}`);
        svg.setAttribute('height', `${size}`);
      }}
      {...props}
    />
  );
};

export { CountryIcon, isCountryIconName };
