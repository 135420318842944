import { useTranslation } from 'react-i18next';
import React from 'react';
import { DatePicker } from '@/components/design-system/molecules/datepicker';
import { SearchInput } from '@/components/design-system/molecules/search-input';

import { useRentsListFiltersContext } from '../../hooks/use-rents-list-filter';
import { ListRentStatusDropDown } from './components/list-rent-status-dropdown';
import { ListHouseSelectionDropDown } from './components/list-house-selection-dropdown';
import { ListRentRemindersDropDown } from './components/list-rent-reminders-dropdown';


const RentsListFilter = () => {
  const { setSearchQuery, searchQuery, setDateRange, startDate, endDate } =
    useRentsListFiltersContext()!;
  const { t } = useTranslation();

  return (
    <div className="relative flex w-full items-end justify-between pt-5">
      <div className="flex gap-4">
        <SearchInput
          placeholder={
            t('js.features.admin.rents.rent_list.search_placeholder') ?? ''
          }
          className="w-[250px]"
          label={t('js.features.admin.rents.shared.search_label') ?? ''}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <DatePicker
          className="w-[250px]"
          onChange={setDateRange}
          label={t('js.features.admin.rents.shared.date_range_label') ?? ''}
          dateRange
          type="month"
          startValue={startDate}
          endValue={endDate}
        />
        <ListRentStatusDropDown />
        <ListHouseSelectionDropDown />
        <ListRentRemindersDropDown />
      </div>
    </div>
  );
};

export { RentsListFilter };
