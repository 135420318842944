const removeFirstWord = (input: string): string => {
  const words = input.trim().split(' ');

  // Check if there are at least two words in the input
  if (words.length > 1) {
    words.shift(); // Remove the first word
    return words.join(' ').trim();
  }

  // If there is only one word or no words, return an empty string
  return '';
};

const removeTimeFromText = (text: string): string => {
  // Find the index of "uur" in the text
  const timeIndex = text.indexOf('uur');
  if (timeIndex !== -1) {
    /* Get the text from the beginning of the string until the index of "uur" - 7
     characters which covers the time in front of "uur" e.g 16:14 uur
     */
    return text.slice(0, timeIndex - 7);
  }

  // return original text if uur was not found.
  return text;
};

const returnStringAfterExtractingKeyword = (
  text: string,
  keyword: string
): string => {
  const keywordLength = keyword.length;

  const indexOfKeywordInText = text.indexOf(keyword);

  if (indexOfKeywordInText !== -1) {
    const slicedText = text.slice(indexOfKeywordInText + keywordLength).trim();
    if (keyword === 'bic:') {
      return removeFirstWord(slicedText);
    }
    return slicedText;
  }

  return text;
};

export const extractDescription = (text: string): string => {
  let descriptionText = text.toLowerCase();

  ['bic:', 'communication:', 'mededeling:', 'info:'].forEach((keyword) => {
    descriptionText = returnStringAfterExtractingKeyword(
      descriptionText,
      keyword
    );
  });

  return removeTimeFromText(descriptionText);
};
