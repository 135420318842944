import React, { useEffect, useState } from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useTranslation } from 'react-i18next';
import { ReactQueryProvider } from '@/providers/react-query-provider';
import { Tabs } from '@/components/design-system/molecules/tabs';
import { ToastProvider } from '@/providers/toast-provider';
import {
  AdminRoutes,
  AdminSideNavigation,
} from '@/features/admin/navigation/containers/admin-side-navigation';
import { RentsList } from '@/features/admin/rents/components/rents-list';
import { RentsSummary } from '@/features/admin/rents/components/rents-summary';
import { RentReportsList } from '@/features/admin/rents/components/report-list';
import { DiscussionThreadsProvider } from '@/features/shared/discussion-threads/providers';
import { AuthContextProvider } from '@/hooks/use-auth';
import { NotificationsProvider } from '@/features/shared/notifications/provider';
import { PopUpConfirmationProvider } from '@/features/shared/pop-up-confirmation/provider';

const RentsScreen = ({ ...props }) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(1);

  // Check for URL parameters
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const queryParams = Object.fromEntries(urlSearchParams.entries());
    const containsAll = queryParams?.all;

    if (containsAll && containsAll === 'true') {
      setSelectedTab(1);
    }
  }, []);

  return (
    <ReactQueryProvider>
      <ToastProvider />
      <AuthContextProvider>
        <DiscussionThreadsProvider>
          <NotificationsProvider>
          <PopUpConfirmationProvider>

          {/* Dev tools wil only be displayed during development */}
          <ReactQueryDevtools initialIsOpen={false} />
          <div className="flex h-screen w-screen">
            <AdminSideNavigation tabToSet={AdminRoutes.Rents} />
            <div className="flex h-full flex-1 flex-col overflow-hidden px-7 pt-7">
              <h1 className="pb-3 text-h3 font-bold text-text-primary">
                {t('js.features.admin.rents.title')}
              </h1>
              <div className="flex w-full flex-1 flex-col overflow-hidden bg-E10">
                <Tabs
                  selectedIndex={selectedTab}
                  onChange={(index) => setSelectedTab(Number(index))}
                >
                  <Tabs.TabsList>
                    <Tabs.Tab>
                      <Tabs.TabDefaultContent>
                        {t('js.features.admin.rents.rent_page_tabs.summary') ??
                          ''}
                      </Tabs.TabDefaultContent>
                    </Tabs.Tab>
                    <Tabs.Tab>
                      <Tabs.TabDefaultContent>
                        {t('js.features.admin.rents.rent_page_tabs.all') ?? ''}
                      </Tabs.TabDefaultContent>
                    </Tabs.Tab>
                    <Tabs.Tab>
                      <Tabs.TabDefaultContent>
                        {t('js.features.admin.rents.rent_page_tabs.reports') ??
                          ''}
                      </Tabs.TabDefaultContent>
                    </Tabs.Tab>
                  </Tabs.TabsList>
                  <Tabs.TabsScreens>
                    <Tabs.TabScreen>
                      <RentsSummary />
                    </Tabs.TabScreen>
                    <Tabs.TabScreen>
                      {props.contract_id &&
                        <div className="text-lead2 text-text-tertiary my-2">
                          Filtered by contract #{props.contract_id}
                        </div>
                      }
                      <RentsList />
                    </Tabs.TabScreen>
                    <Tabs.TabScreen className="relative">
                      <RentReportsList />
                    </Tabs.TabScreen>
                  </Tabs.TabsScreens>
                </Tabs>
              </div>
            </div>
          </div>

          </PopUpConfirmationProvider>
          </NotificationsProvider>
        </DiscussionThreadsProvider>

      </AuthContextProvider>
    </ReactQueryProvider>
  );
};

export { RentsScreen };
