import React from 'react';
import { useTranslation } from 'react-i18next';
import { SearchBarItemProps } from './types';

const SearchBarItem: React.FC<SearchBarItemProps> = ({ item }) => {
  const { t } = useTranslation();

  return (
    <a href={item.path}>
      <div className="flex w-full items-center">
        {item.image && (
          <img
            alt={item.name}
            src={item.image}
            width={40}
            height={40}
            className="mr-[10px] h-6 w-6 rounded"
          />
        )}
        <div className="flex w-full flex-col">
          <span className="text-lead2 font-medium leading-[2.4rem] text-text-secondary">
            {item.name}
          </span>
          {item.rooms_available ? (
            <span className="text-body leading-[2.1rem] text-J300">
              {item.rooms_available > 1
                ? t(
                    'js.features.marketplace.components.room_search.rooms_text',
                    { num_rooms: item.rooms_available }
                  )
                : t('js.features.marketplace.components.room_search.room_text')}
            </span>
          ) : (
            <></>
          )}
        </div>
      </div>
    </a>
  );
};

export { SearchBarItem };
