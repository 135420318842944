import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from '@/components/design-system/atoms/badge';
import { BadgeVariants } from '@/components/design-system/atoms/badge/types';

const RentReminderCellContent: React.FC<{ value: number }> = ({ value }) => {
  const { t } = useTranslation();
  const displayValue = t(
    `js.features.admin.rents.rent_list.reminder_options.${value}`
  );
  let variant: BadgeVariants = 'normal';
  if (value === 1) {
    variant = 'warning';
  } else if (value >= 2) {
    variant = 'danger';
  }
  return (
    <Badge label={displayValue} variant={variant}>
      {displayValue}
    </Badge>
  );
};

export { RentReminderCellContent };
