import { AvatarSize } from './types';

export const AvatarSizeMap: { [key in AvatarSize]: number } = {
  xs: 16,
  s: 24,
  m: 32,
  l: 40,
  xl: 64,
  xxl: 72,
  xxxl: 96,
  xxxxl: 128,
};

export const AvatarStatusIconSizeMap: { [key in AvatarSize]: number } = {
  xs: 10,
  s: 12,
  m: 14,
  l: 15,
  xl: 24,
  xxl: 27,
  xxxl: 30,
  xxxxl: 36,
};

export const AvatarSizeTailWindMap: { [key in AvatarSize]: string } = {
  xs: 'w-[1.6rem] h-[1.6rem]',
  s: 'w-[2.4rem] h-[2.4rem]',
  m: 'w-[3.2rem] h-[3.2rem]',
  l: 'w-[4rem] h-[4rem]',
  xl: 'w-[6.4rem] h-[6.4rem]',
  xxl: 'w-[7.2rem] h-[7.2rem]',
  xxxl: 'w-[9.6rem] h-[9.6rem]',
  xxxxl: 'w-[12.8rem] h-[12.8rem]',
};

export const AvatarInitialsSizeTailWindMap: { [key in AvatarSize]: string } = {
  xs: 'text-footnote2 font-medium',
  s: 'text-footnote1 font-medium',
  m: 'text-body font-medium',
  l: 'text-lead1 font-medium',
  xl: 'text-lead1 font-medium',
  xxl: 'text-h5 font-bold',
  xxxl: 'text-h3 font-bold',
  xxxxl: 'text-h1 font-bold',
};
