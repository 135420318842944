<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="control-group-radio">
    <label
      :class="[
        'ui-form-radio',
        'control-radio',
        disabled && 'ui-form-radio--disabled',
      ]"
    >
      {{ label }}
      <input
        type="radio"
        :name="name"
        :value="radioValue"
        :checked="radioChecked"
        :disabled="disabled"
        @input="changeValue"
      />
      <div class="control_indicator-radio"></div>
    </label>
  </div>
</template>

<script>
import './Radio.scss';

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    radioValue: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      radioChecked: false,
    };
  },
  watch: {
    checked(newVal) {
      this.UpdateCheckedStatus(newVal);
    },
  },
  mounted() {
    this.UpdateCheckedStatus(this.checked);
  },
  methods: {
    UpdateCheckedStatus(value) {
      this.radioChecked = value;
    },
    changeValue(e) {
      this.UpdateCheckedStatus(e.target.checked);
      this.$emit('input', { checked: e.target.checked, value: e.target.value });
    },
  },
};
</script>
