import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';

import { RoomAssignmentLabelProps } from './types';
import { composeClassName } from '@/utils/util_helpers';

const RoomAssignmentLabel: React.FC<RoomAssignmentLabelProps> = ({
  type,
  className,
}) => {
  const { t } = useTranslation();

  const labelTextColor: string = useMemo(() => {
    switch (type) {
      case 'new_room':
        return 'text-text-successHeavy';
      default:
        return 'text-text-infoHeavy';
    }
  }, [type]);

  const labelBorderColor: string = useMemo(() => {
    switch (type) {
      case 'new_room':
        return 'border-fill-icon-successHeavy';
      default:
        return 'border-fill-icon-infoHeavy';
    }
  }, [type]);

  const labelBGColor: string = useMemo(() => {
    switch (type) {
      case 'new_room':
        return 'bg-fill-surface-successLight';
      default:
        return 'bg-fill-surface-infoLight';
    }
  }, [type]);

  return (
    <div
      className={composeClassName(
        'flex items-center justify-center rounded-full border-[1px] border-solid',
        labelBorderColor,
        labelBGColor,
        className && className
      )}
    >
      <span
        className={composeClassName(
          'py-[3px] px-[10px] text-footnote2',
          labelTextColor
        )}
      >
        {t(
          `js.features.admin.contracts.room_assignment.table_cells.labels.${type}`
        )}
      </span>
    </div>
  );
};

export { RoomAssignmentLabel };
