import React from 'react';
import { composeClassName } from '@/utils/util_helpers';
import { CELL_HEIGHT } from '../../types';
import { TableCellProps } from './types';

const TableCell: React.FC<TableCellProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <td
      className={composeClassName(
        'min-w-6 max-w-[150px] whitespace-nowrap px-1 align-middle',
        `h-[${CELL_HEIGHT}px]`,
        className && className
      )}
      {...props}
    >
      {children}
    </td>
  );
};

export { TableCell };
