import React, { useEffect, useRef } from 'react';
import {
  SnapItem,
  SnapList,
  useDragToScroll,
  useScroll,
  useVisibleElements,
} from 'react-snaplist-carousel';
import { composeClassName } from '@/utils/util_helpers';
import { useThirdSectionContext } from '../../hooks/use-third-section-context';
import { InfoOption } from './components/info-option';
import { InfoImage } from '../info-image';

const InfoOptionsMobile = () => {
  const { options, currentOptionNum, setCurrentOption } =
    useThirdSectionContext()!;
  const snapList = useRef(null);
  const visible = useVisibleElements(
    { debounce: 50, ref: snapList },
    ([element]) => element
  );
  const goToChildren = useScroll({ ref: snapList });
  const { isDragging } = useDragToScroll({ ref: snapList });

  useEffect(() => {
    setCurrentOption(visible + 1);
  }, [setCurrentOption, visible]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentOptionNum !== visible + 1) {
        goToChildren(currentOptionNum - 1);
      }
    }, 150);
    return () => {
      clearInterval(intervalId);
    };
  }, [currentOptionNum, goToChildren, visible]);

  return (
    <SnapList
      ref={snapList}
      direction="horizontal"
      className={composeClassName('w-full pb-3', 'sm:pb-7')}
    >
      {Object.keys(options).map((key) => {
        return (
          <SnapItem key={key} snapAlign="center">
            <div
              className={composeClassName(
                'mt-10 flex w-screen flex-col justify-between',
                '[@media(min-width:500px)]:mt-[8.2rem]'
              )}
              style={{
                cursor: isDragging ? 'grabbing' : 'grab',
              }}
            >
              <InfoImage currentOption={options[Number(key)]} />
              <div
                className={composeClassName('flex mt-5 w-full flex-1 justify-center', 'md:mt-4')}
                style={{
                  cursor: isDragging ? 'grabbing' : 'grab',
                }}
              >
                <InfoOption option={options[Number(key)]} />
              </div>
            </div>
          </SnapItem>
        );
      })}
    </SnapList>
  );
};

export { InfoOptionsMobile };
