import { initializeApp } from 'firebase/app';
import {
  getMessaging,
  getToken,
  onMessage,
} from 'firebase/messaging';
import { EventBus } from './event.service';
import { FIREBASE_CONFIG } from '@/config';

// Initialize Firebase
export const firebaseApp = initializeApp(FIREBASE_CONFIG);

const requestPermission = async () => {
  const permission = await Notification.requestPermission();
  return permission === 'granted';
};


const registerServiceWorker = async () => {
  const configParams = new URLSearchParams(FIREBASE_CONFIG).toString();
  const swRegistration = await navigator.serviceWorker.register(`/firebase-messaging-sw.js?${configParams}`);
  let serviceWorker;
  if (swRegistration.installing) {
    serviceWorker = swRegistration.installing;
  } else if (swRegistration.waiting) {
    serviceWorker = swRegistration.waiting;
  } else if (swRegistration.active) {
    serviceWorker = swRegistration.active;
  }
  return serviceWorker;
};



const handleMessaging = async () => {
  const messaging = getMessaging();
  const fcmToken = await getToken(messaging, {
    serviceWorkerRegistration: await navigator.serviceWorker.ready,
  });

  const eventBus = new EventBus();

  onMessage(messaging, (payload) => {
    eventBus.emit('notification', payload);
  });

  return {
    fcmToken,
    eventBus,
  }
}


export const subscribeNotifications = async () => {
  const isGranted = await requestPermission();
  if (isGranted) {
    const serviceWorker = await registerServiceWorker();
    if (serviceWorker) {
      return handleMessaging();
    }
  }
};

export const pushNotification = async ({
  id,
  title,
  body,
  icon,
  image,
  click_action
}) => {
  if (Notification.permission === 'granted') {
    const registration = await navigator.serviceWorker.getRegistration();
    if (registration) {
      registration.showNotification(title, {
        tag: id,
        body,
        icon,
        image,
        data: {
          url: click_action
        }
      });
    }
  }
};
