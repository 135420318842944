import { useMutation } from '@tanstack/react-query';

import { useTranslation } from 'react-i18next';
import { showToast } from '@/services/toast-service';
import { getRoomAssignment, updateContract } from '@/services/coloc-api/admin';
import { useGetRoomsAssignmentContext } from '../../../room-assignment-list/hooks/use-get-room-assignment-list';

const useUpdateApplication = () => {
  const { t } = useTranslation();
  const { updateAssignmentInCache, setShowApplicants, showApplicants } =
    useGetRoomsAssignmentContext()!;

  const mutate = useMutation({
    mutationKey: ['updateApplication'],
    mutationFn: (params: {
      contract_id: string;
      status: 'accepted' | 'cancelled';
    }) => {
      return updateContract({
        path: {
          id: Number(params.contract_id),
        },
        params: {
          status: params.status,
        },
      });
    },
    onSuccess: async () => {
      try {
        const data = await getRoomAssignment({
          id: showApplicants.assignment?.id!,
        });
        updateAssignmentInCache(data.data);
        setShowApplicants({
          show: true,
          assignment: data.data,
        });
        showToast.success({
          text:
            t(
              `js.features.admin.contracts.applicant_overview.update_confirmation.text`
            ) ?? '',
        });
      } catch (error) {
        // Handle any errors that might occur during the API calls
      }
    },
    onError: () => {
      setShowApplicants({
        show: false,
        assignment: undefined,
      });
      showToast.error({});
    },
  });

  return {
    mutate,
  };
};

export { useUpdateApplication };
