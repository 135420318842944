import React from 'react';
import { DividerProps } from './types';

const Divider = ({ className }: DividerProps) => {
  return (
    <div
      className={`my-3 border-t border-solid border-gray-300 ${className}`}
    />
  );
}

export { Divider }
