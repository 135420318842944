/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { composeClassName } from '@/utils/util_helpers';
import { useThirdSectionContext } from '../../../../hooks/use-third-section-context';

const InfoOption: React.FC<{ num: number; option: string }> = ({
  option,
  num,
}) => {
  const { currentOptionNum, setCurrentOption } = useThirdSectionContext()!;
  const { t } = useTranslation();

  const isActive = useMemo(() => {
    return num === currentOptionNum;
  }, [num, currentOptionNum]);

  return (
    <div
      className={composeClassName(
        'flex flex-1 cursor-pointer justify-center border-b-[3px] border-solid border-white bg-white text-center',
        isActive && 'rounded-bl-[4rem] rounded-tr-[4rem] !border-R300 bg-E30'
      )}
      onClick={() => setCurrentOption(num)}
    >
      <div className="flex cursor-pointer flex-col items-center gap-[1.2rem] px-4 py-3">
        <ReactSVG
          src={require(`@assets/icons/home-page-info-icons/${option}.svg`)}
          className="inline-block align-baseline"
          beforeInjection={(svg) => {
            svg.setAttribute('width', `56`);
            svg.setAttribute('height', `56`);
          }}
        />
        <span className="text-lead1 font-medium leading-[2.7rem] text-text-primary">
          {t(`js.features.marketplace.home.section3.options.${option}_title`)}
        </span>
      </div>
    </div>
  );
};

export { InfoOption };
