/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { composeClassName } from '@/utils/util_helpers';
import { DEFAULT_CONTENT_WIDTH } from '@/features/marketplace/constants';
import { useWindowDimensions } from '@/hooks/use-window-dimensions';

const InfoOption: React.FC<{ option: string }> = ({ option }) => {
  const { screenIsLargeOrEqualThan } = useWindowDimensions();
  const { t } = useTranslation();

  return (
    <div className={composeClassName('w-screen')}>
      <div
        className={composeClassName(
          DEFAULT_CONTENT_WIDTH,
          'm-auto flex flex-col items-center gap-2 py-3',
          'md: gap-[12px]'
        )}
      >
        <ReactSVG
          src={require(`@assets/icons/home-page-info-icons/${option}.svg`)}
          className="inline-block align-baseline"
          beforeInjection={(svg) => {
            svg.setAttribute(
              'width',
              screenIsLargeOrEqualThan('md') ? `56` : `48`
            );
            svg.setAttribute(
              'height',
              screenIsLargeOrEqualThan('md') ? `56` : `48`
            );
          }}
        />
        <span
          className={composeClassName(
            'text-lead2 font-medium text-text-primary',
            'md:text-lead1'
          )}
        >
          {t(`js.features.marketplace.home.section3.options.${option}_title`)}
        </span>
        <span
          className={composeClassName(
            'text-center text-body text-text-tertiary',
            'md:text-lead2'
          )}
        >
          {t(`js.features.marketplace.home.section3.options.${option}_text`)}
        </span>
      </div>
    </div>
  );
};

export { InfoOption };
