import React from 'react';
import { TableHeaderProps } from './types';

const TableHeader: React.FC<TableHeaderProps> = ({ children }) => {
  return (
    <thead className="sticky top-[0px] z-10 w-full bg-E30">{children}</thead>
  );
};

export { TableHeader };
