/* eslint-disable react/prop-types */
import React, { isValidElement, useMemo, Children, useId } from 'react';
import { Combobox } from '@headlessui/react';
import { DropDownProps, DropDownSlots } from './types';
import { composeClassName } from '../../../../utils/util_helpers';
import { getFormLabelStyle, inputParentContainer } from '../../shared-styles';
import { DropDownOptionProps } from './components/dropdown-option/types';
import { DropDownOption } from './components/dropdown-option';
import { DropDownOptionDefaultContentProps } from './components/dropdown-option-default-content/types';
import { DropDownOptionDefaultContent } from './components/dropdown-option-default-content';
import { DropDownOptionsProps } from './components/dropdown-options/types';
import { DropDownOptions } from './components/dropdown-options';
import { DropDownInputMultipleValues } from './components/dropdown-input-multiple-values';
import { DropDownContextProvider } from './hooks/use-dropdown-context';
import { DropDownInputMultipleValuesProps } from './components/dropdown-input-multiple-values/types';
import { DropDownInputMultipleValueDefaultContentProps } from './components/dropdown-input-multiple-value-default-content/types';
import { DropDownInputMultipleValueDefaultContent } from './components/dropdown-input-multiple-value-default-content';
import { TextInputHelpOrErrorMessage } from '../../atoms/text/sub-components/text-input-help-or-error-message';
import { DropDownInputContainer } from './components/dropdown-input-container';

const DropDown: React.FC<DropDownProps> & {
  DropDownOption: React.FC<DropDownOptionProps>;
  DropDownOptions: React.FC<DropDownOptionsProps>;
  DropDownOptionDefaultContent: React.ForwardRefExoticComponent<
    DropDownOptionDefaultContentProps &
      React.RefAttributes<((item: Element) => void) | undefined>
  >;
  DropDownInputMultipleValues: React.FC<DropDownInputMultipleValuesProps>;
  DropDownInputMultipleValueDefaultContent: React.FC<DropDownInputMultipleValueDefaultContentProps>;
} = ({
  clearable = true,
  multiple = false,
  disabled = false,
  showLabel = false,
  placeholder,
  label,
  children,
  displayValue,
  onTextInputChange,
  contentBefore,
  className,
  value,
  onChange,
  invalid = false,
  showErrorMessage = true,
  showHelpMessage = true,
  helpMessage,
  errorMessage,
  ...props
}) => {
  const uniqueId = useId();

  const filledSlots = useMemo(() => {
    const slots: DropDownSlots = {
      multipleInput: null,
      options: null,
    };

    Children.toArray(children).forEach((child) => {
      if (!isValidElement(child)) {
        return;
      }

      switch (child.type) {
        case DropDownOptions:
          slots.options = child;
          break;
        case DropDownInputMultipleValues:
          slots.multipleInput = child;
          break;
        default:
      }
    });

    return slots;
  }, [children]);

  const labelStyle = useMemo(() => {
    return getFormLabelStyle({ disabled, showLabel });
  }, [disabled, showLabel]);

  return (
    <DropDownContextProvider
      id={uniqueId}
      onTextInputChange={onTextInputChange}
      displayValue={displayValue}
      disabled={disabled}
      multiple={multiple}
      value={value}
      placeholder={placeholder}
      invalid={invalid}
      clearable={clearable}
      onChange={onChange}
    >
      <Combobox
        {...props}
        as="div"
        value={value}
        className={composeClassName(
          inputParentContainer,
          className && className
        )}
        onChange={onChange}
        disabled={disabled}
        // @ts-expect-error
        nullable={clearable}
        // @ts-expect-error
        multiple={multiple}
      >
        {({ open }) => (
          <>
            <Combobox.Label className={labelStyle}>{label}</Combobox.Label>
            <DropDownInputContainer contentBefore={contentBefore} open={open}>
              {filledSlots.multipleInput}
            </DropDownInputContainer>
            <TextInputHelpOrErrorMessage
              {...{
                invalid,
                showErrorMessage,
                showHelpMessage,
                helpMessage,
                errorMessage,
              }}
            />
            {filledSlots.options}
          </>
        )}
      </Combobox>
    </DropDownContextProvider>
  );
};

DropDown.DropDownInputMultipleValueDefaultContent =
  DropDownInputMultipleValueDefaultContent;
DropDown.DropDownInputMultipleValues = DropDownInputMultipleValues;
DropDown.DropDownOptions = DropDownOptions;
DropDown.DropDownOption = DropDownOption;
// @ts-expect-error
DropDown.DropDownOptionDefaultContent = DropDownOptionDefaultContent;

export { DropDown };
