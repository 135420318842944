import { useTranslation } from 'react-i18next';
import React from 'react';

import { composeClassName } from '../../../../../../../../../utils/util_helpers';
import { OutgoingTransactionStatusCellContentProps } from './types';
import { PayoutStatus } from '@/features/admin/payouts/types';
import { theme } from '@/utils/util_theme';
import { IconSaxIcon } from '@/components/design-system/atoms/icon';
import { IconSaxIconName } from '@/components/design-system/atoms/icon/iconsax-icon/types';

const OutgoingTransactionStatusCellContent: React.FC<OutgoingTransactionStatusCellContentProps> =
  ({ status }) => {
    const { t } = useTranslation();

    const bgColor: { [key in PayoutStatus]: string } = {
      draft: 'bg-E30',
      created: 'bg-Q50',
      action_required: 'bg-transparent',
      pending: 'bg-R50',
      resolved: 'bg-transparent',
      rejected: 'bg-C50',
      cancelled: 'bg-E30',
    };

    const borderColor: { [key in PayoutStatus]: string } = {
      draft: 'border-transparent',
      created: 'border-transparent',
      action_required: 'border-C300',
      pending: 'border-transparent',
      resolved: 'border-J300',
      rejected: 'border-transparent',
      cancelled: 'border-transparent',
    };

    const textColor: { [key in PayoutStatus]: string } = {
      draft: theme.colors.E100,
      created: theme.colors.Q300,
      action_required: theme.colors.C300,
      pending: theme.colors.R500,
      resolved: theme.colors.J300,
      rejected: theme.colors.C300,
      cancelled: theme.colors.E100,
    };

    const iconName: { [key in PayoutStatus]: IconSaxIconName } = {
      draft: 'minus-cirlce',
      created: 'document-add',
      action_required: 'information',
      pending: 'clock-1',
      resolved: 'tick-circle',
      rejected: 'close-circle',
      cancelled: 'close-circle',
    };

    return (
      <div className="inline-block">
        <div
          className={composeClassName(
            'flex items-center gap-1 rounded-[19px] border-[1px] border-solid px-2 py-1',
            borderColor[status],
            bgColor[status]
          )}
        >
          <IconSaxIcon
            size={18}
            strokeColor={textColor[status]}
            name={iconName[status]}
            strokeWidth={1.3}
          />
          <span
            className={composeClassName('text-body')}
            style={{ color: textColor[status] }}
          >
            {t(
              `js.features.admin.transactions.outgoing_transactions_list.transaction_status.${status}`
            )}
          </span>
        </div>
      </div>
    );
  };

export { OutgoingTransactionStatusCellContent };
