import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropDown } from '@/components/design-system/molecules/dropdown';
import { useRoomAssignmentListFiltersContext } from '../../../../hooks/use-get-room-assignment-list-filter';

const AssignmentStatusDropDown = () => {
  const {
    selectedAssignmentStatus,
    setSelectedAssignmentStatus,
    setAssignmentStatusQuery,
    filteredAssignmentStatusArray,
  } = useRoomAssignmentListFiltersContext()!;
  const { t } = useTranslation();

  return (
    <DropDown
      placeholder={t('js.features.admin.rents.shared.status_placeholder') ?? ''}
      className="z-20 w-[130px]"
      showLabel
      label={t('js.features.admin.rents.shared.status_label') ?? ''}
      value={selectedAssignmentStatus}
      onChange={setSelectedAssignmentStatus}
      {...(selectedAssignmentStatus && {
        displayValue: selectedAssignmentStatus.displayValue,
      })}
      onTextInputChange={(event) =>
        setAssignmentStatusQuery(event.target.value)
      }
    >
      <DropDown.DropDownOptions>
        {filteredAssignmentStatusArray.map((option) => {
          return (
            <DropDown.DropDownOption key={option.displayValue} value={option}>
              <DropDown.DropDownOptionDefaultContent>
                {option.displayValue}
              </DropDown.DropDownOptionDefaultContent>
            </DropDown.DropDownOption>
          );
        })}
      </DropDown.DropDownOptions>
    </DropDown>
  );
};

export { AssignmentStatusDropDown };
