<template>
  <inline-svg
    :src="require(`../../../../../../../assets/icons/coloc-icons/${name}.svg`)"
    :width="size"
    :height="size"
    class="coloc-icon"
  />
</template>

<script>
import './ColocIcon.scss';
import InlineSvg from 'vue-inline-svg';

const icons = {};

const requireIcons = require.context(
  '../../../../../../../assets/icons/coloc-icons/',
  false,
  /.svg$/
);

/* Puts all the icons inside the icon folder in the object.
 The object will be used to validate the icon name when passing a name.
 */
requireIcons.keys().forEach((fileName) => {
  const iconName = fileName.replace(/^\.\/(.+)\.svg$/, '$1');
  const componentConfig = requireIcons(fileName);
  icons[iconName] = componentConfig.default || componentConfig;
});

export default {
  components: {
    InlineSvg,
  },
  props: {
    /**
     * The size of the icon. Defaults to 24.
     */
    size: {
      type: Number,
      default: 24,
    },
    /**
     * The name of the icon.
     */
    name: {
      type: String,
      required: true,
      validator(value) {
        return Object.prototype.hasOwnProperty.call(icons, value);
      },
    },
  },
};
</script>
