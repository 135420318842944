import React from 'react';

import { RoomAssignmentThreadCellContentProps } from './types';
import { composeClassName } from '@/utils/util_helpers';
import { Button } from '@/components/design-system/atoms/button';

const RoomAssignmentThreadCellContent: React.FC<RoomAssignmentThreadCellContentProps> =
  ({ messages_count, onClick, className }) => {
    return (
      <div
        className={composeClassName(
          'relative inline-flex items-center',
          className && className
        )}
      >
        <Button
          variant="icon"
          onClick={() => onClick()}
          iconName="messages-2"
        />
        {messages_count >= 1 && (
          <div className="absolute right-[1px] top-[-.5px] rounded-full bg-fill-surface-warningHeavy px-[10px] py-[3px]">
            <span className="text-center text-footnote2 text-text-primaryHeavy">
              {messages_count}
            </span>
          </div>
        )}
      </div>
    );
  };

export { RoomAssignmentThreadCellContent };
