import React from 'react';
import { useTranslation } from 'react-i18next';
import { composeClassName } from '@/utils/util_helpers';
import { SectionTitle } from '../../../../../../../components/section-title';
import { useThirdSectionContext } from '../../hooks/use-third-section-context';

const InfoTextDesktop = () => {
  const { currentOption } = useThirdSectionContext()!;
  const { t } = useTranslation();
  return (
    <div
      className={composeClassName(
        'relative mt-[0px] w-[42.5%] pr-4',
        'xl:mt-9'
      )}
    >
      <SectionTitle
        subTitle={t('js.features.marketplace.home.section3.subtitle') ?? ''}
      >
        {t(
          `js.features.marketplace.home.section3.options.${currentOption}_title`
        )}
      </SectionTitle>
      <span className="mt-4 block w-full text-h5 leading-[3.3rem] text-text-secondary">
        {t(
          `js.features.marketplace.home.section3.options.${currentOption}_text`
        )}
      </span>
    </div>
  );
};

export { InfoTextDesktop };
