import React, { useState, useCallback } from 'react';
import { useGetRentsListContext } from '../../hooks/use-get-rents-list';
import { useRentsListFiltersContext } from '../../hooks/use-rents-list-filter';
import { amountToString } from '@/utils/util_helpers/formatting/amount_to_string';

const RentWidgets = () => {
  const [filterState, setFilterState] = useState('');
  const { widgets } = useGetRentsListContext!();
  const { setSelectedListRentStatusByValue } = useRentsListFiltersContext!();

  const setFilter = useCallback((filter: string) => {
    if(filter === filterState) {
      setFilterState('');
      setSelectedListRentStatusByValue('');
      return;
    }
    setFilterState(filter);
    setSelectedListRentStatusByValue(filter);
  }, [setSelectedListRentStatusByValue, filterState]);

  return (
    <div className="flex justify-center space-x-4 px-1 my-3">
      <div
        className={`p-4 mt-1 rounded-lg flex-1 text-center cursor-pointer ${filterState === 'overflow' ? 'border border-solid border-[#FECE1F]' : 'bg-white shadow-lg'}`}
        onClick={() => setFilter('overflow')}
      >
        <p className="text-[#1B998B] text-[25px]">
          {amountToString(widgets.overflow.amount)}
        </p>
        <p className="text-[#6C757D] text-[16px]">
          {`Total Overflow amount of ${widgets?.overflow.count} rents`}
        </p>
      </div>
      <div
        className={`p-4 mt-1 rounded-lg flex-1 text-center cursor-pointer ${filterState === 'due' ? 'border border-solid border-[#FECE1F]' : 'bg-white shadow-lg'}`}
        onClick={() => setFilter('due')}
      >
        <p className="text-[#E5422F] text-[25px]">
          {amountToString(widgets.due.amount)}
        </p>
        <p className="text-[#6C757D] text-[16px]">
          {`Total Due amount of ${widgets?.due.count} rents`}
        </p>
      </div>
      <div
        className={`p-4 mt-1 rounded-lg flex-1 text-center cursor-pointer ${filterState === 'collected' ? 'border border-solid border-[#FECE1F]' : 'bg-white shadow-lg'}`}
        onClick={() => setFilter('collected')}
      >
        <p className="text-[#212529] text-[25px]">
          {amountToString(widgets.collected.amount)}
        </p>
        <p className="text-[#6C757D] text-[16px]">
          {`Total Collected amount of ${widgets?.collected.count} rents`}
        </p>
      </div>
    </div>
  )
};

export { RentWidgets };
