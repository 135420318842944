const requireIcons = require.context(
  '../../../../../../../assets/icons/country-icons',
  false,
  /.svg$/
);

const CountryIconIcons = {};

requireIcons.keys().forEach((fileName) => {
  const iconName = fileName.replace(/^\.\/(.+)\.svg$/, '$1');
  const componentConfig = requireIcons(fileName);
  CountryIconIcons[iconName] = componentConfig.default || componentConfig;
});

export default CountryIconIcons;
