import React from 'react';
import { HeaderLinkProps } from './types';

const HeaderLink: React.FC<HeaderLinkProps> = ({ children, href }) => {
  return (
    <a href={href}>
      <span className="text-lead2 font-medium text-text-tertiary hover:text-text-secondary">
        {children}
      </span>
    </a>
  );
};

export { HeaderLink };
