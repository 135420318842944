<template>
  <section
    :style="{ backgroundImage: `url(${bgImage})` }"
    class="w-full"
    id="WhatImages"
  >
    <div class="lg:pt-28 m-auto w-full max-w-8xl px-4 py-7 pb-9">
      <div class="flex flex-col items-center space-y-2 lg:hidden">
        <h1 class="font-Vollkorn text-[28px] font-bold text-primary-100">
          Make your old house ready for the future
        </h1>
      </div>
      <div
        class="
          flex
          w-full
          flex-col
          items-center
          lg:flex-row
          lg:items-start
          lg:justify-between
        "
      >
        <div class="flex flex-col items-start space-y-8">
          <div
            class="
              hidden
              w-full
              max-w-[606px]
              flex-col
              items-start
              space-y-2
              lg:flex
            "
          >
            <h1
              class="
                font-Vollkorn
                text-2xl
                font-bold
                leading-[66.86px]
                text-primary-100
                lg:text-5xl
              "
            >
              Make your old house ready for the future
            </h1>
          </div>

          <div class="flex w-full flex-col items-start space-y-8 lg:pb-[145px]">
            <div class="flex w-full items-center space-x-6">
              <ColocIcon name="team" :size="80" />

              <div class="flex flex-col items-start space-y-1">
                <span
                  class="
                    font-sans
                    text-h5
                    font-bold
                    text-primary-100
                    lg:text-h3
                  "
                  >Experienced team</span
                >
                <span class="font-sans text-lg font-medium text-E100 lg:text-h4"
                  >25 renovations done and counting</span
                >
              </div>
            </div>
            <div class="flex w-full items-center space-x-6">
              <ColocIcon name="renewable" :size="80" />
              <div class="flex flex-col items-start space-y-1">
                <span
                  class="
                    font-sans
                    text-h5
                    font-bold
                    text-primary-100
                    lg:text-h3
                  "
                  >Renewable technologies</span
                >
                <span
                  class="font-sans text-lead1 font-medium text-E100 lg:text-h4"
                  >We always strive for the highest energy performance</span
                >
              </div>
            </div>
            <div class="flex w-full items-center space-x-6">
              <ColocIcon name="plan" :size="80" />
              <div class="flex flex-col items-start space-y-1">
                <span
                  class="
                    font-sans
                    text-h5
                    font-bold
                    text-primary-100
                    lg:text-h3
                  "
                  >A good plan works</span
                >
                <span
                  class="font-sans text-lead1 font-medium text-E100 lg:text-h4"
                  >From detailed plans to a perfect execution.</span
                >
              </div>
            </div>
          </div>
        </div>
        <Houses class="flex" />
      </div>
    </div>
  </section>
</template>

<script>
import bgImage from 'assets/images/spot_a_house/secondsection.png';
import Houses from '../Houses.vue';
import ColocIcon from '../../../common/components/ui/atoms/icon/coloc-icon/ColocIcon.vue';

export default {
  data() {
    return {
      bgImage,
    };
  },
  components: {
    Houses,
    ColocIcon,
  },
};
</script>


