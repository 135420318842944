import React from 'react';
import { composeClassName } from '@/utils/util_helpers';
import './styles.css';

const SearchBarLoading = () => {
  return (
    <div
      className={composeClassName(
        'dot-flashing',
        'ml-4 bg-R300 text-R300 before:bg-R300 before:text-R300 after:bg-R300 after:text-R300'
      )}
    />
  );
};

export { SearchBarLoading };
