/* eslint no-use-before-define: ["error", { "functions": false }] */

export const getElement = (id) => document.getElementById(id);

function isString(obj) {
  return Object.prototype.toString.call(obj) === '[object String]';
}

function isArray(obj) {
  return Object.prototype.toString.call(obj) === '[object Array]';
}

function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const showElement = (element, display) => {
  element.style.display = display;
};

export const hideElement = (element) => {
  element.style.display = 'none';
};

export const clearElement = (element) => {
  element.innerHTML = '';
};

export const updateElement = (element, content) => {
  element.innerHTML = content;
};

export const enableElement = (element) => {
  element.disabled = false;
  const newClasses = [];
  element.classList.forEach((klass) => {
    if (klass !== 'disabled') newClasses.push(klass);
  });
  element.className = newClasses.join(' ');
};

export const disableElement = (element) => {
  element.disabled = true;
  element.classList.add('disabled');
};

export const createElement = (tagName, content, callbacks) => {
  if (!tagName && !isString(tagName)) {
    throw new Error('tagName string not provided!');
  }

  const el = document.createElement('div');

  if (content && isString(content)) {
    el.innerHTML = content;
  }

  if (callbacks) {
    if (isArray(callbacks)) {
      callbacks.forEach(({ eventName, callback }) => {
        el.addEventListener(eventName, () => callback());
      });
    } else {
      const { eventName, callback } = callbacks;
      el.addEventListener(eventName, () => callback());
    }
  }

  return el;
};

const styles = getComputedStyle(document.documentElement);

export const breakpoints = {
  xs: styles.getPropertyValue('--breakpoint-xs'),
  sm: styles.getPropertyValue('--breakpoint-sm'),
  md: styles.getPropertyValue('--breakpoint-md'),
  lg: styles.getPropertyValue('--breakpoint-lg'),
  xl: styles.getPropertyValue('--breakpoint-xl'),
};

export const getActiveBreakpoints = () =>
  Object.keys(breakpoints).filter(
    // eslint-disable-next-line radix
    (key) => window.innerWidth > parseInt(breakpoints[key])
  );

const fit =
  (contains) =>
  (parentWidth, parentHeight, childWidth, childHeight, scale = 1) => {
    const childRatio = childWidth / childHeight;
    const parentRatio = parentWidth / parentHeight;
    let width = parentWidth * scale;
    let height = parentHeight * scale;

    if (contains ? childRatio > parentRatio : childRatio < parentRatio) {
      width = parentHeight * childRatio;
    } else {
      height = parentWidth * childRatio;
      width = parentWidth;
    }

    return { width, height };
  };

const contain = fit(true);
const cover = fit(false);

export const drawCanvasCover = (canvas, img, scale = 1) => {
  const { width, height } = cover(
    canvas.getBoundingClientRect().width,
    canvas.getBoundingClientRect().height,
    img.width,
    img.height,
    scale
  );
  img.height = height;
  img.width = width;

  const offsetX = canvas.width / 2 - img.width / 2;
  const offsetY = canvas.height / 2 - img.height / 2;

  const ctx = canvas.getContext('2d');
  ctx.drawImage(img, offsetX, offsetY);
};

export const drawCanvasContain = (canvas, img, scale = 1) => {
  const { width, height } = contain(
    canvas.getBoundingClientRect().width,
    canvas.getBoundingClientRect().height,
    img.width,
    img.height,
    scale
  );
  img.height = height;
  img.width = width;

  // const offsetX = canvas.getBoundingClientRect().width / 2 - img.width / 2;
  const offsetY = img.height / 2;

  const ctx = canvas.getContext('2d');
  ctx.drawImage(img, 0, offsetY);
};

export const parseDataset = (element) => {
  const { dataset } = element;
  const data = {};

  Object.keys(dataset).forEach((attr) => {
    const value = dataset[attr];
    switch (true) {
      // eslint-disable-next-line no-restricted-globals
      case !isNaN(value):
        // eslint-disable-next-line radix
        data[attr] = parseInt(value);
        break;
      case value === 'true':
        data[attr] = true;
        break;
      case value === 'false':
        data[attr] = false;
        break;
      case isJson(value):
        data[attr] = JSON.parse(value);
        break;
      default:
        data[attr] = value;
        break;
    }
  });
  return data;
};
