import { format, sub } from 'date-fns';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { t } from 'i18next';

import { PayoutsSortType } from '@/lib/coloc-api/admin';
import { useColocApi } from '@/hooks/use-coloc-api';
import { PayoutStatus } from '@/features/admin/payouts/types';

type OutgoingStatusOption = {
  status: PayoutStatus;
  displayValue: string;
};

const transactionStatus: Array<OutgoingStatusOption> = [
  {
    status: 'draft',
    displayValue: t(
      'js.features.admin.transactions.outgoing_transactions_list.transaction_status.draft'
    ),
  },
  {
    status: 'created',
    displayValue: t(
      'js.features.admin.transactions.outgoing_transactions_list.transaction_status.created'
    ),
  },
  {
    status: 'action_required',
    displayValue: t(
      'js.features.admin.transactions.outgoing_transactions_list.transaction_status.action_required'
    ),
  },
  {
    status: 'pending',
    displayValue: t(
      'js.features.admin.transactions.outgoing_transactions_list.transaction_status.pending'
    ),
  },
  {
    status: 'resolved',
    displayValue: t(
      'js.features.admin.transactions.outgoing_transactions_list.transaction_status.resolved'
    ),
  },
  {
    status: 'rejected',
    displayValue: t(
      'js.features.admin.transactions.outgoing_transactions_list.transaction_status.rejected'
    ),
  },
  {
    status: 'cancelled',
    displayValue: t(
      'js.features.admin.transactions.outgoing_transactions_list.transaction_status.cancelled'
    ),
  },
];

const useOutgoingTransactionFilter = () => {
  const [startDate, setStartDate] = useState<Date | undefined>(
    sub(new Date(format(new Date(), 'yyyy-MM-01')), { months: 0 })
  );
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [selectedStatus, setSelectedStatus] = useState<
    OutgoingStatusOption | undefined
  >(undefined);
  const [transactionStatusQuery, setTransactionStatusQuery] = useState('');
  const [selectedHouse, setSelectedHouse] = useState<
    { id: string; name: string } | undefined
  >(undefined);
  const [housesQuery, setHousesQuery] = useState('');

  const {
    setColumnToSort,
    sortDirection,
    selectedColumnToSort,
    setSortDirection,
  } = useColocApi<PayoutsSortType>()!;

  // Set default sorting to created_at
  useEffect(() => {
    setColumnToSort('created_at');
    setSortDirection('descending');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDateRange = useCallback(
    (new_date_range: [Date | null, Date | null]) => {
      setStartDate(new_date_range[0] ?? undefined);
      setEndDate(new_date_range[1] ?? undefined);
    },
    []
  );

  const filteredTransactionStatusArray = useMemo(() => {
    return transactionStatus.filter((statusObject) =>
      statusObject.displayValue.includes(transactionStatusQuery)
    );
  }, [transactionStatusQuery]);

  return {
    startDate,
    endDate,
    setDateRange,
    searchQuery,
    setSearchQuery,
    setColumnToSort,
    sortDirection,
    selectedColumnToSort,
    selectedStatus,
    setSelectedStatus,
    transactionStatusQuery,
    setTransactionStatusQuery,
    filteredTransactionStatusArray,
    selectedHouse,
    setSelectedHouse,
    housesQuery,
    setHousesQuery,
  };
};

const GetOutgoingTransactionsListFilterContext = createContext<
  ReturnType<typeof useOutgoingTransactionFilter> | undefined
>(undefined);

const GetOutgoingTransactionsListFilterContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const hook = useOutgoingTransactionFilter();

  return (
    <GetOutgoingTransactionsListFilterContext.Provider value={hook}>
      {children}
    </GetOutgoingTransactionsListFilterContext.Provider>
  );
};

const useGetOutgoingTransactionsListFilterContext = () => {
  const context = useContext(GetOutgoingTransactionsListFilterContext);

  if (context === null) {
    throw new Error(
      'useGetOutgoingTransactionsListFilterContext must be used within a GetOutgoingTransactionsListFilterContextProvider'
    );
  }

  return context;
};

export {
  GetOutgoingTransactionsListFilterContextProvider,
  useGetOutgoingTransactionsListFilterContext,
};
