<template>
  <div
    class="d-flex align-items-center"
    style="padding: 16px 24px; background-color: #f8f9fa; border-radius: 10px"
  >
    <div>
      <span class="font-weight-bold"
        >{{
          t(
            'vue_components.tenant.contract.availability_date.availability_date'
          )
        }}:</span
      >
      {{ formatDate(availabilityDate) }}
    </div>

    <div class="ml-2 align-text-bottom" v-if="changeable">
      <inline-svg
        :src="require(`assets/icons/icon-sax/linear/edit-2.svg`)"
        width="18"
        height="18"
        class="icon icon-xs ml-2"
      />

      <a
        href="#"
        @click.prevent="showModal"
        data-no-turbolinks="true"
        class="small"
        style="margin-left: 2px"
      >
        {{ t('vue_components.tenant.contract.availability_date.edit') }}
      </a>
    </div>

    <div class="ml-auto">
      <a
        data-toggle="popover"
        data-trigger="hover"
        :data-content="
          t('vue_components.tenant.contract.availability_date.info.content')
        "
        :title="
          t('vue_components.tenant.contract.availability_date.info.title')
        "
      >
        <inline-svg
          :src="require(`assets/icons/icon-sax/linear/info-circle.svg`)"
          width="18"
          height="18"
          class="icon icon-sm align-text-bottom"
        />
      </a>
    </div>

    <b-modal ref="modal" @hidden="reset" hide-footer hide-header centered>
      <button
        type="button"
        aria-label="Close"
        @click="hideModal()"
        class="close"
      >
        ×
      </button>

      <div v-if="!finished">
        <h5 class="modal-title">
          {{
            t('vue_components.tenant.contract.availability_date.modal.header')
          }}
        </h5>
        <div class="small text-muted">
          {{
            t(
              'vue_components.tenant.contract.availability_date.modal.subheader',
              { date: formatDate(contract.move_out) }
            )
          }}
        </div>

        <div class="mt-4">
          {{
            t(
              'vue_components.tenant.contract.availability_date.modal.availability_date'
            )
          }}
        </div>

        <flat-pickr
          v-model="newAvailabilityDate"
          :config="flatPickrConfig"
          :placeholder="
            t(
              'vue_components.tenant.contract.availability_date.modal.select_date'
            )
          "
          class="form-control mb-3"
          name="date"
        />

        <div class="text-danger mt-2" v-if="error">{{ error }}</div>

        <div>
          <button
            type="button"
            class="btn btn-primary"
            @click="submit"
            :disabled="submitting || newAvailabilityDate === availabilityDate"
          >
            {{
              t(
                'vue_components.tenant.contract.availability_date.modal.change_date'
              )
            }}
          </button>
          <button type="button" class="btn btn-light" @click="hideModal">
            {{
              t('vue_components.tenant.contract.availability_date.modal.cancel')
            }}
          </button>
        </div>
      </div>
      <div v-else>
        <div>
          {{
            t(
              'vue_components.tenant.contract.availability_date.modal.availability_date_changed'
            )
          }}
        </div>
        <div>
          {{
            t(
              'vue_components.tenant.contract.availability_date.modal.your_availability_date_is_set_to',
              { date: formatDate(availabilityDate) }
            )
          }}
        </div>

        <button type="button" class="btn btn-primary mt-4" @click="hideModal">
          {{ t('vue_components.tenant.contract.availability_date.modal.done') }}
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import i18n from '../../../vue_mixins/i18n';
import * as dateFns from 'date-fns';
import { BModal } from 'bootstrap-vue';
import InlineSvg from 'vue-inline-svg';
import flatPickr from 'vue-flatpickr-component';
import { updateContractAvailabilityDate } from 'src/lib/coloc-api/tenant';

export default {
  mixins: [i18n],
  components: {
    BModal,
    InlineSvg,
    flatPickr,
  },
  props: {
    locale: {
      type: String,
      required: true,
    },
    contract: {
      type: Object,
      required: true,
    },
    changeable: {
      type: Boolean,
      required: true,
    },
  },
  data: function () {
    const availabilityDate =
      this.contract.availability_date || this.contract.move_out;

    return {
      availabilityDate,
      newAvailabilityDate: availabilityDate,
      finished: false,
      error: null,
      submitting: false,
    };
  },
  computed: {
    flatPickrConfig() {
      return {
        locale: this.locale,
        dateFormat: 'Y-m-d',
        altFormat: 'F J, Y',
        showMonths: 1,
        minDate: dateFns.startOfDay(dateFns.add(new Date(), { days: 1 })),
        maxDate: this.contract.move_out,
      };
    },
  },
  methods: {
    showModal() {
      this.$refs['modal'].show();
    },
    hideModal() {
      this.$refs['modal'].hide();
    },
    async submit() {
      this.submitting = true;

      try {
        await updateContractAvailabilityDate({
          contract_id: this.contract.id,
          availability_date: this.newAvailabilityDate,
        });

        this.availabilityDate = this.newAvailabilityDate;
        this.finished = true;
      } catch (exception) {
        if (exception.response.data?.error) {
          this.error = exception.response.data?.error;
        } else {
          this.error = exception.message;
        }
      } finally {
        this.submitting = false;
      }
    },
    reset() {
      this.error = null;
      this.submitting = false;
      this.finished = false;
      this.newAvailabilityDate = this.availabilityDate;
    },
    formatDate(dateStr) {
      if (!dateStr) return '';

      return dateFns.format(new Date(dateStr), 'MMMM do, Y', {
        locale: window.dateFnsLocales[this.currentLocale()],
      });
    },
  },
};
</script>
