import React, { useState } from 'react';
import { IconSaxIcon } from '@/components/design-system/atoms/icon';
import { theme } from '@/utils/util_theme';
import { useLocale } from '@/hooks/use-locale';
import { DropdownMenu } from '@/components/design-system/molecules/dropdown-menu';
import { composeClassName } from '@/utils/util_helpers';

const LocaleButton: React.FC<{ open: boolean | undefined }> = ({
  open = false,
}) => {
  const { currentLocale } = useLocale();
  const [isHovered, setIsHovering] = useState(false);

  return (
    <div
      className="relative flex items-center gap-1"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <IconSaxIcon
        name="global2"
        className={composeClassName(
          '[&_svg>path]:fill-text-tertiary',
          isHovered && '[&_svg>path]:fill-text-secondary'
        )}
        strokeColor={
          isHovered ? theme?.colors.text.secondary : theme?.colors.text.tertiary
        }
        strokeWidth={0.1}
      />
      <span
        className={composeClassName(
          'text-lead2 font-medium uppercase text-text-tertiary',
          isHovered && '!text-text-secondary'
        )}
      >
        {currentLocale}
      </span>
      <IconSaxIcon
        name={open ? 'arrow-up-1' : 'arrow-down'}
        strokeColor={
          isHovered ? theme?.colors.text.secondary : theme?.colors.text.tertiary
        }
        size={16}
      />
    </div>
  );
};

const LocaleSwitcher: React.FC<{}> = () => {
  const { localeOptionsFull, setLocale } = useLocale();

  return (
    <DropdownMenu>
      <DropdownMenu.DropdownMenuButton>
        {({ open }) => {
          return <LocaleButton open={open} />;
        }}
      </DropdownMenu.DropdownMenuButton>
      <DropdownMenu.DropdownMenuOptions className="right-[0px]">
        {Object.keys(localeOptionsFull).map((key) => {
          return (
            <DropdownMenu.DropdownMenuOption
              key={key}
              as="button"
              onClick={() => setLocale(key)}
            >
              {localeOptionsFull[key]}
            </DropdownMenu.DropdownMenuOption>
          );
        })}
      </DropdownMenu.DropdownMenuOptions>
    </DropdownMenu>
  );
};

export { LocaleSwitcher };
