import { Controller } from 'stimulus';

const STORAGE_KEY = 'sidebar';
const DEFAULT_STATE = 'expanded';

export default class extends Controller {
  connect() {
    this.restoreState();
  }

  restoreState() {
    const state = localStorage.getItem(STORAGE_KEY) || DEFAULT_STATE;
    document.body.dataset.sidebar = state;
  }

  toggle() {
    const state = document.body.dataset.sidebar === 'expanded' ? 'collapsed' : 'expanded';
    document.body.dataset.sidebar = state;
    localStorage.setItem(STORAGE_KEY, state);
  }
}
