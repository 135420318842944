import React from 'react';
import { Combobox } from '@headlessui/react';
import { isElement } from 'react-dom/test-utils';
import { DropDownOptionProps } from './types';
import { composeClassName } from '../../../../../../utils/util_helpers';

const DropDownOption: React.FC<DropDownOptionProps> = ({
  value,
  disabled,
  children,
  className,
}) => {
  return (
    <Combobox.Option
      disabled={disabled}
      value={value}
      className={composeClassName(
        'relative cursor-default select-none',
        className && className
      )}
    >
      {isElement(children)
        ? ({ active, disabled: innerDisabled, selected }) => {
            return React.Children.map(
              children as unknown as React.ReactElement,
              (child: React.ReactElement) => {
                return {
                  ...child,
                  props: {
                    ...child.props,
                    active,
                    disabled: innerDisabled,
                    selected,
                  },
                };
              }
            ) as unknown as React.ReactElement;
          }
        : children}
    </Combobox.Option>
  );
};

export { DropDownOption };
