import React, { useCallback, useMemo } from 'react';
import { IconSaxIcon } from '@/components/design-system/atoms/icon';
import { theme } from '@/utils/util_theme';
import { DatePickerDropDownHeaderProps } from './types';
import {
  getFullMonthName,
  getFullYearNumber,
} from '@/utils/util_helpers/formatting/dates';

const DatePickerDropDownHeader: React.FC<DatePickerDropDownHeaderProps> = ({
  prevYearButtonDisabled,
  nextYearButtonDisabled,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  decreaseMonth,
  decreaseYear,
  increaseYear,
  increaseMonth,
  date,
  monthDate,
  type,
}) => {
  const dateString: string = useMemo(() => {
    switch (type) {
      case 'month':
        return monthDate
          ? `${getFullYearNumber(monthDate)}`
          : `${getFullYearNumber(date)}`;
      default:
        return `${getFullMonthName(date)} ${getFullYearNumber(date)}`;
    }
  }, [date, monthDate, type]);

  const previousButtonDisabled = useMemo(() => {
    if (type === 'month') {
      return prevYearButtonDisabled;
    }
    return prevMonthButtonDisabled;
  }, [prevYearButtonDisabled, prevMonthButtonDisabled, type]);

  const nextButtonDisabled = useMemo(() => {
    if (type === 'month') {
      return nextYearButtonDisabled;
    }
    return nextMonthButtonDisabled;
  }, [nextMonthButtonDisabled, nextYearButtonDisabled, type]);

  const handleClickPrevious = useCallback(() => {
    if (type === 'month') {
      decreaseYear();
    } else {
      decreaseMonth();
    }
  }, [type, decreaseYear, decreaseMonth]);

  const handleClickNext = useCallback(() => {
    if (type === 'month') {
      increaseYear();
    } else {
      increaseMonth();
    }
  }, [increaseMonth, increaseYear, type]);

  return (
    <div className="flex items-center justify-between px-3 pt-3">
      <IconSaxIcon
        {...(!previousButtonDisabled && {
          onClick: handleClickPrevious,
        })}
        name="arrow-left-4"
        strokeColor="transparent"
        className=" [&_svg>path]:fill-text-tertiary"
      />
      <span className="text-lead2 font-medium text-text-primary">
        {dateString}
      </span>
      <IconSaxIcon
        {...(!nextButtonDisabled && {
          onClick: handleClickNext,
        })}
        onClick={handleClickNext}
        name="arrow-right-4"
        strokeColor="transparent"
        className=" bg-white [&_svg>path]:fill-text-tertiary"
      />
    </div>
  );
};

export { DatePickerDropDownHeader };
