import { useMutation } from '@tanstack/react-query';

import {
  AddRentTransactionParams,
  AddRentTransactionRequestBody,
} from '@/lib/coloc-api/admin';
import { addRentTransaction } from '@/services/coloc-api/admin';
import { showToast } from '@/services/toast-service';
import { useGetRentsListContext } from '../../use-get-rents-list';

const useAddRentTransaction = () => {
  const {
    toggleShowAddRentTransactionModal,
    toggleShowAddedRentTransactionConfirmationModal,
    updateRentInCache,
  } = useGetRentsListContext()!;

  const mutate = useMutation({
    mutationKey: ['addRentTransaction'],
    mutationFn: (
      params: AddRentTransactionParams & AddRentTransactionRequestBody
    ) => {
      return addRentTransaction(
        {
          rent_id: params.rent_id,
        },
        { transaction: params.transaction }
      );
    },
    onSuccess: (data) => {
      updateRentInCache(data.data.rent);
      toggleShowAddRentTransactionModal({ show: false, rent: undefined });
      toggleShowAddedRentTransactionConfirmationModal(true);
    },
    onError: () => {
      toggleShowAddRentTransactionModal({ show: false, rent: undefined });
      showToast.error({});
    },
  });

  return {
    mutate,
  };
};

export { useAddRentTransaction };
