import { Controller } from 'stimulus';
// //
// import flash from 'src/services/flash.service';

export default class extends Controller {
  static targets = [
    'snapshot',
    'screen',
    'controls',
    'welcome',
    'fileInput',
    'hiddenInput',
  ];

  connect() {
    this.init();
  }

  init() {
    this.showWelcome();
    this.hideControls();
    this.hideSnapshot();
  }

  disconnect() {
    this.stopStreaming();
  }

  startStreaming(e) {
    e.preventDefault();
    this.showControls(e);
    this.hideWelcome();
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => this.handleSuccess(stream));
  }

  handleSuccess(stream) {
    this.screen.style.transform = 'scaleX(-1)';
    this.stream = stream;
    this.screen.srcObject = this.stream;
    // eslint-disable-next-line prefer-destructuring
    this.videoTrack = this.stream.getVideoTracks()[0];
  }

  capture(e) {
    e.preventDefault();
    this.showSnapshot(e);
    const { width, height } = this.snapshot;
    const context = this.snapshot.getContext('2d');
    context.translate(width, 0);
    context.scale(-1, 1);
    context.drawImage(this.screen, 0, 0, width, height);
    this.hiddenInput.value = this.snapshot.toDataURL();
    this.stopStreaming(e);
  }

  clear(e) {
    e.preventDefault();
    this.clearSnapshot(e);
    this.stopStreaming(e);
    this.startStreaming(e);
  }

  cancel(e) {
    e.preventDefault();
    this.init();
    this.clearSnapshot(e);
    this.stopStreaming(e);
  }

  clearSnapshot(e) {
    e.preventDefault();
    const context = this.snapshot.getContext('2d');
    context.clearRect(0, 0, this.snapshot.width, this.snapshot.height);
  }

  stopStreaming() {
    this.videoTrack.stop();
  }

  hideControls() {
    this.controls.classList.add('d-none');
  }

  hideSnapshot() {
    this.snapshot.classList.add('d-none');
  }

  hideWelcome() {
    this.welcome.classList.add('d-none');
  }

  showControls() {
    this.controls.classList.remove('d-none');
  }

  showSnapshot() {
    this.snapshot.classList.remove('d-none');
  }

  showWelcome() {
    this.welcome.classList.remove('d-none');
  }

  get controls() {
    return this.controlsTarget;
  }

  get welcome() {
    return this.welcomeTarget;
  }

  get snapshot() {
    return this.snapshotTarget;
  }

  get screen() {
    return this.screenTarget;
  }

  get fileInput() {
    return this.fileInputTarget;
  }

  get hiddenInput() {
    return this.hiddenInputTarget;
  }
}
