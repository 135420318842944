import React, { createContext, useCallback, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getCitiesList } from '@/services/coloc-api/marketplace';

const useGetCities = () => {
  const fetchCitiesList = useQuery({
    queryKey: ['getCitiesList'],
    queryFn: () => getCitiesList(),
  });

  const citiesSelectionHomePage = {
    bru: {
      id: 1,
      name: 'brussels',
    },
    ghe: {
      id: 2,
      name: 'ghent',
    },
    ant: {
      id: 39,
      name: 'antwerp',
    },
    leu: {
      id: 4,
      name: 'leuven',
    },
  };

  const getCityDetail = useCallback(
    (id: number) => {
      if (fetchCitiesList?.data?.data) {
        return fetchCitiesList.data.data.find((element) => element.id === id);
      }

      return undefined;
    },
    [fetchCitiesList?.data?.data]
  );

  return {
    fetchCitiesList,
    getCityDetail,
    citiesSelectionHomePage,
  };
};

const CitiesListContext = createContext<
  ReturnType<typeof useGetCities> | undefined
>(undefined);

const CitiesListContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const hook = useGetCities();

  return (
    <CitiesListContext.Provider value={hook}>
      {children}
    </CitiesListContext.Provider>
  );
};

const useCitiesListContext = () => {
  const context = useContext(CitiesListContext);

  if (context === null) {
    throw new Error(
      'useCitiesListContext must be used within a CitiesListContextProvider'
    );
  }

  return context;
};

export { useCitiesListContext, CitiesListContextProvider };
