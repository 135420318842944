import React, { useMemo, useState } from 'react';
import { ButtonProps } from './types';
import { IconSaxIcon, SocialIcon } from '../icon';
import { isSocialIconName } from '../icon/social-icon/types';
import { composeClassName } from '../../../../utils/util_helpers';
import {
  buttonStyling,
  buttonContainerOnlyIconStylingSize,
  buttonContainerStylingSize,
  iconColors,
} from './style';
import { theme } from '../../../../utils/util_theme';

const ButtonLabel = ({
  loading,
  buttonText,
  loadingLabelText,
}: Pick<ButtonProps, 'loading' | 'loadingLabelText' | 'children'> & {
  buttonText: string | undefined;
}): JSX.Element | null => {
  if (!loading && buttonText) {
    return <span>{buttonText}</span>;
  }

  if (loadingLabelText && loading && buttonText) {
    return <span>{buttonText}</span>;
  }

  return null;
};

const Button: React.FC<ButtonProps> = ({
  iconName,
  iconPosition = 'before',
  showOriginalIcon = true,
  variant = 'primary',
  size = 'medium',
  loading = false,
  disabled = false,
  type = 'button',
  children,
  loadingLabelText = '...loading',
  onMouseEnter,
  onMouseLeave,
  className,
  title,
  customIconSize = 24,
  nestedContainerClassName,
  ...props
}: ButtonProps): JSX.Element => {
  const [isHovering, setIsHovering] = useState(false);

  const showIcon = useMemo(() => {
    return iconName && !children;
  }, [iconName, children]);

  const buttonText = useMemo(() => {
    if (children && !loading) {
      return children;
    }

    if (title && !loading) {
      return title;
    }

    if (loading && loadingLabelText) {
      return loadingLabelText;
    }

    return undefined;
  }, [children, loading, loadingLabelText, title]);

  const iconStrokeColor = useMemo(() => {
    if (isHovering && variant === 'tertiary') {
      return theme?.colors?.white;
    }

    if (isHovering && (variant === 'link' || variant === 'icon')) {
      return theme?.colors?.J300;
    }

    return disabled ? theme?.colors?.E75 : iconColors[variant];
  }, [variant, isHovering, disabled]);

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={composeClassName(
        'relative box-border rounded-sm font-sans text-body font-medium disabled:cursor-not-allowed',
        buttonStyling[variant],
        className && className
      )}
      disabled={disabled || loading}
      onMouseEnter={(e) => {
        setIsHovering(true);
        onMouseEnter && onMouseEnter(e);
      }}
      onMouseLeave={(e) => {
        setIsHovering(false);
        onMouseLeave && onMouseLeave(e);
      }}
      {...props}
    >
      <div
        className={composeClassName(
          'flex items-center justify-center gap-x-[10px]',
          iconPosition === 'after' && 'flex-row-reverse',
          showIcon
            ? buttonContainerOnlyIconStylingSize[size]
            : buttonContainerStylingSize[size],
          variant === 'link' && '!py-[0px] !px-[0px]',
          nestedContainerClassName && nestedContainerClassName
        )}
      >
        {!loading && (
          <>
            {iconName && isSocialIconName(iconName) && (
              <SocialIcon
                name={iconName}
                size={size === 'large' ? 24 : 18}
                variant={showOriginalIcon ? 'original' : 'black-white'}
                strokeColor={iconStrokeColor}
                strokeWidth={1.2}
              />
            )}
            {iconName && !isSocialIconName(iconName) && (
              <IconSaxIcon
                name={iconName}
                size={
                  // eslint-disable-next-line no-nested-ternary
                  variant === 'icon'
                    ? customIconSize
                    : size === 'large'
                    ? 24
                    : 18
                }
                strokeColor={iconStrokeColor}
                strokeWidth={1.2}
              />
            )}
          </>
        )}
        <ButtonLabel
          loading={loading}
          loadingLabelText={loadingLabelText}
          buttonText={buttonText}
        />
      </div>
    </button>
  );
};

export { Button };
