<template>
  <div class="ui-view-toggle-container">
    <button
      class="ui-view-toggle-button ui-button-left"
      :class="{ 'ui-view-toggle-button--active': SelectedView == 'card' }"
      @click="toggleView"
    >
      <IconSaxIconVue name="element-3" class="nested-sax-icon" :size="16" />
    </button>
    <button
      class="ui-view-toggle-button ui-button-right"
      :class="{ 'ui-view-toggle-button--active': SelectedView == 'list' }"
      @click="toggleView"
    >
      <ColocIcon name="list" :size="16" />
    </button>
  </div>
</template>

<script>
import './ViewToggle.scss';
import ColocIcon from '../icon/coloc-icon/ColocIcon.vue';
import IconSaxIconVue from '../icon/iconsax-icon/IconSaxIcon.vue';

export default {
  components: {
    ColocIcon,
    IconSaxIconVue,
  },
  props: {
    view: {
      type: String,
      default: 'card',
    },
  },
  data() {
    return {
      SelectedView: this.view,
    };
  },
  watch: {
    view() {
      this.SelectedView = this.view;
    },
  },
  methods: {
    toggleView() {
      this.SelectedView = this.SelectedView === 'card' ? 'list' : 'card';
      this.$emit('onToggleView', this.SelectedView);
    },
  },
};
</script>