import React, { useEffect, useMemo, useRef, useState } from 'react';
import { getPropertyManagerList } from '@/lib/coloc-api/admin';
import { Button } from '@/components/design-system/atoms/button';
import { useCreateThreadMessage } from '../../hooks/use-create-thread-message';
import { LoadingSpinner } from '@/components/design-system/atoms/loading-spinner';
import { useCreateDiscussionThread } from '../../hooks/use-create-discussion-thread';
import { useDiscussionThreadProviderContext } from '../../hooks/use-discussion-thread';
import { Mention, MentionsInput } from 'react-mentions';
import { PropertyManager } from '@/features/admin/contracts/types';
import { MentionSuggestion } from './MentionSuggestion';
import defaultStyle from './defaultStyle';
import defaultMentionStyle from './defaultMentionStyle';

const DiscussionThreadInput = () => {
  const [propertyManagers, setPropertyManagers] = useState<
    Array<PropertyManager>
  >([]);

  const { mutate: createThreadMessage } = useCreateThreadMessage();
  const { mutate: createDiscussionThread } = useCreateDiscussionThread();
  const { discussionThreadHasBeenCreated } =
    useDiscussionThreadProviderContext()!;

  const containerRef = useRef<HTMLDivElement>(null);

  const [value, setValue] = useState({
    textInputValue: '',
    mention_ids: [],
  });

  const { textInputValue, mention_ids } = value;

  const isInputContainsText = useMemo(() => {
    return textInputValue.trim().length >= 1;
  }, [textInputValue]);

  useEffect(() => {
    getPropertyManagerList().then((response) => {
      setPropertyManagers(response.data.data);
    });
  }, []);

  const users = useMemo(() => {
    return propertyManagers.map((propertyManager) => ({
      id: propertyManager.id,
      display: `${propertyManager.first_name} ${propertyManager.last_name}`,
      avatar_url: propertyManager.avatar_url,
    }));
  }, [propertyManagers]);


  const onChange = (e: any, newValue: string, newPlainTextValue: string, mentions: any) => {
    setValue({
      textInputValue: newValue,
      mention_ids: mentions.map((mention: any) => mention.id),
    });

  };

  const calculatedStyle = useMemo(() => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const { left, right, height } = rect;

      return {
        ...defaultStyle,
        suggestions: {
          ...defaultStyle.suggestions,
          position: 'fixed',
          top: null,
          bottom: height,
          left: left + 10,
          width: right - left - 20,
        }
      }
    }
    return defaultStyle;
  }, [containerRef.current, textInputValue]);

  return (
    <div
      ref={containerRef}
      className="relative -mx-5 border-t border-solid border-gray-200 p-[36px]"
    >
      <div className="absolute right-[1px] z-10">
        {createThreadMessage.isLoading || createDiscussionThread.isLoading ? (
          <LoadingSpinner size={24} />
        ) : (
          <Button
            type="button"
            variant="icon"
            iconName="send-2"
            disabled={!isInputContainsText}
            onClick={() => {
              if (isInputContainsText) {
                if (discussionThreadHasBeenCreated) {
                  createThreadMessage.mutate({
                    content: textInputValue,
                    mention_ids,
                  });
                } else {
                  createDiscussionThread.mutate({
                    content: textInputValue,
                    mention_ids,
                  });
                }
                setValue({
                  textInputValue: '',
                  mentions: [],
                });
              }
            }}
          />
        )}
      </div>

      <MentionsInput
        value={textInputValue}
        onChange={onChange}
        style={calculatedStyle}
        placeholder="Write your note"
        a11ySuggestionsListLabel="Suggested mentions"
      >
        <Mention
          data={users}
          style={defaultMentionStyle}
          renderSuggestion={MentionSuggestion}
          markup="[@__display__](mention:__id__)"
        />
      </MentionsInput>


    </div>
  );
};

export { DiscussionThreadInput };
