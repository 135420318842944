import { useMutation } from '@tanstack/react-query';
import { unpairTransaction } from '@/lib/coloc-api/admin/rent_transaction';
import { showToast } from '@/services/toast-service';
import { useGetRentsListContext } from '@/features/admin/rents/components/rents-list/hooks/use-get-rents-list';

const useUnpairTransaction = () => {
  const { updateRentInCache } = useGetRentsListContext()!;

  const mutate = useMutation({
    mutationKey: ['unpairTransaction'],
    mutationFn: (transactionId: number) => {
      return unpairTransaction(transactionId);
    },
    onSuccess: (data) => {
      updateRentInCache(data.data.rent);
      showToast.success({
        title: 'Transaction',
        text: 'Transaction unpaired successfully',
      });
    },
    onError: (error) => {
      const keys = Object.keys(error.response.data.errors);
      keys.forEach((key: string) => {
        const message = error.response.data.errors[key];
        showToast.error({
          title: key,
          text: [message].join(', '),
        });
      });
    },
  });

  return {
    mutate,
  };
};

export { useUnpairTransaction };
