import { getI18n } from 'react-i18next';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { RentReportListSortType } from '@/lib/coloc-api/admin';
import { useColocApi } from '@/hooks/use-coloc-api';
import { RentReportStatus } from '@/features/admin/rents/types';

const i18n = getI18n();

type ListReportStatusOption = {
  status: RentReportStatus;
  displayValue: string;
};

const reportStatus: Array<ListReportStatusOption> = [
  {
    status: 'final',
    displayValue: i18n.t('js.features.admin.rent_report.status_options.final'),
  },
  {
    status: 'temporary',
    displayValue: i18n.t(
      'js.features.admin.rent_report.status_options.temporary'
    ),
  },
];

const useRentReportListFilters = () => {
  const [selectedReportStatus, setSelectedReportStatus] = useState<
    ListReportStatusOption | undefined
  >(undefined);
  const [reportStatusQuery, setReportStatusQuery] = useState('');
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const { setColumnToSort, sortDirection, selectedColumnToSort } =
    useColocApi<RentReportListSortType>()!;
  const [selectedHouse, setSelectedHouse] = useState<
    { id: string; name: string } | undefined
  >(undefined);
  const [housesQuery, setHousesQuery] = useState('');

  // Set default sorting to date
  useEffect(() => {
    setColumnToSort('date');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDateRange = useCallback(
    (new_date_range: [Date | null, Date | null]) => {
      setStartDate(new_date_range[0] ?? undefined);
      setEndDate(new_date_range[1] ?? undefined);
    },
    []
  );

  const filteredListReportsStatusArray = useMemo(() => {
    return reportStatus.filter((statusObject) =>
      statusObject.displayValue.includes(reportStatusQuery)
    );
  }, [reportStatusQuery]);

  return {
    selectedReportStatus,
    setSelectedReportStatus,
    setReportStatusQuery,
    reportStatusQuery,
    filteredListReportsStatusArray,
    startDate,
    endDate,
    setDateRange,
    setColumnToSort,
    sortDirection,
    selectedColumnToSort,
    selectedHouse,
    setSelectedHouse,
    housesQuery,
    setHousesQuery,
  };
};

const GetRentReportListFiltersContext = createContext<
  ReturnType<typeof useRentReportListFilters> | undefined
>(undefined);

const GetRentReportListFiltersContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const hook = useRentReportListFilters();

  return (
    <GetRentReportListFiltersContext.Provider value={hook}>
      {children}
    </GetRentReportListFiltersContext.Provider>
  );
};

const useRentReportListFiltersContext = () => {
  const context = useContext(GetRentReportListFiltersContext);

  if (context === null) {
    throw new Error(
      'useRentReportListFiltersContext must be used within a GetRentReportListFiltersContextProvider'
    );
  }

  return context;
};

export {
  GetRentReportListFiltersContextProvider,
  useRentReportListFiltersContext,
};
