import { useMutation } from '@tanstack/react-query';

import { useTranslation } from 'react-i18next';
import { showToast } from '@/services/toast-service';
import { updateRoomAvailability } from '@/services/coloc-api/admin';
import { useGetRoomsAssignmentContext } from '../use-get-room-assignment-list';

const useUpdateRoomAvailability = () => {
  const { t } = useTranslation();
  const { setShowUpdateRoomAvailability, fetch } =
    useGetRoomsAssignmentContext()!;

  const mutate = useMutation({
    mutationKey: ['updateRoomAvailability'],
    mutationFn: (params: { room_id: string; date?: string }) => {
      return updateRoomAvailability({
        id: Number(params.room_id),
        ...(params.date && {
          date: params.date,
        }),
      });
    },
    onSuccess: () => {
      setShowUpdateRoomAvailability({ show: false, assignment: undefined });
      fetch.refetch();
      showToast.success({
        text:
          t(
            `js.features.admin.contracts.change_room_availability_modal.confirmation`
          ) ?? '',
      });
    },
    onError: () => {
      setShowUpdateRoomAvailability({ show: false, assignment: undefined });
      showToast.error({});
    },
  });

  return {
    mutate,
  };
};

export { useUpdateRoomAvailability };
