import { useCallback, useMemo } from 'react';
import { getI18n } from 'react-i18next';
import { LangOption } from '@/types/global';

const useLocale = () => {
  const i18n = getI18n();

  const localeOptionsFull: {
    [key in LangOption]: string;
  } = {
    en: 'English (EN)',
    fr: 'Français (FR)',
    nl: 'Nederlands (NL)',
  };

  const currentLocale = useMemo(() => {
    return i18n.language;
  }, [i18n.language]);

  const setLocale = useCallback(
    (locale: LangOption) => {
      const pathName = window.location.pathname;
      let newPathName = window.location.pathname;

      if (currentLocale !== locale) {
        if (pathName.includes(`/${currentLocale}/`)) {
          newPathName = pathName.replace(`/${currentLocale}/`, `/${locale}/`);
        } else {
          newPathName = `/${locale}/${pathName}`;
        }
      }

      window.location.pathname = newPathName;
    },
    [currentLocale]
  );

  return {
    localeOptionsFull,
    currentLocale,
    setLocale,
  };
};

export { useLocale };
