import { useMutation } from '@tanstack/react-query';

import { useTranslation } from 'react-i18next';
import { ignoreTransaction } from '@/lib/coloc-api/admin';
import { showToast } from '@/services/toast-service';
import { useGetTransactionsListContext } from '../use-get-transactions-list';

const useIgnoreTransaction = () => {
  const { updateTransactionInCache } = useGetTransactionsListContext()!;
  const { t } = useTranslation();

  const mutate = useMutation({
    mutationKey: ['ignoreTransaction'],
    mutationFn: (params: { transactionId: string }) => {
      return ignoreTransaction({
        transaction_id: params.transactionId,
      });
    },
    onSuccess: (data) => {
      updateTransactionInCache(data.data);
      showToast.success({
        text:
          t(
            `js.features.admin.transactions.ignore_transaction.confirmation_message`
          ) ?? '',
      });
    },
    onError: () => {
      showToast.error({});
    },
  });

  return {
    mutate,
  };
};

export { useIgnoreTransaction };
