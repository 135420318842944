import React from 'react';
import { IconSaxIcon } from '@/components/design-system/atoms/icon';
import { PageControlButtonProps } from './types';
import { composeClassName } from '@/utils/util_helpers';
import { useWindowDimensions } from '@/hooks/use-window-dimensions';
import { theme } from '@/utils/util_theme';

const PageControlButton: React.FC<PageControlButtonProps> = ({
  disabled = false,
  variant,
  className,
  ...props
}) => {
  const { screenIsLargeOrEqualThan } = useWindowDimensions();

  return (
    <button
      className={composeClassName(
        'flex items-center justify-center rounded-full border-[2px] border-solid border-E50 p-[1.2rem] disabled:border-E30',
        'sm:p-3',
        className && className
      )}
      type="button"
      disabled={disabled}
      {...props}
    >
      <IconSaxIcon
        name={variant === 'previous' ? 'arrow-left' : 'arrow-right'}
        size={screenIsLargeOrEqualThan('sm') ? 24 : 20}
        strokeWidth={2}
        strokeColor={
          disabled ? theme.colors?.E30 : theme.colors?.text.secondary
        }
      />
    </button>
  );
};

export { PageControlButton };
