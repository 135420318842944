<template>
  <section class="main-bg w-full pt-10 lg:overflow-hidden lg:pt-[92px]">
    <div
      class="
        m-auto
        w-full
        px-4
        pt-2
        pb-7
        lg:pt-[130px]
        lg:pb-[140px]
        2xl:max-w-8xl
      "
    >
      <div class="grid w-full grid-cols-1 lg:grid-cols-2">
        <div
          class="
            order-last
            flex
            w-full
            flex-col
            items-center
            space-y-7
            lg:order-first
            lg:items-start
          "
        >
          <div class="m-auto flex flex-col items-start space-y-4 lg:w-full">
            <h1
              class="
                font-Vollkorn
                text-[28px]
                font-bold
                text-primary-100
                lg:text-6xl
              "
              v-html="t('vue_components.spot_a_house.get_a_cash_reward')"
            ></h1>
            <p
              class="text-base font-medium text-[#414141] lg:text-[22px]"
              v-html="t('vue_components.spot_a_house.get_a_cash_reward2')"
            ></p>
          </div>
          <div
            class="
              hidden
              w-full
              rounded-full
              border border-primary-200
              bg-[#FFFAE9]
              py-5
              px-4
              xl:block
            "
          >
            <div class="flex w-full items-center justify-between">
              <div class="flex items-center space-x-2">
                <IconHouse />
                <h1
                  class="
                    whitespace-nowrap
                    font-Vollkorn
                    text-lg
                    font-bold
                    text-primary-100
                  "
                >
                  {{ t('vue_components.spot_a_house.spot_a_house') }}
                </h1>
                <IconArowRight />
              </div>
              <div class="flex items-center space-x-2">
                <IconDeal />

                <h1
                  class="
                    whitespace-nowrap
                    font-Vollkorn
                    text-lg
                    font-bold
                    text-primary-100
                  "
                >
                  {{ t('vue_components.spot_a_house.we_make_the_deal') }}
                </h1>
                <IconArowRight />
              </div>
              <div class="flex items-center space-x-2">
                <IconRewards />

                <h1
                  class="
                    whitespace-nowrap
                    font-Vollkorn
                    text-lg
                    font-bold
                    text-[#1B998B]
                  "
                >
                  {{ t('vue_components.spot_a_house.you_get_rewarded') }}
                </h1>
              </div>
            </div>
          </div>
          <div
            class="
              md:m-0
              m-auto
              flex
              w-full
              max-w-[250px]
              flex-col
              items-center
              space-y-4
              xl:hidden
            "
          >
            <div
              class="
                flex
                w-full
                rounded-full
                border border-primary-200
                bg-[#FFFAE9]
                py-2
              "
            >
              <div class="m-auto flex items-center space-x-4">
                <IconHouse />
                <h1
                  class="
                    whitespace-nowrap
                    font-Vollkorn
                    text-sm
                    font-bold
                    text-primary-100
                  "
                >
                  {{ t('vue_components.spot_a_house.spot_a_house') }}
                </h1>
              </div>
            </div>
            <svg
              width="11"
              height="14"
              viewBox="0 0 11 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.45312 8.61914L5.49979 12.6658L9.54646 8.61914"
                stroke="#7A7A7A"
                stroke-width="1.2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.5 1.33398L5.5 12.554"
                stroke="#7A7A7A"
                stroke-width="1.2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <div
              class="
                flex
                w-full
                rounded-full
                border border-primary-200
                bg-[#FFFAE9]
                py-2
              "
            >
              <div class="m-auto flex items-center space-x-4">
                <IconDeal />
                <h1
                  class="
                    whitespace-nowrap
                    font-Vollkorn
                    text-sm
                    font-bold
                    text-primary-100
                  "
                >
                  {{ t('vue_components.spot_a_house.we_make_the_deal') }}
                </h1>
              </div>
            </div>
            <svg
              width="11"
              height="14"
              viewBox="0 0 11 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.45312 8.61914L5.49979 12.6658L9.54646 8.61914"
                stroke="#7A7A7A"
                stroke-width="1.2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.5 1.33398L5.5 12.554"
                stroke="#7A7A7A"
                stroke-width="1.2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <div
              class="
                flex
                w-full
                rounded-full
                border border-primary-200
                bg-[#FFFAE9]
                py-2
              "
            >
              <div class="m-auto flex items-center space-x-4">
                <IconRewards />
                <h1
                  class="
                    whitespace-nowrap
                    font-Vollkorn
                    text-sm
                    font-bold
                    text-[#1B998B]
                  "
                >
                  {{ t('vue_components.spot_a_house.you_get_rewarded') }}
                </h1>
              </div>
            </div>
          </div>
          <!-- <div
            class="
              flex flex-col
              items-start
              text-[#414141] text-sm
              lg:text-lg
              font-normal
              pl-9
              lg:pl-6
              p-4
            "
          >
            <ul
              class="list-disc"
              v-html="t('vue_components.spot_a_house.text')"
            ></ul>
          </div> -->

          <button
            @click="GetStarted"
            class="
              h-[44px]
              w-[128px]
              rounded-sm
              bg-[#FECE1F]
              text-center text-sm
              font-medium
              text-primary-100
              hover:bg-opacity-60
              hover:text-opacity-60
              lg:h-[60px]
              lg:w-[196px]
              lg:text-lg
              lg:font-bold
            "
          >
            {{ t('vue_components.spot_a_house.get_started') }}
          </button>
        </div>
        <div class="relative h-full w-full">
          <div
            class="flex h-full w-full lg:absolute lg:-top-[200px] lg:-right-8"
            @mouseover="show = false"
            @mouseleave="show = true"
          >
            <DesktopHero :show="show" class="hidden lg:block 2xl:absolute" />
            <MobileHero class="m-auto lg:hidden" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import DesktopHero from '../hero/DesktopHero.vue';
import MobileHero from '../hero/MobileHero.vue';
import IconArowRight from '../Icons/IconArowRight.vue';
import IconDeal from '../Icons/IconDeal.vue';
import IconRewards from '../Icons/IconRewards.vue';
import IconHouse from '../Icons/IconHouse.vue';

export default {
  components: {
    DesktopHero,
    MobileHero,
    IconArowRight,
    IconDeal,
    IconRewards,
    IconHouse,
  },
  data() {
    return {
      show: true,
    };
  },
  methods: {
    GetStarted() {
      // scroll to ContactUs id
      window.scrollTo({
        top: document.getElementById('ContactUs').offsetTop,
        behavior: 'smooth',
      });
    },
  },
};
</script>
