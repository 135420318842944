import React, { useState } from 'react';
import { SlideOver } from '@/components/design-system/molecules/slide-over';
import { NotificationCard } from './NotificationCard';
import { useNotifications } from '../hooks';


const NotificationsSlideOver = () => {
  const { isOpened, closeNotification, notifications } = useNotifications()!;

  return (
    <SlideOver
      show={isOpened}
      title="Notifications"
      onClose={()=> closeNotification()}
    >
      <SlideOver.SlideOverContent>
        <div className="-m-5">
          {notifications.map((notification) => (
            <NotificationCard
              key={notification.id}
              notification={notification}
            />
          ))
          }
        </div>
      </SlideOver.SlideOverContent>
    </SlideOver>
  );
};


export { NotificationsSlideOver };
