<template>
  <form
    @submit.prevent="submit"
    class="col-span-1 w-full space-y-4 lg:col-span-2 lg:space-y-5"
  >
    <div class="grid w-full grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-7">
      <div class="flex flex-col items-start space-y-2">
        <label
          for="application_first_name"
          class="text-sm font-medium text-text-primary lg:text-lg"
          >{{ t('vue_components.spot_a_house.form.first_name') }}</label
        >
        <input
          id="application_first_name"
          v-model="application.first_name"
          :placeholder="t('vue_components.spot_a_house.form.first_name')"
          type="text"
          class="
            h-7
            w-full
            rounded-sm
            bg-E30
            px-4
            py-3
            text-base
            font-normal
            placeholder-E100
            outline-none
          "
        />
        <div v-if="errors.first_name" class="text-red">
          {{ errors.first_name[0] }}
        </div>
      </div>
      <div class="flex flex-col items-start space-y-2">
        <label
          for="last_name"
          class="text-sm font-medium text-text-primary lg:text-lg"
          >{{ t('vue_components.spot_a_house.form.last_name') }}</label
        >
        <input
          v-model="application.last_name"
          :placeholder="t('vue_components.spot_a_house.form.last_name')"
          type="text"
          class="
            h-7
            w-full
            rounded-sm
            bg-E30
            px-4
            py-3
            text-sm
            font-normal
            placeholder-E100
            outline-none
            lg:text-base
          "
        />
        <div v-if="errors.last_name" class="text-red">
          {{ errors.last_name[0] }}
        </div>
      </div>
    </div>
    <div class="grid w-full grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-7">
      <div class="flex flex-col items-start space-y-2">
        <label
          for="Email"
          class="text-sm font-medium text-text-primary lg:text-lg"
          >{{ t('vue_components.spot_a_house.form.email') }}</label
        >
        <input
          v-model="application.email"
          :placeholder="t('vue_components.spot_a_house.form.email_placeholder')"
          type="email"
          class="
            h-7
            w-full
            rounded-sm
            bg-E30
            px-4
            py-3
            text-sm
            font-normal
            placeholder-E100
            outline-none
            lg:text-base
          "
        />
        <div v-if="errors.email" class="text-red">
          {{ errors.email[0] }}
        </div>
      </div>
      <div class="flex flex-col items-start space-y-2">
        <label
          for="Phone"
          class="text-sm font-medium text-text-primary lg:text-lg"
          >{{ t('vue_components.spot_a_house.form.phone_number') }}</label
        >
        <input
          v-model="application.phone_number"
          :placeholder="t('vue_components.spot_a_house.form.phone_number')"
          type="tel"
          class="
            h-7
            w-full
            rounded-sm
            bg-E30
            px-4
            py-3
            text-sm
            font-normal
            placeholder-E100
            outline-none
            lg:text-base
          "
        />
        <div v-if="errors.phone_number" class="text-red">
          {{ errors.phone_number[0] }}
        </div>
      </div>
    </div>
    <div class="grid w-full grid-cols-1 gap-4 lg:gap-7">
      <div class="flex flex-col items-start space-y-2">
        <label
          for="info"
          class="text-sm font-medium text-text-primary lg:text-lg"
          >{{ t('vue_components.spot_a_house.form.info') }}</label
        >
        <textarea
          cols="30"
          rows="10"
          v-model="application.info"
          :placeholder="t('vue_components.spot_a_house.form.info_placeholder')"
          class="
            w-full
            rounded-sm
            bg-E30
            px-4
            py-3
            text-sm
            font-normal
            placeholder-E100
            outline-none
            lg:text-base
          "
        ></textarea>
        <div v-if="errors.info" class="text-red">
          {{ errors.info[0] }}
        </div>
      </div>
    </div>
    <div class="grid w-full grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-7">
      <div class="flex flex-col items-start space-y-2">
        <label
          for="City"
          class="text-sm font-medium text-text-primary lg:text-lg"
          >{{ t('vue_components.spot_a_house.form.city') }}</label
        >
        <select
          v-model="application.city"
          class="
            h-7
            w-full
            rounded-sm
            bg-E30
            px-4
            py-3
            text-sm
            font-normal
            placeholder-E100
            outline-none
            lg:text-base
          "
        >
          <option value="" disabled selected>
            {{ t('vue_components.spot_a_house.form.city_placeholder') }}
          </option>
          <option value="Brussels">Brussels</option>
          <option value="Ghent">Ghent</option>
          <option value="Bruges">Bruges</option>
          <option value="Leuven">Leuven</option>
          <option value="Antwerp">Antwerp</option>
          <option value="Kortrijk">Kortrijk</option>
        </select>
        <div v-if="errors.city" class="text-red">
          {{ errors.city[0] }}
        </div>
      </div>
      <div class="flex flex-col items-start space-y-2">
        <label
          for="Region"
          class="text-sm font-medium text-text-primary lg:text-lg"
          >{{ t('vue_components.spot_a_house.form.region') }}</label
        >
        <input
          v-model="application.region"
          :placeholder="
            t('vue_components.spot_a_house.form.region_placeholder')
          "
          type="text"
          class="
            h-7
            w-full
            rounded-sm
            bg-E30
            px-4
            py-3
            text-sm
            font-normal
            placeholder-E100
            outline-none
            lg:text-base
          "
        />
        <div v-if="errors.region" class="text-red">
          {{ errors.region[0] }}
        </div>
      </div>
    </div>
    <div class="grid w-full grid-cols-1 gap-10">
      <div class="flex flex-col items-start space-y-2">
        <label
          for="Attachment"
          class="text-sm font-medium text-text-primary lg:text-lg"
          >{{ t('vue_components.spot_a_house.form.attachment') }}</label
        >
        <div
          @drop.prevent="onDrop"
          @dragenter.prevent
          @dragover.prevent
          class="
            flex
            min-h-[100px]
            w-full
            flex-col
            rounded-sm
            border border-dashed border-J300
            bg-E30
          "
        >
          <div class="m-auto flex">
            <div class="m-auto flex items-center space-x-2">
              <span class="text-sm font-normal text-E100">{{
                t('vue_components.spot_a_house.form.drag_and_drop_or')
              }}</span>
              <button
                type="button"
                @click="() => $refs.attachmentFile.click()"
                class="cursor-pointer text-sm font-normal text-J300"
              >
                {{ t('vue_components.spot_a_house.form.browse') }}
              </button>
            </div>
          </div>

          <input
            type="file"
            multiple
            style="display: none"
            @change="handleAttach"
            ref="attachmentFile"
          />
          <div
            v-for="attachment in application.attachments"
            :key="attachment.name"
            class="m-auto"
          >
            <span class="text-sm font-normal">{{ attachment.name }}</span>
            <button
              type="button"
              @click="removeAttachment(attachment)"
              class="cursor-pointer text-sm font-normal text-[#1B998B]"
            >
              {{ t('vue_components.spot_a_house.form.remove_attachment') }}
            </button>
          </div>
        </div>
        <span class="text-sm font-normal text-[#7A7A7A] lg:text-base">{{
          t('vue_components.spot_a_house.form.attachments_hint')
        }}</span>
      </div>
    </div>
    <div class="col-span-2 w-full">
      <button
        class="
          h-[44px]
          w-full
          rounded-sm
          bg-[#FECE1F]
          text-center text-sm
          font-medium
          text-primary-100
          hover:bg-opacity-60
          hover:text-opacity-60
          lg:h-[60px]
          lg:w-[159px]
          lg:text-lg
          lg:font-bold
        "
      >
        {{ t('vue_components.spot_a_house.form.submit') }}
      </button>
    </div>

    <DoneModal v-if="submitted" @close="submitted = false" />
  </form>
</template>

<script>
import Rails from '@rails/ujs';
import axios from 'axios';
import DoneModal from './modals/DoneModal.vue';

const initialApplication = {
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  info: '',
  city: '',
  region: '',
  attachments: [],
};

const initialErrors = {
  first_name: null,
  last_name: null,
  email: null,
  phone_number: null,
  info: null,
  city: null,
  region: null,
};

export default {
  data() {
    return {
      application: JSON.parse(JSON.stringify(initialApplication)),
      errors: JSON.parse(JSON.stringify(initialErrors)),
      submitted: false,
    };
  },
  components: {
    DoneModal,
  },
  methods: {
    resetApplication() {
      this.application = JSON.parse(JSON.stringify(initialApplication));
    },
    resetErrors() {
      this.errors = JSON.parse(JSON.stringify(initialErrors));
    },
    async submit() {
      const formData = new FormData();
      formData.set('application[first_name]', this.application.first_name);
      formData.set('application[last_name]', this.application.last_name);
      formData.set('application[email]', this.application.email);
      formData.set('application[phone_number]', this.application.phone_number);
      formData.set('application[info]', this.application.info);
      formData.set('application[city]', this.application.city);
      formData.set('application[region]', this.application.region);

      this.application.attachments.forEach((attachment) => {
        formData.append('application[attachments][]', attachment);
      });

      try {
        await axios.post('/api/v1/house_spotting/applications', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-CSRF-Token': Rails.csrfToken(),
          },
          withCredentials: true,
        });

        this.resetApplication();
        this.resetErrors();
        this.submitted = true;
      } catch (error) {
        if (!error.response) {
          throw error;
        }

        this.errors = error.response.data.errors;
      }
    },
    removeAttachment(attachment) {
      this.application.attachments = this.application.attachments.filter(
        (a) => a.name !== attachment.name
      );
      this.$refs.attachmentFile.value = '';
    },
    handleAttach(e) {
      Array.from(e.target.files).forEach((file) => {
        this.application.attachments.push(file);
      });
    },
    onDrop(e) {
      Array.from(e.dataTransfer.files).forEach((file) => {
        this.application.attachments.push(file);
      });
    },
  },
};
</script>
