/* eslint no-multi-assign: 0 */
import { Controller } from 'stimulus';
import SignaturePad from 'signature_pad';

export default class extends Controller {
  static targets = ['canvas', 'hiddenInput', 'fileInput', 'filePreview'];

  connect() {
    this.signaturePad = new SignaturePad(this.canvas, {
      onEnd: () => this.changeInput(this.canvasDataURL),
    });
    this.resizeCanvas();
  }

  draw(e) {
    this.signaturePad.clear();
    e.preventDefault();
    const { font, fontSize } = e.target.dataset;
    const ctx = this.canvas.getContext('2d');
    ctx.font = `${fontSize} ${font}`;
    ctx.textAlign = 'start';
    ctx.textBaseline = 'top';
    const offsetY =
      // eslint-disable-next-line radix
      this.canvas.getBoundingClientRect().height / 2 - parseInt(fontSize) / 2;

    ctx.fillText(e.target.innerText, 16, offsetY);
    this.changeInput(this.canvasDataURL);
  }

  resizeCanvas() {
    const ratio = Math.max(window.devicePixelRatio);
    this.canvas.width = this.canvas.offsetWidth * ratio;
    this.canvas.height = this.canvas.offsetHeight * ratio;
    this.canvas.getContext('2d').scale(ratio, ratio);
    this.signaturePad.clear();
  }

  changeInput(dataURL) {
    this.hiddenInput.value = dataURL;
  }

  clear(e) {
    e.preventDefault();
    this.signaturePad.clear();
  }

  loadFile() {
    this.signaturePad.clear();
    const { fileInput } = this;
    const reader = new FileReader();
    reader.onload = () => this.createAndLoadImage(reader);
    reader.readAsDataURL(fileInput.files[0]);
  }

  createAndLoadImage(reader) {
    this.filePreviewTarget.innerHTML = '';
    const dataURL = reader.result;
    const img = new Image();
    img.onload = () => {
      this.filePreviewTarget.appendChild(img);
      this.changeInput(dataURL);
    };
    img.src = dataURL;
    img.height = 100;
  }

  get canvasDataURL() {
    return this.canvas.toDataURL();
  }

  get canvas() {
    return this.canvasTarget;
  }

  get hiddenInput() {
    return this.hiddenInputTarget;
  }

  get fileInput() {
    return this.fileInputTarget;
  }
}
