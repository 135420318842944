import { useInfiniteQuery } from '@tanstack/react-query';

import { RoomListSortType, getRoomList } from '@/lib/coloc-api/admin';
import { useDebounce } from '@/hooks/use-debounce';
import { useColocApi } from '@/hooks/use-coloc-api';

const useGetRoomsInfinite = (params: {
  searchQuery?: string;
  selectedHouseId?: number;
}) => {
  const { setPage, setPageLimit, page, pageLimit } =
    useColocApi<RoomListSortType>()!;
  const debouncedSearchQuery: string = useDebounce(
    params.searchQuery ?? '',
    500
  );

  const fetch = useInfiniteQuery({
    queryKey: [
      'getRoomsList',
      debouncedSearchQuery,
      page,
      pageLimit,
      params.selectedHouseId,
    ],
    queryFn: ({ pageParam = 1 }) =>
      getRoomList({
        page_limit: 5,
        ...(pageParam && { page: pageParam }),
        ...(debouncedSearchQuery &&
          debouncedSearchQuery !== '' && { query: debouncedSearchQuery }),
        ...(params.selectedHouseId && { house_id: params.selectedHouseId }),
      }),
    keepPreviousData: true,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.data.pagination.total_pages > pages.length) {
        return pages.length + 1;
      }

      return undefined;
    },
  });

  return {
    pageLimit,
    setPageLimit,
    fetch,
    setPage,
  };
};

export { useGetRoomsInfinite };
