<template>
  <div class id="press">
    <h2>Press & Media Kit</h2>
    <p>
      Please send Press Enquiries to
      <a
        href="mailto:press@colochousing.com?subject=I would like more info about Coloc Housing"
      >press@colochousing.com</a>
    </p>
    <h3>Logo</h3>
    <ul class="c-dwlList">
      <li class="c-dwlList__item">
        <a
          href="https://www.dropbox.com/s/8ktw0a1zu71ln4f/Coloc%20HOUSING%20%28w%3A%20bcg%29.png?dl=0"
          class="c-dwlList__link"
          download
        >
          <picture class="c-dwlList__picture">
            <img
              :src="require('assets/images/press/logo-coloc-housing.png')"
              alt
              class="c-dwlList__img"
              loading="lazy"
            />
          </picture>
          <span class="c-dwlList__filename">
            <IconDownload />coloc_housing_logo.png
          </span>
        </a>
      </li>
      <li class="c-dwlList__item">
        <a
          href="https://www.dropbox.com/s/2e4372b10apisuu/logo-transparent.png?dl=0"
          class="c-dwlList__link"
          download
        >
          <picture class="c-dwlList__picture">
            <img
              :src="
                require('assets/images/press/logo-coloc-housing-white.jpeg')
              "
              alt
              class="c-dwlList__img"
              loading="lazy"
            />
          </picture>
          <span class="c-dwlList__filename">
            <IconDownload />coloc_housing_logo_white.png
          </span>
        </a>
      </li>
    </ul>
    <h3>Photos</h3>
    <ul class="c-dwlList">
      <li class="c-dwlList__item">
        <a
          href="https://www.dropbox.com/s/hngtyw132a5lzk1/eetkamer%289%29.jpg?dl=0"
          class="c-dwlList__link"
          download
        >
          <picture class="c-dwlList__picture">
            <img
              :src="
                require('assets/images/press/zandpoort-living-room-coloc-housing.jpeg')
              "
              alt="Miniature picture of the coloc housing logo with a color gradient from orange to yellow in the letters"
              class="c-dwlList__img"
              loading="lazy"
            />
          </picture>
          <span class="c-dwlList__filename">
            <IconDownload />zandpoort_kitchen.jpg
          </span>
        </a>
      </li>
      <li class="c-dwlList__item">
        <a
          href="https://www.dropbox.com/s/y6888hu66215g3b/Grand-Hospice-24-Living-Room.jpg?dl=0"
          class="c-dwlList__link"
          download
        >
          <picture class="c-dwlList__picture">
            <img
              :src="
                require('assets/images/press/grand-hospice-living-room-coloc-housing.jpeg')
              "
              alt="Miniature picture of the coloc housing logo in white with a black background"
              class="c-dwlList__img"
              loading="lazy"
            />
          </picture>
          <span class="c-dwlList__filename">
            <IconDownload />grand_hospice_living_room.jpg
          </span>
        </a>
      </li>
      </li>
      <li class="c-dwlList__item">
        <a
          href="https://www.dropbox.com/s/4yk6m4d13rwumt7/kamer-6%282%29.jpg?dl=0"
          class="c-dwlList__link"
          download
        >
          <picture class="c-dwlList__picture">
            <img
              :src="
                require('assets/images/press/zandpoort-bedroom-coloc-housing.jpeg')
              "
              alt="The miniature photo of one of the the bedroom from the colocation in Zandpoort in Gent"
              class="c-dwlList__img"
              loading="lazy"
            />
          </picture>
          <span class="c-dwlList__filename">
            <IconDownload />zandpoort_bedroom.jpg
          </span>
        </a>
      </li>
      <li class="c-dwlList__item">
        <a
          href="https://www.dropbox.com/s/lmxnww6cr955z0p/kamer-9%285%29.jpg?dl=0"
          class="c-dwlList__link"
          download
        >
          <picture class="c-dwlList__picture">
            <img
              :src="
                require('assets/images/press/zandpoort-bedroom-2-coloc-housing.jpeg')
              "
              alt="The miniature photo of one of the the bedrooms from the colocation in Zandpoort in Gent"
              class="c-dwlList__img"
              loading="lazy"
            />
          </picture>
          <span class="c-dwlList__filename">
            <IconDownload />zandpoort_bedroom.jpg
          </span>
        </a>
      </li>
      <li class="c-dwlList__item">
        <a
          href="https://www.dropbox.com/s/r7dkotlv97iiwms/living-room%282%29.jpg?dl=0"
          class="c-dwlList__link"
          download
        >
          <picture class="c-dwlList__picture">
            <img
              :src="
                require('assets/images/press/tiensevest-living-room-coloc-housing.jpeg')
              "
              alt="The miniature photo of the living room from the colocation in Tiensevest in Leuven"
              class="c-dwlList__img"
              loading="lazy"
            />
          </picture>
          <span class="c-dwlList__filename">
            <IconDownload />tiensevest_living_room.jpg
          </span>
        </a>
      </li>
      <li class="c-dwlList__item">
        <a
          href="https://www.dropbox.com/s/qo15nrcz0ya9imt/Zandpoort-02a.jpg?dl=0"
          class="c-dwlList__link"
          download
        >
          <picture class="c-dwlList__picture">
            <img
              :src="
                require('assets/images/press/zandpoort-kitchen-flat-coloc-housing.jpeg')
              "
              alt="The miniature photo of the kitchen and living room in the flat at the last floor from the colocation in Zandpoort in Gent"
              class="c-dwlList__img"
              loading="lazy"
            />
          </picture>
          <span class="c-dwlList__filename">
            <IconDownload />zandpoort_flat.jpg
          </span>
        </a>
      </li>
      <li class="c-dwlList__item">
        <a
          href="https://www.dropbox.com/s/5ld4d0e9ro5zem4/badkamer-2%284%29.jpg?dl=0"
          class="c-dwlList__link"
          download
        >
          <picture class="c-dwlList__picture">
            <img
              :src="
                require('assets/images/press/zandpoort-bathroom-coloc-housing.jpeg')
              "
              alt="The miniature photo of the bathroom from the colocation in Zanpoort in Gent"
              class="c-dwlList__img"
              loading="lazy"
            />
          </picture>
          <span class="c-dwlList__filename">
            <IconDownload />zandpoort_bathroom.jpg
          </span>
        </a>
      </li>
    </ul>
    <div class="center">
      <a
        href="https://www.dropbox.com/sh/qokhl8u982dzokw/AAAbNgT1BXlMr3Uhjltypet6a?dl=0"
        class="btn btn--black"
      >
        <IconDownload />Download all the files
      </a>
    </div>
    <h3>In the news</h3>
    <ul class="c-feed">
      <li class="c-feed__item">
        <a
           target="_blank"
          href="https://plus.lesoir.be/353330/article/2021-02-05/le-cohousing-eldorado-immobilier-dans-le-viseur-des-communes"
          class="c-feed__link"
        >
          <picture class="c-feed__picture">
            <img
              src="https://plus.lesoir.be/sites/default/files/dpistyles_v2/ena_16_9_extra_big/2021/02/05/node_353330/27909127/public/2021/02/05/B9726034023Z.1_20210205122909_000+GAVHHP4IA.1-0.jpg"
              alt="Illustration photo from Le Soir showing the interior of a colocation in Brussels."
              loading="lazy"
              class="c-feed__img"
            />
          </picture>
          <div class="c-feed__details">
            <p class="c-feed__metadata">
              Le Soir
              <span>•</span> Arthur Sente
              <span>•</span> 5 feb. 2021
            </p>
            <h4
              class="c-feed__title"
            >Le «cohousing», eldorado immobilier dans le viseur des communes</h4>
            <p class="c-feed__excerpt">
              Alors que le modèle de la « colocation de luxe » s’est répandu à
              grande vitesse, des échevins s’inquiètent. Effets sur les prix,
              densification, normes urbanistiques… Les sociétés qui ont suivi le
              filon d’or sont priées de montrer patte blanche.
            </p>
            <span class="c-feed__btn">Read the article</span>
          </div>
        </a>
      </li>
      <li class="c-feed__item">
        <a
          target="_blank"
          href="https://www.tijd.be/partner/vastgoed/ion/duurzaam-samenwonen-in-een-authentiek-kader/10370127.html"
          class="c-feed__link"
        >
          <picture class="c-feed__picture">
            <img
              src="https://images.tijd.be/view?iid=Elvis:F9_nfnUxqL9AKzg3rMNJyo&context=ONLINE&ratio=16/9&width=1024&u=1646049922000"
              alt="Duurzaam samenwonen in een authentiek kader"
              loading="lazy"
              class="c-feed__img"
            />
          </picture>
          <div class="c-feed__details">
            <p class="c-feed__metadata">
              De Tijd
              <span>•</span>
              <span>•</span> 28 feb. 2022
            </p>
            <h4 class="c-feed__title">
              Duurzaam samenwonen in een authentiek kader
            </h4>
            <p class="c-feed__excerpt">
              Wonen in het groen en toch vlak bij het centrum van de stad: Burenberg
              bewijst dat het kan. Projectontwikkelaar ION realiseert dit project op
              liefst twee hectare, op de site van de voormalige Acco-drukkerij en een
              voormalig klooster. ION koos bewust voor een mix van nieuwbouw en
              herbestemming van bestaande gebouwen, waarbij de waardevolle
              erfgoedelementen behouden blijven. Dat resulteert in een boeiende mix van moderne
              woonmogelijkheden op ieders maat.
            </p>
            <span class="c-feed__btn">Read the article</span>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
import IconDownload from './icons/IconDownload';
export default {
  components: {
    IconDownload,
  },
};
</script>
<style scoped>
li {
    margin-bottom: 10px;
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
ul {
  list-style: none;
  padding: 0;
}
h2 {
  font-size: 1.5rem;
}
h3 {
  display: block;
  margin-top: 24px;
  font-size: 1.2rem;
}
p {
  font-size: 1rem;
  line-height: 1.3;
}
.c-dwlList {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
}
.c-dwlList__item {
  flex: 0 1 16ch;
  margin-right: 16px;
  margin-bottom: 16px;
}
@supports (gap: 16px) {
  .c-dwlList {
    gap: 16px;
  }
  .c-dwlList__item {
    margin: 0;
  }
}
.c-dwlList__link {
  text-decoration: none;
}
.c-dwlList__picture {
  display: block;
  aspect-ratio: 16/9;
}
@supports (not (aspect-ratio: 16/9)) {
  .c-dwlList__picture {
    position: relative;
    height: 0;
    padding-bottom: 63%;
  }
  .c-dwlList__img {
    position: absolute;
    top: 0;
  }
}
.c-dwlList__filename {
  display: block;
  margin-top: 4px;
  text-align: right;
  font-size: 10px;
  color: #575757;
}
.c-dwlList__filename svg {
  vertical-align: bottom;
}
.c-feed__link {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.c-feed__picture {
  flex: 1 1 10ch;
}
@supports (not (gap: 16px)) {
  @media screen and (min-width: 601px) {
    .c-feed__picture {
      margin-right: 16px;
    }
  }
  @media screen and (max-width: 600px) {
    .c-feed__picture {
      margin-bottom: 16px;
    }
  }
}
.c-feed__details {
  position: relative;
  flex: 3 1 30ch;
}
.c-feed__metadata {
  margin-bottom: 8px;
  font-size: 14px;
  color: #575757;
}
.c-feed__metadata span {
  color: #e5e5e5;
}
.c-feed__link {
  text-decoration: none;
}
.c-feed__title {
  font-size: 18px;
}
.c-feed__btn {
  display: block;
  font-size: 14px;
  text-decoration: underline;
  text-align: right;
}
.c-feed__link:hover .c-feed__btn {
  text-decoration: none;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn {
  display: inline-block;
  padding: 8px 16px;
  text-decoration: none;
  font-size: 14px;
  color: #f7f6f6;
  background: #1a1a1a;
  transition: all 0.3s ease-in-out;
  border: none;
  cursor: pointer;
  border-radius: 0;
  line-height: 1.3;
}
@media (hover: hover) {
  .btn:hover {
    color: #1a1a1a;
    background: #fcce23;
  }
  .btn:hover svg > * {
    stroke: #1a1a1a;
  }
}
</style>
