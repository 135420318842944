import {
  useEffect,
  useState,
  createContext,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { getNotificationList, updateFcmToken } from '@/lib/coloc-api/admin';
import { NotificationType } from './types';
import {
  subscribeNotifications,
  pushNotification,
} from '@/services/firebase.service';
import { EventBus } from '@/services/event.service';
import { useAuthContext } from '@/hooks/use-auth';
import { markAllNotificationsAsRead } from '@/lib/coloc-api/admin';


const uniqueNotifications = (notifications: NotificationType[]) => {
  const unique = new Map();
  notifications.forEach((notification) => {
    unique.set(notification.id, notification);
  });

  return Array.from(unique.values());
};

const useNotificationsProvider = () => {
  const [isOpened, setIsOpened] = useState(false);
  const [notifications, setNotifications] = useState<NotificationType[]>([]);
  const [listener, setListener] = useState<EventBus>(null);
  const { fetchUser } = useAuthContext()!;

  const readAllNotifications = useCallback(() => {
    markAllNotificationsAsRead().then(() => {
      fetchUser();
      setNotifications((prev) =>
        prev.map((notification) => ({
          ...notification,
          read_at: new Date(),
        }))
      );
    });
  }, [fetchUser]);

  const closeNotification = useCallback(() => {
    readAllNotifications();
    setIsOpened(false);
  }, []);

  const addNotification = useCallback((payload) => {
    const {
      data: { notification_data },
    } = payload;

    const data = JSON.parse(notification_data);
    setNotifications((prev) => uniqueNotifications([data, ...prev]));

    if (!isOpened) {
      const {
          notification: { body, title },
        } = payload;

        const click_action = `${window.location.href}#notifications`;

        pushNotification({
          id: notification_data.id,
          title,
          body,
          click_action,
        });
    }
  }, [isOpened]);

  const openNotification = useCallback(() => {
    setIsOpened(true);
  }, []);

  const subscribeToNotificationService = useCallback(() => {
    if (listener) return;
    subscribeNotifications().then(
      ({ eventBus, fcmToken }: { eventBus: EventBus; fcmToken: string }) => {
        updateFcmToken({ fcm_token: fcmToken });
        setListener(eventBus);
      });
  }, [listener]);

  useEffect(() => {
    if (isOpened) {
      getNotificationList().then(({ data }) => {
        setNotifications(uniqueNotifications(data.data));
      });

      subscribeToNotificationService();
    }
  }, [isOpened, subscribeToNotificationService]);

  useEffect(() => {
    if (listener) {
      listener.on('notification', (payload) => {
        addNotification(payload);
      });
    }

    return () => {
      if (listener) {
        listener.off('notification');
      }
    };
  }, [listener, addNotification]);

  useEffect(() => {
    if (Notification.permission === 'granted') subscribeToNotificationService();

    const handleHashChange = () => {
      if (window.location.hash === '#notifications') {
        setIsOpened(true);
      } else {
        setIsOpened(false);
      }
    };

    handleHashChange();
    window.addEventListener('hashchange', handleHashChange);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  const isNotificationEnabled = useMemo(() => {
    return Boolean(listener);
  }, [listener]);

  return {
    isOpened,
    notifications,
    closeNotification,
    openNotification,
    isNotificationEnabled
  };
}


const NotificationsContext = createContext<
  ReturnType<typeof useNotificationsProvider> | undefined
>(undefined);

const useNotifications = () => {
  const context = useContext(NotificationsContext);
  if (!context) {
    throw new Error(
      'useNotifications must be used within a NotificationsProvider'
    );
  }
  return context;
}

export { useNotificationsProvider, NotificationsContext, useNotifications };
