/* eslint-disable react/jsx-no-constructed-context-values */
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

type PDFLoadingStatus = 'loaded' | 'failed' | 'loading';

const usePresentation = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfLoadingStatus, setPDFLoadingStatus] =
    useState<PDFLoadingStatus>('loading');
  const [numPages, setNumPages] = useState(0);

  const nextSlideAvailable = useMemo(() => {
    if (pageNumber >= numPages || pdfLoadingStatus !== 'loaded') {
      return false;
    }

    return true;
  }, [numPages, pageNumber, pdfLoadingStatus]);

  const previousSlideAvailable = useMemo(() => {
    if (pageNumber === 1 || pdfLoadingStatus !== 'loaded') {
      return false;
    }

    return true;
  }, [pageNumber, pdfLoadingStatus]);

  const showNextSlide = useCallback(() => {
    setPageNumber(pageNumber + 1);
  }, [pageNumber]);

  const showPreviousSlide = useCallback(() => {
    setPageNumber(pageNumber - 1);
  }, [pageNumber]);

  return {
    pageNumber,
    pdfLoadingStatus,
    setPDFLoadingStatus,
    numPages,
    setNumPages,
    nextSlideAvailable,
    previousSlideAvailable,
    showNextSlide,
    showPreviousSlide,
  };
};

const PresentationContext = createContext<
  ReturnType<typeof usePresentation> | undefined
>(undefined);

const PresentationContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const presentationCont = usePresentation();

  return (
    <div>
      <PresentationContext.Provider value={presentationCont}>
        {children}
      </PresentationContext.Provider>
    </div>
  );
};

const usePresentationContext = () => {
  const context = useContext(PresentationContext);

  if (context === null) {
    throw new Error(
      'usePresentationContext must be used within a PresentationContextProvider'
    );
  }

  return context;
};

export { usePresentationContext, PresentationContextProvider };
