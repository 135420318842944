import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropDown } from '@/components/design-system/molecules/dropdown';
import { PaginatedColocApiConnectedDropDown } from '@/features/shared/feature-paginated-coloc-api-connected-dropdown';

import { usePairTransactionWithRentContext } from '@/features/admin/transactions/components/incoming-transactions-list/hooks/use-pair-transaction-with-rent';
import { Room } from '@/types/admin';
import { useGetRoomsInfinite } from '@/features/admin/transactions/components/incoming-transactions-list/hooks/use-get-rooms-infinite';

const PairTransactionRoomSelectionDropDown = () => {
  const {
    selectedHouse,
    selectedRoom,
    setSelectedRoom,
    roomsQuery,
    setRoomsQuery,
  } = usePairTransactionWithRentContext()!;

  const { t } = useTranslation();

  return (
    <PaginatedColocApiConnectedDropDown<Room>
      disabled={!selectedHouse}
      placeholder={
        t(
          'js.features.admin.transactions.transaction_pairing_modal.room_selection_placeholder'
        ) ?? ''
      }
      className="z-40 w-full"
      showLabel
      label={t(
        'js.features.admin.transactions.transaction_pairing_modal.room_selection_label'
      )}
      value={selectedRoom}
      onChange={setSelectedRoom}
      {...(selectedRoom && {
        displayValue: String(selectedRoom.room_number),
      })}
      onTextInputChange={(event) => setRoomsQuery(event.target.value)}
      dataSource={
        useGetRoomsInfinite({
          searchQuery: roomsQuery,
          ...(selectedHouse && { selectedHouseId: selectedHouse.id }),
        })!
      }
      itemRenderer={(itemProps, ref) => {
        return (
          // @ts-ignore
          <DropDown.DropDownOptionDefaultContent ref={ref}>
            {String(itemProps.room_number)}
          </DropDown.DropDownOptionDefaultContent>
        );
      }}
    />
  );
};

export { PairTransactionRoomSelectionDropDown };
