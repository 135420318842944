import React from 'react';
import { useTranslation } from 'react-i18next';
import { Transition } from '@headlessui/react';
import { TableBulkActionsProps } from './types';
import { composeClassName } from '@/utils/util_helpers';

const TableBulkActions: React.FC<TableBulkActionsProps> = ({
  children,
  hideTitle = false,
  title,
  className,
  show,
}) => {
  const { t } = useTranslation();

  return (
    <Transition
      show={show}
      enter="transition-opacity ease-linear duration-350 transform"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity ease-linear duration-350 transform"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className="sticky top-[0px]"
    >
      <div
        className={composeClassName(
          'sticky top-[0px] flex flex-col flex-wrap gap-2',
          className && className
        )}
      >
        {!hideTitle && (
          <span className="text-footnote1 text-text-primary">
            {title || t('js.components.table.bulk_actions_title')}
          </span>
        )}
        <div className="flex w-full flex-wrap gap-3 pb-4">{children}</div>
      </div>
    </Transition>
  );
};

export { TableBulkActions };
