import { Controller } from 'stimulus';

export default class extends Controller {
  hide() {
    this.element.remove();
  }

  dismiss(event) {
    event.preventDefault();
    this.hide();

    fetch('/current_contract/whatsapp_group/dismiss', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRF-Token': jQuery.rails.csrfToken(), // eslint-disable-line no-undef
      },
    });
  }
}
