<template>
  <TextInput
    v-bind="$props"
    :secure-text-entry="!showingPassword"
    v-on="$listeners"
  >
    <template #right_component>
      <IconSaxIconVue
        :name="showingPassword ? 'eye-slash' : 'eye'"
        class="ui-password-input__toggle-icon"
        @click.native="toggleShowPassword"
      />
    </template>
  </TextInput>
</template>

<script>
import './PasswordInput.scss';
import TextInput from '../../atoms/text/text-input/TextInput.vue';
import IconSaxIconVue from '../../atoms/icon/iconsax-icon/IconSaxIcon.vue';

export default {
  components: { TextInput, IconSaxIconVue },
  props: {
    /**
     * label of the input. Required for accessibility reasons.
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * Show the label above the input.
     */
    showLabel: {
      type: Boolean,
    },
    /**
     * Help message for input field.
     */
    helpMessage: {
      type: String,
    },
    /**
     * Show help message for input field.
     */
    showHelpMessage: {
      type: Boolean,
      default: true,
    },
    /**
     * Is input disabled or not.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Is input valid or not.
     */
    invalid: {
      type: Boolean,
      default: false,
    },
    /**
     * Error message to show when field is invalid.
     */
    errorMessage: {
      type: String,
    },
    /**
     * Show error message when field is invalid.
     */
    showErrorMessage: {
      type: Boolean,
      default: true,
    },
    /**
     * Value of the input.
     */
    value: {
      type: String,
    },
  },
  data() {
    return {
      showingPassword: false,
    };
  },
  methods: {
    toggleShowPassword() {
      this.showingPassword = !this.showingPassword;
      this.$emit('toggleShowPassword', {
        showingPassword: this.showingPassword,
      });
    },
  },
};
</script>
