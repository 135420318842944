import React, { createContext, useContext, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getRentsSummaryHouses } from '@/services/coloc-api/admin';

import { useRentsSummaryFiltersContext } from '../use-rents-summary-filter';
import { useDebounce } from '@/hooks/use-debounce';
import { useColocApi } from '@/hooks/use-coloc-api';

const useGetRentsSummaryHouses = () => {
  const {
    searchQuery,
    endDate,
    startDate,
    rentStatusQuery,
    selectedHouseRentStatus,
  } = useRentsSummaryFiltersContext()!;
  const { setPage, setPageLimit, page, pageLimit, setPaginationResponse } =
    useColocApi<any>()!;
  const debouncedSearchQuery: string = useDebounce(searchQuery ?? '', 500);

  const PAGE_LIMIT = 8;
  useEffect(() => {
    setPageLimit(PAGE_LIMIT);
  }, [setPageLimit]);

  const fetch = useQuery({
    queryKey: [
      'getRentsSummaryHouses',
      page,
      pageLimit,
      selectedHouseRentStatus,
      startDate,
      endDate,
      debouncedSearchQuery,
      searchQuery,
    ],
    queryFn: () =>
      getRentsSummaryHouses({
        ...(startDate && { start_date: startDate }),
        ...(endDate && { end_date: endDate }),
        ...(debouncedSearchQuery &&
          debouncedSearchQuery !== '' && { query: debouncedSearchQuery }),
        ...(page && { page }),
        ...(pageLimit && { page_limit: pageLimit }),
        ...(selectedHouseRentStatus && {
          status: selectedHouseRentStatus.status,
        }),
      }),
    keepPreviousData: true,
  });

  useEffect(() => {
    setPaginationResponse(
      fetch.data?.data.pagination
        ? { pagination: fetch.data?.data.pagination }
        : undefined
    );
  }, [fetch.data?.data.pagination, setPaginationResponse]);

  return {
    fetch,
    rentStatusQuery,
    setPage,
    page,
    startDate,
    endDate,
    searchQuery,
    pageLimit,
  };
};

const GetRentsSummaryHousesContext = createContext<
  ReturnType<typeof useGetRentsSummaryHouses> | undefined
>(undefined);

const GetRentsSummaryHousesContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const hook = useGetRentsSummaryHouses();

  return (
    <GetRentsSummaryHousesContext.Provider value={hook}>
      {children}
    </GetRentsSummaryHousesContext.Provider>
  );
};

const useGetRentsSummaryHousesContext = () => {
  const context = useContext(GetRentsSummaryHousesContext);

  if (context === null) {
    throw new Error(
      'useGetRentsSummaryHousesContext must be used within a GetRentsSummaryHousesContextProvider'
    );
  }

  return context;
};

export {
  GetRentsSummaryHousesContextProvider,
  useGetRentsSummaryHousesContext,
};
