import React, { useCallback, useRef } from 'react';
import { t } from 'i18next';
import { Button } from '@/components/design-system/atoms/button';
import { ColocAPIConnectedCardListProps } from './types';
import { Pagination } from '@/components/design-system/atoms/pagination';
import { EmptyState } from '@/components/design-system/atoms/empty-state';

const EmptyContentWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <div className="table-cell min-h-[inherit] w-full align-middle">
      <div className="my-10 flex h-[inherit] w-full items-center justify-center">
        {children}
      </div>
    </div>
  );
};

const ContentListEmptyState: React.FC<{
  status: 'loading' | 'error' | 'success';
  refetch: () => void;
  pageLimit: number;
  loadingCard: React.ReactElement;
}> = ({ status, refetch, loadingCard, pageLimit }) => {
  if (status === 'loading') {
    return (
      <div className="scrollbar-hide flex h-full max-h-full w-full flex-row flex-wrap gap-4 overflow-visible pt-3 pb-4">
        {[...Array.from(Array(pageLimit).keys())].map((num) => {
          return {
            ...loadingCard,
            key: num,
            props: {
              ...loadingCard.props,
            },
          };
        })}
      </div>
    );
  }

  if (status === 'error') {
    return (
      <EmptyContentWrapper>
        <EmptyState
          type="Error"
          actionButton={
            <Button onClick={() => refetch()}>
              {`${t('js.features.shared.connected_card_list.retry')}`}
            </Button>
          }
        />
      </EmptyContentWrapper>
    );
  }

  return (
    <EmptyContentWrapper>
      <EmptyState
        type="NoResults"
        text={`${t('js.features.shared.connected_card_list.no_results_found')}`}
      />
    </EmptyContentWrapper>
  );
};

// eslint-disable-next-line react/function-component-definition
function ColocApiConnectedCardList<T1>({
  dataSource,
  cardRenderer,
  hidePagination = false,
  loadingCard,
}: ColocAPIConnectedCardListProps<T1>) {
  const { fetch, pageLimit, setPage } = dataSource;
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const { data, status, refetch } = fetch;

  const getItem = useCallback(
    (props: { props: T1; key: number }) => {
      return cardRenderer(props.props, props.key);
    },
    [cardRenderer]
  );

  if (data?.data && data?.data.data?.length >= 1 && status === 'success') {
    return (
      <div
        ref={scrollableContainerRef}
        className="scrollbar-hide relative mb-5 mt-1 flex h-full w-full items-center overflow-y-scroll"
      >
        <div className="scrollbar-hide relative flex h-full w-full flex-col">
          <div className="w-full">
            <div className="scrollbar-hide flex h-full max-h-full w-full flex-row flex-wrap gap-4 overflow-visible pt-3 pb-4">
              {data?.data.data.map((item, index) => {
                return getItem({ props: item, key: index });
              })}
            </div>
          </div>
          {!hidePagination ? (
            <div className="flex flex-1 items-end justify-center">
              <div className="pb-5">
                <Pagination
                  className="justify-center"
                  disabled={!data?.data.pagination}
                  totalPages={data?.data.pagination.total_pages ?? 1}
                  currentPage={data?.data.pagination.page ?? 1}
                  onPageChange={(new_page) => {
                    setPage(new_page);
                    scrollableContainerRef.current?.scroll({
                      top: 0,
                      behavior: 'smooth',
                    });
                  }}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }

  return (
    <ContentListEmptyState
      status={status}
      refetch={refetch}
      loadingCard={loadingCard}
      pageLimit={pageLimit}
    />
  );
}

export { ColocApiConnectedCardList };
