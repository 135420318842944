import { useQuery } from '@tanstack/react-query';

import { getRentsForContract } from '@/lib/coloc-api/admin';

const useGetRentsForTenant = (params: { selectedContractId: number }) => {
  const fetch = useQuery({
    queryKey: ['getRentsForTenant', params.selectedContractId],
    queryFn: () =>
      getRentsForContract({
        contract_id: params.selectedContractId,
      }),
    keepPreviousData: true,
    enabled: params.selectedContractId !== 0,
  });

  return {
    fetch,
  };
};

export { useGetRentsForTenant };
