import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetOutgoingTransactionPaymentContext } from '@/features/admin/transactions/components/outgoing-transactions-list/hooks/use-get-outgoing-transaction-payment';
import { useCancelOutgoingTransactionContext } from '../../../../hooks/use-cancel-outgoing-transaction';
import { OutgoingTransactionActionCellContentProps } from './types';
import { Table } from '@/components/design-system/molecules/table';
import { IconSaxIcon } from '@/components/design-system/atoms/icon';

const OutgoingTransactionActionCellContent: React.FC<OutgoingTransactionActionCellContentProps> =
  ({ outgoingTransaction }) => {
    const { t } = useTranslation();
    const { setPaymentId, fetchingPayment, paymentId } =
      useGetOutgoingTransactionPaymentContext()!;
    const { mutate: cancelOutgoingTransaction } =
      useCancelOutgoingTransactionContext()!;

    if (!['pending', 'action_required'].includes(outgoingTransaction.status)) {
      return null;
    }

    const isFetchingPayment = useMemo(() => {
      return fetchingPayment && paymentId === outgoingTransaction.payment_id;
    }, [fetchingPayment, outgoingTransaction.payment_id, paymentId]);

    return (
      <Table.TableCellRowActionsContent>
        <Table.TableCellRowActionsContent.TableRowActions>
          {['pending', 'action_required'].includes(
            outgoingTransaction.status
          ) && (
            <Table.TableCellRowActionsContent.TableRowAction
              onClick={() =>
                cancelOutgoingTransaction.mutate({ id: outgoingTransaction.id })
              }
              loading={cancelOutgoingTransaction.isLoading}
              disabled={cancelOutgoingTransaction.isLoading}
            >
              <Table.TableCellRowActionsContent.TableRowActionContent>
                Cancel pay-out
              </Table.TableCellRowActionsContent.TableRowActionContent>

            </Table.TableCellRowActionsContent.TableRowAction>
          )}

          {outgoingTransaction.status === 'pending' && (
            <Table.TableCellRowActionsContent.TableRowAction
              onClick={() => setPaymentId(outgoingTransaction.payment_id)}
              loading={isFetchingPayment}
              disabled={!isFetchingPayment && fetchingPayment}
            >
              <Table.TableCellRowActionsContent.TableRowActionContent>
                {t(
                  'js.features.admin.transactions.outgoing_transactions_list.table_cells.sync_payment_button'
                ) ?? ''}
              </Table.TableCellRowActionsContent.TableRowActionContent>
            </Table.TableCellRowActionsContent.TableRowAction>
          )}
        </Table.TableCellRowActionsContent.TableRowActions>
      </Table.TableCellRowActionsContent>
    )
};

export { OutgoingTransactionActionCellContent };
