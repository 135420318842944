import React from 'react';

export const textMultiLine = (text: string) => {
  return text.split('\n').map((item) => {
    return (
      <span key={item}>
        {item}
        <br />
      </span>
    );
  });
};
