import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Modal } from '@/components/design-system/atoms/modal';

import { Button } from '@/components/design-system/atoms/button';
import { DropDown } from '@/components/design-system/molecules/dropdown';

import { useGetRentReportVersions } from '../../hooks/use-get-rent-report-versions';
import { DownloadRentReportVersionModalProps } from './types';
import { RentReportVersion } from '@/features/admin/rents/types';
import { ColocApiConnectedDropDown } from '@/features/shared/feature-coloc-api-connected-dropdown';
import { useGetRentReportListContext } from '../../hooks/use-get-rent-report-list';
import { useDownloadFile } from '@/hooks/use-download-file';

const DownloadReportVersionModal: React.FC<DownloadRentReportVersionModalProps> =
  ({ open, onClose, report }) => {
    const { t } = useTranslation();
    const { toggleShowDownloadReportVersionModal } =
      useGetRentReportListContext()!;

    const [selectedVersion, setSelectedVersion] = useState<RentReportVersion>({
      version_number: report.version_number,
      date: report.date,
      download_link: report.download_link,
      status: report.status,
    });

    const [versionQuery, setVersionQuery] = useState<string>('');
    const [downloadingFile, downloadFile] = useDownloadFile({
      url: selectedVersion.download_link,
      filename: `rent_report_${report.house.name}_${format(
        new Date(selectedVersion.date),
        'MMMyy'
      )}`,
      filetype: 'pdf',
      onCompletion: () => {
        toggleShowDownloadReportVersionModal({
          show: false,
          report: undefined,
        });
      },
    });

    return (
      <Modal open={open} onClose={onClose}>
        <div className="flex w-[500px] flex-col gap-5 rounded-lg bg-white p-7 shadow-medium">
          <div className="flex w-full flex-col gap-2 text-text-primaryHeavy">
            <span className="text-lead2 font-bold">
              {t('js.features.admin.rent_report.download_report_modal.title')}
            </span>
            <span className="text-body text-text-primaryMedium">
              {t('js.features.admin.rent_report.download_report_modal.text')}
            </span>
          </div>
          <div className="flex w-full flex-col gap-3">
            <ColocApiConnectedDropDown<RentReportVersion>
              placeholder={
                t(
                  'js.features.admin.rent_report.download_report_modal.dropdown_placeholder'
                ) ?? ''
              }
              className="z-40 w-full"
              showLabel
              label={t(
                'js.features.admin.rent_report.download_report_modal.dropdown_label'
              )}
              value={selectedVersion}
              onChange={setSelectedVersion}
              {...(selectedVersion && {
                displayValue: String(selectedVersion?.version_number),
              })}
              onTextInputChange={(event) => setVersionQuery(event.target.value)}
              dataSource={useGetRentReportVersions({ report_id: report.id })}
              filterFunction={(item) => {
                return String(item.version_number)
                  .toLowerCase()
                  .includes(versionQuery.toLowerCase());
              }}
              itemRenderer={(itemProps) => {
                return (
                  // @ts-ignore
                  <DropDown.DropDownOptionDefaultContent>
                    {String(itemProps.version_number)}
                  </DropDown.DropDownOptionDefaultContent>
                );
              }}
            />
          </div>
          <div className="flex gap-4">
            <Button
              variant="primary"
              loading={downloadingFile}
              onClick={downloadFile}
            >
              {t(
                'js.features.admin.rent_report.download_report_modal.download_button'
              ) ?? ''}
            </Button>
            <Button
              variant="ghost"
              disabled={downloadingFile}
              onClick={() => {
                toggleShowDownloadReportVersionModal({
                  show: false,
                  report: undefined,
                });
              }}
            >
              {t(
                'js.features.admin.rent_report.download_report_modal.cancel_button'
              ) ?? ''}
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

export { DownloadReportVersionModal };
