<template>
  <section class="main-bg w-full pt-10 lg:overflow-hidden lg:pt-[92px]">
    <div
      class="
        lg:pt-28
        m-auto
        w-full
        px-4
        pt-1
        pb-7
        lg:pt-10
        lg:pb-[200px]
        2xl:max-w-8xl
      "
    >
      <div class="grid w-full grid-cols-1 lg:grid-cols-2">
        <div
          class="
            order-last
            flex
            w-full
            flex-col
            items-center
            space-y-7
            lg:order-first
            lg:items-start
          "
        >
          <div class="m-auto flex flex-col items-start space-y-3 lg:w-full">
            <h1
              class="
                font-Vollkorn
                text-h4
                font-bold
                text-primary-100
                lg:text-5xl
              "
            >
              Does your building need a make-over?
            </h1>
            <p class="font-sans text-lead2 font-semibold text-J300 lg:text-h3">
              Turn it into a coliving! Care free.
            </p>
          </div>

          <div
            class="
              md:m-0
              m-auto
              flex
              w-full
              max-w-[250px]
              flex-col
              items-center
              space-y-4
              lg:hidden
            "
          >
            <div
              class="
                flex
                w-full
                rounded-full
                border border-primary-200
                bg-R50
                py-2
              "
            >
              <div class="m-auto flex items-center space-x-4">
                <ColocIcon name="renovate" :size="35" />
                <h1
                  class="
                    whitespace-nowrap
                    font-Vollkorn
                    text-base
                    font-bold
                    text-primary-100
                  "
                >
                  Renovate
                </h1>
              </div>
            </div>
            <svg
              width="24"
              height="24"
              viewBox="0 0 11 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.45312 8.61914L5.49979 12.6658L9.54646 8.61914"
                stroke="#7A7A7A"
                stroke-width="1.2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.5 1.33398L5.5 12.554"
                stroke="#7A7A7A"
                stroke-width="1.2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <div
              class="
                flex
                w-full
                rounded-full
                border border-primary-200
                bg-R50
                py-2
              "
            >
              <div class="m-auto flex items-center space-x-4">
                <ColocIcon name="decorate" :size="35" />
                <h1
                  class="
                    whitespace-nowrap
                    font-Vollkorn
                    text-base
                    font-bold
                    text-primary-100
                  "
                >
                  Decorate
                </h1>
              </div>
            </div>
            <svg
              width="24"
              height="24"
              viewBox="0 0 11 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.45312 8.61914L5.49979 12.6658L9.54646 8.61914"
                stroke="#7A7A7A"
                stroke-width="1.2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.5 1.33398L5.5 12.554"
                stroke="#7A7A7A"
                stroke-width="1.2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <div
              class="
                flex
                w-full
                rounded-full
                border border-primary-200
                bg-R50
                py-2
              "
            >
              <div class="m-auto flex items-center space-x-4">
                <ColocIcon name="manage" :size="35" />
                <h1
                  class="
                    whitespace-nowrap
                    font-Vollkorn
                    text-base
                    font-bold
                    text-primary-100
                  "
                >
                  Manage
                </h1>
              </div>
            </div>
          </div>

          <div
            class="
              m-auto
              flex flex-col
              items-start
              space-y-2
              px-3
              pt-3
              text-sm
              font-normal
              text-text-secondary
              lg:w-full
              lg:px-4
              lg:text-lg
            "
          >
            <ul
              class="
                list-disc
                space-y-1
                font-sans
                text-sm
                font-medium
                lg:text-xl
              "
            >
              <li>Substiantially increase the rental revenues</li>
              <li>99% occupation</li>
              <li>Be part of the sharing economy</li>
            </ul>
          </div>

          <div
            class="
              hidden
              w-full
              rounded-full
              border border-primary-200
              bg-R50
              py-3
              px-4
              lg:mt-10
              lg:mb-[200px]
              lg:block
            "
          >
            <div class="flex w-full items-center justify-between">
              <div class="flex items-center space-x-2">
                <ColocIcon name="renovate" :size="48" />
                <h1
                  class="
                    whitespace-nowrap
                    font-Vollkorn
                    text-xl
                    font-bold
                    text-primary-100
                  "
                >
                  Renovate
                </h1>
              </div>
              <IconArowRight />
              <div class="flex items-center space-x-2">
                <ColocIcon name="decorate" :size="48" />
                <h1
                  class="
                    whitespace-nowrap
                    font-Vollkorn
                    text-xl
                    font-bold
                    text-primary-100
                  "
                >
                  Decorate
                </h1>
              </div>
              <IconArowRight />
              <div class="flex items-center space-x-2">
                <ColocIcon name="manage" :size="48" />
                <h1
                  class="
                    whitespace-nowrap
                    font-Vollkorn
                    text-xl
                    font-bold
                    text-primary-100
                  "
                >
                  Manage
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div class="relative h-full w-full">
          <div
            class="flex h-full w-full lg:absolute lg:-top-[200px] lg:-right-8"
            @mouseover="show = false"
            @mouseleave="show = true"
          >
            <DesktopHero :show="show" class="hidden lg:block 2xl:absolute" />
            <MobileHero class="m-auto lg:hidden" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import DesktopHero from '../../spot_a_house/hero/DesktopHero.vue';
import MobileHero from '../../spot_a_house/hero/MobileHero.vue';
import IconArowRight from '../../spot_a_house/Icons/IconArowRight.vue';
import ColocIcon from '../../../common/components/ui/atoms/icon/coloc-icon/ColocIcon.vue';

export default {
  components: {
    DesktopHero,
    MobileHero,
    IconArowRight,
    ColocIcon,
  },
  data() {
    return {
      show: true,
    };
  },
  methods: {
    GetStarted() {
      // scroll to ContactUs id
      window.scrollTo({
        top: document.getElementById('ContactUs').offsetTop,
        behavior: 'smooth',
      });
    },
  },
};
</script>