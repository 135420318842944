/* eslint-disable react/require-default-props */
import React, { useMemo } from 'react';
import { composeClassName } from '@/utils/util_helpers';

const SectionTitle: React.FC<{
  children: string;
  subTitle?: string;
  className?: string;
}> = ({ children, subTitle, className }) => {
  const titleText = useMemo(() => {
    return children.split('\n').map((item) => {
      return (
        <span key={item}>
          {item}
          <br />
        </span>
      );
    });
  }, [children]);

  return (
    <div className={composeClassName(className && className)}>
      <div className="relative">
        <div className="relative z-10">
          {subTitle && (
            <span className="text-body font-bold uppercase leading-[2.4rem] tracking-[.4rem] text-text-secondary opacity-50">
              {subTitle}
            </span>
          )}
          <h2
            className={composeClassName(
              'relative text-h3 font-bold leading-[3.7rem] text-text-primary',
              'sm:gap-[0.6rem] sm:text-[4.8rem] sm:leading-[5.5rem]'
            )}
          >
            {titleText}
          </h2>
        </div>
        {/* circle */}
        <div
          className={composeClassName(
            'absolute top-[-1.4rem] left-[-1.6rem] z-0 h-[7.2rem] w-[7.2rem] rounded-full bg-R300',
            'sm:top-[-2rem] sm:left-[-2rem] sm:h-[9.6rem] sm:w-[9.6rem]'
          )}
        />
      </div>
    </div>
  );
};

export { SectionTitle };
