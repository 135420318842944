import React from 'react';
import { Avatar } from '@/components/design-system/atoms/avatar';

const MentionSuggestion = (
  suggestion,
  query,
  highlightedDisplay,
  index,
  focused
) => {
  const {
    id, display, avatar_url
  } = suggestion;
  return (
    <div
      className="flex items-center gap-2 px-2 py-1"
    >
      <Avatar src={avatar_url} size="s" className="flex-shrink-0" />
      <div className="flex flex-col">
        <span className="text-sm font-semibold">{display}</span>
      </div>
    </div>
  );
};

export { MentionSuggestion };
