import React, { useEffect } from 'react';
import { RentTransactionsRowProps } from './types';
import { composeClassName } from '@/utils/util_helpers';
import { useGetRentTransactions } from '../../hooks/rent-transactions/use-get-rent-transactions';
import { RentTransactionsTable } from './components/rent-transactions-table';
import { AddRentTransactionRow } from './components/add-rent-transaction-row';
import { EmptyState } from '@/components/design-system/atoms/empty-state';
import { Button } from '@/components/design-system/atoms/button';
import { Shimmer } from '@/components/design-system/atoms/shimmer';
import { useGetRentsListContext } from '../../hooks/use-get-rents-list';

const RentTransactionRowContent: React.FC<RentTransactionsRowProps> = ({
  rent,
}) => {
  const { toggleShowDeleteRentTransactionModal, reloadTransactionsAt } = useGetRentsListContext()!;
  const { fetch } = useGetRentTransactions({ rent_id: rent.id })!;
  const { data, isLoading, isSuccess, isError, refetch } = fetch;


  useEffect(() => {
    refetch();
  }, [
    refetch,
    rent,
    reloadTransactionsAt,
  ]);
  if (isLoading) {
    return (
      <div className="flex w-full flex-col gap-4">
        <Shimmer className="h-[20px] w-[200px]" />
        <Shimmer className="h-[20px] w-[200px]" />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex items-center justify-center">
        <EmptyState
          type="Error"
          size={128}
          className="py-5"
          actionButton={<Button onClick={() => refetch()}>Retry</Button>}
        />
      </div>
    );
  }

  if (isSuccess) {
    if (data?.data?.length >= 1) {
      return (
        <>
          <RentTransactionsTable
            rent={rent}
            reload={()=> refetch()}
            data={data.data}
            onDelete={(transaction) =>
              toggleShowDeleteRentTransactionModal({
                show: true,
                transaction,
              })
            }
          />
        </>
      );
    }
    return (
      <>
        <div className="flex items-center justify-center">
          <EmptyState type="NoResults" size={128} className="py-5" />
        </div>
      </>
    );
  }

  return <></>;
};

const RentTransactionsRow: React.FC<RentTransactionsRowProps> = ({
  rent,
  index,
  onClose,
}) => {
  const { toggleShowAddRentTransactionModal } = useGetRentsListContext()!;

  return (
    <div
      className={composeClassName(
        'w-full',
        index % 2 === 0 ? 'bg-white' : 'bg-E30'
      )}
    >
      <div className="h-[1px] w-full bg-E50" />
      <div className="my-3 mx-7">
        <RentTransactionRowContent rent={rent}/>
      </div>
      <AddRentTransactionRow
        onClickClose={onClose}
        onClickAdd={() =>
          toggleShowAddRentTransactionModal({
            rent,
            show: true
          })
        }
      />
    </div>
  );
};

export { RentTransactionsRow };
