/* eslint-disable react/prop-types */
import React from 'react';
import { Menu } from '@headlessui/react';
import { composeClassName } from '@/utils/util_helpers';
import { TableCellRowActionsContentPropsAndSubComponents } from './types';
import { IconSaxIcon } from '@/components/design-system/atoms/icon';
import { theme } from '@/utils/util_theme';
import { TableCellRowActions } from './components/table-cell-row-actions';
import { TableCellRowAction } from './components/table-cell-row-action';
import { TableCellRowActionContent } from './components/table-cell-row-action-content';

const TableCellRowActionsContent: TableCellRowActionsContentPropsAndSubComponents =
  ({ children, className }) => {
    return (
      <Menu
        as="div"
        className={composeClassName('relative', className && className)}
      >
        <Menu.Button className="relative flex h-[28px] w-[28px] flex-col items-center justify-center rounded-full border-[1px] border-solid border-N300 bg-white hover:border-E50 hover:bg-E30">
          <IconSaxIcon
            name="more-ic"
            strokeColor={theme?.colors.text.N900}
            className="relative [&_svg>path]:fill-text-N900"
            strokeWidth={0.1}
            size={20}
          />
        </Menu.Button>
        {children}
      </Menu>
    );
  };

TableCellRowActionsContent.TableRowActions = TableCellRowActions;
TableCellRowActionsContent.TableRowAction = TableCellRowAction;
TableCellRowActionsContent.TableRowActionContent = TableCellRowActionContent;

export { TableCellRowActionsContent };
