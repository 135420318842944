import React from 'react';
import { RoomApplicantPreviewCardProps } from './types';
import { composeClassName } from '@/utils/util_helpers';
import { ApplicantStatusBox } from './components/applicant-status-box';
import { ApplicantInfo } from './components/applicant-info/index.';

const ApplicantPreviewCard: React.FC<RoomApplicantPreviewCardProps> = ({
  applicant,
  className,
  chat_url,
  isActionsEnabled = true,
  onAccept,
  onDecline,
  onEditMoveIn,
}) => {
  return (
    <div
      className={composeClassName(
        'flex w-full max-w-full flex-col gap-3 rounded-lg border-[1px] border-solid border-border-primaryLight p-4',
        className && className
      )}
    >
      <div className="flex flex-col gap-3">
        <ApplicantStatusBox
          applicant={applicant}
          isActionsEnabled={isActionsEnabled}
          onAccept={() => onAccept()}
          onDecline={() => onDecline()}
        />
        <div className="h-[1px] w-full bg-border-primaryLight" />
        <ApplicantInfo
          applicant={applicant}
          chat_url={chat_url}
          onEditMoveIn={onEditMoveIn}
        />
      </div>
    </div>
  );
};

export { ApplicantPreviewCard };
