import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DropDown } from '@/components/design-system/molecules/dropdown';

import { usePairTransactionWithRentContext } from '@/features/admin/transactions/components/incoming-transactions-list/hooks/use-pair-transaction-with-rent';
import { TenantOfRoom } from '@/types/admin';
import { ColocApiConnectedDropDown } from '@/features/shared/feature-coloc-api-connected-dropdown';
import { useGetTenantsForRoom } from '@/features/admin/transactions/components/incoming-transactions-list/hooks/use-get-tenants-for-room';
import { getFullDate } from '@/utils/util_helpers/formatting/dates';

const PairTransactionTenantSelectionDropDown = () => {
  const {
    selectedRoom,
    selectedTenant,
    setSelectedTenant,
    tenantQuery,
    setTenantQuery,
  } = usePairTransactionWithRentContext()!;

  const { t } = useTranslation();

  const getDescriptionText = useCallback(
    (tenant: TenantOfRoom) => {
      if (tenant.move_in && !tenant.move_out) {
        return t(
          'js.features.admin.transactions.transaction_pairing_modal.tenant_selection_current_tenant_date_range',
          { start_date: getFullDate(new Date(tenant.move_in)) }
        );
      }

      return `${getFullDate(new Date(tenant.move_in))} - ${getFullDate(
        new Date(tenant.move_out!)
      )}`;
    },
    [t]
  );

  return (
    <ColocApiConnectedDropDown<TenantOfRoom>
      disabled={!selectedRoom}
      placeholder={
        t(
          'js.features.admin.transactions.transaction_pairing_modal.tenant_selection_placeholder'
        ) ?? ''
      }
      className="z-30 w-full"
      showLabel
      label={t(
        'js.features.admin.transactions.transaction_pairing_modal.tenant_selection_label'
      )}
      value={selectedTenant}
      onChange={setSelectedTenant}
      {...(selectedTenant && {
        displayValue: `${selectedTenant.first_name} ${selectedTenant.last_name}`,
      })}
      onTextInputChange={(event) => setTenantQuery(event.target.value)}
      dataSource={
        useGetTenantsForRoom({
          selectedRoomId: selectedRoom?.id ?? 0,
        })!
      }
      filterFunction={(item) => {
        return (
          item.first_name.toLowerCase().includes(tenantQuery.toLowerCase()) ||
          item.last_name.toLowerCase().includes(tenantQuery.toLowerCase())
        );
      }}
      itemRenderer={(itemProps) => {
        return (
          <DropDown.DropDownOptionDefaultContent
            description={getDescriptionText(itemProps)}
          >
            {`${itemProps.first_name} ${itemProps.last_name}`}
          </DropDown.DropDownOptionDefaultContent>
        );
      }}
    />
  );
};

export { PairTransactionTenantSelectionDropDown };
