import React, { forwardRef } from 'react';
import { useMergeRefs } from '@floating-ui/react';
import { usePopOverContext } from '../../hooks/use-pop-over-context';
import { PopOverTriggerProps } from './types';

const PopOverTrigger = forwardRef<
  PopOverTriggerProps,
  React.HTMLProps<HTMLElement> & { asChild?: boolean }
>(({ children, asChild = false, ...props }, propRef) => {
  const state = usePopOverContext()!;

  const childrenRef = (children as any).ref;
  const ref = useMergeRefs([state.refs.setReference, propRef, childrenRef]);

  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(
      children,
      state.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': state.open ? 'open' : 'closed',
      })
    );
  }

  return (
    <button
      type="button"
      ref={ref}
      className="align-start flex items-start text-left"
      // The user can style the trigger based on the state
      data-state={state.open ? 'open' : 'closed'}
      {...state.getReferenceProps(props)}
    >
      {children}
    </button>
  );
});

PopOverTrigger.displayName = 'PopOverTrigger';

export { PopOverTrigger };
