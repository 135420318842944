import { BaseIconProps } from '../types';

const colocIconNames = [
  'account',
  'appliances',
  'average',
  'bank',
  'bed',
  'bell-outline',
  'birthday',
  'budget',
  'building',
  'calendar',
  'call',
  'card',
  'cart',
  'case',
  'chat',
  'check-circle',
  'check-user',
  'cleaning',
  'contract',
  'contract-x',
  'community',
  'costs',
  'couples',
  'clean-energy',
  'clean-energy2',
  'dashboard',
  'decorate',
  'deposit',
  'description',
  'document',
  'edit',
  'electricity',
  'fees',
  'festival',
  'furniture',
  'graph',
  'happy-face',
  'handlove',
  'handshake',
  'heart',
  'help',
  'home-rent',
  'house',
  'lamp',
  'like',
  'line-chart',
  'list',
  'location',
  'lock',
  'logout',
  'manage',
  'marketing',
  'message',
  'money',
  'move-in',
  'move-out',
  'movingin',
  'movingout',
  'mobile-app',
  'notfication',
  'paint',
  'payment',
  'phone',
  'plan',
  'presention-chart',
  'process',
  'profile',
  'renewable',
  'renovate',
  'repair',
  'revenue',
  'rocket',
  'roof',
  'search',
  'settings',
  'shower',
  'signature',
  'star',
  'status-approved',
  'status-busy',
  'status-declined',
  'status-focus',
  'status-locked',
  'status-offline',
  'status-online',
  'smart-door',
  'smart-light',
  'tag',
  'team',
  'ticket',
  'transactions',
  'trash',
  'uncheck-user',
  'vote',
  'wall',
  'wallet',
  'wifi',
  'wishlist',
  'vr',
  'vr2',
  'question',
] as const;

export type ColocIconName = typeof colocIconNames[number];

export type ColocIconProps = BaseIconProps & {
  /**
   * The name of the icon.
   */
  name: ColocIconName;
};
