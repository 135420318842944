<template>
  <section
    :style="{ backgroundImage: `url(${bgImage})` }"
    class="w-full space-y-9 py-5 lg:py-10"
    id="WhatImages"
  >
    <div class="m-auto w-full max-w-8xl px-4">
      <div class="flex w-full max-w-[409px] flex-col items-start space-y-2">
        <h1
          class="
            font-Vollkorn
            text-2xl
            font-bold
            leading-[66.86px]
            text-primary-100
            lg:text-5xl
          "
        >
          We take care of all things rental
        </h1>
      </div>
    </div>

    <div class="flex w-full justify-end">
      <div
        class="
          flex
          w-full
          max-w-[1650px]
          flex-col
          items-end
          space-y-4
          md:flex-row
          md:items-start
          md:space-y-[0px]
        "
      >
        <div>
          <img
            src="~assets/images/owner/idea.png"
            class="h-full w-full object-cover"
            alt="decoration group"
          />
        </div>
        <div>
          <img
            src="~assets/images/owner/Macbook.png"
            class="h-full w-full object-cover"
            alt="decoration group"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import bgImage from 'assets/images/spot_a_house/secondsection.png';

export default {
  data() {
    return {
      bgImage,
    };
  },
};
</script>


