import React from 'react';
import { composeClassName } from '@/utils/util_helpers';
import { TableCellDefaultContentProps } from './types';

const TableCellDefaultContent: React.FC<TableCellDefaultContentProps> = ({
  children,
  description,
  className,
  descriptionClassName,
  titleClassName,
  href,
}) => {
  return (
    <div
      className={composeClassName(
        'flex flex-col flex-wrap overflow-hidden text-body font-normal',
        className && className
      )}
    >
      <span
        className={composeClassName(
          'break-all font-medium text-text-primaryHeavy',
          description ? 'line-clamp-1' : 'line-clamp-2',
          titleClassName && titleClassName
        )}
        style={{ whiteSpace: 'initial' }}
      >
        {href ? (
          <a target="_blank" rel="noreferrer" className="underline" href={href}>
            {children}
          </a>
        ) : (
          children
        )}
      </span>
      {description && (
        <span
          className={composeClassName(
            'line-clamp-1 break-all text-text-primaryLight',
            descriptionClassName && descriptionClassName
          )}
          style={{ whiteSpace: 'initial' }}
        >
          {description}
        </span>
      )}
    </div>
  );
};

export { TableCellDefaultContent };
