import React from 'react';
import './style.css';
import { CheckboxProps } from './types';
import { composeClassName } from '../../../../utils/util_helpers';

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  size = 'large',
  labelPosition = 'after',
  className,
  id,
  inputClassName,
  ...props
}): JSX.Element => {
  return (
    <>
      <div
        className={composeClassName(
          'flex items-center gap-[10px]',
          labelPosition === 'after' && 'flex-row-reverse justify-end',
          className && className
        )}
      >
        {label && (
          <label
            htmlFor={id}
            className={composeClassName(
              'text-E300',
              size === 'small' ? 'text-footnote1' : 'text-body'
            )}
          >
            {label}
          </label>
        )}
        <input
          className={composeClassName(
            'transition duration-300 ease-out',
            'relative  appearance-none rounded-[3px] border-[1px] border-E75 bg-white',
            size === 'small' ? 'h-3 w-3' : 'h-4 w-4',
            'focus:border-R300',
            'checked:border-R300 checked:bg-R300',
            'checked:focus:border-R100',
            'checked:hover:border-R100 checked:hover:bg-R100',
            'checked:after:absolute  checked:after:resize checked:after:bg-E300 checked:after:bg-cover checked:after:transition',
            size === 'small'
              ? 'checked:after:top-[1.25px] checked:after:left-[1.25px] checked:after:h-[12px] checked:after:w-[12px]'
              : 'checked:after:top-[2px] checked:after:left-[2px] checked:after:h-[18px] checked:after:w-[18px]',
            'checkbox-bg',
            size === 'small' ? 'checkbox-bg--small' : 'checkbox-bg--large',
            'disabled:border-E50 disabled:bg-E30',
            'disabled:checked:border-E30 disabled:checked:after:bg-E50',
            inputClassName && inputClassName
          )}
          {...props}
          type="checkbox"
          data-testid={id}
        />
      </div>
    </>
  );
};

export { Checkbox };
