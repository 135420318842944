var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui-text-input__container"},[(_vm.showLabel)?_c('label',{class:[
      'ui-text-input__label',
      _vm.disabled && 'ui-text-input__label--disabled',
    ]},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_vm._v(" "),_c('textarea',{class:_vm.textInputStyling,style:({ height: _vm.height }),attrs:{"id":_vm.label,"placeholder":_vm.placeholder,"oninvalid":"toggleFieldInvalid","disabled":_vm.disabled},domProps:{"value":_vm.value}}),_vm._v(" "),(_vm.showHelpOrErrorMessage)?_c('span',{class:[
      'ui-text-input__help-text',
      _vm.invalid && 'ui-text-input__help-text--error',
    ]},[_vm._v(_vm._s(_vm.helpOrErrorMessage))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }