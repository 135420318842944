import { useInfiniteQuery } from '@tanstack/react-query';

import { CityListSortType } from '@/lib/coloc-api/admin';
import { useDebounce } from '@/hooks/use-debounce';
import { useColocApi } from '@/hooks/use-coloc-api';
import { getCityList } from '@/services/coloc-api/admin';

const useGetCitiesInfinite = (params: { searchQuery?: string }) => {
  const { setPage, setPageLimit, page, pageLimit } = useColocApi<CityListSortType>()!;
  const debouncedSearchQuery: string = useDebounce(
    params.searchQuery ?? '',
    500
  );

  const fetch = useInfiniteQuery({
    queryKey: ['getCityList', debouncedSearchQuery, page, pageLimit],
    queryFn: ({ pageParam = 1 }) =>
      getCityList({
        page_limit: 5,
        sort: 'name',
        ...(pageParam && { page: pageParam }),
        ...(debouncedSearchQuery &&
          debouncedSearchQuery !== '' && { query: debouncedSearchQuery }),
      }),
    keepPreviousData: true,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.data.pagination.total_pages > pages.length) {
        return pages.length + 1;
      }

      return undefined;
    },
  });

  return {
    pageLimit,
    setPageLimit,
    fetch,
    setPage,
  };
};

export { useGetCitiesInfinite };
