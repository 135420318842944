import React from 'react';
import './style.css';
import { composeClassName } from '../../../../utils/util_helpers';
import { ShimmerProps } from './types';

const Shimmer: React.FC<ShimmerProps> = ({ className }) => {
  return (
    <div
      className={composeClassName('loader-container', className && className)}
    />
  );
};

export { Shimmer };
