import React, { useMemo, useRef } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { IconSaxIcon } from '@/components/design-system/atoms/icon';
import { Table } from '@/components/design-system/molecules/table';
import {
  GetRoomsAssignmentListContextProvider,
  useGetRoomsAssignmentContext,
} from './hooks/use-get-room-assignment-list';
import { RoomAssignment } from '../../types';
import { ColocAPIConnectedTable } from '@/features/shared/feature-coloc-api-connected-table';
import { RoomAssignmentListSortType } from '@/lib/coloc-api/admin';
import { ColocAPIConnectedTableImperativeMethods } from '@/features/shared/feature-coloc-api-connected-table/types';
import { BASE_URL } from '@/config';
import { RoomUrgencyStatusTableCellContent } from './components/table-cells/room-urgency-status-table-cell-content';
import { RoomAssignmentRoomCellContent } from './components/table-cells/room-assignment-room-cell-content';
import { RoomAssignmentStatusCellContent } from './components/table-cells/room-assignment-status-cell-content';
import { RoomAssignmentPropertyManagerTableCellContent } from './components/table-cells/room-assignment-property-manager-cell-content';
import { GetRoomAssignmentListFiltersContextProvider } from './hooks/use-get-room-assignment-list-filter';
import { RoomAssignmentListFilter } from './components/room-assignment-list-filter';
import { useDiscussionThreadProviderContext } from '@/features/shared/discussion-threads/hooks/use-discussion-thread';
import { RoomAssignmentApplicantsOverviewCellContent } from './components/table-cells/room-assignment-applicants-overview-cell-content';
import { ApplicantsOverviewSlideOver } from '../applicants-overview-slide-over';
import { useUpdateApplication } from '../applicants-overview-slide-over/hooks/use-update-application';
import { ChangeRoomAvailabilityModal } from './components/change-room-availability-modal';
import { ChangeMoveInModal } from './components/change-move-in-modal';
import { DiscussionThreadTableCellContent } from '@/features/shared/discussion-threads/components/discussion-thread-table-cell-content';
import { getRoomAssignment } from '@/services/coloc-api/admin';

const RoomAssignmentListContent = () => {
  const columnHelper = createColumnHelper<RoomAssignment>();
  const { t } = useTranslation();
  const tableRef = useRef<ColocAPIConnectedTableImperativeMethods>(null);
  const { openDiscussionThread } = useDiscussionThreadProviderContext()!;
  const {
    setShowApplicants,
    showApplicants,
    showUpdateRoomAvailability,
    setShowUpdateRoomAvailability,
    showUpdateMoveIn,
    setShowUpdateMoveIn,
    updateAssignmentInCache,
  } = useGetRoomsAssignmentContext()!;
  const { mutate: updateApplication } = useUpdateApplication()!;

  const columns = useMemo(
    () => [
      columnHelper.accessor('urgency', {
        id: 'urgency',
        cell: (info) => (
          <RoomUrgencyStatusTableCellContent
            status={info.getValue().status}
            reason={info.getValue().reason}
            reason_date={
              info.row.original.end_of_notice_period ||
              info.row.original.availability_date
            }
          />
        ),
        header: () =>
          t(
            'js.features.admin.contracts.room_assignment.table_columns.urgency'
          ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('room', {
        id: 'room',
        cell: (info) => (
          <RoomAssignmentRoomCellContent
            room={info.getValue()}
            current_tenant={info.row.original?.current_tenant}
            base_url={BASE_URL}
            new_room={info.row.original.new_room}
          />
        ),
        header: () =>
          t('js.features.admin.contracts.room_assignment.table_columns.room_and_current_tenant'),
        footer: (info) => info.column.id,
        meta: {
          className: 'w-[115px]',
          headerClassName: 'w-[115px]',
        },
      }),
      columnHelper.accessor('discussion_thread', {
        id: 'discussion_thread',
        cell: (info) => (
          <DiscussionThreadTableCellContent
            messages_count={info.getValue().messages_count}
            onClick={() => {
              openDiscussionThread({
                type: 'RoomAssignment',
                ...(info.getValue().id && info.getValue().id !== 'null'
                  ? { discussionThreadId: Number(info.getValue().id) }
                  : { discussableId: Number(info.row.original.id) }),
                onMessageSent: () => {
                  getRoomAssignment({ id: info.row.original.id }).then(
                    (response) => {
                      updateAssignmentInCache(response.data);
                    }
                  );
                },
              });
            }}
          />
        ),
        header: () =>
          t(
            'js.features.admin.contracts.room_assignment.table_columns.discussion_thread'
          ),
        footer: (info) => info.column.id,
        enableSorting: false,
        enableColumnFilter: false,
        meta: {
          className: 'w-[50px]',
          headerClassName: 'w-[50px]',
        },
      }),
      columnHelper.accessor('assignment_status', {
        id: 'assignment_status',
        cell: (info) => (
          <RoomAssignmentStatusCellContent
            status={info.getValue().status}
            awaiting_sepa_payment={info.row.original.checkin_contract?.security_deposit?.awaiting_sepa_payment}
            last_update={info.getValue().last_update}
            notice_of_termination_received_at={
              info.row.original.notice_of_termination_received_at
            }
          />
        ),
        header: () =>
          t('js.features.admin.contracts.room_assignment.table_columns.status'),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('property_manager', {
        id: 'property_manager',
        cell: (info) => (
          <RoomAssignmentPropertyManagerTableCellContent
            avatar_url={info.getValue().avatar_url}
            first_name={info.getValue().first_name}
            last_name={info.getValue().last_name}
          />
        ),
        header: () =>
          t(
            'js.features.admin.contracts.room_assignment.table_columns.property_manager'
          ),
        footer: (info) => info.column.id,
      }),

      columnHelper.accessor('end_of_notice_period', {
        id: 'end_of_notice_period',
        cell: (info) => (
          <Table.TableCellDefaultContent className="w-[95px]">
            {info.getValue()
              ? format(new Date(info.getValue()!), 'MMM d, yyyy')
              : '-'}
          </Table.TableCellDefaultContent>
        ),
        header: () =>
          t(
            'js.features.admin.contracts.room_assignment.table_columns.end_of_notice_period'
          ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('availability_date', {
        id: 'availability_date',
        cell: (info) => (
          <Table.TableCellDefaultContent className="w-[95px]">
            {info.getValue()
              ? format(new Date(info.getValue()!), 'MMM d, yyyy')
              : '-'}
          </Table.TableCellDefaultContent>
        ),
        header: () =>
          t(
            'js.features.admin.contracts.room_assignment.table_columns.availability_date'
          ),
        footer: (info) => info.column.id,
      }),

      columnHelper.accessor('rental_type', {
        id: 'rental_type',
        cell: (info) => (
          <Table.TableCellDefaultContent className="w-[95px]">
            {
              t(
                `js.features.admin.contracts.room_assignment.table_cells.rental_types.${info.getValue()}`
              )
            }
          </Table.TableCellDefaultContent>
        ),
        header: () =>
          t(
            'js.features.admin.contracts.room_assignment.table_columns.rental_type'
          ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('applications', {
        id: 'applications',
        cell: (info) => (
          <RoomAssignmentApplicantsOverviewCellContent
            replacement={info.row.original.checkin_tenant}
            applicants={info.getValue()}
            base_url={BASE_URL}
            showApplicants={() =>
              setShowApplicants({
                show: true,
                assignment: info.row.original,
              })
            }
          />
        ),
        header: () =>
          t(
            'js.features.admin.contracts.room_assignment.table_columns.replacement'
          ),
        footer: (info) => info.column.id,
        enableSorting: false,
        enableColumnFilter: false,
      }),
      columnHelper.display({
        id: 'actions',
        cell: ({ row }) => (
          <Table.TableCellRowActionsContent>
            <Table.TableCellRowActionsContent.TableRowActions>
              <Table.TableCellRowActionsContent.TableRowAction
                onClick={() =>
                  setShowUpdateRoomAvailability({
                    show: true,
                    assignment: row.original,
                  })
                }
              >
                <Table.TableCellRowActionsContent.TableRowActionContent
                  contentBefore={<IconSaxIcon name="calendar" size={20} />}
                >
                  {t(
                    'js.features.admin.contracts.room_assignment.row_actions.edit_availability'
                  ) ?? ''}
                </Table.TableCellRowActionsContent.TableRowActionContent>
              </Table.TableCellRowActionsContent.TableRowAction>
            </Table.TableCellRowActionsContent.TableRowActions>
          </Table.TableCellRowActionsContent>
        ),
        footer: (info) => info.column.id,
      }),
    ],
    [
      columnHelper,
      openDiscussionThread,
      setShowApplicants,
      setShowUpdateRoomAvailability,
      t,
    ]
  );

  return (
    <>
      <ApplicantsOverviewSlideOver
        show={showApplicants.show}
        assignment={showApplicants.assignment}
        close={() =>
          setShowApplicants({
            show: false,
            assignment: undefined,
          })
        }
        updatingApplication={updateApplication.isLoading}
        onEditMoveIn={(applicant) => {
          setShowUpdateMoveIn({ show: true, applicant });
        }}
        onAccept={(applicant) =>
          updateApplication.mutate({
            contract_id: String(applicant.contract.id),
            status: 'accepted',
          })
        }
        onDecline={(applicant) =>
          updateApplication.mutate({
            contract_id: String(applicant.contract.id),
            status: 'cancelled',
          })
        }
      />

      {showUpdateRoomAvailability.show &&
        showUpdateRoomAvailability.assignment && (
          <ChangeRoomAvailabilityModal
            open={showUpdateRoomAvailability.show}
            room={showUpdateRoomAvailability.assignment.room}
            availability_date={
              showUpdateRoomAvailability.assignment.availability_date
            }
            end_of_notice_period={
              showUpdateRoomAvailability.assignment.end_of_notice_period
            }
            onClose={() =>
              setShowUpdateRoomAvailability({
                show: false,
                assignment: undefined,
              })
            }
          />
        )}

      {showApplicants.show &&
        showApplicants.assignment &&
        showUpdateMoveIn.show &&
        showUpdateMoveIn.applicant && (
          <ChangeMoveInModal
            open={showUpdateMoveIn.show}
            applicant={showUpdateMoveIn.applicant}
            assignment={showApplicants.assignment}
            status={showUpdateMoveIn.status}
            onClose={() =>
              setShowUpdateMoveIn({
                show: false,
                status: undefined,
                applicant: undefined,
              })
            }
          />
        )}

      <ColocAPIConnectedTable<RoomAssignment, RoomAssignmentListSortType>
        ref={tableRef}
        columns={columns}
        dataSource={useGetRoomsAssignmentContext()!}
      />
    </>
  );
};

const RoomAssignmentList = () => {
  return (
    <GetRoomAssignmentListFiltersContextProvider>
      <GetRoomsAssignmentListContextProvider>
        <div className="flex h-full w-full flex-1 flex-col text-[14px] leading-[21px]">
          <RoomAssignmentListFilter />
          <RoomAssignmentListContent />
        </div>
      </GetRoomsAssignmentListContextProvider>
    </GetRoomAssignmentListFiltersContextProvider>
  );
};

export { RoomAssignmentList };
