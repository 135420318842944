<template>
<section class="vue-fullwidth">
	<h2 class="display-4 mb-3">{{t('components.landing_lifestyles.title')}}</h2>
	<div class="slider">
		<button class="slider__ctrls prev" data-prev="0">
			<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#1A1A1A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
				<path d="M15 18l-6-6 6-6" />
			</svg>
		</button>
		<button class="slider__ctrls next" data-next="1">
			<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#1A1A1A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
				<path d="M9 18l6-6-6-6" />
			</svg>
		</button>
		<div class="slider__view" style="margin: 0 -24px;">
			<ul class="slider__list" style="padding: 0 24px;">
				<li v-for="lifestyle in lifestyles" :key="lifestyle.path" class="slider__item">
					<a :href="lifestyle.path" class="slider__link">
						<picture class="slider__picture">
							<img :src="lifestyle.photo || require('assets/images/no-img-fallback.png')" :alt="lifestyle.name" class="img-fluid slider__img" loading="lazy">
						</picture>
						<p class="slider__title">{{lifestyle.name}}</p>
					</a>
				</li>
			</ul>
		</div>
	</div>
</section>
</template>

<script>
import i18n from '../../vue_mixins/i18n';

export default {
  mixins: [i18n],
  props: ['lifestyles'],
}

var sliders = document.querySelectorAll('.slider');
    if (sliders.length > 0){
      showSliderBtn();
      scrollSliders();
    }

    function showSliderBtn(){
      for (var i = 0; i < sliders.length; i++){
        var list = sliders[i];
        var nextBtn = list.querySelector('.next');
        var childrenSize = list.querySelectorAll('.slider__item')[0].offsetWidth;
        var childrenNb = list.querySelectorAll('.slider__item').length;
        var apparentChild = Math.floor(list.offsetWidth / childrenSize);
        var limit = childrenNb - apparentChild;

        if (limit == 0){
          nextBtn.style.display = "none";
        }
        else{
          nextBtn.style.display = "flex";
        }
      }
    }

    function scrollSliders(){
      var sliderBtn = document.querySelectorAll(".slider__ctrls");

      if (sliderBtn){
        sliderBtn.forEach(scroll);
      }

      function scroll(btn) {
        btn.addEventListener("click", function(){
          var next = parseInt(btn.dataset.next);
          var prev = parseInt(btn.dataset.prev);
          var btnClicked = this;
          var parent = btnClicked.parentNode;
          var list = parent.querySelector(".slider__list");
          var childrenSize = list.querySelectorAll('.slider__item')[0].offsetWidth;
          var childrenNb = list.querySelectorAll('.slider__item').length;
          var apparentChild = Math.floor(list.offsetWidth / childrenSize);
          var limit = childrenNb - apparentChild;
          console.log(limit);
          var prevBtn = this.parentNode.querySelector('.prev');
          var nextBtn = this.parentNode.querySelector('.next');

          if (btnClicked.classList[1] == "next") {
            if( next <= limit){
              list.style.overflowX = 'initial';
              list.style.setProperty("transform", "translate(-" +  childrenSize * next + "px, 0)");
              next += 1;
              prev = next - 1;
              this.setAttribute("data-next", next);
              prevBtn.setAttribute("data-prev", prev);
              prevBtn.style.display = "flex"
            }
            if (next == (limit + 1)){
              nextBtn.style.display = "none"
            }
          }
          else {
            if (prev == 1){
              prevBtn.style.display = "none";
              setTimeout(function(){list.style.overflowX = 'auto';}, 500);
            }
            if (prev == 0) {
              list.style.setProperty("transform", "translate(0px, 0)");
              prev = 0;
              next = 1;
            }
            else{
              nextBtn.style.display = "flex"
              prev = prev - 1;
              list.style.setProperty("transform", "translate(-" +  childrenSize * prev + "px, 0)");
              next = prev + 1;
              nextBtn.setAttribute("data-next", next);
              this.setAttribute("data-prev", prev);
            }
          }
        });
      }
    }
</script>
