<template>
  <div class="ui-text-input__container">
    <label
      v-if="showLabel"
      :class="[
        'ui-text-input__label',
        disabled && 'ui-text-input__label--disabled',
      ]"
      >{{ label }}</label
    >
    <textarea
      :id="label"
      :class="textInputStyling"
      :placeholder="placeholder"
      oninvalid="toggleFieldInvalid"
      :disabled="disabled"
      :value="value"
      :style="{ height: height }"
    />
    <span
      v-if="showHelpOrErrorMessage"
      :class="[
        'ui-text-input__help-text',
        invalid && 'ui-text-input__help-text--error',
      ]"
      >{{ helpOrErrorMessage }}</span
    >
  </div>
</template>

<script>
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig, {
  theme,
} from '../../../../../../../../../config/tailwind.config';
import '../Text.scss';
import './TextArea.scss';

const fullConfig = resolveConfig(tailwindConfig);

export default {
  props: {
    /**
     * label of the input. Required for accessibility reasons.
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * Show the label above the input.
     */
    showLabel: {
      type: Boolean,
    },
    /**
     * Help message for input field.
     */
    helpMessage: {
      type: String,
    },
    /**
     * Show help message for input field.
     */
    showHelpMessage: {
      type: Boolean,
      default: true,
    },
    /**
     * Is input disabled or not.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Is input valid or not.
     */
    invalid: {
      type: Boolean,
      default: false,
    },
    /**
     * Error message to show when field is invalid.
     */
    errorMessage: {
      type: String,
    },
    /**
     * Show error message when field is invalid.
     */
    showErrorMessage: {
      type: Boolean,
      default: true,
    },
    /**
     * Value of the input.
     */
    value: {
      type: String,
    },
    /**
     * Placeholder value.
     */
    placeholder: {
      type: String,
    },
    /**
     * Height in px.
     */
    height: {
      type: String,
      default: theme.spacing[11],
      validator(value) {
        return value.includes('px');
      },
    },
  },
  data: () => ({ theme: fullConfig.theme }),
  computed: {
    showHelpOrErrorMessage() {
      if (this.errorMessage && this.showErrorMessage && this.invalid) {
        return true;
      }

      if (this.helpMessage && this.showHelpMessage) {
        return true;
      }

      return false;
    },
    helpOrErrorMessage() {
      if (this.errorMessage && this.showErrorMessage && this.invalid) {
        return this.errorMessage;
      }

      if (this.helpMessage && this.showHelpMessage) {
        return this.helpMessage;
      }

      return null;
    },
    textInputStyling() {
      return [
        'ui-text-input',
        'ui-text-input--area',
        this.invalid && 'ui-text-input--error',
        this.disabled && 'ui-text-input--disabled',
      ];
    },
  },
};
</script>
