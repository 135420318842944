import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { composeClassName } from '@/utils/util_helpers';
import { CityCardProps } from './types';
import { useCitiesListContext } from '@/features/marketplace/hooks/use-get-cities';
import { useLocale } from '@/hooks/use-locale';
import './styles.css';
import { IconSaxIcon } from '@/components/design-system/atoms/icon';
import { theme } from '@/utils/util_theme';
import { COLOC_MAIN_APP } from '@/config';
import { useWindowDimensions } from '@/hooks/use-window-dimensions';

const CityCard: React.FC<CityCardProps> = ({
  cityId,
  cityName: passedCityName,
  className,
  onClick,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const { currentLocale } = useLocale();
  const { t } = useTranslation();

  const { screenIsLargeOrEqualThan } = useWindowDimensions();

  const { getCityDetail } = useCitiesListContext()!;

  const cityDetail = useMemo(() => {
    return getCityDetail(cityId);
  }, [cityId, getCityDetail]);

  const cityName = useMemo(() => {
    if (cityDetail) {
      return cityDetail.name_translations[currentLocale];
    }
    return undefined;
  }, [cityDetail, currentLocale]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={composeClassName(
        'relative h-[430px] w-full self-start object-cover',
        screenIsLargeOrEqualThan(450) && 'w-[364px]',
        className && className
      )}
      onClick={(e) => onClick && onClick(e)}
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <picture className="h-full w-full overflow-hidden object-cover">
        <source
          srcSet={`${require(`@assets/images/marketplace/cities/${passedCityName}.webp`)} 1x, ${require(`@assets/images/marketplace/cities/${passedCityName}@2x.webp`)} 2x, ${require(`@assets/images/marketplace/cities/${passedCityName}@3x.webp`)} 3x`}
          type="image/webp"
        />
        <img
          className="h-full w-full object-cover"
          src={require(`@assets/images/marketplace/cities/${passedCityName}`)}
          alt={passedCityName}
        />
      </picture>
      <div
        className={composeClassName(
          'city-title__container',
          isHovering && 'city-title__container--hover'
        )}
      >
        <div
          className={composeClassName(
            'city-title__wrapper',
            isHovering && 'city-title__wrapper--hover'
          )}
        >
          <span
            className={composeClassName(
              'city-title',
              isHovering && 'city-title--hover'
            )}
          >
            {cityName}
          </span>
          {cityDetail?.num_houses && (
            <span
              className={composeClassName(
                'room-number',
                isHovering && 'room-number--hover'
              )}
            >
              {cityDetail.num_houses > 1
                ? t(
                    'js.features.marketplace.home.section2.city_card.houses_text',
                    { num_houses: cityDetail.num_houses }
                  )
                : t(
                    'js.features.marketplace.home.section2.city_card.house_text'
                  )}
            </span>
          )}
        </div>
        <div className="absolute bottom-5 z-30 flex w-full justify-center">
          <a
            href={`${COLOC_MAIN_APP}/${currentLocale}/shared-houses-${cityDetail?.name_translations.en.toLowerCase()}`}
            className={composeClassName(
              'explore-city__button',
              isHovering && 'explore-city__button--hover'
            )}
          >
            <span className="text-body font-medium">
              {t(
                'js.features.marketplace.home.section2.city_card.explore_button'
              )}
            </span>
            <IconSaxIcon
              name="arrow-right"
              strokeColor={theme?.colors?.white}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export { CityCard };
