import React from 'react';
import { useTranslation } from 'react-i18next';
import { endOfMonth } from 'date-fns';
import { Modal } from '@/components/design-system/atoms/modal';
import { useCreateRentReportContext } from '../../hooks/use-create-rent-report';
import { CreateRentReportModalProps } from './types';
import { SelectHouseCreateReportDropdown } from './components/select-house-create-report-dropdown';
import { DatePicker } from '@/components/design-system/molecules/datepicker';
import { Button } from '@/components/design-system/atoms/button';

const CreateRentReportModal: React.FC<CreateRentReportModalProps> = ({
  open,
  onClose,
}) => {
  const {
    setSelectedDate,
    selectedDate,
    toggleShowCreateRentReportModal,
    mutate,
    reportIsCreatable,
  } = useCreateRentReportContext()!;
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={onClose}>
      <div className="flex w-[500px] flex-col gap-5 rounded-lg bg-white p-7 shadow-medium">
        <div className="flex w-full flex-col gap-2 text-text-primaryHeavy">
          <span className="text-lead2 font-bold">
            {t('js.features.admin.rent_report.create_report_modal.title')}
          </span>
          <span className="text-body text-text-primaryMedium">
            {t('js.features.admin.rent_report.create_report_modal.text')}
          </span>
        </div>
        <div className="flex w-full flex-col gap-3">
          <SelectHouseCreateReportDropdown />
          <DatePicker
            type="month"
            placeholder={
              t(
                'js.features.admin.rent_report.create_report_modal.date_placeholder'
              ) ?? ''
            }
            onChange={setSelectedDate}
            value={selectedDate}
            maxDate={endOfMonth(new Date())}
            label={
              t(
                'js.features.admin.rent_report.create_report_modal.date_label'
              ) ?? ''
            }
          />
        </div>
        <div className="flex gap-4">
          <Button
            variant="primary"
            disabled={!reportIsCreatable || mutate.isLoading}
            loading={mutate.isLoading}
            onClick={() => mutate.mutate()}
          >
            {t(
              'js.features.admin.rent_report.create_report_modal.create_button'
            ) ?? ''}
          </Button>
          <Button
            variant="ghost"
            disabled={mutate.isLoading}
            onClick={() => {
              toggleShowCreateRentReportModal({
                show: false,
              });
            }}
          >
            {t(
              'js.features.admin.rent_report.create_report_modal.cancel_button'
            ) ?? ''}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export { CreateRentReportModal };
