import React, { useRef } from 'react';
import { theme } from '@/utils/util_theme';
import { IconSaxIcon } from '../../atoms/icon';
import { TextInput } from '../../atoms/text/text-input';
import { SearchInputProps } from './types';

const SearchInput: React.FC<SearchInputProps> = ({
  className,
  value,
  onChange,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <TextInput
      {...props}
      ref={inputRef}
      value={value}
      type="search"
      containerClassName={className}
      onChange={onChange}
      contentAfter={
        <IconSaxIcon
          name={value ? 'close' : 'search-normal-1'}
          strokeColor={theme?.colors.text.tertiary}
          onClick={() => {
            if (inputRef?.current?.value) {
              // @ts-ignore
              // eslint-disable-next-line
              inputRef.current.value = null;
              inputRef.current.dispatchEvent(
                new Event('input', { bubbles: true })
              );
            }
          }}
        />
      }
    />
  );
};

export { SearchInput };
