const MOBILE_BREAK_POINT = 480;
const TABLET_BREAK_POINT = 768;
const LAPTOP_BREAK_POINT = 1024;
const DESKTOP_BREAK_POINT = 1280;
const HEADER_HEIGHT = 'h-[8rem]';
const PADDING_TOP_HEADER_HEIGHT = 'pt-[8rem]';

export {
  MOBILE_BREAK_POINT,
  TABLET_BREAK_POINT,
  LAPTOP_BREAK_POINT,
  DESKTOP_BREAK_POINT,
  HEADER_HEIGHT,
  PADDING_TOP_HEADER_HEIGHT,
};
