<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <label class="ui-form-toggle">
    <input
      type="checkbox"
      :checked="value"
      :disabled="disabled"
      @input="$emit('input', $event.target.checked)"
    />
    <span class="lable">{{ label }}</span>
  </label>
</template>

<script>
import './Toggle.scss';

export default {
  props: {
    label: [String],
    value: Boolean,
    disabled: Boolean,
  },
};
</script>