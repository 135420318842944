<template>
  <div class="d-flex">
    <div class="mr-2">
      <img class="rounded" :src="item.image || require('assets/images/no-img-fallback.png')" width="32" height="32" alt="">
    </div>
    <div>
      <div>{{ item.name }}</div>
      <div class="small text-muted" v-if="item.rooms_available != undefined">
        {{roomsAvailability}}
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '../vue_mixins/i18n';

export default {
  mixins: [
    i18n,
  ],
  props: {
    item: { required: true }
  },
  computed: {
    roomsAvailability() {
      if (this.item.founded) {
        return this.t('vue_components.search_bar_item.rooms_available_soon', { count: this.item.rooms_available });
      } else {
        return this.t('vue_components.search_bar_item.rooms_available', { count: this.item.rooms_available });
      }
    }
  }
}
</script>
