import { useMutation } from '@tanstack/react-query';

import { useMemo } from 'react';
import { showToast } from '@/services/toast-service';
import { createPayout } from '@/services/coloc-api/admin';
import { useGetPayoutSummaryContext } from '../use-get-payout-summary';
import { BASE_URL } from '@/config';

const useCreatePayout = () => {
  const { passedRentIds } = useGetPayoutSummaryContext()!;

  const passedRentIdsAsObjects: Array<{ rent_id: number }> = useMemo(() => {
    const rentIDArray = passedRentIds.split(',').map((rentId) => {
      return { rent_id: Number(rentId.trim()) };
    });
    return rentIDArray;
  }, [passedRentIds]);

  const mutate = useMutation({
    mutationKey: ['createPayout'],
    mutationFn: () => {
      return createPayout({
        redirect_uri: `${window.location.origin}/admin/transactions?outgoing=true`,
        items: passedRentIdsAsObjects,
      });
    },
    onSuccess: (data) => {
      window.location.replace(data.data.data.payment.links.redirect);
    },
    onError: () => {
      showToast.error({});
    },
  });

  return {
    mutate,
  };
};

export { useCreatePayout };
