import { AxiosRequestConfig } from 'axios';
import { axiosLib } from '@/lib/axios';
import { getColocApiCallProperties } from '..';

import { COLOC_APP_API_HOST } from '@/config';
import { CityDetailResults, RoomSearchResult } from '@/types/marketplace';

const getColocMarketPlaceApiCallProperties = (): AxiosRequestConfig => {
  return {
    ...getColocApiCallProperties(),
    baseURL: `${COLOC_APP_API_HOST}/api/v1/marketplace`,
  };
};

type getSearchRoomAutoCompleteResultsParams = {
  q?: string;
};

export type getSearchRoomAutoCompleteResultsResponse = Array<RoomSearchResult>;

export const getSearchRoomAutoCompleteResults = (
  params: getSearchRoomAutoCompleteResultsParams
) => {
  return axiosLib.get<getSearchRoomAutoCompleteResultsResponse>(
    `/autocomplete`,
    {
      baseURL: `${COLOC_APP_API_HOST}`,
      params: { ...params },
    }
  );
};

export type getCitiesResponse = Array<CityDetailResults>;

export const getCitiesList = () => {
  return axiosLib.get<getCitiesResponse>(`/cities`, {
    ...getColocMarketPlaceApiCallProperties(),
  });
};
