import React, { createContext, useContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getPayoutPreviewSummary } from '@/services/coloc-api/admin';
import { Payout } from '@/features/admin/payouts/types';

const useGetPayoutSummary = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const queryParams = Object.fromEntries(urlSearchParams.entries());
  const passedRentIds = queryParams?.rent_ids ?? '';
  const [showRentDetailsPayoutModal, toggleShowRentDetailsPayoutModal] =
    useState<{ show: boolean; payout: Payout | undefined }>({
      show: false,
      payout: undefined,
    });

  const fetch = useQuery({
    queryKey: ['getPayoutSummary', passedRentIds],
    queryFn: () =>
      getPayoutPreviewSummary({
        rent_ids: passedRentIds,
      }),
    keepPreviousData: true,
  });

  return {
    fetch,
    showRentDetailsPayoutModal,
    toggleShowRentDetailsPayoutModal,
    passedRentIds,
  };
};

const GetPayoutSummaryContext = createContext<
  ReturnType<typeof useGetPayoutSummary> | undefined
>(undefined);

const GetPayoutSummaryContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const hook = useGetPayoutSummary();

  return (
    <GetPayoutSummaryContext.Provider value={hook}>
      {children}
    </GetPayoutSummaryContext.Provider>
  );
};

const useGetPayoutSummaryContext = () => {
  const context = useContext(GetPayoutSummaryContext);

  if (context === null) {
    throw new Error(
      'useGetPayoutSummaryContext must be used within a GetPayoutSummaryContextProvider'
    );
  }

  return context;
};

export { GetPayoutSummaryContextProvider, useGetPayoutSummaryContext };
