import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { QueryKey, useQuery } from '@tanstack/react-query';

import { RowSelectionState } from '@tanstack/react-table';

import { AxiosResponse } from 'axios';
import {
  GetRentReportListResponse,
  rentReportListSortableIds,
  RentReportListSortType,
} from '@/lib/coloc-api/admin';
import { getRentReportList } from '@/services/coloc-api/admin';
import { useColocApi } from '@/hooks/use-coloc-api';

import { useRentReportListFiltersContext } from '../use-get-rent-report-filter';
import { RentReport } from '@/features/admin/rents/types';
import { queryClient } from '@/lib/react-query';

const useGetRentReportList = () => {
  const {
    endDate,
    startDate,
    selectedReportStatus,
    setColumnToSort,
    sortDirection,
    selectedColumnToSort,
    selectedHouse,
  } = useRentReportListFiltersContext()!;
  const {
    setPage,
    setPageLimit,
    page,
    pageLimit,
    resultSummary,
    setPaginationResponse,
  } = useColocApi<RentReportListSortType>()!;
  const sortableColumns = rentReportListSortableIds;
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [showDownloadReportVersionModal, toggleShowDownloadReportVersionModal] =
    useState<{ show: boolean; report: RentReport | undefined }>({
      show: false,
      report: undefined,
    });
  const [showCreateRentReportModal, toggleShowCreateRentReportModal] =
    useState<{
      show: boolean;
    }>({
      show: false,
    });

  const queryKey: QueryKey = useMemo(
    () => [
      'getRentReportList',
      pageLimit,
      page,
      selectedColumnToSort,
      sortDirection,
      selectedHouse,
      startDate,
      endDate,
      selectedReportStatus,
    ],
    [
      pageLimit,
      page,
      selectedColumnToSort,
      sortDirection,
      selectedHouse,
      startDate,
      endDate,
      selectedReportStatus,
    ]
  );

  const fetch = useQuery({
    queryKey,
    queryFn: () =>
      getRentReportList({
        ...(pageLimit && { page_limit: pageLimit }),
        ...(page && { page }),
        ...(sortDirection && { sort_direction: sortDirection }),
        ...(selectedColumnToSort && { sort: selectedColumnToSort }),
        ...(selectedReportStatus && {
          status: selectedReportStatus.status,
        }),
        ...(selectedHouse && { house_id: selectedHouse.id }),
        ...(startDate && { start_date: startDate }),
        ...(endDate && { end_date: endDate }),
      }),
    keepPreviousData: true,
  });

  const updateRentReportsInCache = useCallback(
    (reports: Array<RentReport>) => {
      queryClient.setQueryData(
        queryKey,
        (
          oldData: AxiosResponse<GetRentReportListResponse, any> | undefined
        ) => {
          if (oldData?.data) {
            const oldDataCopy = { ...oldData };
            const newData = oldDataCopy.data.data.map((mappedReport) => {
              const foundReport = reports.find(
                (report) => report.id === mappedReport.id
              );
              return foundReport ?? mappedReport;
            });
            oldDataCopy.data.data = newData;

            return oldDataCopy;
          }
          return oldData;
        }
      );
    },
    [queryKey]
  );

  const rowSelectionFullData = useMemo(() => {
    if (fetch?.data?.data && fetch?.data?.data.data?.length >= 1) {
      const dataCopy = [...fetch.data.data.data];
      const selectedData: Array<RentReport> = [];
      // @ts-ignore
      Object.keys(rowSelection).forEach((value: number) => {
        selectedData.push(dataCopy[value]);
      });
      return selectedData;
    }
    return [];
  }, [fetch.data?.data, rowSelection]);

  useEffect(() => {
    setPaginationResponse(
      fetch.data?.data.pagination
        ? { pagination: fetch.data?.data.pagination }
        : undefined
    );
  }, [fetch.data?.data.pagination, setPaginationResponse]);

  // Reset rowSelection when changing page.
  useEffect(() => {
    setRowSelection({});
  }, [
    page,
    // sortDirection, selectedColumnToSort
  ]);

  return {
    pageLimit,
    setPageLimit,
    fetch,
    setPage,
    sortableColumns,
    selectedColumnToSort,
    setColumnToSort,
    sortDirection,
    resultSummary,
    rowSelection,
    rowSelectionFullData,
    setRowSelection,
    showDownloadReportVersionModal,
    toggleShowDownloadReportVersionModal,
    showCreateRentReportModal,
    toggleShowCreateRentReportModal,
    updateRentReportsInCache,
  };
};

const GetRentReportListContext = createContext<
  ReturnType<typeof useGetRentReportList> | undefined
>(undefined);

const GetRentReportListContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const hook = useGetRentReportList();

  return (
    <GetRentReportListContext.Provider value={hook}>
      {children}
    </GetRentReportListContext.Provider>
  );
};

const useGetRentReportListContext = () => {
  const context = useContext(GetRentReportListContext)!;

  if (context === null) {
    throw new Error(
      'useGetRentReportListContext must be used within a GetRentReportListContextProvider'
    );
  }

  return context;
};

export { GetRentReportListContextProvider, useGetRentReportListContext };
