import React, { useEffect, useState } from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useTranslation } from 'react-i18next';
import { ReactQueryProvider } from '@/providers/react-query-provider';
import { Tabs } from '@/components/design-system/molecules/tabs';
import { ToastProvider } from '@/providers/toast-provider';
import {
  AdminRoutes,
  AdminSideNavigation,
} from '@/features/admin/navigation/containers/admin-side-navigation';
import { RoomAssignmentList } from '@/features/admin/contracts/components/room-assignment-list';
import { AuthContextProvider } from '@/hooks/use-auth';
import { DiscussionThreadsProvider } from '@/features/shared/discussion-threads/providers';
import { NotificationsProvider } from '@/features/shared/notifications/provider';

const ContractsScreen = () => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(1);

  // Check for URL parameters
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const queryParams = Object.fromEntries(urlSearchParams.entries());
    const containsAll = queryParams?.all;

    if (containsAll && containsAll === 'true') {
      setSelectedTab(1);
    }
  }, []);

  return (
    <ReactQueryProvider>
      <ToastProvider />
      <AuthContextProvider>
        <DiscussionThreadsProvider>
          <NotificationsProvider>

            {/* Dev tools wil only be displayed during development */}
            <ReactQueryDevtools initialIsOpen={false} />
            <div className="flex h-screen w-screen">
              <AdminSideNavigation tabToSet={AdminRoutes.Contracts} />
              <div className="flex h-full flex-1 flex-col overflow-hidden px-7 pt-7">
                <h1 className="pb-3 text-h3 font-bold text-text-primary">
                  {t('js.features.admin.contracts.title')}
                </h1>
                <div className="flex w-full flex-1 flex-col overflow-hidden bg-E10">
                  <Tabs
                    selectedIndex={selectedTab}
                    onChange={(index) => setSelectedTab(Number(index))}
                  >
                    <Tabs.TabsList>
                      <Tabs.Tab disabled>
                        <Tabs.TabDefaultContent>
                          {t(
                            'js.features.admin.contracts.contracts_page_tabs.all_contracts'
                          ) ?? ''}
                        </Tabs.TabDefaultContent>
                      </Tabs.Tab>
                      <Tabs.Tab>
                        <Tabs.TabDefaultContent>
                          {t(
                            'js.features.admin.contracts.contracts_page_tabs.room_assignment'
                          ) ?? ''}
                        </Tabs.TabDefaultContent>
                      </Tabs.Tab>
                    </Tabs.TabsList>
                    <Tabs.TabsScreens>
                      <Tabs.TabScreen>
                        <div />
                      </Tabs.TabScreen>
                      <Tabs.TabScreen>
                        <RoomAssignmentList />
                      </Tabs.TabScreen>
                    </Tabs.TabsScreens>
                  </Tabs>
                </div>
              </div>
            </div>

          </NotificationsProvider>
        </DiscussionThreadsProvider>
      </AuthContextProvider>
    </ReactQueryProvider>
  );
};

export { ContractsScreen };
