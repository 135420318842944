import React from 'react';

const OptionStateSelector = ({ options, selectedOption, onSelect }) => {
  return <div className="flex space-x-4">
      {
        options.map((option) => {
          return (
            <button
              key={option.value}
              className={`rounded-full px-4 py-2 ${
                selectedOption && selectedOption === option.value
                  ? 'bg-black text-white'
                  : 'border-[1px] border-black'
              }`}
              onClick={() => onSelect(option.value)}
            >
              {option.label}
            </button>
          );
      })
      }
  </div>
};

export { OptionStateSelector };
