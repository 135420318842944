import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@/components/design-system/atoms/modal';

import { TransactionPairModalProps } from './types';
import { extractDescription } from '@/utils/util_helpers/formatting/extract_description_message';
import { PairTransactionHouseSelectionDropDown } from './components/pair-transaction-house-selection-dropdown';
import { PairTransactionRoomSelectionDropDown } from './components/pair-transaction-room-selection-dropdown';
import { PairTransactionTenantSelectionDropDown } from './components/pair-transaction-tenant-selection-dropdown';
import { PairTransactionRentSelectionDropDown } from './components/pair-transaction-rent-selection-dropdown';
import { Button } from '@/components/design-system/atoms/button';
import { usePairTransactionWithRentContext } from '@/features/admin/transactions/components/incoming-transactions-list/hooks/use-pair-transaction-with-rent';
import { capitalize } from '@/utils/util_helpers/formatting/capitalize';

const TransactionPairModal: React.FC<TransactionPairModalProps> = ({
  open,
  onClose,
  transaction,
}) => {
  const { t } = useTranslation();
  const {
    rentIsSubmittable,
    toggleShowPairTransactionModal,
    mutate: submitForm,
  } = usePairTransactionWithRentContext()!;

  return (
    <Modal open={open} onClose={onClose}>
      <div className="flex w-[500px] flex-col gap-5 rounded-lg bg-white p-7 shadow-medium">
        <div className="flex w-full flex-col gap-3 text-text-primary">
          <span className="text-lead1 font-bold">
            {t(
              'js.features.admin.transactions.transaction_pairing_modal.title'
            )}
          </span>
          <span className="text-lead2">
            {t('js.features.admin.transactions.transaction_pairing_modal.text')}
          </span>
          <div className="flex flex-col text-lead2">
            <span>
              {t(
                'js.features.admin.transactions.transaction_pairing_modal.bank_description_text'
              )}
              <span className="font-bold">
                {`  ${
                  transaction.bank_description
                    ? extractDescription(transaction.bank_description)
                    : ''
                }`}
              </span>
            </span>
            <span>
              {t(
                'js.features.admin.transactions.transaction_pairing_modal.account_name_text'
              )}
              <span className="font-bold">
                <span className="font-bold">{`  ${capitalize(
                  transaction.sender.account_name || ''
                )}`}</span>
              </span>
            </span>
            <span>
              {t(
                'js.features.admin.transactions.transaction_pairing_modal.account_number_text'
              )}
              <span className="font-bold">{`  ${transaction.sender.account_number}`}</span>
            </span>
          </div>
        </div>
        <div className="flex w-full flex-col gap-3">
          <PairTransactionHouseSelectionDropDown />
          <PairTransactionRoomSelectionDropDown />
          <PairTransactionTenantSelectionDropDown />
          <PairTransactionRentSelectionDropDown />
        </div>
        <div className="flex gap-4">
          <Button
            variant="primary"
            disabled={!rentIsSubmittable}
            onClick={() => {
              submitForm.mutate();
            }}
          >
            {t(
              'js.features.admin.transactions.transaction_pairing_modal.pair_button'
            ) ?? ''}
          </Button>
          <Button
            variant="ghost"
            onClick={() => {
              toggleShowPairTransactionModal({
                show: false,
                transaction: undefined,
              });
            }}
          >
            {t(
              'js.features.admin.transactions.transaction_pairing_modal.cancel_button'
            ) ?? ''}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export { TransactionPairModal };
