<template>
  <section class="vue-fullwidth cities">
    <div class="citySlider">
      <button class="citySlider__ctrls prev">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#1A1A1A"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="M15 18l-6-6 6-6" />
        </svg>
      </button>
      <button class="citySlider__ctrls next">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#1A1A1A"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="M9 18l6-6-6-6" />
        </svg>
      </button>
      <div class="citySlider__limit">
        <ul
          class="citySlider__list"
          style="
            grid-template-areas: 'city1 city2 city5 city6' 'city3 city4 city7 city8';
          "
        >
          <li
            class="citySlider__item"
            v-for="city in cities"
            v-bind:key="city.name"
          >
            <a
              :href="
                colocations_path({
                  locale: currentLocale(),
                  city_slug: city.slug,
                  colocations: { district_id: '', place_id: '' },
                })
              "
              class="citySlider__link"
            >
              <picture class="citySlider__picture">
                <img :src="city.photo" class="citySlider__img" loading="lazy" />
              </picture>
              <h3 class="citySlider__title">
                {{ city.name }}
              </h3>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="cityDesc">
      <h2 class="display-3">
        {{ t('components.cities_hero.title') }}
      </h2>
      <p class="lead d-none d-lg-block">
        {{ t('components.cities_hero.paragraph') }}
      </p>
      <a
        :href="colocations_path({ locale: currentLocale() })"
        class="lead display-4 d-none d-lg-block mb-4 mb-lg-8"
      >
        {{ t('components.cities_hero.cta') }}
      </a>
    </div>
  </section>
</template>

<script>
import i18n from '../../vue_mixins/i18n';
import Routes from '../../routes';

export default {
  mixins: [i18n],
  props: ['cities'],
  mounted() {
    citySlider(this.$el);
  },
  methods: {
    colocations_path(params) {
      const { locale } = params;
      return Routes[`colocations_${locale}_path`](params);
    },
  },
};

function citySlider(rootElement) {
  var citySlider = rootElement.querySelectorAll('.citySlider');
  if (citySlider.length > 0) {
    setGridArea();
    scrollCitySliders();
  }

  function setGridArea() {
    for (var i = 0; i < citySlider.length; i++) {
      var list = citySlider[i];
      var citySliderItems = list.querySelectorAll('.citySlider__item');
      for (var j = 0; j < citySliderItems.length; j++) {
        citySliderItems[j].style.gridArea = 'city' + (j + 1);
      }
    }
  }

  function scrollCitySliders() {
    var sliderBtn = document.querySelectorAll('.citySlider__ctrls');

    if (sliderBtn) {
      sliderBtn.forEach(scroll);
    }
    function scroll(btn) {
      btn.addEventListener('click', function () {
        var slider = btn.parentNode;
        var sliderItems = slider.querySelectorAll('.citySlider__item');

        for (var i = 0; i < sliderItems.length; i++) {
          var cityArea = sliderItems[i].getAttribute('style');
          var cityNb = parseInt(cityArea.slice(-2, -1));
          if (this.classList[1] == 'next') {
            if (cityNb == 1) {
              sliderItems[i].style.gridArea = 'city' + sliderItems.length;
            } else {
              var newCityNb = cityNb - 1;
              sliderItems[i].style.gridArea = 'city' + newCityNb;
            }
          } else {
            if (cityNb == sliderItems.length) {
              sliderItems[i].style.gridArea = 'city1';
            } else {
              var newCityNb = cityNb + 1;
              sliderItems[i].style.gridArea = 'city' + newCityNb;
            }
          }
        }
      });
    }
  }
}
</script>
