import React from 'react';
import { composeClassName } from '../../../../../../utils/util_helpers';
import { afterTextInputStyle } from '../../../../shared-styles';
import { TextInputContentAfterWrapperProps } from './types';

const TextInputContentAfterWrapper: React.FC<TextInputContentAfterWrapperProps> =
  ({ children, className }) => {
    return (
      <>
        {children && (
          <div
            className={composeClassName(
              afterTextInputStyle,
              className && className
            )}
          >
            {children}
          </div>
        )}
      </>
    );
  };

export { TextInputContentAfterWrapper };
