import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropDown } from '@/components/design-system/molecules/dropdown';
import { PaginatedColocApiConnectedDropDown } from '@/features/shared/feature-paginated-coloc-api-connected-dropdown';

import { usePairTransactionWithRentContext } from '@/features/admin/transactions/components/incoming-transactions-list/hooks/use-pair-transaction-with-rent';
import { House } from '@/types/admin';
import { useGetHousesInfinite } from '@/features/shared/hooks/use-get-houses-infinite';

const PairTransactionHouseSelectionDropDown = () => {
  const { selectedHouse, setSelectedHouse, housesQuery, setHousesQuery } =
    usePairTransactionWithRentContext()!;

  const { t } = useTranslation();

  return (
    <PaginatedColocApiConnectedDropDown<House>
      placeholder={
        t(
          'js.features.admin.transactions.transaction_pairing_modal.house_selection_placeholder'
        ) ?? ''
      }
      className="z-50 w-full"
      showLabel
      label={t(
        'js.features.admin.transactions.transaction_pairing_modal.house_selection_placeholder'
      )}
      value={selectedHouse}
      onChange={setSelectedHouse}
      {...(selectedHouse && {
        displayValue: selectedHouse.name,
      })}
      onTextInputChange={(event) => setHousesQuery(event.target.value)}
      dataSource={
        useGetHousesInfinite({
          searchQuery: housesQuery,
        })!
      }
      itemRenderer={(itemProps, ref) => {
        return (
          // @ts-ignore
          <DropDown.DropDownOptionDefaultContent ref={ref}>
            {itemProps.name}
          </DropDown.DropDownOptionDefaultContent>
        );
      }}
    />
  );
};

export { PairTransactionHouseSelectionDropDown };
