import { useMutation } from '@tanstack/react-query';

import { DeleteDiscussionThreadMessageParams } from '@/lib/coloc-api/admin';
import { deleteDiscussionThreadMessage } from '@/services/coloc-api/admin';
import { showToast } from '@/services/toast-service';
import { useDiscussionThreadProviderContext } from '../use-discussion-thread';

const useDeleteDiscussionThreadMessage = () => {
  const { deleteDiscussionThreadMessageInCache, showDiscussionThread } =
    useDiscussionThreadProviderContext()!;

  const mutate = useMutation({
    mutationKey: ['deleteDiscussionThreadMessage'],
    mutationFn: (params: DeleteDiscussionThreadMessageParams) => {
      return deleteDiscussionThreadMessage(params);
    },
    onSuccess: (data) => {
      if (data.config && data.config.url) {
        const deletedMessageIdString = data?.config?.url.match(/\/(\d+)$/);
        const deletedMessageIdNumber = deletedMessageIdString
          ? parseInt(deletedMessageIdString[1], 10)
          : undefined;

        if (deletedMessageIdNumber) {
          deleteDiscussionThreadMessageInCache(deletedMessageIdNumber);
        }
      }
      showDiscussionThread.onMessageSent?.();
    },
    onError: () => {
      showToast.error({});
    },
  });

  return {
    mutate,
  };
};

export { useDeleteDiscussionThreadMessage };
