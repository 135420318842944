import React from 'react';
import { PaginationItem } from './components/pagination-item';
import { PaginationProps } from './types';
import { PaginationContextProvider } from './hooks/use-pagination-context';
import PaginationPagesContainer from './components/pagination-pages-container';
import { composeClassName } from '@/utils/util_helpers';

const Pagination: React.FC<PaginationProps> = ({
  totalPages,
  currentPage,
  disabled = false,
  className,
  onPageChange,
}) => {
  return (
    <PaginationContextProvider
      currentPage={currentPage}
      totalPages={totalPages}
      disabled={disabled}
      onPageChange={onPageChange}
    >
      <div
        className={composeClassName(
          'flex h-5 flex-row gap-3',
          className && className
        )}
      >
        <PaginationItem
          isBackButton
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1 || disabled}
        />
        <PaginationPagesContainer />
        <PaginationItem
          isForwardButton
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages || disabled}
        />
      </div>
    </PaginationContextProvider>
  );
};

export { Pagination };
