import React from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContextProvider } from '@/hooks/use-auth';
import { ReactQueryProvider } from '@/providers/react-query-provider';
import { MarketPlaceHeader } from '@/features/marketplace/components/header';
import { PADDING_TOP_HEADER_HEIGHT } from '@/constants';
import { composeClassName } from '@/utils/util_helpers';
import { Footer } from '@/features/marketplace/components/footer';
import { SecondSection } from './containers/second-section';
import { ThirdSection } from './containers/third-section';
import { FourthSection } from './containers/fourth-section';
import { HeroSection } from './containers/hero-section';

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <ReactQueryProvider>
      <AuthContextProvider>
        <MarketPlaceHeader />
        <div
          className={composeClassName(
            'overflow-hidden',
            PADDING_TOP_HEADER_HEIGHT
          )}
        >
          <HeroSection
            title={t('js.features.marketplace.about_us.hero.title')}
            subTitle={t('js.features.marketplace.about_us.hero.subtitle')}
          />
          <SecondSection />
          <ThirdSection />
          <FourthSection />
        </div>
        <Footer />
      </AuthContextProvider>
    </ReactQueryProvider>
  );
};

export { AboutUs };
