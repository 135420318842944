import { useState, createContext, useContext, useCallback } from 'react';
import { PopUpPropsType } from './types';

const usePopUpConfirmationProvider = () => {
  const [popUpProps, setPopUpProps] = useState<PopUpPropsType | null>(null);
  const [isOpened, setIsOpened] = useState(false);

  const openPopUpConfirmation = useCallback((props: PopUpPropsType) => {
    setPopUpProps(props);
    setIsOpened(true);
  }, []);

  const closePopUpConfirmation = useCallback(() => {
    setPopUpProps(null);
    setIsOpened(false);
  }, []);

  return {
    isOpened,
    popUpProps,
    openPopUpConfirmation,
    closePopUpConfirmation,
  };
};

const PopUpConfirmationContext = createContext<
  ReturnType<typeof usePopUpConfirmation> | undefined
>(undefined);

const usePopUpConfirmation = () => {
  const context = useContext(PopUpConfirmationContext);
  if (!context) {
    throw new Error(
      'useNotifications must be used within a NotificationsProvider'
    );
  }
  return context;
}

export { usePopUpConfirmationProvider, PopUpConfirmationContext, usePopUpConfirmation };
