import React, { useMemo, useRef } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { IconSaxIcon } from '@/components/design-system/atoms/icon';
import { Table } from '@/components/design-system/molecules/table';
import {
  GetRentsListContextProvider,
  useGetRentsListContext,
} from './hooks/use-get-rents-list';
import { Rent } from '../../types';
import { ColocAPIConnectedTable } from '@/features/shared/feature-coloc-api-connected-table';
import { RentsListSortType } from '@/lib/coloc-api/admin';
import {
  ColocAPIConnectedTableImperativeMethods,
  ColocApiConnectedTableBulkActionDeclaration,
} from '@/features/shared/feature-coloc-api-connected-table/types';
import { useUpdateRents } from './hooks/use-update-rents';
import { useGetRentSelectionCSV } from './hooks/use-get-rents-csv';
import { RentTransactionsRow } from './components/rent-transactions-row';
import { AddRentTransactionModal } from './components/add-rent-transaction-modal';
import { MakeTransferModal } from './components/make-transfer-modal';
import { Modal } from '@/components/design-system/atoms/modal';
import { PopUp } from '@/components/design-system/molecules/pop-up';
import { DeleteRentTransactionModal } from './components/delete-rent-transaction-modal';
import { BASE_URL } from '@/config';
import { PopOver } from '@/components/design-system/molecules/pop-over';
import {
  RentPeriodCellContent,
  RentDateCellContent,
  RentDueCellContent,
  RentPaymentsStatusCellContent,
  RentTotalAmountCellContent,
} from './components/table-cells';
import { GetRentsListFiltersContextProvider } from './hooks/use-rents-list-filter';
import { RentsListFilter } from './components/rents-list-filter';
import { useDiscussionThreadProviderContext } from '@/features/shared/discussion-threads/hooks/use-discussion-thread';
import { DiscussionThreadTableCellContent } from '@/features/shared/discussion-threads/components/discussion-thread-table-cell-content';
import { getRent } from '@/services/coloc-api/admin';
import { RentReminderCellContent } from './components/table-cells/rent-reminder-cell-content';
import { usePopUpConfirmation } from '@/features/shared/pop-up-confirmation/hooks'
import { RentWidgets } from './components/widgets';
import { useAuthContext } from '@/hooks/use-auth';

const RentsListContent = () => {
  const columnHelper = createColumnHelper<Rent>();
  const { t } = useTranslation();
  const { isSuperAdmin } = useAuthContext();
  const tableRef = useRef<ColocAPIConnectedTableImperativeMethods>(null);
  const {
    mutate: mutateRentSelection,
    performingAction: performingRentMutationAction,
  } = useUpdateRents()!;
  const {
    showAddRentTransactionModal,
    toggleShowAddRentTransactionModal,
    showAddedRentTransactionConfirmationModal,
    toggleShowAddedRentTransactionConfirmationModal,
    toggleShowMarkRentsAsPaidConfirmationModal,
    showMarkRentsAsPaidConfirmationModal,
    navigateToPayoutsScreen,
    updateRentInCache,
    showMakeTransferModal,
    toggleShowMakeTransferModal
  } = useGetRentsListContext()!;
  const { openDiscussionThread } = useDiscussionThreadProviderContext()!;
  const { fetch: fetchRentsCSV, isExportingRents } = useGetRentSelectionCSV()!;
  const { openPopUpConfirmation, closePopUpConfirmation } =
    usePopUpConfirmation()!;

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.room, {
        id: 'house_name',
        cell: (info) => (
          <PopOver placement="bottom-start">
            <PopOver.PopOverTrigger>
              <Table.TableCellDefaultContent
                href={`${BASE_URL}/admin/contracts/${info.row.original.contract_id}`}
                description={`${info.getValue().house.name}`}
              >
                {`#${info.row.original.room.room_number} - ${info.row.original.tenant.name}`}
              </Table.TableCellDefaultContent>
            </PopOver.PopOverTrigger>
            <PopOver.PopOverContent className="!max-w-[400px]">
              <a
                className="text-body text-text-primary underline"
                href={`${BASE_URL}/admin/contracts/${info.row.original.contract_id}`}
              >
                {`#${info.row.original.room.room_number} - ${info.row.original.tenant.name}`}
              </a>
              <span className="text-body text-text-tertiary">
                {`${info.getValue().house.name}`}
              </span>
            </PopOver.PopOverContent>
          </PopOver>
        ),
        header: () =>
          t('js.features.admin.rents.rent_list.table_columns.houses'),

        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('discussion_thread', {
        id: 'discussion_thread',
        cell: (info) => (
          <DiscussionThreadTableCellContent
            messages_count={info.getValue().messages_count}
            onClick={() => {
              openDiscussionThread({
                type: 'Rent',
                ...(info.getValue().id && info.getValue().id !== 'null'
                  ? { discussionThreadId: Number(info.getValue().id) }
                  : { discussableId: Number(info.row.original.id) }),
                onMessageSent: () => {
                  getRent({ id: info.row.original.id }).then((response) => {
                    updateRentInCache(response.data);
                  });
                },
              });
            }}
          />
        ),
        header: () =>
          t(
            'js.features.admin.contracts.room_assignment.table_columns.discussion_thread'
          ),
        footer: (info) => info.column.id,
        enableSorting: false,
        enableColumnFilter: false,
      }),
      columnHelper.accessor('period', {
        id: 'period',
        cell: (info) => <RentPeriodCellContent period={info.getValue()} />,
        header: () =>
          t('js.features.admin.rents.rent_list.table_columns.period'),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('status', {
        id: 'status',
        header: () =>
          t('js.features.admin.rents.rent_list.table_columns.status'),
        cell: (info) => (
          <PopOver placement="bottom-start">
            <PopOver.PopOverTrigger>
              <RentPaymentsStatusCellContent
                status={info.getValue()}
                amountPaid={info.row.original.total_amount_paid}
                className="w-[160px]"
              />
            </PopOver.PopOverTrigger>
            <PopOver.PopOverContent className="!max-w-[400px]">
              <RentPaymentsStatusCellContent
                status={info.getValue()}
                amountPaid={info.row.original.total_amount_paid}
              />
            </PopOver.PopOverContent>
          </PopOver>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('unpaid_amount', {
        header: () => t('js.features.admin.rents.rent_list.table_columns.due'),
        cell: (info) => <RentDueCellContent unpaid_amount={info.getValue()} />,
        footer: (info) => info.column.id,
      }),
      columnHelper.display({
        id: 'full_amount',
        cell: (info) => (
          <RentTotalAmountCellContent
            paidAmount={info.row.original.total_amount_paid}
            unpaidAmount={info.row.original.unpaid_amount}
            discountAmount={info.row.original.discount_amount}
            chargesAmount={info.row.original.charges_amount}
            netAmount={info.row.original.net_amount}
          />
        ),
        header: () =>
          t('js.features.admin.rents.rent_list.table_columns.total_payment'),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('due_on', {
        id: 'due_on',
        header: () =>
          t('js.features.admin.rents.rent_list.table_columns.due_date'),
        cell: (info) => (
          <RentDateCellContent
            dueDate={info.getValue()}
            status={info.row.original.status}
          />
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('reminders_sent', {
        id: 'reminders_sent',
        header: () =>
          t('js.features.admin.rents.rent_list.table_columns.reminders_sent'),
        cell: (info) => <RentReminderCellContent value={info.getValue()} />,
        footer: (info) => info.column.id,
      }),
      columnHelper.display({
        id: 'actions',
        cell: ({ row }) => (
          <Table.TableCellRowActionsContent>
            <Table.TableCellRowActionsContent.TableRowActions>
              {isSuperAdmin && (
                <Table.TableCellRowActionsContent.TableRowAction
                  onClick={() => {
                    !row.getIsExpanded() && row.toggleExpanded();
                    toggleShowAddRentTransactionModal({
                      show: true,
                      rent: row.original,
                    });
                  }}
                >
                  <Table.TableCellRowActionsContent.TableRowActionContent
                    contentBefore={<IconSaxIcon name="add" size={20} />}
                  >
                    {t(
                      'js.features.admin.rents.rent_list.row_actions.add_transaction'
                    ) ?? ''}
                  </Table.TableCellRowActionsContent.TableRowActionContent>
                </Table.TableCellRowActionsContent.TableRowAction>
              )}

              {!['pending_payout', 'resolved'].includes(row.original.status) && (
                <Table.TableCellRowActionsContent.TableRowAction
                  onClick={() => {
                    !row.getIsExpanded() && row.toggleExpanded();
                    toggleShowMakeTransferModal({
                      show: true,
                      rent: row.original,
                    });
                  }}
                >
                  <Table.TableCellRowActionsContent.TableRowActionContent
                    contentBefore={<IconSaxIcon name="arrow-2" size={20} />}
                  >
                    {t(
                      'js.features.admin.rents.rent_list.row_actions.make_transfer'
                    ) ?? ''}
                  </Table.TableCellRowActionsContent.TableRowActionContent>
                </Table.TableCellRowActionsContent.TableRowAction>
              )}

              <Table.TableCellRowActionsContent.TableRowAction
                onClick={() => row.toggleExpanded()}
              >
                <Table.TableCellRowActionsContent.TableRowActionContent
                  contentBefore={<IconSaxIcon name="eye" size={20} />}
                >
                  {t(
                    'js.features.admin.rents.rent_list.row_actions.show_transactions'
                  ) ?? ''}
                </Table.TableCellRowActionsContent.TableRowActionContent>
              </Table.TableCellRowActionsContent.TableRowAction>
            </Table.TableCellRowActionsContent.TableRowActions>
          </Table.TableCellRowActionsContent>
        ),
        footer: (info) => info.column.id,
      }),
    ],
    [
      columnHelper,
      openDiscussionThread,
      t,
      toggleShowAddRentTransactionModal,
      updateRentInCache,
      toggleShowMakeTransferModal
    ]
  );

  const disabledActionButtons = useMemo(() => {
    return isExportingRents || mutateRentSelection.status === 'loading';
  }, [isExportingRents, mutateRentSelection.status]);

  const actions: Array<ColocApiConnectedTableBulkActionDeclaration> =
    useMemo(() => {
      return [
        {
          text: t(
            'js.features.admin.rents.rent_list.bulk_actions.resolved.button_title'
          ),
          iconName: 'card-tick-1',
          onClick: () =>
            openPopUpConfirmation({
              type: 'warning',
              title: t('js.features.admin.rents.paid_confirmation_modal.title'),
              description: t('js.features.admin.rents.paid_confirmation_modal.text'),
              confirmLabel: t('js.features.admin.rents.paid_confirmation_modal.confirm_button_label'),
              cancelLabel: t('js.features.admin.rents.paid_confirmation_modal.cancel_button_label'),
              onConfirm: () => {
                mutateRentSelection.mutate('resolved');
                closePopUpConfirmation();
              },
              onClose: () => closePopUpConfirmation(),

            }),
          disabled: disabledActionButtons,
          loading: performingRentMutationAction === 'resolved',
        },
        /*
        // Hidden bulk action as payments done to the landlord should
        {
          text: t(
            'js.features.admin.rents.rent_list.bulk_actions.resolved.button_title'
          ),
          iconName: 'tick-circle',
          onClick: () => toggleShowMarkRentsAsPaidConfirmationModal(true),
          disabled: disabledActionButtons,
          loading: performingRentMutationAction === 'resolved',
        },
        */
        {
          text: t(
            'js.features.admin.rents.rent_list.bulk_actions.payout.button_title'
          ),
          iconName: 'money-send',
          onClick: () => navigateToPayoutsScreen(),
          disabled: disabledActionButtons,
        },
        {
          text: t(
            'js.features.admin.rents.rent_list.bulk_actions.first_reminder.button_title'
          ),
          iconName: 'send-2',
          onClick: () => mutateRentSelection.mutate('first_reminder'),
          disabled: disabledActionButtons,
          loading: performingRentMutationAction === 'first_reminder',
        },
        {
          text: t(
            'js.features.admin.rents.rent_list.bulk_actions.second_reminder.button_title'
          ),
          iconName: 'send-2',
          onClick: () => mutateRentSelection.mutate('second_reminder'),
          disabled: disabledActionButtons,
          loading: performingRentMutationAction === 'second_reminder',
        },
        {
          text: t(
            'js.features.admin.rents.rent_list.bulk_actions.regenerate.button_title'
          ),
          iconName: 'refresh',
          onClick: () => mutateRentSelection.mutate('regenerate'),
          disabled: disabledActionButtons,
          loading: performingRentMutationAction === 'regenerate',
        },
        {
          text: t(
            'js.features.admin.rents.rent_list.bulk_actions.csv.button_title'
          ),
          iconName: 'document-text',
          onClick: () => fetchRentsCSV(),
          disabled: disabledActionButtons,
          loading: isExportingRents,
        },
        {
          text: t(
            'js.features.admin.rents.rent_list.bulk_actions.delete.button_title'
          ),
          iconName: 'trash',
          onClick: () => mutateRentSelection.mutate('delete'),
          disabled: mutateRentSelection.status === 'loading',
          loading: performingRentMutationAction === 'delete',
        },
      ];
    }, [
      disabledActionButtons,
      fetchRentsCSV,
      isExportingRents,
      mutateRentSelection,
      navigateToPayoutsScreen,
      performingRentMutationAction,
      t,
      closePopUpConfirmation,
      openPopUpConfirmation,
    ]);

  return (
    <>
      <ColocAPIConnectedTable<Rent, RentsListSortType>
        ref={tableRef}
        columns={columns}
        actions={actions}
        dataSource={useGetRentsListContext()!}
        enableMultiRowSelection
        enableRowSelection
        renderSubComponent={(row) => {
          return (
            <RentTransactionsRow
              rent={row.row.original}
              index={row.row.index}
              onClose={() => row.row.toggleExpanded()}
            />
          );
        }}
      />

      {showAddRentTransactionModal.show && showAddRentTransactionModal.rent && (
        <AddRentTransactionModal
          open={showAddRentTransactionModal.show}
          onClose={() =>
            toggleShowAddRentTransactionModal({ show: false, rent: undefined })
          }
          rent={showAddRentTransactionModal.rent}
        />
      )}

      {showMakeTransferModal.show && showMakeTransferModal.rent && (
        <MakeTransferModal
          open={showMakeTransferModal.show}
          onClose={() =>
            toggleShowMakeTransferModal({ show: false, rent: undefined })
          }
          rent={showMakeTransferModal.rent}
        />
      )}

      <DeleteRentTransactionModal />

      {showAddedRentTransactionConfirmationModal && (
        <Modal
          open={showAddedRentTransactionConfirmationModal}
          onClose={() => toggleShowAddedRentTransactionConfirmationModal(false)}
        >
          <PopUp
            type="feedback"
            title={t(
              'js.features.admin.rents.rent_list.transaction_added_pop_up.title'
            )}
            primaryButtonProps={{
              onClick: () =>
                toggleShowAddedRentTransactionConfirmationModal(false),
              title:
                t(
                  'js.features.admin.rents.rent_list.transaction_added_pop_up.button_label'
                ) ?? '',
            }}
          />
        </Modal>
      )}

      {showMarkRentsAsPaidConfirmationModal && (
        <Modal
          open={showMarkRentsAsPaidConfirmationModal}
          onClose={() => toggleShowMarkRentsAsPaidConfirmationModal(false)}
        >
          <PopUp
            type="default"
            title={t('js.features.admin.rents.paid_confirmation_modal.title')}
            description={
              t('js.features.admin.rents.paid_confirmation_modal.text') ?? ''
            }
            primaryButtonProps={{
              disabled: disabledActionButtons,
              onClick: () => {
                mutateRentSelection.mutate('resolved');
                toggleShowMarkRentsAsPaidConfirmationModal(false);
              },
              title:
                t(
                  'js.features.admin.rents.paid_confirmation_modal.confirm_button_label'
                ) ?? '',
            }}
            secondaryButtonProps={{
              onClick: () => toggleShowMarkRentsAsPaidConfirmationModal(false),
              title:
                t(
                  'js.features.admin.rents.paid_confirmation_modal.cancel_button_label'
                ) ?? '',
            }}
          />
        </Modal>
      )}
    </>
  );
};

const RentsList = () => {
  return (
    <GetRentsListFiltersContextProvider>
      <GetRentsListContextProvider>
        <div className="flex h-full w-full flex-1 flex-col">
          <RentWidgets />
          <RentsListFilter />
          <RentsListContent />
        </div>
      </GetRentsListContextProvider>
    </GetRentsListFiltersContextProvider>
  );
};

export { RentsList };
