import React from 'react';
import { ReactSVG } from 'react-svg';
import { composeClassName } from '@/utils/util_helpers';
import { useNavigationMenuContext } from '../../hooks/use-navigation-menu-context';

const NavigationMenuHomeLink = () => {
  const { displayValue } = useNavigationMenuContext()!;

  return (
    <div className="flex h-[152px] items-end justify-center transition-all duration-500">
      <a href="./">
        {displayValue === 'expanded' ? (
          <ReactSVG
            src={require(`@assets/icons/other/coloc-icon-full.svg`)}
            className={composeClassName('inline-block align-baseline')}
            beforeInjection={(svg) => {
              svg.setAttribute('width', `144`);
              svg.setAttribute('height', `96`);
            }}
          />
        ) : (
          <ReactSVG
            src={require(`@assets/icons/other/coloc-icon.svg`)}
            className={composeClassName('inline-block align-baseline')}
            beforeInjection={(svg) => {
              svg.setAttribute('width', `80`);
              svg.setAttribute('height', `40`);
            }}
          />
        )}
      </a>
    </div>
  );
};

export { NavigationMenuHomeLink };
