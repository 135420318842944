import React from 'react';
import { composeClassName } from '../../../../../../utils/util_helpers';
import { TextInputContentBeforeWrapperProps } from './types';

const TextInputContentBeforeWrapper: React.FC<TextInputContentBeforeWrapperProps> =
  ({ children, disabled = false, className }) => {
    return (
      <>
        {children && (
          <div
            className={composeClassName(
              'absolute left-[0px] top-[0px] flex h-full items-center px-3',
              disabled && 'grayscale-[80%] [&_svg>path]:stroke-E75',
              className && className
            )}
          >
            {children}
          </div>
        )}
      </>
    );
  };

export { TextInputContentBeforeWrapper };
