import React from 'react';
import { Button } from '@/components/design-system/atoms/button';
import { TableCellAccordionContentProps } from './types';

const TableCellAccordionContent: React.FC<TableCellAccordionContentProps> = ({
  open,
  onClick,
}) => {
  return (
    <div className="flex items-center justify-center">
      <Button
        customIconSize={18}
        variant="icon"
        iconName={open ? 'arrow-up-4' : 'arrow-down-4'}
        onClick={() => onClick()}
      />
    </div>
  );
};

export { TableCellAccordionContent };
