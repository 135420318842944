import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { RentPeriodCellContentProps } from './types';
import { Table } from '@/components/design-system/molecules/table';

const RentPeriodCellContent: React.FC<RentPeriodCellContentProps> = ({
  period,
}) => {
  const monthName = useMemo(() => {
    return format(new Date(period.start_date), 'MMM yy');
  }, [period.start_date]);

  const dateRangeText = useMemo(() => {
    return `${format(new Date(period.start_date), 'do')} - ${format(
      new Date(period.end_date),
      'do'
    )}${period.is_pro_rata ? ` (${period.pro_rata_percent}%)` : ''}`;
  }, [period]);

  return (
    <Table.TableCellDefaultContent description={dateRangeText}>
      {monthName}
    </Table.TableCellDefaultContent>
  );
};

export { RentPeriodCellContent };
