/* eslint-disable no-underscore-dangle */
/* eslint-disable new-cap */
/* eslint no-unused-vars: 0 */
/* eslint-disable */
import I18n from 'i18n-js';

import AbstractModalsController from 'src/controllers/abstract_modals_controller';
import flash from 'src/services/legacy/flash.service';
import Hint from 'src/components/hint';
import flatpickr from 'flatpickr';
import monthSelect from 'flatpickr/dist/plugins/monthSelect';

export default class extends AbstractModalsController {
  static targets = [
    'deleteLink',
    'measurementInput',
    'percentAmountInput',
    'absoluteAmountInput',
    'periodInput',
    'noteInput',
    'formError',
    'netRent',
    'modal',
    'discountStartDate',
    'discountEndDate',
    'recurringPeriod',
  ];

  connect() {
    this.displayAbsolute();
    this.toggleDeleteDiscount();
    this.fillInAmountIfExists();
    this.fillInNote();

    this.recurringPeriodTarget.style.display =
      this.period === 'recurring' ? 'flex' : 'none';

    this.discountStartDatePicker = flatpickr(this.discountStartDateTarget, {
      altInput: true,
      plugins: [
        new monthSelect({
          shorthand: false,
          dateFormat: 'Y-m-d',
          altFormat: 'F Y',
        }),
      ],
    });

    this.discountEndDatePicker = flatpickr(this.discountEndDateTarget, {
      altInput: true,
      plugins: [
        new monthSelect({
          shorthand: false,
          dateFormat: 'Y-m-d',
          altFormat: 'F Y',
        }),
      ],
    });
  }

  disconnect() {
    this.discountStartDatePicker.destroy();
    this.discountEndDatePicker.destroy();
  }

  onTogglePeriod(_e) {
    this.fillInAmountIfExists();
    this.fillInNote();
    this.toggleDeleteDiscount();

    this.recurringPeriodTarget.style.display =
      this.period === 'recurring' ? 'flex' : 'none';
  }

  onToggleMeasurement(_e) {
    this.fillInAmountIfExists();
  }

  fillInAmountIfExists() {
    if (this.period === 'recurring') {
      return this.fillInAmount(this.recurringDiscount);
    }
    return this.isThisMonthPeriod
      ? this.fillInAmount(this.thisMonthDiscount)
      : this.fillInAmount(this.nextMonthDiscount);
  }

  fillInAmount(amount) {
    if (this.isAbsoluteMeasurement) {
      this.absoluteAmountInputTarget.value = amount;
    } else {
      const asPercentage = ((amount / this.netRent) * 100).toFixed(2);
      this.percentAmountInputTarget.value = asPercentage;
      this.displayAbsolute();
    }
  }

  fillInNote() {
    if (this.period === 'recurring') {
      this.noteInputTarget.value = this.data.get('recurringDiscountNote');
    } else if (this.isThisMonthPeriod) {
      this.noteInputTarget.value = this.data.get('thisMonthDiscountNote');
    } else {
      this.noteInputTarget.value = this.data.get('nextMonthDiscountNote');
    }
  }

  toggleDeleteDiscount() {
    const url = new URL(this.deleteLinkTarget.href);
    url.searchParams.set('period', this.period);
    this.deleteLinkTarget.href = url.href;

    if (this.period === 'recurring') {
      this.toggleDeleteDiscountButton(this.hasRecurringDiscount);
    } else {
      this.toggleDeleteDiscountButton(
        this.period === 'this_month'
          ? this.hasThisMonthDiscount
          : this.hasNextMonthDiscount
      );
    }
  }

  toggleDeleteDiscountButton(hasDiscount) {
    const display = JSON.parse(hasDiscount || 'false') ? 'block' : 'none';
    this.deleteLinkTarget.style.display = display;
  }

  displayAbsolute() {
    if (this.hint) {
      this.hint.remove();
    }

    if (this.isPercentMeasurement && this.percentAmountNotZero) {
      this.hint = new Hint(
        this.percentAmountInputTarget.parentElement,
        this.hintMessage
      );
    }
  }

  success(event) {
    this.formErrorTarget.innerHTML = '&nbsp;';
    const [data, _status, _xhr] = event.detail;
    const {
      error_message: errorMessage,
      success_message: successMessage,
      html,
      success,
    } = data;

    if (success) {
      flash('notice', successMessage);

      this.close();
      this.element.parentElement.insertAdjacentHTML('beforeend', html);
    } else {
      this.formErrorTarget.innerHTML = errorMessage;
    }
  }

  get thisMonthDiscount() {
    return JSON.parse(this.data.get('thisMonthDiscount')).toFixed(2);
  }

  get nextMonthDiscount() {
    return JSON.parse(this.data.get('nextMonthDiscount')).toFixed(2);
  }

  get recurringDiscount() {
    return JSON.parse(this.data.get('recurringDiscount')).toFixed(2);
  }

  get hasThisMonthDiscount() {
    return this.thisMonthDiscount > 0;
  }

  get hasNextMonthDiscount() {
    return this.nextMonthDiscount > 0;
  }

  get hasRecurringDiscount() {
    return this.recurringDiscount > 0;
  }

  get hintMessage() {
    return `Equivalent to €${this.absoluteAmount}`;
  }

  get percentAmountNotZero() {
    return this.percentAmount > 0;
  }

  get isPercentMeasurement() {
    return this.measurement === 'percent';
  }

  get isAbsoluteMeasurement() {
    return this.measurement === 'absolute';
  }

  get isThisMonthPeriod() {
    return this.period === 'this_month';
  }

  get period() {
    return this.periodInputTarget.value;
  }

  get measurement() {
    return this.measurementInputTarget.value;
  }

  get percentAmount() {
    return parseFloat(this.percentAmountInputTarget.value) || 0;
  }

  get absoluteAmount() {
    return Math.ceil((this.percentAmount * this.netRent) / 100);
  }

  get netRent() {
    if (this.data.has('netRent')) {
      return this.data.get('netRent');
    }

    return null;
  }
}
