import React from 'react';
import { Menu, Transition } from '@headlessui/react';
import { isElement } from 'react-dom/test-utils';
import { composeClassName } from '@/utils/util_helpers';
import { TableCellRowActionsProps } from './types';

const TableCellRowActions: React.FC<TableCellRowActionsProps> = ({
  children,
  className,
}) => {
  return (
    <Transition
      as={React.Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items
        className={composeClassName(
          'absolute right-[0px] top-5 z-10 rounded bg-white shadow-medium',
          className && className
        )}
      >
        {isElement(children) ? (
          ({ open }) => {
            return (
              <div className="flex flex-col gap-2 p-3">
                {
                  React.Children.map(
                    children as unknown as React.ReactElement,
                    (child: React.ReactElement) => {
                      return {
                        ...child,
                        props: {
                          ...child.props,
                          open,
                        },
                      };
                    }
                  ) as unknown as React.ReactElement
                }
              </div>
            );
          }
        ) : (
          // @ts-expect-error
          <div className="flex flex-col gap-2 p-3">{children}</div>
        )}
      </Menu.Items>
    </Transition>
  );
};

export { TableCellRowActions };
