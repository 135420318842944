<template>
  <inline-svg
    :src="
      require(`../../../../../../../assets/icons/country-icons/${name}.svg`)
    "
    :width="size"
    :height="size"
    class="icon-country"
  />
</template>

<script>
import './CountryIcon.scss';
import InlineSvg from 'vue-inline-svg';
import CountryIconIcons from './countryIconValidator';

export default {
  components: {
    InlineSvg,
  },
  props: {
    /**
     * The size of the icon. Defaults to 24.
     */
    size: {
      type: Number,
      default: 24,
    },
    /**
     * The name of the icon.
     */
    name: {
      type: String,
      required: true,
      validator(value) {
        return Object.prototype.hasOwnProperty.call(CountryIconIcons, value);
      },
    },
  },
};
</script>
