<template>
  <label class="ui-form-checkbox">
    <span class="whitespace-nowrap">{{ label }}</span>
    <input
      type="checkbox"
      :checked="value"
      :disabled="disabled"
      @input="$emit('input', $event.target.checked)"
    />
    <div class="b-input"></div>
  </label>
</template>

<script>
import './Checkbox.scss';

export default {
  props: {
    label: String,
    value: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
