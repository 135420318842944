import React from 'react';
import { composeClassName } from '@/utils/util_helpers';
import { BadgeProps } from './types';
import { sizeStyles, variantStyles } from './styles';

const Badge: React.FC<BadgeProps> = ({
  label,
  size = 'medium',
  variant = 'normal',
  leadingIcon,
  trailingIcon,
  outline = true,
  className,
  ...props
}) => {
  return (
    <div
      className={composeClassName(
        'flex items-center justify-center rounded-full border-solid p-[0.4rem]',
        outline && 'border',
        className && className,
        variantStyles[variant] && variantStyles[variant],
        sizeStyles[size] && sizeStyles[size]
      )}
      {...props}
    >
      {React.isValidElement(leadingIcon) && leadingIcon}
      {label && <span>{label}</span>}
      {React.isValidElement(trailingIcon) && trailingIcon}
    </div>
  );
};

export { Badge };
