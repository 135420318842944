import React, { useMemo } from 'react';
import {
  differenceInCalendarDays,
  isBefore,
  isToday,
  isTomorrow,
  isYesterday,
} from 'date-fns';
import { useTranslation } from 'react-i18next';
import { getFullDate } from '@/utils/util_helpers/formatting/dates';
import { RentDateCellProps } from './types';
import { composeClassName } from '@/utils/util_helpers';
import { Table } from '@/components/design-system/molecules/table';

type DueDateStatus = 'today' | 'before' | 'after';

const RentDateCellContent: React.FC<RentDateCellProps> = ({
  dueDate,
  status,
}) => {
  const { t } = useTranslation();

  const showDaysUntilDueDate: boolean = useMemo(() => {
    return status !== 'paid' && status !== 'resolved';
  }, [status]);

  const dayState: DueDateStatus = useMemo(() => {
    if (isToday(new Date(dueDate))) {
      return 'today';
    }

    if (isBefore(new Date(), new Date(dueDate))) {
      return 'before';
    }

    return 'after';
  }, [dueDate]);

  const dateDifferenceText: string = useMemo(() => {
    if (dayState === 'today') {
      return t('js.features.admin.rents.rent_date_cell.today');
    }

    if (dayState === 'before') {
      if (isTomorrow(new Date(dueDate))) {
        return t('js.features.admin.rents.rent_date_cell.tomorrow');
      }

      return t('js.features.admin.rents.rent_date_cell.before', {
        num_days: differenceInCalendarDays(new Date(dueDate), new Date()),
      });
    }

    if (isYesterday(new Date(dueDate))) {
      return t('js.features.admin.rents.rent_date_cell.yesterday');
    }

    return t('js.features.admin.rents.rent_date_cell.after', {
      num_days: differenceInCalendarDays(new Date(), new Date(dueDate)),
    });
  }, [dayState, dueDate, t]);

  return (
    <Table.TableCellDefaultContent
      {...(showDaysUntilDueDate && { description: dateDifferenceText })}
      descriptionClassName={composeClassName(
        dayState === 'after' && '!text-text-failed'
      )}
    >
      {getFullDate(new Date(dueDate))}
    </Table.TableCellDefaultContent>
  );
};

export { RentDateCellContent };
