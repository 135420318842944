import React, { useEffect, useRef } from 'react';

import { SlideOverContentProps } from './types';
import { composeClassName } from '@/utils/util_helpers';

const SlideOverContent: React.FC<SlideOverContentProps> = ({
  className,
  children,
  scrollToEnd = false,
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!scrollToEnd) return;
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [children, scrollToEnd]);

  return (
    <div
      ref={containerRef}
      className={composeClassName(
        'flex w-full flex-1 flex-col overflow-y-scroll p-5 pb-10',
        className && className
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export { SlideOverContent };
