import Rails from '@rails/ujs';
import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.localeElement = this.element.querySelector(
      'input[name="document_locale"]'
    );
  }

  downloadWithEn() {
    this._downloadWithLocale('en');
  }

  downloadWithFr() {
    this._downloadWithLocale('fr');
  }

  downloadWithNl() {
    this._downloadWithLocale('nl');
  }

  _downloadWithLocale(locale) {
    this.localeElement.value = locale;
    this.element.submit();
  }
}
