<style scoped>
label {
  display: flex;
  height: 16px;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
label svg {
  width: 16px;
  height: 16px;
}
</style>

<template>
  <div>
    <div
      class="d-flex align-items-center searchContainer"
      style="padding: 4px 16px"
    >
      <label for="searchInput" aria-label="Search for a place to live in">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21 21L15.8033 15.8033M15.8033 15.8033C17.1605 14.4461 18 12.5711 18 10.5C18 6.35786 14.6421 3 10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18C12.5711 18 14.4461 17.1605 15.8033 15.8033Z" stroke="#495057" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </label>
      <img
        v-if="image"
        :src="image"
        alt=""
        width="26"
        height="26"
        class="rounded"
        style="margin: 1.6px 2px 1.6px 12px"
      />
      <autocomplete
        :items="items"
        :get-label="getLabel"
        :component-item="template"
        :min-len="1"
        :wait="300"
        :value="initialQuery"
        :auto-select-one-item="false"
        :input-attrs="{
          id: 'searchInput',
          class: 'form-control-sm border-0 shadow-none',
          placeholder: t('vue_components.search_bar.placeholder'),
          autocomplete: 'off',
        }"
        @update-items="updateItems"
        @change="change"
        @focus="focus"
        @blur="blur"
        @item-selected="itemSelected"
      ></autocomplete>
    </div>

    <div class="v-autocomplete" v-if="!loadingItems && focused && query == ''">
      <div class="v-autocomplete-list">
        <div class="v-autocomplete-list-item">
          {{ t('vue_components.search_bar.hint') }}
        </div>
      </div>
    </div>

    <div
      class="v-autocomplete"
      v-if="!loadingItems && focused && query != '' && items.length == 0"
    >
      <div class="v-autocomplete-list">
        <div class="v-autocomplete-list-item">
          {{ t('vue_components.search_bar.no_results') }}
        </div>
      </div>
    </div>

    <div class="v-autocomplete" v-if="loadingItems && query != ''">
      <div class="v-autocomplete-list">
        <div class="v-autocomplete-list-item">
          <div class="spinner-grow spinner-grow-sm" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
    <div id="widthSearchInput"></div>
  </div>
</template>

<script>
import Vue from 'vue';
import Autocomplete from 'v-autocomplete';
import SearchBarItem from './SearchBarItem.vue';
import flash from 'src/services/legacy/flash.service';
import i18n from '../vue_mixins/i18n';

export default {
  components: {
    Autocomplete,
  },
  mixins: [i18n],
  props: ['initialQuery', 'initialImage'],
  data() {
    return {
      loadingItems: false,
      query: '',
      focused: false,
      items: [],
      template: SearchBarItem,
      image: null,
    };
  },
  mounted() {
    this.image = this.initialImage;
    this.resizeInput();
  },
  methods: {
    getLabel(item) {
      if (item && item.name) {
        return item.name;
      }

      return item;
    },
    updateItems(text) {
      const abortController = new AbortController();
      const abortSignal = abortController.signal;

      this.fetchPromise = fetch('/autocomplete?q=' + text.trim(), {
        signal: abortSignal,
      })
        .then((response) => {
          if (response.status !== 200) {
            throw response.statusText;
          }

          return response.json();
        })
        .then((data) => {
          this.items = data;
          this.loadingItems = false;
        })
        .catch((error) => {
          if (error.code === DOMException.ABORT_ERR) {
            return;
          }

          flash('alert', error);
        });

      this.fetchPromise.abortController = abortController;
    },
    change(text) {
      this.resizeInput();

      this.items = [];
      this.query = text.trim();

      if (this.fetchPromise) {
        this.fetchPromise.abortController.abort();
      }

      if (this.query == '') {
        this.loadingItems = false;
      } else {
        this.loadingItems = true;
      }

      this.image = null;
    },
    focus() {
      this.focused = true;
    },
    blur() {
      this.focused = false;
    },
    itemSelected(item) {
      this.image = item.image;

      Vue.nextTick(function () {
        if (item.path) {
          Turbolinks.visit(item.path);
        }
      });
    },
    resizeInput() {
      const elemDiv = document.getElementById('widthSearchInput');
      const elemInput = document.getElementById('searchInput');

      elemDiv.innerText = elemInput.value;
      elemInput.style.width = elemDiv.clientWidth + 'px';
    },
  },
};
</script>
