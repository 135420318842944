import { useQuery } from '@tanstack/react-query';
import { GetRoomAssignmentParams } from '@/lib/coloc-api/admin';
import { getRoomAssignment } from '@/services/coloc-api/admin';

const useGetRoomAssignment = () => {
  const fetch = useQuery({
    queryKey: ['getRoomAssignment'],
    queryFn: (params: GetRoomAssignmentParams) => getRoomAssignment(params),
    keepPreviousData: true,
  });

  return {
    fetch,
  };
};

export { useGetRoomAssignment };
