<template>
  <div>
    <div id="scroll-up-container" class="scroll-to-top__wrapper" />
    <Fab
      v-if="showScrollTop"
      v-bind="$props"
      icon-name="arrow-up-1"
      @click="handleClick"
    />
  </div>
</template>

<script>
import Fab from '../../atoms/fab/Fab.vue';
import './ScrollUpButton.scss';

const options = {
  rootMargin: '0px',
  threshold: 1.0,
};

let observer;

const elementId = '#scroll-up-container';

export default {
  components: { Fab },
  props: {
    /**
     * Button disabled or not
     *
     * Hover and pressed state are handled internaly
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * At what scroll Y position to show. Defaults to 100
     */
    showAtYPosition: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      showScrollTop: true,
      screenHeight: 0,
      black: 'black',
    };
  },
  mounted() {
    observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio >= 1) {
          this.showScrollTop = false;
        } else {
          this.showScrollTop = true;
        }
      });
    }, options);

    observer.observe(document.querySelector(elementId));
  },
  beforeDestroy() {
    observer.unobserve(document.querySelector(elementId));
  },
  methods: {
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    handleClick(e) {
      this.scrollTop();
      this.$emit('click', e);
    },
  },
};
</script>
