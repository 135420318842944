import React from 'react';
import { ViewToggleResultsIndicatorProps } from './types';

const ViewToggleResultsIndicator: React.FC<ViewToggleResultsIndicatorProps> = ({
  totalItems,
  fromItem,
  untilItem,
}) => {
  return (
    <div className="flex h-full items-center text-body text-text-secondary">
      <span>{`Showing ${fromItem} to ${untilItem} of ${totalItems}`}</span>
    </div>
  );
};

export { ViewToggleResultsIndicator };
