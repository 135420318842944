import React, { useMemo } from 'react';
import { EmptyState } from '@/components/design-system/atoms/empty-state';
import { composeClassName } from '../../../../../../utils/util_helpers';
import { CELL_HEIGHT } from '../../types';
import { TableEmptyStateDefaultContentProps } from './types';
import { Shimmer } from '@/components/design-system/atoms/shimmer';

const TableEmptyStateDefaultContent: React.FC<TableEmptyStateDefaultContentProps> =
  ({ numColumns, className, numRows, type, ...props }) => {
    const heightInPx = useMemo(() => {
      return numRows * CELL_HEIGHT;
    }, [numRows]);

    if (type !== 'Loading') {
      return (
        <tr
          className={composeClassName(
            'w-full',
            `h-[${heightInPx}px]`,
            className && className
          )}
        >
          <td colSpan={numColumns} rowSpan={0} className="h-full w-full">
            <div
              className={composeClassName(
                'my-10 flex items-center justify-center'
              )}
            >
              <EmptyState {...props} size={144} type={type} />
            </div>
          </td>
        </tr>
      );
    }

    return (
      <>
        {[...Array.from(Array(numRows).keys())].map((num, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <tr className="h-[58px] w-full bg-white" key={i}>
            {i % 2 !== 0 && (
              <td colSpan={numColumns} rowSpan={1} className="h-full w-full">
                <Shimmer className="h-[58px] w-full" />
              </td>
            )}
          </tr>
        ))}
      </>
    );
  };

export { TableEmptyStateDefaultContent };
