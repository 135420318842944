/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
import { ReactSVG } from 'react-svg';
import React, { Children, isValidElement, useMemo } from 'react';
import {
  HEADER_HEIGHT,
  MOBILE_BREAK_POINT,
  TABLET_BREAK_POINT,
} from '@/constants';
import { useWindowDimensions } from '@/hooks/use-window-dimensions';
import { composeClassName } from '@/utils/util_helpers';
import { DEFAULT_CONTENT_WIDTH } from '@/features/marketplace/constants';
import { HeaderLinkProps } from './components/header-link/types';
import { HeaderLink } from './components/header-link';
import { MobileSideMenu } from './components/mobile-side-menu';
import { HeaderProps, Slots } from './types';
import { MobileSideMenuPropsAndSubComponents } from './components/mobile-side-menu/types';

const Leading: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const {
    windowDimensions: { width },
  } = useWindowDimensions();

  const headerIconSize: { width: number; height: number } = useMemo(() => {
    if (width < MOBILE_BREAK_POINT) {
      return { width: 67, height: 34 };
    }

    if (width < TABLET_BREAK_POINT) {
      return { width: 87, height: 44 };
    }

    return { width: 99, height: 50 };
  }, [width]);

  return (
    <div
      className={composeClassName('flex h-full items-center gap-5 lg:gap-7')}
    >
      <a href="/">
        <ReactSVG
          src={require(`@assets/icons/other/coloc-icon.svg`)}
          className={composeClassName('inline-block align-baseline')}
          beforeInjection={(svg) => {
            svg.setAttribute('width', `${headerIconSize.width}`);
            svg.setAttribute('height', `${headerIconSize.height}`);
          }}
        />
      </a>
      {children}
    </div>
  );
};

const Trailing: React.FC<{ children: React.ReactNode; className?: string }> = ({
  children,
  className,
}) => {
  return (
    <div
      className={composeClassName(
        'flex h-full items-center gap-5 md:gap-7',
        className && className
      )}
    >
      {children}
    </div>
  );
};

const Header: React.FC<HeaderProps> & {
  HeaderLink: React.FC<HeaderLinkProps>;
  Leading: React.FC<{ children: React.ReactNode }>;
  Trailing: React.FC<{ children: React.ReactNode; className?: string }>;
  MobileSideMenu: MobileSideMenuPropsAndSubComponents;
} = ({ children }) => {
  const filledSlots = useMemo(() => {
    const slots: Slots = {
      leading: null,
      trailing: null,
    };

    Children.toArray(children).forEach((child) => {
      if (!isValidElement(child)) {
        return;
      }

      switch (child.type) {
        case Leading:
          slots.leading = child;
          break;
        case Trailing:
          slots.trailing = child;
          break;
        default:
      }
    });

    return slots;
  }, [children]);

  return (
    <header
      className={composeClassName(
        'inset-x-0 top-0 fixed z-40 flex w-full items-center justify-between bg-white shadow-[-16px_5px_30px_0px_rgba(18,2,47,0.04)]',
        HEADER_HEIGHT
      )}
    >
      <div
        className={composeClassName(
          DEFAULT_CONTENT_WIDTH,
          'm-auto flex items-center justify-between'
        )}
      >
        {filledSlots.leading}
        {filledSlots.trailing}
      </div>
    </header>
  );
};

Header.HeaderLink = HeaderLink;
Header.Leading = Leading;
Header.Trailing = Trailing;
Header.MobileSideMenu = MobileSideMenu;

export { Header };
