import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import {
  GetPayoutPaymentParams,
  GetPayoutPaymentResponse,
} from '@/lib/coloc-api/admin';
import { getPayoutPayment } from '@/services/coloc-api/admin';

const useGetPayoutPayment = (params: {
  params?: GetPayoutPaymentParams;
  onSuccessCallback?: (
    data: AxiosResponse<GetPayoutPaymentResponse, any>
  ) => void;
  onErrorCallback?: (err: AxiosResponse<GetPayoutPaymentResponse, any>) => void;
}) => {
  const fetch = useQuery({
    enabled: !!params?.params?.id,
    queryKey: ['getPayoutPayment', params],
    queryFn: () => getPayoutPayment(params.params!),
    keepPreviousData: true,
    ...(params.onSuccessCallback && { onSuccess: params.onSuccessCallback }),
    ...(params.onErrorCallback && { onError: params.onErrorCallback }),
  });

  return {
    fetch,
  };
};

export { useGetPayoutPayment };
