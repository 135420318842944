import React from 'react';

import { composeClassName } from '../../../../../../../../../utils/util_helpers';
import { OutgoingTransactionAmountCellContentProps } from './types';
import { amountToString } from '@/utils/util_helpers/formatting/amount_to_string';
import { Button } from '@/components/design-system/atoms/button';

const OutgoingTransactionAmountCellContent: React.FC<OutgoingTransactionAmountCellContentProps> =
  ({ amount, openDetailsModal }) => {
    return (
      <div className={composeClassName('flex items-center gap-3')}>
        <span className={composeClassName('text-body text-text-primary')}>
          {amountToString(amount)}
        </span>
        <Button
          iconName="eye"
          variant="ghost"
          onClick={() => openDetailsModal()}
        />
      </div>
    );
  };

export { OutgoingTransactionAmountCellContent };
