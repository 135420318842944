import React from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_CONTENT_WIDTH } from '@/features/marketplace/constants';
import { composeClassName } from '@/utils/util_helpers';

type Employee = { name: string; function: string; picture: string };

const employees: ReadonlyArray<Employee> = [
  {
    name: 'Christophe Vercarre',
    function: 'CEO & Co-founder',
    picture: 'christophe.png',
  },
  {
    name: 'Karel Versyp',
    function: 'CFO & Co-founder',
    picture: 'karel.png',
  },
  {
    name: 'Viktor Van Esbroeck',
    function: 'Head of Operations',
    picture: 'viktor.png',
  },
  {
    name: `Filip D'Hooghe`,
    function: 'Renovation Site Manager',
    picture: 'filip.png',
  },
  {
    name: `Eveline De Smet`,
    function: 'Office Manager',
    picture: 'eveline.png',
  },
  {
    name: 'Humayun Khalid',
    function: 'Property Manager',
    picture: 'humayun.png',
  },
  {
    name: 'Michelle Francis',
    function: 'Interior architect',
    picture: 'michelle.png',
  },
  {
    name: 'Kate Simborskaya',
    function: 'Interior architect',
    picture: 'kate.png',
  },
  {
    name: 'Sander Scheldeman',
    function: 'Investment associate',
    picture: 'sander.png',
  },
  {
    name: 'Charles Crolla',
    function: 'Community Facilitator Intern',
    picture: 'charles.png',
  },
] as const;

const EmployeeSummary: React.FC<{ employee: Employee }> = ({ employee }) => {
  return (
    <div
      className={composeClassName(
        'mx-auto flex w-full max-w-[26.7rem] flex-col items-center py-4 text-center'
      )}
    >
      <div className="relative flex h-[164px] w-[164px] items-center justify-center overflow-hidden rounded-full">
        <picture className={composeClassName('h-full w-full object-cover')}>
          <source
            srcSet={`${require(`@assets/images/marketplace/about-us/team-avatar/${employee.picture}`)}`}
            type="image/png"
          />
          <img
            className="h-full w-full"
            src={require(`@assets/images/marketplace/about-us/team-avatar/${employee.picture}`)}
            alt={employee.name}
          />
        </picture>
      </div>
      <div className="mt-3 flex flex-col gap-1">
        <span className="text-lead1 font-bold text-text-primary">
          {employee.name}
        </span>
        <span className="text-lead2 text-text-secondary">
          {employee.function}
        </span>
      </div>
    </div>
  );
};

const FourthSection = () => {
  const { t } = useTranslation();

  return (
    <section
      className={composeClassName(
        DEFAULT_CONTENT_WIDTH,
        'm-auto mt-[5.6rem] flex flex-col items-center gap-8',
        'md:mt-[12.8rem]',
        'lg:mt-[10rem]'
      )}
    >
      <div className="flex max-w-[55rem] flex-col items-center gap-3">
        <h2
          className={composeClassName(
            'text-center text-[3.2rem] font-extrabold text-text-primary',
            'sm:text-[4.8rem]'
          )}
        >
          {t('js.features.marketplace.about_us.section4.title')}
        </h2>
        <span className="text-center text-body">
          {t('js.features.marketplace.about_us.section4.subtitle')}
        </span>
      </div>
      <div
        className={composeClassName(
          'grid gap-y-4 gap-x-4 pb-9 w-full',
          'sm:grid-cols-2 md:grid-cols-4',
          'md:gap-y-7 md:pb-10',
          'lg:pb-[11rem]'
        )}
      >
        {employees.map((employee) => {
          return <EmployeeSummary key={employee.name} employee={employee} />;
        })}
      </div>
    </section>
  );
};

export { FourthSection };
