import React from 'react';
import { useTranslation } from 'react-i18next';
import { composeClassName } from '@/utils/util_helpers';
import { SectionTitle } from '../../../../../components/section-title';
import { DEFAULT_CONTENT_WIDTH } from '../../../../../constants';
import { PhotoCollage } from './components/photo-collage';

const FifthSection = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full">
      <div className={composeClassName(DEFAULT_CONTENT_WIDTH, 'm-auto')}>
        <div
          className={composeClassName(
            'relative mt-10 h-auto w-full',
            'sm:mt-[16rem]'
          )}
        >
          <PhotoCollage />
        </div>
        <div
          className={composeClassName(
            'mt-8 mb-[13rem] w-full',
            'md:mb-[20rem]',
            'lg:mb-[22rem] lg:mt-10'
          )}
        >
          <SectionTitle
            className="m-auto flex w-full justify-center"
            subTitle={t('js.features.marketplace.home.section5.subtitle') ?? ''}
          >
            {t('js.features.marketplace.home.section5.title')}
          </SectionTitle>
        </div>
      </div>
    </div>
  );
};

export { FifthSection };
