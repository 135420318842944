import { useCallback, useEffect, useState } from 'react';
import { ResultsSummary } from '@/types/global';
import {
  ColocAPIPaginationResponse,
  ColocAPISortDirections,
} from '@/lib/coloc-api';
import { paginationToResultsSummaryType } from '@/utils/util_helpers/coloc_api_helpers';

const useColocApi = <T extends string>() => {
  const [pageLimit, setPageLimit] = useState(30);
  const [page, setPage] = useState(1);
  const [paginationResponse, setPaginationResponse] = useState<
    ColocAPIPaginationResponse | undefined
  >(undefined);
  const [resultSummary, setResultsSummary] = useState<ResultsSummary>({
    totalItems: 0,
    fromItem: 0,
    untilItem: 0,
  });
  const [sortDirection, setSortDirection] =
    useState<ColocAPISortDirections | null>(null);
  const [selectedColumnToSort, internalSetColumnToSort] = useState<T | null>(
    null
  );

  const setColumnToSort = useCallback(
    (column: T) => {
      if (selectedColumnToSort === column) {
        if (sortDirection) {
          if (sortDirection === 'ascending') {
            setSortDirection('descending');
          } else {
            internalSetColumnToSort(null);
            setSortDirection(null);
          }
        } else {
          setSortDirection('ascending');
        }
      } else {
        internalSetColumnToSort(column);
        setSortDirection('ascending');
      }
    },
    [selectedColumnToSort, sortDirection]
  );

  useEffect(() => {
    setResultsSummary(paginationToResultsSummaryType(paginationResponse));
    if (paginationResponse) {
      setPage(paginationResponse.pagination.page);
    } else {
      setPage(1);
    }
  }, [paginationResponse]);

  return {
    page,
    setPage,
    pageLimit,
    setPageLimit,
    resultSummary,
    sortDirection,
    setSortDirection,
    selectedColumnToSort,
    setColumnToSort,
    setPaginationResponse,
  };
};

export { useColocApi };
