import React from 'react';
import { DropDownInputMultipleValuesProps } from './types';
import { DropDownInput } from '../dropdown-input';
import { composeClassName } from '../../../../../../utils/util_helpers';

const DropDownInputMultipleValues: React.FC<DropDownInputMultipleValuesProps> =
  ({ children, className }) => {
    return (
      <ul
        className={composeClassName(
          'relative flex w-full flex-row flex-wrap items-center gap-1 py-2 pl-3',
          className && className
        )}
      >
        {children}
        <li>
          <DropDownInput className="pl-0 h-4 min-w-[80px]" />
        </li>
      </ul>
    );
  };

export { DropDownInputMultipleValues };
