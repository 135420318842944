import React from 'react';
import { usePaginationContext } from '../../hooks/use-pagination-context';
import { PaginationItem } from '../pagination-item';
import { PaginationRestItem } from '../pagination-rest-item';

const PaginationPagesContainer = () => {
  const {
    previousPages,
    middlePages,
    nextPages,
    currentPage,
    onPageChange,
    disabled,
  } = usePaginationContext()!;

  const renderPageElement = (page: number) => {
    return (
      <PaginationItem
        key={page}
        value={page}
        active={page === currentPage}
        onClick={() => onPageChange(page)}
        disabled={disabled}
      />
    );
  };

  return (
    <>
      {previousPages.map(renderPageElement)}
      <PaginationRestItem isPrevious />
      {middlePages.map(renderPageElement)}
      <PaginationRestItem />
      {nextPages.map(renderPageElement)}
    </>
  );
};

export default PaginationPagesContainer;
