import React from 'react';

import { Avatar } from '@/components/design-system/atoms/avatar';

import { RoomAssignmentPropertyManagerTableCellProps } from './types';

const RoomAssignmentPropertyManagerTableCellContent: React.FC<RoomAssignmentPropertyManagerTableCellProps> = ({
  avatar_url,
  first_name,
  last_name,
}) => {

  return (
    <div className="flex items-center space-x-2">
      <Avatar
        src={avatar_url}
        type="person"
      />
      <span className="text-text-primary">
        {`${first_name} ${last_name}`}
      </span>
    </div>
  );
};

export { RoomAssignmentPropertyManagerTableCellContent };
