import React from 'react';
import { composeClassName } from '@/utils/util_helpers';

const backgroundImage = require(`@assets/images/marketplace/hero.webp`);

const PromoVideo = () => {
  return (
    <div
      className={composeClassName(
        'background-image-hero',
        'relative z-10 aspect-[5/3] w-full self-end rounded-[3.2rem]',
        'lg:aspect-[5/4] lg:w-[40rem]',
        '[@media(min-width:1200px)]:w-[50rem]',
        '2xl:!w-[60rem]'
      )}
      style={{
        backgroundSize: 'cover',
        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundImage})`,
      }}
    />
  );
};

export { PromoVideo };
