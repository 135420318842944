<template>
  <div class="relative w-full overflow-hidden">
    <Header />
    <div class="relative">
      <FirstSection />
      <SecondSection />
      <ThirdSection />
      <FourthSection />
      <FifthSection />
      <FooterSection />
      <div
        v-if="showScrollTop"
        @click="scrollTop"
        class="
          fixed
          right-5
          bottom-5
          z-40
          flex
          h-[40px]
          w-[40px]
          cursor-pointer
          rounded-full
          bg-[#FECE1F]
          hover:bg-opacity-80
          hover:text-opacity-80
        "
      >
        <svg
          class="m-auto"
          width="18"
          height="9"
          viewBox="0 0 18 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.9201 8.05063L10.4001 1.53062C9.63008 0.760625 8.37008 0.760625 7.60008 1.53062L1.08008 8.05063"
            stroke="#1A1A1A"
            stroke-width="1.3"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';
import FirstSection from './sections/FirstSection.vue';
import SecondSection from './sections/SecondSection.vue';
import ThirdSection from './sections/ThirdSection.vue';
import FourthSection from './sections/FourthSection.vue';
import FifthSection from './sections/FifthSection.vue';
import FooterSection from './sections/FooterSection.vue';

export default {
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Header,
    FirstSection,
    SecondSection,
    ThirdSection,
    FourthSection,
    FifthSection,
    FooterSection,
  },
  layout: 'default',
  data() {
    return {
      showScrollTop: false,
    };
  },
  methods: {
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.showScrollTop = window.scrollY > 100;
    });
  },
};
</script>
