var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex align-items-center searchContainer",staticStyle:{"padding":"4px 16px"}},[_c('label',{attrs:{"for":"searchInput","aria-label":"Search for a place to live in"}},[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M21 21L15.8033 15.8033M15.8033 15.8033C17.1605 14.4461 18 12.5711 18 10.5C18 6.35786 14.6421 3 10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18C12.5711 18 14.4461 17.1605 15.8033 15.8033Z","stroke":"#495057","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})])]),_vm._v(" "),(_vm.image)?_c('img',{staticClass:"rounded",staticStyle:{"margin":"1.6px 2px 1.6px 12px"},attrs:{"src":_vm.image,"alt":"","width":"26","height":"26"}}):_vm._e(),_vm._v(" "),_c('autocomplete',{attrs:{"items":_vm.items,"get-label":_vm.getLabel,"component-item":_vm.template,"min-len":1,"wait":300,"value":_vm.initialQuery,"auto-select-one-item":false,"input-attrs":{
        id: 'searchInput',
        class: 'form-control-sm border-0 shadow-none',
        placeholder: _vm.t('vue_components.search_bar.placeholder'),
        autocomplete: 'off',
      }},on:{"update-items":_vm.updateItems,"change":_vm.change,"focus":_vm.focus,"blur":_vm.blur,"item-selected":_vm.itemSelected}})],1),_vm._v(" "),(!_vm.loadingItems && _vm.focused && _vm.query == '')?_c('div',{staticClass:"v-autocomplete"},[_c('div',{staticClass:"v-autocomplete-list"},[_c('div',{staticClass:"v-autocomplete-list-item"},[_vm._v("\n        "+_vm._s(_vm.t('vue_components.search_bar.hint'))+"\n      ")])])]):_vm._e(),_vm._v(" "),(!_vm.loadingItems && _vm.focused && _vm.query != '' && _vm.items.length == 0)?_c('div',{staticClass:"v-autocomplete"},[_c('div',{staticClass:"v-autocomplete-list"},[_c('div',{staticClass:"v-autocomplete-list-item"},[_vm._v("\n        "+_vm._s(_vm.t('vue_components.search_bar.no_results'))+"\n      ")])])]):_vm._e(),_vm._v(" "),(_vm.loadingItems && _vm.query != '')?_c('div',{staticClass:"v-autocomplete"},[_vm._m(0)]):_vm._e(),_vm._v(" "),_c('div',{attrs:{"id":"widthSearchInput"}})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"v-autocomplete-list"},[_c('div',{staticClass:"v-autocomplete-list-item"},[_c('div',{staticClass:"spinner-grow spinner-grow-sm",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])])])
}]

export { render, staticRenderFns }