import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { t } from 'i18next';

import {
  TransactionListStatusFilterType,
  TransactionsListSortType,
} from '@/lib/coloc-api/admin';
import { useColocApi } from '@/hooks/use-coloc-api';
import { BankAccount } from '../../../../types';

type PairStatusOption = {
  status: TransactionListStatusFilterType;
  displayValue: string;
};

const pairTransactionStatus: Array<PairStatusOption> = [
  {
    status: 'match',
    displayValue: t(
      'js.features.admin.transactions.transactions_list.paired_option.paired'
    ),
  },
  {
    status: 'unmatched',
    displayValue: t(
      'js.features.admin.transactions.transactions_list.paired_option.unpaired'
    ),
  },
  {
    status: 'ignored',
    displayValue: t(
      'js.features.admin.transactions.transactions_list.paired_option.ignored'
    ),
  },
];

const useTransactionFilters = () => {
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [selectedTransactionPairStatus, setSelectedTransactionPairStatus] =
    useState<PairStatusOption | undefined>({ ...pairTransactionStatus[1] });
  const [pairStatusQuery, setPairStatusQuery] = useState('');
  const [selectedBankAccount, setSelectedBankAccount] = useState<
    BankAccount | undefined
  >(undefined);
  const [bankAccountQuery, setBankAccountQuery] = useState('');

  const {
    setColumnToSort,
    sortDirection,
    selectedColumnToSort,
    setSortDirection,
  } = useColocApi<TransactionsListSortType>()!;

  // Set default sorting to house name
  useEffect(() => {
    setColumnToSort('date');
    setSortDirection('descending');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDateRange = useCallback(
    (new_date_range: [Date | null, Date | null]) => {
      setStartDate(new_date_range[0] ?? undefined);
      setEndDate(new_date_range[1] ?? undefined);
    },
    []
  );

  const filteredTransactionPairedStatusArray = useMemo(() => {
    return pairTransactionStatus.filter((statusObject) =>
      statusObject.displayValue.includes(pairStatusQuery)
    );
  }, [pairStatusQuery]);

  return {
    startDate,
    endDate,
    setDateRange,
    searchQuery,
    setSearchQuery,
    setColumnToSort,
    sortDirection,
    selectedColumnToSort,
    selectedTransactionPairStatus,
    setSelectedTransactionPairStatus,
    pairStatusQuery,
    setPairStatusQuery,
    filteredTransactionPairedStatusArray,
    selectedBankAccount,
    setSelectedBankAccount,
    bankAccountQuery,
    setBankAccountQuery,
  };
};

const GetTransactionsFiltersContext = createContext<
  ReturnType<typeof useTransactionFilters> | undefined
>(undefined);

const GetTransactionsFiltersContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const hook = useTransactionFilters();

  return (
    <GetTransactionsFiltersContext.Provider value={hook}>
      {children}
    </GetTransactionsFiltersContext.Provider>
  );
};

const useTransactionsFiltersContext = () => {
  const context = useContext(GetTransactionsFiltersContext);

  if (context === null) {
    throw new Error(
      'useTransactionsFiltersContext must be used within a GetTransactionsFiltersContextProvider'
    );
  }

  return context;
};

export { GetTransactionsFiltersContextProvider, useTransactionsFiltersContext };
