import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import { IconSaxIcon } from '@/components/design-system/atoms/icon';
import { IconSaxIconName } from '@/components/design-system/atoms/icon/iconsax-icon/types';

import { RentPaymentsStatusCellContentProps } from './types';
import { theme } from '@/utils/util_theme';
import { composeClassName } from '@/utils/util_helpers';

type DisplayPaymentRentStatusDisplayValue =
  | 'collected'
  | 'partially_collected'
  | 'due'
  | 'in_flight'
  | 'resolved'
  | 'pending_payout'
  | 'partially_first_reminder'
  | 'partially_second_reminder'
  | 'unpaid_second_reminder'
  | 'unpaid_first_reminder';

type TranslationStatus =
  | Omit<
      DisplayPaymentRentStatusDisplayValue,
      | 'partially_first_reminder'
      | 'partially_second_reminder'
      | 'unpaid_second_reminder'
      | 'unpaid_first_reminder'
    >
  | 'first_reminder'
  | 'second_reminder';

const RentPaymentsStatusCellContent: React.FC<RentPaymentsStatusCellContentProps> =
  ({ status, amountPaid, className }) => {
    const { t } = useTranslation();

    const displayRentStatus: DisplayPaymentRentStatusDisplayValue =
      useMemo(() => {
        if (status === 'resolved') {
          return 'resolved';
        }

        if (status === 'collected') {
          return 'collected';
        }

        if (status === 'in_flight') {
          return 'in_flight';
        }

        if (status === 'pending_payout') {
          return 'pending_payout';
        }

        if (amountPaid.cents === 0) {
          return 'due';
        }

        return 'partially_collected';
      }, [amountPaid, status]);

    const translationStatus: TranslationStatus = useMemo(() => {
      if (status === 'due') {
        if (amountPaid.cents === 0) {
          return 'due';
        }
        return 'partially_collected';
      }

      return status;
    }, [amountPaid, status]);

    const iconName: IconSaxIconName = useMemo(() => {
      switch (displayRentStatus) {
        case 'resolved':
          return 'tick-circle';
        case 'pending_payout':
        case 'in_flight':
          return 'clock-1';
        case 'collected':
          return 'card-tick-1';
        case 'partially_collected':
          return 'card-part';
        default:
          return 'card-slash';
      }
    }, [displayRentStatus]);

    const strokeColor: string = useMemo(() => {
      switch (displayRentStatus) {
        case 'collected':
        case 'resolved':
          return theme?.colors.J300;
        case 'pending_payout':
        case 'in_flight':
          return theme?.colors.R500;
        case 'partially_collected':
          return theme?.colors.Q300;
        default:
          return theme?.colors.C300;
      }
    }, [displayRentStatus]);

    const stringColor: string = useMemo(() => {
      switch (displayRentStatus) {
        case 'collected':
        case 'resolved':
          return 'text-J300';
        case 'in_flight':
        case 'pending_payout':
          return 'text-R500';
        case 'partially_collected':
          return 'text-Q300';
        default:
          return 'text-C300';
      }
    }, [displayRentStatus]);

    return (
      <div
        className={composeClassName(
          'flex  items-center',
          className && className
        )}
      >
        <IconSaxIcon
          name={iconName}
          strokeWidth={1.5}
          strokeColor={strokeColor}
          size={18}
        />
        <span
          className={composeClassName(
            'line-clamp-1 overflow-hidden break-all pl-1 text-body',
            stringColor
          )}
          style={{ whiteSpace: 'initial' }}
        >
          {t(
            `js.features.admin.rents.rent_payment_status_cell_content.${translationStatus}`
          )}
        </span>
      </div>
    );
  };

export { RentPaymentsStatusCellContent };
