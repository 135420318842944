import React, { useMemo } from 'react';
import { IconSaxIcon } from '../../../../atoms/icon';
import { theme } from '../../../../../../utils/util_theme';
import { TableHeaderCellDefaultContentProps } from './types';
import { withoutSortingProps } from '../../types';
import { IconSaxIconName } from '../../../../atoms/icon/iconsax-icon/types';

const TableHeaderCellDefaultContentDefault: React.FC<TableHeaderCellDefaultContentProps> =
  ({ children, sortingOrder }) => {
    const sortIcon: IconSaxIconName | undefined = useMemo(() => {
      if (sortingOrder) {
        switch (sortingOrder) {
          case 'asc':
            return 'arrow-up';
          case 'desc':
            return 'arrow-down-1';
          default:
            return 'arrow-3';
        }
      }

      return undefined;
    }, [sortingOrder]);

    return (
      <div className="width-full flex items-center">
        <span className="pr-2 text-body font-medium text-N700">{children}</span>
        {sortIcon && (
          <IconSaxIcon
            name={sortIcon}
            size={14}
            strokeWidth={1.6}
            strokeColor={theme?.colors?.E100}
          />
        )}
      </div>
    );
  };

const TableHeaderCellDefaultContent =
  withoutSortingProps<TableHeaderCellDefaultContentProps>(
    TableHeaderCellDefaultContentDefault
  );

export { TableHeaderCellDefaultContent };
