import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchRoomContext } from '@/features/marketplace/hooks/use-search-room';
import { SearchBarItem } from './components/search-bar-item';
import { SearchBarLoading } from './components/search-bar-loading';

const NoResultsFound = () => {
  const { t } = useTranslation();

  return (
    <span className="w-full text-lead2 font-medium text-text-secondary">
      {t('js.features.marketplace.components.room_search.no_results')}
    </span>
  );
};

const SearchBarItems = () => {
  const {
    fetchSearchRoomResults: { data, status },
    searchQuery,
  } = useSearchRoomContext()!;
  const { t } = useTranslation();

  if (!searchQuery || searchQuery === '') {
    return (
      <span className="text-lead2 text-text-secondary">
        {t('js.features.marketplace.components.room_search.start_typing_text')}
      </span>
    );
  }

  if (status === 'loading') {
    return <SearchBarLoading />;
  }

  if (status === 'error') {
    return <NoResultsFound />;
  }

  if (data?.data && data?.data?.length >= 1) {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {data?.data && (
          <>
            {data.data.map((item) => {
              return <SearchBarItem key={item.name} item={item} />;
            })}
          </>
        )}
      </>
    );
  }

  return <NoResultsFound />;
};

export { SearchBarItems };
