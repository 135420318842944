import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '@/hooks/use-auth';
import { Avatar } from '@/components/design-system/atoms/avatar';
import { COLOC_APP_API_HOST, COLOC_MAIN_APP } from '@/config';
import { IconSaxIcon } from '@/components/design-system/atoms/icon';
import { theme } from '@/utils/util_theme';
import { DropdownMenu } from '@/components/design-system/molecules/dropdown-menu';
import { DropdownMenuOption } from '@/components/design-system/molecules/dropdown-menu/components/dropdown-menu-option';

const UserMenu = () => {
  const { user } = useAuthContext()!;
  const { t } = useTranslation();

  const userAvatar = useMemo(() => {
    if (user?.avatar_only_path && user.avatar) {
      return `${COLOC_APP_API_HOST}${user?.avatar}`;
    }

    if (user?.avatar) {
      return user.avatar;
    }

    return undefined;
  }, [user?.avatar, user?.avatar_only_path]);

  if (user) {
    return (
      <form method="post" action={`${COLOC_APP_API_HOST}/users/sign_out`}>
        <input type="hidden" name="_method" value="delete" />
        <DropdownMenu>
          <DropdownMenu.DropdownMenuButton>
            {({ open }) => (
              <>
                <Avatar
                  size="l"
                  firstName={user.first_name}
                  lastName={user.last_name}
                  {...(userAvatar && { src: userAvatar })}
                />
                <IconSaxIcon
                  name={open ? 'arrow-up-1' : 'arrow-down'}
                  strokeColor={theme?.colors.text.tertiary}
                  size={16}
                />
              </>
            )}
          </DropdownMenu.DropdownMenuButton>
          <DropdownMenu.DropdownMenuOptions className="right-[0px]">
            <DropdownMenuOption
              href={
                user.type === 'Owner'
                  ? `${COLOC_MAIN_APP}/admin`
                  : `${COLOC_MAIN_APP}/dashboard`
              }
              iconName="element-2"
            >
              Dashboard
            </DropdownMenuOption>
            <DropdownMenuOption
              href={`${COLOC_MAIN_APP}/profile/${user.slug}`}
              iconName="user"
            >
              {t('js.features.shared.header.account') ?? ''}
            </DropdownMenuOption>
            <DropdownMenuOption
              href={`${COLOC_MAIN_APP}/users/edit`}
              iconName="setting-2"
            >
              {t('js.features.shared.header.settings') ?? ''}
            </DropdownMenuOption>
            <DropdownMenuOption
              href={`${COLOC_MAIN_APP}/watchlist`}
              iconName="heart"
            >
              {t('js.features.shared.header.wishlist') ?? ''}
            </DropdownMenuOption>
            <DropdownMenuOption
              as="button"
              type="submit"
              iconName="logout"
              destructive
            >
              {t('js.features.shared.header.sign_out') ?? ''}
            </DropdownMenuOption>
          </DropdownMenu.DropdownMenuOptions>
        </DropdownMenu>
      </form>
    );
  }

  return <></>;
};

export { UserMenu };
