import React from 'react';
import { composeClassName } from '@/utils/util_helpers';

type Props = { step: number; title: string; text: string };

const ApplyStep: React.FC<Props> = ({ step, title, text }) => {
  return (
    <div className={composeClassName('flex w-full gap-[3rem]')}>
      <div className="flex flex-col items-center">
        <div
          className={composeClassName(
            'flex h-7 w-7 items-center justify-center rounded-full bg-E50',
            'sm:h-8 sm:w-8'
          )}
        >
          <span
            className={composeClassName(
              'text-lead1 font-bold text-text-primary',
              'sm:text-h5'
            )}
          >
            {step}
          </span>
        </div>
        {step !== 3 && (
          <div
            className="my-2 w-[2px] flex-1"
            style={{
              backgroundImage:
                'linear-gradient(#E8E8E8 33%, rgba(255,255,255,0) 0%)',
              backgroundPosition: 'right',
              backgroundSize: '4px 10px',
              backgroundRepeat: 'repeat-y',
            }}
          />
        )}
      </div>
      <div
        className={composeClassName(
          'flex flex-1 flex-col gap-1 pb-8',
          'sm:gap-3 sm:pb-7',
          'md:pb-[3rem]'
        )}
      >
        <span
          className={composeClassName(
            'text-lead2 font-medium text-text-primary ',
            'sm:text-lead1'
          )}
        >
          {title}
        </span>
        <span
          className={composeClassName(
            'text-body text-text-tertiary',
            'sm:text-lead2'
          )}
        >
          {text}
        </span>
      </div>
    </div>
  );
};

export { ApplyStep };
