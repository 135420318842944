import React, { useMemo } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { composeClassName } from '@/utils/util_helpers';
import { EmptyStateProps } from './types';

const EmptyState: React.FC<EmptyStateProps> = ({
  className,
  type,
  text,
  size = 248,
  textClassName,
  illustrationClassName,
  actionButton,
}) => {
  const { t } = useTranslation();
  const iconName = useMemo(() => {
    switch (type) {
      case 'PageNotFound':
        return '404';
      case 'NoResults':
        return 'no-results';
      default:
        return 'error';
    }
  }, [type]);

  const stateText = useMemo(() => {
    if (text) {
      return text;
    }

    switch (type) {
      case 'PageNotFound':
        return t('js.components.empty_state.page_not_found');
      case 'NoResults':
        return t('js.components.empty_state.no_results');
      default:
        return t('js.components.empty_state.error');
    }
  }, [text, type, t]);

  return (
    <div
      className={composeClassName(
        'inline-flex flex-col items-center gap-3',
        className && className
      )}
    >
      <ReactSVG
        data-testid={iconName}
        src={require(`@assets/icons/empty-states/${iconName}.svg`)}
        className={composeClassName(
          'inline-block align-baseline',
          illustrationClassName && illustrationClassName
        )}
        beforeInjection={(svg) => {
          svg.setAttribute('width', `${size}`);
          svg.setAttribute('height', `${size}`);
        }}
      />
      <span
        className={composeClassName(
          'text-lead1 font-medium text-text-primary',
          textClassName && textClassName
        )}
      >
        {stateText}
      </span>
      {actionButton && actionButton}
    </div>
  );
};

export { EmptyState };
