import { useTranslation } from 'react-i18next';
import React from 'react';
import { DatePicker } from '@/components/design-system/molecules/datepicker';
import { SearchInput } from '@/components/design-system/molecules/search-input';

import { HouseRentStatusDropDown } from './components/house-rent-status-dropdown';
import { useRentsSummaryFiltersContext } from '../../hooks/use-rents-summary-filter';

const RentsSummaryFilter = () => {
  const { setSearchQuery, searchQuery, setDateRange, startDate, endDate } =
    useRentsSummaryFiltersContext()!;
  const { t } = useTranslation();

  return (
    <div className="relative flex w-full items-end justify-between pt-5">
      <div className="flex gap-4">
        <SearchInput
          placeholder={
            t(
              'js.features.admin.rents.rent_houses_overview.search_placeholder'
            ) ?? ''
          }
          className="w-[250px]"
          label={t('js.features.admin.rents.shared.search_label') ?? ''}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <DatePicker
          className="w-[250px]"
          onChange={setDateRange}
          label={t('js.features.admin.rents.shared.date_range_label') ?? ''}
          dateRange
          type="month"
          startValue={startDate}
          endValue={endDate}
        />
        <HouseRentStatusDropDown />
      </div>
    </div>
  );
};

export { RentsSummaryFilter };
