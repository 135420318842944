import React, { useState } from 'react';
import { Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { IconSaxIcon } from '@/components/design-system/atoms/icon';
import { theme } from '@/utils/util_theme';
import { useSearchRoomContext } from '@/features/marketplace/hooks/use-search-room';
import { SearchBarItems } from '@/features/marketplace/components/search-bar-items';
import { composeClassName } from '@/utils/util_helpers';
import { useWindowDimensions } from '@/hooks/use-window-dimensions';

const SearchBarHero = () => {
  const [inputActive, setInputActive] = useState(false);

  const { setSearchQuery } = useSearchRoomContext()!;
  const { screenWidthKey } = useWindowDimensions();

  const { t } = useTranslation();

  return (
    <div className="relative">
      <div
        className={composeClassName(
          'mt-5 flex h-[6rem] w-full items-center rounded-[50px] bg-white px-3 shadow-longShadow hover:shadow-[-16px_16px_80px_rgba(18,2,47,0.17)]',
          'md:mt-6 md:h-[9rem] md:px-5',
          'lg:mt-10'
        )}
      >
        <IconSaxIcon
          name="search-normal-1"
          strokeColor={theme?.colors.text.tertiary}
        />
        <input
          className="hero-search"
          type="text"
          placeholder={
            t('js.features.marketplace.components.room_search.placeholder') ??
            ''
          }
          onChange={(e) => setSearchQuery(e.target.value)}
          onFocus={() => setInputActive(true)}
          onBlur={() => setInputActive(false)}
        />
        <button
          type="button"
          className={composeClassName(
            'flex h-6 w-6 items-center justify-center rounded-full bg-R300 shadow-[0_3.6rem_7.2rem_0px_rgba(254,206,31,0.5)]',
            'md:h-8 md:w-8'
          )}
        >
          <IconSaxIcon
            size={screenWidthKey === 'sm' ? 20 : 32}
            name="arrow-right"
            strokeColor={theme?.colors.text.primary}
            strokeWidth={2}
          />
        </button>
      </div>
      <Transition
        show={inputActive}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        className="relative z-20"
      >
        <div className="scrollbar-hide absolute top-[1.6rem] z-30 flex max-h-[28rem] w-full flex-col gap-4 overflow-y-scroll rounded-[24px] bg-white p-4 shadow-medium">
          <SearchBarItems />
        </div>
      </Transition>
    </div>
  );
};

export { SearchBarHero };
