import React from 'react';
import { composeClassName } from '../../../../utils/util_helpers';
import { CardProps } from './types';

const Card: React.FC<CardProps> = ({ className, children, ...props }) => {
  return (
    <div
      className={composeClassName(
        'overflow-hidden rounded-lg bg-white shadow-large',
        className && className
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export { Card };
