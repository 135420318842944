import React from 'react';

import { ColocApiConnectedCardList } from '@/features/shared/feature-coloc-api-connected-card-list';
import { HouseRentSummary } from '../../types';
import { RentHouseCard } from './components/rent-house-card';
import { Card } from '@/components/design-system/atoms/card';
import { Shimmer } from '@/components/design-system/atoms/shimmer';
import {
  GetRentsSummaryHousesContextProvider,
  useGetRentsSummaryHousesContext,
} from './hooks/use-get-rents-summary-houses';
import {
  GetRentsSummaryFiltersContextProvider,
  useRentsSummaryFiltersContext,
} from './hooks/use-rents-summary-filter';
import { RentsSummaryFilter } from './components/rents-summary-filter';

const Content = () => {
  const context = useGetRentsSummaryHousesContext()!;
  const { startDate, endDate } = useRentsSummaryFiltersContext()!;

  return (
    <>
      <ColocApiConnectedCardList<HouseRentSummary>
        dataSource={context}
        cardRenderer={(item, key) => {
          return (
            <RentHouseCard
              key={key}
              rent_summary={item}
              onClick={() => {
                window.location.replace(
                  `?all=true&house_id=${item.id}&house_name=${item.name}${
                    startDate ? `&start_date=${startDate.toISOString()}` : ''
                  }${endDate ? `&end_date=${endDate.toISOString()}` : ''}`
                );
              }}
            />
          );
        }}
        loadingCard={
          <Card className="h-[320px] w-[365px]">
            <Shimmer className="h-[55%] w-full object-cover" />
            <div className="h-full py-4 px-3">
              <Shimmer className="h-3 w-[200px] rounded-full" />
              <div className="flex h-8 w-full gap-4 pt-5">
                <Shimmer className="h-full w-[120px] rounded-lg" />
                <Shimmer className="h-full w-[120px] rounded-lg" />
              </div>
            </div>
          </Card>
        }
      />
    </>
  );
};

const RentsSummary = () => {
  return (
    <GetRentsSummaryFiltersContextProvider>
      <GetRentsSummaryHousesContextProvider>
        <div className="flex h-full w-full flex-1 flex-col">
          <RentsSummaryFilter />
          <Content />
        </div>
      </GetRentsSummaryHousesContextProvider>
    </GetRentsSummaryFiltersContextProvider>
  );
};

export { RentsSummary };
