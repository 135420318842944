import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicantStatusBoxProps } from './types';
import { composeClassName } from '@/utils/util_helpers';
import { DropdownMenu } from '@/components/design-system/molecules/dropdown-menu';
import { IconSaxIcon } from '@/components/design-system/atoms/icon';
import {
  ApplicantReasonContractReason,
  ApplicantReasonContractReasonType,
} from '@/features/admin/contracts/types';
import { differenceInDays, format } from 'date-fns';

const isApplicantReasonContractReason = (
  str: string
): str is ApplicantReasonContractReasonType => {
  return str in ApplicantReasonContractReason;
};

type ApplicantStatusUI =
  | 'applied'
  | 'accepted'
  | 'queued'
  | 'cancelled'
  | 'signed';

const ApplicantStatusBox: React.FC<ApplicantStatusBoxProps> = ({
  applicant,
  isActionsEnabled = true,
  onAccept,
  onDecline,
}) => {

  const { status, contract_event, created_at } = applicant.contract;
  const { t, i18n } = useTranslation();

  const hasValidReason = useMemo(() => {
    return isApplicantReasonContractReason(contract_event.reason);
  }, [contract_event.reason]);

  const mappedStatus: ApplicantStatusUI = useMemo(() => {
    if (hasValidReason) {
      if (status === 'applied') {
        if (contract_event.reason.includes('accepted')) {
          return 'accepted';
        }

        if (contract_event.reason.includes('queued')) {
          return 'queued';
        }

        return 'applied';
      }
    }

    if (status === 'activated') {
      return 'accepted';
    }

    if (status === 'signed') {
      if (applicant.security_deposit?.awaiting_sepa_payment) {
        return 'awaiting_sepa_payment'
      }
    }

    return status;
  }, [hasValidReason, status, contract_event.reason]);

  const stringColor: string = useMemo(() => {
    switch (mappedStatus) {
      case 'applied':
        return 'text-text-infoHeavy';
      case 'accepted':
        return 'text-text-successHeavy';
      case 'queued':
        return 'text-text-warningHeavy';
      default:
        return 'text-text-dangerHeavy';
    }
  }, [mappedStatus]);

  const subText = useMemo(() => {
    if (hasValidReason) {
      return t(
        `js.features.admin.contracts.applicant_overview.reason.${contract_event.reason}`,
        { actor_name: contract_event.actor.name }
      );
    }

    return t(`js.features.admin.contracts.applicant_overview.status_overview.${mappedStatus}`);
  }, [
    contract_event.actor.name,
    contract_event.reason,
    hasValidReason,
    mappedStatus,
    t,
    i18n
  ]);


  const { dateString, daysAgo } = useMemo(() => {
    const date = new Date(created_at);
    return {
      dateString: format(date, 'dd MMMM, yyyy'),
      daysAgo: differenceInDays(new Date(), date),
    };
  }, [created_at]);

  return (
    <div className="w-full rounded bg-fill-surface-primaryMedium px-3 py-2">
      <div className="flex justify-between text-footnote2 text-text-primaryLight">
        <span>Applied on {dateString}</span>
        <span>{daysAgo} days ago</span>
      </div>
      <div className="flex w-full flex-row items-center justify-between gap-3">
        <div className="flex w-full flex-col flex-wrap">
          <span className={composeClassName('text-lead2 font-bold', stringColor)}>
            {t(
              `js.features.admin.contracts.applicant_overview.status.${mappedStatus}`
            )}
          </span>
          {subText && (
            <span className="whitespace-pre-line text-body text-text-primaryLight text-footnote1">
              {subText}
            </span>
          )}
        </div>
        {mappedStatus === 'applied' && isActionsEnabled && (
          <DropdownMenu>
            <DropdownMenu.DropdownMenuButton>
              <IconSaxIcon name="more" className="rotate-90" />
            </DropdownMenu.DropdownMenuButton>
            <DropdownMenu.DropdownMenuOptions className="right-[0px]">
              <DropdownMenu.DropdownMenuOption
                iconName="tick-circle"
                as="button"
                onClick={() => onAccept()}
              >
                accept
              </DropdownMenu.DropdownMenuOption>
              <DropdownMenu.DropdownMenuOption
                iconName="close-circle"
                destructive
                as="button"
                onClick={() => onDecline()}
              >
                decline
              </DropdownMenu.DropdownMenuOption>
            </DropdownMenu.DropdownMenuOptions>
          </DropdownMenu>
        )}

        {(mappedStatus === 'accepted' || mappedStatus == 'signed') && !isActionsEnabled && (
          <DropdownMenu>
            <DropdownMenu.DropdownMenuButton>
              <IconSaxIcon name="more" className="rotate-90" />
            </DropdownMenu.DropdownMenuButton>
            <DropdownMenu.DropdownMenuOptions className="right-[0px]">
              <DropdownMenu.DropdownMenuOption
                iconName="close-circle"
                destructive
                as="button"
                onClick={() => onDecline()}
              >
                decline
              </DropdownMenu.DropdownMenuOption>
            </DropdownMenu.DropdownMenuOptions>
          </DropdownMenu>
        )}
      </div>
    </div>
  );
};

export { ApplicantStatusBox };
