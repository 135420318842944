import React from 'react';
import { useTranslation } from 'react-i18next';
import { composeClassName } from '@/utils/util_helpers';
import { DEFAULT_CONTENT_WIDTH } from '../../../../../constants';
import { CitiesList } from './components/cities-list';
import { COLOC_MAIN_APP } from '@/config';
import { useLocale } from '@/hooks/use-locale';
import { SectionTitle } from '@/features/marketplace/components/section-title';

const backgroundImage = require(`@assets/images/marketplace/home-section-2-curve.svg`);

const SecondSection = () => {
  const { currentLocale } = useLocale();
  const { t } = useTranslation();

  return (
    <section
      className='w-full overflow-hidden bg-[#fbfbfb]'
    >
      <div className={composeClassName(DEFAULT_CONTENT_WIDTH, 'm-auto flex-1')}>
        <div className="w-full">
          <SectionTitle
            subTitle={t('js.features.marketplace.home.section2.subtitle') ?? ''}
            className="mt-8 "
          >
            {t('js.features.marketplace.home.section2.title')}
          </SectionTitle>
        </div>
      </div>
      <CitiesList />
      <div
        className={composeClassName(
          DEFAULT_CONTENT_WIDTH,
          'm-auto flex justify-center pb-7'
        )}
      >
        <a
          className="rounded-[2.4rem] border-[0.1rem] border-solid border-E75 px-5 py-[1.2rem] text-lead2 font-bold text-text-primary hover:opacity-70"
          href={`${COLOC_MAIN_APP}/${currentLocale}/cities`}
        >
          {t('js.features.marketplace.home.section2.discover_button')}
        </a>
      </div>
    </section>
  );
};

export { SecondSection };
