import { useMutation } from '@tanstack/react-query';

import { useTranslation } from 'react-i18next';
import { showToast } from '@/services/toast-service';
import { updateMoveIn } from '@/services/coloc-api/admin';
import { useGetRoomsAssignmentContext } from '../use-get-room-assignment-list';

const useUpdateMoveIn = () => {
  const { t } = useTranslation();
  const { updateApplicantWithContractId, setShowUpdateMoveIn, fetch } =
    useGetRoomsAssignmentContext()!;

  const mutate = useMutation({
    mutationKey: ['updateMoveIn'],
    mutationFn: (params: { contract_id: string; date: string }) => {
      return updateMoveIn({
        id: Number(params.contract_id),
        ...(params.date && {
          move_in: params.date,
        }),
      });
    },
    onSuccess: (_data, params) => {
      updateApplicantWithContractId({
        contract_id: Number(params.contract_id),
        params: {
          move_in_date: new Date(params.date),
        },
      });
      setShowUpdateMoveIn((currentState) => ({
        ...currentState,
        status: 'success',
      }));

      fetch.refetch();
    },
    onError: (err) => {
      setShowUpdateMoveIn({ show: false, applicant: undefined });

      console.log('err', err);
      const errors = err?.response?.data?.errors;
      if (!errors) {
        return showToast.error({});
      }
      const keys = Object.keys(errors);
      keys.forEach((key) => {
        const messages = errors[key];
        messages.forEach((message) => {
          const humanizedKey = key.replace(/_/g, ' ');
          showToast.error({
            text: `${humanizedKey} ${message}`,
          });
        });
      });
    },
  });

  return {
    mutate,
  };
};

export { useUpdateMoveIn };
