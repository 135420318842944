import { BaseIconWithAdjustableStrokeProps } from '../types';

const iconSaxNames = [
  '24-support',
  '3d-cube-scan',
  '3d-rotate',
  '3d-square',
  '3dcube',
  '3square',
  'activity',
  'add-circle',
  'add-square',
  'add',
  'additem',
  'airdrop',
  'airplane-square',
  'airplane',
  'airpod',
  'airpods',
  'alarm',
  'align-bottom',
  'align-horizontally',
  'align-left',
  'align-right',
  'align-vertically',
  'aquarius',
  'archive-1',
  'archive-add',
  'archive-book',
  'archive-minus',
  'archive-slash',
  'archive-tick',
  'archive',
  'arrange-circle-2',
  'arrange-circle',
  'arrange-square-2',
  'arrange-square',
  'arrow-2',
  'arrow-3',
  'arrow-bottom',
  'arrow-circle-down',
  'arrow-circle-left',
  'arrow-circle-right',
  'arrow-down-1',
  'arrow-down-2',
  'arrow-down-4',
  'arrow-down',
  'arrow-left-1',
  'arrow-left-2',
  'arrow-left-3',
  'arrow-left-4',
  'arrow-left',
  'arrow-right-1',
  'arrow-right-2',
  'arrow-right-3',
  'arrow-right-4',
  'arrow-right',
  'arrow-square-down',
  'arrow-square-left',
  'arrow-square-right',
  'arrow-square-up',
  'arrow-square',
  'arrow-swap-horizontal',
  'arrow-up-1',
  'arrow-up-2',
  'arrow-up-3',
  'arrow-up-4',
  'arrow-up',
  'arrow',
  'arrow-next',
  'arrow-previous',
  'attach-circle',
  'attach-square',
  'audio-square',
  'autobrightness',
  'award',
  'back-square',
  'backward-10-seconds',
  'backward-15-seconds',
  'backward-5-seconds',
  'backward-item-1',
  'backward-item',
  'backward',
  'bag-2',
  'bag-cross-1',
  'bag-cross',
  'bag-happy',
  'bag-tick-2',
  'bag-tick',
  'bag-timer',
  'bag',
  'bank',
  'barcode',
  'battery-3full',
  'battery-charging',
  'battery-disable',
  'battery-empty-1',
  'battery-empty',
  'battery-full',
  'bezier-1',
  'bezier',
  'bill',
  'bitcoin-card',
  'bitcoin-convert',
  'bitcoin-refresh',
  'blend-2',
  'blend',
  'bluetooth-2',
  'bluetooth-circle',
  'bluetooth-rectangle',
  'bluetooth',
  'blur-1',
  'blur',
  'book-1',
  'book-saved',
  'book-square',
  'book',
  'bookmark-2',
  'bookmark',
  'box-1',
  'box-2',
  'box-add',
  'box-remove',
  'box-search',
  'box-tick',
  'box-time',
  'box',
  'briefcase',
  'brifecase-cross',
  'brifecase-tick',
  'brifecase-timer',
  'broom',
  'brush-1',
  'brush-2',
  'brush-3',
  'brush',
  'bubble',
  'bucket-circle-1',
  'bucket-circle',
  'bucket-square-1',
  'bucket-square',
  'building-3',
  'building-4',
  'building',
  'buildings-2',
  'buildings',
  'buliding',
  'bus',
  'buy-crypto',
  'cake',
  'calculator',
  'calendar-1',
  'calendar-2',
  'calendar-add',
  'calendar-circle',
  'calendar-edit',
  'calendar-remove',
  'calendar-search',
  'calendar-tick',
  'calendar',
  'call-add',
  'call-calling',
  'call-incoming',
  'call-minus',
  'call-outgoing',
  'call-received',
  'call-remove',
  'call-slash',
  'call',
  'camera-slash',
  'camera',
  'candle-2',
  'candle',
  'car',
  'card-add',
  'card-coin',
  'card-edit',
  'card-part',
  'card-pos',
  'card-receive',
  'card-remove-1',
  'card-remove',
  'card-send',
  'card-slash',
  'card-tick-1',
  'card-tick',
  'card',
  'cards',
  'category-2',
  'category',
  'cd',
  'chart-1',
  'chart-2',
  'chart-21',
  'chart-3',
  'chart-square',
  'chart-success',
  'chart',
  'check',
  'chrome',
  'clipboard-close',
  'clipboard-export',
  'clipboard-import',
  'clipboard-text',
  'clipboard-tick',
  'clipboard',
  'clock-1',
  'clock',
  'close',
  'close-circle',
  'close-square',
  'cloud-add',
  'cloud-change',
  'cloud-connection',
  'cloud-cross',
  'cloud-drizzle',
  'cloud-fog',
  'cloud-lightning',
  'cloud-minus',
  'cloud-notif',
  'cloud-plus',
  'cloud-remove',
  'cloud-snow',
  'cloud-sunny',
  'cloud',
  'code-1',
  'code-circle',
  'code',
  'coffee',
  'coin-1',
  'coin',
  'color-swatch',
  'colorfilter',
  'colors-square-1',
  'colors-square',
  'command-square',
  'command',
  'component-1',
  'component',
  'computing',
  'convert-3d-cube',
  'convert',
  'convertshape-2',
  'convertshape',
  'copy-success',
  'copy',
  'copyright',
  'courthouse',
  'cpu-charge',
  'cpu-setting',
  'cpu',
  'creative-commons',
  'crop',
  'crown-1',
  'crown',
  'cup',
  'danger',
  'data-2',
  'data',
  'designtools',
  'device-message',
  'devices-1',
  'devices',
  'deposit',
  'diagram',
  'diamonds',
  'direct-down',
  'direct-inbox',
  'direct-left',
  'direct-normal',
  'direct-notification',
  'direct-right',
  'direct-send',
  'direct-up',
  'direct',
  'directbox-default',
  'directbox-notif',
  'directbox-receive',
  'directbox-send',
  'discount-circle',
  'discount-shape',
  'discover-1',
  'discover',
  'dislike',
  'document-1',
  'document-add',
  'document-cloud',
  'document-code-2',
  'document-code',
  'document-copy',
  'document-download',
  'document-favorite',
  'document-filter',
  'document-forward',
  'document-like',
  'document-normal',
  'document-previous',
  'document-sketch',
  'document-text-1',
  'document-text',
  'document-upload',
  'document',
  'dollar-circle',
  'dollar-square',
  'driver-2',
  'driver-refresh',
  'driver',
  'driving',
  'drop',
  'edit-2',
  'edit',
  'electricity',
  'element-1',
  'element-2',
  'element-3',
  'element-4',
  'element-equal',
  'element-plus',
  'emoji-happy',
  'emoji-normal',
  'emoji-sad',
  'empty-wallet-add',
  'empty-wallet-change',
  'empty-wallet-remove',
  'empty-wallet-tick',
  'empty-wallet-time',
  'empty-wallet',
  'eraser-1',
  'eraser-2',
  'eraser',
  'export-1',
  'export-2',
  'export-3',
  'export',
  'external-drive',
  'eye-slash',
  'eye',
  'fatrows',
  'favorite-chart',
  'filter-add',
  'filter-edit',
  'filter-remove',
  'filter-search',
  'filter-square',
  'filter-tick',
  'filter',
  'finger-cricle',
  'finger-scan',
  'firstline',
  'flag-2',
  'flag',
  'flash-1',
  'flash-circle-1',
  'flash-circle-2',
  'flash-circle',
  'flash-slash',
  'flash',
  'folder-2',
  'folder-add',
  'folder-cloud',
  'folder-connection',
  'folder-cross',
  'folder-favorite',
  'folder-minus',
  'folder-open',
  'folder',
  'format-circle',
  'format-square',
  'forward-10-seconds',
  'forward-15-seconds',
  'forward-5-seconds',
  'forward-item-1',
  'forward-item',
  'forward-square',
  'forward',
  'frame-1',
  'frame-2',
  'frame-3',
  'frame-4',
  'frame-5',
  'frame',
  'gallery-add',
  'gallery-edit',
  'gallery-export',
  'gallery-favorite',
  'gallery-import',
  'gallery-remove',
  'gallery-slash',
  'gallery-tick',
  'gallery',
  'game',
  'gameboy',
  'gas-station',
  'gemini-2',
  'gemini',
  'ghost',
  'gift',
  'glass-1',
  'glass',
  'global-edit',
  'global-refresh',
  'global-search',
  'global',
  'global2',
  'gps-slash',
  'gps',
  'grammerly',
  'graph',
  'grid-1',
  'grid-2',
  'grid-3',
  'grid-4',
  'grid-5',
  'grid-6',
  'grid-7',
  'grid-8',
  'grid-9',
  'grid-edit',
  'grid-eraser',
  'grid-lock',
  'group-1',
  'group-10',
  'group-11',
  'group-2',
  'group-3',
  'group-4',
  'group-5',
  'group-6',
  'group-7',
  'group-8',
  'group-9',
  'group',
  'happyemoji',
  'hashtag-1',
  'hashtag-down',
  'hashtag-up',
  'hashtag',
  'headphone',
  'headphones',
  'health',
  'heart-add',
  'heart-circle',
  'heart-edit',
  'heart-remove',
  'heart-search',
  'heart-slash',
  'heart-tick',
  'heart',
  'help',
  'hierarchy-2',
  'hierarchy-3',
  'hierarchy-square-2',
  'hierarchy-square-3',
  'hierarchy-square',
  'hierarchy',
  'home-2',
  'home-hashtag',
  'home-trend-down',
  'home-trend-up',
  'home-wifi',
  'home',
  'hospital',
  'house-2',
  'house',
  'image',
  'import-1',
  'import-2',
  'import',
  'info-circle',
  'information',
  'judge',
  'kanban',
  'key-square',
  'key',
  'keyboard-open',
  'keyboard',
  'lamp-1',
  'lamp-charge',
  'lamp-on',
  'lamp-slash',
  'lamp',
  'language-circle',
  'language-square',
  'layer',
  'level',
  'lifebuoy',
  'like-1',
  'like-dislike',
  'like-shapes',
  'like-tag',
  'like',
  'link-1',
  'link-2',
  'link-21',
  'link-circle',
  'link-square',
  'link',
  'location-add',
  'location-cross',
  'location-minus',
  'location-slash',
  'location-tick',
  'location',
  'lock-1',
  'lock-circle',
  'lock-slash',
  'lock',
  'login-1',
  'login',
  'logout-1',
  'logout',
  'lovely',
  'magic-star',
  'magicpen',
  'main-component-1',
  'main-component',
  'man',
  'map-1',
  'map',
  'mask-1',
  'mask-2',
  'mask-3',
  'mask',
  'math',
  'maximize-1',
  'maximize-2',
  'maximize-21',
  'maximize-3',
  'maximize-4',
  'maximize-circle',
  'maximize',
  'medal-star',
  'medal',
  'menu-1',
  'menu-board',
  'menu',
  'menu-expand2',
  'menu-collapse2',
  'message-2',
  'message-add-1',
  'message-add',
  'message-circle',
  'message-edit',
  'message-favorite',
  'message-minus',
  'message-notif',
  'message-programming',
  'message-question',
  'message-remove',
  'message-search',
  'message-square',
  'message-text-1',
  'message-text',
  'message-tick',
  'message-time',
  'message',
  'messages-1',
  'messages-2',
  'messages-3',
  'messages',
  'microphone-2',
  'microphone-slash-1',
  'microphone-slash',
  'microphone',
  'microscope',
  'milk',
  'mini-music-sqaure',
  'minus-cirlce',
  'minus-square',
  'minus',
  'mirror',
  'mirroring-screen',
  'mobile-programming',
  'mobile',
  'money-2',
  'money-3',
  'money-4',
  'money-add',
  'money-change',
  'money-forbidden',
  'money-recive',
  'money-remove',
  'money-send',
  'money-tick',
  'money-time',
  'money',
  'moneys',
  'monitor-mobbile',
  'monitor-recorder',
  'monitor',
  'moon',
  'more-2',
  'more-circle',
  'more-square',
  'more',
  'more-ic',
  'mouse-1',
  'mouse-circle',
  'mouse-square',
  'mouse',
  'music-circle',
  'music-dashboard',
  'music-filter',
  'music-library-2',
  'music-play',
  'music-playlist',
  'music-square-add',
  'music-square-remove',
  'music-square-search',
  'music-square',
  'music',
  'musicnote',
  'next',
  'note-1',
  'note-2',
  'note-21',
  'note-add',
  'note-favorite',
  'note-remove',
  'note-square',
  'note-text',
  'note',
  'notification-1',
  'notification-bing',
  'notification-circle',
  'notification-favorite',
  'notification-status',
  'notification',
  'omega-circle-1',
  'omega-circle',
  'omega-square-1',
  'omega-square',
  'paintbucket',
  'paperclip-2',
  'paperclip',
  'password-check',
  'path-2',
  'path-square',
  'path',
  'pause-circle',
  'pause',
  'pen-add-1',
  'pen-add',
  'pen-close-1',
  'pen-close',
  'pen-remove-1',
  'pen-remove',
  'pen-tool-1',
  'pen-tool-2-1',
  'pen-tool-2',
  'pen-tool',
  'people',
  'percentage-square',
  'personalcard',
  'pet',
  'pharagraphspacing',
  'picture-frame',
  'play-add',
  'play-circle',
  'play-cricle',
  'play-remove',
  'play',
  'presention-chart',
  'previous',
  'printer-slash',
  'printer',
  'profile-2user',
  'profile-add',
  'profile-circle',
  'profile-delete',
  'profile-remove',
  'profile-tick',
  'profile',
  'programming-arrow',
  'programming-arrows',
  'quote-down-circle',
  'quote-down-square',
  'quote-down',
  'quote-up-circle',
  'quote-up-square',
  'quote-up',
  'radar-1',
  'radar-2',
  'radar',
  'radio',
  'ram-2',
  'ram',
  'ranking-1',
  'ranking',
  'receipt-1',
  'receipt-2-1',
  'receipt-2',
  'receipt-add',
  'receipt-discount',
  'receipt-disscount',
  'receipt-edit',
  'receipt-item',
  'receipt-minus',
  'receipt-search',
  'receipt-square',
  'receipt-text',
  'receipt',
  'receive-square-2',
  'receive-square',
  'received',
  'record-circle',
  'record',
  'recovery-convert',
  'redo',
  'refresh-2',
  'refresh-circle',
  'refresh-left-square',
  'refresh-right-square',
  'refresh-square-2',
  'refresh',
  'repeat-circle',
  'repeat',
  'repeate-music',
  'repeate-one',
  'reserve',
  'rotate-left-1',
  'rotate-left',
  'rotate-right-1',
  'rotate-right',
  'route-square',
  'routing-2',
  'routing',
  'row-horizontal',
  'row-vertical',
  'ruler&pen',
  'ruler',
  'safe-home',
  'sagittarius',
  'save-2',
  'save-add',
  'save-minus',
  'save-remove',
  'scan-barcode',
  'scan',
  'scanner',
  'scanning',
  'scissor-1',
  'scissor-2',
  'scissor',
  'screenmirroring',
  'scroll',
  'search-favorite-1',
  'search-favorite',
  'search-normal-1',
  'search-normal',
  'search-status-1',
  'search-status',
  'search-zoom-in-1',
  'search-zoom-in',
  'search-zoom-out-1',
  'search-zoom-out',
  'security-card',
  'security-safe',
  'security-time',
  'security-user',
  'security',
  'send-1',
  'send-2',
  'send-sqaure-2',
  'send-square',
  'send',
  'setting-2',
  'setting-3',
  'setting-4',
  'setting-5',
  'setting',
  'settings',
  'shapes-1',
  'shapes-2',
  'shapes',
  'share',
  'shield-cross',
  'shield-search',
  'shield-security',
  'shield-slash',
  'shield-tick',
  'shield',
  'ship',
  'shop-add',
  'shop-remove',
  'shop',
  'shopping-bag',
  'shopping-cart',
  'sidebar-bottom',
  'sidebar-left',
  'sidebar-right',
  'sidebar-top',
  'signpost',
  'simcard-1',
  'simcard-2',
  'simcard',
  'size',
  'slash',
  'signature',
  'slider-horizontal-1',
  'slider-horizontal',
  'slider-vertical-1',
  'slider-vertical',
  'slider',
  'smallcaps',
  'smart-car',
  'smart-home',
  'smileys',
  'sms-edit',
  'sms-notification',
  'sms-search',
  'sms-star',
  'sms-tracking',
  'sms',
  'sort',
  'sound',
  'speaker',
  'speedometer',
  'star-1',
  'star-slash',
  'star',
  'status-up',
  'status',
  'sticker',
  'stickynote',
  'stop-circle',
  'stop',
  'story',
  'strongbox-2',
  'strongbox',
  'subtitle',
  'sun-1',
  'sun-fog',
  'sun',
  'tag-2',
  'tag-cross',
  'tag-right',
  'tag-user',
  'tag',
  'task-square',
  'task',
  'teacher',
  'text-block',
  'text-bold',
  'text-italic',
  'text-underline',
  'text',
  'textalign-center',
  'textalign-justifycenter',
  'textalign-justifyleft',
  'textalign-justifyright',
  'textalign-left',
  'textalign-right',
  'tick-circle',
  'tick-square',
  'tick',
  'ticket-2',
  'ticket-discount',
  'ticket-expired',
  'ticket-star',
  'ticket',
  'timer-1',
  'timer-pause',
  'timer-start',
  'timer',
  'toggle-off-circle',
  'toggle-off',
  'toggle-on-circle',
  'toggle-on',
  'trade',
  'transaction-minus',
  'translate',
  'trash',
  'tree',
  'trend-down',
  'trend-up',
  'triangle',
  'truck-remove',
  'truck-tick',
  'truck-time',
  'trush-square',
  'undo',
  'unlimited',
  'unlock',
  'user-add',
  'user-cirlce-add',
  'user-edit',
  'user-minus',
  'user-octagon',
  'user-remove',
  'user-search',
  'user-square',
  'user-tag',
  'user-tick',
  'user',
  'verify',
  'video-add',
  'video-circle',
  'video-horizontal',
  'video-octagon',
  'video-play',
  'video-remove',
  'video-slash',
  'video-square',
  'video-tick',
  'video-time',
  'video-vertical',
  'video',
  'voice-cricle',
  'voice-square',
  'volume-cross',
  'volume-high',
  'volume-low-1',
  'volume-low',
  'volume-mute',
  'volume-slash',
  'volume-up',
  'wallet-1',
  'wallet-2',
  'wallet-3',
  'wallet-add-1',
  'wallet-add',
  'wallet-check',
  'wallet-minus',
  'wallet-money',
  'wallet-remove',
  'wallet-search',
  'wallet',
  'warning-2',
  'watch-status',
  'watch',
  'weight-1',
  'weight',
  'wifi-square',
  'wifi',
  'wind-2',
  'wind',
  'woman',
] as const;

export type IconSaxIconName = typeof iconSaxNames[number];

export type IconSaxIconProps = BaseIconWithAdjustableStrokeProps & {
  /**
   * The name of the icon.
   */
  name: IconSaxIconName;
};
