/* eslint-disable no-shadow */
import { useState, useEffect, useMemo, useCallback } from 'react';
import { theme } from '@/utils/util_theme';

const pixelStringToNum = (pixelString: string): number => {
  return Number(pixelString.substring(0, pixelString.length - 2));
};

enum ResponsiveWidth {
  'sm' = pixelStringToNum(theme?.screens?.sm),
  'md' = pixelStringToNum(theme?.screens?.md),
  'lg' = pixelStringToNum(theme?.screens?.lg),
  'xl' = pixelStringToNum(theme?.screens?.xl),
  '2xl' = pixelStringToNum(theme?.screens?.['2xl']),
}

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const screenWidthKey: keyof typeof ResponsiveWidth = useMemo(() => {
    const { width } = windowDimensions;

    if (width >= ResponsiveWidth['2xl']) {
      return '2xl';
    }

    if (width >= ResponsiveWidth.xl) {
      return 'xl';
    }

    if (width >= ResponsiveWidth.lg) {
      return 'lg';
    }

    if (width >= ResponsiveWidth.md) {
      return 'md';
    }

    return 'sm';
  }, [windowDimensions]);

  const screenIsLargeOrEqualThan = useCallback(
    (screenWidth: keyof typeof ResponsiveWidth | number) => {
      const { width } = windowDimensions;

      if (Object.keys(ResponsiveWidth).includes(String(screenWidth))) {
        return width >= ResponsiveWidth[screenWidth];
      }

      return width >= screenWidth;
    },
    [windowDimensions]
  );

  const screenIsSmallerThan = useCallback(
    (screenWidth: keyof typeof ResponsiveWidth | number) => {
      const { width } = windowDimensions;

      if (Object.keys(ResponsiveWidth).includes(String(screenWidth))) {
        return width < ResponsiveWidth[screenWidth];
      }

      return width < screenWidth;
    },
    [windowDimensions]
  );

  return {
    windowDimensions,
    screenWidthKey,
    ResponsiveWidth,
    screenIsLargeOrEqualThan,
    screenIsSmallerThan,
  };
};

export { useWindowDimensions };
