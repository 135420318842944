import React from 'react';
import { useTranslation } from 'react-i18next';
import { composeClassName } from '@/utils/util_helpers';

import { DEFAULT_CONTENT_WIDTH } from '../../../../../constants';
import { InfoOptionsDesktop } from './components/info-options-desktop';
import { InfoTextDesktop } from './components/info-text-desktop';
import { ThirdSectionContextProvider } from './hooks/use-third-section-context';
import { useWindowDimensions } from '@/hooks/use-window-dimensions';
import { SectionTitle } from '../../../../../components/section-title';
import { InfoOptionsMobile } from './components/info-options-mobile';
import { PagerIndicator } from './components/info-options-mobile/components/pager-indicator';
import { CurrentInfoImage } from './components/current-info-image';

const ThirdSection = () => {
  const { screenIsLargeOrEqualThan } = useWindowDimensions();
  const { t } = useTranslation();

  if (screenIsLargeOrEqualThan('lg')) {
    return (
      <ThirdSectionContextProvider>
        <section className=" relative w-full">
          <div
            className={composeClassName(
              DEFAULT_CONTENT_WIDTH,
              'm-auto bg-white'
            )}
          >
            <div className="mt-4 flex justify-between">
              <InfoTextDesktop />
              <CurrentInfoImage />
            </div>
            <div className="mt-5 mb-[10.5rem] w-full">
              <InfoOptionsDesktop />
            </div>
          </div>
        </section>
      </ThirdSectionContextProvider>
    );
  }

  return (
    <ThirdSectionContextProvider>
      <section className="relative w-full bg-white">
        <div className={composeClassName(DEFAULT_CONTENT_WIDTH, 'm-auto')}>
          <SectionTitle
            className={composeClassName('mt-[8.7rem]', 'md:mt-[18.8rem]')}
            subTitle={t('js.features.marketplace.home.section3.subtitle') ?? ''}
          >
            {t('js.features.marketplace.home.section3.title')}
          </SectionTitle>
        </div>
        <InfoOptionsMobile />
        <div
          className={composeClassName(
            DEFAULT_CONTENT_WIDTH,
            'm-auto flex items-center justify-center pb-8',
            '[@media(min-width:500px)]:pb-10'
          )}
        >
          <PagerIndicator />
        </div>
      </section>
    </ThirdSectionContextProvider>
  );
};

export { ThirdSection };
