import React from 'react';
import { useThirdSectionContext } from '../../hooks/use-third-section-context';
import { InfoOption } from './components/info-option';

const InfoOptionsDesktop = () => {
  const { options } = useThirdSectionContext()!;

  return (
    <div className="w-full">
      <div className="flex w-full gap-4">
        {options.slice(0, 4).map((value, index) => {
          return <InfoOption key={value} num={index + 1} option={value} />;
        })}
      </div>
      <div className="mt-7 flex w-full gap-4">
        {options.slice(4, 8).map((value, index) => {
          return <InfoOption key={value} num={index + 5} option={value} />;
        })}
      </div>
    </div>
  );
};

export { InfoOptionsDesktop };
