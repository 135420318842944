<template>
  <div class="modal" :class="{ open: shown }">
    <div class="modal__backdrop"></div>
    <div class="modal__content--alert" style="width: 485px; height: 570px" v-show="!showConfirmationDialog && !showSuccessDialog">
      <form @submit.prevent="submitForm">
        <div class="modal__main">
          <div class="modal__body" style="text-align: left;">
            <h3 class="mb-3">{{ t('components.activation_dialog.heading') }}</h3>
            <p v-html="t('components.activation_dialog.note')"></p>

            <div class="my-2 p-3" style="text-align: center; background-color: #f7f8fa">
              <div class="mb-2"><b>{{ t('components.activation_dialog.security_deposit.amount') }}</b></div>
              <div>{{ numberToCurrency(deposit.amount) }}</div>
            </div>

            <div class="custom-input mt-5" :class="{ 'custom-input__input--is-invalid': !isAmountPaidValid }">
              <label class="custom-input__label" for="security_deposit_amount_paid">
                {{ t('components.activation_dialog.security_deposit.amount_paid') }}
              </label>
              <input v-model="deposit.amountPaid" class="custom-input__input" type="text" id="security_deposit_amount_paid">
            </div>

            <div class="custom-input mt-5">
              <label class="custom-input__label" for="security_deposit_onboarding_fee_paid">
                {{ t('components.activation_dialog.onboarding_fee_paid') }}
              </label>
              <div class="d-flex mx-auto mt-3">
                <div class="form-check form-check-inline mr-3">
                  <input type="radio" id="onboarding_fee_paid_true" class="form-check-input" v-model="contract.onboarding_fee_paid" :value="true">
                  <label class="form-check-label" for="onboarding_fee_paid_true">
                    {{ t('globals.yes') }}
                  </label>
                </div>
                <div class="form-check form-check-inline mr-3">
                  <input type="radio" id="onboarding_fee_paid_false" class="form-check-input" v-model="contract.onboarding_fee_paid" :value="false">
                  <label class="form-check-label" for="onboarding_fee_paid_false">
                    {{ t('globals.no') }}
                  </label>
                </div>
              </div>
            </div>

            <div class="custom-input mt-5">
              <label class="custom-input__label" for="security_deposit_payment_medium">
                {{ t('components.activation_dialog.security_deposit.payment_medium') }}
              </label>

              <div class="d-flex mx-auto mt-3">
                <div v-for="(name, value) in paymentMediums" class="form-check form-check-inline mr-3">
                  <input type="radio" :id="`security_deposit_payment_medium_${value}`"
                    class="form-check-input"
                    v-model="deposit.paymentMedium" :value="value">
                  <label class="form-check-label" :for="`security_deposit_payment_medium_${value}`">
                    {{ name }}
                  </label>
                </div>
              </div>
            </div>

            <div class="custom-input my-5">
              <label class="custom-input__label" for="security_deposit_storage">
                {{ t('components.activation_dialog.security_deposit.storage') }}
              </label>

              <div class="d-inline-flex flex-wrap mx-auto mt-3" style="row-gap: 10px">
                <div v-for="(name, value) in storages" class="form-check form-check-inline mr-3">
                  <input type="radio" :id="`security_deposit_storage_${value}`"
                    class="form-check-input" v-model="deposit.storage"
                    :value="value">
                  <label class="form-check-label" :for="`security_deposit_storage_${value}`">
                    {{ name }}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="modal__footer--content-c justify-content-start mt-5">
            <button type="submit" class="btn--success" :disabled="!isFormValid">
              {{ t('components.activation_dialog.activate') }}
            </button>
            <button class="btn--gray" @click.prevent="hideModal">
              {{ t('labels.cancel') }}
            </button>
          </div>
        </div>
      </form>
    </div>

    <div class="modal__content--alert" style="width: 485px; height: 200px" v-if="showConfirmationDialog">
      <div class="modal__main">
        <div class="modal__body" style="text-align: left;">
          <h3 class="mb-3">
            {{ t('components.activation_dialog.confirmation_dialog.heading') }}
          </h3>
          <p v-html="t('components.activation_dialog.confirmation_dialog.note')"></p>

          <div class="modal__footer--content-c justify-content-start mt-5">
            <button type="submit" class="btn--success" @click="activateContract" :disabled="loading">
              {{ t('components.activation_dialog.activate') }}
            </button>
            <button class="btn--gray" @click="showConfirmationDialog = false" :disabled="loading">
              {{ t('labels.cancel') }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal__content--alert" style="width: 485px; height: 210px" v-if="showSuccessDialog">
      <div class="modal__main">
        <div class="modal__body" style="text-align: left;">
          <h3 class="mb-3">
            {{ t('components.activation_dialog.success_dialog.heading') }}
          </h3>
          <p v-html="t('components.activation_dialog.success_dialog.note')"></p>

          <div class="modal__footer--content-c mt-5">
            <button type="button" class="btn--success" @click="done">
              {{ t('components.activation_dialog.success_dialog.done') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Rails from '@rails/ujs';
import i18n from '../../../vue_mixins/i18n';
import { numberToCurrency } from 'src/utils/util_helpers/formatting/amount_to_string/index.ts'

export default {
  mixins: [i18n],
  props: {
    initialContract: {
      type: Object,
      required: true,
    },
    initialSecurityDeposit: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      loading: false,
      shown: false,
      showConfirmationDialog: false,
      showSuccessDialog: false,
      contract: {
        onboarding_fee_paid: Boolean(this.initialContract.onboarding_fee_paid_on)
      },
      deposit: {
        amount: this.initialSecurityDeposit.amount,
        amountPaid: this.initialSecurityDeposit.amount_paid,
        paymentMedium: this.initialSecurityDeposit.payment_medium,
        storage: this.initialSecurityDeposit.storage
      },
    };
  },
  methods: {
    showModal() {
      this.shown = true;
    },
    hideModal() {
      this.shown = false;
    },
    submitForm() {
      if (this.isFormValid) {
        this.showConfirmationDialog = true
      }
    },
    async activateContract() {
      try {
        this.loading = true;

        await axios.patch(`/admin/contracts/${this.initialContract.id}/timeline`, {
          contract_attributes: {
            onboarding_fee_paid: this.contract.onboarding_fee_paid
          },
          security_deposit_attributes: {
            amount_paid: this.deposit.amountPaid,
            payment_medium: this.deposit.paymentMedium,
            storage: this.deposit.storage
          }
        }, {
          headers: {
            'Accept': 'application/json',
            'X-CSRF-Token': Rails.csrfToken()
          }
        })

        this.showConfirmationDialog = false
        this.showSuccessDialog = true
      } catch (error) {
        window.location.reload()
      }
    },
    done() {
      window.location.reload()
    },
    numberToCurrency
  },
  computed: {
    isAmountPaidValid() {
      return Number(this.deposit.amountPaid) > 0;
    },
    isFormValid() {
      return this.isAmountPaidValid
    },
    paymentMediums() {
      return {
        bank_transfer: 'Bank transfer',
        cash: 'Cash',
        stripe: 'Stripe'
      }
    },
    storages() {
      return {
        coloc: 'Coloc deposit',
        landlord: 'Landlord account',
        block_saving_account: 'Block saving account',
        edepo: 'E-Depo',
        korfine: 'Korfine'
      }
    }
  },
};
</script>
