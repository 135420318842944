import Rails from '@rails/ujs';
import { AxiosRequestConfig, RawAxiosRequestHeaders } from 'axios';
import { COLOC_APP_API_TIMEOUT_MS } from '@/config';

export type ColocAPIPaginationParams = {
  page?: number;
  page_limit?: number;
};

export type ColocAPIDateRangeParams = {
  start_date?: Date;
  end_date?: Date;
};

export type ColocAPISortDirections = 'ascending' | 'descending';

export type ColocAPISortParams = {
  sort_direction?: ColocAPISortDirections;
  sort?: string;
};

export type ColocAPIPaginationResponse = {
  pagination: {
    page: number;
    page_limit: number;
    total: number;
    total_pages: number;
  };
};

export type ColocAPISortDirectionResponse = {
  sort_direction: ColocAPISortDirections;
};

export const getColocApiCallHeaders = (): RawAxiosRequestHeaders => {
  return {
    Accept: 'application/json',
    'X-CSRF-Token': Rails.csrfToken(),
  };
};

export const getColocApiCallProperties = (): AxiosRequestConfig => {
  return {
    headers: getColocApiCallHeaders(),
    timeout: COLOC_APP_API_TIMEOUT_MS,
    withCredentials: true,
  };
};
