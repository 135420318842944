import { useQuery } from '@tanstack/react-query';

import { getTenantsForRoom } from '@/services/coloc-api/admin';

const useGetTenantsForRoom = (params: { selectedRoomId: number }) => {
  const fetch = useQuery({
    queryKey: ['getTenantsFrorRoom', params.selectedRoomId],
    queryFn: () =>
      getTenantsForRoom({
        room_id: params.selectedRoomId,
      }),
    keepPreviousData: true,
    enabled: params.selectedRoomId !== 0,
  });

  return {
    fetch,
  };
};

export { useGetTenantsForRoom };
