<template>
  <div class="inset-x-0 fixed z-50 w-full bg-white p-4 shadow-medium">
    <div class="m-auto flex w-full max-w-8xl items-center justify-between">
      <a href="/" data-turbolinks="false">
        <Logo />
      </a>

      <div class="flex items-center space-x-4">
        <LocaleSwitcher />

        <button
          @click="GetStarted"
          class="
            rounded-md
            h-[40px]
            w-[110px]
            border border-primary-100
            bg-white
            text-sm
            font-bold
            text-primary-100
            hover:bg-primary-100
            hover:text-white
            lg:h-[60px]
            lg:w-[194px]
            lg:text-lg
          "
        >
          {{ t('vue_components.spot_a_house.get_started') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from './logo/Logo.vue';
import LocaleSwitcher from './LocaleSwitcher.vue';

export default {
  components: {
    Logo,
    LocaleSwitcher,
  },
  methods: {
    GetStarted() {
      // scroll to ContactUs id
      window.scrollTo({
        top: document.getElementById('ContactUs').offsetTop,
        behavior: 'smooth',
      });
    },
  },
};
</script>
