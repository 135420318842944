import { useQuery } from '@tanstack/react-query';
import { GetRentReportVersionsParams } from '@/lib/coloc-api/admin';
import { getRentReportVersions } from '@/services/coloc-api/admin';

const useGetRentReportVersions = (params: GetRentReportVersionsParams) => {
  const fetch = useQuery({
    queryKey: ['getRentReportVersions', params],
    queryFn: () => getRentReportVersions(params),
    keepPreviousData: true,
  });

  return {
    fetch,
  };
};

export { useGetRentReportVersions };
