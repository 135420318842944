import { useMemo, useState } from 'react';

type DownloadFileType = 'pdf' | 'csv';

type DownloadPDFParams = {
  url: string;
  filename: string;
  filetype: DownloadFileType;
  onCompletion?: () => void;
  onError?: () => void;
};

type DownloadPDFHook = (
  params: DownloadPDFParams
) => [boolean, () => void, number | null];

const useDownloadFile: DownloadPDFHook = ({
  url,
  filename,
  filetype,
  onCompletion,
  onError,
}) => {
  const [progress, setProgress] = useState<number | null>(null);
  const [downloadingFile, toggleDownloading] = useState(false);

  const fileTypeString = useMemo(() => {
    switch (filetype) {
      case 'pdf':
        return 'application/pdf';
      default:
        return 'text/csv';
    }
  }, [filetype]);

  const downloadFile = () => {
    toggleDownloading(true);

    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';

    xhr.addEventListener('progress', (event) => {
      if (event.lengthComputable) {
        const percentComplete = (event.loaded / event.total) * 100;
        setProgress(percentComplete);
      }
    });

    xhr.addEventListener('load', (e) => {
      if (xhr.status === 200) {
        const blob = new Blob([xhr.response], { type: fileTypeString });
        const newUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = newUrl;
        a.download = `${filename}.${filetype}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(newUrl);
        onCompletion && onCompletion();
      } else {
        onError && onError();
      }

      toggleDownloading(false);
      setProgress(null);
    });

    xhr.send();
  };

  return [downloadingFile, downloadFile, progress];
};

export { useDownloadFile };
