import React, { useId } from 'react';
import { Checkbox } from '../../../../atoms/checkbox';
import { TableHeaderSelectionCellProps } from './types';

const TableHeaderSelectionCell: React.FC<TableHeaderSelectionCellProps> = ({
  cellProps,
  ...props
}) => {
  const id = useId();

  return (
    <th className="py-[10px] px-3 align-middle" {...cellProps}>
      <Checkbox id={id} {...props} size="large" />
    </th>
  );
};

export { TableHeaderSelectionCell };
