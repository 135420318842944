import React from 'react';

import { OutgoingTransactionReceiverCellContentProps } from './types';
import { Table } from '@/components/design-system/molecules/table';

// TODO: Add more logic once refunds are implemented. Room number as in design
const OutgoingTransactionReceiverCellContent: React.FC<OutgoingTransactionReceiverCellContentProps> =
  ({ transaction }) => {
    return (
      <Table.TableCellDefaultContent description={transaction.destination.iban}>
        {transaction.destination.account_holder}
      </Table.TableCellDefaultContent>
    );
  };

export { OutgoingTransactionReceiverCellContent };
