import { BadgeSizes, BadgeVariants } from './types';

const variantStyles: { [key in BadgeVariants]: string } = /* tw */ {
  normal: 'bg-[##F8F9FA] text-[#212529]  border-[#CED4DA] ',
  info: 'bg-Q50 text-Q300  border-Q300',
  success: 'bg-J50 text-J300  border-J300',
  warning: 'bg-R50 text-R500  border-R500',
  danger: 'bg-C50 text-C300  border-C300',
};

const sizeStyles: { [key in BadgeSizes]: string } = /* tw */ {
  small: 'py-[0.3rem] px-[1rem] text-footnote2',
  medium: 'py-[0.4rem] px-[1rem] text-body',
  large: 'py-[0.7rem] px-[1.2rem] text-body font-medium',
};

export { variantStyles, sizeStyles };
