import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.collapseSidebar();
  }

  collapseSidebar() {
    document.body.dataset.sidebar = 'collapsed';
  }
}
