const requireBWIcons = require.context(
  '../../../../../../../assets/icons/social-icons/bw',
  false,
  /.svg$/
);

const requireOGIcons = require.context(
  '../../../../../../../assets/icons/social-icons/og',
  false,
  /.svg$/
);

const SocialIcons = {};

requireBWIcons.keys().forEach((fileName) => {
  const iconName = fileName.replace(/^\.\/(.+)-bw.svg$/, '$1');
  const componentConfig = requireBWIcons(fileName);
  SocialIcons[iconName] = componentConfig.default || componentConfig;
});

requireOGIcons.keys().forEach((fileName) => {
  const iconName = fileName.replace(/^\.\/(.+)-og.svg$/, '$1');
  const componentConfig = requireOGIcons(fileName);
  SocialIcons[iconName] = componentConfig.default || componentConfig;
});

export default SocialIcons;
