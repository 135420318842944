import React, { ReactElement } from 'react';
import { BaseComponent } from '../../../../types/global';

export const CELL_HEIGHT = 54;

export type TableProps = BaseComponent &
  React.AriaAttributes & {
    /**
     * Props to pass to nested table element.
     */
    tableProps?: React.TableHTMLAttributes<HTMLTableElement>;
    children?: ReactElement | ReactElement[];
    /**
     * class name for wrapper around the table content (Table itself + pagination).
     */
    tableContentClassName?: string;
    innerClassName?: string;
  };

export type TableColumnSortingOptions = 'asc' | 'desc' | 'none';

export type SortingProps = {
  /**
   * Applied sorting direction of column.
   */
  sortingOrder?: TableColumnSortingOptions;
};

type PropsWithoutSorting<TBaseProps> = Omit<TBaseProps, keyof SortingProps>;

export function withoutSortingProps<TProps extends SortingProps>(
  WrappedComponent: React.ComponentType<
    PropsWithoutSorting<TProps> & SortingProps
  >
) {
  // eslint-disable-next-line react/display-name
  return (props: PropsWithoutSorting<TProps>) => {
    return <WrappedComponent {...props} />;
  };
}

export type TableSlots = {
  pagination: ReactElement | null;
  content: ReactElement | null;
  actions: ReactElement | null;
};
