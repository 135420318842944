import React from 'react';
import { DropDown } from '@/components/design-system/molecules/dropdown';
import { useRoomAssignmentListFiltersContext } from '../../../../hooks/use-get-room-assignment-list-filter';

const rentalTypeList = [
  {
    slug: 'new',
    displayValue: 'New Launch',
  },
  {
    slug: 're-rental',
    displayValue: 'Re-Rental',
  },
  {
    slug: 'relaunch',
    displayValue: 'Relaunch',
  }
];

const RentalTypeFilter = () => {
  const { rentalType, setRentalType } = useRoomAssignmentListFiltersContext()!;

  return (
    <DropDown
      placeholder="All"
      className="z-20 w-[172px]"
      showLabel
      label="Rental Type"
      value={rentalType}
      {...(rentalType && {
        displayValue: rentalType.displayValue,
      })}
      onChange={setRentalType}
    >
      <DropDown.DropDownOptions>
        {rentalTypeList.map((option) => {
          return (
            <DropDown.DropDownOption key={option.displayValue} value={option}>
              <DropDown.DropDownOptionDefaultContent>
                {option.displayValue}
              </DropDown.DropDownOptionDefaultContent>
            </DropDown.DropDownOption>
          );
        })}
      </DropDown.DropDownOptions>
    </DropDown>
  );
};

export { RentalTypeFilter };
