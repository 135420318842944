
import React, {
  createContext,
  useContext,
  useState,
} from 'react';
import { useMutation } from '@tanstack/react-query';
import { useGetOutgoingTransactionsListContext } from '../use-get-outgoing-transactions';
import {
  CancelPayoutParams,
  CancelPayoutResponse,
  cancelPayout
} from '@/lib/coloc-api/admin';
import { showToast } from '@/services/toast-service';

const useCancelOutgoingTransaction = () => {
  const { updateOutgoingTransactionsInCache } =
    useGetOutgoingTransactionsListContext()!;

  const mutate = useMutation({
    mutationKey: ['cancelPayout'],
    mutationFn: (params: CancelPayoutParams) => cancelPayout(params),
    onSuccess: (response: CancelPayoutResponse) => {
      showToast.success({
        text: 'Outgoing transaction cancelled successfully',
      });
      updateOutgoingTransactionsInCache([response.data]);
    },
    onError: (error: any) => {
      showToast.error({
        text: error.message,
      });
    },
  });

  return {
    mutate
  };


};

const CancelOutgoingTransactionContext = createContext<
  ReturnType<typeof useCancelOutgoingTransaction> | undefined
>(undefined);

const CancelOutgoingTransactionContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const hook = useCancelOutgoingTransaction();

  return (
    <CancelOutgoingTransactionContext.Provider value={hook}>
      {children}
    </CancelOutgoingTransactionContext.Provider>
  );
};

const useCancelOutgoingTransactionContext = () => {
  const context = useContext(CancelOutgoingTransactionContext)!;

  if (context === null) {
    throw new Error(
      'useCancelOutgoingTransactionContext must be used within a CancelOutgoingTransactionContext'
    );
  }

  return context;
};

export {
  CancelOutgoingTransactionContextProvider,
  useCancelOutgoingTransactionContext,
};
