import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@/components/design-system/atoms/modal';
import { ChangeRoomAvailabilityProps } from './types';
import { DatePicker } from '@/components/design-system/molecules/datepicker';
import { Button } from '@/components/design-system/atoms/button';
import { Checkbox } from '@/components/design-system/atoms/checkbox';
import { useUpdateRoomAvailability } from '../../hooks/use-update-room-availability';
import { getFullDate } from '@/utils/util_helpers/formatting/dates';

const ChangeRoomAvailabilityModal: React.FC<ChangeRoomAvailabilityProps> = ({
  open,
  onClose,
  availability_date,
  end_of_notice_period,
  room,
}) => {
  const { t } = useTranslation();
  const [selectedAsUnavailable, setSelectedAsUnavailable] = useState<boolean>(
    !availability_date
  );
  const [selectedDate, setSelectedDate] = useState<Date>(
    // eslint-disable-next-line no-nested-ternary
    availability_date
      ? new Date(availability_date)
      : end_of_notice_period
      ? new Date(end_of_notice_period)
      : new Date()
  );
  const { mutate: changeDate } = useUpdateRoomAvailability()!;

  return (
    <Modal open={open} onClose={onClose}>
      <div className="overflow-hidden rounded-lg bg-white shadow-medium">
        <div className="flex w-full overflow-hidden">
          <div className="flex flex-col gap-5 bg-white p-7">
            <div className="inline-flex flex-col">
              <span className="text-lead2 font-bold text-text-primary">
                {t(
                  'js.features.admin.contracts.change_room_availability_modal.title'
                )}
              </span>
              <span className="text-body text-text-primaryMedium">
                {`#${room.room_number} - ${room.house.name}`}
              </span>
            </div>
            <div className="flex w-[320px] flex-col gap-4">
              <DatePicker
                label={t(
                  'js.features.admin.contracts.change_room_availability_modal.availability_date'
                )}
                type="date"
                value={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                disabled={selectedAsUnavailable}
              />
              <Checkbox
                id="make-unavailable"
                label={
                  t(
                    'js.features.admin.contracts.change_room_availability_modal.unavailable'
                  ) ?? ''
                }
                checked={selectedAsUnavailable}
                onChange={(e) => setSelectedAsUnavailable(e.target.checked)}
              />
            </div>
            <div className="flex items-center gap-4">
              <Button
                variant="primary"
                disabled={
                  !(selectedDate || selectedAsUnavailable) ||
                  changeDate.isLoading
                }
                onClick={() => {
                  changeDate.mutate({
                    room_id: room.id,
                    ...(selectedDate &&
                      !selectedAsUnavailable && {
                        date: getFullDate(selectedDate),
                      }),
                  });
                }}
              >
                {t(
                  'js.features.admin.contracts.change_room_availability_modal.update_button'
                ) ?? ''}
              </Button>
              <Button
                variant="ghost"
                onClick={() => {
                  onClose && onClose();
                }}
              >
                {t(
                  'js.features.admin.contracts.change_room_availability_modal.cancel_button'
                ) ?? ''}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { ChangeRoomAvailabilityModal };
