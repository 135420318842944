import React, { forwardRef, useId } from 'react';
import { DropDownOptionDefaultContentProps } from './types';
import { composeClassName } from '../../../../../../utils/util_helpers';
import { useDropDownContext } from '../../hooks/use-dropdown-context';
import { Checkbox } from '../../../../atoms/checkbox';

const DropDownOptionDefaultContent = forwardRef<
  HTMLDivElement,
  DropDownOptionDefaultContentProps
>(
  (
    {
      children,
      disabled = false,
      active = false,
      selected = false,
      contentBefore,
      className,
      description,
    },
    ref
  ) => {
    const id = useId();
    const { multiple } = useDropDownContext()!;

    return (
      <div
        ref={ref}
        className={composeClassName(
          'relative flex w-full items-center py-2',
          active && !selected && !disabled && 'bg-E30',
          selected && !active && !multiple && !disabled && 'bg-R100',
          selected && active && multiple && !disabled && 'bg-R50',
          active && selected && !multiple && !disabled && 'bg-R300',
          disabled && 'bg-E30',
          className && className
        )}
      >
        {multiple && (
          <Checkbox
            id={id}
            checked={selected}
            size="small"
            className="pl-3"
            disabled={disabled}
            readOnly
          />
        )}
        {contentBefore && (
          <div className=" flex h-full items-center pl-3 ui-disabled:grayscale-[80%] ui-disabled:[&_svg>path]:stroke-E75">
            {contentBefore}
          </div>
        )}
        <div
          className="line-clamp-2 flex flex-col flex-wrap break-words px-3 text-body text-text-primary"
          style={{ whiteSpace: 'initial' }}
        >
          <span
            className={composeClassName(
              'text-text-primary ui-disabled:text-text-tertiary'
            )}
          >
            {children}
          </span>
          {description && (
            <span className="text-text-tertiary">{description}</span>
          )}
        </div>
      </div>
    );
  }
);

DropDownOptionDefaultContent.displayName = 'DropDownOptionDefaultContent';

export { DropDownOptionDefaultContent };
