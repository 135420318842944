import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@/components/design-system/atoms/modal';
import { ChangeMoveInProps } from './types';
import { DatePicker } from '@/components/design-system/molecules/datepicker';
import { Button } from '@/components/design-system/atoms/button';
import { useUpdateMoveIn } from '../../hooks/use-update-move-in';
import { getFullDateAPIFormat } from '@/utils/util_helpers/formatting/dates';
import { endOfMonth, subMonths } from 'date-fns';
import { SuccessContent } from './SuccessContent'

const ChangeMoveInModal: React.FC<ChangeMoveInProps> = ({
  open,
  onClose,
  applicant,
  status,
  assignment,
}) => {
  const { room } = assignment;
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    applicant.contract?.move_in_date
      ? new Date(applicant.contract.move_in_date)
      : undefined
  );
  const { mutate: changeDate } = useUpdateMoveIn()!;

  const minDate = useMemo(() => {
    const endOfLastMonth = endOfMonth(subMonths(new Date(), 1));
    const moveInDate = new Date(assignment.availability_date);
    return moveInDate > endOfLastMonth ? moveInDate : endOfLastMonth;
  }, [assignment]);

  return (
    <Modal open={open} onClose={onClose}>
      <div className="overflow-hidden rounded-lg bg-white shadow-medium">
        <div className="flex w-full overflow-hidden">
          <div className="flex flex-col gap-5 bg-white p-7">
            { status === 'success' ? (
              <SuccessContent
                text={
                `${applicant.user.name}\n#${room.room_number} - ${room.house.name}`
                }
              />

            ) : (
              <>
                <div className="inline-flex flex-col">
                  <span className="text-lead2 font-bold text-text-primary">
                    {t('js.features.admin.contracts.change_move_in_modal.title')}
                  </span>
                  <span className="text-body text-text-primaryMedium">
                    {applicant.user.name}
                  </span>
                  <span className="text-body text-text-primaryMedium">
                    {`#${room.room_number} - ${room.house.name}`}
                  </span>
                </div>
                <div className="flex w-[320px] flex-col gap-4">
                  <DatePicker
                    label={t(
                      'js.features.admin.contracts.change_move_in_modal.move_in_date'
                    )}
                    type="date"
                    value={selectedDate}
                    minDate={minDate}
                    onChange={(date) => setSelectedDate(date)}
                  />
                </div>
                <div className="flex items-center gap-4">
                  <Button
                    variant="primary"
                    disabled={!selectedDate || changeDate.isLoading}
                    onClick={() => {
                      changeDate.mutate({
                        contract_id: applicant.contract.id,
                        ...(selectedDate && {
                          date: getFullDateAPIFormat(selectedDate),
                        }),
                      })
                    }}
                  >
                    {t(
                      'js.features.admin.contracts.change_move_in_modal.update_button'
                    ) ?? ''}
                  </Button>
                  <Button
                    variant="ghost"
                    onClick={() => {
                      onClose && onClose();
                    }}
                  >
                    {t(
                      'js.features.admin.contracts.change_move_in_modal.cancel_button'
                    ) ?? ''}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

    </Modal>
  );
};

export { ChangeMoveInModal };
