import React from 'react';
import { useTranslation } from 'react-i18next';
import { SectionTitle } from '@/features/marketplace/components/section-title';
import { DEFAULT_CONTENT_WIDTH } from '@/features/marketplace/constants';
import { composeClassName } from '@/utils/util_helpers';
import { Presentation } from './components/presentation';
import { PageControlButton } from '@/features/marketplace/components/page-control-button';
import {
  PresentationContextProvider,
  usePresentationContext,
} from './hooks/use-presentation-context';
import { useWindowDimensions } from '@/hooks/use-window-dimensions';

const FourthSectionContent = () => {
  const {
    showNextSlide,
    showPreviousSlide,
    nextSlideAvailable,
    previousSlideAvailable,
    numPages,
    pageNumber,
  } = usePresentationContext()!;
  const { screenIsLargeOrEqualThan, screenIsSmallerThan } =
    useWindowDimensions();
  const { t } = useTranslation();

  return (
    <div
      className={composeClassName(
        DEFAULT_CONTENT_WIDTH,
        'm-auto mt-8',
        'md:mt-10',
        'lg:mt-[13rem]'
      )}
    >
      <div className="flex w-full items-center justify-between">
        <SectionTitle>
          {t('js.features.marketplace.press.section4.title')}
        </SectionTitle>
        {screenIsLargeOrEqualThan('md') && (
          <div className="flex gap-4">
            <PageControlButton
              variant="previous"
              disabled={!previousSlideAvailable}
              onClick={() => showPreviousSlide()}
            />
            <PageControlButton
              disabled={!nextSlideAvailable}
              variant="next"
              onClick={() => showNextSlide()}
            />
          </div>
        )}
      </div>
      <Presentation />
      {screenIsSmallerThan('md') && (
        <div className="mb-6 flex w-full items-center justify-between">
          <PageControlButton
            variant="previous"
            disabled={!previousSlideAvailable}
            onClick={() => showPreviousSlide()}
          />
          <span className="text-lead2 text-text-secondary ">
            {t(`js.features.marketplace.press.section4.slide_indicator`, {
              current_slide: pageNumber,
              num_slides: numPages,
            })}
          </span>
          <PageControlButton
            disabled={!nextSlideAvailable}
            variant="next"
            onClick={() => showNextSlide()}
          />
        </div>
      )}
    </div>
  );
};

const FourthSection = () => {
  return (
    <section>
      <PresentationContextProvider>
        <FourthSectionContent />
      </PresentationContextProvider>
    </section>
  );
};

export { FourthSection };
