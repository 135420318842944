import React, { isValidElement } from 'react';
import { Combobox } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { composeClassName } from '../../../../../../utils/util_helpers';
import { DropDownInputProps } from './types';
import { useDropDownContext } from '../../hooks/use-dropdown-context';
import { useDropDownHasValue } from '../../hooks/use-dropdown-has-value';
import { TextInputContentBeforeWrapper } from '../../../../atoms/text/sub-components/text-input-content-before-wrapper';

const DropDownInput: React.FC<DropDownInputProps> = ({
  className,
  contentBefore,
}) => {
  const {
    onTextInputChange,
    setInputFocused,
    displayValue,
    disabled,
    placeholder,
    optionsOpen,
    id,
  } = useDropDownContext()!;
  const value = useDropDownHasValue();
  const { t } = useTranslation();

  function empty() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    undefined;
  }

  return (
    <>
      <TextInputContentBeforeWrapper disabled={disabled}>
        {contentBefore}
      </TextInputContentBeforeWrapper>
      <Combobox.Input
        className={composeClassName(
          'w-full border-none bg-inherit text-body outline-none placeholder:text-text-tertiary',
          !disabled && 'text-text-primary',
          disabled && '!text-E75',
          isValidElement(contentBefore) &&
            '!pl-[calc(theme(padding.7)_+_theme(padding.2))]',
          className && className
        )}
        placeholder={
          !value
            ? placeholder ?? t('js.components.dropdown.placeholder')
            : undefined
        }
        onChange={onTextInputChange ?? empty}
        onFocus={(e: any) => {
          setInputFocused(true);
          /**  Headless UI does not shown it's options by default by focusing on the input.
           * This method searches for the combobox button and clicks it to trigger showing them.
           * GH issue: https://github.com/tailwindlabs/headlessui/discussions/1236
           */
          if (!e.relatedTarget) {
            const buttonElement: HTMLElement = document?.getElementById(
              id
            ) as HTMLElement;
            if (!buttonElement) return;

            !optionsOpen && buttonElement.click();
          }
        }}
        onBlur={() => setInputFocused(false)}
        {...(displayValue && { displayValue: () => displayValue })}
      />
    </>
  );
};

export { DropDownInput };
