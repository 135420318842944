import React, { useMemo } from 'react';
import { ReactSVG } from 'react-svg';
import { composeClassName } from '@/utils/util_helpers';
import { SlideOverEmptyStateProps } from './types';

const SlideOverEmptyState: React.FC<SlideOverEmptyStateProps> = ({
  className,
  illustration,
  title,
  text,
  actionButton,
}) => {
  const iconName = useMemo(() => {
    switch (illustration) {
      default:
        return 'no-results';
    }
  }, [illustration]);

  return (
    <div
      className={composeClassName(
        'inline-flex flex-1 flex-col items-center justify-center',
        className && className
      )}
    >
      <ReactSVG
        data-testid={iconName}
        src={require(`@assets/icons/empty-states/${iconName}.svg`)}
        className={composeClassName('inline-block align-baseline')}
        beforeInjection={(svg) => {
          svg.setAttribute('width', `${132}`);
          svg.setAttribute('height', `${132}`);
        }}
      />
      <span
        className={composeClassName(
          'pt-4 text-h5 font-medium text-text-primaryMedium'
        )}
      >
        {title}
      </span>
      {text && (
        <span className="pt-2 text-lead2 text-text-primaryLight">{text}</span>
      )}
      <div className="pt-4">{actionButton && actionButton}</div>
    </div>
  );
};

export { SlideOverEmptyState };
