import { Controller } from 'stimulus';

export default class extends Controller {
  replaceValue({ currentTarget }) {
    const { inputTarget } = currentTarget.dataset;
    const inputValue = currentTarget.dataset.inputValue || currentTarget.value;

    document.querySelectorAll(inputTarget).forEach((el) => {
      el.value = inputValue;
      el.form.submit();
    });
  }
}
