import { useMutation } from '@tanstack/react-query';
import {
  MakeTransferParams,
  MakeTransferRequestBody,
  makeTransfer,
} from '@/lib/coloc-api/admin';
import { showToast } from '@/services/toast-service';
import { useGetRentsListContext } from '../../hooks/use-get-rents-list';

const useMakeTransfer = () => {
  const { toggleShowMakeTransferModal, updateRentInCache } =
    useGetRentsListContext()!;

  const mutate = useMutation({
    mutationKey: ['makeTransferTransaction'],
    mutationFn: (params: MakeTransferParams & MakeTransferRequestBody) => {
      return makeTransfer(
        {
          rent_id: params.rent_id,
        },
        { transaction: params.transaction }
      );
    },
    onSuccess: (data) => {
      updateRentInCache(data.data.source_rent);
      updateRentInCache(data.data.destination_rent);

      toggleShowMakeTransferModal({ show: false, rent: undefined });
    },
    onError: (error) => {
      const keys = Object.keys(error.response.data.errors);
      keys.forEach((key: string) => {
        const message = error.response.data.errors[key];
        showToast.error({
          title: key,
          text: [message].join(', '),
        });
      });
    },
  });

  return {
    mutate,
  };
};

export { useMakeTransfer };
