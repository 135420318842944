import React, { useMemo } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { composeClassName } from '@/utils/util_helpers';
import { DEFAULT_CONTENT_WIDTH } from '../../constants';
import { getFullYearNumber } from '@/utils/util_helpers/formatting/dates';
import { SocialIcon } from '@/components/design-system/atoms/icon';
import { theme } from '@/utils/util_theme';
import { useWindowDimensions } from '@/hooks/use-window-dimensions';
import { COLOC_MAIN_APP } from '@/config';
import { useLocale } from '@/hooks/use-locale';

const CompanyIcon = () => {
  return (
    <div>
      <ReactSVG
        beforeInjection={(svg) => {
          svg.setAttribute('width', `99`);
          svg.setAttribute('height', `66`);
        }}
        className={composeClassName('inline-block align-baseline')}
        src={require(`@assets/images/marketplace/footer/coloc_logo.svg`)}
      />
    </div>
  );
};

const CompanyInfo = () => {
  const { screenIsSmallerThan } = useWindowDimensions();
  const { t } = useTranslation();

  return (
    <div>
      {screenIsSmallerThan('lg') && (
        <div className="mb-5">
          <CompanyIcon />
        </div>
      )}
      <div className="flex flex-col gap-1">
        <span>Bld Saint-Michel 47, 1040 Etterbeek</span>
        <span>info@colochousing.com</span>
        <span>0032 2 615 44 10</span>
      </div>
      <div className="mt-3 flex flex-col gap-1">
        <span>Christophe Vercarre</span>
        <span>IPI 512 838</span>
        <span>NV Axa Belgium n°730.390.160</span>
        <span>
          {t('js.features.marketplace.components.footer.account_number', {
            account: 'BE37 0689 4916 4528',
          })}
        </span>
      </div>
    </div>
  );
};

const Navigation = () => {
  const { currentLocale } = useLocale();
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-col gap-3 hover:[&>a]:text-white">
        <span className="text-lead2 font-bold text-white">
          {t('js.features.marketplace.components.footer.explore.title')}
        </span>
        <a href={`${COLOC_MAIN_APP}/${currentLocale}/shared-houses`}>
          {t('js.features.marketplace.components.footer.explore.houses')}
        </a>
        <a href="https://help.colochousing.com/">Help Center</a>
        <a href="https://help.colochousing.com/">FAQ</a>
        <a href={`${COLOC_MAIN_APP}/${currentLocale}/terms-and-conditions`}>
          {t('js.features.marketplace.components.footer.explore.terms')}
        </a>
        <a href={`${COLOC_MAIN_APP}/${currentLocale}/privacy-policy`}>
          {t('js.features.marketplace.components.footer.explore.privacy')}
        </a>
      </div>
      <div className="flex flex-col gap-3 hover:[&>a]:text-white">
        <span className="text-lead2 font-bold text-white">
          {t('js.features.marketplace.components.footer.company.title')}
        </span>
        <a href={`${COLOC_MAIN_APP}/${currentLocale}/about-us`}>
          {t('js.features.marketplace.components.footer.explore.about_us')}
        </a>
        <a href={`${COLOC_MAIN_APP}/${currentLocale}/press`}>
          Press & Media Kit
        </a>
        <a
          href="https://www.linkedin.com/company/colochousing/jobs/"
          target="_blank"
          rel="noreferrer noopener"
        >
          {t('js.features.marketplace.components.footer.company.jobs')}
        </a>
        {/* <a href={`${COLOC_MAIN_APP}/${currentLocale}/spot-a-house`}>
          Spot a house
        </a> */}
      </div>
      {/* # NOTE: INVEST */}
      <div className="flex flex-col gap-3 hover:[&>a]:text-white">
        <div />
        {/* <span className="text-lead2 font-bold text-white">
          {t('js.features.marketplace.components.footer.invest.title')}
        </span>
        <a href="https://www.colochousing.com/owner">
          {t(
            'js.features.marketplace.components.footer.invest.converting_your_property'
          )}
        </a> */}
      </div>
    </>
  );
};

const SocialIcons = () => {
  const { screenIsLargeOrEqualThan } = useWindowDimensions();

  const socialIconSize = useMemo(() => {
    return screenIsLargeOrEqualThan('lg') ? 20 : 24;
  }, [screenIsLargeOrEqualThan]);

  return (
    <div className="flex flex-wrap gap-4">
      <a
        href="https://www.facebook.com/ColocHousing"
        target="_blank"
        rel="noreferrer noopener"
      >
        <SocialIcon
          variant="black-white"
          name="facebook"
          size={socialIconSize}
          strokeColor={theme?.colors.E75}
          hoverStrokeColor={theme?.colors.white}
        />
      </a>
      <a
        href="https://twitter.com/ColocHousing"
        target="_blank"
        rel="noreferrer noopener"
      >
        <SocialIcon
          variant="black-white"
          name="X"
          size={socialIconSize}
          strokeColor={theme?.colors.E75}
          hoverStrokeColor={theme?.colors.white}
        />
      </a>
      <a
        href="https://www.instagram.com/colochousing"
        target="_blank"
        rel="noreferrer noopener"
      >
        <SocialIcon
          variant="black-white"
          name="instagram"
          size={socialIconSize}
          strokeColor={theme?.colors.E75}
          hoverStrokeColor={theme?.colors.white}
        />
      </a>
      <a
        href="https://www.youtube.com/@colochousing"
        target="_blank"
        rel="noreferrer noopener"
      >
        <SocialIcon
          variant="black-white"
          name="youtube"
          size={socialIconSize}
          strokeColor={theme?.colors.E75}
          hoverStrokeColor={theme?.colors.white}
        />
      </a>
      <a
        href="https://www.tiktok.com/@colochousing"
        target="_blank"
        rel="noreferrer noopener"
      >
        <SocialIcon
          variant="black-white"
          name="tiktok"
          size={socialIconSize}
          strokeColor={theme?.colors.E75}
          hoverStrokeColor={theme?.colors.white}
        />
      </a>
      <a
        href="https://www.linkedin.com/company/colochousing/"
        target="_blank"
        rel="noreferrer noopener"
      >
        <SocialIcon
          variant="black-white"
          name="linkedin"
          size={socialIconSize}
          strokeColor={theme?.colors.E75}
          hoverStrokeColor={theme?.colors.white}
        />
      </a>
      <a
        href="https://www.pinterest.com/colochousing/"
        target="_blank"
        rel="noreferrer noopener"
      >
        <SocialIcon
          variant="black-white"
          name="pinterest"
          size={socialIconSize}
          strokeColor={theme?.colors.E75}
          hoverStrokeColor={theme?.colors.white}
        />
      </a>
    </div>
  );
};

const TradeMark = () => {
  return (
    <span>{`©${getFullYearNumber(
      new Date()
    )} COSY BV • VAT BE0773.923.606`}</span>
  );
};

const Line = () => {
  return <div className="h-[1px] w-full bg-E75 opacity-10" />;
};

const Footer = () => {
  const { screenIsLargeOrEqualThan } = useWindowDimensions();

  return (
    <footer className="w-full bg-text-primary text-body text-E75">
      <div
        className={composeClassName(
          DEFAULT_CONTENT_WIDTH,
          'm-auto flex flex-col gap-7 py-7',
          'sm:py-8',
          'lg:gap-[0px] lg:py-10'
        )}
      >
        {screenIsLargeOrEqualThan('lg') && <CompanyIcon />}
        {screenIsLargeOrEqualThan('lg') ? (
          <div className="mt-8 flex justify-between">
            <CompanyInfo />
            <Navigation />
          </div>
        ) : (
          <>
            <CompanyInfo />
            <div
              className={composeClassName(
                'flex flex-col gap-7',
                screenIsLargeOrEqualThan(500) && '!flex-row !justify-between'
              )}
            >
              <Navigation />
            </div>
          </>
        )}
        {screenIsLargeOrEqualThan('lg') ? (
          <div className="mt-5 w-full">
            <Line />
            <div
              className={composeClassName(
                'mt-5 flex w-full flex-col-reverse justify-between gap-5',
                'lg:flex-row'
              )}
            >
              <TradeMark />
              <SocialIcons />
            </div>
          </div>
        ) : (
          <div className="flex w-full flex-col gap-5">
            <SocialIcons />
            <Line />
            <TradeMark />
          </div>
        )}
      </div>
    </footer>
  );
};

export { Footer };
