import { BaseIconProps } from '../types';

export const countryIconNames = ['be', 'de', 'fr', 'nl', 'uk', 'us'];

const countryIconNamesInt = [...countryIconNames] as const;

export type CountryIconName = typeof countryIconNamesInt[number];

export type CountyIconProps = BaseIconProps & {
  /**
   * The name of the icon.
   */
  name: CountryIconName;
};
