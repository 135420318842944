import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DropDown } from '@/components/design-system/molecules/dropdown';

import { PaginatedColocApiConnectedDropDown } from '@/features/shared/feature-paginated-coloc-api-connected-dropdown';

import { BankAccount } from '@/features/admin/transactions/types';
import { useGetBankAccountsInfinite } from '@/features/admin/transactions/components/incoming-transactions-list/hooks/use-get-bank-accounts-infinite';
import { useTransactionsFiltersContext } from '@/features/admin/transactions/components/incoming-transactions-list/hooks/use-transactions-list-filter';

const BankAccountDropDown = () => {
  const {
    bankAccountQuery,
    setBankAccountQuery,
    selectedBankAccount,
    setSelectedBankAccount,
  } = useTransactionsFiltersContext()!;
  const { t } = useTranslation();

  const onLoaded = useCallback(
    (data: Array<BankAccount>) => {
      if (data) {
        const rent_account = data
          .filter((d) => d.account_type === 'rent')
          .at(0);
        if (rent_account) {
          setSelectedBankAccount(rent_account);
        }
      }
    },
    [setSelectedBankAccount]
  );

  return (
    <PaginatedColocApiConnectedDropDown<BankAccount>
      placeholder={
        t(
          'js.features.admin.transactions.transactions_list.filters.account_placeholder'
        ) ?? ''
      }
      className="z-50 w-[220px]"
      showLabel
      label={t(
        'js.features.admin.transactions.transactions_list.filters.account_label'
      )}
      value={selectedBankAccount}
      onChange={setSelectedBankAccount}
      onLoaded={onLoaded}
      {...(selectedBankAccount && {
        displayValue:
          selectedBankAccount.account_description ||
          selectedBankAccount.account_number,
      })}
      onTextInputChange={(event) => setBankAccountQuery(event.target.value)}
      dataSource={
        useGetBankAccountsInfinite({
          searchQuery: bankAccountQuery,
        })!
      }
      itemRenderer={(itemProps, ref) => {
        return (
          <DropDown.DropDownOptionDefaultContent
            description={itemProps.connected_houses
              .map(
                (x, index) => `${index >= 1 ? '   ' : ''}${x.name.slice(0, -1)}`
              )
              .toString()}
            // @ts-ignore
            ref={ref}
          >
            {itemProps.account_description || itemProps.account_number}
          </DropDown.DropDownOptionDefaultContent>
        );
      }}
    />
  );
};

export { BankAccountDropDown };
