import { composeClassName } from '../../utils/util_helpers';

export const getFormLabelStyle = (props: {
  disabled: boolean;
  showLabel: boolean;
}): string => {
  return composeClassName(
    'w-full break-words pb-1 text-footnote1 capitalize text-text-secondary text-N700 leading-[16px]',
    props.disabled && 'text-text-tertiary',
    !props.showLabel && 'hidden'
  );
};

export const afterTextInputStyle =
  'absolute right-[0px] top-[0px] flex h-full items-center px-3';

export const inputParentContainer = 'flex flex-col items-start';
