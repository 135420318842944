/* eslint-disable react/prop-types */
import React from 'react';
import { FloatingDelayGroup } from '@floating-ui/react';
import { PopOverProps } from './types';
import { PopOverContextProvider } from './hooks/use-pop-over-context';
import { PopOverContentProps } from './components/pop-over-content/types';
import { PopOverContent } from './components/pop-over-content';
import { PopOverTrigger } from './components/pop-over-trigger';
import { PopOverTriggerProps } from './components/pop-over-trigger/types';

const PopOver: React.FC<PopOverProps> & {
  PopOverTrigger: React.ForwardRefExoticComponent<PopOverTriggerProps> &
    React.RefAttributes<HTMLDivElement>;
  PopOverContent: React.ForwardRefExoticComponent<PopOverContentProps> &
    React.RefAttributes<HTMLDivElement>;
} = ({ children, placement = 'bottom-end', ...props }) => {
  return (
    <FloatingDelayGroup delay={200}>
      <PopOverContextProvider placement={placement} {...props}>
        {children}
      </PopOverContextProvider>
    </FloatingDelayGroup>
  );
};

PopOver.PopOverContent = PopOverContent;
// @ts-ignore
PopOver.PopOverTrigger = PopOverTrigger;

export { PopOver };
