/* eslint-disable react/prop-types */
import React, { Children, isValidElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from '@/components/design-system/molecules/header';
import { useAuthContext } from '@/hooks/use-auth';
import { COLOC_APP_API_HOST, COLOC_MAIN_APP } from '@/config';
import { ConnectedMobileSideMenuPropsAndSubComponents, Slots } from './types';

const Leading: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <>{children}</>;
};

const Trailing: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <>{children}</>;
};

const ConnectedMobileSideMenu: ConnectedMobileSideMenuPropsAndSubComponents = ({
  children,
}) => {
  const { user } = useAuthContext()!;
  const { t } = useTranslation();

  const filledSlots = useMemo(() => {
    const slots: Slots = {
      leading: null,
      trailing: null,
    };

    Children.toArray(children).forEach((child) => {
      if (!isValidElement(child)) {
        return;
      }

      switch (child.type) {
        case Leading:
          slots.leading = child;
          break;
        case Trailing:
          slots.trailing = child;
          break;
        default:
      }
    });

    return slots;
  }, [children]);

  return (
    <form method="post" action={`${COLOC_APP_API_HOST}/users/sign_out`}>
      <input type="hidden" name="_method" value="delete" />
      <Header.MobileSideMenu>
        <Header.MobileSideMenu.Leading>
          {filledSlots.leading}
          {user ? (
            <Header.MobileSideMenu.MobileSideMenuOptions>
              <Header.MobileSideMenu.MobileSideMenuOption
                href={`${COLOC_MAIN_APP}/dashboard`}
                iconName="element-2"
              >
                Dashboard
              </Header.MobileSideMenu.MobileSideMenuOption>
              <Header.MobileSideMenu.MobileSideMenuOption
                href={`${COLOC_MAIN_APP}/profile/${user.slug}`}
                iconName="user"
              >
                {t('js.features.shared.header.account') ?? ''}
              </Header.MobileSideMenu.MobileSideMenuOption>
              <Header.MobileSideMenu.MobileSideMenuOption
                href={`${COLOC_MAIN_APP}/users/edit`}
                iconName="setting-2"
              >
                {t('js.features.shared.header.settings') ?? ''}
              </Header.MobileSideMenu.MobileSideMenuOption>
              <Header.MobileSideMenu.MobileSideMenuOption
                href={`${COLOC_MAIN_APP}/watchlist`}
                iconName="heart"
              >
                {t('js.features.shared.header.wishlist') ?? ''}
              </Header.MobileSideMenu.MobileSideMenuOption>
              <Header.MobileSideMenu.MobileSideMenuOption
                as="button"
                type="submit"
                iconName="logout"
                destructive
              >
                {t('js.features.shared.header.sign_out') ?? ''}
              </Header.MobileSideMenu.MobileSideMenuOption>
            </Header.MobileSideMenu.MobileSideMenuOptions>
          ) : (
            <Header.MobileSideMenu.MobileSideMenuOptions>
              <Header.MobileSideMenu.MobileSideMenuOption
                href={`${COLOC_MAIN_APP}/watchlist`}
              >
                {t('js.features.shared.header.login') ?? ''}
              </Header.MobileSideMenu.MobileSideMenuOption>
              <Header.MobileSideMenu.MobileSideMenuOption
                href={`${COLOC_MAIN_APP}/watchlist`}
              >
                {t('js.features.shared.header.register') ?? ''}
              </Header.MobileSideMenu.MobileSideMenuOption>
            </Header.MobileSideMenu.MobileSideMenuOptions>
          )}
        </Header.MobileSideMenu.Leading>
        <Header.MobileSideMenu.Trailing>
          {filledSlots.trailing}
          <Header.MobileSideMenu.MobileSideMenuOptions>
            <Header.MobileSideMenu.MobileSideMenuOption
              iconName="help"
              href={`${COLOC_MAIN_APP}/users/sign_in`}
            >
              Help Center
            </Header.MobileSideMenu.MobileSideMenuOption>
          </Header.MobileSideMenu.MobileSideMenuOptions>
        </Header.MobileSideMenu.Trailing>
      </Header.MobileSideMenu>
    </form>
  );
};

ConnectedMobileSideMenu.Leading = Leading;
ConnectedMobileSideMenu.Trailing = Trailing;

export { ConnectedMobileSideMenu };
