/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
import React from 'react';
import { NavigationMenuProps } from './types';
import { NavigationMenuTabProps } from './components/navigation-menu-tab/types';
import { NavigationMenuTab } from './components/navigation-menu-tab';
import { NavigationMenuTabsProps } from './components/navigation-menu-tabs/types';
import { NavigationMenuTabs } from './components/navigation-menu-tabs';
import {
  NavigationMenuContextProvider,
  useNavigationMenuContext,
} from './hooks/use-navigation-menu-context';
import { CollapsingToggle } from './components/collapsing-toggle';
import { NavigationMenuHomeLink } from './components/navigation-menu-home-link';
import { composeClassName } from '@/utils/util_helpers';

const NavigationMenuContent: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { displayValue } = useNavigationMenuContext()!;

  return (
    <nav
      className={composeClassName(
        'relative block h-full bg-white shadow-small transition-all duration-500',
        displayValue === 'collapsed' ? 'w-[120px]' : 'w-[280px]'
      )}
    >
      <NavigationMenuHomeLink />
      <CollapsingToggle />
      <>{children}</>
    </nav>
  );
};

const NavigationMenu: React.FC<NavigationMenuProps> & {
  NavigationMenuTab: React.FC<NavigationMenuTabProps>;
  NavigationMenuTabs: React.FC<NavigationMenuTabsProps>;
} = ({ children, onChange, activeNavigationKey, displayValue }) => {
  return (
    <NavigationMenuContextProvider
      onChange={onChange}
      activeNavigationKey={activeNavigationKey}
      {...(displayValue && { displayValue })}
    >
      <NavigationMenuContent>{children}</NavigationMenuContent>
    </NavigationMenuContextProvider>
  );
};

NavigationMenu.NavigationMenuTab = NavigationMenuTab;
NavigationMenu.NavigationMenuTabs = NavigationMenuTabs;

export { NavigationMenu };
