import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTrans from '../locales/en.json';
import frTrans from '../locales/fr.json';
import nlTrans from '../locales/nl.json';

i18n
  .use(initReactI18next)
  .use(LanguageDetector) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: { ...enTrans },
      },
      fr: {
        translation: { ...frTrans },
      },
      nl: {
        translation: { ...nlTrans },
      },
    },
    fallbackLng: 'en',
    detection: { order: ['path'] },
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default i18n;
