import React, { useMemo } from 'react';
import { RentDueCellContentProps } from './types';
import { Table } from '@/components/design-system/molecules/table';
import { composeClassName } from '@/utils/util_helpers';
import { amountToString } from '@/utils/util_helpers/formatting/amount_to_string';

const RentDueCellContent: React.FC<RentDueCellContentProps> = ({
  unpaid_amount,
}) => {
  const dueStatus: 'due' | 'level' | 'overpaid' = useMemo(() => {
    if (unpaid_amount.cents === 0) {
      return 'level';
    }

    if (unpaid_amount.cents < 0) {
      return 'overpaid';
    }

    return 'due';
  }, [unpaid_amount.cents]);

  const adjustedAmount = useMemo(() => {
    const amountCopy = { ...unpaid_amount };
    if (dueStatus === 'overpaid') {
      amountCopy.cents = Math.abs(amountCopy.cents);
    }

    return amountCopy;
  }, [dueStatus, unpaid_amount]);

  return (
    <Table.TableCellDefaultContent
      titleClassName={composeClassName(
        dueStatus === 'due' && '!text-text-failed',
        dueStatus === 'level' && '!text-text-tertiary',
        dueStatus === 'overpaid' && '!text-text-success'
      )}
    >
      {`${dueStatus === 'overpaid' ? '+ ' : ''}${amountToString(
        adjustedAmount
      )}`}
    </Table.TableCellDefaultContent>
  );
};

export { RentDueCellContent };
