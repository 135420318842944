import React from 'react';
import { useTranslation } from 'react-i18next';
import { composeClassName } from '@/utils/util_helpers';
import { SectionTitle } from '../../../../../components/section-title';
import { DEFAULT_CONTENT_WIDTH } from '../../../../../constants';
import { ApplyStep } from './components/apply-step';
import { VotingCards } from './components/voting-cards';

const backgroundImage = require(`@assets/images/marketplace/home-section-4-curve.svg`);

const FourthSection = () => {
  const { t } = useTranslation();

  return (
    <section className="relative w-full bg-[#fbfbfb]">
      <div
        className="h-[120px] w-full "
        style={{
          backgroundSize: 'cover',
          backgroundRepeat: 'none',
          backgroundImage: `url(${backgroundImage})`,
        }}
      />
      <div
        className={composeClassName(
          DEFAULT_CONTENT_WIDTH,
          'm-auto pb-7',
          'md:mt-8',
          'lg:mt-5 lg:pb-10'
        )}
      >
        <div className={composeClassName('w-full', 'lg:w-[60%]', 'xl:w-[40%]')}>
          <SectionTitle
            subTitle={t('js.features.marketplace.home.section4.subtitle') ?? ''}
          >
            {t('js.features.marketplace.home.section4.title')}
          </SectionTitle>
        </div>
        <div
          className={composeClassName(
            'flex w-full flex-col-reverse',
            'lg:flex-row lg:justify-between'
          )}
        >
          <div className={composeClassName('w-full', 'lg:w-[40%]')}>
            <div
              className={composeClassName(
                'mt-5 flex w-full flex-col',
                'md:mt-7',
                'lg:mt-[7.2rem]'
              )}
            >
              <ApplyStep
                step={1}
                title={
                  t(
                    'js.features.marketplace.home.section4.steps.step1_title'
                  ) ?? ''
                }
                text={
                  t('js.features.marketplace.home.section4.steps.step1_text') ??
                  ''
                }
              />
              <ApplyStep
                step={2}
                title={
                  t(
                    'js.features.marketplace.home.section4.steps.step2_title'
                  ) ?? ''
                }
                text={
                  t('js.features.marketplace.home.section4.steps.step2_text') ??
                  ''
                }
              />
              <ApplyStep
                step={3}
                title={
                  t(
                    'js.features.marketplace.home.section4.steps.step3_title'
                  ) ?? ''
                }
                text={
                  t('js.features.marketplace.home.section4.steps.step3_text') ??
                  ''
                }
              />
            </div>
          </div>
          <VotingCards />
        </div>
      </div>
    </section>
  );
};

export { FourthSection };
