import React from 'react';
import { ConnectedHeader } from '@/features/shared/feature-connected-header';
import { SearchBarHeader } from '../search-bar';
import { SearchRoomContextProvider } from '../../hooks/use-search-room';
import { useWindowDimensions } from '@/hooks/use-window-dimensions';

const MarketPlaceHeader = () => {
  const { screenIsLargeOrEqualThan, screenIsSmallerThan } =
    useWindowDimensions();

  return (
    <SearchRoomContextProvider>
      <ConnectedHeader>
        <ConnectedHeader.Leading>
          {screenIsLargeOrEqualThan('md') && <SearchBarHeader />}
        </ConnectedHeader.Leading>
        <ConnectedHeader.MobileSideMenuLeading>
          {screenIsSmallerThan('md') && (
            <>
              <SearchBarHeader inputWrappingClassName="!w-full" />
              <div className="my-7 h-[1px] w-full bg-E50" />
            </>
          )}
        </ConnectedHeader.MobileSideMenuLeading>
      </ConnectedHeader>
    </SearchRoomContextProvider>
  );
};

export { MarketPlaceHeader };
