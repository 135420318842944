import React from 'react';
import { DiscussionThreadMessageCard } from '../discussion-thread-message-card';
import { LoadingSpinner } from '@/components/design-system/atoms/loading-spinner';
import { DiscussionThreadMessageListProps } from './types';

const DiscussionThreadMessageList: React.FC<DiscussionThreadMessageListProps> =
  ({ isLoading, messages, currentUserId, onDelete }) => {
    if (isLoading) {
      return (
        <div className="flex h-full w-full items-center justify-center">
          <LoadingSpinner size={48} />
        </div>
      );
    }

    if (messages.length >= 1) {
      return (
        <>
          {messages.map((message) => (
            <DiscussionThreadMessageCard
              key={message.id}
              message={message}
              messageByCurrentUser={currentUserId === message.user.id}
              className="w-full"
              onDelete={() => onDelete(message.id)}
            />
          ))}
        </>
      );
    }

    return null;
  };

export { DiscussionThreadMessageList };
