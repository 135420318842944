import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropDown } from '@/components/design-system/molecules/dropdown';
import { useRentsListFiltersContext } from '../../../../hooks/use-rents-list-filter';

const ListRentStatusDropDown = () => {
  const {
    selectedListRentStatus,
    setSelectedListRentStatus,
    setRentStatusQuery,
    filteredListRentsStatusArray,
  } = useRentsListFiltersContext()!;
  const { t } = useTranslation();

  return (
    <DropDown
      placeholder={t('js.features.admin.rents.shared.status_placeholder') ?? ''}
      className="z-50 w-[180px]"
      showLabel
      label={t('js.features.admin.rents.shared.status_label') ?? ''}
      value={selectedListRentStatus}
      onChange={setSelectedListRentStatus}
      {...(selectedListRentStatus && {
        displayValue: selectedListRentStatus.displayValue,
      })}
      onTextInputChange={(event) => setRentStatusQuery(event.target.value)}
    >
      <DropDown.DropDownOptions>
        {filteredListRentsStatusArray.map((option) => {
          return (
            <DropDown.DropDownOption key={option.displayValue} value={option}>
              <DropDown.DropDownOptionDefaultContent>
                {option.displayValue}
              </DropDown.DropDownOptionDefaultContent>
            </DropDown.DropDownOption>
          );
        })}
      </DropDown.DropDownOptions>
    </DropDown>
  );
};

export { ListRentStatusDropDown };
