import { useTranslation } from 'react-i18next';
import React from 'react';
import { DatePicker } from '@/components/design-system/molecules/datepicker';
import { SearchInput } from '@/components/design-system/molecules/search-input';

import { DropDown } from '@/components/design-system/molecules/dropdown';
import { useGetOutgoingTransactionsListFilterContext } from '../../hooks/use-get-outgoing-transactions-list-filter';
import { OutgoingTransactionsHouseSelectionDropDown } from './components/outgoing-transactions-house-selection-dropdown';

const OutgoingTransactionsListFilterContainer = () => {
  const {
    setSearchQuery,
    searchQuery,
    setDateRange,
    startDate,
    endDate,
    setTransactionStatusQuery,
    selectedStatus,
    setSelectedStatus,
    filteredTransactionStatusArray,
  } = useGetOutgoingTransactionsListFilterContext()!;
  const { t } = useTranslation();

  return (
    <div className="relative flex w-full items-end justify-between pt-4">
      <div className="flex gap-4">
        <SearchInput
          placeholder={
            t(
              'js.features.admin.transactions.outgoing_transactions_list.filters.search_placeholder'
            ) ?? ''
          }
          className="w-[270px]"
          label={
            t(
              'js.features.admin.transactions.outgoing_transactions_list.filters.search_label'
            ) ?? ''
          }
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <DatePicker
          className="w-[320px]"
          onChange={setDateRange}
          label={
            t(
              'js.features.admin.transactions.outgoing_transactions_list.filters.date_range_label'
            ) ?? ''
          }
          dateRange
          type="month"
          startValue={startDate}
          endValue={endDate}
        />
        <OutgoingTransactionsHouseSelectionDropDown />
        <DropDown
          placeholder={
            t(
              'js.features.admin.transactions.outgoing_transactions_list.filters.status_placeholder'
            ) ?? ''
          }
          className="z-50 w-[180px]"
          showLabel
          label={
            t(
              'js.features.admin.transactions.outgoing_transactions_list.filters.status_label'
            ) ?? ''
          }
          value={selectedStatus}
          onChange={setSelectedStatus}
          {...(selectedStatus && {
            displayValue: selectedStatus.displayValue,
          })}
          onTextInputChange={(event) =>
            setTransactionStatusQuery(event.target.value)
          }
        >
          <DropDown.DropDownOptions>
            {filteredTransactionStatusArray.map((option) => {
              return (
                <DropDown.DropDownOption
                  key={option.displayValue}
                  value={option}
                >
                  <DropDown.DropDownOptionDefaultContent>
                    {option.displayValue}
                  </DropDown.DropDownOptionDefaultContent>
                </DropDown.DropDownOption>
              );
            })}
          </DropDown.DropDownOptions>
        </DropDown>
      </div>
    </div>
  );
};

export { OutgoingTransactionsListFilterContainer };
