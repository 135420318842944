import React from 'react';
import { t } from 'i18next';
import { ColocAPIConnectedDropDownProps } from './types';
import { DropDown } from '@/components/design-system/molecules/dropdown';

// eslint-disable-next-line react/function-component-definition
function ColocApiConnectedDropDown<T1>({
  dataSource,
  itemRenderer,
  filterFunction,
  ...props
}: ColocAPIConnectedDropDownProps<T1>) {
  const { fetch } = dataSource;
  const { data, status } = fetch;

  const filteredData = React.useMemo(() => {
    if (filterFunction && data?.data.data) {
      return data?.data.data.filter((item) => filterFunction(item));
    }
    return data?.data.data;
  }, [data, filterFunction]);

  return (
    <DropDown {...props}>
      <DropDown.DropDownOptions>
        <>
          {data?.data.data &&
          data?.data.data.length >= 1 &&
          status === 'success' ? (
            <>
              {filteredData?.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <DropDown.DropDownOption key={index} value={item}>
                  {itemRenderer(item)}
                </DropDown.DropDownOption>
              ))}
            </>
          ) : (
            <div className="flex items-center justify-center">
              {t('js.features.shared.connected_dropdown.no_results')}
            </div>
          )}
        </>
      </DropDown.DropDownOptions>
    </DropDown>
  );
}

ColocApiConnectedDropDown.displayName = 'ColocApiConnectedDropDown';

export { ColocApiConnectedDropDown };
