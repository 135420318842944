import React, { useMemo, useState } from 'react';
import { NavigationMenuTabProps } from './types';
import { IconSaxIcon } from '@/components/design-system/atoms/icon';
import { composeClassName } from '@/utils/util_helpers';
import { theme } from '@/utils/util_theme';
import { useNavigationMenuContext } from '../../hooks/use-navigation-menu-context';

const NavigationMenuTab: React.FC<NavigationMenuTabProps> = ({
  children,
  iconName,
  href,
  active = false,
  navigationKey,
  ...props
}) => {
  const { onChange, activeNavigationKey, displayValue } =
    useNavigationMenuContext()!;
  const [isPressed, setPressedState] = useState(false);

  const tabIsActive = useMemo(() => {
    if (active) {
      return active;
    }

    if (activeNavigationKey) {
      return activeNavigationKey === navigationKey;
    }

    return false;
  }, [active, activeNavigationKey, navigationKey]);

  const iconStrokeColor = useMemo(() => {
    if (isPressed) {
      return theme?.colors?.R500;
    }

    if (active) {
      return theme?.colors?.text?.primary;
    }

    return theme?.colors?.text?.secondary;
  }, [active, isPressed]);

  return (
    <a
      {...props}
      href={href}
      onMouseDown={(e) => {
        props.onMouseDown && props.onMouseDown(e);
        setPressedState(true);
      }}
      onMouseUp={(e) => {
        props.onMouseUp && props.onMouseUp(e);
        setPressedState(false);
      }}
      onClick={(e) => {
        onChange && onChange(navigationKey);
        props.onClick && props.onClick(e);
      }}
    >
      <div
        className={composeClassName(
          'relative flex items-center gap-2 px-7 py-[12px] text-text-secondary',
          'hover:bg-E30',
          isPressed && '!bg-R50 !text-R500',
          tabIsActive && '!bg-R50 !text-text-primary'
        )}
      >
        {tabIsActive && (
          <div className="absolute right-[0px] h-full w-1 bg-R300" />
        )}
        <IconSaxIcon
          name={iconName}
          strokeColor={iconStrokeColor}
          strokeWidth={1.3}
        />
        {displayValue === 'expanded' && (
          <span className="duration-800 text-lead2 transition-all">
            {children}
          </span>
        )}
      </div>
    </a>
  );
};

export { NavigationMenuTab };
