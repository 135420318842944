import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { QueryKey, useQuery } from '@tanstack/react-query';

import { RowSelectionState } from '@tanstack/react-table';

import { AxiosResponse } from 'axios';
import {
  GetTransactionsListResponse,
  transactionsListSortableIds,
  TransactionsListSortType,
} from '@/lib/coloc-api/admin';
import { getTransactionsList } from '@/services/coloc-api/admin';
import { useDebounce } from '@/hooks/use-debounce';
import { useColocApi } from '@/hooks/use-coloc-api';
import { useTransactionsFiltersContext } from '../use-transactions-list-filter';
import { queryClient } from '@/lib/react-query';
import { Transaction } from '../../../../types';

const useGetTransactionsList = () => {
  const {
    searchQuery,
    endDate,
    startDate,
    setColumnToSort,
    sortDirection,
    selectedColumnToSort,
    selectedTransactionPairStatus,
    selectedBankAccount,
  } = useTransactionsFiltersContext()!;
  const {
    setPage,
    setPageLimit,
    page,
    pageLimit,
    resultSummary,
    setPaginationResponse,
  } = useColocApi<TransactionsListSortType>()!;
  const debouncedSearchQuery: string = useDebounce(searchQuery ?? '', 500);
  const sortableColumns = transactionsListSortableIds;
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const queryKey: QueryKey = useMemo(
    () => [
      'getTransactionsList',
      debouncedSearchQuery,
      page,
      pageLimit,
      selectedColumnToSort,
      sortDirection,
      startDate,
      endDate,
      selectedTransactionPairStatus,
      selectedBankAccount,
    ],
    [
      debouncedSearchQuery,
      endDate,
      page,
      pageLimit,
      selectedBankAccount,
      selectedColumnToSort,
      selectedTransactionPairStatus,
      sortDirection,
      startDate,
    ]
  );

  const fetch = useQuery({
    queryKey,
    queryFn: () =>
      getTransactionsList({
        ...(pageLimit && { page_limit: pageLimit }),
        ...(page && { page }),
        ...(debouncedSearchQuery &&
          debouncedSearchQuery !== '' && { query: debouncedSearchQuery }),
        ...(sortDirection && { sort_direction: sortDirection }),
        ...(selectedColumnToSort && { sort: selectedColumnToSort }),
        ...(startDate && { start_date: startDate }),
        ...(endDate && { end_date: endDate }),
        ...(selectedBankAccount && { bank_account_id: selectedBankAccount.id }),
        ...(selectedTransactionPairStatus && {
          status: selectedTransactionPairStatus.status,
        }),
      }),
    keepPreviousData: true,
  });

  const updateTransactionInCache = useCallback(
    (transaction: Transaction) => {
      queryClient.setQueryData(
        queryKey,
        (
          oldData: AxiosResponse<GetTransactionsListResponse, any> | undefined
        ) => {
          if (oldData?.data) {
            const oldDataCopy = { ...oldData };
            const newData = oldDataCopy.data.data.map((mappedTransaction) => {
              if (mappedTransaction.id === transaction.id) {
                return transaction;
              }

              return mappedTransaction;
            });
            oldDataCopy.data.data = newData;

            return oldDataCopy;
          }
          return oldData;
        }
      );
    },
    [queryKey]
  );

  useEffect(() => {
    setPaginationResponse(
      fetch.data?.data.pagination
        ? { pagination: fetch.data?.data.pagination }
        : undefined
    );
  }, [fetch.data?.data.pagination, setPaginationResponse]);

  return {
    pageLimit,
    setPageLimit,
    fetch,
    setPage,
    sortableColumns,
    selectedColumnToSort,
    setColumnToSort,
    sortDirection,
    resultSummary,
    rowSelection,
    setRowSelection,
    updateTransactionInCache,
  };
};

const GetTransactionsListContext = createContext<
  ReturnType<typeof useGetTransactionsList> | undefined
>(undefined);

const GetTransactionsListContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const hook = useGetTransactionsList();

  return (
    <GetTransactionsListContext.Provider value={hook}>
      {children}
    </GetTransactionsListContext.Provider>
  );
};

const useGetTransactionsListContext = () => {
  const context = useContext(GetTransactionsListContext)!;

  if (context === null) {
    throw new Error(
      'useGetTransactionsListContext must be used within a GetTransactionsListContextProvider'
    );
  }

  return context;
};

export { GetTransactionsListContextProvider, useGetTransactionsListContext };
