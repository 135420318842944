import React from 'react';
import { composeClassName } from '@/utils/util_helpers';
import { NotificationButton } from './NotificationButton';
import { CurrentUserInfo } from './CurrentUserInfo';
import { useNavigationMenuContext } from '@/components/design-system/molecules/navigation-menu/hooks/use-navigation-menu-context';

const BottomSection = () => {
  const { displayValue } = useNavigationMenuContext()!;
  return (
    <div
      className={composeClassName(
        'flex flex-row items-center justify-between px-7 py-3',
        displayValue === 'collapsed' && 'flex-col-reverse gap-3'
      )}
    >
      <CurrentUserInfo />
      <NotificationButton />
    </div>
  );
};

export { BottomSection };
