<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div
    :class="[
      'ui-avatar',
      `ui-avatar-s${size}`,
      status ? `ui-avatar-icon-${iconPosition}` : null,
      src ? 'ui-avatar-image' : 'ui-avatar-identicon',
    ]"
  >
    <img v-if="src" src="/avatar.jpg" :alt="alt" />
    {{ !src ? identiconText : '' }}

    <ColocIcon v-if="status" :name="`status-${status}`" />
  </div>
</template>

<script>
import { avatarSizeOptions } from '../../../../utils/constants';
import { getAvatarChars } from '../../../../utils/string_utils';
import ColocIcon from '../icon/coloc-icon/ColocIcon.vue';
import './Avatar.scss';

export default {
  components: {
    ColocIcon,
  },
  props: {
    firstName: {
      type: String,
      required: false,
      default: null,
    },
    lastName: {
      type: String,
      required: false,
      default: null,
    },
    src: {
      type: String,
      required: false,
      default: '',
    },
    alt: {
      type: String,
      required: false,
      default: '',
    },
    size: {
      type: Number,
      required: true,
      validator: (size) => avatarSizeOptions.includes(size),
    },
    status: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    iconPosition() {
      switch (this.status) {
        case 'online':
        case 'busy':
        case 'focus':
        case 'offline':
          return 'bottom';
        default:
          return 'top';
      }
    },
    identiconText() {
      return getAvatarChars(this.firstName, this.lastName);
    },
  },
};
</script>
