import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropDown } from '@/components/design-system/molecules/dropdown';
import { PaginatedColocApiConnectedDropDown } from '@/features/shared/feature-paginated-coloc-api-connected-dropdown';
import { useGetCitiesInfinite } from '@/features/shared/hooks/use-get-cities-infinite';
import { useRoomAssignmentListFiltersContext } from '../../../../hooks/use-get-room-assignment-list-filter';
import { City } from '@/types/admin';

const CitiesSelectionDropdown = () => {
  const { selectedCity, setSelectedCity, citiesQuery, setCitiesQuery } =
    useRoomAssignmentListFiltersContext()!;

  const { t } = useTranslation();
  return <PaginatedColocApiConnectedDropDown<City>
      placeholder={
        t('js.features.admin.contracts.room_assignment.all') ??
        ''
      }
      className="z-20 w-[130px]"
      showLabel
      label={t('js.features.admin.contracts.room_assignment.city')}
      value={selectedCity}
      onChange={setSelectedCity}
      {...(selectedCity && {
        displayValue: selectedCity.name,
      })}
      onTextInputChange={(event) => setCitiesQuery(event.target.value)}
      dataSource={
        useGetCitiesInfinite({
          searchQuery: citiesQuery,
        })!
      }
      itemRenderer={(itemProps, ref) => {
        return (
          // @ts-ignore
          <DropDown.DropDownOptionDefaultContent ref={ref}>
            {itemProps.name}
          </DropDown.DropDownOptionDefaultContent>
        );
      }}
    />
}

export { CitiesSelectionDropdown };
